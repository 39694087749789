import React from "react";
import { Text } from "react-native";
import { Picker } from "react-native-web";

export default class DropDownPicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.defaultValue ? this.props.defaultValue : 0,
      valueList: this.props.items ? this.props.items : [],
      editable: !this.props.disabled ?? true,
    };
  }

  componentDidMount() {
    this.setState({
      value: this.props.defaultValue ? this.props.defaultValue : 0,
      valueList: this.props.items ? this.props.items : [],
      editable: !this.props.disabled ?? true,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    
    if (
      (this.props.defaultValue !== this.state.value &&
        this.props.value &&
        typeof this.props.defaultValue !== "undefined") ||
      this.state.valueList !== this.props.items
    ) {
      this.setState({
        value: this.props.defaultValue ?? 0,
        valueList: this.props.items,
        editable: !this.props.disabled ?? true,
      });
    }
  }

  updatePickerValue = (value) => {
    if (this.state.editable) {
      this.setState(
        {
          value: value.value,
        },
        () => {
          this.props.onChangeItem(value);
        }
      );
    }
  };

  static getDerivedStateFromProps(props, state) {
    if (props.hasOwnProperty("defaultValue")) {
      if (!props.defaultValue && state?.value?.length) {
        state.value = "";
      } else {
        state.value = props.defaultValue;
      }
    }
    return null;
  }
  render() {
    let picker;
    const { noBorder = false, disabled,bColor,borderRadius} = this.props;
    let { value, valueList } = this.state;
    
    picker = (
    <>
      <Picker
        id="guarantorTitle"
        enabled={this.state.editable}
        selectedValue={value}
        style={{
          height: 45,
          marginTop: noBorder ? 0 : 8,
          borderWidth: noBorder ? 0 : 2,
          borderColor: disabled?"#d0d0d0":bColor?bColor: "#fbebf3",
          borderWidth:bColor?1:1,
          borderRadius: 5,
          backgroundColor: "#ffffff",
          // fontSize: 14,
        }}
        className={"price-filter-box"}
        onValueChange={(value) => this.updatePickerValue({ value: value })}
        {...this.props}
      >
        {valueList.map((item, index) => (
          <Picker.Item
            key={index}
            label={item.label}
            value={item.value}
            style={{
              color: "red",
              padding: 5,
            }}
          />
        ))}
      </Picker>
      {this.props?.error? <Text style={{alignSelf:'flex-end',color:'red'}}>*Required</Text>:null}
      </>
    );

    return picker;
  }
}
