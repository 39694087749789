/*
--------------------------------------------------------------------------------------------------------
for rendering the dynamic list we need to have below mentioned scripts and markups in our outer most parent.

this.state = {
      customDropdown:{
        isOpen: false,
        currentDropdownList: "",
        valueSelected1: "abc",
        valueSelected2: "",
        currentDropdown: "",

      }
    };
---------------------------------------------------------------------------------------------------------
Pass the dropdown values in this format
dropdownlist1 = [
    {
      key: "1",
      value: "valuea1",
    },
    {
      key: "2",
      value: "valuea2",
    },
    {
      key: "3",
      value: "valuea3",
    },
    {
      key: "4",
      value: "valuea4",
    },
    {
      key: "5",
      value: "valuea5",
    },
  ];
---------------------------------------------------------------------------------------------------------
Action on selecting the dropdown value

actionOnRow = (value) => {
    const current = this.state.customDropdown.currentDropdown;
    let customDropdown = {...this.state.customDropdown};
    customDropdown[current]= value;
    customDropdown.isOpen= !customDropdown.isOpen;
    this.setState(
      {
        customDropdown
      }
    );
  };
------------------------------------------------------------------------------------------------------------
On click of dropdown's text we need to show the list for whihc we are updating the list values,
isOpen flag, currentDropdown to the one clicked and we are setting its position as per the box

  setCurrentDropdown = (list, currentDropdown, position) => {
    let customDropdown = {...this.state.customDropdown};
    customDropdown.isOpen= !customDropdown.isOpen;
    customDropdown.currentDropdownList= list;
    customDropdown.currentDropdown= currentDropdown;
    customDropdown.position= position;

    this.setState({
      customDropdown
    });
  };


-------------------------------------------------------------------------------------------------------
Import CustomDropdown component and pass props in below format

<CustomDropdown
  dropdownValue={this.dropdownlist1} //object of data to render in dropdown
  setCurrentDropdown={this.setCurrentDropdown} //function call to set the Dropdown list for current DD
  valueSelected={this.state.customDropdown.valueSelected1} //selected value
  title="new drop" //Label text
  dropdownName="valueSelected1" //name of the property we are maintaining in state
  isOpen={this.state.customDropdown.isOpen} //list flag
/>
  
--------------------------------------------------------------------------------------------------------
Import FlatListDropdown and FlatListOption from customDropDown.style.js and pass props in below format 
{this.state.customDropdown.isOpen ? (
            <FlatListDropdown
              data={this.state.customDropdown.currentDropdownList}
              style={{
                top: this.state.customDropdown.position.y,
                left: this.state.customDropdown.position.x,
                width: this.state.customDropdown.position.width,
              }}
              renderItem={({ item }) => (
                <TouchableWithoutFeedback key={item.key}
                  onPress={() => {
                    this.actionOnRow(item.value);
                  }}
                >
                  <View style={{backgroundColor: this.state.customDropdown[this.state.customDropdown.currentDropdown] == item.value? "#faebd7": "#fff"}}>
                    <FlatListOption>{item.value}</FlatListOption>
                  </View>
                </TouchableWithoutFeedback>
              )}
            />
          ) : null}
---------------------------------------------------------------------------------------------------------
*/

import React, { Component } from "react";
import {
  View,
  TouchableWithoutFeedback,
  UIManager,
  findNodeHandle,
} from "react-native";
import { Entypo } from "@expo/vector-icons";

import {
  DropdownWrapper,
  DropdownText,
  DropDownClickText,
  DropdownTextRight,
  GroupedText,
} from "./customDropdown.style";

export default class CustomDropdown extends Component {
  state = {
    position: "",
    isOpen: false,
  };
  setPositions = (e) => {
    UIManager.measure(
      findNodeHandle(this.textInput),
      (x, y, width, height, pageX, pageY) => {
        let position = { x: pageX, y: pageY, width, height };
        this.props.setCurrentDropdown(
          this.props.dropdownValue,
          this.props.dropdownName,
          position
        );
        this.setState({
          isOpen: !this.state.isOpen,
        });
      }
    );
  };

  render() {
    const {
      valueSelected,
      dropdownValue,
      title,
      setDropdown,
      dropdownName,
      textright,
    } = this.props;
    return (
      <DropdownWrapper>
        {title ? (
          <GroupedText>
            <DropdownText>{title ? title : ""}</DropdownText>
            {textright ? (
              <DropdownTextRight>{textright}</DropdownTextRight>
            ) : null}
          </GroupedText>
        ) : null}
        <View>
          <TouchableWithoutFeedback
            onPress={this.setPositions}
            ref={(ref) => {
              this.textInput = ref;
            }}
          >
            <DropDownClickText placeholder={this.props.placeholder}>
              {this.props.valueSelected}
            </DropDownClickText>
          </TouchableWithoutFeedback>
          <View
            style={{ position: "absolute", right: "5%", top: "32%", zIndex: 1 }}
          >
            <Entypo
              name={this.state.isOpen ? "chevron-up" : "chevron-down"}
              size={17}
              color="black"
            />
          </View>
        </View>
      </DropdownWrapper>
    );
  }
}
