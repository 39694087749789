import React, { useState } from "react";
import styled from "styled-components/native";
import getPlatformType from "../../../helpers/getPlatform";
import { DropDownSectionWrapper, DropDownWrapper } from "../../searchFilter/form.style";
import DropDownPicker from "../../../commonComponents/DropDownPicker";
import Modal  from "react-native-modal";
import { AntDesign } from '@expo/vector-icons';
import { View } from 'react-native';
import { Button } from '../../openFlow/atoms/button';


const loanDetailsDropdownOptionsObj = {
  loanTerm: [
    { value: "12", label: "12" },
    { value: "24", label: "24" },
    { value: "36", label: "36" },
    { value: "48", label: "48" },
    { value: "60", label: "60" },
  ],
  loanPurposes: [
       {label:"Select Loan Purpose",value:0},
      { label: "Rent Deposit", value: "OTHER" },
      { label: "CAR", value: "CAR" },
      { label: "Home Improvement", value: "HOME_IMPROVEMENTS" },
      { label: "Debt Consolidation", value: "CONSOLIDATE" },
      { label: "Other", value: "OTHER" },
  ]
};

const layoutType = getPlatformType();

const Text = styled.Text`
  color: #000;
  font-family: 'Lato';
`;

const ErrorText = styled.Text`
  color: red;
  font-family: 'Lato';
  font-size: 12px;
  position: absolute;
  bottom: 2px;
`;

const Title = styled(Text)`
  font-size: 17px;
  color: rgb(255, 181, 128);
  font-weight: 600;
  font-family: "Segoe UI";
`;

const LabelText = styled(Title)`
  font-size: 15px;
  color: #000;
  font-weight: 700;
  font-family: 'Lato';
`;
const Row = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const RowContainer = styled.View`
  display: flex;
  flex-direction: ${props => (props.platform == 'web' ? "row" : (props.platform == 'tab') ? 'row' : 'column')};
`;

const ColumnContainer = styled.View`
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: flex-end;
`;

const InputContainerBig = styled.TextInput`
  width: ${props => (props.platform == 'web' ? "250px" : (props.platform == 'tab') ? 'fit-content' : '100%')};
  height: 40px;
  border-radius: 6px;
  border: 1px solid #d0d0d0;
  margin-top: 10px;
  padding-left: 20px;
  position: relative;
`;

const InputContainerExtraLarge = styled(InputContainerBig)`
  width: ${props => (props.platform == 'web' ? "646px" : (props.platform == 'tab') ? '646px' : 'auto')};
  height: 192px;
  padding: 20px;
`;

const LoanDetails = (data) => {
  const [modal, setModal] = useState({ visible: false, text: '' });
  const onChangeTextHandler = (fieldName, value, isEmpty) => {
    data.dataSetter(fieldName, value);
    if(value.length > 0) {
      data.checkEmptyStatus(isEmpty, false)
    }else {
      data.checkEmptyStatus(isEmpty, true)
    } 
  };

  return(
    <>
      <RowContainer platform={layoutType}>
        <ColumnContainer>
          <LabelText>Loan Amount</LabelText>
          <Row>
          <InputContainerBig 
           id={"ZOPA-LOAN_AMOUNT"}
            onChangeText={(text)=> onChangeTextHandler('loanAmount', text.replace(/[^0-9+]/g, ''), 'loanAmountIsEmmpty') }             
            value={data.data.loanAmount} platform={layoutType} 
          />
           <AntDesign
              onPress={() =>
                setModal({
                  visible: true,
                  text: 'Loan amount is the amount of money you wish to borrow. You can borrow between £1000 to £35,000.',
                  heading:"Loan Amount"
                })
              }
              style={{ marginTop: 10, marginLeft: 10 }}
              name="infocirlceo"
              size={24}
              color="black"
            />
          </Row>
          {data.loanDetailsEmptyStatus.loanAmountIsEmmpty && <ErrorText>This field is required</ErrorText>}
        </ColumnContainer>
        <ColumnContainer>
          <LabelText>Loan Term</LabelText>
        <DropDownWrapper>
        <Row>
          <DropDownSectionWrapper style={{ flexGrow: 2, width: '250px'  }}>
      
            <DropDownPicker
             id={"ZOPA-LOAN-TYPE"}
              items={loanDetailsDropdownOptionsObj.loanTerm}              
              defaultValue={data.data.loanTerm}
              onChangeItem={(e) => data.dataSetter('loanTerm', e.value)}
            />
           
            
          </DropDownSectionWrapper>
          <AntDesign
              onPress={() =>
                setModal({
                  visible: true,
                  text: 'Select from the drop down list, the term of your loan. A loan term is the duration of time it takes to completely pay off your loan',
                  heading:"Loan Term"
                })
              }
              style={{ marginTop: 10, marginLeft: 10 }}
              name="infocirlceo"
              size={24}
              color="black"
            />
          </Row>
          </DropDownWrapper>
        </ColumnContainer>
      </RowContainer>
      <RowContainer platform={layoutType}>
        <ColumnContainer>
          <LabelText>Loan Purpose</LabelText>
          <DropDownWrapper>
          <Row>
          <DropDownSectionWrapper style={{ flexGrow: 2, width: '250px'  }}>
       
            <DropDownPicker
               id={"ZOPA-LOAN-PURPOSE"}
               place
              items={loanDetailsDropdownOptionsObj.loanPurposes}
              defaultValue={data.data.loanPurpose}
              onChangeItem={(e) => data.dataSetter('loanPurpose', e.value)}
            />
           
          </DropDownSectionWrapper>
          <AntDesign
              onPress={() =>
                setModal({
                  visible: true,
                  text: 'Select from the drop down list the purpose of your loan. If you are applying for a loan to help fund your home move (and/or) tenancy deposit, then please select “other” on the drop down list.',
               heading:"Loan Purpose"
                })
              }
              style={{ marginTop: 10, marginLeft: 10 }}
              name="infocirlceo"
              size={24}
              color="black"
            />
             </Row>
          </DropDownWrapper>
          {data.loanDetailsEmptyStatus.loanPurposeIsEmmpty && <ErrorText>This field is required</ErrorText>}
        </ColumnContainer>
      </RowContainer>
      { data?.ButtonView()}
      <Modal
        isVisible={modal.visible}
        transparent={true}
        coverScreen={false}
        hasBackdrop={false}
        backdropOpacity={0}
        onBackdropPress={() =>
          setModal({
            visible: false,
            text: '',
          })
        }
        style={{
          margin: 0,
          width: '100%',
          flex: 1,
          backgroundColor: '#00000080',
          paddingVertical: layoutType == 'phone' ? '5%' : '1%',
          paddingHorizontal: layoutType == 'phone' ? '8%' : '3%',
        }}
      >
        <View
          style={{
            width: layoutType == 'phone' ? '365px' : '600px',
            backgroundColor: '#FAFAFA',
            borderRadius: 20,
            overflow: 'hidden',
            alignSelf: 'center',
            padding: layoutType == 'phone' ? '5%' : '2%',
          }}
        >
          <Text style={{ fontSize: 40, fontWeight: 'bold', color: '#FFB580', alignSelf: 'center' }}>
            {modal?.heading}
          </Text>
          <Text style={{fontSize: 18,marginTop: 30,alignSelf:'center'}}>{modal?.text ? modal?.text : ''}</Text>

          <View
            style={{
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
              marginTop: 60,
            }}
          >
            <Button
              onClick={() => {
                setModal({
                  visible: false,
                  text: '',
                });
              }}
              type={'stripe'}
              color={'#FC4850'}
              title={'Close'}
            />
          </View>
        </View>
      </Modal>
   
    </>
  );
}

export default LoanDetails;