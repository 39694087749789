import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, TouchableOpacity, View, Image } from 'react-native';
import getPlatformType from '../../../helpers/getPlatform';
import { MainSection, Typography } from '../style';
import { useDispatch, useSelector } from 'react-redux';
import { tenantActions } from './../openFlowRedux/tenant.actions';
import BackGroundGenerator from '../../LandingPages/common/BackGroundGenerator';
import { BoxShadow } from '../../tds/style';
import { COLORS } from '../../../constants/colorPallete';
import moment from 'moment';
import { formatDate } from '../../../helpers/functionHelpers';

import { callIfAvailable } from '../../../helpers/callEmailHelper';
import ODSTabs from '../../../../openbrix-design-system/ui/atoms/ODSTabs';
import { getTokens } from '../../../../openbrix-design-system/design-tokens/color-tokens';
import { ODSText } from '../../../../openbrix-design-system/ui/atoms/ODSText';
import ODSButton from '../../../../openbrix-design-system/ui/atoms/ODSButton';
import ODSdocumentCard from '../../../../openbrix-design-system/ui/molecules/propertyBox/ODSdocumentCard';

const layoutType = getPlatformType();
const howToRentGuide = 'https://assets.publishing.service.gov.uk/media/65159e567c2c4a001395e1ea/DLUHC_How_to_rent_Oct2023.pdf';
const Images = {
  floCouncilTax: require('./../../../assets/images/openflow/council_tax.png'),
  floBroabrand: require('./../../../assets/images/openflow/broadband.png'),
  floGas: require('./../../../assets/images/openflow/gas.png'),
  floElectricity: require('./../../../assets/images/openflow/bulb.png'),
  floTV: require('./../../../assets/images/openflow/tv.png'),
  floWater: require('./../../../assets/images/openflow/water.png'),
};
const RentalOffers = (props) => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.authReducer?.profile?.data?.appUser?.app_user_id);
  const [openFlow, setOpenFlowData] = React.useState({});
  const [holdingDeposit, setHoldingDeposit] = React.useState({});
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [rentalOffer, setRentalOffer] = React.useState({});
  const [showMoreInfo, setShowMoreInfo] = React.useState(false);
  const [commonConditions, setCommonConditions] = React.useState([]);
  const [showotherTenants, setShowotherTenants] = React.useState(false);
  const [additionalConditions, setAdditionalConditions] = React.useState([]);
  const [tenantResponsibilities, setTenantResponsibilities] = React.useState([]);
  const [landlordResponsibilities, setLandlordResponsibilities] = React.useState([]);
  const profile = useSelector((state) => state.authReducer.profile);

  useEffect(() => {
    getOpenflowData();
  }, [props?.route?.params?.id]);
  const getOpenflowData = () => {
    if (props?.route?.params?.id) {
      dispatch(
        tenantActions.getOpenflowData(props?.route?.params?.id, (openflow) => {
          if (openflow) {
            setOpenFlowData(openflow);
            setRentalOffer(openflow.open_flow_rental_offer);
            if (openflow) {
              if (openflow.open_flow_rental_offer?.additional_conditions) {
                setAdditionalConditions(
                  openflow.open_flow_rental_offer?.additional_conditions.split(';,;')
                );
              }
              if (openflow.open_flow_rental_offer?.common_conditions) {
                setCommonConditions(JSON.parse(openflow.open_flow_rental_offer?.common_conditions));
              }
              const array1 = [];
              const array2 = [];

              if (openflow.open_flow_rental_offer.tenant_council_tax_responsibility) {
                array1.push({ name: 'Council Tax', image: Images.floCouncilTax });
              } else {
                array2.push({ name: 'Council Tax', image: Images.floCouncilTax });
              }

              if (openflow.open_flow_rental_offer.tenant_broadbank_phone_responsibility) {
                array1.push({ name: 'Broadband', image: Images.floBroabrand });
              } else {
                array2.push({ name: 'Broadband', image: Images.floBroabrand });
              }

              if (openflow.open_flow_rental_offer.tenant_gas_responsibility) {
                array1.push({ name: 'Gas/Oil', image: Images.floGas });
              } else {
                array2.push({ name: 'Gas/Oil', image: Images.floGas });
              }

              if (openflow.open_flow_rental_offer.tenant_electricity_responsibility) {
                array1.push({ name: 'Electricity', image: Images.floElectricity });
              } else {
                array2.push({ name: 'Electricity', image: Images.floElectricity });
              }

              if (openflow.open_flow_rental_offer.tenant_tv_license_responsibility) {
                array1.push({ name: 'TV', image: Images.floTV });
              } else {
                array2.push({ name: 'TV', image: Images.floTV });
              }

              if (openflow.open_flow_rental_offer.tenant_water_responsibility) {
                array1.push({ name: 'Water', image: Images.floWater });
              } else {
                array2.push({ name: 'Water', image: Images.floWater });
              }
              console.log('array1array1', array1);
              console.log('array2array2', array2);

              setTenantResponsibilities(array1);
              setLandlordResponsibilities(array2);
            }
          }
        })
      );
    }
  };

  const getRentalAmountPerTenant = () => {
    if (openFlow?.tenants?.length) {
      return rentalOffer?.rental_amount / openFlow.tenants.length;
    } else {
      return rentalOffer?.rental_amount;
    }
  };

  const getAgentName = () => {
    return `${openFlow?.branch?.name}, ${openFlow?.branch?.company?.name}`;
  };

  const openCertificate = () => {
    if (!rentalOffer?.is_seen_howtorent) {
      dispatch(
        tenantActions.toggleSeen(
          null,
          props?.route?.params?.id,
          () => {
            getOpenflowData();
          },
          true
        )
      );
      //   dispatch(viewOpenFlowCertificate(howToRentGuide, props?.route?.params?.openflow_id));
    }
    window.open(howToRentGuide, '_blank', 'noreferrer');
  };

  const getNextRentDueDate = () => {
    if (rentalOffer?.rental_due_date) {
      const now = new Date();
      const nextRentDueDate = new Date();
      nextRentDueDate.setDate(rentalOffer?.rental_due_date);
      const tenancyEndDate = new Date(rentalOffer?.tenancy_end_date);
      if (nextRentDueDate.getTime() < tenancyEndDate.getTime()) {
        if (nextRentDueDate.getTime() < now.getTime()) {
          const month = nextRentDueDate.getMonth();
          nextRentDueDate.setMonth(month + 1);
          return moment(nextRentDueDate).format('Do MMM YYYY');
        } else {
          return moment(nextRentDueDate).format('Do MMM YYYY');
        }
      } else {
        return 'N/A';
      }
    } else {
      return 'N/A';
    }
  };

  const getCurrentTenant = () => {
    return openFlow?.tenants?.find((tenant) => tenant.app_user_id == userId);
  };

  const getHoldingDepositPerApplicant = () => {
    if (holdingDeposit?.holding_desposit_amount) {
      return (holdingDeposit?.holding_desposit_amount / openFlow?.tenants?.length).toFixed(2);
    } else {
      return 0;
    }
  };

  const getHoldingDepositDueFromYou = () => {
    if (openFlow?.holding_deposit_collection_from_lead_tenant) {
      const currentTenant = getCurrentTenant();
      if (currentTenant?.is_lead) {
        return holdingDeposit?.holding_desposit_amount;
      } else {
        return 0;
      }
    } else {
      return getHoldingDepositPerApplicant();
    }
  };
  const renderDetailsCardTop = () => {
    const currentTenant = getCurrentTenant();
    return (
      <View style={styles.paddingStyle}>
        <View style={[styles.rowFlexView, { borderWidth: 0, marginVertical: 0, marginTop: 0 }]}>
          <View style={styles.rowItem}>
            <ODSText color="black" type={'button'}>
              Total Amount
            </ODSText>
            <ODSText marginTop={8} color="black" type={'sm'}>
              £ {rentalOffer?.rental_amount}
            </ODSText>
          </View>
        </View>
        <View style={[styles.rowFlexView, { borderWidth: 0 }]}>
          <View style={styles.rowItem}>
            <ODSText color="black" type={'button'}>
              Move in Date
            </ODSText>
            <ODSText marginTop={8} color="black" type={'sm'}>
              {moment(rentalOffer?.movein_date).format('Do MMM YYYY')}
            </ODSText>
          </View>
        </View>
        <View style={[styles.rowFlexView, { borderWidth: 0 }]}>
          <View style={styles.rowItem}>
            <ODSText color="black" type={'button'}>
              Rental Due Date
            </ODSText>
            <ODSText marginTop={8} color="black" type={'sm'}>
              {getNextRentDueDate()}
            </ODSText>
          </View>
        </View>
        <View
          style={{
            width: '100%',
            height: 1,
            backgroundColor: themeObj.neutral[300],
            marginVertical: 24,
          }}
        />
        <View style={[styles.rowFlexView, { borderWidth: 0 }]}>
          <View style={styles.rowItem}>
            <ODSText color="black" type={'button'}>
              Number of residents
            </ODSText>
            <ODSText marginTop={8} color="black" type={'sm'}>
              {openFlow?.tenants?.length} residents
            </ODSText>
          </View>
        </View>
        <View style={[styles.rowFlexView, { borderWidth: 0 }]}>
          <View style={styles.rowItem}>
            <ODSText color="black" type={'button'}>
              Rent amount per Applicant
            </ODSText>
            <ODSText marginTop={8} color="black" type={'sm'}>
            £ {getRentalAmountPerTenant()}
            </ODSText>
          </View>
        </View>
        <View style={[styles.rowFlexView, { borderWidth: 0 }]}>
          <View style={styles.rowItem}>
            <ODSText color="black" type={'button'}>
              Rental Type
            </ODSText>
            <ODSText marginTop={8} color="black" type={'sm'}>
              {rentalOffer?.rental_frequency}
            </ODSText>
          </View>
        </View>
        <View style={[styles.rowFlexView, { borderWidth: 0 }]}>
          <View style={styles.rowItem}>
            <ODSText color="black" type={'button'}>
              Agent
            </ODSText>
            <ODSText marginTop={8} color="black" type={'sm'}>
              {getAgentName()}
            </ODSText>
          </View>
        </View>
        {renderCallButtons()}
        {renderCertificates()}
      </View>
    );
  };
  const emailAgent = async () => {
    let mailId = openFlow?.branch?.email ? openFlow?.branch?.email : 'Info@openbrix.co.uk';
    let mailTitle = 'Message from tlyfe openflo tenant';
    let mailBody = `Dear Hyde Park,
    
    I am currently using your tenant move in process and would like to discuss the following points.


    [Add your points here]
    
    Kind regards,
    ${profile?.data?.first_name + ' ' + profile?.data?.last_name}`;
    let url = `mailto:${mailId}?subject=${encodeURIComponent(mailTitle)}&body=${encodeURIComponent(
      mailBody
    )}`;
    window.open(url, '_blank');
  };

  const callAgent = async (x) => {
    let status = false;
    if (x) {
      callIfAvailable(x);
      // status = await callIfAvailable(x);
      // if (!status) {
      //   dispatch(showToaster("error", "Sorry, agent's contact unavailable"));
      // }
    } else if (!status) {
      dispatch(showToaster('error', "Sorry, agent's contact unavailable"));
    }
  };
  const renderCallButtons = () => {
    return (
      <View style={{ marginTop: 20, flexDirection: 'row', justifyContent: 'space-between' }}>
        <View style={{ flex: 0.45 }}>
          <ODSButton
            rIcon={'TBPhone'}
            restStyle={{ width: '100%', height: 40 }}
            type="secondary"
            disabled={false}
            onPress={() =>
              callAgent(
                openFlow?.branch?.contact_number
                  ? openFlow?.branch?.contact_number
                  : '0203 740 4721'
              )
            }
          >
            Call
          </ODSButton>
        </View>
        <View style={{ flex: 0.45 }}>
          <ODSButton
            rIcon={'email'}
            restStyle={{ width: '100%', height: 40 }}
            type="secondary"
            disabled={false}
            onPress={() => emailAgent()}
          >
            Email
          </ODSButton>
        </View>
      </View>
    );
  };
  const renderCertificates = () => {
    return (
      <>
        <ODSdocumentCard
          title={'How to Rent Guide'}
          description={formatDate(openFlow?.createdAt)}
          is_seen={rentalOffer?.is_seen_howtorent}
          onPress={() => {
            openCertificate();
          }}
          style={{ marginTop: 30 }}
        />
        <ODSdocumentCard
          title={'Deposit'}
          hideRightIcon
          description={`Deposit protected with ${
            ' ' + openFlow?.open_flow_move_in?.open_flow_tenancy_deposit_scheme?.name
          }`}
          onPress={() =>
            window.open(
              openFlow?.open_flow_move_in?.open_flow_tenancy_deposit_scheme?.link,
              '_blank',
              'noreferrer'
            )
          }
          style={{ marginTop: 16 }}
        />
      </>
    );
  };

  const renderStatus = () => {
    const currentTenant = getCurrentTenant();
    console.log("currentTenant",currentTenant)
    if (currentTenant) {
      if (currentTenant.rental_offer_status==0) {
        return (
          <View
          style={{
            flexDirection: 'column',
            width: layoutType == 'web' ? 400 : '100%',
            alignSelf: 'center',
          }}
        >
          <ODSButton
            restStyle={{ width: '100%' }}
            type="primary"
            disabled={true}
            onPress={() => { }}
          >
            NOT REQUESTED
          </ODSButton>
        </View>
        );
      } else if (currentTenant.rental_offer_status == 1) {
        return (
          <View
            style={{
              flexDirection: 'column',
              width: layoutType == 'web' ? 400 : '100%',
              alignSelf: 'center',
            }}
          >
            <TouchableOpacity
              activeOpacity={0.9}
              style={{
                borderRadius: 6,
                paddingVertical: 10,
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: COLORS.YELLOW,

                width: '70%',
                marginBottom: 16,
                alignSelf: 'center',
              }}
            >
               <ODSText type={'sm'}>
               Offer Requested
            </ODSText>
    
            </TouchableOpacity>
            <ODSButton
              restStyle={{ width: '100%' }}
              type="primary"
              disabled={false}
              onPress={() => {
                dispatch(
                  tenantActions.addRentalOffer(props?.route?.params?.id, () => {
                    getOpenflowData();
                  })
                );
              }}
            >
              ACCEPT OFFER
            </ODSButton>
          </View>
        );
      } else if (currentTenant.rental_offer_status == 2) {
        return (
          <View
          style={{
            flexDirection: 'column',
            width: layoutType == 'web' ? 400 : '100%',
            alignSelf: 'center',
          }}
        >
          <ODSButton
            restStyle={{ width: '100%' }}
            type="primary"
            disabled={false}
            onPress={() => { }}
          >
            OFFER ACCEPTED
          </ODSButton>
        </View>

        );
      }
    }
  };
  const themeObj = getTokens('light').colors;
  const TabView = () => {
    return (
      <ODSTabs
        items={['Rental Offer', 'Responsibilities', 'Special Conditions']}
        selectedColor={themeObj.main.blue[700]}
        color={themeObj.main.blue[500]}
        activeTab={selectedIndex}
        onTabChange={(val) => setSelectedIndex(val)}
        restStyle={{
          borderRadius: 0,
          borderTopEndRadius: 8,
          borderTopStartRadius: 8,
          minHeight: 68,
          marginBottom: 20,
        }}
      />
    );
  };
  const renderRentalForm = () => {
    return (
      <View style={styles.paddingStyle}>
        <View style={[styles.rowFlexView, { borderWidth: 0, marginVertical: 0, marginTop: 0 }]}>
          <View style={styles.rowItem}>
            <ODSText color="black" type={'button'}>
              Rent Due Date
            </ODSText>
            <ODSText marginTop={8} color="black" type={'sm'}>
              {rentalOffer?.rental_due_date}
            </ODSText>
          </View>
        </View>
        <View style={[styles.rowFlexView]}>
          <View style={styles.rowItem}>
            <ODSText color="black" type={'button'}>
              Initial Rental Payment (£)
            </ODSText>
            <ODSText marginTop={8} color="black" type={'sm'}>
              {rentalOffer?.initial_rental_amount
                ? `£ ${rentalOffer?.initial_rental_amount}`
                : 'Not Requested'}
            </ODSText>
          </View>
        </View>
        <View style={[styles.rowFlexView]}>
          <View style={styles.rowItem}>
            <ODSText color="black" type={'button'}>
              Tenancy start date
            </ODSText>
            <ODSText marginTop={8} color="black" type={'sm'}>
              {moment(rentalOffer?.tenancy_start_date).format('Do MMM YYYY')}
            </ODSText>
          </View>
        </View>
        <View style={[styles.rowFlexView]}>
          <View style={styles.rowItem}>
            <ODSText color="black" type={'button'}>
              Tenancy end date
            </ODSText>
            <ODSText marginTop={8} color="black" type={'sm'}>
              {moment(rentalOffer?.tenancy_end_date).format('Do MMM YYYY')}
            </ODSText>
          </View>
        </View>
        <View style={[styles.rowFlexView]}>
          <View style={styles.rowItem}>
            <ODSText color="black" type={'button'}>
              Tenancy deposit (£)
            </ODSText>
            <ODSText marginTop={8} color="black" type={'sm'}>
              £ {rentalOffer?.tenancy_deposit_amount}
            </ODSText>
          </View>
        </View>

        <View style={[styles.rowFlexView]}>
          <View style={styles.rowItem}>
            <ODSText color="black" type={'button'}>
              Frequency
            </ODSText>
            <ODSText marginTop={8} color="black" type={'sm'}>
              {rentalOffer?.rental_frequency}
            </ODSText>
          </View>
        </View>
        <View style={[styles.rowFlexView]}>
          <View style={styles.rowItem}>
            <ODSText color="black" type={'button'}>
              Rental amount (£)
            </ODSText>
            <ODSText marginTop={8} color="black" type={'sm'}>
              £ {rentalOffer?.rental_amount}
            </ODSText>
          </View>
        </View>
        <View style={[styles.rowFlexView]}>
          <View style={styles.rowItem}>
            <ODSText color="black" type={'button'}>
              Furnishing
            </ODSText>
            <ODSText marginTop={8} color="black" type={'sm'}>
              {rentalOffer?.furnished}
            </ODSText>
          </View>
        </View>
        <View style={[styles.rowFlexView]}>
          <View style={styles.rowItem}>
            <ODSText color="black" type={'button'}>
              Who will receive the rental payment
            </ODSText>
            <ODSText marginTop={8} color="black" type={'sm'}>
              {openFlow?.is_rental_payment_received_by_landlord ? 'Landlord' : 'Agent'}
            </ODSText>
          </View>
        </View>
      </View>
    );
  };

  const renderResposiblity = () => {
    return (
      <View style={styles.paddingStyle}>
        <View style={[styles.rowFlexView, { borderBottomWidth: 0 }]}>
          <View style={{ flexDirection: 'column', flex: 0.5 }}>
          <ODSText type={'button'}>
          Tenant responsible for
            </ODSText>
           
          </View>
        </View>
        {tenantResponsibilities.length > 0
          ? tenantResponsibilities.map((item, index) => (
              <View key={index} style={styles.rowFlexView}>
                <Image
                  source={item.image}
                  style={{
                    width: 30,
                    height: 30,
                    elevation: 5,
                    display: 'flex',
                    shadowRadius: 3.84,
                    shadowOpacity: 0.25,
                    shadowColor: '#000',
                    shadowOffset: { width: 0, height: 2 },
                    marginRight: 20,
                  }}
                />
                <View style={{ flexDirection: 'column', flex: 0.5 }}>
                <ODSText type={'sm'}>
                {item.name}
            </ODSText>
                 
                </View>
              </View>
            ))
          : null}
        <View style={[styles.rowFlexView, { borderBottomWidth: 0 }]}>
          <View style={{ flexDirection: 'column', flex: 0.5 }}>
          <ODSText type={'button'}>
          Landlord responsible for
            </ODSText>
           
          </View>
        </View>
        {landlordResponsibilities.length > 0
          ? landlordResponsibilities.map((item, index) => (
              <View key={index} style={styles.rowFlexView}>
                <Image
                  source={item.image}
                  style={{
                    width: 30,
                    height: 30,
                    elevation: 5,
                    display: 'flex',
                    shadowRadius: 3.84,
                    shadowOpacity: 0.25,
                    shadowColor: '#000',
                    shadowOffset: { width: 0, height: 2 },
                    marginRight: 20,
                  }}
                />
                <View style={{ flexDirection: 'column', flex: 0.5 }}>
                <ODSText type={'sm'}>
                {item.name}
            </ODSText>
                 
                </View>
              </View>
            ))
          : null}
      </View>
    );
  };
  const renderConditionForm = () => {
    return (
      <View style={styles.paddingStyle}>
        {commonConditions.length > 0
          ? commonConditions.map((commonCondition, key) => (
              <>
                <View key={key} style={styles.rowFlexView}>
                  <View style={{ flexDirection: 'column', flex: 1 }}>
                   
                  <ODSText type={'button'}>
                  {key + 1}. {commonCondition.header}
            </ODSText>
           
                
                  </View>
                </View>
                <View style={[styles.rowFlexView, { borderBottomWidth: 0 }]}>
                  <View style={{ flexDirection: 'column', flex: 1 }}>
                  <ODSText marginTop={8} type={'sm'}>
                  {commonCondition.text}
            </ODSText>
                   
                  </View>
                </View>
              </>
            ))
          : null}
        {additionalConditions.length > 0
          ? additionalConditions.map((additionalCondition, key) => (
              <>
                <View key={key} style={styles.rowFlexView}>
                  <View style={{ flexDirection: 'column', flex: 1 }}>
                  <ODSText type={'button'}>
                  {commonConditions.length + key + 1}. Additional Condition
            </ODSText>
           
                  </View>
                </View>
                <View style={[styles.rowFlexView, { borderBottomWidth: 0 }]}>
                  <View style={{ flexDirection: 'column', flex: 1 }}>
                  <ODSText marginTop={8} type={'sm'}>
                  {additionalCondition}
            </ODSText>
                   
                  </View>
                </View>
              </>
            ))
          : null}
      </View>
    );
  };
  const renderTermsCard = () => {
    return (
      <>
        <TabView />
        {selectedIndex == 0 ? renderRentalForm() : null}
        {selectedIndex == 1 ? renderResposiblity() : null}
        {selectedIndex == 2 ? renderConditionForm() : null}
        <View style={styles.paddingStyle}>{renderStatus()}</View>
      </>
    );
  };

  return (
    <BackGroundGenerator
      props={props}
      isTLYF
      isOpenflowContainer
      openFlowData={{
        title: 'Rental Offer',
        desc: 'Breakdown of your rental offer, subject to contract. Check and accept the offer.',
        buttonText: openFlow?.rental_offer_status,
        message: openFlow?.rental_offer_message,
        listIcon: 'rentaloffer',
      }}
    >
      <MainSection
        style={{
          flexDirection: layoutType == 'phone' ? 'column' : 'row',
          justifyContent: 'space-between',
        }}
      >
        <View style={layoutType == 'phone' ? { width: '100%' } : { flex: 0.48 }}>
        <BoxShadow style={{ padding: 0 }}>{renderDetailsCardTop()}</BoxShadow>
        </View>
        <View
          style={layoutType == 'phone' ? { flex: 1, marginTop: 26, width: '100%' } : { flex: 0.48 }}
        >
          <BoxShadow style={{ padding: 0 }}>{renderTermsCard()}</BoxShadow>
        </View>
      </MainSection>
    </BackGroundGenerator>
  );
};
const styles = StyleSheet.create({
  paddingStyle: {
    padding: layoutType == 'phone' ? 12 : 20,
  },
  floIcon: {
    width: 60,
    height: 50,
    elevation: 5,
    display: 'flex',
    shadowRadius: 3.84,
    shadowOpacity: 0.25,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
  },
  rowFlexView:{
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 10,
  },
  centeredView: {
    flex: 1,
    padding: 20,
    marginTop: 22,
    backgroundColor: 'white',
    borderRadius: 20,
  },
  whiteColor12Regular: { color: '#FFFFFF', fontSize: 12 },
  rowItem: { flexDirection: 'column', flex: 5, alignItems: 'flex-start' },
});
export default RentalOffers;
