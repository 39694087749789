import React, { useEffect } from 'react';
import { FontAwesome } from '@expo/vector-icons';
import { View, Text, Platform, StyleSheet } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';
import { saveAs } from 'file-saver';
import { useDispatch, useSelector } from 'react-redux';
import { TenantService } from '../openFlow/openFlowRedux/tenant.service';
import { RTR_URL, YOTI_URL } from '../../helpers/connectionHelpers';
import { openUrlHelpers } from '../../helpers/openDocHelpers';
import { BoxShadow, IconButton, Title2, TitleBlack, Wrapper, Line } from './styles';
import { getDigitalVerificationStatus } from '../openFlow/helpers';
import WideButton from '../../commonComponents/wideButton';
import { CustomButton } from '../identifyAndFinance/stylesNew';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { COLORS } from '../../constants/colorPallete';
import RightToRentOpenflow from './rightToRentOpenFlow';
import IdVerificationOpenflow from './idverificationOpenflow';





const IdVerification = (props) => {
  const { showDocsOnSingleSection, showRtRblock } = props;
  const userData = useSelector((state) => state.authReducer?.profile?.data?.appUser);
  const userId = useSelector((state) => state.authReducer?.profile?.data?.appUser?.app_user_id);
  const yotiRequestId = useSelector((state) => state.authReducer?.profile?.data?.user_id);
  const idVerification = useSelector(
    (state) => state.authReducer?.profile?.data?.appUser?.idverification
  );
  const ussssrdata = useSelector((state) => state?.authReducer?.profile?.data?.appUser);
  const dispatch = useDispatch();
  const getButtonStatus = (id) => {
    if (id == 0 || id === 5 || id == 9) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    setUser(ussssrdata);
  }, []);

  const openDoc = (doc) => {
    openUrlHelpers(doc.filepath);
  };
  const openLinkYoti = async (id) => {
    if (yotiRequestId && getButtonStatus(idVerification)) {
      let u = YOTI_URL + yotiRequestId;

      if (Platform.OS === 'web') {
        window.open(
          u,
          '_blank' // <- This is what makes it open in a new window.
        );
      } else {
        Linking.openURL(u).catch((err) => console.error('An error occurred', err));
      }
    } else {
      console.error('An error occurred', yotiRequestId, getButtonStatus());
    }
  };
  const downloadVerifiedDocuments = async () => {
    if (userData.yoti_verification_document) {
      window.open(userData.yoti_verification_document, '_blank');
    } else {
      alertActions.error('This user does not have verified Documents to download');
    }
  };

  const openLinkRTR = async (id) => {
    let u = RTR_URL;

    if (Platform.OS === 'web') {
      window.open(
        u,
        '_blank' // <- This is what makes it open in a new window.
      );
    } else {
      Linking.openURL(u).catch((err) => console.error('An error occurred', err));
    }
  };

  const [teanants, setUser] = React.useState(null);
  const renderDocs = () => {
    return (
      <>
        {userData?.idverification == 9 ? (
          <>
            <Title2 color={COLORS.LGREEN}>Verification failed reason:</Title2>
            <TitleBlack color={'#000000'} top={'10px'}>
              {userData?.yoti_reason}
            </TitleBlack>
          </>
        ) : (
          <>
            <Title2 color={COLORS.LGREEN}>Download docs</Title2>
            <TitleBlack color={'#000000'} top={'10px'}>
              Documents used for Identity verification can be downloaded and stored to be shared
              with anyone you choose.
            </TitleBlack>
            {getDigitalVerificationStatus(userData?.idverification, userData?.yoti_reason)
              ?.BtnText === 'Completed' ? (
              <>
                <Title2 color={'#000000'} top={'20px'}>
                  Verification doc
                </Title2>
                <View
                  style={{
                    marginTop: 20,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <TouchableOpacity
                    onPress={() => downloadVerifiedDocuments()}
                    style={{ flexDirection: 'row', alignItems: 'center' }}
                  >
                    <IconButton style={{ marginRight: 12 }}>
                      <FontAwesome name="download" size={18} color="white" />
                    </IconButton>
                    <Title2 color={'#000000'}>Download</Title2>
                  </TouchableOpacity>
                  <Line width={'1px'} height={'51px'} />
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <IconButton color={'#FF5A51'} style={{ marginRight: 12 }}>
                      <MaterialIcons name="delete" size={18} color="white" />
                    </IconButton>
                    <Title2 color={'#000000'}>Remove</Title2>
                  </View>
                </View>
              </>
            ) : null}
          </>
        )}
      </>
    );
  };
  return (
    
    <>
      <Wrapper >
      <RightToRentOpenflow navigation={props.navigation}/>
      <IdVerificationOpenflow navigation={props.navigation}/>
      </Wrapper>
    </>
  );
};
const styles = StyleSheet.create({});
export default IdVerification;
