import React, { useEffect } from 'react';
import { StyleSheet, View, Image, FlatList } from 'react-native';
import { useDispatch } from 'react-redux';
import { tenantActions } from '../openFlowRedux/tenant.actions';
import getPlatformType from '../../../helpers/getPlatform';
const layoutType = getPlatformType();
import variables from '../atoms/variables';

import { MainSection, Typography } from '../style';
import WideButton from '../../../commonComponents/wideButton';
import BackGroundGenerator from '../../LandingPages/common/BackGroundGenerator';
import { TouchableOpacity } from 'react-native';
import { COLORS } from '../../../constants/colorPallete';
import { formatDate } from '../../../helpers/functionHelpers';
import { BoxShadow } from '../../tds/style';
import { ODSIcon } from '../../../../openbrix-design-system/ui/atoms/ODSIcon';
import { getTokens } from '../../../../openbrix-design-system/design-tokens/color-tokens';
import { ODSText } from '../../../../openbrix-design-system/ui/atoms/ODSText';
import ODSdocumentCard from '../../../../openbrix-design-system/ui/molecules/propertyBox/ODSdocumentCard';
import ODSButton from '../../../../openbrix-design-system/ui/atoms/ODSButton';

const PropertyDocs = (props) => {
  const themeObj = getTokens('light').colors;
  const dispatch = useDispatch();
  const [openFlowData, setOpenFlowData] = React.useState({});
  const [certificates, setCertificates] = React.useState([]);
  useEffect(() => {
    if (props?.route?.params?.id) {
      getOpenflow();
    }
  }, [props?.route?.params?.id]);
  const getOpenflow = () => {
    dispatch(
      tenantActions.getOpenflowData(props?.route?.params?.id, (openflowData) => {
        setOpenFlowData(openflowData);
        setCertificates(openflowData.open_flow_certificates);
      })
    );
  };
  const getCertificateDisplayName = (certificate: any) => {
    if (certificate.certificate_type) {
      return certificate.certificate_type.split('_').reverse()[0];
    } else {
      return '';
    }
  };
  const openCertificate = (certificate, seenAll) => {
    console.log('certificate', certificate);
    if (certificate?.certificate_url) {
      window.open(certificate.certificate_url, '_blank', 'noreferrer');
    }
    if (certificate?.is_seen == false) {
      dispatch(
        tenantActions.toggleSeen(certificate?.certificate_url, props?.route?.params?.id, () => {
          getOpenflow();
        })
      );
    } else if (seenAll) {
      dispatch(
        tenantActions.toggleSeenAll(props?.route?.params?.id, () => {
          getOpenflow();
        })
      );
    }
  };
  const renderCertificate = (item, index) => {
    console.log("item",item)
    return (
      <ODSdocumentCard
        id={item.certificate_id}
        title={getCertificateDisplayName(item)}
        description={formatDate(item.certificate_upload_date)}
        is_seen={item?.is_seen}
        onPress={() => {
          openCertificate(item);
        }}
        style={
          layoutType !== 'web'
            ? { width: '100%',marginVertical: 10 }
            : {
                width: 500,
                margin: 10,
              }
        }
      />
    );
  };
  return (
    <BackGroundGenerator
      props={props}
      isTLYF
      isOpenflowContainer
      openFlowData={{
        title: 'Landlord & Property Document',
        desc: 'Important documentation for you to read and retain. This is stored for future reference.',
        buttonText: openFlowData?.doc_status,
        message: openFlowData?.doc_message,
        listIcon: "doc",
      }}
    >
      <MainSection>
        <View
          style={
            layoutType == 'web'
              ? { alignSelf: 'center', flexDirection: 'row', flexWrap: 'wrap', width: 1050 }
              : { alignSelf: 'center', flexDirection: 'column', width: '100%' }
          }
        >
          {certificates.map((item, index) => renderCertificate(item, index))}
        </View>

        <View
          style={
            layoutType == 'web'
              ? { width: 1000, alignSelf: 'center', marginTop: 30 }
              : { width: '100%', marginTop: 20 }
          }
        >
          <ODSButton
            restStyle={{ width: layoutType == 'phone' ? '100%' : 300, alignSelf: 'flex-end' }}
            type="primary"
            disabled={false}
            onPress={() => openCertificate(null, true)}
          >
            Mark all as read
          </ODSButton>
        </View>
      </MainSection>
    </BackGroundGenerator>
  );
};
const styles = StyleSheet.create({});
export default PropertyDocs;
