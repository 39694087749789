import React from "react";
import styled from "styled-components/native";
import getPlatformType from "../helpers/getPlatform";

const layoutType = getPlatformType();
let Container, MainContent;

if (layoutType == "phone") {
  Container = styled.View`
    width: 100%;
    margin: 10px auto;
  `;
  MainContent = styled.View`
    justify-content: center;
    align-items: center;
    padding: 0 16px;
    min-height: 70px;
  `;
} else if (layoutType == "web") {
  Container = styled.View`
    width: 100%;
    margin: 20px auto;
  `;
  MainContent = styled.View`
    width: 1150px;
    margin: 20px auto;
    flex-direction: row;
    justify-content: center;
    min-height: 60vh;
  `;
} else {
  Container = styled.View`
    width: 100%;
    margin: 20px auto;
  `;
  MainContent = styled.View`
    flex-direction: row;
    align-items: flex-start;
    min-height: 80vh;
    padding: 0 60px;
  `;
}

export { Container, MainContent };
