import idx from "idx";
import React, { Component } from "react";
import { SafeAreaView, ScrollView, Switch, Text, View } from "react-native";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Footer from "../../commonComponents/footerComponent/customFooter";
import Header from "../../commonComponents/headerComponent/headerComponent";
import TripleTabs from "../../commonComponents/tripleTabs/tripleTabs";
import WideButton from "../../commonComponents/wideButton";
import NotificationList from "./NotificationList";
import getPlatformType from "../../helpers/getPlatform";
import { fetchDoc, LogoutApi, updateNotiSettings } from "../../redux/actions/auth";
import { showToaster } from "../../redux/actions/toast";
import Loader from "./../../commonComponents/activityIndicator/activityIndicator";
import { Container, MainContent, WideButtonView, WideButtonWrapper } from "./myAccountStyles";
import BackButton from "../../commonComponents/BackButton";
import { FormView } from "../myaccount/myAccountStyles";
import Settings from "./settings";

const layoutType = getPlatformType();

class NotificationSettings extends Component {
  notificationListDummy = [
    {
      createdAt: "2020-11-27T19:23:31.328Z",
      description: "<h2>Ash</h2>",
      is_viewed: false,
      notification_type: "supplier",
      supplier_company_id: 51,
      tenant_notification_id: 56,
      title: "mm",
      updatedAt: "2020-11-27T19:23:31.328Z",
      user_id: 61,
    },
    {
      createdAt: "2020-11-27T19:22:40.597Z",
      description: "<h2>Ash</h2>",
      is_viewed: false,
      notification_type: "supplier",
      supplier_company_id: 51,
      tenant_notification_id: 55,
      title: "iii",
      updatedAt: "2020-11-27T19:22:40.597Z",
      user_id: 61,
    },
  ];

  constructor(props) {
    super(props);
    this.state = {
      currentTab: "tab1",
      firstName: "",
      lastName: "",
      contact: "",
      email: "",
      user_id: "",
      idverificationStatus: "",
      yoti_reason: "",
      opbrix_id: "",
      TextInputDisableHolder: true,
      prevProfile: null,
      showDetails: false,
      contactError: "",
      property_alert: false,
      marketplace_alert: false,
      rental_alert: false,
      maintenance_alert: false,
      showLoading: true,
      list: [],
      openflow_alert: false,
    };
  }

  componentDidMount = () => {
    const {
      property_notification,
      marketplace_notification,
      maintenance_notification,
      rental_notification,
      openflow_notification,
    } = idx(this.props, (_) => _.route.params);
    this.setState({
      property_alert: JSON.parse(property_notification),
      maintenance_alert: JSON.parse(maintenance_notification),
      rental_alert: JSON.parse(rental_notification),
      marketplace_alert: JSON.parse(marketplace_notification),
      openflow_alert: JSON.parse(openflow_notification),
      showLoading: false,
    });
    this.props.fetchDoc();
  };

  modifyDate = (date) => {
    return date.substr(0, 10)
    .split("-")
    .join("/");
  };

  updateStatus = () => {
    let req = {
      property_notification: this.state.property_alert,
      marketplace_notification: this.state.marketplace_alert,
      rental_notification: this.state.rental_alert,
      maintenance_notification: this.state.maintenance_alert,
      openflow_notification: this.state.openflow_alert,
    };
    this.props.updateNotiSettings(req);
  };

  render() {
    const {
      profile,
      loading,
      profileLoading,
    } = this.props;

    const {
      property_alert,
      marketplace_alert,
      rental_alert,
      maintenance_alert,
      openflow_alert,
    } = this.state;

    const tab2 = (
      <FormView>
        <NotificationList
          isArchived={true}
        />
      </FormView>
    );
    const tab1 = (
      <View style={{ width: "100%" }}>
        <View
          style={{
            width: "100%",
            padding: 20,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View
            style={{
              width: "50%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text style={{
              fontWeight: "Bold",
              fontSize: 16,
              color: "black",
            }}>
              Propety Alert
            </Text>
          </View>
          <View
            style={{
              width: "50%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Switch
              id={"PROPERTY_SWITCH"}
              trackColor={{
                false: "#767577",
                true: "#81b0ff",
              }}
              thumbColor={property_alert ? "#f5dd4b" : "#f4f3f4"}
              style={{
                backgroundColor: this.state.property_alert
                  ? "tranparent"
                  : "#cdcdcd",
                borderRadius: 30,
              }}
              onValueChange={(e) =>
                this.setState({
                  property_alert: e,
                  showLoading: true,
                }, () => {
                  this.setState({ showLoading: false });
                })
              }
              value={property_alert}
            />
          </View>
        </View>
        <View
          style={{
            width: "100%",
            padding: 20,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View
            style={{
              width: "50%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text style={{
              fontWeight: "Bold",
              fontSize: 16,
              color: "black",
            }}>
              Marketplace
            </Text>
          </View>
          <View
            style={{
              width: "50%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Switch
              id={"MARKETPLACE_SWITCH"}
              trackColor={{
                false: "#767577",
                true: "#81b0ff",
              }}
              thumbColor={marketplace_alert ? "#f5dd4b" : "#f4f3f4"}
              style={{
                backgroundColor: this.state.marketplace_alert
                  ? "tranparent"
                  : "#cdcdcd",
                borderRadius: 30,
              }}
              onValueChange={(e) =>
                this.setState(
                  {
                    marketplace_alert: e,
                    showLoading: true,
                  },
                  () => {
                    this.setState({ showLoading: false });
                  },
                )
              }
              value={marketplace_alert}
            />
          </View>
        </View>
        <View
          style={{
            width: "100%",
            padding: 20,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View
            style={{
              width: "50%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text style={{
              fontWeight: "Bold",
              fontSize: 16,
              color: "black",
            }}>
              Rental Transaction
            </Text>
          </View>
          <View
            style={{
              width: "50%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Switch
              id={"RENTAL_TXN_SWITCH"}
              trackColor={{
                false: "#767577",
                true: "#81b0ff",
              }}
              thumbColor={rental_alert ? "#f5dd4b" : "#f4f3f4"}
              style={{
                backgroundColor: this.state.rental_alert
                  ? "tranparent"
                  : "#cdcdcd",
                borderRadius: 30,
              }}
              onValueChange={(e) =>
                this.setState({
                  rental_alert: e,
                  showLoading: true,
                }, () => {
                  this.setState({ showLoading: false });
                })
              }
              value={rental_alert}
            />
          </View>
        </View>
        <View
          style={{
            width: "100%",
            padding: 20,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View
            style={{
              width: "50%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text style={{
              fontWeight: "Bold",
              fontSize: 16,
              color: "black",
            }}>
              Maintenance
            </Text>
          </View>
          <View
            style={{
              width: "50%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Switch
              id={"MAINTENANCE_SWITCH"}
              trackColor={{
                false: "#767577",
                true: "#81b0ff",
              }}
              thumbColor={maintenance_alert ? "#f5dd4b" : "#f4f3f4"}
              style={{
                backgroundColor: this.state.maintenance_alert
                  ? "tranparent"
                  : "#cdcdcd",
                borderRadius: 30,
              }}
              onValueChange={(e) =>
                this.setState(
                  {
                    maintenance_alert: e,
                    showLoading: true,
                  },
                  () => {
                    this.setState({ showLoading: false });
                  },
                )
              }
              value={maintenance_alert}
            />
          </View>
        </View>
        <View
          style={{
            width: "100%",
            padding: 20,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View
            style={{
              width: "50%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text style={{
              fontWeight: "Bold",
              fontSize: 16,
              color: "black",
            }}>
              OpenFlow
            </Text>
          </View>
          <View
            style={{
              width: "50%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Switch
              id={"OPENFLOW_SWITCH"}
              trackColor={{
                false: "#767577",
                true: "#81b0ff",
              }}
              thumbColor={true ? "#f5dd4b" : "#f4f3f4"}
              style={{
                backgroundColor: this.state.openflow_alert
                  ? "tranparent"
                  : "#cdcdcd",
                borderRadius: 30,
              }}
              onValueChange={(e) =>
                this.setState(
                  {
                    openflow_alert: e,
                    showLoading: true,
                  },
                  () => {
                    this.setState({ showLoading: false });
                  },
                )
              }
              value={openflow_alert}
            />
          </View>
        </View>
      </View>
    );
    const tab2Footer = (
      <WideButtonView>
        <WideButtonWrapper>
          <WideButton
            id={"UPDATE_SETTINGS"}
            gradiant
            onPress={() => {
              this.updateStatus();
            }}
            borderRadius={15}
            childText={"Update"}
          />
        </WideButtonWrapper>
      </WideButtonView>
    );

    const tabList = [
      {
        label: "Archived Notifications",
        target: tab2,
        id: "ARCHIVED_TAB",
      },
      {
        label: "Notification Settings",
        id: "NOTIFICATION_SETTINGS",
        target: <Settings
          onChange={(val) =>
            this.setState({
              property_alert: val.property_alert,
              maintenance_alert: val.maintenance_alert,
              rental_alert: val.rental_alert,
              marketplace_alert: val.marketplace_alert,
              openflow_alert: val.openflow_alert,
            })}

          property_alert={this.state.property_alert}
          maintenance_alert={this.state.maintenance_alert}
          rental_alert={this.state.rental_alert}
          marketplace_alert={this.state.marketplace_alert}
          openflow_alert={this.state.openflow_alert}/>,
        targetfooter: tab2Footer,
      },
    ];

    if (loading || this.state.showLoading) {
      return (
        <View
          style={{
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader loading={loading || this.state.showLoading}/>
        </View>
      );
    } else {
      return (
        <SafeAreaView style={{
          backgroundColor: "#FAFAFA",
          height: "100%",
        }}>
          <Header
            MenuAccountWeb
            showDrawerMob
            showDrawerTab
            showLogoWeb
            showLogoMob
            showLogoTab
            {...this.props}
          />
          <ScrollView>
            <BackButton navigation={this.props.navigation} replace={"My Account"}/>
            <Container>
              <MainContent>
                <TripleTabs
                  tabList={tabList}
                  title="Archived Notifications & Settings"
                  isFullWidth={false}
                />
              </MainContent>
              <Footer {...this.props} />
            </Container>
          </ScrollView>
        </SafeAreaView>
      );
    }
  }
}

const mapStateToProps = (state) => {

  return {
    loading: state.authReducer.updateNotiLoading,
    profile: state.authReducer.profile,
    docs: state.authReducer.docs,
    updateProfileStatus: state.authReducer.updateProfileStatus,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchDoc,
      LogoutApi,
      showToaster,
      updateNotiSettings,
    },
    dispatch,
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationSettings);
