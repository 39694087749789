import React, { useState } from "react";
import {
  View,
  Text,
  SafeAreaView,
  Image,
  TouchableOpacity,
  Platform,
  ScrollView,
  Button,
} from "react-native";
import {
  Container,
  SectionWrapper,
  DualView,
  TextBox,
  SectionTitle,
  SectionText,
  ImageBox,
  TeamsSection,
  CenterSection,
  Section,
  MemberBoxWrapper,
  MemberBox,
  MemberImage,
  MemberName,
  MemberNameText,
  Title,
  Description,
  MemberDetailsText,
  MemberHeader,
  TeamHeader,
  ContentContainer,
  TopSection,
  LogoImage,
  CenterImage,
  SectionImage,
  Left,
  Right,
} from "./styles";

import getPlatformType from "../../helpers/getPlatform";
const layoutType = getPlatformType();

import Header from "../../commonComponents/headerComponent/headerComponent";
import Footer from "../../commonComponents/footerComponent/customFooter";
import TripleTabs from "../../commonComponents/tripleTabs/tripleTabs";
import { COLORS } from "../../constants/colorPallete";
import GradientText from "../../commonComponents/gradientText";
const mlsDescr=`Share properties with other UK Estate Agents and International Agents. Collaborate together, work for buyers and share commissions.  
`;
const openfloDesc=`An interactive tenant onboarding system that's efficient, secure and compliant.`
const searchDesc=`Empower tenants with real time data. From local schools and transport to council tax rates and crime. Smart data property portal for a smart new world.`
const learnDesc=`Agency Complaints and Learning Academy (ACLA). (Coming Soon)
Make your negotiators standout with the best in class training programme.

Build a validated training profile, and retain their learning material, workbooks, CPD and qualifications on their portable devices. 

Blockchain based evidence repository for complaint handling.`;
const tyleDescr=`A Multifunctional Digital ID and Rental Passport. 
A tlyfe App that allows them to be in control of all their property needs. 
-  Interactive “moving-in” process, 
- Search for property, 
- Purchase products, 
- Secure document storage,
- Increase their credit score when paying their rent.
`;
const marketplacedesc=` Agents can integrate and interact with approved Suppliers with ease.
Tenants and Agents can purchase approved products at preferential rates.`
const TabHowItWorks = (props) => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const content=(svg,style,description,heading)=>{
    return(
      <ContentContainer style={{width:layoutType=="phone"?'80%':"100%"}}>
      <Image source={svg} style={[style,{resizeMode:'contain'}]}/>
             
      <Description style={{marginVertical:10,fontFamily:"Lato-Bold",textAlign:'justify'}}>
        {heading}
        </Description>
      <Description style={{marginTop:15,fontFamily:"Lato",textAlign:'justify'}}>
        {description}
      </Description>
      </ContentContainer>
    )
  }
  const tlyfeContent=()=>{
    return(
      <ContentContainer style={{width:layoutType=="phone"?'80%':"100%"}} >
           <Image source={layoutType=="phone"?require('../../assets/SVG/tenantMobile.svg'):require('../../assets/SVG/tenant.svg')} style={{width:190,height:67,resizeMode:'contain'}}/> 
      <Description style={{marginVertical:10,fontFamily:"Lato-Bold"}}>
      A Multifunctional Digital ID and Rental Passport. 
        </Description>
      <Description style={{marginTop:15,fontFamily:"Lato",textAlign:'justify'}}>
      A tenant app that allows tenants to be in control of all their property needs. 
      </Description>
      {["Interactive “moving-in” process","Property search facility","Product purchase","Secure document storage","Increase credit score when paying rent"].map((e)=> <View style={{flexDirection:'row',width:'100%',marginTop:15,alignItems:'center'}}>
        <Image source={require('../../assets/images/aboutus/checkbox.png')} style={{height:18,width:18,resizeMode:'contain'}}/>
        <Description style={{marginLeft:10,fontFamily:"Lato",textAlign:'justify'}}>
        {e}
        </Description>
      </View>)}
      </ContentContainer>
    )
  }

  return (
    <SectionWrapper>
      <TopSection>
        <Image source={require('../../assets/SVG/openbrixlogo.svg')} style={{width:200,marginTop:20,marginBottom:10,alignSelf:layoutType=='phone'?'center':'center',height:32,resizeMode:'contain'}}/>    

        <SectionText style={{fontFamily:"Lato-Bold"}}>for Agents</SectionText>

        <View style={{flexDirection:'row'}}>
             <SectionText style={{color:COLORS.LIGHTPINK3,marginTop:0,fontFamily:"Lato-Bold"}}>Interact </SectionText>
             <SectionText style={{marginTop:0,fontFamily:"Lato-Bold"}}>and</SectionText>
             <SectionText style={{color:COLORS.orange,marginTop:0,fontFamily:"Lato-Bold"}}> Innovate</SectionText>
             <SectionText style={{marginTop:0,fontFamily:"Lato-Bold"}}> with your</SectionText>
        </View>
        <SectionText style={{marginTop:0,fontFamily:"Lato-Bold",marginBottom:60}}>tenants and buyers</SectionText>
      </TopSection>
      <CenterSection>
          <Left>
            {content(require('../../assets/SVG/openflo.svg'),{width:130,height:35},openfloDesc,"Pre-tenancy Process")}
          <View style={{marginTop:layoutType=="phone"?20:60}}/>
          {tlyfeContent()}
          </Left>
          <Right>
          <CenterImage source={require('../../assets/images/aboutus/circle.png')}  />
        {layoutType=="web" && <View style={{flexDirection:'row',justifyContent:'space-around',marginTop:40}}>
      <Section style={{width:"35%"}}>
           {content(require('../../assets/SVG/search.svg'),{width:117,height:29},searchDesc,"Smart dynamic property search")}
           </Section>
           <Section style={{width:"35%"}}>
           {content(require('../../assets/SVG/mls.svg'),{width:60,height:35},mlsDescr,"Multi-Listing Service")}
           </Section>
         </View>}
        
          </Right>
        
      </CenterSection>
      {layoutType!="web"&&  <View style={{flexDirection:layoutType=="phone"?'column-reverse':'row',justifyContent:layoutType=="phone"?"center":'space-between',alignItems:'center',width:layoutType=="phone"?'100%':'80%',marginTop:layoutType=="phone"?20:40}}>
      <Section >
           {content(require('../../assets/SVG/search.svg'),{width:117,height:29,marginTop:20},searchDesc,"Smart dynamic property search")}
           </Section>
           <Section  style={{marginTop:layoutType=="phone"?20:0}}>
           {content(require('../../assets/SVG/mls.svg'),{width:60,height:35},mlsDescr,"Multi-Listing Service")}
           </Section>
         </View>}

         <Image source={layoutType=="phone"?require('../../assets/SVG/nextMobile.svg'):require('../../assets/SVG/next.svg')} style={{width:layoutType=="phone"?260:460,marginTop:20,marginBottom:10,alignSelf:layoutType=='phone'?'center':'center',height:180,resizeMode:'contain'}}/>    


    </SectionWrapper>
  );
};

export default TabHowItWorks;
