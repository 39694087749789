import { tenantConstants } from './tenant.constants';

export function tenant(
  state = {
    tenantLoading: false,
    rentalOffersData: [],
    addRentalData: null,
    moveInData: [],
    flow: [],
    tenancyInfo: [],
    verificationRefData: [],
    selectedProperty: null,
    holdingDepositeData: null,
    preQualData: null,
    sharedCodes: null,
    postCodeData: {},
    tenantInfoData:null
  },
  action
) {
  switch (action.type) {
    case tenantConstants.GET_BILLING_BETTER_IFRAME_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.GET_BILLING_BETTER_IFRAME_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.GET_BILLING_BETTER_IFRAME_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.GET_TEANANTS_DOC_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };

    case tenantConstants.GET_TEANANTS_DOC_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
        tenantDocs: action.data,
      };
    case tenantConstants.GET_TEANANTS_DOC_FAIL:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.GET_TEANANTS_DETAILS_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.GET_TEANANTS_DETAILS_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
        tenantDetails: action.data,
      };
    case tenantConstants.GET_TEANANTS_DETAILS_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.GET_TEANANTS_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.GET_TEANANTS_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
        flow: action.data,
      };
    case tenantConstants.GET_TEANANTS_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.GET_FLOWINFO_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.GET_FLOWINFO_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
        flowIdInfo: action.data,
      };
    case tenantConstants.GET_FLOWINFO_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.GET_FLOWINFO_RESET:
      return {
        ...state,
        flowIdInfo: null,
      };
    case tenantConstants.SAVE_TEANANTS_PROPERTY_REQUEST:
      return {
        ...state,
        selectedProperty: action.data,
        tenantLoading: true,
      };

    case tenantConstants.SAVE_TEANANTS_PROPERTY_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };

    case tenantConstants.SAVE_TEANANTS_PROPERTY_SUCCESS:
      return {
        ...state,
        selectedProperty: action.data,
        tenantLoading: false,
      };

    case tenantConstants.GET_RENTAL_OFFERS_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.GET_RENTAL_OFFERS_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
        rentalOffersData: action.data,
      };
    case tenantConstants.GET_RENTAL_OFFERS_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.UPLOAD_AST_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.UPLOAD_AST_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
        uploadAstData: action.data,
      };
    case tenantConstants.UPLOAD_AST_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.ADD_RENTAL_OFFERS_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.ADD_RENTAL_OFFERS_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
        addRentalData: action.data,
      };
    case tenantConstants.ADD_RENTAL_OFFERS_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.GET_MOVEIN_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.GET_MOVEIN_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
        moveInData: action.data,
      };
    case tenantConstants.GET_MOVEIN_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.ADD_MOVEIN_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.ADD_MOVEIN_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
        addMoveInData: action.data,
      };
    case tenantConstants.ADD_MOVEIN_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.ARRANGE_CHECKIN_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.ARRANGE_CHECKIN_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
        arrangeCheckInData: action.data,
      };
    case tenantConstants.ARRANGE_CHECKIN_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.GET_TENANCY_INFO_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.GET_TENANCY_INFO_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
        tenancyInfo: action.data,
      };
    case tenantConstants.GET_TENANCY_INFO_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.GET_NHA_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.GET_NHA_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
        nhaData: action.data,
      };
    case tenantConstants.GET_NHA_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.GET_AST_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.GET_AST_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
        astData: action.data,
      };
    case tenantConstants.GET_AST_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.ADD_TENANCY_INFO_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.ADD_TENANCY_INFO_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
        addTenancyInfoData: action.data,
      };
    case tenantConstants.ADD_TENANCY_INFO_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.GET_VERIFICATION_REF_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.GET_VERIFICATION_REF_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
        verificationRefData: action.data,
      };
    case tenantConstants.GET_VERIFICATION_REF_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.ADD_TEANANTS_DETAILS_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.ADD_TEANANTS_DETAILS_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.ADD_TEANANTS_DETAILS_FAIL:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.GET_TOGGLE_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.GET_TOGGLE_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.GET_TOGGLE_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };

    case tenantConstants.DELETE_DOCS:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.DELETE_DOCS_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.DELETE_DOCSFAILURE:
      return {
        ...state,
        tenantLoading: false,
      };

    case tenantConstants.GET_HOLDING_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.GET_HOLDING_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
        holdingDepositeData: action.data,
      };
    case tenantConstants.GET_TOGGLE_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };

    case tenantConstants.SAVE_HOLDING_DEPOSIT_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.SAVE_HOLDING_DEPOSIT_REQUEST:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.SAVE_HOLDING_DEPOSIT_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };

    case tenantConstants.REQUEST_HOLDING_DEPOSIT_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.REQUEST_HOLDING_DEPOSIT_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.REQUEST_HOLDING_DEPOSIT_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };

    case tenantConstants.CONFIRM_HOLDING_DEPOSIT_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.CONFIRM_HOLDING_DEPOSIT_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.CONFIRM_HOLDING_DEPOSIT_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };

    case tenantConstants.DEL_CER_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.DEL_CER_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.DEL_CER_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };

    case tenantConstants.SAVE_CER_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.SAVE_CER_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.SAVE_CER_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };

    case tenantConstants.BUY_CER_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.BUY_CER_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.BUY_CER_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };

    case tenantConstants.GET_IDEN_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.GET_IDEN_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
        idendityData: action.data,
      };
    case tenantConstants.GET_IDEN_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };

    case tenantConstants.SAVE_IDEN_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.SAVE_IDEN_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.SAVE_IDEN_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };

    case tenantConstants.GET_TDS_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.GET_TDS_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
        tdsData: action.data,
      };
    case tenantConstants.GET_TDS_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };

    case tenantConstants.SAVE_MOVEIN_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.SAVE_MOVEIN_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.SAVE_MOVEIN_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };

    case tenantConstants.SAVE_CHECKIN_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.SAVE_CHECKIN_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.SAVE_CHECKIN_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };

    case tenantConstants.SAVE_PROTECT_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.SAVE_PROTECT_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.SAVE_PROTECT_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };

    case tenantConstants.GET_PROTECT_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.GET_PROTECT_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
        depositProtectData: action.data,
      };
    case tenantConstants.GET_PROTECT_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };

    case tenantConstants.UPDATE_ISSEEN_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.UPDATE_ISSEEN_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.UPDATE_ISSEEN_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };

    case tenantConstants.CONFIRM_GAURANTOR_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.CONFIRM_GAURANTOR_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.CONFIRM_GAURANTOR_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };

    case tenantConstants.ACCEPT_MOVEIN_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.ACCEPT_MOVEIN_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.ACCEPT_MOVEIN_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.GET_RENTALCOLL_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.GET_RENTALCOLL_SUCCESS:
      return {
        ...state,
        rentColData: action.data,
      };
    case tenantConstants.GET_RENTALCOLL_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.SEARCH_ADDRESS_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.SEARCH_ADDRESS_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
        searchedAddresses: action.data,
      };
    case tenantConstants.SEARCH_ADDRESS_FAILURE:
      return {
        ...state,
        tenantLoading: false,
        searchedAddresses: [],
      };
    case tenantConstants.REQUEST_LOAN_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.REQUEST_LOAN_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
        requestLoanResponse: action.data,
      };
    case tenantConstants.REQUEST_LOAN_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.FOLLOWUP_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.FOLLOWUP_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.FOLLOWUP_ERROR:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.GET_PACK:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.GET_PACK_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
        pack: action.data,
      };
    case tenantConstants.GET_PACK_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.MAKE_PAYMENTS:
      return {
        ...state,
        preQualData: null,
        tenantLoading: true,
      };
    case tenantConstants.MAKE_PAYMENTSSUCCESS:
      return {
        ...state,
        preQualData: null,
        tenantLoading: false,
      };
    case tenantConstants.MAKE_PAYMENTSFAILURE:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.GET_Prequalification:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.GET_Prequalification_success:
      return {
        ...state,
        tenantLoading: false,
        preQualData: action.data,
      };
    case tenantConstants.GET_Prequalification_failure:
      return {
        ...state,
        tenantLoading: false,
      };
      case tenantConstants.GET_REWARDS_SUBS_STATUS:
        return {
          ...state,
          tenantLoading: true,
          rewardssubsData: null,
        };
      case tenantConstants.GET_REWARDS_SUBS_STATUS_SUCCESS:
        return {
          ...state,
          tenantLoading: false,
          // rewardssubsData: action.data,
        };
      case tenantConstants.GET_REWARDS_SUBS_STATUS_FAILURE:
        return {
          ...state,
          tenantLoading: false,
        };
    case tenantConstants.GET_CODES:
      return {
        ...state,
        tenantLoading: true,
        sharedCodes: null,
      };
    case tenantConstants.GET_CODES_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
        sharedCodes: action.data,
      };
    case tenantConstants.GET_CODES_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };

    case tenantConstants.POST_CODES:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.POST_CODES:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.POST_CODES:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.GET_INSURANCE_CLICKER:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.GET_INSURANCE_CLICKER_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.UPLOAD_REF_DOC_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.UPLOAD_REF_DOC_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.UPLOAD_REF_DOC_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.UPLOAD_TENANT_REF_DOC_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.UPLOAD_TENANT_REF_DOC_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.UPLOAD_TENANT_REF_DOC_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.GET_INSURANCE_CLICKER_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.SET_POSTCODE_ACTION:
      return {
        ...state,
        postCodeData: action.data,
      };

    case tenantConstants.GET_TENANT_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.GET_TENANT_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
        tenantInfoData:action?.data?.data
      };
    case tenantConstants.GET_TENANT_FAILED:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.SAVE_TENANT_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.SAVE_TENANT_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
        
      };
    case tenantConstants.SAVE_CHECKIN_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };
    default:
      return state;
  }
}
