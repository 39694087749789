import React, { useEffect, useState } from "react";
import { Linking, Text, TouchableOpacity, View } from "react-native";
import CustomTextInput from "../../../commonComponents/customTextInput/customTextInput";
import CheckBox from "../../../commonComponents/checkbox/checkbox";
import { EmailValidation, MobileValidation, NameValidation, PasswordValidation } from "../../../helpers/validation";
import { useDispatch } from "react-redux";
import WideButton from "../../../commonComponents/wideButton";
import { COLORS } from "../../../constants/colorPallete";
import { signup } from "../../../redux/actions/auth";
import { TermsLinks } from "../../signupScreen/signupScreen.style";
import { Link } from "@react-navigation/native";
import { Typography } from "../../openFlow/style";
import { types } from "../../../redux/actionTypes";
import SocialLogin from "../landingSignin/soicalLogin";
import getPlatformType from "../../../helpers/getPlatform";
import Regex from "../../../helpers/regex";
import StoreButtons from "../common/StoreButtons";
import { openFlow } from "../../../constants/openFlo";
import DropDownPicker from "../../../commonComponents/DropDownPicker.web";
import { SERVER_URL, URL, _get } from "../../../helpers/connectionHelpers";

const LandingRegister = (props) => {
  const layoutType = getPlatformType();
  const [state, setState] = useState({
    tncStatus: false,
    contactStatus: false,
    buyerEmail: "",
    buyerEmailErrorMsg: "",
    buyerFirstName: "",
    buyerFirstNameErrorMsg: "",
    buyerLastName: "",
    buyerLastNameErrorMsg: "",
    buyerMobile: "",
    buyerMobileErrorMsg: "",
    buyerPassword: "",
    buyerPasswordErrorMsg: "",
    validateCheckbox: false,
    propertyAgent: "",
    isRenter: true,
    isCurrentRentProperty: true,
    selectedTab: "Tab1",
    recaptchaValue: false,
    register_refrence:""
  });
const [sourceData,setSourceData] =useState([])
  const {
    navigation,
    isTLYF,
    params
  } = props;
  const {
    tncStatus,
    contactStatus,
    buyerEmail,
    buyerEmailErrorMsg,
    buyerFirstName,
    buyerFirstNameErrorMsg,
    buyerMobileErrorMsg,
    buyerMobile,
    buyerLastNameErrorMsg,
    buyerPassword,
    buyerPasswordErrorMsg,
    buyerLastName,
    validateCheckbox,
    propertyAgent,
    isRenter,
    isCurrentRentProperty,
  } = state;

  const terms = (
    <>
      I accept tlyfe{" "}
      <TermsLinks style={{ color: isTLYF ? COLORS.GREEN : COLORS.LIGHTPINK2 }}>
        <Link to="/legal-bits" target="_blank">
          T&Cs
        </Link>
      </TermsLinks>{" "}
      and{" "}
      <TermsLinks style={{ color: isTLYF ? COLORS.GREEN : COLORS.LIGHTPINK2 }}>
        <Link to="/legal-bits" target="_blank">
          privacy policy
        </Link>
      </TermsLinks>
    </>
  );

  const resetState = () => {
    setState({
      tncStatus: false,
      contactStatus: false,
      buyerEmail: "",
      buyerEmailErrorMsg: "",
      buyerFirstName: "",
      buyerFirstNameErrorMsg: "",
      buyerLastName: "",
      buyerLastNameErrorMsg: "",
      buyerMobile: "",
      buyerMobileErrorMsg: "",
      buyerPassword: "",
      buyerPasswordErrorMsg: "",
      validateCheckbox: false,
      propertyAgent: "",
      isRenter: true,
      isCurrentRentProperty: true,
      selectedTab: "Tab1",
      recaptchaValue: false,
      register_refrence:''
    });
  };

  const dispatch = useDispatch();
  useEffect(() => {
    getSourceDetails()
  }, []);
  const getSourceDetails = ()=>{
    _get(
      `${SERVER_URL}${URL.getsource}`,
      {}, // all headers here (if)
      {}, // body here (if)
      (res) => {
        // function to perform on success
      console.log("getsource",res)
      if(res&&res?.data&&res?.data?.length){
        let finalData = [{key:'',name:'Please select one'},...res?.data]
        setSourceData(finalData)
      }
      },
      (err) => {
        // function to perform on failure
      
      }
    );
  }
  useEffect(() => {
    console.log('paramsparams',params)
    if (params?.from) {
      setState({
        ...state,
        buyerEmail:params?.from ? params?.from : "",
      });
    }
  }, [params]);
  useEffect(() => {
    if (props?.route?.params?.from) {
      setState({
        ...state,
        from: props?.route?.params?.from ? props?.route?.params?.from : "",
      });
    }
  }, [props?.route?.params?.from]);
  const _onSignup = () => {
    const {
      tncStatus,
      contactStatus,
      buyerEmail,
      buyerFirstName,
      buyerMobile,
      buyerPassword,
      propertyAgent,
      isCurrentRentProperty,
      isRenter,
      buyerLastName,
      register_refrence,
      /* recaptchaValue,*/
      from,
    } = state;
    let emailCheck = EmailValidation(buyerEmail);
    let firstNameCheck = NameValidation(buyerFirstName);
    let lastNameCheck = NameValidation(buyerLastName);
    let mobileCheck = MobileValidation(buyerMobile);
    let passwordCheck = PasswordValidation(buyerPassword);
    if (
      emailCheck.status &&
      mobileCheck.status &&
      passwordCheck.status &&
      firstNameCheck.status &&
      lastNameCheck.status &&
      tncStatus
    ) {
      setState({
        ...state,
        validateCheckbox: false,
      });
      dispatch(
        signup(
          {
            first_name: buyerFirstName,
            last_name: buyerLastName,
            contact_number: buyerMobile,
            email: buyerEmail.trim(),
            password: buyerPassword,
            isRenter: isRenter,
            isCurrentRentProperty: isCurrentRentProperty,
            propertyAgentName: propertyAgent,
            user_type: 0,
            registered_from: from,
            register_refrence:register_refrence
          },
          props.navigation.navigate,
          () => {
            resetState();
            dispatch({
              type: types.SHOW_ALERT,
              alertType: "success",
              text: "Registration Successful!",
            });
            props?.onRegisterClick();
          },
        ),
      );
    } else {
      setState({
        ...state,
        buyerFirstNameErrorMsg: buyerFirstName ? "" : "This is a required field",
        buyerLastNameErrorMsg: buyerLastName ? "" : "This is a required field",
        buyerEmailErrorMsg: emailCheck.status ? "" : emailCheck.msg,
        buyerPasswordErrorMsg: passwordCheck.status ? "" : passwordCheck.msg,
        buyerMobileErrorMsg: mobileCheck.status ? "" : mobileCheck.msg,
        validateCheckbox: true,
      });
    }
  };
  const handleOnChange = (value) => {
    setState({
      ...state,
      recaptchaValue: true,
    });
  };

  return (
    <>
      <View style={{
        width: "100%",
        padding: "15px",
      }}>
        {/* <MaterialIcons name="close" size={24} color="black"
      onPress={()=>{
        resetState()
        props?.closePopup()
      }}
        style={{position:"absolute",top:24,right:0}}
      /> */}
        <Typography
          color={isTLYF ? COLORS.LGREEN : COLORS.LIGHTPINK1}
          raj
          size={"30px"}
          bold={"700"}
        >
          REGISTER
        </Typography>
        <TouchableOpacity
          onPress={() => {
            props?.onRegisterClick();
            //state?.from ? navigate('Register', { from: state?.from }) : navigate('Register')
          }}
        >
          <Typography color={COLORS.LABELGREY} raj size={"18px"} bold={"500"}>
            Already have an account?{" "}
            <Typography
              color={isTLYF ? COLORS.GREEN : COLORS.LIGHTPINK2}
              raj
              size={"18px"}
              bold={"500"}
            >
              LOGIN
            </Typography>
          </Typography>
        </TouchableOpacity>
        <View
          style={{
            height: 0.5,
            width: "100%",
            backgroundColor: COLORS.GREY1,
            marginVertical: 20,
          }}
        />
        <View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <CustomTextInput
              width="48%"
              onlyLetters
              showErrorBottom
              bRadius={50}
              testID="register-firstName"
              bColor={"#E2E2E2"}
              // prefilledValue={state.buyerFirstName}
              titleComponent={
                <Typography color={COLORS.BLACK} raj size={"16px"} bold={"600"}>
                  First Name
                </Typography>
              }
              onChangeText={(firstName) => {
               Regex.validateWithSpecialName(firstName) &&
                setState({
                  ...state,
                  buyerFirstName: firstName,
                  buyerFirstNameErrorMsg: "",
                });
              }}
              showError={buyerFirstNameErrorMsg == "" ? false : true}
              errorMessage={buyerFirstNameErrorMsg}
            />
            <CustomTextInput
              width="48%"
              onlyLetters
              showErrorBottom
              testID="register-LastName"
              // prefilledValue={state.buyerLastName}
              onChangeText={(lastName) =>
                Regex.validateWithSpecialName(lastName) &&
                setState({
                  ...state,
                  buyerLastName: lastName,
                  buyerLastNameErrorMsg: "",
                })
              }
              showError={buyerLastNameErrorMsg == "" ? false : true}
              errorMessage={buyerLastNameErrorMsg}
              bRadius={50}
              bColor={"#E2E2E2"}
              titleComponent={
                <Typography color={COLORS.BLACK} raj size={"16px"} bold={"600"}>
                  Last Name
                </Typography>
              }
            />
          </View>

          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <CustomTextInput
              width="48%"
              bRadius={50}
              bColor={"#E2E2E2"}
              showErrorBottom
              titleComponent={
                <Typography color={COLORS.BLACK} raj size={"16px"} bold={"600"}>
                  Email
                </Typography>
              }
              prefilledValue={buyerEmail}
              testID="register-Email"
              onChangeText={(email) =>
                setState({
                  ...state,
                  buyerEmail: email,
                  buyerEmailErrorMsg: "",
                })
              }

              showError={buyerEmailErrorMsg == "" ? false : true}
              errorMessage={buyerEmailErrorMsg}
            />
            <CustomTextInput
              testID="register-ContactNumber"
              width="48%"
              onChangeText={(mobile) =>
                setState({
                  ...state,
                  buyerMobile: mobile,
                  buyerMobileErrorMsg: "",
                })
              }
              bRadius={50}
              bColor={"#E2E2E2"}
              titleComponent={
                <Typography color={COLORS.BLACK} raj size={"16px"} bold={"600"}>
                  Contact Number
                </Typography>
              }
              // prefilledValue={buyerMobile}
              rightText="optional"
              showError={buyerMobileErrorMsg == "" ? false : true}
              errorMessage={buyerMobileErrorMsg}
              keyboardType="number-pad"
            />
          </View>

          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              alignItems: "baseline"
            }}
          >
            <CustomTextInput
              width="48%"
              testID="register-Password"
              bRadius={50}
              eyePosition={"38%"}
              bColor={"#E2E2E2"}
              showErrorBottom
              titleComponent={
                <Typography color={COLORS.BLACK} raj size={"16px"} bold={"600"}>
                  Password
                </Typography>
              }
              onChangeText={(password) =>
                setState({
                  ...state,
                  buyerPassword: password,
                  buyerPasswordErrorMsg: "",
                })
              }
              // prefilledValue={buyerPassword}
              renderRightComponent
              password={true}
              showError={buyerPasswordErrorMsg == "" ? false : true}
              errorMessage={buyerPasswordErrorMsg}
            />
          <View
              style={{
                width: layoutType !== 'phone' ? '48%' : '100%',
              }}
            >
                 <Typography color={COLORS.BLACK} align={"flex-start"} raj size={"16px"} bold={"600"}>
                 where did you hear about us
                </Typography>
             
              <DropDownPicker
                items={sourceData.map((salutation) => {
                  return { value: salutation.key, label: salutation.name };
                })}
                id="openflo-Title"
                bColor="rgb(226, 226, 226)"
                placeholder="Title"
                defaultValue={state?.register_refrence}
                onChangeItem={(e) => {
                  console.log("onChangeItem",e)
                  setState({
                    ...state,
                  register_refrence: e.value,
                  })
                }}
                dropDownStyle={{ backgroundColor: 'white' }}
                zIndex={1001}
                borderRadius={50}
              />
            </View>
           
          </View>
          <Typography
              color={COLORS.LABELGREY}
              line={"19px"}
              align={"left"}
              raj
              size={"15px"}
              bold={"600"}
              top={"20px"}
            >
              You're about to register as a tenant, if your intention is to register as an agent,
              please click
              <Typography
                onPress={() => Linking.openURL("https://agent.openbrix.co.uk/")}
                color={isTLYF ? COLORS.GREEN : COLORS.LIGHTPINK2}
                raj
                size={"15px"}
                bold={"600"}
              >
                {" "}
                here
              </Typography>
            </Typography>
          {/*true ? null : (
            <View
              style={{
                paddingTop: "2%",

              }}
            >
              <ReCAPTCHA
                id={"register-recaptcha"}
                sitekey="6LemSK0hAAAAAOpgBpY0oB4ge1-fan6eCXWNYBq2"
                onChange={handleOnChange}
              />
            </View>
          )}
          {validateCheckbox && !state.recaptchaValue ? (
            <Text style={{ color: "red" }}>Please confirm you are not a robot</Text>
          ) : null*/}
  <WideButton
            width={"100%"}
            id="registerSignup"
            onPress={() => _onSignup()}
            gradiant
            isLGreen={isTLYF}
            childText="SIGN UP"
            height={"55px"}
            gradBorderRadius={50}
            isRaj={true}
            textSize={25}
          />
        
          <View
            style={{
              borderTopColor: "lightgrey",
              paddingTop: "2%",
              flexDirection: layoutType === "phone" ? "column" : "row",
              alignItems: layoutType === "phone" ? "flex-start" : "center",
              justifyContent: "space-between",
            }}
          >
            <View style={{
              flexDirection: "column",
              flex: 1,
              marginTop: 10,
            }}>
              <CheckBox
                testID="registerCheckbox-1"
                checked={tncStatus}
                label={terms}
                onPress={() =>
                  setState({
                    ...state,
                    tncStatus: !state.tncStatus,
                  })
                }
              />
              {validateCheckbox && !tncStatus ? (
                <Text style={{ color: "red" }}>Please accept above condition.</Text>
              ) : null}
            </View>
            {/* <View style={{
              flexDirection: "column",
              flex: 1,
              marginTop: 10,
            }}>
              <CheckBox
                id="registerCheckbox-2"
                checked={contactStatus}
                label="Agree to being contacted and updated"
                onPress={() =>
                  setState({
                    ...state,
                    contactStatus: !state.contactStatus,
                  })
                }
              />
              {validateCheckbox && !contactStatus ? (
                <Text style={{ color: "red" }}>Please accept above condition.</Text>
              ) : null}
            </View> */}
          </View>
        </View>
        <SocialLogin
          params={params}
          navigation={props.navigation}/>

          <StoreButtons/>
      </View>
    </>
  );
};
export default LandingRegister;
