import { View } from 'react-native';
import React, { useState  } from "react";
import { useSelector } from 'react-redux';

import CommonAuth from "./CommonAuth";
import MediaHubDetails from "../../mediaHub/mediaHubDetails";
import BackGroundGenerator from "../common/BackGroundGenerator";
import { backgroundSearchArr } from "../images/tlyfe/searchArrayMedia";

const LandingInfoHub = (props) => {

  const [type, setType] = useState("web");

  const token = useSelector((state) => state?.appReducer?.loginData?.data?.token);
  return (
    <>
      <BackGroundGenerator
        props={props}
        layoutType={(data) => setType(data)}
        backgroundArr={backgroundSearchArr}
        isTLYF={true}
        isLanding
        cloudHeader={token?false: true}
      >
        
        <MediaHubDetails backLink="LandingInfoHub" navigation={props.navigation} id={props?.route?.params?.id} />
      


     {token?null: <View style={{ height: 400 }}></View>}
      </BackGroundGenerator>     
    </>
  );
};

export default LandingInfoHub;
