import React, { Component } from "react";
import {
  View,
  Text,
  Dimensions,
  SafeAreaView,
  Image,
  TouchableOpacity,
  Platform,
  ScrollView,
  FlatList,
  TextInput,
} from "react-native";
import Footer from "../../commonComponents/footerComponent/customFooter";

import { MaterialIcons } from "@expo/vector-icons";
import getPlatformType from "../../helpers/getPlatform";
import Header from "../../commonComponents/headerComponent/headerComponent";
import {
  Container,
  FormContainer,
  BottomContainer,
  ContactTitle,
  ContactHead,
  FormControls,
  LeftPanal,
  BackTextWrapper,
} from "./styles";
import WideButton from "../../commonComponents/wideButton";
const layoutType = getPlatformType();
import { login, getData } from "../../redux/actions/auth";
import { logError } from "../../redux/actions/Ladger";
import idx from "idx";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DatePickerComponent from "../../commonComponents/datepicker/DatePickerComponent";
import CustomTextInput from "../../commonComponents/customTextInput/customTextInput";
import NoDataNotification from "../../components/NoDataFound/noData"; // "../NoDataFound/noData";
import { Entypo } from "@expo/vector-icons";
import FilePicker from "../../commonComponents/filePicker/filepicker";
import {
  TextBox,
  Title,
} from "../../commonComponents/customTextInput/customTextInput.style";

class ReportIssue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      descriptionText: "",
      fileName: "",
      issueImage: "",
      dateOpened: new Date(),
      showNodata: false,
    };
  }

  _logError = () => {
    const { profile } = this.props;
    
    if (
      profile?.data?.tenantDetails &&
      profile?.data?.tenantDetails?.property_id
    ) {
      this.props.logError(
        {
          app_user_id: profile.data.appUser.app_user_id,
          property_id: profile.data.tenantDetails.property_id,
          issuetitle: this.state.title,
          issueSummary: this.state.descriptionText,
          date_closed: this.state.dateOpened,
          issueImage: this.state.issueImage,
        },
        this.props.navigation.navigate
      );
    } else {
      this.setState({ showNodata: true });
    }
  };

  dateUpdateInput = (date, property) => {
    this.setState({ dateOpened: date });
  };

  render() {
    const { navigation } = this.props;

    return (
      <SafeAreaView
        style={{
          flex: 1,
          backgroundColor: "#fafafa",
        }}
      >
        <Header
          MenuAccountWeb
          showLogoWeb
          showLogoTab
          showBackButtonMob
          showBackButtonTab
          onBackAction={() => navigation.navigate("Rental Passport")}
          {...this.props}
        />
        <ScrollView>
          <View style={{ flex: 1 }}>
            <View style={{ flexDirection: "column" }}>
              {layoutType === "web" && (
                <View
                  style={{
                    flexDirection: "column",
                    width: "100%",
                    alignItems: "center",
                    marginBottom: 12,
                  }}
                >
                  <View style={{ width: 1130, paddingVertical: 10 }}>
                    <LeftPanal>
                      <TouchableOpacity
                        onPress={() => {
                          navigation.navigate("Rental Passport");
                        }}
                      >
                        <BackTextWrapper>
                          <Entypo
                            name="chevron-left"
                            size={18}
                            color="#000"
                            // style={{ verticalAlign: "bottom" }}
                          />{" "}
                          Back
                        </BackTextWrapper>
                      </TouchableOpacity>
                    </LeftPanal>
                  </View>
                </View>
              )}
              {!this.state.showNodata ? (
                <FormControls
                  style={{
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.09,
                    shadowRadius: 6.68,
                    elevation: 11,
                  }}
                >
                  <Container>
                    <View
                      style={{
                        width: "100%",
                        paddingVertical: 15,
                      }}
                    >
                      <Text
                        style={{
                          color: "black",
                          fontWeight: "bold",
                          fontSize: 20,
                          marginBottom: 24,
                        }}
                      >
                        Report an issue
                      </Text>

                      <FormContainer>
                        <View
                          style={{
                            flexGrow: layoutType !== "phone" ? 1 : 0,
                            marginBottom: 16,
                            width: layoutType !== "phone" ? "auto" : "100%",
                          }}
                        >
                          <Text
                            style={{ fontWeight: "bold", marginBottom: 10 }}
                          >
                            Report title
                          </Text>
                          <View
                            style={{
                              width: layoutType !== "phone" ? "100%" : "100%",
                              height: 40,
                              borderColor: "#b2b2b2",
                              borderWidth: 1,
                              borderRadius: 5,
                            }}
                          >
                            <TextInput
                              style={{
                                width: "100%",
                                height: "100%",
                                padding: 10,
                              }}
                              maxLength={40}
                              onChangeText={(value) => {
                                this.setState({ title: value });
                              }}
                            />
                          </View>
                        </View>
                        <View
                          style={{
                            flexGrow: layoutType !== "phone" ? 1 : 0,
                            marginLeft: layoutType !== "phone" ? 16 : 0,
                            marginBottom: 16,
                            width: layoutType !== "phone" ? "auto" : "100%",
                          }}
                        >
                          <Text
                            style={{ fontWeight: "bold", marginBottom: 10 }}
                          >
                            Date Opened
                          </Text>
                          <View
                            style={{
                              width: layoutType !== "phone" ? "100%" : "100%",
                              height: 40,
                              borderColor: "#b2b2b2",
                              // borderWidth: 1,
                              borderRadius: 5,
                            }}
                          >
                            <DatePickerComponent
                              onUpdateInput={(date, property) => {
                                this.dateUpdateInput(date, property);
                              }}
                            />
                          </View>
                        </View>
                      </FormContainer>
                      <View style={{ width: "100%" }}>
                        <View>
                          <Text
                            style={{ fontWeight: "bold", marginBottom: 10 }}
                          >
                            Describe your issue here
                          </Text>
                          <View
                            style={{
                              width: "100%",
                              height: 150,
                              borderColor: "#b2b2b2",
                              borderWidth: 1,
                              borderRadius: 5,
                              position: "relative",
                            }}
                          >
                            <TextInput
                              style={{
                                width: "100%",
                                height: "100%",
                                padding: 10,
                              }}
                              maxLength={400}
                              multiline
                              onChangeText={(value) => {
                                this.setState({ descriptionText: value });
                              }}
                              // placeholder="0/200 character limit"
                            />
                            <Text
                              style={{
                                position: "absolute",
                                bottom: 5,
                                right: 2,
                              }}
                            >
                              {" "}
                              {this.state.descriptionText.length}/400 character
                              limit{" "}
                            </Text>
                          </View>
                        </View>
                        <View style={{ marginTop: 10, marginBottom: 10 }}>
                          <Title>Issue Photo: </Title>
                          <FilePicker
                            fileName={this.state.fileName}
                            onFileSelect={(val) => {
                              this.setState({
                                issueImage: val,
                                fileName: val.name,
                              });
                            }}
                          />
                        </View>
                      </View>
                      <View
                        style={{
                          width: layoutType !== "phone" ? 300 : "100%",
                          alignSelf:
                            layoutType !== "phone" ? "flex-end" : "center",
                          marginTop: 16,
                        }}
                      >
                        <WideButton
                          onPress={() => this._logError()}
                          width="100%"
                          childText={"Submit report"}
                        />
                      </View>
                    </View>
                  </Container>
                </FormControls>
              ) : (
                <NoDataNotification
                  title="Sorry, your property is not associated with any buyer"
                  description=""
                  ctaText="search for properties"
                  onPress={() => {
                    this.props.navigation.navigate(
                      "tlyfe - powered by OpenBrix"
                    );
                    this.setState({ showNodata: false });
                  }}
                />
              )}
            </View>
            {layoutType !== "phone" ? <Footer {...this.props} /> : null}
          </View>
        </ScrollView>
      </SafeAreaView>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.authReducer.loginLoading,
  loginStatus: state.authReducer.loginStatus,
  profile: state.authReducer.profile,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logError,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ReportIssue);
