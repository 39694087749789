import React, { useState } from "react";
import { SafeAreaView, View } from "react-native";
import {
  Background,
  BannerImage1,
  BannerImage2,
  BannerImage3,
  BannerText1,
  BannerText2,
  Container,
  IntroWrapper,
} from "./landingPageTlyfeObx.style";

import Header from "../../commonComponents/headerComponent/headerComponent";

import { LinearGradient } from "expo-linear-gradient";
import getPlatformType from "../../helpers/getPlatform";
import Footer from "../../commonComponents/footerComponent/customFooter";
import IntroScreenCard from "./introScreenCard";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { ContainerScrollView } from "../../helpers/appStyles";

const layoutType = getPlatformType();

function LandingPageTlyfeObx(props) {

  const [email, setEmail] = useState("");
  React.useEffect(() => {
    if (props?.route?.params?.email) {
      setEmail(props?.route?.params?.email);
    }
  }, [props?.route?.params?.email]);
  console.log("emailemailemail", email);
  return (
    <SafeAreaView style={{
      backgroundColor: "#FAFAFA",
      height: "100%",
    }}>
      <Container>
        <Header
          showLogoMob
          showLogoWeb
          showLogoTab
          rightChildLabelTab
          rightChildLabelWeb
          rightChildLabelMob
          showDrawerMob
          showDrawerTab
          {...props}
        />
        <ContainerScrollView>
          <Background
            resize-mode={"cover"}
            source={require("../../assets/images/tlyfBanner.png")}
          >
            <LinearGradient
              colors={["rgba(255,181,128,0.9)", "rgba(251,175,230,0.6)"]}
              start={{
                x: 0,
                y: 1,
              }}
              end={{
                x: 1,
                y: 1,
              }}
              style={{
                height: "100%",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  flexWrap: "wrap",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingHorizontal: getPlatformType() === "phone" ? "30%" : "10%",
                }}
              >
                <BannerImage1
                  resizeMode="contain"
                  source={require("../../assets/images/tlyfe/tlyfe.png")}
                />
                <BannerImage2
                  resizeMode="contain"
                  source={require("../../assets/images/X.png")}
                />
                <BannerImage3
                  resizeMode="contain"
                  source={require("../../assets/images/Openflo.png")}
                />
              </View>
              <BannerText1>we put you in control of </BannerText1>
              <BannerText2>property rental </BannerText2>

            </LinearGradient>
          </Background>
          <IntroWrapper
            style={{
              shadowOffset: {
                width: 0,
                height: 2,
              },
              shadowOpacity: 0.09,
              shadowRadius: 6.68,
              elevation: 11,
            }}
          >
            <IntroScreenCard
              navigation={props.navigation}
              title={"Using OpenFlo you will have the have the entire end to end move in process at your fingertips."}
              dataArray={
                [
                  {

                    text: "With the tlyfe connection you will be able to check the details are correct which will smooth out your pre-tenancy process",
                    image: require("../../assets/images/tlyfeImageText.png"),
                    chileimage: require("../../assets/images/tlyfeImageText1.png"),
                    title: "Check your details",
                  },
                  {

                    text: "Receive all your documents directly to your tlyfe app.This will be available to you for future reference.",
                    image: require("../../assets/images/tlyfeImageText.png"),
                    chileimage: require("../../assets/images/tlyfeImageText2.png"),

                    title: "Digital Documentation",
                  },
                  {

                    text: "Digitally sign your tenancy agreement and documents.",
                    image: require("../../assets/images/tlyfeImageText.png"),
                    chileimage: require("../../assets/images/tlyfeImageText3.png"),
                    title: "Digital Signture",
                  },
                ]
              }
            />
          </IntroWrapper>
          <IntroWrapper
            style={{
              shadowOffset: {
                width: 0,
                height: 2,
              },
              shadowOpacity: 0.09,
              shadowRadius: 6.68,
              elevation: 11,
            }}
          >
            <IntroScreenCard
              showButton
              navigation={props.navigation}
              passParams={email}
              headerTitle={"Introducing tlyfe"}
              title={"The only tenant ID and passport that you will ever need!"}
              dataArray={
                [
                  {

                    text: "Providing you with an unlimited and secure storage facility to share docu-mentation directly with your agents, without any data-mining.",
                    image: require("../../assets/images/tlyfeImageText.png"),
                    chileimage: require("../../assets/images/tlyfeImageText2.png"),
                    title: "Document Storage",
                  },
                  {

                    text: "A Government Grade verified and secure Digital ID owned by you the Consumer where you can pre-reference and qualify yourselves. R2R and KYC/AML",
                    image: require("../../assets/images/tlyfeImageText.png"),
                    chileimage: require("../../assets/images/tlyfeImageText1.png"),

                    title: "Verified Digital ID",
                  },
                  {

                    text: "Gives you the tenat a lifetime record of validated rental payments and access to increase your credit scores when paying your rent.",
                    image: require("../../assets/images/tlyfeImageText.png"),
                    chileimage: require("../../assets/images/tlyfeImageText3.png"),
                    title: "Rental Passport",
                  },
                ]
              }
            />
          </IntroWrapper>
          <Footer {...props} />
        </ContainerScrollView>

      </Container>
    </SafeAreaView>
  );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {},
    dispatch,
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LandingPageTlyfeObx);
