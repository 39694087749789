import React from "react";
import styled from "styled-components/native";
import variables from "../variables.json"

/*
Example
<CardBox hasShadow={true} hasBorderRadius={true}>
    Some data
</CardBox>

*/

let CardBox;

CardBox = styled.View`
  box-shadow: ${props => (props.hasShadow ? `1px 3px 8px rgba(0, 0, 0, 0.15)` : "none")};
  border-radius: ${props => props.hasBorderRadius ? `8px`: `0`};
  padding: 18px;
  border-width:1px;
  border-color:#dadada;
  background-color: ${props => props.backColor ?`${props.backColor}`:`${variables.white}`};
`;

export {CardBox}