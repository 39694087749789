import { Alert, Platform } from "react-native";
import { types } from "../../actionTypes";
import {
  SERVER_URL,
  _post,
  URL,
  _get,
  _put,
  _delete,
} from "../../../helpers/connectionHelpers";

export const showToaster = (toastertype, msg) => {
  if (toastertype == "error") {
    return (dispatch) => {
      dispatch({ type: types.SHOW_ALERT, text: msg, alertType: "error" });
    };
  } else {
    return (dispatch) => {
      dispatch({
        type: types.SHOW_ALERT,
        alertType: "success",
        text: msg,
      });
    };
  }
};
export const showToasterPopUp = () => {
  return (dispatch) => {
    dispatch({ type: types.SHOW_TOASTER_POPUP });
  };
};
