import React from "react";
import { TouchableOpacity, Text, ActivityIndicator, View } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { COLORS } from "../constants/colorPallete";
export default class WideButton extends React.Component {
  render() {
    const {
      stripetextColor,
      backgroundColor,
      childText,
      onPress,
      height,
      loadingState,
      borderColor,
      borderWidth,
      childColor,
      width,
      whiteButton,
      borderRadius,
      gradBorderRadius,
      gradBorderWidth,
      buttonType,
      gradiant,
      bgcolor,
      isDisabled,
      id,
      icon,
      isLGreen,
      textSize,
      alignSelf,
      ricon,
      isRed,
      type,
      isRaj,
      testID,
      top
    } = this.props;
    console.log("isLGreen", isLGreen)
    console.log("bgcolor", bgcolor)

    return buttonType=="stripe"?(
    <TouchableOpacity
        disabled={loadingState}
        onPress={onPress}
        id={id}
        testID={testID||id}
        type={type}
        style={{
          height: height ? height : 40,
          width: width ? width : "95%",
          borderRadius: borderRadius ? borderRadius : 40,
          borderColor: backgroundColor ? backgroundColor : null,
          borderWidth: borderWidth ? borderWidth : 1,
          alignSelf:alignSelf? alignSelf:"flex-start",
          marginTop: top?top:0
        }}
      >
          <View
            style={{
              height: "100%",
              width: "100%",
              borderRadius: gradBorderRadius ? gradBorderRadius : 30,
              alignItems: "center",
              justifyContent: "center",
              flexDirection:'row'
            }}
          >
            {loadingState ? (
              <ActivityIndicator color="white" />
            ) : (
              <>
              {icon?icon:null}
              <Text
                style={{
                  color:stripetextColor?stripetextColor: backgroundColor,
                  fontSize: textSize? textSize:16,
                }}
                id={id}
              >
                {childText}
              </Text>
              {ricon?ricon:null}
              </>
            )}
          </View>
      </TouchableOpacity>)
    :(
      <TouchableOpacity
          disabled={loadingState}
        onPress={onPress}
        type={type}
        testID={testID}
        style={{
          backgroundColor: backgroundColor ? backgroundColor : "transparent",
          height: height ? height : 40,
          width: width ? width : "95%",
          borderRadius: borderRadius ? borderRadius : 40,
          borderColor: borderColor ? borderColor : null,
          borderWidth: borderWidth ? borderWidth : null,
          alignSelf:alignSelf? alignSelf:"inherit",
          marginTop: top?top:0
        }}
        id={id}
      >
        <LinearGradient
          colors={
          isDisabled? ['#dadada','#dadada']:
           isLGreen?[COLORS.gradientColor3, COLORS.gradientColor4]:isRed?["#FF5A51","#FF5A51"]: gradiant
              ? ['#FBB1E6',COLORS.orange]
              : bgcolor?[bgcolor,bgcolor ]: [COLORS.gradientColor1, COLORS.gradientColor2]
          }
          style={[{
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            height: "100%",
            width: "100%",
            borderRadius: gradBorderRadius ? gradBorderRadius : 20,
            padding: gradBorderWidth ? gradBorderWidth : 2,
          },this.props?.style]}
          start={[0.4, 0.5]}
          end={[1, 1]}
        >
          <View
            style={{
              height: "100%",
              width: "100%",
              backgroundColor: whiteButton ? "white" : "transparent",
              borderRadius: gradBorderRadius ? gradBorderRadius : 30,
              alignItems: "center",
              justifyContent: "center",
              flexDirection:'row'
            }}
          >
            {loadingState ? (
              <ActivityIndicator color="white" />
            ) : (
              <>
              {icon?icon:null}
              <Text
                id={id}
                style={{
                  color: childColor
                    ? childColor
                    : whiteButton
                    ? "black"
                    : "white",
                  fontSize: textSize? textSize:14,
                  fontFamily:isRaj?"Raj":"Lato"
                }}
              >
                {childText}
              </Text>
              {ricon?ricon:null}
              </>
            )}
          </View>
        </LinearGradient>
      </TouchableOpacity>
    );
  }
}
