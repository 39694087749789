import { types } from "../../actionTypes";

const mobileReducer = (
  state = {
    isCreditBuilderPayment:false,
    isInappWebView:false
  },
  action
) => {
  switch (action.type) {
    case types.SET_CREDIT_PAYMENT: {
      return {
        ...state,
        isCreditBuilderPayment: action?.payload,
      };
    }
    case types.SET_INAPP_WEBVIEW: {
      return {
        ...state,
        isInappWebView: action?.payload,
      };
    }
    
    default:
      return state;
  }
};

export default mobileReducer;
