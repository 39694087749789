import React from "react";
import styled from "styled-components/native";
import getPlatformType from "../../helpers/getPlatform";

const layoutType = getPlatformType();

let Container, Heading, HeadingText, FormBox, ButtonBox, TextBox;

Container = styled.View`
  width: 1130px;
  margin: 0 auto;
  display:flex;
  justify-content: center;
  align-items: center;
`;
Heading = styled.View`
width: 100%;
`;
FormBox = styled.View`
    width: 550px;
    height: 400px;
    justify-content: center;
    align-items: center;
    display: flex;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    box-shadow: rgba(128, 128, 128, 0.4) 3px 2px 3px;
    padding: 20px;
    background-color: #ffffff;
    margin-top:20px;
    margin-bottom:30px;

`;

ButtonBox = styled.View`
    width: 100%;
    margin-top: 20px;
    align-items:center;
    justify-content: center;
`;
TextBox = styled.View`
width: 100%;
`;
HeadingText = styled.Text`
  font-weight: 600;
  font-size: 23px;
  margin-bottom: 30px;
`;
if (layoutType == "phone") {
  Container = styled.View`
    width: 342px;
    margin: 0 auto;
    display:flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  
  `;
  FormBox = styled.View`
    width: 100%;
    height: 400px;
    justify-content: center;
    align-items: center;
    display: flex;
    border-top-right-radius: 50px;
    border-bottom-left-radius: 50px;
    box-shadow: rgba(128, 128, 128, 0.4) 3px 2px 3px;
    padding: 20px;
    background-color: #ffffff;
    margin-top:15px;
    margin-bottom:15px;

`;
  
} else if (layoutType == "web") {
} else {
  Container = styled.View`
    width: 944px;
    margin: 0 auto;
    display:flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-top: 20px;
    min-height:70vh;
  `;
  
  
}

export {
  Container,
  Heading, 
  HeadingText,
  FormBox,
  ButtonBox,
  TextBox
};
