import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { ODSText } from '../../atoms/ODSText';
import { ODSIcon } from '../../atoms/ODSIcon';
import { getTokens } from '../../../design-tokens/color-tokens';

const ODSdocumentCard = ({
  title,
  onPress = () => {},
  id,
  description,
  is_seen,
  style,
  isDelete,
  onDeletePress,
  hideRightIcon,
}) => {
  const themeObj = getTokens('light').colors;

  return (
    <TouchableOpacity key={id} style={[styles(themeObj).containerStyle, style]} onPress={onPress}>
      <View style={{ flexDirection: 'column', flex: 0.1, alignItems: 'center' }}>
        <ODSIcon color={themeObj.main.blue[400]} icon={'TBDownload'} size={26} />
      </View>
      <View
        style={{
          flexDirection: 'column',
          flex: 0.7,
          marginHorizontal: 6,
          justifyContent: 'center',
        }}>
        <ODSText color="black" type={'button'}>
          {title}
        </ODSText>
        {description ? (
          <ODSText marginTop={8} color="black" type={'sm'}>
            {description}
          </ODSText>
        ) : null}
      </View>

      {hideRightIcon ? (
        <View style={{ flex: 0.2 }}></View>
      ) : (
        <TouchableOpacity
          onPress={onDeletePress}
          disabled={isDelete ? false : true}
          style={{ flexDirection: 'column', flex: 0.2, alignItems: 'center' }}>
          <ODSIcon
            color={
              isDelete
                ? themeObj.feedback.error['200']
                : is_seen
                ? themeObj.feedback['main']
                : themeObj.feedback.error['200']
            }
            icon={isDelete ? 'TB-Trash' : 'TBDoc'}
            size={26}
          />
          <ODSText marginTop={8} color="black" type={'sm'}>
            {isDelete ? 'Delete' : is_seen ? 'Read' : 'Unread'}
          </ODSText>
        </TouchableOpacity>
      )}
    </TouchableOpacity>
  );
};

const styles = (theme) =>
  StyleSheet.create({
    containerStyle: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-around',
      width: '100%',
      borderRadius: 12,
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 1,
      },
      shadowOpacity: 0.3,
      shadowRadius: 1.65,
      elevation: 4,
      backgroundColor: theme.neutral['white'],
      padding: 20,
      borderColor: '#D0D6DD',
      borderWidth: 1,
    },
  });

export default ODSdocumentCard;
