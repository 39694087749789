export const FilterConfigObject = {
  // accomodation_type: [
  //   {
  //     key: "all",
  //     title: "All",
  //     selected: true,
  //     name: "hexagon-logo",
  //     image: require("../../../assets/img/icons/hexagon-logo.png"),
  //     image_grey: require("../../../assets/img/icons/hexagon-logo-grey.png"),
  //     image_color: require("../../../assets/img/icons/hexagon-logo-color.png"),
  //   },
  //   {
  //     key: "detached",
  //     title: "Detached",
  //     selected: false,
  //     name: "detached",
  //     image: require("../../../assets/img/icons/semidetached.png"),
  //     image_grey: require("../../../assets/img/icons/semidetached-grey.png"),
  //     image_color: require("../../../assets/img/icons/semidetached-color.png"),
  //   },
  //   {
  //     key: "semidetached",
  //     title: "Semi-Detached",
  //     selected: false,
  //     name: "semi-detached",
  //     image: require("../../../assets/img/icons/semi-detached.png"),
  //     image_grey: require("../../../assets/img/icons/semi-detached-grey.png"),
  //     image_color: require("../../../assets/img/icons/semi-detached-color.png"),
  //   },
  //   {
  //     key: "terraced",
  //     title: "Terraced",
  //     selected: false,
  //     name: "terraced",
  //     image: require("../../../assets/img/icons/terraced.png"),
  //     image_grey: require("../../../assets/img/icons/terraced-grey.png"),
  //     image_color: require("../../../assets/img/icons/terraced-color.png"),
  //   },
  //   {
  //     key: "flats",
  //     title: "Flats",
  //     selected: false,
  //     name: "flat",
  //     image: require("../../../assets/img/icons/flat.png"),
  //     image_grey: require("../../../assets/img/icons/flat-grey.png"),
  //     image_color: require("../../../assets/img/icons/flat-color.png"),
  //   },
  //   {
  //     key: "farmsland",
  //     title: "Farms/land",
  //     selected: false,
  //     name: "farm",
  //     image: require("../../../assets/img/icons/farm.png"),
  //     image_grey: require("../../../assets/img/icons/farm-grey.png"),
  //     image_color: require("../../../assets/img/icons/farm-color.png"),
  //   },
  //   {
  //     key: "bungalow",
  //     title: "Bungalow",
  //     selected: false,
  //     name: "bungalow",
  //     image: require("../../../assets/img/icons/bungalow.png"),
  //     image_grey: require("../../../assets/img/icons/bungalow-grey.png"),
  //     image_color: require("../../../assets/img/icons/bungalow-color.png"),
  //   },
  //   {
  //     key: "parkhome",
  //     title: "Park Home",
  //     selected: false,
  //     name: "park-home",
  //     image: require("../../../assets/img/icons/park-home.png"),
  //     image_grey: require("../../../assets/img/icons/park-home-grey.png"),
  //     image_color: require("../../../assets/img/icons/park-home-color.png"),
  //   },
  // ],
  distances: [
    {
      value: "0",
      label: "This area only",
    },
    {
      value: "0.4",
      label: "1/4 mile",
    },
    {
      value: "0.8",
      label: "1/2 mile",
    },
    {
      value: "1.6",
      label: "1 mile",
    },
    {
      value: "8",
      label: "5 mile",
    },
    {
      value: "16",
      label: "10 mile",
    },
    {
      value: "34",
      label: "15 mile",
    },
    {
      value: "32",
      label: "20 mile",
    },
    {
      value: "49",
      label: "30 mile",
    },
    {
      value: "65",
      label: "40 mile",
    },
  ],
  min_price_rental: [
    { label: "No Min", value: "0" },
    { label: "£100 pcm", value: "100" },
    { label: "£200 pcm", value: "200" },
    { label: "£300 pcm", value: "300" },
    { label: "£400 pcm", value: "400" },
    { label: "£500 pcm", value: "500" },
    { label: "£600 pcm", value: "600" },
    { label: "£700 pcm", value: "700" },
    { label: "£800 pcm", value: "800" },
    { label: "£900 pcm", value: "900" },
    { label: "£1,000 pcm", value: "1000" },
    { label: "£1,250 pcm", value: "1250" },
    { label: "£1,500 pcm", value: "1500" },
    { label: "£1,750 pcm", value: "1750" },
    { label: "£2,000 pcm", value: "2000" },
    { label: "£2,250 pcm", value: "2250" },
    { label: "£2,500 pcm", value: "2500" },
    { label: "£2,750 pcm", value: "2750" },
    { label: "£3,000 pcm", value: "3000" },
    { label: "£3,250 pcm", value: "3250" },
    { label: "£3,500 pcm", value: "3500" },
    { label: "£3,750 pcm", value: "3750" },
    { label: "£4,000 pcm", value: "4000" },
    { label: "£4,250 pcm", value: "4250" },
    { label: "£4,500 pcm", value: "4500" },
    { label: "£4,750 pcm", value: "4750" },
    { label: "£5,000 pcm", value: "5000" },
    { label: "£5,500 pcm", value: "5500" },
    { label: "£6,000 pcm", value: "6000" },
    { label: "£6,500 pcm", value: "6500" },
    { label: "£7,000 pcm", value: "7000" },
    { label: "£7,500 pcm", value: "7500" },
    { label: "£8,000 pcm", value: "8000" },
    { label: "£8,500 pcm", value: "8500" },
    { label: "£9,000 pcm", value: "9000" },
    { label: "£9,500 pcm", value: "9500" },
    { label: "£10,000 pcm", value: "10000" },
    { label: "£12,500 pcm", value: "12500" },
    { label: "£15,000 pcm", value: "15000" },
    { label: "£17,500 pcm", value: "17500" },
    { label: "£20,000 pcm", value: "20000" },
  ],
  sort_by: [
    { label: "Distance", value: "&sortBy=distance&order=ASC" },
    { label: "Newest to Oldest", value: "&sortBy=available_from&order=DESC" },
    { label: "Highest Price", value: "&sortBy=property_price&order=DESC" },
    { label: "Lowest Price", value: "&sortBy=property_price&order=ASC" },
  ],
  max_price_rental: [
    { label: "No Max", value: "0" },
    { label: "£100 pcm", value: "100" },
    { label: "£200 pcm", value: "200" },
    { label: "£300 pcm", value: "300" },
    { label: "£400 pcm", value: "400" },
    { label: "£500 pcm", value: "500" },
    { label: "£600 pcm", value: "600" },
    { label: "£700 pcm", value: "700" },
    { label: "£800 pcm", value: "800" },
    { label: "£900 pcm", value: "900" },
    { label: "£1,000 pcm", value: "1000" },
    { label: "£1,250 pcm", value: "1250" },
    { label: "£1,500 pcm", value: "1500" },
    { label: "£1,750 pcm", value: "1750" },
    { label: "£2,000 pcm", value: "2000" },
    { label: "£2,250 pcm", value: "2250" },
    { label: "£2,500 pcm", value: "2500" },
    { label: "£2,750 pcm", value: "2750" },
    { label: "£3,000 pcm", value: "3000" },
    { label: "£3,250 pcm", value: "3250" },
    { label: "£3,500 pcm", value: "3500" },
    { label: "£3,750 pcm", value: "3750" },
    { label: "£4,000 pcm", value: "4000" },
    { label: "£4,250 pcm", value: "4250" },
    { label: "£4,500 pcm", value: "4500" },
    { label: "£4,750 pcm", value: "4750" },
    { label: "£5,000 pcm", value: "5000" },
    { label: "£5,500 pcm", value: "5500" },
    { label: "£6,000 pcm", value: "6000" },
    { label: "£6,500 pcm", value: "6500" },
    { label: "£7,000 pcm", value: "7000" },
    { label: "£7,500 pcm", value: "7500" },
    { label: "£8,000 pcm", value: "8000" },
    { label: "£8,500 pcm", value: "8500" },
    { label: "£9,000 pcm", value: "9000" },
    { label: "£9,500 pcm", value: "9500" },
    { label: "£10,000 pcm", value: "10000" },
    { label: "£12,500 pcm", value: "12500" },
    { label: "£15,000 pcm", value: "15000" },
    { label: "£17,500 pcm", value: "17500" },
    { label: "£20,000 pcm", value: "20000" },
    { label: "£25,500 pcm +", value: "25500" },
  ],

  prices_rental: [
    { label: "No Min / Max", value: "0" },
    { label: "£100 pcm", value: "100" },
    { label: "£200 pcm", value: "200" },
    { label: "£300 pcm", value: "300" },
    { label: "£400 pcm", value: "400" },
    { label: "£500 pcm", value: "500" },
    { label: "£600 pcm", value: "600" },
    { label: "£700 pcm", value: "700" },
    { label: "£800 pcm", value: "800" },
    { label: "£900 pcm", value: "900" },
    { label: "£1,000 pcm", value: "1000" },
    { label: "£1,250 pcm", value: "1250" },
    { label: "£1,500 pcm", value: "1500" },
    { label: "£1,750 pcm", value: "1750" },
    { label: "£2,000 pcm", value: "2000" },
    { label: "£2,250 pcm", value: "2250" },
    { label: "£2,500 pcm", value: "2500" },
    { label: "£2,750 pcm", value: "2750" },
    { label: "£3,000 pcm", value: "3000" },
    { label: "£3,250 pcm", value: "3250" },
    { label: "£3,500 pcm", value: "3500" },
    { label: "£3,750 pcm", value: "3750" },
    { label: "£4,000 pcm", value: "4000" },
    { label: "£4,250 pcm", value: "4250" },
    { label: "£4,500 pcm", value: "4500" },
    { label: "£4,750 pcm", value: "4750" },
    { label: "£5,000 pcm", value: "5000" },
    { label: "£5,500 pcm", value: "5500" },
    { label: "£6,000 pcm", value: "6000" },
    { label: "£6,500 pcm", value: "6500" },
    { label: "£7,000 pcm", value: "7000" },
    { label: "£7,500 pcm", value: "7500" },
    { label: "£8,000 pcm", value: "8000" },
    { label: "£8,500 pcm", value: "8500" },
    { label: "£9,000 pcm", value: "9000" },
    { label: "£9,500 pcm", value: "9500" },
    { label: "£10,000 pcm", value: "10000" },
    { label: "£12,500 pcm", value: "12500" },
    { label: "£15,000 pcm", value: "15000" },
    { label: "£17,500 pcm", value: "17500" },
    { label: "£20,000 pcm", value: "20000" },
    { label: "£25,500 pcm +", value: "25500" },
  ],
  min_price_sale: [
    { label: "No Min", value: "0" },
    { label: "£10,000", value: "10000" },
    { label: "£20,000", value: "20000" },
    { label: "£30,000", value: "30000" },
    { label: "£40,000", value: "40000" },
    { label: "£50,000", value: "50000" },
    { label: "£60,000", value: "60000" },
    { label: "£70,000", value: "70000" },
    { label: "£80,000", value: "80000" },
    { label: "£90,000", value: "90000" },
    { label: "£100,000", value: "100000" },
    { label: "£110,000", value: "110000" },
    { label: "£120,000", value: "120000" },
    { label: "£130,000", value: "130000" },
    { label: "£140,000", value: "140000" },
    { label: "£150,000", value: "150000" },
    { label: "£160,000", value: "160000" },
    { label: "£170,000", value: "170000" },
    { label: "£180,000", value: "180000" },
    { label: "£190,000", value: "190000" },
    { label: "£200,000", value: "200000" },
    { label: "£210,000", value: "210000" },
    { label: "£220,000", value: "220000" },
    { label: "£230,000", value: "230000" },
    { label: "£240,000", value: "240000" },
    { label: "£250,000", value: "250000" },
    { label: "£275,000", value: "275000" },
    { label: "£300,000", value: "300000" },
    { label: "£325,000", value: "325000" },
    { label: "£350,000", value: "350000" },
    { label: "£375,000", value: "375000" },
    { label: "£400,000", value: "400000" },
    { label: "£425,000", value: "425000" },
    { label: "£450,000", value: "450000" },
    { label: "£475,000", value: "475000" },
    { label: "£500,000", value: "500000" },
    { label: "£550,000", value: "550000" },
    { label: "£600,000", value: "600000" },
    { label: "£650,000", value: "650000" },
    { label: "£700,000", value: "700000" },
    { label: "£750,000", value: "750000" },
    { label: "£800,000", value: "800000" },
    { label: "£850,000", value: "850000" },
    { label: "£900,000", value: "900000" },
    { label: "£950,000", value: "950000" },
    { label: "£1,000,000", value: "1000000" },
    { label: "£1,100,000", value: "1100000" },
    { label: "£1,200,000", value: "1200000" },
    { label: "£1,300,000", value: "1300000" },
    { label: "£1,400,000", value: "1400000" },
    { label: "£1,500,000", value: "1500000" },
    { label: "£1,600,000", value: "1600000" },
    { label: "£1,700,000", value: "1700000" },
    { label: "£1,800,000", value: "1800000" },
    { label: "£1,900,000", value: "1900000" },
    { label: "£2,000,000", value: "2000000" },
    { label: "£2,100,000", value: "2100000" },
    { label: "£2,200,000", value: "2200000" },
    { label: "£2,300,000", value: "2300000" },
    { label: "£2,400,000", value: "2400000" },
    { label: "£2,500,000", value: "2500000" },
    { label: "£2,750,000", value: "2750000" },
    { label: "£3,000,000", value: "3000000" },
    { label: "£3,250,000", value: "3250000" },
    { label: "£3,500,000", value: "3500000" },
    { label: "£3,750,000", value: "3750000" },
    { label: "£4,000,000", value: "4000000" },
    { label: "£4,250,000", value: "4250000" },
    { label: "£4,500,000", value: "4500000" },
    { label: "£4,750,000", value: "4750000" },
    { label: "£5,000,000", value: "5000000" },
    { label: "£5,500,000", value: "5500000" },
    { label: "£6,000,000", value: "6000000" },
    { label: "£6,500,000", value: "6500000" },
    { label: "£7,000,000", value: "7000000" },
    { label: "£7,500,000", value: "7500000" },
    { label: "£8,000,000", value: "8000000" },
    { label: "£8,500,000", value: "8500000" },
    { label: "£9,000,000", value: "9000000" },
    { label: "£9,500,000", value: "9500000" },
    { label: "£10,000,000", value: "10000000" },
    { label: "£12,500,000", value: "12500000" },
  ],
  max_price_sale: [
    { label: "No Max", value: "0" },
    { label: "£10,000", value: "10000" },
    { label: "£20,000", value: "20000" },
    { label: "£30,000", value: "30000" },
    { label: "£40,000", value: "40000" },
    { label: "£50,000", value: "50000" },
    { label: "£60,000", value: "60000" },
    { label: "£70,000", value: "70000" },
    { label: "£80,000", value: "80000" },
    { label: "£90,000", value: "90000" },
    { label: "£100,000", value: "100000" },
    { label: "£110,000", value: "110000" },
    { label: "£120,000", value: "120000" },
    { label: "£130,000", value: "130000" },
    { label: "£140,000", value: "140000" },
    { label: "£150,000", value: "150000" },
    { label: "£160,000", value: "160000" },
    { label: "£170,000", value: "170000" },
    { label: "£180,000", value: "180000" },
    { label: "£190,000", value: "190000" },
    { label: "£200,000", value: "200000" },
    { label: "£210,000", value: "210000" },
    { label: "£220,000", value: "220000" },
    { label: "£230,000", value: "230000" },
    { label: "£240,000", value: "240000" },
    { label: "£250,000", value: "250000" },
    { label: "£275,000", value: "275000" },
    { label: "£300,000", value: "300000" },
    { label: "£325,000", value: "325000" },
    { label: "£350,000", value: "350000" },
    { label: "£375,000", value: "375000" },
    { label: "£400,000", value: "400000" },
    { label: "£425,000", value: "425000" },
    { label: "£450,000", value: "450000" },
    { label: "£475,000", value: "475000" },
    { label: "£500,000", value: "500000" },
    { label: "£550,000", value: "550000" },
    { label: "£600,000", value: "600000" },
    { label: "£650,000", value: "650000" },
    { label: "£700,000", value: "700000" },
    { label: "£750,000", value: "750000" },
    { label: "£800,000", value: "800000" },
    { label: "£850,000", value: "850000" },
    { label: "£900,000", value: "900000" },
    { label: "£950,000", value: "950000" },
    { label: "£1,000,000", value: "1000000" },
    { label: "£1,100,000", value: "1100000" },
    { label: "£1,200,000", value: "1200000" },
    { label: "£1,300,000", value: "1300000" },
    { label: "£1,400,000", value: "1400000" },
    { label: "£1,500,000", value: "1500000" },
    { label: "£1,600,000", value: "1600000" },
    { label: "£1,700,000", value: "1700000" },
    { label: "£1,800,000", value: "1800000" },
    { label: "£1,900,000", value: "1900000" },
    { label: "£2,000,000", value: "2000000" },
    { label: "£2,100,000", value: "2100000" },
    { label: "£2,200,000", value: "2200000" },
    { label: "£2,300,000", value: "2300000" },
    { label: "£2,400,000", value: "2400000" },
    { label: "£2,500,000", value: "2500000" },
    { label: "£2,750,000", value: "2750000" },
    { label: "£3,000,000", value: "3000000" },
    { label: "£3,250,000", value: "3250000" },
    { label: "£3,500,000", value: "3500000" },
    { label: "£3,750,000", value: "3750000" },
    { label: "£4,000,000", value: "4000000" },
    { label: "£4,250,000", value: "4250000" },
    { label: "£4,500,000", value: "4500000" },
    { label: "£4,750,000", value: "4750000" },
    { label: "£5,000,000", value: "5000000" },
    { label: "£5,500,000", value: "5500000" },
    { label: "£6,000,000", value: "6000000" },
    { label: "£6,500,000", value: "6500000" },
    { label: "£7,000,000", value: "7000000" },
    { label: "£7,500,000", value: "7500000" },
    { label: "£8,000,000", value: "8000000" },
    { label: "£8,500,000", value: "8500000" },
    { label: "£9,000,000", value: "9000000" },
    { label: "£9,500,000", value: "9500000" },
    { label: "£10,000,000", value: "10000000" },
    { label: "£12,500,000", value: "12500000" },
    { label: "£15,000,000 +", value: "15000000" },
  ],
  prices_sale: [
    { label: "No Min / Max", value: "0" },
    { label: "£10,000", value: "10000" },
    { label: "£20,000", value: "20000" },
    { label: "£30,000", value: "30000" },
    { label: "£40,000", value: "40000" },
    { label: "£50,000", value: "50000" },
    { label: "£60,000", value: "60000" },
    { label: "£70,000", value: "70000" },
    { label: "£80,000", value: "80000" },
    { label: "£90,000", value: "90000" },
    { label: "£100,000", value: "100000" },
    { label: "£110,000", value: "110000" },
    { label: "£120,000", value: "120000" },
    { label: "£130,000", value: "130000" },
    { label: "£140,000", value: "140000" },
    { label: "£150,000", value: "150000" },
    { label: "£160,000", value: "160000" },
    { label: "£170,000", value: "170000" },
    { label: "£180,000", value: "180000" },
    { label: "£190,000", value: "190000" },
    { label: "£200,000", value: "200000" },
    { label: "£210,000", value: "210000" },
    { label: "£220,000", value: "220000" },
    { label: "£230,000", value: "230000" },
    { label: "£240,000", value: "240000" },
    { label: "£250,000", value: "250000" },
    { label: "£275,000", value: "275000" },
    { label: "£300,000", value: "300000" },
    { label: "£325,000", value: "325000" },
    { label: "£350,000", value: "350000" },
    { label: "£375,000", value: "375000" },
    { label: "£400,000", value: "400000" },
    { label: "£425,000", value: "425000" },
    { label: "£450,000", value: "450000" },
    { label: "£475,000", value: "475000" },
    { label: "£500,000", value: "500000" },
    { label: "£550,000", value: "550000" },
    { label: "£600,000", value: "600000" },
    { label: "£650,000", value: "650000" },
    { label: "£700,000", value: "700000" },
    { label: "£750,000", value: "750000" },
    { label: "£800,000", value: "800000" },
    { label: "£850,000", value: "850000" },
    { label: "£900,000", value: "900000" },
    { label: "£950,000", value: "950000" },
    { label: "£1,000,000", value: "1000000" },
    { label: "£1,100,000", value: "1100000" },
    { label: "£1,200,000", value: "1200000" },
    { label: "£1,300,000", value: "1300000" },
    { label: "£1,400,000", value: "1400000" },
    { label: "£1,500,000", value: "1500000" },
    { label: "£1,600,000", value: "1600000" },
    { label: "£1,700,000", value: "1700000" },
    { label: "£1,800,000", value: "1800000" },
    { label: "£1,900,000", value: "1900000" },
    { label: "£2,000,000", value: "2000000" },
    { label: "£2,100,000", value: "2100000" },
    { label: "£2,200,000", value: "2200000" },
    { label: "£2,300,000", value: "2300000" },
    { label: "£2,400,000", value: "2400000" },
    { label: "£2,500,000", value: "2500000" },
    { label: "£2,750,000", value: "2750000" },
    { label: "£3,000,000", value: "3000000" },
    { label: "£3,250,000", value: "3250000" },
    { label: "£3,500,000", value: "3500000" },
    { label: "£3,750,000", value: "3750000" },
    { label: "£4,000,000", value: "4000000" },
    { label: "£4,250,000", value: "4250000" },
    { label: "£4,500,000", value: "4500000" },
    { label: "£4,750,000", value: "4750000" },
    { label: "£5,000,000", value: "5000000" },
    { label: "£5,500,000", value: "5500000" },
    { label: "£6,000,000", value: "6000000" },
    { label: "£6,500,000", value: "6500000" },
    { label: "£7,000,000", value: "7000000" },
    { label: "£7,500,000", value: "7500000" },
    { label: "£8,000,000", value: "8000000" },
    { label: "£8,500,000", value: "8500000" },
    { label: "£9,000,000", value: "9000000" },
    { label: "£9,500,000", value: "9500000" },
    { label: "£10,000,000", value: "10000000" },
    { label: "£12,500,000", value: "12500000" },
    { label: "£15,000,000 +", value: "15000000" },
  ],
  addedMin: 0,
  addedMax: 30,
  bedroomsMin: 0,
  bedroomsMax: 10,
  bedrooms_min: [
    { label: "No Min", value: "0" },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
  ],
  own_id: [
    { value: "rentRecord", label: "Rent Record" },
    { value: "Documents", label: "Documents" },
  ],
  bedrooms_max: [
    { label: "No Max", value: "0" },
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
    { value: 6, label: "6" },
    { value: 7, label: "7" },
    { value: 8, label: "8" },
    { value: 9, label: "9" },
    { value: 10, label: "10" },
  ],
  furnishingOptions: [
    { value: "", label: "Any (All)" },
    { value: "furnished", label: "Furnished" },
    { value: "part_furnished", label: "Part Furnished" },
    { value: "unfurnished", label: "Unfurnished" },
  ],
  dateListed: [
    {
      value: "7",
      label: "7 days",
    },
    {
      value: "15",
      label: "15 days",
    },
    {
      value: "30",
      label: "30 days",
    },
  ],

  alertOptions: [
    {
      value: "Both",
      text: "Both",
      setAll: true,
      id:'ALEART_BOTH'
    },
    {
      value: "Popup",
      text: "Pop Up",
      id:'ALEART_POP_UP'
    },
    {
      value: "Email",
      text: "Email",
      id:'ALEART_EMAIL'
    },
  ],
  boxCategoriesSale: [
    {
      value: "Shared Ownership",
      text: "Shared Ownership",
    },
    {
      value: "Retirement Homes",
      text: "Retirement Homes",
    },
  ],
  boxCategoriesRent: [
    {
      value: "Student Let",
      text: "Student Let",
    },
    {
      value: "House Share",
      text: "House Share",
    },
  ],
};
