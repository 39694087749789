
const webPath= "./web/"
const tabPath= "./tab/"
const mobilePath= "./mobile/"
export const backgroundTdsArr = [
    {
      webUri: require(webPath+'tdsHome.png'),
      tabUri: require(tabPath+'tdsHomeTab.png'),
      mobileUri: require(mobilePath+'tdsHome.png'),
      webStyle: {
      },
      tabStyle: {
      },
      mobileStyle: {
      },
    },
  ];