import { tdsService } from "./tds.service";
import { tdsConstants } from "./tds.constants";
import { types } from "../../../redux/actionTypes";
import { navigateAction } from "../../../helpers/navigationHelpers";

export const tdsActions = {
  clearSearchDeposit : () => {
    return (dispatch) => {
      dispatch(request());
     
    function request() {
      return { type: tdsConstants.CLEAR_DEPOSIT_REQUEST };
    }
  }
  },
  searchDeposit : (data) => {
    return (dispatch) => {
      dispatch(request());
      tdsService.searchDeposit(data).then((data) => {
        if (data.status) {
          dispatch(success(data?.data));
        } else {
          dispatch(failure(data));
        }
      }).catch((ERR)=>{
        dispatch(failure(ERR));
      });
    };
    function request() {
      return { type: tdsConstants.SEARCH_DEPOSIT_REQUEST };
    }
    function success(data) {
      return { type: tdsConstants.SEARCH_DEPOSIT_SUCCESS, data };
    }
    function failure(error) {
      return { type: tdsConstants.SEARCH_DEPOSIT_FAILURE, error };
    }
  },
 
  getSearchedDeposit:  () => {
    return (dispatch) => {
      dispatch(request());
      tdsService.getSearchedDeposit().then((data) => {
        if (data.status) {
          dispatch(success(data?.data));
        } else {
          dispatch(failure(data));
        }
      }).catch((ERR)=>{
        dispatch(failure(ERR));
      });
    };
    function request() {
      return { type: tdsConstants.GET_SEARCHED_DEPOSIT_REQUEST };
    }
    function success(data) {
      return { type: tdsConstants.GET_SEARCHED_DEPOSIT_SUCCESS, data };
    }
    function failure(error) {
      return { type: tdsConstants.GET_SEARCHED_DEPOSIT_FAILURE, error };
    }
  },

  triggerResetPassword:  () => {
    return (dispatch) => {
      dispatch(request());
      tdsService.triggerResetPassword().then((data) => {
        if (data.status) {
          dispatch(success(data?.data));
          dispatch({
            type: types.SHOW_ALERT,
            alertType: "success",
            text: data?.message? data?.message:"Request completed successfully!" , 
          });
        } else {
          console.log("triggerResetPassword",data)
          dispatch(failure(data));
          try {
            let errors=[];
            let obj=data?.message?.errors;
            Object.keys(obj).forEach(function(key){
              var value = obj[key];
              errors.push(value);
            });
            dispatch({
              type: types.SHOW_ALERT,
              alertType: "error",
              text: errors.toString().split(',').join('\n\n'),
            });
          } catch (error) {
            dispatch({
              type: types.SHOW_ALERT,
              alertType: "error",
              text: data?.message? data?.message:"Something went wrong!" , 
            });
          }
        }
      }).catch((ERR)=>{
        console.log("ERR",ERR)
        dispatch(failure(ERR));
      });
    };
    function request() {
      return { type: tdsConstants.TRIGGER_PASSWORD_RESET_REQUEST };
    }
    function success(data) {
      return { type: tdsConstants.TRIGGER_PASSWORD_RESET_SUCCESS, data };
    }
    function failure(error) {
      return { type: tdsConstants.TRIGGER_PASSWORD_RESET_FAILURE, error };
    }
  },

  getTDSToken: () => {
    return (dispatch) => {
      dispatch(request());
      tdsService.getTDSToken().then((data) => {
        if (data.status) {
          dispatch(success(data?.data));
        } else {
          dispatch(failure(data));
        }
      }).catch((ERR)=>{
        dispatch(failure(ERR));
      })
    };
    function request() {
      return { type: tdsConstants.GET_TDS_TOKEN_REQUEST };
    }
    function success(data) {
      return { type: tdsConstants.GET_TDS_TOKEN_SUCCESS, data };
    }
    function failure(error) {
      return { type: tdsConstants.GET_TDS_TOKEN_FAILURE, error };
    }
  },

  loginToTDS: (payload,isScotland) => {
    return (dispatch) => {
      dispatch(request());
      tdsService.loginToTDS(payload,isScotland).then((data) => {
        if (data?.status) {
          dispatch(success(data?.data));
        } else {
          dispatch(failure(data));
          dispatch({
            type: types.SHOW_ALERT,
            alertType: "error",
            text: "Invalid credentials",
          });
        }
      }).catch((ERR)=>{
        dispatch(failure(ERR));
      });
    };
    function request() {
      return { type: tdsConstants.LOGIN_TO_TDS_REQUEST };
    }
    function success(data) {
      return { type: tdsConstants.LOGIN_TO_TDS_SUCCESS, data };
    }
    function failure(error) {
      return { type: tdsConstants.LOGIN_TO_TDS_FAILURE, error };
    }
  },
  clearTdsToken: ()=>{
    return (dispatch) => {
      dispatch({ type: tdsConstants.LOGIN_TO_TDS_FAILURE, data:true })
    }
  },
  checkUserType: (payload,cb,isSds) => {
    return (dispatch) => {
      dispatch(request());
      tdsService.checkUserType(payload,isSds).then((data) => {
       
        if (data.status) {
          dispatch(success());
          if(cb){
            cb(data)
          }
          console.log("checkUserType",data)
        } else {
          dispatch(failure(data));
          dispatch({
            type: types.SHOW_ALERT,
            alertType: "error",
            text: "Invalid credentials",
          });
        }
      }).catch((ERR)=>{
        dispatch(failure(ERR));
      });
    };
    function request() {
      return { type: tdsConstants.LOGIN_TO_TDS_REQUEST };
    }
    function success(data) {
      return { type: tdsConstants.LOGIN_TO_TDS_SUCCESS, data };
    }
    function failure(error) {
      return { type: tdsConstants.LOGIN_TO_TDS_FAILURE, error };
    }
  },
  logoutToTDS: (navigation) => {
    return (dispatch) => {
      dispatch(request());
      tdsService.logoutToTDS().then((data) => {
        if (data.status) {
          dispatch(success(data?.data));
          navigateAction(navigation, 'tdsLocation');
          dispatch(tdsLocationSelected(null));
        } else {
          dispatch(failure(data));
          dispatch({
            type: types.SHOW_ALERT,
            alertType: "failure",
            text: "Invalid credentials",
          });
        }
      }).catch((ERR)=>{
        dispatch(failure(ERR));
      });
    };
    function request() {
      return { type: tdsConstants.LOGOUT_TO_TDS_REQUEST };
    }
    function success(data) {
      return { type: tdsConstants.LOGOUT_TO_TDS_SUCCESS, data };
    }
    function failure(error) {
      return { type: tdsConstants.LOGOUT_TO_TDS_FAILURE, error };
    }
  },

  updateMarketingPreferences: (payload) => {
    return (dispatch) => {
      dispatch(request());
      tdsService.updateMarketingPreferences(payload).then((data) => {
        if (data.status) {
          dispatch(success(data?.data));
          dispatch({
            type: types.SHOW_ALERT,
            alertType: "success",
            text: "Updated Successfully",
          });
        } else {
          dispatch(failure(data));
          let errors=[];
          let obj=data?.message?.errors;
          Object.keys(obj).forEach(function(key){
            var value = obj[key];
            errors.push(value);
          });
          dispatch({
            type: types.SHOW_ALERT,
            alertType: "error",
            text: errors.toString().split(',').join('\n\n'),
          });
        }
      }).catch((ERR)=>{
        dispatch(failure(ERR));
      });
    };
    function request() {
      return { type: tdsConstants.UPDATE_MARKETING_PREFERENCES_REQUEST };
    }
    function success(data) {
      return { type: tdsConstants.UPDATE_MARKETING_PREFERENCES_SUCCESS, data };
    }
    function failure(error) {
      return { type: tdsConstants.UPDATE_MARKETING_PREFERENCES_FAILURE, error };
    }
  },

  updateLeadPreferences: (payload) => {
    return (dispatch) => {
      dispatch(request());
      tdsService.updateLeadPreferences(payload).then((data) => {
        if (data.status) {
          dispatch(success(data?.data));
          dispatch({
            type: types.SHOW_ALERT,
            alertType: "success",
            text: "Updated Successfully",
          });
        } else {
          dispatch(failure(data));
          let errors=[];
          let obj=data?.message?.errors;
          Object.keys(obj).forEach(function(key){
            var value = obj[key];
            errors.push(value);
          });
          dispatch({
            type: types.SHOW_ALERT,
            alertType: "error",
            text: errors.toString().split(',').join('\n\n'),
          });
        }
      }).catch((ERR)=>{
        dispatch(failure(ERR));
      });
    };
    function request() {
      return { type: tdsConstants.UPDATE_LEAD_PREFERENCES_REQUEST };
    }
    function success(data) {
      return { type: tdsConstants.UPDATE_LEAD_PREFERENCES_SUCCESS, data };
    }
    function failure(error) {
      return { type: tdsConstants.UPDATE_LEAD_PREFERENCES_FAILURE, error };
    }
  },

  downloadTenancyCertificate: (dpcId) => {
    return (dispatch) => {
      dispatch(request());
      tdsService.downloadTenancyCertificate(dpcId).then((data) => {
        if (data.status) {
           window.open(data?.data, "_blank")
          dispatch(success(data?.data));
        } else {
          dispatch(failure(data));
        }
      }).catch((ERR)=>{
        dispatch(failure(ERR));
      });
    };
    function request() {
      return { type: tdsConstants.DOWNLOAD_DEPOSIT_CERTIFICATE_REQUEST };
    }
    function success(data) {
      return { type: tdsConstants.DOWNLOAD_DEPOSIT_CERTIFICATE_SUCCESS, data };
    }
    function failure(error) {
      return { type: tdsConstants.DOWNLOAD_DEPOSIT_CERTIFICATE_FAILURE, error };
    }
  },

  getTDSAccount: () => {
    return (dispatch) => {
      dispatch(request());
      tdsService.getTDSAccount().then((data) => {
        if (data.status) {
          dispatch(success(data?.data));
        } else {
          dispatch(failure(data));
        }
      }).catch((ERR)=>{
        dispatch(failure(ERR));
      });
    };
    function request() {
      return { type: tdsConstants.GET_TDS_ACCOUNT_REQUEST };
    }
    function success(data) {
      return { type: tdsConstants.GET_TDS_ACCOUNT_SUCCESS, data };
    }
    function failure(error) {
      return { type: tdsConstants.GET_TDS_ACCOUNT_FAILURE, error };
    }
  },

  updateBankAccount: (payload) => {
    return (dispatch) => {
      dispatch(request());
      tdsService.updateBankAccount(payload).then((data) => {
        console.log("updateBankAccount",data)
        if (data.status) {
          dispatch(success(data?.data));
          dispatch({
            type: types.SHOW_ALERT,
            alertType: "success",
            text: "Updated Successfully",
          });
        } else {
          dispatch(failure(data));
          let errors=[];
          try {
            let obj=JSON.parse(data?.message);
            Object.keys(obj).forEach(function(key){
              var value = obj[key];
              errors.push(value);
            });
            dispatch({
              type: types.SHOW_ALERT,
              alertType: "error",
              text: message,
            });
          } catch (error) {
            dispatch({
              type: types.SHOW_ALERT,
              alertType: "error",
              text:data?.message? data?.message:"Something went wrong!",
            });
          }
         
        }
      }).catch((ERR)=>{
        dispatch(failure(ERR));
      });
    };
    function request() {
      return { type: tdsConstants.UPDATE_BANK_ACCOUNT_REQUEST };
    }
    function success(data) {
      return { type: tdsConstants.UPDATE_BANK_ACCOUNT_SUCCESS, data };
    }
    function failure(error) {
      return { type: tdsConstants.UPDATE_BANK_ACCOUNT_FAILURE, error };
    }
  },

  updateInternationalBankAccount: (payload) => {
    return (dispatch) => {
      dispatch(request());
      tdsService.updateInternationalBankAccount(payload).then((data) => {
        if (data.status) {
          dispatch(success(data?.data));
          dispatch({
            type: types.SHOW_ALERT,
            alertType: "success",
            text: "Updated Succesfully",
          });
        } else {
          dispatch(failure(data));
          try {
            let errors=[];
            let obj=JSON.parse(data?.message);
            Object.keys(obj).forEach(function(key){
              var value = obj[key];
              errors.push(value);
            });
            dispatch({
              type: types.SHOW_ALERT,
              alertType: "error",
              text: errors.toString().split(',').join('\n\n'),
            });
          } catch (error) {
            dispatch({
              type: types.SHOW_ALERT,
              alertType: "error",
              text:data?.message? data?.message:"Something went wrong!",
            });
          }
        }
      }).catch((ERR)=>{
        dispatch(failure(ERR));
      });
    };
    function request() {
      return { type: tdsConstants.UPDATE_INTERNATIONAL_BANK_ACCOUNT_REQUEST };
    }
    function success(data) {
      return { type: tdsConstants.UPDATE_INTERNATIONAL_BANK_ACCOUNT_SUCCESS, data };
    }
    function failure(error) {
      return { type: tdsConstants.UPDATE_INTERNATIONAL_BANK_ACCOUNT_FAILURE, error };
    }
  },

  createDepositRepaymentProposal: (payload) => {
    return (dispatch) => {
      dispatch(request());
      tdsService.createDepositRepaymentProposal(payload).then((data) => {
        if (data.status) {
          dispatch(success(data?.data));
          dispatch({
            type: types.SHOW_ALERT,
            alertType: "success",
            text: "Repayment requested successfully",
          });
        } else {
          dispatch(failure(data));
          let errors=[];
          let obj=data?.message?.errors;
          Object.keys(obj).forEach(function(key){
            var value = obj[key];
            errors.push(value);
          });
          dispatch({
            type: types.SHOW_ALERT,
            alertType: "error",
            text: errors.toString().split(',').join('\n\n'),
          });
        }
      }).catch((ERR)=>{
        dispatch(failure(ERR));
      });
    };
    function request() {
      return { type: tdsConstants.CREATE_DEPOSIT_REPAYMENT_PROPOSAL_REQUEST };
    }
    function success(data) {
      return { type: tdsConstants.CREATE_DEPOSIT_REPAYMENT_PROPOSAL_SUCCESS, data };
    }
    function failure(error) {
      return { type: tdsConstants.CREATE_DEPOSIT_REPAYMENT_PROPOSAL_FAILURE, error };
    }
  },

  updateDepositRepaymentProposal: (caseId) => {
    return (dispatch) => {
      dispatch(request());
      tdsService.updateDepositRepaymentProposal(caseId).then((data) => {
        if (data.status) {
          dispatch(success(data?.data));
          dispatch({ type: types.SHOW_ALERT, alertType: "success", text: "Updated Succesfully" });
        } else {
          dispatch(failure(data));
        }
      }).catch((ERR)=>{
        dispatch(failure(ERR));
      });
    };
    function request() {
      return { type: tdsConstants.UPDATE_DEPOSIT_REPAYMENT_PROPOSAL_REQUEST };
    }
    function success(data) {
      return { type: tdsConstants.UPDATE_DEPOSIT_REPAYMENT_PROPOSAL_SUCCESS, data };
    }
    function failure(error) {
      return { type: tdsConstants.UPDATE_DEPOSIT_REPAYMENT_PROPOSAL_FAILURE, error };
    }
  },

  getDepositRepaymentProposal: (caseId, tenancyId, dan) => {
    return (dispatch) => {
      dispatch(request());
      tdsService.getDepositRepaymentProposal(caseId, tenancyId, dan).then((data) => {
        if (data.status) {
          dispatch(success(data?.data));
        } else {
          dispatch(failure(data));
        }
      }).catch((ERR)=>{
        dispatch(failure(ERR));
      });
    };
    function request() {
      return { type: tdsConstants.GET_DEPOSIT_REPAYMENT_PROPOSAL_REQUEST };
    }
    function success(data) {
      return { type: tdsConstants.GET_DEPOSIT_REPAYMENT_PROPOSAL_SUCCESS, data };
    }
    function failure(error) {
      return { type: tdsConstants.GET_DEPOSIT_REPAYMENT_PROPOSAL_FAILURE, error };
    }
  },

  getTenancies: () => {
    return (dispatch) => {
      dispatch(request());
      tdsService.getTenancies().then((data) => {
        if (data.status) {
          dispatch(success(data?.data));
        } else {
          dispatch(failure(data));
        }
      }).catch((ERR)=>{
        dispatch(failure(ERR));
      });
    };
    function request() {
      return { type: tdsConstants.GET_TENANCIES_REQUEST };
    }
    function success(data) {
      return { type: tdsConstants.GET_TENANCIES_SUCCESS, data };
    }
    function failure(error) {
      return { type: tdsConstants.GET_TENANCIES_FAILURE, error };
    }
  },

  getTenancy: (tenancyId, dan) => {
    return (dispatch) => {
      dispatch(request());
      tdsService.getTenancy(tenancyId, dan).then((data) => {
        if (data.status) {
          dispatch(success(data?.data));
        } else {
          dispatch(failure(data));
        }
      }).catch((ERR)=>{
        dispatch(failure(ERR));
      });
    };
    function request() {
      return { type: tdsConstants.GET_TENANCY_REQUEST };
    }
    function success(data) {
      return { type: tdsConstants.GET_TENANCY_SUCCESS, data };
    }
    function failure(error) {
      return { type: tdsConstants.GET_TENANCY_FAILURE, error };
    }
  },

  updateAccount: (payload) => {
    return (dispatch) => {
      dispatch(request());
      tdsService.updateAccount(payload).then((data) => {
        if (data.status) {
          dispatch(success(data?.data));
          dispatch({ type: types.SHOW_ALERT, alertType: "success", text: "Profile Updated Successfully" });
        } else {
          dispatch(failure(data));
          dispatch({ type: types.SHOW_ALERT, alertType: "error", text: data?.message?.errors?.validation_error? data?.message?.errors?.validation_error:(data?.message&& typeof data.message=="string")?data?.message:"" });
        }
      }).catch((ERR)=>{
        dispatch(failure(ERR));
      });
    };
    function request() {
      return { type: tdsConstants.UPDATE_TDS_ACCOUNT_REQUEST };
    }
    function success(data) {
      return { type: tdsConstants.UPDATE_TDS_ACCOUNT_SUCCESS, data };
    }
    function failure(error) {
      return { type: tdsConstants.UPDATE_TDS_ACCOUNT_FAILURE, error };
    }
  },

  forgotPassword: (payload,isSf,cb,finalCall) => {
    return (dispatch) => {
      dispatch(request());
      tdsService.forgotPassword(payload).then((data) => {
        if (data?.status) {
          console.log("forgotPassword",data)
          if(cb){
            cb(data)
          }
          dispatch(success(data?.data));
          if(finalCall){
            dispatch({ type: types.SHOW_ALERT, alertType: "success", text:"Password updated Successfully!" });

          }else{
            dispatch({ type: types.SHOW_ALERT, alertType: "success", text:isSf? "Otp sent Successfully" : "Activation link sent Successfully" });
          }
        } else {

          if(cb){
            cb(data)
          }
          dispatch(failure(data));

          if(finalCall){
            dispatch({ type: types.SHOW_ALERT, alertType: "error", text: "Error,Please try again!" });
          }else{
            dispatch({ type: types.SHOW_ALERT, alertType: "error", text: isSf? "Failed to send Otp": "Failed to send activation link" });
          }
        }
      }).catch((ERR)=>{
        dispatch(failure(ERR));
      });
    };
    function request() {
      return { type: tdsConstants.FORGOT_PASSWORD_RESET_REQUEST };
    }
    function success(data) {
      return { type: tdsConstants.FORGOT_PASSWORD_RESET_SUCCESS, data };
    }
    function failure(error) {
      return { type: tdsConstants.FORGOT_PASSWORD_RESET_FAILURE, error };
    }
  },

  confirmTenancy: (payload, tenancyId) => {
    return (dispatch) => {
      dispatch(request());
      tdsService.confirmTenancy(payload, tenancyId).then((data) => {
        if (data.status) {
          dispatch(success(data?.data));
          dispatch({ type: types.SHOW_ALERT, alertType: "success", text: "Tenancy confirmed Successfully" });
        } else {
          dispatch(failure(data));
          let message = []
          Object.keys(data?.message?.errors).forEach(error=>{
            message.push(data?.message?.errors[error])
          })
          dispatch({ type: types.SHOW_ALERT, alertType: "error", text: message? message.toString():"Something went wrong!" });
        }
      }).catch((ERR)=>{
        dispatch({ type: types.SHOW_ALERT, alertType: "error", text: "Something went wrong!" });

        dispatch(failure(ERR));
      });
    };
    function request() {
      return { type: tdsConstants.CONFIRM_TENANCY_REQUEST };
    }
    function success(data) {
      return { type: tdsConstants.CONFIRM_TENANCY_SUCCESS, data };
    }
    function failure(error) {
      return { type: tdsConstants.CONFIRM_TENANCY_FAILURE, error };
    }
  },
  activateEmail: (payload,cb,isSFUser) => {
    return (dispatch) => {
      dispatch(request());
      tdsService.verifyEmail(payload,isSFUser).then((data) => {
        console.log("datadata",data)
        if (data.status==true) {
          cb(true)
          dispatch(success(data?.data));
        } else {
          dispatch(failure(data));
          let errors=[];
          let obj=JSON.stringify(data?.message);
          Object.keys(obj).forEach(function(key){
            var value = obj[key];
            errors.push(value);
          });
          if(isSFUser){
            dispatch({
              type: types.SHOW_ALERT,
              alertType: "error",
              text: data?.message?data?.message:"Something went wrong!" ,
            });
          }else{
            dispatch({
              type: types.SHOW_ALERT,
              alertType: "error",
              text: data?.message?data?.message:"Something went wrong!" ,
            });
          }
          
        }
      }).catch((ERR)=>{
        dispatch(failure(ERR));
      });
    };
    function request() {
      return { type: tdsConstants.ACTIVATE_EMAIL_REQUEST };
    }
    function success(data) {
      return { type: tdsConstants.ACTIVATE_EMAIL_SUCCESS, data };
    }
    function failure(error) {
      return { type: tdsConstants.ACTIVATE_EMAIL_FAILURE, error };
    }
  },
  resetPassword: (payload,navigation) => {
    return (dispatch) => {
      dispatch(request());
      tdsService.resetPassword(payload).then((data) => {
        if (data?.status) {
          dispatch(success(data?.data));
          dispatch({
            type: types.SHOW_ALERT,
            alertType: "success",
            text: "Password reset successfully",
          });
          navigateAction(navigation, 'TDS Search Deposit')
        } else {
          dispatch(failure(data));
          let errors=[];
          let obj=data?.message?.errors;
          Object.keys(obj).forEach(function(key){
            var value = obj[key];
            errors.push(value);
          });
          dispatch({
            type: types.SHOW_ALERT,
            alertType: "error",
            text: errors.toString().split(',').join('\n\n'),
          });
        }
      }).catch((ERR)=>{
        dispatch(failure(ERR));
      });
    };
    function request() {
      return { type: tdsConstants.TDS_RESET_PASSWORD_REQUEST };
    }
    function success(data) {
      return { type: tdsConstants.TDS_RESET_PASSWORD_SUCCESS, data };
    }
    function failure(error) {
      return { type: tdsConstants.TDS_RESET_PASSWORD_FAILURE, error };
    }
  },
  sendActivationCode: (payload,cb,isSFUser) => {
    return (dispatch) => {
      dispatch(request());
      tdsService.sendActivationCode(payload,isSFUser).then((data) => {
        if (data.status) {
          dispatch(success(data?.data));
          cb(true)
        } else {
          dispatch(failure(data));
          let errors=[];
          let obj=data?.message?.errors;
          Object.keys(obj).forEach(function(key){
            var value = obj[key];
            errors.push(value);
          });
          dispatch({
            type: types.SHOW_ALERT,
            alertType: "error",
            text: errors.toString().split(',').join('\n\n'),
          });
        }
      }).catch((ERR)=>{
        dispatch(failure(ERR));
      });
    };
    function request() {
      return { type: tdsConstants.SEND_ACTIVATION_REQUEST };
    }
    function success(data) {
      return { type: tdsConstants.SEND_ACTIVATION_SUCCESS, data };
    }
    function failure(error) {
      return { type: tdsConstants.SEND_ACTIVATION_FAILURE, error };
    }
  },
};

