import React, { useState } from 'react';
import { TouchableOpacity, Image, View } from 'react-native';
import { COLORS } from '../../constants/colorPallete';
import { useDispatch } from 'react-redux';
import { likeDislike } from '../../redux/actions/mediaHub';

const Like = (props) => {
  const [isLiked, setIsLiked] = useState(props?.value);
  console.log(props)
  const dispatch=useDispatch();
  const handleLike = () => {
    const payload = {
      id: props?.infohub_id
   }
  dispatch(likeDislike(payload,props?.infohub_id, () => {
      setIsLiked(!isLiked);
  }))
  };
  

  return (
    <View style={{ alignItems: 'center', justifyContent: 'center' }}>
      <TouchableOpacity onPress={handleLike}>
        <Image
          source={require('../../assets/images/infohub/like.png')}
          style={{
            height: 22,
            width: 22,
            resizeMode: 'contain',
            tintColor: isLiked ? COLORS.LGREEN : 'transparent',
          }}
        />
      </TouchableOpacity>
    </View>
  );
};

export default Like;
