import React from "react";
import { View, Button, Image } from "react-native";
import { ShareComponentView, ShareComponentList, ShareComponentText, ShareComponentTitle } from "./styles";

import {
  FacebookShareButton,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  LinkedinShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  EmailShareButton,
  TwitterIcon,
  LinkedinIcon,
  TelegramIcon,
  WhatsappIcon,
  EmailIcon,
  FacebookIcon,
} from "react-share";
import { Title } from "../../components/createAlert/createAlertScreen.style";

const ShareMedia = (props) => {
  const { shareUrl, emailSubject, emailBody } = props;
  let shareUrlForFb = '', titleForFb = '';
  if(shareUrl) {
    titleForFb = shareUrl.split('!\n')[0]?.trim();
    shareUrlForFb = shareUrl.split('out!\n')[1]?.trim();
  }

  const title = "";
  return (
    <ShareComponentView>
      <ShareComponentTitle>Share via:</ShareComponentTitle>
      <ShareComponentList>
        <FacebookShareButton
          url={shareUrlForFb}
          quote={titleForFb}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <FacebookIcon size={32} round />
          <ShareComponentText>
            Facebook
          </ShareComponentText>
        </FacebookShareButton>
       
      </ShareComponentList>

      <ShareComponentList>
        <TwitterShareButton
          url={shareUrl}
          title={title}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <TwitterIcon size={32} round />
          <ShareComponentText>
          Twitter
        </ShareComponentText>
        </TwitterShareButton>
       
      </ShareComponentList>
      <ShareComponentList>
        <TelegramShareButton
          url={shareUrl}
          title={title}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <TelegramIcon size={32} round />
          <ShareComponentText>
          Telegram
        </ShareComponentText>
        </TelegramShareButton>
        
      </ShareComponentList>
      <ShareComponentList>
        <WhatsappShareButton
          url={shareUrl}
          title={title}
          separator=":: "
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <WhatsappIcon size={32} round />
          <ShareComponentText>
          Whatsapp
        </ShareComponentText>
        </WhatsappShareButton>
       
      </ShareComponentList>
      <ShareComponentList>
        <LinkedinShareButton
          url={shareUrl}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <LinkedinIcon size={32} round />
          <ShareComponentText>
          LinkedIn
        </ShareComponentText>
        </LinkedinShareButton>
        
      </ShareComponentList>
      <ShareComponentList>
      <EmailShareButton
            url={''}
            subject={emailSubject}
            body={emailBody}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <EmailIcon size={32} round />
            <ShareComponentText>
            Email
          </ShareComponentText>
          </EmailShareButton>
          
      </ShareComponentList>
    </ShareComponentView>
  );
};

export default ShareMedia;
