import React, { Component } from "react";
import { StyleSheet, View, Image, Text } from "react-native";
import Modal  from "react-native-modal";
const Loader = (props) => {
  const { loading, ...attributes } = props;

  return (
    <Modal
      isVisible={loading}
      backdropTransitionInTiming={2000}
      backdropTransitionOutTiming={2000}
      fullScreen={true}
    >
      <View style={styles.modalBackground}>
        <View style={styles.activityIndicatorWrapper}>
          <Image
            source={require("../../../openbrix-design-system/images/loader.gif")}
            resizeMode="contain"
            style={{ height: 80, width: 80 }}
          />
          {/* <Text style={{ color: "#E71E82", fontSize: 18 }}>Please wait...</Text> */}
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modalBackground: {
    flex: 1,
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  activityIndicatorWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    backgroundColor:'white',
    padding:30,
    borderRadius:1000
  },
});

export default Loader;
