import { Dimensions } from "react-native";
const { height, width } = Dimensions.get("window");
const aspectRatio = height / width;

function getPlatformType() {
  if (width <= 767) {
    return "phone";
  } else if (width >= 768 && width <= 1024) {
    return "tab";
  } else if (width >= 1025) {
    return "web";
  }
}
export function getImage(name,isSingle) {
  if(isSingle){
    return require(`./../../openbrix-design-system/images/${name}`);
  }else{
    if (width <= 767) {
      return require(`./../../openbrix-design-system/images/banner/mobile/${name}`);
    } else if (width >= 768 && width <= 1024) {
      return require(`./../../openbrix-design-system/images/banner/tab/${name}`);
    } else if (width >= 1025) {
      return require(`./../../openbrix-design-system/images/banner/web/${name}`);
    }
  }
 
}
export const FULL_HEIGHT=Dimensions.get('window').height;
export default getPlatformType;
