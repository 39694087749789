import { types } from "../../actionTypes";

export const showCreditNativePayment = (data) => {
    return (dispatch) => {
      dispatch({ type: types.SET_CREDIT_PAYMENT, payload: data});
    };
};
export const showInapNativeWebView = (data) => {
  return (dispatch) => {
    dispatch({ type: types.SET_INAPP_WEBVIEW, payload: data});
  };
};
