import React, { useCallback, useEffect, useRef, useState } from "react";
import { Dimensions, Image, Linking, TouchableOpacity, View } from "react-native";
import { AntDesign } from "@expo/vector-icons";

import stylesMobile from "./stylesMobile";
import stylesTab from "./stylesTab";
import stylesWeb from "./stylesWeb";
import { backgroundSearchArr } from "../images/tlyfe/searchArrayTlyfe";
import BackGroundGenerator from "../common/BackGroundGenerator";
import { navigateAction } from "../../../helpers/navigationHelpers";
import { useSelector } from "react-redux";
import { Typography } from "../../openFlow/style";
import { COLORS } from "../../../constants/colorPallete";
import getPlatformType from "../../../helpers/getPlatform";
import CommonAuth from "./CommonAuth";

const FEATURED = [
  {
    onButtonClick: () => {
      window.open('https://agent.openbrix.co.uk','_blank')
    },
    buttonText: "CLICK HERE TO REGISTER/LOGIN",
    buttonStyle: { marginTop: 30 },
    bottomHeading:
      "AUTOMATED, INNOVATIVE, COMPLIANT AND COMPLETE! BRINGING HARMONY TO AGENTS AND THEIR TENANTS!",
  },
];
const tiles = [
  {
    name: "Adam Pigott",
    title: "Chief Executive Officer",
    list: [
      "Former Director and Founder CreditLadder.com",
      "Former Director and Founder Makeurmove.com",
      "Former Director and Founder, CHK Mountford",
    ],
    image: require("./images/team/ap.png"),
  },
  {
    name: "Shahad Choudhury",
    title: "Chief Innovation Officer",
    list: [
      "Blockchain and AI Advisory Role",
      "HM Government, Digital Project Delivery, JP Morgan, World Bank",
      "Project Delivery, UK Export Finance",
    ],
    image: require("./images/team/sc.png"),
  },
  {
    name: "James Gaubert",
    title: "Chief Marketing Officer",
    list: [
      "Former Head of Digital Marketing @ Ogilvy & Mather",
      "Author of 2 Amazon bestsellers in digital marketing",
      "TedX speaker on future marketing strategies",
    ],
    image: require("./images/team/jg.png"),
  },
  {
    name: "Fernando Pinto",
    title: "Chief Technology Officer",
    list: [
      "Extensive experience in systems architecture",
      "CTO in many start-ups from RPA to Automation",
      "API development expert",
    ],
    image: require("./images/team/fp.png"),
  },
  {
    name: "Sandra Donovan",
    title: "Chief Operations Officer",
    list: [
      "Former Head of Lettings Compliance at Countrywide Residential Lettings Ltd",
      "Principal Trainer and Countrywide",
      "Industry leader in National Lettings",
    ],
    image: require("./images/team/sd.png"),
  },
  {
    name: "John Hards",
    title: "Director and Chairman",
    list: [
      "Former Group MD Countrywide Lettings",
      "Sunday Times Award – Contribution to the Property Industry",
      "Member of The Lettings Industry Council",
      "Former Director of ARLA (Propertymark)",
    ],
    image: require("./images/team/jh.png"),
  },
  {
    name: "Gareth Williams",
    title: "Company Secretary",
    list: [
      "Former Legal Counsel and Group Company Secretary of Countrywide PLC",
      "Former Director, Countrywide Residential Lettings Ltd",
      "Former Head of Legal and Co.Sec. of Thorn UK Ltd (formally EMI)",
    ],
    image: require("./images/team/gw.png"),
  },
  {
    name: "Martyn Stones",
    title: "Chief Risk Officer",
    list: [
      "Former Group Enterprise Risk Director of Countrywide PLC",
      "Former Operations Director of Countrywide Surveyors Ltd",
      "Visiting Research Academic, Oxford Brookes University",
    ],
    image: require("./images/team/ms.png"),
  },
  {
    name: "Peter Speak",
    title: "Partnership Relationship Officer",
    list: [
      "Former Equity Partner of Bairstow Eves",
      "Former Managing Director Mann & Co",
      "Former Director of Countrywide Residential Lettings",
      "Countrywide Representative of RICS, MHCLG, NTSLEAT and",
      "The Property Ombudsman",
    ],
    image: require("./images/team/ac.png"),
  },
  {
    name: "Adrienne Clarke",
    title: "Chief Financial Officer",
    list: [
      "Former Group Financial Controller of Countrywide PLC",
      "Former Divisional Financial Director, Countrywide PLC",
      "Financial Director with 24 years experience in PLC and PE world",
    ],
    image: require("./images/team/ps.png"),
  },
];
const LandingTeam = (props) => {
  const [type, setType] = useState("web");
  const layoutType = getPlatformType();
  const isAnylyticsEnable = useSelector(
    (state) => state?.cookiesReducer?.customiseCookies.Analytics,
  );
  const { navigate } = props.navigation;
  const navigateTo = (params, from) => {
    if (from) {
      navigateAction(props?.navigation, params, from);
    } else {
      navigateAction(props?.navigation, params);
    }
  };
  const scrollViewRef = useRef();
  const [property_for, setPropertyFor] = useState(1);
  const styles = type == "web" ? stylesWeb : type == "mobile" ? stylesMobile : stylesTab;
  const [state, setState] = useState({
    from: "",
  });

  useEffect(() => {
    if (props?.route?.params?.from) {
      setState({
        ...state,
        from: props?.route?.params?.from ? props?.route?.params?.from : "",
      });
    }
  }, [props?.route?.params?.from]);
  console.log("type", type);
  const handleScrollButtonClick = useCallback(() => {
    const height=Dimensions.get('window').height;
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTo({ y:layoutType=='web'?height:height/2, animated: true });
    }
  }, []);
  const emailAgent = async () => {
    let email = "info@openbrix.co.uk";
    const url = `mailto: ${email}`;
    Linking.openURL(url)
    .catch((err) => {
      console.error("An error occurred", err);
      return false;
    });
  };
  return (
    <>
      <BackGroundGenerator
        props={props}
        layoutType={(data) => setType(data)}
        backgroundArr={backgroundSearchArr}
        isLanding
        cloudHeader={true}
        hideAuth={true}
        scrollViewRef={scrollViewRef}
      >
        <CommonAuth
          heading={"MEET THE TEAM"}
          navigation={navigate}
          type={type}
          subheading={"Individuals can and do make a difference, but it takes a team to really mess things up.".toUpperCase()}
          desc={""}
          hideAuth={true}
          onScrollButtonClick={handleScrollButtonClick}
          hideButton={false}
          logoName={" "}
          {...props}
        />

        <View style={[styles.imageContainer, { backgroundColor: "white" }]}>
          <Typography
            raj
            bold={"700"}
            color={"#1C1C1C"}
            size={type !== "web" ? "30px" : "48px"}
            line={type !== "web" ? "35px" : "52px"}
            align={"center"}
          >
            Meet The Team
          </Typography>
          <View
            style={{
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {tiles.map((item, index) => {
              return (
                <View
                  key={index}
                  style={[
                    {
                      width: 274,

                      paddingVertical: 40,
                      justifyContent: "flex-start",

                      borderRadius: 20,
                      margin: 20,
                    },
                  ]}
                >
                  <View style={{
                    height: 146,
                    alignItems: "center",
                  }}>
                    <Image
                      source={item.image}
                      style={{
                        width: 146,
                        height: 146,
                      }}
                      resizeMode={"contain"}
                    />
                  </View>
                  <Typography
                    line={"30px"}
                    raj
                    bold={"600"}
                    top={"10px"}
                    color={"#1C1C1C"}
                    size={"26px"}
                    align={"center"}
                  >
                    {item.name}
                  </Typography>
                  <Typography
                    line={"26px"}
                    raj
                    bold={"600"}
                    top={"6px"}
                    color={COLORS.orange}
                    size={"20px"}
                    align={"center"}
                  >
                    {item.title}
                  </Typography>
                  {item.list.map((item, index) => {
                    return (
                      <View style={{ flexDirection: "row" }}>
                        <Typography
                          key={index}
                          line={"27px"}
                          raj
                          bold={"500"}
                          color={"#737373"}
                          size={"18px"}
                          align={"left"}
                        >
                          &#9679;
                        </Typography>
                        <Typography
                          key={index}
                          line={"27px"}
                          raj
                          bold={"500"}
                          color={"#737373"}
                          size={"18px"}
                          align={"left"}
                        >
                          {" "}
                          {item}
                        </Typography>
                      </View>
                    );
                  })}
                </View>
              );
            })}
          </View>
        </View>
        <View
          style={[
            styles.textContainer,
            {
              backgroundColor: "#FAFAFB",
              marginBottom: type != "web" ? 300 : 300,
            },
          ]}
        >
          <View
            style={[
              styles.shawow,
              {
                width: "100%",
                flexDirection: "column",
                paddingHorizontal: "6%",
                borderRadius: 23,
                borderBottomWidth: 3,
                borderColor: COLORS.SAFFRON,
                alignItems: "center",
              },
            ]}
          >
            <Typography
              line={"35px"}
              raj
              bold={"600"}
              top={"30px"}
              color={"#1C1C1C"}
              size={"26px"}
              align={"center"}
              bottom={"40px"}
            >
              To find out more about current vacancies and enquire about joining feel free to contact us at:
            </Typography>
            <TouchableOpacity
              onPress={emailAgent}
              style={{
                justifyContent: "center",
                alignItems: "center",
                width: type === "web" ? 364 : "100%",
                borderStyle: "dashed",
                marginBottom: 30,
                height: 50,
                borderRadius: 5,
                borderWidth: 1,
                borderColor: COLORS.orange,
                flexDirection: "row",
              }}
            >
              <AntDesign name="mail" size={24} style={{ marginRight: 10 }} color={COLORS.orange}/>

              <Typography
                line={"26px"}
                raj
                bold={"600"}
                color={COLORS.orange}
                size={"20px"}
                align={"center"}
              >
                info@openbrix.co.uk
              </Typography>
            </TouchableOpacity>
          </View>
        </View>
      </BackGroundGenerator>
    </>
  );
};
export default LandingTeam;
