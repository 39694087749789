import React, { useEffect } from "react";
import {
  View,
  Text,
} from "react-native";
import { navigateAction } from "../../helpers/navigationHelpers";
import { useSelector } from "react-redux";




const HandoverPage = (props) => {
    const authData = useSelector((state) => state?.appReducer?.mobileData?.data);
    useEffect(() => {
      if (authData?.subUrl) {
        navigateAction(props?.navigation, authData?.subUrl);
      }
    }, [authData, authData?.subUrl]);
  return (
    <>

    </>
  );
};

export default HandoverPage;
