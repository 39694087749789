import React, { Component } from "react";

import {
  Image,
} from "react-native";

import {
  BoxButton,
  BoxView,
  BoxText,
  BoxWrapper,
  BoxTextLabel,
} from "./boxSelectorStyles";
const InitialArray = ["Detached", "Semi detached", "Terraced", "Flats", "Bungalow", "Farm/land", "Other"]

export default class BoxSelector extends Component {
  state = {
    selectedIndex: this.props.selectedIndex ? this.props.selectedIndex : [],
    selectedValues: [],
    values: [],
    setAllValues: false,
  };
  setSelection = (_index, value, setAll) => {
    let selectedValues = [],
      selectedIndex = [];
      if(_index ===0 &&value ==="All"){
      }
      else if(_index !=0&&this.state.selectedIndex.length==1 && this.state.selectedIndex.indexOf(_index)!=-1  ){
        
            this.setState({
              selectedIndex:[0],
              selectedValues:InitialArray,
            });
            this.props.onValueChange ? this.props.onValueChange(InitialArray) : null;
            this.props.setBoxIndex ? this.props.setBoxIndex([0]) : null;
      }
      else{
    if (setAll) {
      if (this.state.setAllValues) {
        selectedValues = [];
        selectedIndex = [];
        this.setState({
          setAllValues: false,
        });
      } else {
        selectedIndex = [_index];
        selectedValues = this.props.boxList.map((list) => {
          return list.value;
        });
        selectedValues.splice(selectedValues.indexOf(value), 1);
        this.setState({
          setAllValues: true,
        });
      }
    } else if (this.state.setAllValues && !setAll) {
      selectedIndex = [_index];
      selectedValues = [value];
      this.setState({
        setAllValues: false,
      });
    } else {
      selectedIndex = [...this.state.selectedIndex];
      let _theindex = selectedIndex.indexOf(_index);
      selectedIndex = selectedIndex.filter((item) => item !== _index);
      selectedIndex =
        _theindex == -1 ? [...selectedIndex, _index] : selectedIndex;
      selectedValues = [...this.state.selectedValues];
      // selectedValues = selectedValues ? selectedValues.split("|") : [];
      selectedValues =
        selectedValues.indexOf(value) == -1
          ? [...selectedValues, value]
          : selectedValues.filter((item) => item !== value);
    }
    this.props.onValueChange ? this.props.onValueChange(selectedValues) : null;
    this.props.setBoxIndex ? this.props.setBoxIndex(selectedIndex) : null;
    this.setState({
      selectedIndex,
      selectedValues,
    });
  }
    //return;
   
  };

  static getDerivedStateFromProps(props, state) {
    let selectedIndex = [];

    if (props.selectedIndex && state) {
      if (props.selectedIndex !== state.selectedIndex) {
        state.selectedIndex = props.selectedIndex;
      }
    }
    if (
      props.prefilledValues &&
      props.prefilledValues.length &&
      state 
    ) {
      const prefilledValues =
        typeof props.prefilledValues !== "object"
          ? JSON.parse(props.prefilledValues)
          : props.prefilledValues;
      let setValueIndex = null;
      props.boxList.map((list, index) => {
        if (list.setAll) {
          setValueIndex = index;
        }
        if (prefilledValues.indexOf(list.value) !== -1) {
          selectedIndex.push(index);
        }
      });
      if (
        setValueIndex !== null &&
        props.boxList.length - 1 == selectedIndex.length
      ) {
        selectedIndex = [setValueIndex];
        state.setAllValues = true;
      } else {
        state.setAllValues = false;
      }
      state.selectedIndex = selectedIndex;
      state.selectedValues = prefilledValues;
    }
    return state;
  }

  render() {
    const { boxList, label, boxStyle, isDisabled = false } = this.props;
    console.log("boxList",boxList)
    return (
      <BoxWrapper>
        <BoxTextLabel>{label ? label : ""}</BoxTextLabel>
        <BoxView>
          {boxList.map((list, index) => {
            let setAll = list.setAll ? list.setAll : false;
            return (
              <BoxButton
                id={list?.id}
                testID={list?.id}
                disabled={isDisabled}
                onPress={() => this.setSelection(index, list.value, setAll)}
                key={`bbn${index}`}
                style={{
                  borderColor:
                    this.state.selectedIndex &&
                    this.state.selectedIndex.length != 0 &&
                    this.state.selectedIndex.indexOf(index) != -1
                      ? "#E71E82"
                      : "#D0D0D0",
                  ...boxStyle,
                }}
              >
                {list.image && (
                  <Image
                    style={{
                      height: 26,
                      width: 26,
                      tintColor:
                        this.state.selectedIndex &&
                        this.state.selectedIndex.length != 0 &&
                        this.state.selectedIndex.indexOf(index) != -1
                          ? "#E71E82"
                          : "#494949",
                    }}
                    source={list.image}
                    resizeMode="contain"
                  />
                )}
                <BoxText
                  style={{
                    color:
                      this.state.selectedIndex &&
                      this.state.selectedIndex.length != 0 &&
                      this.state.selectedIndex.indexOf(index) != -1
                        ? "#E71E82"
                        : "#000000",
                  }}
                >
                  {list.text}
                </BoxText>
              </BoxButton>
            );
          })}
        </BoxView>
      </BoxWrapper>
    );
  }
}
