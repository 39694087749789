import { tdsConstants } from "./tds.constants";

export function tds(state = { tdsLoading: false }, action) {
  switch (action.type) {

    case tdsConstants.SEARCH_DEPOSIT_REQUEST:
      return {
        ...state,
        tdsLoading: true,
      };
    case tdsConstants.SEARCH_DEPOSIT_SUCCESS:
      return {
        ...state,
        tdsLoading: false,
        searchedDeposit: action.data,
      };
         case tdsConstants.CLEAR_DEPOSIT_REQUEST:
      return {
        ...state,
        searchedDeposit: null,
      };
      
    case tdsConstants.SEARCH_DEPOSIT_FAILURE:
      return {
        ...state,
        tdsLoading: false,
        searchedDeposit: action.error,
      };
    case tdsConstants.GET_SEARCHED_DEPOSIT_REQUEST:
      return {
        ...state,
        tdsLoading: true,
      };
    case tdsConstants.GET_SEARCHED_DEPOSIT_SUCCESS:
      return {
        ...state,
        tdsLoading: false,
        previousDeposits: action.data,
      };
    case tdsConstants.GET_SEARCHED_DEPOSIT_FAILURE:
      return {
        ...state,
        tdsLoading: false,
        previousDeposits: false,
      };  
    case tdsConstants.GET_TDS_TOKEN_REQUEST:
      return {
        ...state,
        tdsLoading: true,
      };
    case tdsConstants.GET_TDS_TOKEN_SUCCESS:
      return {
        ...state,
        tdsLoading: false,
        tdsToken: action.data,
      };
    case tdsConstants.GET_TDS_TOKEN_FAILURE:
      return {
        ...state,
        tdsLoading: false,
        tdsToken: false,
      };    
    case tdsConstants.LOGIN_TO_TDS_REQUEST:
      return {
        ...state,
        tdsLoading: true,
      };
    case tdsConstants.LOGIN_TO_TDS_SUCCESS:
      return {
        ...state,
        tdsLoading: false,
        tdsLoginResponse: action.data,
      };
    case tdsConstants.LOGIN_TO_TDS_FAILURE:
      return {
        tdsLoading: false,
        tdsLoginResponse: false,
      };
      case tdsConstants.LOGOUT_TO_TDS_REQUEST:
        return {
          ...state,
          tdsLoading: true,
        };
      case tdsConstants.LOGOUT_TO_TDS_SUCCESS:
        return {
            tdsLoading: false
        };
      case tdsConstants.LOGOUT_TO_TDS_FAILURE:
        return {
          ...state,
          tdsLoading: false,

        };
    case tdsConstants.GET_TENANCY_DETAILS_REQUEST:
      return {
        ...state,
        tdsLoading: true,
      };      
    case tdsConstants.GET_TENANCY_DETAILS_FAILURE:
      return {
        ...state,
        tdsLoading: false,
      };
    case tdsConstants.GET_TENANCY_DETAILS_SUCCESS: 
      return {
        ...state,
        tdsLoading: false,
      };
    case tdsConstants.UPDATE_TDS_ACCOUNT_REQUEST:
      return {
        ...state,
        tdsLoading: true,
      };
    case tdsConstants.UPDATE_TDS_ACCOUNT_SUCCESS:
      return {
        ...state,
        tdsLoading: false,
      };
    case tdsConstants.UPDATE_TDS_ACCOUNT_FAILURE: 
      return {
        ...state,
        tdsLoading: false,
      };  
    case tdsConstants.FORGOT_PASSWORD_RESET_REQUEST:
      return {
        ...state,
        tdsLoading: true,
      };
    case tdsConstants.FORGOT_PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        tdsLoading: false,
      };
    case tdsConstants.FORGOT_PASSWORD_RESET_FAILURE: 
      return {
        ...state,
        tdsLoading: false,
      };    
    case tdsConstants.CONFIRM_TENANCY_REQUEST:
      return {
        ...state,
        tdsLoading: true,
      };
    case tdsConstants.CONFIRM_TENANCY_SUCCESS:
      return {
        ...state,
        tdsLoading: false,
        confirmTenancySuccess: true
      };
    case tdsConstants.CONFIRM_TENANCY_FAILURE: 
      return {
        ...state,
        tdsLoading: false,
      };      
    case tdsConstants.DOWNLOAD_DEPOSIT_CERTIFICATE_REQUEST:
      return {
        ...state,
        tdsLoading: true,
      };
    case tdsConstants.DOWNLOAD_DEPOSIT_CERTIFICATE_FAILURE:
      return {
        ...state,
        tdsLoading: false,
      };
    case tdsConstants.DOWNLOAD_DEPOSIT_CERTIFICATE_SUCCESS: 
      return {
        ...state,
        tdsLoading: false,
        depositCertificate: action.data
      };
    case tdsConstants.GET_TDS_ACCOUNT_REQUEST:
      return {
        ...state,
        tdsLoading: true,
      };
    case tdsConstants.GET_TDS_ACCOUNT_FAILURE:
      return {
        ...state,
        tdsLoading: false,
      };
    case tdsConstants.GET_TDS_ACCOUNT_SUCCESS:
      return {
        ...state,
        tdsLoading: false,
        tdsAccount: action.data
      };
    case tdsConstants.UPDATE_BANK_ACCOUNT_REQUEST:
      return {
        ...state,
        tdsLoading: true,
      };
    case tdsConstants.UPDATE_BANK_ACCOUNT_FAILURE:
      return {
        ...state,
        tdsLoading: false,
        updateBankAccountResponse: action.data
      };
    case tdsConstants.UPDATE_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        tdsLoading: false,
        updateBankAccountResponse: action.data
      };
    case tdsConstants.UPDATE_INTERNATIONAL_BANK_ACCOUNT_REQUEST:
      return {
        ...state,
        tdsLoading: true,
      };
    case tdsConstants.UPDATE_INTERNATIONAL_BANK_ACCOUNT_FAILURE:
      return {
        ...state,
        tdsLoading: false,
        updateInternationalBankAccountResponse: action.data
      };
    case tdsConstants.UPDATE_INTERNATIONAL_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        tdsLoading: false,
        updateInternationalBankAccountResponse: action.data
      };
    case tdsConstants.TRIGGER_PASSWORD_RESET_REQUEST:
      return {
        ...state,
        tdsLoading: true,
      };
    case tdsConstants.TRIGGER_PASSWORD_RESET_FAILURE:
      return {
        ...state,
        tdsLoading: false,
      };
    case tdsConstants.TRIGGER_PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        tdsLoading: false,
      };
    case tdsConstants.CREATE_DEPOSIT_REPAYMENT_PROPOSAL_REQUEST:
      return {
        ...state,
        tdsLoading: true,
      };
    case tdsConstants.CREATE_DEPOSIT_REPAYMENT_PROPOSAL_SUCCESS:
      return {
        ...state,
        tdsLoading: false,
        createRepaymentSuccess: true
      };
    case tdsConstants.CREATE_DEPOSIT_REPAYMENT_PROPOSAL_FAILURE:
      return {
        ...state,
        tdsLoading: false,
      };
    case tdsConstants.GET_DEPOSIT_REPAYMENT_PROPOSAL_REQUEST:
      return {
        ...state,
        tdsLoading: true,
      };
    case tdsConstants.GET_DEPOSIT_REPAYMENT_PROPOSAL_SUCCESS:
      return {
        ...state,
        tdsLoading: false,
        repaymentProposal: action.data
      };
    case tdsConstants.GET_DEPOSIT_REPAYMENT_PROPOSAL_FAILURE:
      return {
        ...state,
        tdsLoading: false,
      };
    case tdsConstants.UPDATE_DEPOSIT_REPAYMENT_PROPOSAL_REQUEST:
      return {
        ...state,
        tdsLoading: true,
      };
    case tdsConstants.UPDATE_DEPOSIT_REPAYMENT_PROPOSAL_SUCCESS:
      return {
        ...state,
        tdsLoading: false,
        respondSuccess: true
      };
    case tdsConstants.UPDATE_DEPOSIT_REPAYMENT_PROPOSAL_FAILURE:
      return {
        ...state,
        tdsLoading: false,
      };
    case tdsConstants.UPDATE_MARKETING_PREFERENCES_REQUEST:
      return {
        ...state,
        tdsLoading: true,
      };
    case tdsConstants.UPDATE_MARKETING_PREFERENCES_FAILURE:
      return {
        ...state,
        tdsLoading: false,
      };
    case tdsConstants.UPDATE_MARKETING_PREFERENCES_SUCCESS:
      return {
        ...state,
        tdsLoading: false,
      };  
    case tdsConstants.UPDATE_LEAD_PREFERENCES_REQUEST:
      return {
        ...state,
        tdsLoading: true,
      };
    case tdsConstants.UPDATE_LEAD_PREFERENCES_FAILURE:
      return {
        ...state,
        tdsLoading: false,
      };
    case tdsConstants.UPDATE_LEAD_PREFERENCES_SUCCESS:
      return {
        ...state,
        tdsLoading: false,
      };
    case tdsConstants.GET_TENANCIES_REQUEST:
      return {
        ...state,
        tdsLoading: true,
      };
    case tdsConstants.GET_TENANCIES_FAILURE:
      return {
        ...state,
        tdsLoading: false,
        tenancies: []
      };
    case tdsConstants.GET_TENANCIES_SUCCESS:
      return {
        ...state,
        tdsLoading: false,
        tenancies: action.data
      };    
    case tdsConstants.GET_TENANCY_REQUEST:
      return {
        ...state,
        tdsLoading: true,
      };
    case tdsConstants.GET_TENANCY_FAILURE:
      return {
        ...state,
        tdsLoading: false,
        tenancy: {}
      };
    case tdsConstants.GET_TENANCY_SUCCESS:
      return {
        ...state,
        tdsLoading: false,
        tenancy: action.data
      };
    case tdsConstants.TRIGGER_RESET_PASSWORD_REQUEST:
      return {
        ...state,
        tdsLoading: true,
      };
    case tdsConstants.TRIGGER_RESET_PASSWORD_FAILURE:
      return {
        ...state,
        tdsLoading: false,
      };
    case tdsConstants.TRIGGER_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        tdsLoading: false,
      };
    case tdsConstants.TRIGGER_RESET_PASSWORD_REQUEST:
      return {
        ...state,
        tdsLoading: true,
      };
    case tdsConstants.TRIGGER_RESET_PASSWORD_FAILURE:
      return {
        ...state,
        tdsLoading: false,
      };
    case tdsConstants.TRIGGER_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        tdsLoading: false,
      };  
    case tdsConstants.TRIGGER_RESET_PASSWORD_REQUEST:
      return {
        ...state,
        tdsLoading: true,
      };
    case tdsConstants.TRIGGER_RESET_PASSWORD_FAILURE:
      return {
        ...state,
        tdsLoading: false,
      };
    case tdsConstants.TRIGGER_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        tdsLoading: false,
      };  
    case tdsConstants.ACTIVATE_EMAIL_REQUEST:
        return {
          ...state,
          tdsLoading: true,
        }; 
     case tdsConstants.ACTIVATE_EMAIL_SUCCESS:
        return {
          ...state,
          tdsLoading: false,
        }; 
     case tdsConstants.ACTIVATE_EMAIL_FAILURE:
        return {
          ...state,
          tdsLoading: false,
        }; 
     case tdsConstants.TDS_RESET_PASSWORD_REQUEST:
        return {
          ...state,
          tdsLoading: true,
        }; 
       case tdsConstants.TDS_RESET_PASSWORD_SUCCESS:
          return {
            ...state,
            tdsLoading: false,
          }; 
       case tdsConstants.TDS_RESET_PASSWORD_FAILURE:
            return {
              ...state,
              tdsLoading: false,
            }; 
       case tdsConstants.SEND_ACTIVATION_REQUEST:
              return {
                ...state,
                tdsLoading: true,
              };     
              case tdsConstants.SEND_ACTIVATION_SUCCESS:
                return {
                  ...state,
                  tdsLoading: false,
                };   
                case tdsConstants.SEND_ACTIVATION_FAILURE:
                  return {
                    ...state,
                    tdsLoading: false,
                  };   
    
    default:
      return state;
  }
}
