export const COLORS = {
  gradientColor1: "#E71E82",
  gradientColor2: "#EB5936",
  MAINPINK:'#F1B1D0',
  LIGHTPINK1: "#EDABC5",
  LIGHTPINK2: "#EEBCB6",
  LIGHTPINK3: "#E82C70",
  GREY1: "#a7a4a4",
  orange: "#FFB580",
  GREEN: "#A4D4A2",
  LGREEN: "#1B8A8C",
  gradientColor3: "#3DB9BB",
  gradientColor4: "#1B8A8C",
  teal: "#C4F5D8",
  BLACK: "#000000",
  SAFFRON: "#FF5A51",
  NGREEN: "#90CBCC",
  YELLOW: "#ffc163",
  LABELGREY: "#737373",
  bgGREY:'#FAFAFA',
  ligntGreen:"#EFFDEE",
  tds:"#194592"
};
export const OPEN_FLO_THEME = {
  completed: "#E71E82",
  inprogress: "#ffc163",
  notstarted: "#FFFFFF",
  actionrequired: "#FFB580",
  incomplete: "#FFFFFF",
  seen: "#008000",
  notSeen: "#FC4850",
  acceptoffer: "#76CE95",
  offeraccepted: "#06A006",
};
export const OPEN_FLO_TEXT_THEME = {
  notstarted: "#FFB580",
  inprogress: "#FFB580",
  incomplete: "#FFB580",
  actionrequired: "#FFB580",
  completed: "#FFFFFF",
  notInitiated: "#FFB580",
  offeraccepted: "#FFB580",

};

export const generalColorsTheme = {
  pink: "#FFB580",
  yellow: "#FFC059",
  red: "#FC4850",
  green: "#06A006",
  gray: "#646566",
  blue: "#438DAC",
  white: "#fff",
  active: "#638ACC",
  inactive: "rgba(99, 138, 204, 0.44)",
};

