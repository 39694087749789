import React, { useEffect } from 'react';
import Header from '../../commonComponents/headerComponent/headerComponent';
import Footer from '../../commonComponents/footerComponent/customFooter';
import {
  View,
  Text,
  Dimensions,
  SafeAreaView,
  Image,
  TouchableOpacity,
  Platform,
  ScrollView,
  FlatList,
  TextInput,
  StyleSheet,
  FileSystem,
} from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { saveAs } from 'file-saver';
import variables from '../openFlow/atoms/variables.json';
import { AntDesign } from '@expo/vector-icons';
import { Entypo } from '@expo/vector-icons';
import { FontAwesome } from '@expo/vector-icons';
import { MainSection, Section1, Section2, DocSection } from './IdVerificationStyle';
import CardComponent from './Components/cardComponent';
import { ContainerScrollView } from '../../helpers/appStyles';
import SkeltonComponents from './Components/skeltonComponents';
import { useDispatch, useSelector } from 'react-redux';
import { getDigitalVerificationStatus } from '../openFlow/helpers';
import { TenantService } from '../openFlow/openFlowRedux/tenant.service';
import { RTR_URL, YOTI_URL } from '../../helpers/connectionHelpers';
import BackButton from '../../commonComponents/BackButton';
import { Content, ContentWrapper } from '../moreInformation/styles';
import WideButton from '../../commonComponents/wideButton';
import moment from "moment";
import {
  DocBoxLeft,
  DocBoxRight,
  DocDate,
  DocDateText,
  DocName,
  DocNameText,
  DocRemove,
  DocRemoveText,
  DocShare,
  DocShareText,
  DocumentBox,
  Heading,
} from '../myDocumentsList/styles';
import { openUrlHelpers } from '../../helpers/openDocHelpers';
import { COLORS } from '../../constants/colorPallete';
import { RentalInfoBox, RentalInfoRow } from '../openFlow/style';

import Modal  from "react-native-modal";

import getPlatformType from '../../helpers/getPlatform';
import CustomTextInput from '../../commonComponents/customTextInput/customTextInput';
import { tenantActions } from '../openFlow/openFlowRedux/tenant.actions';
import { Button } from '../openFlow/atoms/button';
import DatePickerComponent from '../../commonComponents/datepicker/DatePickerComponent';
const layoutType = getPlatformType();
const IdVerification = (props) => {
  const userData = useSelector((state) => state.authReducer?.profile?.data?.appUser);
  const userId = useSelector((state) => state.authReducer?.profile?.data?.appUser?.app_user_id);
  const yotiRequestId = useSelector((state) => state.authReducer?.profile?.data?.user_id);
  const idVerification = useSelector(
    (state) => state.authReducer?.profile?.data?.appUser?.idverification
  );
  const ussssrdata = useSelector((state) => state?.authReducer?.profile?.data?.appUser);
  const dispatch = useDispatch();
  const getButtonStatus = (id) => {
    if (id == 0 || id === 5 || id == 9) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    setUser(ussssrdata);
  }, []);
  const [showModal, setShowModal] = React.useState(false);
  const [rtr_code, setRtr_code] = React.useState('');
  const [rtr_dob, setRtr_dob] = React.useState('');
  const openDoc = (doc) => {
    openUrlHelpers(doc.filepath);
  };
  const openLinkYoti = async (id) => {
    if (yotiRequestId && getButtonStatus(idVerification)) {
      let u = YOTI_URL + yotiRequestId;

      if (Platform.OS === 'web') {
        window.open(
          u,
          '_blank' // <- This is what makes it open in a new window.
        );
      } else {
        Linking.openURL(u).catch((err) => console.error('An error occurred', err));
      }
    } else {
      console.error('An error occurred', yotiRequestId, getButtonStatus());
    }
  };
  const downloadVerifiedDocuments = async () => {
    TenantService.downloadVerifiedDocuments({
      user_id: userId,
    })
      .then((response) => {
        if (!response.ok) {
          alertActions.error('This user does not have verified Documents to download');
          var err = new Error('HTTP status code: ' + response.status);
          err.response = response;
          err.status = response.status;
          throw err;
        } else {
          return response.blob();
        }
      })
      .then((blob) => {
        alert('Your document are being downloaded, check your download folder');

        saveAs(blob, userId + '-uploaded-documents.zip');
      })
      .catch((err) => {});
  };
  const openLinkRTR = async (id) => {
    let u = RTR_URL;

    if (Platform.OS === 'web') {
      window.open(
        u,
        '_blank' // <- This is what makes it open in a new window.
      );
    } else {
      Linking.openURL(u).catch((err) => console.error('An error occurred', err));
    }
  };

  const [teanants, setUser] = React.useState(null);
  const getRtrCodeDob = () => {
    if (teanants) {
      if (teanants.right_to_rent_dob) {
        return moment(teanants.right_to_rent_dob).format("DD-MM-YYYY")
      } else return 'N/A';
    } else return 'N/A';
  };
  const getRtrCode = () => {
    if (teanants) {
      if (teanants.right_to_rent) {
        return teanants.right_to_rent;
      } else return 'N/A';
    } else return 'N/A';
  };
  return (
    <>
      <MainSection>
        <Section1>
          <ContentWrapper style={{ marginTop: 0 }}>
            <Text style={{ fontWeight: 'bold', marginBottom: 10 }}>ID Verification</Text>
            <Content>
              <View
                style={{
                  height: 71,
                  flexDirection: 'row',
                  // backgroundColor: "pink",
                  paddingHorizontal: 20,
                  paddingVertical: 16,
                  borderBottomWidth: 1,
                  borderBottomColor: '#979797',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Text>Verification</Text>
                <WideButton
                  onPress={() => openLinkYoti()}
                  childText={
                    getDigitalVerificationStatus(userData?.idverification, userData?.yoti_reason)
                      ?.BtnText
                  }
                  width={167.5}
                  isDisabled={
                    getDigitalVerificationStatus(userData?.idverification, userData?.yoti_reason)
                      ?.BtnText === 'Start'
                      ? false
                      : true
                  }
                  gradiant
                />
              </View>
              <View
                style={{
                  height: 71,
                  flexDirection: 'row',
                  // backgroundColor: "silver",
                  paddingHorizontal: 20,
                  paddingVertical: 16,
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Text>Status</Text>

                <View
                  style={{
                    height: 40,
                    width: 167.5,
                    backgroundColor: getDigitalVerificationStatus(
                      userData?.idverification,
                      userData?.yoti_reason
                    ).color,
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 10,
                  }}
                >
                  <Text
                    style={{
                      color: getDigitalVerificationStatus(
                        userData?.idverification,
                        userData?.yoti_reason
                      )?.textColor,
                    }}
                  >
                    {
                      getDigitalVerificationStatus(userData?.idverification, userData?.yoti_reason)
                        ?.StatusText
                    }
                  </Text>
                </View>
              </View>
            </Content>
          </ContentWrapper>

          {/* <SkeltonComponents
              type={"button"}
              title={"ID Verification"}
              data={[
                {
                  title: "Status",
                  button: getDigitalVerificationStatus(userData?.idverification)
                    .Text,
                  buttonColor: getDigitalVerificationStatus(
                    userData?.idverification
                  ).color,
                  onClick: () => {
                    openLinkYoti();
                  },
                },
              ]}
            /> */}
          <RentalInfoBox
            style={{
              shadowColor: 'grey',
              shadowOffset: {
                width: 2,
                height: 2,
              },
              width: '100%',
              shadowOpacity: 0.09,
              shadowRadius: 6.68,
              elevation: 11,
              marginTop: '20%',
            }}
          >
            <RentalInfoRow>
              <Text>Right to Rent</Text>

              <Button onClick={() => openLinkRTR()} type={'solid'} title={'Start'} />
            </RentalInfoRow>
            <RentalInfoRow>
              <Text>Date of Birth</Text>
              <View style={{ flexDirection: 'column' }}>
                <View
                  style={{
                    height: 40,
                    width: 167.5,
                    backgroundColor: getDigitalVerificationStatus(0).color,
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 10,
                  }}
                >
                  <Text style={{ color: getDigitalVerificationStatus(0)?.textColor }}>
                    {getRtrCodeDob()}
                  </Text>
                </View>
              </View>
            </RentalInfoRow>
            <RentalInfoRow>
              <Text>Rtr Code</Text>
              <View style={{ flexDirection: 'column' }}>
                <View
                  style={{
                    height: 40,
                    width: 167.5,
                    backgroundColor: getDigitalVerificationStatus(0).color,
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 10,
                  }}
                >
                  <Text style={{ color: getDigitalVerificationStatus(0)?.textColor }}>
                    {getRtrCode()}
                  </Text>
                </View>
                <Text
                  onClick={() => setShowModal(true)}
                  style={{
                    color: '#FBB1E6',
                    textAlign: 'center',
                    marginTop: 20,
                    cursor: 'pointer',
                  }}
                >
                  Add Code
                </Text>
              </View>
            </RentalInfoRow>
          </RentalInfoBox>
        </Section1>
        <Section2>
          <SkeltonComponents
            type={'summary'}
            title={'ID Verification Information'}
            subTitleRow1={'Recurring Income'}
            subTitleRow2={'Amount'}
            data={[
              {
                label: 'Verify yourself!',
                value: 'Get yourself verified!',
                onClick: () => {},
              },
              {
                label: 'Download Docs',
                value:
                  'Download all your verified documents to your document storage, which can also be used to share with others.',
                onClick: () => {
                  downloadVerifiedDocuments();
                },
              },
            ]}
          />
          <SkeltonComponents
            type={'summary'}
            title={'Right to Rent Check'}
            style={{ marginTop: 20 }}
            data={[
              {
                label: '',
                value: ` You are required by law to prove your "right to rent" in England`,
              },
              {
                label: '',
                value: '  Please click "start" to begin the process.',
              },
              {
                label: '',
                value: `Once you complete the process, you can then add your share code, which will be shared with your letting agent.`,
              },
            ]}
          />
        </Section2>
      </MainSection>
      {idVerification === 2 ? (
        // <SkeltonComponents
        //   type={"document"}
        //   title={"ID Documentation"}
        //   style={{ marginTop: 34 }}
        //   renderArrow={false}
        //   subTitleRow1={"Verification document"}
        //   onClick={() => downloadVerifiedDocuments()}
        // />
        <DocSection>
          <Text style={{ marginBottom: 20 }}>ID Documentation</Text>
          <DocumentBox>
            <DocBoxLeft>
              <DocName>
                <DocNameText onPress={() => openDoc(doc)}>Verification document</DocNameText>
              </DocName>
              <DocShare onPress={() => downloadVerifiedDocuments()}>
                <FontAwesome name="cloud-download" size={22} color={'#E71E82'} />
                <DocShareText
                  style={{
                    color: 'black',
                  }}
                >
                  Download
                </DocShareText>
              </DocShare>
            </DocBoxLeft>
            <DocBoxRight>
              <DocRemove onPress={() => {}}>
                <Entypo name="cross" size={22} color={'#E71E82'} />
                <DocRemoveText>Remove</DocRemoveText>
              </DocRemove>
              <DocDate onPress={() => downloadVerifiedDocuments()}>
                <DocDateText style={{ color: '#E71E82' }}>{'N/A'}</DocDateText>
                <Entypo name="chevron-right" size={22} color={'#E71E82'} />
              </DocDate>
            </DocBoxRight>
          </DocumentBox>
        </DocSection>
      ) : null}

      <Modal
        isVisible={showModal}
        transparent={true}
        coverScreen={false}
        hasBackdrop={false}
        backdropOpacity={0}
        onBackdropPress={() => setShowModal(false)}
        style={{
          margin: 0,
          width: '100%',
          flex: 1,
          backgroundColor: '#00000080',
          paddingVertical: layoutType == 'phone' ? '5%' : '1%',
          paddingHorizontal: layoutType == 'phone' ? '8%' : '3%',
        }}
      >
        <View
          style={{
            width: layoutType == 'phone' ? '365px' : '600px',
            backgroundColor: '#FAFAFA',
            borderRadius: 20,
            overflow: 'hidden',
            alignSelf: 'center',
            padding: layoutType == 'phone' ? '5%' : '2%',
          }}
        >
          <Text style={{ fontSize: 40, fontWeight: 'bold', color: '#FFB580' }}>Right to Rent</Text>
          <Text style={{ fontSize: 18, marginTop: 30 }}>
            Have you completed your right to rent process via gov.uk site?
          </Text>
          <Text style={{ fontSize: 18, marginTop: 30 }}>
            If you have not, please close this pop up and click "start" on the right to rent
            section.
          </Text>
          <Text style={{ fontSize: 18, marginTop: 30, marginBottom: 40 }}>
            If you have completed the process and have you share code please add it below.
          </Text>
          <CustomTextInput
            textSize={18}
            width={'100%'}
            title="Enter Share code below"
            onChangeText={(value) => setRtr_code(value)}
            name="rtr_code"
            prefilledValue={rtr_code}
          />
          {/* <CustomTextInput
            textSize={18}
            width={'100%'}
            title="Date of Birth"
            onChangeText={(value) => }
            name="rtr_code"
            prefilledValue={rtr_dob}
          /> */}
                 <DatePickerComponent
                      is18yrs
                          value={rtr_dob}
                          onUpdateInput={(date) => {
                            setRtr_dob(value)
                          }}
                        />
          <View
            style={{
              width: '100%',
              justifyContent: 'space-between',
              flexDirection: 'row',
              marginTop: 60,
            }}
          >
            <Button
              onClick={() => {
                setShowModal(false);
                setRtr_code('');
                setRtr_dob('');
              }}
              type={'stripe'}
              color={'#FC4850'}
              title={'Close'}
            />
            <Button
              onClick={() =>
                dispatch(
                  tenantActions.postRtrCode(
                    {
                      right_to_rent_code: rtr_code,
                      right_to_rent_dob: rtr_dob,
                    },
                    (data) => {
                      setShowModal(false);
                      setRtr_code('');
                      setRtr_dob('');
                    }
                  )
                )
              }
              type={'stripe'}
              color={'#90C418'}
              title={'Save'}
            />
          </View>
        </View>
      </Modal>
    </>
  );
};
const styles = StyleSheet.create({});
export default IdVerification;
