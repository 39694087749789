import { Text, View } from 'react-native';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal  from "react-native-modal";
import { Hoverable } from 'react-native-web-hooks';

import { tdsActions } from './tdsRedux/tds.actions';
import { backgroundTdsArr } from './backgroundTdsArr';
import { navigateAction } from '../../helpers/navigationHelpers';
import BackGroundGenerator from '../LandingPages/common/BackGroundGenerator';
import CustomTextInput from '../../commonComponents/customTextInput/customTextInput';
import {
  BoxShadow,
  ButtonWrapper,
  Container,
  FormText,
  primaryColor,
  RowContainer,
  Section,
  Typography,
} from './style';
import getPlatformType from '../../helpers/getPlatform';
import { COLORS } from '../../constants/colorPallete';
import { useIsFocused } from '@react-navigation/native';

const TdsLogin = (props) => {
  const dispatch = useDispatch();
  const [type, setType] = useState('web');
  const [state, setState] = useState({username:"",email:""});
  const [isSFUser, setIsSFUser] = useState(null);

  const [showSuccess, setShowSuccess] = useState(false);
  const tdsTokenResponse = useSelector((state) => state.tds?.tdsToken);
  const tdsLoginResponse = useSelector((state) => state.tds?.tdsLoginResponse);
  const previousDeposits = useSelector((state) => state.tds?.previousDeposits);

  const [modal, setModal] = useState({
    visible: false,
    text: '',
  });
  const layoutType = getPlatformType();

  useEffect(() => {
    // dispatch(tdsActions.getTDSToken());
  }, []);
  const focus = useIsFocused();
  useEffect(() => {
    setIsSFUser(null);
    setState({username:"",email:""})
  }, [focus]);
  console.log("setState",state)
  // useEffect(() => {
  //   if (tdsTokenResponse) {
  //     if (tdsTokenResponse.token) {
  //       navigateAction(props.navigation, 'Custodial Tenancies');
  //     } else {
  //       dispatch(tdsActions.getSearchedDeposit());
  //     }
  //   }
  // }, [tdsTokenResponse]);

  useEffect(() => {
    if (previousDeposits) {
      if (previousDeposits.length > 0) {
        const latestDepositSearched = previousDeposits[0];
        if (latestDepositSearched.response.scheme == 'custodial') {
          setShowSuccess(true);
        }
      }
    }
  }, [previousDeposits]);

  const renderInfoBox = () => {
    return (
      <View
        style={{
          width: '100%',
          backgroundColor: '#C4F5D8',
          justifyContent: 'center',
          alignItems: 'center',
          paddingVertical: 50,
          marginVertical: 20,
          borderRadius: 10,
        }}
      >
        <Text
          style={{
            color: type == '#4C664B',
            fontSize: 23,
            fontWeight: '600',
            textAlign: 'center',
          }}
        >
          {' '}
          {'Your deposit is Protected with TDS Custodial'}
        </Text>
      </View>
    );
  };

  const handleChange = (value, field) => {
    setState({
      ...state,
      [field]: value,
    });
  };

  useEffect(() => {
    if (tdsLoginResponse) {
      navigateAction(props.navigation, 'Custodial Tenancies');
    }
  }, [tdsLoginResponse]);

  const loginToTDS = () => {
    dispatch(
      tdsActions.loginToTDS({
        username: state.username,
        password: state.password,
      })
    );
  };
  const checkUserType = (isPasswordFlow) => {
    dispatch(
      tdsActions.checkUserType(
        {
          email: state.username,
        },
        (data) => {
          //   data.data = {
          //   active_user:'Yes',
          //   last_login:null
          // }
          if (data?.data?.active_user == 'Yes' && !data?.data?.last_login &&!isPasswordFlow) {
            Promise.resolve()
            .then(()=>{
              setIsSFUser(data?.data);
            })
            .then(()=>{
              loginToActivation(data?.data?.active_user);
            })
          }
          console.log("isPasswordFlow",isPasswordFlow)
          if(isPasswordFlow && data?.data?.active_user == 'Yes'){
            Promise.resolve()
            .then(()=>{
              setModal({
                visible: false,
                text: '',
              });
            })
            .then(()=>{
              navigateAction(props.navigation, 'Forget Password', { email: state.username });
              closeModal()
            })
          }else{
            setIsSFUser(data?.data);
          }
          // // case 1 if user is sf active user
          // data.data= {
          //   active_user:'Yes',
          //   last_login: new Date()
          // }
          // setIsSFUser(data.data)

          // case 2 if user is sf non active user
          // data.data = {
          //   active_user:'Yes',
          //   last_login:null
          // }

          console.log('checkUserType', data);
        }
      )
    );
  };
  const loginToActivation = (isSFUser) => {
    navigateAction(props.navigation, 'Custodial Activation',{isSFUser:isSFUser});
  };

  const [hoverStartTime, setHoverStartTime] = useState(null);
  const userId = useSelector((state) => state.authReducer?.profile?.data?.appUser?.app_user_id);

  //commented out until tom or someone else can finish the backend for this
  const handleHoverIn = () => {
    //  setHoverStartTime(Date.now());
  };
  const handleHoverOut = (type) => {
    //  if (hoverStartTime) {
    //    const duration = (Date.now() - hoverStartTime) / 1000;
    //
    //    dispatch(
    //      appUserTrackingProductHover({
    //        app_user_id: userId,
    //        product: type,
    //        hover_time_seconds: duration,
    //        current_page: "Tds Login",
    //      })
    //    );
    //  }
    //  setHoverStartTime(null);
  };
const closeModal =()=>{
    Promise.resolve().then(()=>{
      setIsSFUser(null)
    }).then(()=>{
      setModal({
        visible: false,
        text: '',
      });
      setState({username:"",email:""})
    })
}
  return (
    <>
      <Typography size={'24px'} id={'TDS-LOGIN'} color={primaryColor}>
        {' '}
        Login
      </Typography>
      <Typography bold={'700'} top={'20px'} color={'#194592'} size={'22px'} fontWeight={'300'}>
        Already have a TDS account?
      </Typography>
      <Typography
        bold={'400'}
        top={'10px'}
        size={'18px'}
        bottom={'30px'}
        color={'#000'}
        fontWeight={'300'}
      >
        Please login below to manage your custodial deposit
      </Typography>
      <CustomTextInput
        id="username"
        width={'100%'}
        title="Email"
        type="text"
        name="email"
        prefilledValue={state.username}
        bColor={'#638ACC'}
        onChangeText={(value) => handleChange(value, 'username')}
      />

      {isSFUser == null ? null : (
        <CustomTextInput
          id="password"
          width={'100%'}
          title="Password"
          type="password"
          name="password"
          prefilledValue={state.password}
          bColor={'#638ACC'}
          password
          renderRightComponent
          onChangeText={(value) => handleChange(value, 'password')}
        />
      )}

      <ButtonWrapper
        id={'TDS-LOGIN'}
        style={{width:layoutType=="phone"?"100%":"70%"}}
        onPress={() => (isSFUser == null ? checkUserType() : loginToTDS())}
      >
        <Typography id={'TDS-LOGIN-TEXT'} color={'white'}>
          {isSFUser == null ? 'Continue' : 'Login'}
        </Typography>
      </ButtonWrapper>

      <RowContainer
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 15,
        }}
      >
        <Typography style={{ fontSize: 17 }} color={'#000'}>
          Forgot password?
          <Typography
            style={{ fontSize: 17 }}
            id={'TDS-FORGOT-PASSWORD'}
            onPress={() => {
              Promise.resolve().then(()=>{
                setIsSFUser(null)
                setState({username:"",email:""})
              }).then(()=>{
                setModal({
                  visible: true,
                  text: '',
                });
              })
            }}
            color={primaryColor}
          >
            {' '}
            Click here
          </Typography>
        </Typography>
      </RowContainer>

      <View
        style={{
          height: 1,
          width: '100%',
          backgroundColor: primaryColor,
          marginTop: 20,
        }}
      />

      {isSFUser?.active_user == 'No' && !isSFUser?.last_login ? (
        <View
          style={{
            alignSelf: 'center',
            marginTop: 20,
            // backgroundColor: COLORS.ligntGreen,
            paddingVertical: 24,
            paddingHorizontal: 16,
            borderRadius: 16,
          }}
        >
          <Text
            style={{
              color: 'black',
              fontSize: 20,
              fontWeight: '400',
              textAlign: 'center',
            }}
          >
            If you never logged in, please activate your account below.
          </Text>

          <ButtonWrapper
            color={'white'}
            bcolor={COLORS.BLACK}
            style={{ marginTop: 10, width: 250, height: 49 }}
            onPress={() => loginToActivation()}
          >
            <Text style={{ color: COLORS.BLACK }}>ACTIVATE YOUR ACCOUNT</Text>
          </ButtonWrapper>
        </View>
      ) : null}
      <Hoverable
        onHoverIn={() => handleHoverIn('Credit Builder')}
        onHoverOut={() => handleHoverOut('Credit Builder')}
      >
        <View
          style={{
            alignSelf: 'center',
            marginTop: 20,
            backgroundColor: COLORS.ligntGreen,
            paddingVertical: 24,
            paddingHorizontal: 16,
            borderRadius: 16,
          }}
        >
          <Text
            style={{
              color: 'black',
              fontSize: 20,
              fontWeight: '400',
              textAlign: 'center',
            }}
          >
            {'Did you know you can help boost your '}
            <Text style={{ fontWeight: '600' }}>{'credit score'}</Text>
            {' with tlyfe?'}
          </Text>

          <ButtonWrapper
            color={'white'}
            bcolor={COLORS.LGREEN}
            style={{ marginTop: 10, width: 250, height: 49 }}
            onPress={() => {
              window.location.replace('/credit-builder');
            }}
          >
            <Text style={{ color: COLORS.LGREEN }}>Go To Credit Builder</Text>
          </ButtonWrapper>
        </View>
      </Hoverable>

      {/* <Hoverable
        onHoverIn={() => handleHoverIn('LandingInsurance')}
        onHoverOut={() => handleHoverOut('LandingInsurance')}
      >
        <View
          style={{
            alignSelf: 'center',
            marginTop: 20,
            backgroundColor: COLORS.ligntGreen,
            paddingVertical: 24,
            paddingHorizontal: 16,
            borderRadius: 16,
          }}
        >
          <Text
            style={{
              color: 'black',
              fontSize: 20,
              fontWeight: '400',
              textAlign: 'center',
            }}
          >
            {'Protect more than just your deposit, with Tenant Contents Insurance!'}
          </Text>
          <ButtonWrapper
            color={'white'}
            bcolor={COLORS.LGREEN}
            style={{ marginTop: 10, width: 250, height: 49 }}
            onPress={() => {
              // window.open("https://paragonadvance.com/tlyfe-from-openbrix/");
              navigateAction(props.navigation, 'TenantInsurance');
            }}
          >
            <Text style={{ color: COLORS.LGREEN }}>Get a free quote today!</Text>
          </ButtonWrapper>
        </View>
      </Hoverable> */}
      <Modal
        isVisible={modal.visible}
        transparent={true}
        coverScreen={false}
        hasBackdrop={false}
        backdropOpacity={0}
        onBackdropPress={() =>
          setModal({
            visible: false,
            text: '',
          })
        }
        style={{
          margin: 0,
          width: '100%',
          flex: 1,
          backgroundColor: '#00000080',
          paddingVertical: layoutType == 'phone' ? '5%' : '1%',
          paddingHorizontal: layoutType == 'phone' ? '8%' : '3%',
        }}
      >
        <View
          style={{
            width: layoutType == 'phone' ? '365px' : '500px',
            backgroundColor: '#FAFAFA',
            borderRadius: 20,
            overflow: 'hidden',
            alignSelf: 'center',
            padding: layoutType == 'phone' ? '5%' : '2%',
          }}
        >
          <BoxShadow
            style={{
              backgroundColor: '#C4F5D8',
              paddingHorizontal: 30,
              paddingVertical: 20,
            }}
          >
            <Typography id={'TDS-FORGOT-PASSWORD-TEXT'} color={'#4C664B'} size={'23px'} bold={800}>
              Forgot Password
            </Typography>
          </BoxShadow>
          {isSFUser == null ? (
            <>
              <Typography top={'33px'} color={'#000'} align={'center'} bold={800}>
                Please enter your email to continue
              </Typography>
              <CustomTextInput
                id="username"
                width={'100%'}
                title="Email"
                type="text"
                name="email"
                prefilledValue={state.username}
                bColor={'#638ACC'}
                onChangeText={(value) => handleChange(value, 'username')}
              />
              <ButtonWrapper
                bcolor={'#638ACC'}
                color={'#FFFFFF'}
                width={'80%'}
                style={{ marginTop: 30 }}
                onPress={() => {
                 closeModal()
                }}
                id={'TDS-FORGOT-PASSWORD-CANCEL'}
              >
                <Typography color={'black'} size={'19px'} bold={700}>
                  Cancel
                </Typography>
              </ButtonWrapper>
              <ButtonWrapper
                color={'#638ACC'}
                width={'80%'}
                style={{ marginTop: 20 }}
                id={'TDS-FORGOT-PASSWORD-OPEN'}
                onPress={() => {
                  checkUserType(true);

                }}
              >
                <Typography color={'#FFFFFF'} size={'19px'} bold={700}>
                  Continue
                </Typography>
              </ButtonWrapper>
            </>
          ) : isSFUser?.active_user == 'No'? (
            <>
              <Typography top={'33px'} color={'#000'} align={'center'} bold={800}>
                I’ve forgotten my password
              </Typography>
              <Typography top={'33px'} color={'#454545'} align={'left'} bold={500}>
                The selected response action will have to be continued on the website
              </Typography>

              <ButtonWrapper
                bcolor={'#638ACC'}
                color={'#FFFFFF'}
                width={'80%'}
                style={{ marginTop: 30 }}
                onPress={() => {
                  
                  closeModal()
                }}
                id={'TDS-FORGOT-PASSWORD-CANCEL'}
              >
                <Typography color={'black'} size={'19px'} bold={700}>
                  Cancel
                </Typography>
              </ButtonWrapper>
              <ButtonWrapper
                color={'#638ACC'}
                width={'80%'}
                style={{ marginTop: 20 }}
                id={'TDS-FORGOT-PASSWORD-OPEN'}
                onPress={() => {
                  window.open('https://www.tenancydepositscheme.com/forgotten-password/', '_blank');
                  closeModal()
                }}
              >
                <Typography color={'#FFFFFF'} size={'19px'} bold={700}>
                  OPEN TDS WEBSITE
                </Typography>
              </ButtonWrapper>
            </>
          ) : null}
        </View>
      </Modal>
    </>
  );
};

export default TdsLogin;
