
const webPath= "./web/"
const tabPath= "./tab/"
const mobilePath= "./mobile/"
export const backgroundSearchArr = [
    {
      webUri: "",
      webStyle: {
        width: 115,
        position: 'absolute',
        left: 0,
      },
      tabStyle: {
        width: 126,
        position: 'absolute',
        left: 0,
      },
      mobileStyle: {
        width: 155,
        position: 'absolute',
        left: 0,
      },
      blurRadius:true,
    },
  ];