import React, { Component } from "react";

import {
  BoxButton,
  BoxView,
  BoxText,
  BoxWrapper,
  BoxTextLabel,
  TitleView,
  ImageWrapper,
  IntroImg,
  TitleText,
} from "./tlyfeIntroCard.style";
import { color } from "react-native-reanimated";
import { Text, View } from "react-native";
import WideButton from "../wideButton";
import TlyfeButton from "../tlyfeButton";
import getPlatformType from "../../helpers/getPlatform";
import IntroScreenCard from "./../../components/landingPageTlyfeObx/introScreenCard"
const layoutType = getPlatformType();
const four= [
  {

    text: "tlyfe allows the agent to guide tenants through the moving in process efficiently, securely and compliantly.",
    image: require("../../assets/images/tlyfeImageText.png"),
    chileimage: require("../../assets/images/logoText/movein.png"),
    title: "The Moving In Process",
  },
  {

   text: "Unlimited and secure storage with the facility to share directly to other people on the platform.",
   image: require("../../assets/images/tlyfeImageText.png"),
   chileimage: require("../../assets/images/tlyfeImageText2.png"),
   title: "Document Storage",
 },
  {

    text: "A Government Grade verified and secure Digital ID owned by you the Consumer. ",
    image: require("../../assets/images/tlyfeImageText.png"),
    chileimage: require("../../assets/images/tlyfeImageText1.png"),
    title: "Verified Digital ID",
  },
  {

    text: "Gives you, the tenant, a lifetime record of validated rental payments and helps you increase your credit scores when paying your rent.",
    image: require("../../assets/images/tlyfeImageText.png"),
    chileimage: require("../../assets/images/tlyfeImageText3.png"),
    title: "Rental Passport",
  },
];
const three= [
  {

   text: "Unlimited and secure storage with the facility to share directly to other people on the platform.",
   image: require("../../assets/images/tlyfeImageText.png"),
   chileimage: require("../../assets/images/tlyfeImageText2.png"),
   title: "Document Storage",
 },
  {

    text: "A Government Grade verified and secure Digital ID owned by you the Consumer.",
    image: require("../../assets/images/tlyfeImageText.png"),
    chileimage: require("../../assets/images/tlyfeImageText1.png"),

    title: "Verified Digital ID",
  },
  {

    text: "Gives you, the tenant, a lifetime record of validated rental payments and helps you increase your credit scores when paying your rent.",
    image: require("../../assets/images/tlyfeImageText.png"),
    chileimage: require("../../assets/images/tlyfeImageText3.png"),
    title: "Rental Passport",
  },
]
export default class TlyfeIntroCard extends Component {
  render() {
    const { label, boxStyle, token, navigation ,hideHeader,showButton=true,hideMovein} = this.props;

    return (
      <BoxWrapper>
        <IntroScreenCard
              showButton={showButton}
              navigation={navigation}
              // headerTitle={hideHeader?null:require("./../../assets/images/logoText/tlyfe.png")}
              header={hideHeader?null:"tlyfe"}
              svg={require('../../assets/SVG/tlyfe.svg')}
              style={{width:130,height:60,resizeMode:'contain'}}
              title={hideHeader?null:"Introducing tlyfe, the only tenant ID and passport that you will ever need!"}
              dataArray={hideMovein?three:four
              
              }
            />
           
      </BoxWrapper>
    );
  }
}
