import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Image, TouchableOpacity, View } from 'react-native';
import WideButton from '../../commonComponents/wideButton';
import CustomTextInput from '../../commonComponents/customTextInput/customTextInput';
import { COLORS } from '../../constants/colorPallete';
import { tenantActions } from '../openFlow/openFlowRedux/tenant.actions';
import { Typography } from '../openFlow/style';
import { types } from '../../redux/actionTypes';
import { AntDesign, FontAwesome } from '@expo/vector-icons';
export const PayByVoucher = (props) => {
  const dispatch = useDispatch();
  const [code, setCode] = useState('');
  const [load, setLoad] = useState(false);
  const [valid, setValid] = useState(false);

  const handleValidateCode = () => {
    setLoad(true);
    dispatch(
      tenantActions.payWithVoucher({ voucher: code }, (e) => {
        if (e?.status) {
          setCode('');
          // setValid(code);
          dispatch({
            type: types.SHOW_ALERT,
            alertType: 'success',
            text: "Validate successfully",
          });
          props.onSuccessToken(true)
          
        } else {
          dispatch({
            type: types.SHOW_ALERT,
            alertType: 'error',
            text: e?.message,
          });
          setValid(false);
        }
        setLoad(false);
      })
    );
  };

  const renderPayByVoucher = () => {
    return (
      <>
      <Typography size={14} bottom={15} color={COLORS.GREY1} align={'left'}>By paying with agent voucher, you also get the rent ready reference included</Typography>
        <CustomTextInput
          id="password"
          width={'100%'}
          placeHolder="Type here"
          name="code"
          marginTop={15}
          title={'Enter voucher code'}
          showError={true}
          prefilledValue={code}
          bColor={COLORS.LGREEN}
          bRadius={4}
          onChangeText={setCode}
        />
        {valid && (
          <View style={{ flex: 1, justifyContent: 'flex-end', alignItems: 'flex-end' }}>
            <View
              style={{
                marginVertical: 10,
                paddingHorizontal: 10,
                paddingVertical: 5,
                borderWidth: 1,
                borderColor: COLORS.LGREEN,
                borderRadius: 1000,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography size={14} color={COLORS.LGREEN}>
                {valid}
              </Typography>
              <TouchableOpacity
                onPress={() => setValid(false)}
                style={{
                  width: 16,
                  height: 16,
                  backgroundColor: COLORS.LGREEN,
                  position: 'absolute',
                  borderRadius: 100,
                  right: -7,
                  top: -5,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <AntDesign name="close" size={10} color="white" />
              </TouchableOpacity>
            </View>
          </View>
        )}
        <View
          style={{
            marginTop: 10,
            alignSelf: 'flex-end',
          }}
        >
          <WideButton
            type="submit"
            width={200}
            loadingState={load}
            onPress={code != '' && handleValidateCode}
            isDisabled={code == '' && true}
            childText={'Validate & Submit'}
            gradiant
            isLGreen
            alignSelf={'flex-end'}
          />
        </View>
        {valid && (
          <View
            style={{
              marginVertical: 30,
              alignSelf: 'flex-end',
            }}
          >
            <WideButton
              type="submit"
              width={200}
              id={'stripesubmitoder'}
              onPress={() => props.onSuccessToken(true)}
              // isDisabled={!stripe || !elements}
              childText={'Submit order'}
              gradiant
              isLGreen
              alignSelf={'flex-end'}
            />
          </View>
        )}
      </>
    );
  };

  return (
    <View>   
      {renderPayByVoucher()}
    </View>
  );
};

export default PayByVoucher;
