import React, { useState } from "react";
import {
    View,
    Text,
    SafeAreaView,
    Image,
    TouchableOpacity,
    Platform,
    ScrollView,
    Button,
} from "react-native";
import {
    Container,
    SectionWrapper,
    DualView,
    TextBox,
    SectionTitle,
    SectionText,
    ImageBox,
    TeamsSection,
    MemberBoxWrapper,
    MemberBox,
    MemberImage,
    MemberName,
    MemberNameText,
    MemberDetails,
    MemberDetailsText,
    MemberHeader,
    TeamHeader
} from "./styles";

import getPlatformType from "../../helpers/getPlatform";
const layoutType = getPlatformType();

import Header from "../../commonComponents/headerComponent/headerComponent";
import Footer from "../../commonComponents/footerComponent/customFooter";
import TripleTabs from "../../commonComponents/tripleTabs/tripleTabs";

const TabAgentPartnership = (props) => {
    return (
        <SectionWrapper>
            <DualView
                style={{
                    flexDirection: layoutType !== 'phone' ? 'row-reverse' : 'column'
                }}>
                <ImageBox

                >
                    <Image
                        source={require("../../assets/images/Next_Generation_Portal.jpg")}
                        style={{ width: "100%", height: "100%", resizeMode: "contain" }}
                    ></Image>
                </ImageBox>
                <TextBox>
                    <SectionTitle>The next generation property portal</SectionTitle>
                    <SectionText>
                        OpenBrix is set to revolutionize the property portal market through the introduction of
                        the first UK wide transparent Multi-Listing Service (MLS), increased earning potential
                        through the monetisation of property portfolios through partnership commission,
                        access to tenant information’s including payment history and maintenance tickets, as
                        well as a whole bunch of end consumer benefits not seen on any property portal
                        before.
          </SectionText>

                </TextBox>

            </DualView>
            <DualView>
                <ImageBox>
                    <Image
                        source={require("../../assets/images/MLS.jpg")}
                        style={{ width: "100%", height: "100%", resizeMode: "contain" }}
                    ></Image>
                </ImageBox>
                <TextBox>
                    <SectionTitle>The worlds first MLS using blockchain technology</SectionTitle>
                    <SectionText>
                        Thanks to technology innovations such as the blockchain agents are now able to
                        connect online and setup their own bespoke networks, agree commission structures,
                        marketing rights and of course each agreement is immutable and transparent,
                        ultimately enabling agents to proactively work for both buyers and tenants
                        collaboratively.
          </SectionText>
                    <SectionText>
                        In today’s variable market conditions we need to collaborate and work together,
                        whether you are an agent short on supply or short on demand, now is the time to join
                        the MLS!
          </SectionText>
                </TextBox>
            </DualView>
            <DualView
                style={{
                    flexDirection: layoutType !== 'phone' ? 'row-reverse' : 'column'
                }}>
                <ImageBox

                >
                    <Image
                        source={require("../../assets/images/Partnerships.jpg")}
                        style={{ width: "100%", height: "100%", resizeMode: "contain" }}
                    ></Image>
                </ImageBox>
                <TextBox>
                    <SectionTitle>Earn without having to up-sell (partnerships)</SectionTitle>
                    <SectionText>
                        Through our partnerships we will be bringing this technology to full effect. Once a
                        tenant has been partnered with a branch for an intending tenancy.
                        Our partners will have full compliant access to the prospective tenant, with their
                        direct instruction and consent, to up sell various benefits. The agent branch will
                        receive commission for doing so without OpenBrix benefitting in anyway.
          </SectionText>

                </TextBox>

            </DualView>
        </SectionWrapper>
    );
};


export default TabAgentPartnership;
