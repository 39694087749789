import { types } from "../../actionTypes";

const appReducer = (
  state = {
    loginData: null,
    searchObject:null,
    sortValue:null,
    mobileData:null
  },
  action
) => {
  switch (action.type) {
    case types.SAVE_AUTH_TOKEN: {
      return {
        ...state,
        loginData: action.payload,
      };
    }
    case types.saveAuthTokenFromMobile: {
      return {
        ...state,
        loginLoading: false,
        loginData: action.payload,
        loginStatus: action.payload.status,
        mobileData: action.payload,
      };
    }
    case types.REMOVE_AUTH_TOKEN: {
      return {
        ...state,
        loginData: null,
      };
    }
    case types.SAVE_SEARCH_OBJECT: {
      return {
        ...state,
        searchObject: action.payload,
      };
    }
    case types.REMOVE_SEARCH_OBJECT: {
      return {
        ...state,
        searchObject: null,
      };
    }
    case types.SAVE_SORT_VALUE: {
      return {
        ...state,
        sortValue: action.payload,
      };
    }
    case types.SAVE_TDS_SELECTION: {
      return {
        ...state,
        tdsLocationSelected: action.payload,
      };
    }
    
    default:
      return state;
  }
};

export default appReducer;
