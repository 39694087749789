import React from "react";
import { Text, View } from "react-native";
import { ODSIcon } from "../atoms/ODSIcon";
import { ODSInput } from "../atoms/ODSInput";
import { ODSTextArea } from "../atoms/ODSTextArea";
import { ODSCombobox } from "../atoms/ODSComboBox";
const combodata = [{
  value: 1,
  label: 'Phoenix Baker'
}, {
  value: 2,
  label: 'Olivia Rhye'
},
{
  value: 3,
  label: 'Phoenix Baker'
},
{
  value: 4,
  label: 'Lana Steiner'
},
{
  value: 5,
  label: 'Phoenix Baker'
}
];
export const Input = () => {
  return (
    <View>
      <Text style={{ textAlign: 'center', marginVertical: 10, fontSize: 28, fontWeight: 'bold' }}>Input</Text>
      <View style={{ width: '100%', flexDirection: 'row', flexWrap: 'wrap', gap: 30, padding: 30 }}>
        <ODSInput
          placeholder="Placeholder"
          hints={[{ hint: 'This is a hint text to help user.', state: 'default' }]}
          label="Email"
        />
        <ODSInput
          placeholder="Placeholder"
          hints={[{ hint: 'This is a hint text to help user.', state: 'default' }]}
          label="Email"
          value={'olivia@untitledui.com'}
        />
        <ODSInput
          placeholder="Placeholder"
          hints={[{ hint: 'This is a hint text to help user.', state: 'disabled' }]}
          label="Email"
          value={'olivia@untitledui.com'}
          disabled
        />
        <ODSInput
          placeholder="Placeholder"
          hints={[{ hint: 'This is a hint text to help user.', state: 'error' }]}
          label="Email"
          value={'olivia@untitledui.com'}
          error
        />
        <ODSInput
          placeholder="Placeholder"
          hints={[{ hint: 'This is a hint text to help user.', state: 'default' }]}
          label="Email"
          leftIcon="TB-Calendar"
          value={'olivia@untitledui.com'}
        />
        <ODSTextArea
          placeholder="Description"
          hints={[{ hint: 'This is a hint text to help user.', state: 'default' }]}
          label="Description"
        />
        <ODSTextArea
          placeholder="Description"
          hints={[{ hint: 'This is a hint text to help user.', state: 'disabled' }]}
          label="Description"
          value={'olivia@untitledui.com'}
          disabled
        />
        <ODSTextArea
          placeholder="Description"
          hints={[{ hint: 'This is a hint text to help user.', state: 'error' }]}
          label="Description"
          value={'olivia@untitledui.com'}
          error
        />
        <ODSTextArea
          placeholder="Description"
          hints={[{ hint: 'This is a hint text to help user.', state: 'error' }]}
          label="Description"
          value={'olivia@untitledui.com'}
          error
        />

      </View>
      <ODSCombobox
        label="Label"
        placeholder="Select one"
        onChange={() => { }}
        width={320}
        height={44}
        hints={[{ hint: 'This is a hint text to help user.', state: 'default' }]}
        data={combodata}
      />
      <ODSCombobox
        label="Label"
        placeholder="Select one"
        onChange={() => { }}
        width={320}
        height={44}
        hints={[{ hint: 'This is a hint text to help user.', state: 'error' }]}
        data={combodata}
        error
      />
      <ODSCombobox
        label="Label"
        placeholder="Select one"
        onChange={() => { }}
        width={320}
        height={44}
        hints={[{ hint: 'This is a hint text to help user.', state: 'disabled' }]}
        data={combodata}
        disabled
      />

      <Text style={{ backgroundColor: '#3a3b3c', color: 'white', padding: 10, textAlign: 'center' }}>{`
  import { ODSInput,ODSTextArea } from 'openbrix-design-system';

  <ODSInput
  placeholder="Placeholder"
  hints={[{hint:'This is a hint text to help user.',state:'disabled'}]}
  label="Email"
  value={'olivia@untitledui.com'}
  disabled
  />
  <ODSTextArea
  placeholder="Placeholder"
  hints={[{hint:'This is a hint text to help user.',state:'disabled'}]}
  label="Email"
  value={'olivia@untitledui.com'}
  disabled
  />
  `}</Text>
    </View>

  )
}