import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { Picker } from 'react-native-web';
import { Entypo } from '@expo/vector-icons';
import { COLORS } from '../../../../constants/colorPallete';
import getPlatformType from '../../../../helpers/getPlatform';
const CustomPicker = (props) => {
  const layout = getPlatformType();
  return (
    <View
      style={{
        width: layout == 'phone' ? '100%' : '30%',
        height: 40,
        marginVertical: layout == 'phone' ? 10 : 0,
      }}
    >
      <Picker
        selectedValue={props?.value}
        style={styles.picker}
        onValueChange={(itemValue, itemIndex) => props?.onSelect(itemValue)}
      >
        <Picker.Item label={props?.label} value={null} />
        {props?.data.map((item, index) => (
          <Picker.Item key={index} label={item.label} value={item.value} />
        ))}
      </Picker>
      <Entypo
        name="chevron-down"
        size={18}
        color={COLORS.LGREEN}
        style={{
          height: 15,
          width: 15,
          position: 'absolute',
          right: 5,
          backgroundColor: 'white',
          top: 10,
        }}
      />
    </View>
  );
};
const styles = StyleSheet.create({
  picker: {
    width: '100%',
    height: '100%',
    color: '#B2b2b2',
    borderColor: '#B2B2B2',
    borderWidth: 1,
    borderRadius: 4,
    paddingLeft: 5,
    fontFamily: 'Raj',
    fontSize: 20,
  },
});
export default CustomPicker;
