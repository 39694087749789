import React, { useState, useEffect } from 'react';
import { View, Image, Dimensions, Text, StyleSheet, TouchableOpacity } from 'react-native';
import DropDownPicker from '../../../commonComponents/DropDownPicker';
import { useDispatch, useSelector } from 'react-redux';
import { tenantActions } from './../openFlowRedux/tenant.actions';
const { width: deviceWidth } = Dimensions.get('window');
import getPlatformType from '../../../helpers/getPlatform';
import { openFlow } from '../../../constants/openFlo';
let layoutType = getPlatformType();
import { MainSection } from '../style';
import BackGroundGenerator from '../../LandingPages/common/BackGroundGenerator';
import { BoxShadow } from '../../tds/style';

import { ODSIcon } from '../../../../openbrix-design-system/ui/atoms/ODSIcon';
import { getTokens } from '../../../../openbrix-design-system/design-tokens/color-tokens';
import { ODSText } from '../../../../openbrix-design-system/ui/atoms/ODSText';
import ODSButton from '../../../../openbrix-design-system/ui/atoms/ODSButton';
import { ODSInput } from '../../../../openbrix-design-system/ui/atoms/ODSInput';
import ODSBadges from '../../../../openbrix-design-system/ui/atoms/ODSBadges';

const TenantDetails = (props) => {
  if (deviceWidth >= 768 && deviceWidth < 992) {
    layoutType = 'ipad';
  }
  const idverification = useSelector(
    (state) => state?.authReducer?.profile?.data?.appUser?.idverification
  );
  const userId = useSelector((state) => state.authReducer?.profile?.data?.appUser?.app_user_id);

  const dispatch = useDispatch();
  const [flowId, setFlowId] = React.useState(null);
  const [isEdit, setIsEdit] = React.useState(false);
  const [titleError, setTitleError] = React.useState(false);
  const [showTeanants, setShowTeanants] = React.useState(layoutType == 'phone' ? false : true);
  const [openFlows, setOpenFlowData] = React.useState({});
  const [formFields, setFormFields] = useState({
    firstName: '',
    lastName: '',
    contactNumber: '',
    email: '',
    post_code: '',
    current_address: '',
    title: '',
    isConfirmed: false,
  });
  const getCurrentTenant = () => {
    return openFlows?.tenants?.find((tenant) => tenant?.app_user_id == userId);
  };
  const getOtherTenants = () => {
    return openFlows?.tenants?.filter((tenant) => tenant?.app_user_id != userId);
  };

  useEffect(() => {
    if (props?.route?.params?.id) {
      callOpenFlowApi();
    }
  }, [props?.route?.params?.id]);
  const callOpenFlowApi = () => {
    dispatch(
      tenantActions.getOpenflowData(props?.route?.params?.id, (openflowData) => {
        setOpenFlowData(openflowData);
      })
    );
  };

  useEffect(() => {
    let dataaa = getCurrentTenant();

    if (dataaa?.app_user?.user) {
      let payload = dataaa?.app_user?.user;
      setFormFields({
        firstName: payload?.first_name,
        lastName: payload?.last_name,
        contactNumber: payload?.contact_number,
        email: payload?.email,
        post_code: payload.postcode,
        current_address: payload.address,
        title: payload?.title,
        isConfirmed: dataaa?.tenant_detail_confirmed,
        is_lead: dataaa?.is_lead
      });
    }
  }, [getCurrentTenant()]);

  const handleChange = (value, field) => {
    setFormFields({
      ...formFields,
      [field]: value,
    });
  };

  const _onSave = () => {
    if (isEdit) {
      if (formFields.title != 'Please select one') {
        let payloadToSend = {
          first_name: formFields.firstName,
          last_name: formFields.lastName,
          contact_number: formFields.contactNumber,
          email: formFields?.email,
          postcode: formFields?.post_code,
          current_address: formFields?.current_address,
          user_type: 0,
          title: formFields?.title,
          isConfirmed: true,
        };
        setIsEdit(!isEdit);
        dispatch(
          tenantActions.saveTeanantDetails({ user: payloadToSend }, flowId, '', () => {
            callOpenFlowApi();
          })
        );
      } else {
        setTitleError(true);
      }
    } else {
      setIsEdit(true);
    }
  };
  const _onConfirm = () => {
    let payloadToSend = {
      first_name: formFields.firstName,
      last_name: formFields.lastName,
      contact_number: formFields.contactNumber,
      email: formFields?.email,
      post_code: formFields?.post_code,
      current_address: formFields?.current_address,
      user_type: 0,
      title: formFields?.title,
      isConfirmed: true,
    };
    dispatch(
      tenantActions.saveTeanantDetails(
        { user: payloadToSend },
        flowId,
        'Details confirmed Successfully!',
        () => {
          callOpenFlowApi();
        }
      )
    );
  };
  const themeObj = getTokens('light').colors;
  const renderViewOnlyForm = () => {
    return (
      <View style={{ flexDirection: 'column' }}>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            alignSelf: 'center',
            marginBottom: 20,
          }}
        >
          <ODSIcon color={themeObj.main.blue[400]} icon={'idref'} size={26} />
          <ODSText marginLeft={10} textAlign={'center'} color="black" type={'h5'}>
          {formFields?.is_lead ? "Lead Tenant":"Tenant" }
          </ODSText>
        </View>
        <View style={[styles.rowFlexView, { borderWidth: 0 }]}>
          <View style={styles.rowItem}>
            <ODSText color="black" type={'button'}>
              Title
            </ODSText>
            <ODSText marginTop={8} color="black" type={'sm'}>
              {formFields?.title}
            </ODSText>
          </View>
          <View style={styles.rowItem}>
            <ODSText color="black" type={'button'}>
              First Name
            </ODSText>
            <ODSText marginTop={8} color="black" type={'sm'}>
              {formFields.firstName}
            </ODSText>
          </View>
        </View>

        <View style={[styles.rowFlexView, { borderWidth: 0 }]}>
          <View style={styles.rowItem}>
            <ODSText color="black" type={'button'}>
              Last Name
            </ODSText>
            <ODSText marginTop={8} color="black" type={'sm'}>
              {formFields.lastName}
            </ODSText>
          </View>
          <View style={styles.rowItem}>
            <ODSText color="black" type={'button'}>
              Contact Number
            </ODSText>
            <ODSText marginTop={8} color="black" type={'sm'}>
              {formFields.contactNumber}
            </ODSText>
          </View>
        </View>
        <View style={[styles.rowFlexView, { borderWidth: 0 }]}>
          <View style={styles.rowItem}>
            <ODSText color="black" type={'button'}>
              Current Address
            </ODSText>
            <ODSText marginTop={8} color="black" type={'sm'}>
              {formFields.current_address}
            </ODSText>
          </View>
          <View style={styles.rowItem}>
            <ODSText color="black" type={'button'}>
              Post Code
            </ODSText>
            <ODSText marginTop={8} color="black" type={'sm'}>
              {formFields.post_code}
            </ODSText>
          </View>
        </View>
        <View style={[styles.rowFlexView, { borderWidth: 0 }]}>
          <View style={styles.rowItem}>
            <ODSText color="black" type={'button'}>
              Email Address
            </ODSText>
            <ODSText marginTop={8} color="black" type={'sm'}>
              {formFields.email}
            </ODSText>
          </View>
        </View>
        <View
          style={{
            flexDirection: layoutType === 'phone' ? 'column' : 'row',
            width: layoutType !== 'phone' ? '100%' : '100%',
            alignItems: layoutType !== 'phone' ? 'center' : 'normal',
            justifyContent: 'space-between',
            marginTop: 20,
          }}
        >
          <View style={{ flex: 1 }}>
            <ODSButton
              rIcon={'TB-Edit'}
              restStyle={{ width: '100%', marginRight: layoutType === 'phone' ? 0 : 10 }}
              type="secondary"
              disabled={false}
              onPress={() => _onSave()}
            >
              {'Edit'}
            </ODSButton>
          </View>
          <View
            style={{
              flex: 1,
              marginLeft: layoutType === 'phone' ? 0 : 10,
              marginTop: layoutType === 'phone' ? 20 : 0,
            }}
          >
            <ODSButton
              rIcon={formFields?.isConfirmed ? 'TB-Check' : null}
              restStyle={{ width: '100%' }}
              type="primary"
              disabled={false}
              onPress={() => (isEdit ? null : formFields?.isConfirmed ? null : _onConfirm())}
            >
              {formFields?.isConfirmed ? 'Confirmed' : 'Confirm'}
            </ODSButton>
          </View>
        </View>
      </View>
    );
  };
  const renderEditForm = () => {
    return (
      <View style={{ flexDirection: 'column' }}>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            alignSelf: 'center',
            marginBottom: 20,
          }}
        >
          <ODSIcon color={themeObj.main.blue[400]} icon={'idref'} size={26} />
          <ODSText marginLeft={10} textAlign={'center'} color="black" type={'h5'}>
          {formFields?.is_lead ? "Lead Tenant":"Tenant" }
          </ODSText>
        </View>

        <View
          style={{
            marginBottom: 10,
            width: '100%',
          }}
        >
          <ODSText
            type="sm-bold"
            color={!isEdit ? themeObj.disabled['text'] : themeObj.text['primary']}
            paddingVertical={4}
          >
            Title
          </ODSText>
          <DropDownPicker
            items={openFlow?.titles.map((salutation) => {
              return { value: salutation, label: salutation };
            })}
            id="openflo-Title"
            placeholder="Title"
            defaultValue={formFields?.title}
            onChangeItem={(e) => {
              
              handleChange(e?.value, 'title');
              setTitleError(false);
            }}
            dropDownStyle={{ backgroundColor: 'white' }}
            zIndex={1001}
            disabled={isEdit ? false : true}
            error={titleError}
          />
        </View>

        <ODSInput
          placeholder="First Name"
          label="First Name"
          value={formFields.firstName}
          disabled={idverification == 2 ? true : isEdit ? false : true}
          width={'100%'}
          onChange={(value) => {
            handleChange(value, 'firstName');
          }}
          hints={
            formFields.firstName
              ? []
              : [{ hint: 'First Name is a required Field.', state: 'error' }]
          }
          error={formFields.firstName ? false : true}
        />
        <ODSInput
          placeholder="Last Name"
          label="Last Name"
          value={formFields.lastName}
          disabled={idverification == 2 ? true : isEdit ? false : true}
          width={'100%'}
          onChange={(value) => {
            handleChange(value, 'lastName');
          }}
          hints={
            formFields.lastName ? [] : [{ hint: 'Last Name is a required Field.', state: 'error' }]
          }
          error={formFields.lastName ? false : true}
        />
        <ODSInput
          placeholder="Contact Number"
          label="Contact Number"
          value={formFields.contactNumber}
          disabled={isEdit ? false : true}
          width={'100%'}
          onChange={(value) => {
            handleChange(value.replace(/[^0-9]/g, ''), 'contactNumber');
          }}
        />
        <ODSInput
          placeholder="Email address"
          label="Email address"
          value={formFields.email}
          disabled={ true}
          width={'100%'}
          onChange={(value) => {
            handleChange(value, 'email');
          }}
        />

        <ODSInput
          placeholder="Current Address"
          label="Current Address"
          value={formFields.current_address}
          disabled={isEdit ? false : true}
          width={'100%'}
          onChange={(value) => {
            handleChange(value, 'current_address');
          }}
        />
        <ODSInput
          placeholder="Post Code"
          label="Post Code"
          value={formFields.post_code}
          disabled={isEdit ? false : true}
          width={'100%'}
          onChange={(value) => {
            handleChange(value, 'post_code');
          }}
        />
        <View
          style={{
            flex: 1,
          }}
        >
          <ODSButton
            restStyle={{
              width: layoutType == 'phone' ? '100%' : 300,
              alignSelf: 'flex-end',
              marginTop: 30,
            }}
            type="primary"
            disabled={false}
            onPress={() => _onSave()}
          >
            Save Information
          </ODSButton>
        </View>
      </View>
    );
  };
  const renderProfileForm = () => {
    return <>{!isEdit ? renderViewOnlyForm() : renderEditForm()}</>;
  };
  const getDisplayName = (tenant) => {
    return `${tenant?.app_user?.user?.first_name} ${tenant?.app_user?.user?.last_name}`;
  };
  const renderOtherTeanant = () => {
    let ten = getOtherTenants();
    return (
      <>
        <TouchableOpacity
          onPress={() => setShowTeanants(!showTeanants)}
          disabled={layoutType == 'phone' ? false : true}
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            alignSelf: 'center',
            padding: layoutType == 'phone' ? 6 : 0,
          }}
        >
          {layoutType == 'phone' ? (
            <ODSIcon
              style={{ marginRight: 20 }}
              color={themeObj.main.blue[400]}
              icon={showTeanants ? 'TB-Caret-Down' : 'TB-Caret-Up'}
              size={26}
            />
          ) : null}
          <ODSText marginLeft={10} textAlign={'center'} color="black" type={'h5'}>
            {layoutType == 'phone'
              ? showTeanants
                ? 'Hide other tenants status'
                : 'Show other tenants status'
              : 'Other tenants'}
          </ODSText>
        </TouchableOpacity>
        {showTeanants ? (
          <View style={{ flex: 1 }}>
            {ten && ten.length
              ? ten.map((tenant, index) => (
                  <View
                    key={tenant?.app_user?.opbrix_id}
                    style={{
                      flexDirection: 'column',
                      width: '100%',
                      borderTopColor: '#D9D9D9',
                    }}
                  >
                    <View
                      style={{
                        width: '100%',
                        height: 1,
                        backgroundColor: themeObj.neutral[300],
                        marginVertical: 24,
                      }}
                    />
                    <View style={[styles.rowFlexView, { borderBottomWidth: 0 }]}>
                      <ODSText type="sm" fontSize={20} paddingVertical={4}>
                        {getDisplayName(tenant)}
                      </ODSText>
                    </View>
                    <View style={[styles.rowFlexView, { borderWidth: 0 }]}>
                      <View style={styles.rowItem}>
                        <ODSText color="black" type={'button'}>
                          tlyfe ID
                        </ODSText>
                      </View>
                      <View style={[styles.rowItem, { alignItems: 'flex-end' }]}>
                        <ODSText color="black" type={'sm'}>
                          {tenant?.app_user?.opbrix_id}
                        </ODSText>
                      </View>
                    </View>
                    <View style={[styles.rowFlexView, { borderWidth: 0 }]}>
                      <View style={styles.rowItem}>
                        <ODSText color="black" type={'button'}>
                          Details confirmed
                        </ODSText>
                      </View>
                      <View style={styles.rowItem}>
                        <ODSBadges
                          restStyle={{ alignSelf: 'flex-end' }}
                          type={tenant?.tenant_detail_confirmed ? 'Completed' : 'Not Started'}
                        >
                          {tenant?.tenant_detail_confirmed ? 'Confirmed' : 'Not Confirmed'}
                        </ODSBadges>
                      </View>
                    </View>
                  </View>
                ))
              : null}
          </View>
        ) : null}
      </>
    );
  };
  return (
    <BackGroundGenerator
      props={props}
      isTLYF
      isOpenflowContainer
      openFlowData={{
        title: 'Tenant',
        desc: 'Are your details correct? You are about to be “paired” to the property under offer and subject to contract. If your details are incorrect, you can change them here and your agent will see the changes.',
        buttonText: openFlows?.tenancy_status,
        message: openFlows?.verification_message,
        listIcon: 'TB-User-Circle',
      }}
    >
      <MainSection
        style={{
          flexDirection: layoutType == 'phone' ? 'column' : 'row',
          justifyContent: 'space-between',
        }}
      >
        <View style={layoutType == 'phone' ? { width: '100%' } : { flex: 0.48 }}>
          <BoxShadow style={{ padding: layoutType == 'phone' ? 12 : 20 }}>
            {renderProfileForm()}
          </BoxShadow>
        </View>
        <View
          style={layoutType == 'phone' ? { flex: 1, marginTop: 26, width: '100%' } : { flex: 0.48 }}
        >
          <BoxShadow style={{ padding: layoutType == 'phone' ? 12 : 20 }}>
            {renderOtherTeanant()}
          </BoxShadow>
        </View>
      </MainSection>
    </BackGroundGenerator>
  );
};

export default TenantDetails;
const styles = StyleSheet.create({
  rowFlexView:
    layoutType === 'phone'
      ? {
          flexDirection: 'row',
          alignItems: 'center',
          borderBottomWidth: 1,
          borderColor: '#D9D9D9',
          marginVertical: 10,
        }
      : {
          flexDirection: 'row',
          alignItems: 'center',
          borderBottomWidth: 1,
          borderColor: '#D9D9D9',
          marginVertical: 10,
        },
  centeredView: {
    flex: 1,
    padding: 20,
    marginTop: 22,
    backgroundColor: 'white',
    borderRadius: 20,
  },
  whiteColor12Regular: { color: '#FFFFFF', fontSize: 12 },
  rowItem: { flexDirection: 'column', flex: 5, alignItems: 'flex-start' },
});
