import { types } from "../../actionTypes";

const alertReducer = (
  state = {
    createAlertLoading: false,
    createAlertData: null,
    createAlertStatus: false,
    myAlertsLoading: false,
    myAlertsData: null,
    myAlertsStatus: false,
    delAlertData: null,
    delAlertStatus: false,
    alertByIdData: null,
    alertByIdStatus: false,
  },
  action
) => {
  switch (action.type) {
    case types.CREATE_ALERT_REQUEST: {
      return {
        ...state,
        createAlertLoading: true,
      };
    }
    case types.CREATE_ALERT_SUCCESS: {
      return {
        ...state,
        createAlertLoading: false,
        createAlertData: action.payload,
        createAlertStatus: action.payload.status,
      };
    }
    case types.CREATE_ALERT_FAILED: {
      return {
        ...state,
        createAlertLoading: false,
        createAlertData: action.payload,
        createAlertStatus: false,
      };
    }
    case types.GET_MY_ALERTS_REQUEST: {
      return {
        ...state,
        myAlertsLoading: true,
      };
    }
    case types.GET_MY_ALERTS_SUCCESS: {
      
      return {
        ...state,
        myAlertsLoading: false,
        myAlertsData: action.payload,
        myAlertsStatus: action.payload.status,
      };
    }
    case types.GET_MY_ALERTS_FAILED: {
      return {
        ...state,
        myAlertsLoading: false,
        myAlertsData: action.payload,
        myAlertsStatus: false,
      };
    }
    case types.GET_ALERT_BY_ID_REQUEST: {
      return {
        ...state,
        createAlertLoading: true,
      };
    }
    case types.GET_ALERT_BY_ID_SUCCESS: {
      return {
        ...state,
        createAlertLoading: false,
        alertByIdData: action.payload,
        alertByIdStatus: action.payload.status,
      };
    }
    case types.GET_ALERT_BY_ID_FAILED: {
      return {
        ...state,
        createAlertLoading: false,
        alertByIdData: action.payload,
        alertByIdStatus: false,
      };
    }
    case types.DEL_ALERT_REQUEST: {
      return {
        ...state,
        alertByIdLoading: true,
      };
    }
    case types.DEL_ALERT_SUCCESS: {
      
      return {
        ...state,
        myAlertsLoading: false,
        delAlertData: action.payload,
        delAlertStatus: action.payload.status,
      };
    }
    case types.DEL_ALERT_FAILED: {
      return {
        ...state,
        myAlertsLoading: false,
        delAlertData: action.payload,
        delAlertStatus: false,
      };
    }
    case types.CLEAR_ALERT: {
      return {
        ...state,
        myAlertsLoading: false,
        alertByIdData: null,
        alertByIdStatus: false,
      };
    }
    default:
      return state;
  }
};

export default alertReducer;
