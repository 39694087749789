import React from "react";
import styled from "styled-components/native";
import getPlatformType from "../../helpers/getPlatform";

const layoutType = getPlatformType();

const DocumentBox = styled.TouchableOpacity`
  border: 0.5px solid grey;
  width: ${(props) => (props?.width ? props?.width  : '230px')};
  height: 160px;
  border-top-right-radius: 17px;
  border-top-left-radius: 17px;
  border-bottom-right-radius: 17px;
  border-bottom-left-radius: 17px;
`;

export { DocumentBox };
