import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { tdsActions } from './tdsRedux/tds.actions';
import { backgroundTdsArr } from './backgroundTdsArr';
import { navigateAction, popAction, replaceAction } from '../../helpers/navigationHelpers';
import BackGroundGenerator from '../LandingPages/common/BackGroundGenerator';
import {
  Container,
  LineSaperator,
  ButtonWrapper,
  BlueBox,
  BoxShadow,
  Typography,
  primaryColor,
} from './style';
import ReCAPTCHA from 'react-google-recaptcha';
import { types } from '../../redux/actionTypes';
import { View } from 'react-native';
import CustomTextInput from '../../commonComponents/customTextInput/customTextInput';
import getPlatformType from '../../helpers/getPlatform';
import Constant from './tdsRedux/Constant';
const layoutType = getPlatformType();
const CustodialForgetPassword = (props) => {
  const dispatch = useDispatch();
  const [type, setType] = useState('web');
  const [otpCalled, setOtpCalled] = useState(false);
  const [state, setState] = useState({
    password: '',
    rePassword: '',
    otp:"",
    captchaToken:''
  });
  const triggerChangePassword = () => {
    if (otpCalled) {
    } else {
      if (state?.captchaToken) {
        let payload = {
          email: props?.route?.params?.email,
          recaptcha: state.captchaToken,
        };
        dispatch(
          tdsActions.forgotPassword(payload, true, (data) => {
            console.log("forgotPassword",data)
            if (data?.status) {
              setOtpCalled(true);
            }
          })
        );
      } else {
        dispatch({
          type: types.SHOW_ALERT,
          alertType: 'error',
          text: 'Please validate recaptcha first!',
        });
      }
    }
  };
  const handleOnChange = (value) => {
    setState({
      ...state,
      recaptchaValue: true,
      captchaToken: value,
    });
  };
  const handleChange = (value, field) => {
    setState({ ...state, [field]: value });
  };
  const tdsLoginResponse = useSelector((state) => state.tds?.tdsLoginResponse);
  useEffect(() => {
    if (tdsLoginResponse) {
      replaceAction(props.navigation, 'Custodial Tenancies');
    }
  }, [tdsLoginResponse]);

  const validatePassword = () => {
    let regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
    if (regex.test(state?.password)) {
      if (state?.rePassword == state?.password) {
        if (state.otp) {
          if (props?.route?.params?.email) {
            let payload = {
              email: props?.route?.params?.email,
              newpassword: state?.password,
              otp: state?.otp,
            };
           dispatch(tdsActions.forgotPassword(
              payload,
              true,
              (data) => {
                if (data?.status) {
                  if(props?.route?.params?.isSdsUser){
                    dispatch(
                      tdsActions.loginToTDS(
                        {
                          username: props?.route?.params?.email,
                          password: state.password,
                        },
                        true
                      )
                    );
                  }else{
                    replaceAction(props.navigation, 'TDS Search Deposit')
                  }
                }
              },
              true
            ))
          } else {
            dispatch({
              type: types.SHOW_ALERT,
              alertType: 'error',
              text: 'Session Expired',
            });
          }
        } else {
          dispatch({
            type: types.SHOW_ALERT,
            alertType: 'error',
            text: 'Please enter otp!',
          });
        }
      } else {
        dispatch({
          type: types.SHOW_ALERT,
          alertType: 'error',
          text: 'Password not matched',
        });
      }
    } else {
      dispatch({
        type: types.SHOW_ALERT,
        alertType: 'error',
        text: 'Invalid password',
      });
    }
  };

  const renderBoxSection = () => {
    return (
      <View
        style={{
          width: layoutType != 'web' ? '100%' : '70%',
          marginTop: 50,
          marginBottom: 50,
        }}
      >
        <BoxShadow
          bottom={'30px'}
          style={layoutType == 'phone' ? {} : { marginRight: 8, flex: 0.5 }}
        > 
        <Typography top={'20px'} color={'#0000002'} size={'23px'} bold={700} bottom={10}>
        Enter OTP
      </Typography>
      <CustomTextInput
            id="otp"
            width={'100%'}
            placeHolder="Enter OTP"
            name="otp"
            // prefilledValue={state?.password}
            bColor={'#638ACC'}
            onChangeText={(value) => handleChange(value, 'otp')}
          />
          <Typography top={'10px'} color={'#0000002'} size={'23px'} bold={700} bottom={10}>
            Confirm Password
          </Typography>
          <Typography
            align={'flex-start'}
            top={'10px'}
            color={'#454545'}
            size={'20px'}
            bold={400}
            text
          >
            Your password must contain at least:{' '}
          </Typography>
          <Typography
            align={'flex-start'}
            top={'10px'}
            color={'#454545'}
            size={'20px'}
            bold={400}
            bottom={10}
          >
            • one capital letter {'\n'}• one lower case letter {'\n'}• one number{'\n'}• one special
            character from {`@$!%*?&£|^~#+=-`}
            {'\n'}• a total of 8 characters or more{'\n'}
            N.B - Your password cannot contain any spaces or a full stop (.)
          </Typography>
          <Typography
            top={'10px'}
            color={'#0000002'}
            size={'23px'}
            bottom={10}
            align={'flex-start'}
            bold={700}
          >
            Please enter your password
          </Typography>
          <CustomTextInput
            id="password"
            width={'100%'}
            placeHolder="Enter Password"
            type="password"
            name="password"
            // prefilledValue={state?.password}
            bColor={'#638ACC'}
            password
            renderRightComponent
            onChangeText={(value) => handleChange(value, 'password')}
          />
          <CustomTextInput
            id="rePassword"
            width={'100%'}
            placeHolder="Re-enter Password"
            type="password"
            name="rePassword"
            password
            renderRightComponent
            // prefilledValue={state?.rePassword}
            bColor={'#638ACC'}
            onChangeText={(value) => handleChange(value, 'rePassword')}
          />
               {props?.route?.params?.isSdsUser?     <Typography
            top={'20px'}
            color={'#0000002'}
            size={'23px'}

            align={'flex-start'}
            bold={700}
          >
        Please Note: When you click "submit",  should your password be suitable, then you will be automatically logged in.
          </Typography>:null}
         
          <ButtonWrapper width={'50%'} style={{ marginTop: 30 }} onPress={() => validatePassword()}>
            <Typography color={'#FFFFFF'} size={'19px'} bold={700}>
              SUBMIT
            </Typography>
          </ButtonWrapper>
        </BoxShadow>
      </View>
    );
  };

  return (
    <>
      <BackGroundGenerator
        isTds
        props={props}
        layoutType={(data) => {
          setType(data);
        }}
        isTLYF
        backgroundArr={backgroundTdsArr}
        isBack={true}
        infoIcon
        backNavigation={() => popAction(props.navigation, 'TDS Search Deposit')}
      >
        <Container>
          {otpCalled ? (
            renderBoxSection()
          ) : (
            <BoxShadow>
            {props?.route?.params?.isSdsUser?
             <>
              <Typography color={primaryColor} size={'23px'} bold={600}>
              Create Password
              </Typography>
              <Typography top={'20px'} color={'#454545'} size={'20px'} bold={400}>
              Pressing the button below will begin the forgot password / create password process              </Typography>
              </>:
              <>
                <Typography color={primaryColor} size={'23px'} bold={600}>
                Forget Password
              </Typography>
              <Typography top={'20px'} color={'#454545'} size={'20px'} bold={400}>
                Pressing the button below will initiate the forget password process.
              </Typography>
              </>
              }
             
              <Typography top={'20px'} color={'#454545'} size={'20px'} bold={400}>
              You will be sent an email containing a One Time Password (OTP)
              </Typography>
              <Typography bottom={'30px'} top={'20px'} color={'#454545'} size={'20px'} bold={400}>
              Click below to sent One Time Passcode (OTP)
              </Typography>
              <LineSaperator />
              <View
                style={{
                  paddingTop: 20,
                  alignItems: 'center',
                }}
              >
                <ReCAPTCHA
                  id={'register-recaptcha'}
                  sitekey={Constant.RECAPTCHA_KEY}
                  onChange={handleOnChange}
                />
              </View>
              <ButtonWrapper
                id={'TDS_CHANGE_MY_PASS'}
                top={'20px'}
                onPress={() => triggerChangePassword()}
              >
                <Typography
                  id={'TDS_CHANGE_MY_PASS_TEXT'}
                  color={'#FFFFFF'}
                  size={'19px'}
                  bold={700}
                >
                  Send OTP
                </Typography>
              </ButtonWrapper>
            </BoxShadow>
          )}
        </Container>
      </BackGroundGenerator>
    </>
  );
};

export default CustodialForgetPassword;
