import React, { useState } from "react";
import { Dimensions, SafeAreaView, ScrollView } from "react-native";
import { ButtonBox, Container, ErrorBox, ErrorMsg, FormBox, Heading, HeadingText, TextBox } from "./styles";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import idx from "idx";

import { changePassword } from "../../redux/actions/auth";

import getPlatformType from "../../helpers/getPlatform";
import Header from "../../commonComponents/headerComponent/headerComponent";
import Footer from "../../commonComponents/footerComponent/customFooter";
import CustomTextInput from "../../commonComponents/customTextInput/customTextInput";
import WideButton from "../../commonComponents/wideButton";
import Loader from "./../../commonComponents/activityIndicator/activityIndicator";
import { PasswordComparisonValidation, PasswordValidation } from "../../helpers/validation";

const layoutType = getPlatformType();

const { height: WINDOW_HEIGHT, width: WINDOW_WIDTH } = Dimensions.get("window");

const ChangePassword = (props) => {
  const [currentPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [retypedPassword, setRetypedPassword] = useState("");
  const [currentPasswordErrorMsg, setCurrentPasswordErrorMsg] = useState("");
  const [newPasswordErrorMsg, setNewPasswordErrorMsg] = useState("");
  const [retypePasswordErrorMsg, setRetypedPasswordErrorMsg] = useState("");
  const [passwordComparisonErrorMsg, setPasswordComparisonErrorMsg] = useState(
    "",
  );

  const { loading } = props;
  const changePassword = () => {
    let currentPassCheck = PasswordValidation(currentPassword);
    let newPassCheck = PasswordValidation(newPassword);
    let retypedPassCheck = PasswordValidation(retypedPassword);
    let passwordComparisonCheck = PasswordComparisonValidation(
      newPassword,
      retypedPassword,
    );
    setPasswordComparisonErrorMsg(
      passwordComparisonCheck.status ? "" : passwordComparisonCheck.msg,
    );

    if (
      currentPassCheck.status &&
      newPassCheck.status &&
      retypedPassCheck.status &&
      passwordComparisonCheck.status
    ) {
      props.changePassword(
        {
          newPassword,
          currentPassword,
        },
        props.navigation.navigate,
      );
    } else {
      setCurrentPasswordErrorMsg(
        currentPassCheck.status ? "" : currentPassCheck.msg,
      );
      setNewPasswordErrorMsg(newPassCheck.status ? "" : newPassCheck.msg);
      setRetypedPasswordErrorMsg(
        retypedPassCheck.status ? "" : retypedPassCheck.msg,
      );
      setPasswordComparisonErrorMsg(
        passwordComparisonCheck.status ? "" : passwordComparisonCheck.msg,
      );
    }
  };
  return (
    <SafeAreaView style={{
      backgroundColor: "#FAFAFA",
      height: "100%",
    }}>
      <Header
        showLogoMob
        showLogoWeb
        showLogoTab
        rightChildLabelTab
        rightChildLabelWeb
        showBackButtonMob
        showBackButtonTab
        {...props}
        isTLYF
      />
      <ScrollView style={{ height: "100%" }}>
        <Container>
          <FormBox>
            <Heading>
              <HeadingText>Change Password</HeadingText>
            </Heading>
            <TextBox>
              <CustomTextInput
                height={WINDOW_HEIGHT * 0.06}
                width="100%"
                borderWidth={1}
                borderColor="grey"
                title={"Old Password"}
                id={"OLD_PASSWORD"}
                password
                renderRightComponent
                onChangeText={(currentPassword) => {
                  setOldPassword(currentPassword);
                  setCurrentPasswordErrorMsg("");
                }}
                showError={currentPasswordErrorMsg == "" ? false : true}
                errorMessage={currentPasswordErrorMsg}
              />
            </TextBox>
            <TextBox>
              <CustomTextInput
                height={WINDOW_HEIGHT * 0.06}
                width="100%"
                borderWidth={1}
                borderColor="grey"
                title={"New Password"}
                id={"NEW_PASSWORD"}
                password
                renderRightComponent
                onChangeText={(newPassword) => {
                  setNewPassword(newPassword);
                  setNewPasswordErrorMsg("");
                }}
                showError={newPasswordErrorMsg == "" ? false : true}
                errorMessage={newPasswordErrorMsg}
              />
            </TextBox>
            <TextBox>
              <CustomTextInput
                height={WINDOW_HEIGHT * 0.06}
                width="100%"
                borderWidth={1}
                borderColor="grey"
                title={"Confirm Password"}
                id={"CONFIRM_PASSWORD"}
                password
                renderRightComponent
                onChangeText={(retypedPassword) => {
                  setRetypedPassword(retypedPassword);
                  setRetypedPasswordErrorMsg("");
                }}
                showError={retypePasswordErrorMsg == "" ? false : true}
                errorMessage={retypePasswordErrorMsg}
              />
            </TextBox>
            {passwordComparisonErrorMsg ? (
              <ErrorBox>
                <ErrorMsg>{passwordComparisonErrorMsg}</ErrorMsg>
              </ErrorBox>
            ) : null}

            <ButtonBox>
              <WideButton
                width={layoutType === "phone" ? "100%" : "40%"}
                borderRadius={15}
                childText="Save"
                id={"SAVE_FORM"}
                gradiant
                onPress={changePassword}
              />
            </ButtonBox>
          </FormBox>
        </Container>
        <Footer {...props} isTLYF/>
      </ScrollView>
      <Loader loading={loading}/>
    </SafeAreaView>
  );
};

const mapStateToProps = (state) => ({
  loading: idx(state, (_) => _.authReducer.changePasswordLoading),
  changePasswordStatus: idx(state, (_) => _.authReducer.changePasswordStatus),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changePassword,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
