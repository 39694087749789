import React from "react";
import styled from "styled-components/native";
import getPlatformType from "../../helpers/getPlatform";
import { ImageBackground } from "react-native";

const layoutType = getPlatformType();
let Title;
let Container;
let Background;
let TabWrapper;
let FormContainer;
let ListSection;
let TitleWrapper;
let PropertyCardWrapper;
let DropDownWrapper,
  DropDownSectionWrapper,
  IntroWrapper,
  TitleTextWrapper,
  ReviewWrapper,
  ButtomSection,
  MainContent,
  ReviewItem,
  ListCol,
  ListRow,
  Row,
  Col,
  FilterInnerView,
  DualOptionalView,
  AlertTitle,
  AlertTitleText,
  AlertMoreInfo,
  AlertMoreInfoText,
  ListHeaderCTAText,
  ListHeaderText,
  ListHeader,
  ListHeaderClearCTA,
  UpperContainer,
  BackBtnBox,
  ErrorMsg,
  NoDataTag;

ListCol = styled.View`
  width: 459px;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 15px;
  margin: 10px auto;
`;
AlertTitle = styled.View`
  height: 22px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 16px;
  margin-right: 16px;
`;
AlertTitleText = styled.Text`
  color: #000000;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
`;
AlertMoreInfo = styled.TouchableOpacity`
  border: 1px solid #d0d0d0;
  border-radius: 16px;
  position: relative;
  padding: 5px 10px;
  height: 30px;
  min-width: 100px;
  margin: 5px 10px 5px 0px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;
AlertMoreInfoText = styled.Text`
  color: #000000;
  font-size: 13px;
  letter-spacing: 0.41px;
  line-height: 16px;
`;
ListHeader = styled.View`
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 10px 20px;
`;
ListHeaderText = styled.Text`
  color: #000000;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 27px;
`;
ListHeaderCTAText = styled.Text`
  color: #000000;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
`;
ListHeaderClearCTA = styled.TouchableOpacity``;

UpperContainer = styled.View`
  width: 1130px;
  margin: 0 auto;
`;
BackBtnBox = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;
NoDataTag = styled.View`
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;
if (layoutType == "phone") {
  Container = styled.View`
    flex: 1;
    background-color: #fafafa;
  `;
  FormContainer = styled.View`
    padding: 8px 16px;
    width: 100%;
  `;
  TitleWrapper = styled.Text`
    color: #000000;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
    margin: 21px 0px 0px 0px;
  `;
  ListSection = styled.View`
    position: absolute;
    top: 10%;
    width: 100%;
    height: 100;
  `;
  Title = styled.Text`
    color: #000;
    text-align: center;
    font-size: 16px;
    margin-top: 15px;
  `;
  Background = styled.View`
    padding: 24px 16px;
  `;
  TabWrapper = styled.View`
    width: 100%;
  `;
  ListHeader = styled.View`
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    padding: 10px 0px;
  `;
  PropertyCardWrapper = styled.View`
    height: 226px;
    margin: 0px 0px 0px 16px;
  `;
  TitleTextWrapper = styled.Text`
    margin: 30px 0px 16px 16px;
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
  `;
  IntroWrapper = styled.View`
    background-color: #ffffff;
    margin: 40px 0px 0px 0px;
    shadow-color: #000;
  `;
  ReviewWrapper = styled.View`
    height: 153px;
    padding: 0 16px 16px 16px;
  `;
  ReviewItem = styled.View`
    width: 100%;
    padding: 16px;
    margin: 8px 8px 8px 0;
  `;
  MainContent = styled.View`
    width: 100%;
  `;
  Row = styled.View`
    width: 100%;
    flex-direction: column;
    margin: auto;
    padding: 0 16px;
  `;
  Col = styled.View`
    flex-grow: 1;
    width: 100%;
  `;
  ListRow = styled.View`
    flex-direction: column;
    width: 100%;
    flex-wrap: wrap;
    margin: 16px auto;
    padding: 0 16px;
    justify-content: space-between;
    background: #ffffff;
    min-height: 65px;
  `;
  ListCol = styled.View`
    width: 100%;
    border-top-right-radius: 15px;
    border-bottom-left-radius: 15px;
    margin: 10px auto;
    background: #ffffff;
  `;
  FilterInnerView = styled.View`
    flex-grow: 1;
  `;
  DualOptionalView = styled.View`
    display: flex;
    flex-direction: column;
  `;
} else if (layoutType == "web") {
  Container = styled.View`
    flex: 1;
    background-color: #fafafa;
  `;
  Title = styled.Text`
    color: #000;
    text-align: center;
    font-size: 16px;
    margin-top: 15px;
  `;
  Background = styled(ImageBackground)`
    height: auto;
    width: "100%";
  `;
  TabWrapper = styled.View``;
  FormContainer = styled.View`
    margin: 0px 16px;
  `;
  TitleWrapper = styled.Text`
    color: #000000;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
    margin: 21px 0px 0px 0px;
  `;
  PropertyCardWrapper = styled.View`
    height: auto;
  `;
  TitleTextWrapper = styled.Text`
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
    margin: 48px 0 24px;
  `;
  IntroWrapper = styled.View`
    background-color: #ffffff;
    margin: 48px 0px 0px 0px;
    shadow-color: #000;
  `;
  ReviewWrapper = styled.View`
    margin-bottom: 66px;
  `;
  ReviewItem = styled.View`
    width: 286px;
    padding: 24px;
  `;
  MainContent = styled.View`
    width: 100%;
  `;
  Row = styled.View`
    flex-direction: row;
    width: 980px;
    margin: auto;
  `;
  Col = styled.View`
    flex-grow: 1;
    width: 384px;
  `;
  FilterInnerView = styled.View`
    flex-grow: 1;
  `;
  DualOptionalView = styled.View`
    display: flex;
    flex-direction: column;
  `;
  ListRow = styled.View`
    flex-direction: row;
    width: 980px;
    height: 100%;
    flex-wrap: wrap;
    margin: 0px auto;
    justify-content: space-between;
  `;
} else {
  Container = styled.View`
    flex: 1;
    background-color: #fafafa;
  `;
  Title = styled.Text`
    color: #000;
    text-align: center;
    font-size: 16px;
  `;
  Background = styled(ImageBackground)`
    resize-mode: contain;
    justify-content: center;
    padding-bottom: 20px;
    width: "100%";
  `;
  TabWrapper = styled.View`
    height: 100%;
    width: 100%;
  `;
  FormContainer = styled.View`
    margin: 0px 16px;
  `;
  TitleWrapper = styled.Text`
    color: #000000;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
    margin: 21px 0px 0px 0px;
  `;
  PropertyCardWrapper = styled.View`
    height: 226px;
    margin: 0px 0px 0px 40px;
  `;
  TitleTextWrapper = styled.Text`
    margin: 48px 0px 16px 40px;
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
  `;
  IntroWrapper = styled.View`
    background-color: #ffffff;
    margin: 40px 0px 0px 0px;
    shadow-color: #000;
  `;
  ReviewWrapper = styled.View`
    height: 153px;
    margin: 0px 0px 40px 40px;
  `;
  ReviewItem = styled.View`
    width: 286px;
    padding: 24px;
  `;
  MainContent = styled.View`
    width: 100%;
  `;
  Row = styled.View`
    flex-direction: row;
    width: 784px;
    margin: auto;
    padding: 0 16px;
  `;
  Col = styled.View`
    flex-grow: 1;
    width: 48%;
  `;
  ListRow = styled.View`
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0px auto;
    width: 784px;
    justify-content: space-between;
    min-height: 80vh;
  `;
  ListCol = styled.View`
    width: 48%;
    border-top-right-radius: 15px;
    border-bottom-left-radius: 15px;
    margin: 10px auto;
  `;
  FilterInnerView = styled.View`
    flex-grow: 1;
  `;
  DualOptionalView = styled.View`
    display: flex;
    flex-direction: column;
  `;
}
DropDownWrapper = styled.View`
  flex-direction: row;
`;
DropDownSectionWrapper = styled.View`
  flex: 0.5;
`;

ButtomSection = styled.TouchableOpacity`
  margin: 10px 0px 16px;
  justify-content: flex-end;
  align-items: flex-end;
`;
ErrorMsg = styled.Text`
  height: 18px;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  margin: 5px 0px;
  padding-right: 2px;
  color: red;
`;
export {
  Container,
  Title,
  Background,
  TabWrapper,
  FormContainer,
  ListSection,
  TitleWrapper,
  DropDownWrapper,
  DropDownSectionWrapper,
  ButtomSection,
  IntroWrapper,
  PropertyCardWrapper,
  ReviewWrapper,
  TitleTextWrapper,
  MainContent,
  ReviewItem,
  ListCol,
  ListRow,
  Row,
  Col,
  FilterInnerView,
  DualOptionalView,
  ErrorMsg,
  AlertTitle,
  AlertTitleText,
  AlertMoreInfo,
  AlertMoreInfoText,
  ListHeaderCTAText,
  ListHeaderText,
  ListHeader,
  ListHeaderClearCTA,
  BackBtnBox,
  UpperContainer,
  NoDataTag,
};
