import React from "react";

import ZopaForm from "./common/ZopaForm";
import TopSection from "./common/TopSection";
import { Container, Content, ContentWrap, Main } from "./styles";
import Footer from "../../commonComponents/footerComponent/customFooter";
import Header from "../../commonComponents/headerComponent/headerComponent";

const ZopaApplication = (props) => {

    return (
        <>
            <Header MenuAccountWeb showDrawerMob showDrawerTab showLogoMob showLogoWeb showLogoTab rightChildLabelTab isTLYF  {...props}/>
            <Container>
                <Main>
                    <TopSection
                        goBack={() => props?.goBack()}
                        title={'Zopa loan'}
                        body={`Please complete the application form below. Upon submission Zopa will check your eligibility for a loan`}
                    />
                    <Content>
                        <ContentWrap>
                            <ZopaForm onNext={(e) => props?.onNext(e)} />
                        </ContentWrap>
                    </Content>
                </Main>
                <Footer isTLYF />
            </Container>
        </>
    )
}

export default ZopaApplication;