import React from 'react';
import { ImageBackground } from 'react-native';
import { Text, View } from 'react-native';
import CircleSvg1 from '../../../../assets/SVG/circle1.svg';
import CircleSvg2 from '../../../../assets/SVG/circle2.svg';
import CircleSvg3 from '../../../../assets/SVG/circle3.svg';
import AutoHeightImage from '../AutoImage';

const ThreeCircle = (props) => {
  let { layoutType } = props;
  let width = props?.size;
  let height = props?.size;
  if (layoutType == 'tab') {
    return <AutoHeightImage style={{ width: 700 }} uri={require('./tab.png')} />;
  } else if (layoutType == 'mobile') {
    return <AutoHeightImage style={{ width: 342 }} uri={require('./mobile.png')} />;
  } else
    return (
      <View style={{ justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
        <ImageBackground
          source={CircleSvg1}
          style={{
            height,
            width,
            borderRadius: 1000,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Text
            style={{
              fontSize: 16,
              fontFamily: 'Lato-Bold',
              color: '#545454',
              marginBottom: props?.size / 6,
            }}
          >
            COMPLIANCE
          </Text>
          <Text
            style={{
              fontFamily: 'Lato',
              color: '#545454',
              fontSize: 12,
              width: 200,
              position: 'absolute',
              right: -props?.size / 1.2,
              top: props?.size / 8,
            }}
          >
            Openflo provides agents with the security of knowing that each element of the tenant
            onboarding process is done properly, adhering to legislation and ensuring compliance
          </Text>
        </ImageBackground>
        <View style={{ flexDirection: 'row', marginTop: -props?.size / 2.5 }}>
          <ImageBackground
            source={CircleSvg2}
            style={{
              height,
              width,
              backgroundColor: '#F9BA8D30',
              borderRadius: 1000,
              marginRight: -props?.size / 8,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Text
              style={{
                fontSize: 16,
                fontFamily: 'Lato-Bold',
                color: '#545454',
                marginRight: props?.size / 7,
              }}
            >
              COMMUNICATION
            </Text>
            <Text
              style={{
                fontFamily: 'Lato',
                color: '#545454',
                fontSize: 12,
                width: 200,
                position: 'absolute',
                left: -props?.size / 1.2,
              }}
            >
              Improved communication between agents and tenants, all tracked and traced and managed
              by the click of a button
            </Text>
          </ImageBackground>
          <ImageBackground
            source={CircleSvg3}
            style={{
              height,
              width,
              backgroundColor: '#F9BA8D30',
              borderRadius: 1000,
              marginLeft: -props?.size / 8,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Text
              style={{
                fontSize: 16,
                fontFamily: 'Lato-Bold',
                color: '#545454',
                marginLeft: props?.size / 7,
              }}
            >
              EFFICIENCY
            </Text>
            <Text
              style={{
                fontFamily: 'Lato',
                color: '#545454',
                fontSize: 12,
                width: 200,
                position: 'absolute',
                right: -props?.size / 1.2,
              }}
            >
              Openflo improves efficiency of the tenant onboarding process by automating manual
              administrative tasks
            </Text>
          </ImageBackground>
        </View>
      </View>
    );
};
export default ThreeCircle;
