import React from 'react';
import { View, Text, StyleSheet } from "react-native"
import { getTokens } from '../../design-tokens/color-tokens';

export const ColorPallete = () => {
    const colorPallete = getTokens('light');
    const renderColors = (colors, prefix = '') => {
        return Object.entries(colors).map(([key, value]) => {
            if (typeof value === 'object') {
                return renderColors(value, `${prefix}${key}-`);
            } else {
                return (
                    <View key={`${prefix}${key}`} style={{
                        width: 70, height: 70, margin: 10, borderRadius: 8, shadowColor: '#101828',
                        shadowOffset: {
                            width: 0,
                            height: 4,
                        },
                        shadowOpacity: 0.05,
                        shadowRadius: 6,
                        elevation: 4
                    }}>
                        <View style={{ borderTopRightRadius: 8, borderTopLeftRadius: 8, width: '100%', height: '50%', backgroundColor: value }} />
                        <View style={{ width: '100%', height: '50%', justifyContent: 'center', alignItems: 'center', backgroundColor: 'white', borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }}>
                            <Text style={styles.colorText}>{`${prefix}${key}`}</Text>
                            <Text style={{ color: 'grey', fontSize: 8, textAlign: 'center', marginTop: 3 }}>{value}</Text>
                        </View>
                    </View>
                );
            }
        });
    };
    return (
        <View style={{ flex: 1 }}>
            <Text style={{ textAlign: 'center', marginVertical: 10, fontSize: 28, fontWeight: 'bold' }}>Colors</Text>
            {Object.entries(colorPallete.colors).map(([category, colors]) => (
                <View key={category} style={styles.categoryContainer}>
                    <Text style={styles.categoryTitle}>{category}</Text>
                    <View style={styles.colorContainer}>
                        {renderColors(colors)}
                    </View>
                </View>
            ))}
            <Text style={{ backgroundColor: '#3a3b3c', color: 'white', padding: 10, textAlign: 'center', marginBottom: 10 }}>{`
 const theme=getTokens('light');

 theme.colors.neutral[100];
 theme.colors.main.blue[100];
 theme.colors.primary
`}</Text>
        </View>
    )
};
const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 10,
    },
    categoryContainer: {
        padding: 10
    },
    categoryTitle: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 10,
    },
    colorContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
    },
    colorBox: {
        width: 100,
        height: 100,
        borderRadius: 5,
        justifyContent: 'center',
        alignItems: 'center',
        margin: 5,
    },
    colorText: {
        color: 'black',
        fontSize: 8,
        textAlign: 'center',
    },
});