import styled from "styled-components/native";
import { COLORS } from "../../constants/colorPallete";
import getPlatformType from "../../helpers/getPlatform";

const layoutType = getPlatformType();

let WideView,
  DualView,
  FormView,
  WideButtonWrapper,
  WideButtonView,
  ButtonLinkView,
  ButtonLinkBox,
  DualViewBox,
  WideHiddenPropButtonWrapper,
  Container,
  ButtonLinkText,
  InnerContainer,
  ServiceCard,
  ServiceDetailCard,
  BackBox,
  Box,
  MainContent,
  ListBox,
  NotificationBox,
  NotificationName,
  NotificationNameText,
  NotificationRemove,
  NotificationRemoveText,
  NotificationDate,
  NotificationBoxLeft,
  NotificationBoxRight,
  NotificationDateText;

ListBox = styled.View`
  margin: 40px 0px;
  width: 67%;
`;
NotificationBox = styled.View`
  width: 100%;
  padding: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid #ececec;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  box-shadow: rgba(128, 128, 128, 0.4) 3px 2px 3px;
  margin-top: 5px;
  margin-bottom: 5px;
  height: 56px;
`;

NotificationName = styled.View`
  flex-grow: 1.5;
  border-right-width: 1px;
  border-right-color: #cecece;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10px;
`;
NotificationNameText = styled.Text`
  justify-content: flex-start;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
`;
NotificationRemove = styled.TouchableOpacity`
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-grow: 1;
  border-right-width: 1px;
  border-right-color: #cecece;
`;
NotificationRemoveText = styled.Text`
  font-size: 13px;
  font-weight: 500;
  margin-left: 10px;
`;
NotificationDate = styled.TouchableOpacity`
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  flex-direction: row;
`;
NotificationDateText = styled.Text`
  font-size: 13px;
  font-weight: 500;
  margin-right: 10px;
  color: ${COLORS.orange};
`;
NotificationBoxRight = styled.View`
  display: flex;
  flex-direction: row;
  width: 45%;
  justify-content: space-between;
`;

NotificationBoxLeft = styled.View`
  display: flex;
  flex-direction: row;
  width: 55%;
  justify-content: space-between;
`;
if (layoutType == "web") {
  Container = styled.View`
    min-height: 80vh;
    width: 100%;
    margin: 0 auto;
    flex: 1;
  `;
  WideButtonWrapper = styled.View`
    margin-top: 20px;
    width: 458px;
  `;
  WideView = styled.View`
    width: 521px;
    margin: 0px 5px;
  `;
  DualView = styled.View`
    width: 257px;
    margin: 0px 5px;
  `;
  DualViewBox = styled.View`
    display: flex;
    flex-direction: row;
  `;
  ButtonLinkText = styled.Text`
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 21px;
  `;

  ButtonLinkBox = styled.View`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 40px;
    width: 554px;
    border-top-right-radius: 7px;
    border-bottom-left-radius: 7px;
    background-color: #ffffff;
    box-shadow: rgba(128, 128, 128, 0.4) 3px 2px 3px;
    align-items: center;
    padding: 0px 30px;
    margin-top: 20px;
  `;
  WideHiddenPropButtonWrapper = styled.View`
    margin-top: 20px;
    width: 279px;
  `;
  Box = styled.View`
    align-items: center;
    justify-content: center;
    height: 40px;
    background-color: #ffffff;
    width: 100%;
    border-radius: 5px;
    margin-bottom: 16px;
  `;

  InnerContainer = styled.View`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
    padding: 50px;
    justify-content: center;
  `;
  ServiceCard = styled.TouchableOpacity`
    background-color: #fff;
    justify-content: center;
    align-items: center;
    margin: 20px 20px;
    height: 120px;
    width: 20%;
    box-shadow: rgba(128, 128, 128, 0.4) 0px 0px 7px;
  `;
  ServiceDetailCard = styled.View`
    background-color: #fff;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin: 20px 20px;
    height: 100px;
    width: 20%;
    padding: 10px;
    box-shadow: rgba(128, 128, 128, 0.4) 0px 0px 7px;
  `;
  BackBox = styled.View`
    position: absolute;
    top: 20px;
    left: 40px;
    width: 100%;
  `;
  MainContent = styled.View`
    width: 1124px;
    margin: 0 auto;
  `;
} else if (layoutType == "phone") {
  Container = styled.View`
    width: 100%;
    margin: 0 auto;
    flex: 1;
  `;
  WideView = styled.View`
    width: 311px;
    margin: 0px 5px;
  `;
  DualView = styled.View`
    width: 311px;
    margin: 0px 5px;
  `;
  WideButtonWrapper = styled.View`
    margin-top: 20px;
    width: 311px;
  `;
  DualViewBox = styled.View`
    display: flex;
    flex-direction: column;
  `;
  ButtonLinkText = styled.Text`
    color: #000000;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
  `;
  ButtonLinkBox = styled.View`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 40px;
    width: 343px;
    border-top-right-radius: 7px;
    border-bottom-left-radius: 7px;
    background-color: #ffffff;
    box-shadow: rgba(128, 128, 128, 0.4) 3px 2px 3px;
    align-items: center;
    padding: 0px 30px;
    margin-top: 20px;
  `;
  WideHiddenPropButtonWrapper = styled.View`
    margin-top: 20px;
    width: 279px;
  `;
  Box = styled.View`
    align-items: center;
    justify-content: center;
    height: 40px;
    background-color: #ffffff;
    width: 100%;
    border-radius: 5px;
    margin-bottom: 16px;
  `;
  InnerContainer = styled.View`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
    padding: 35px 20px;
    justify-content: center;
  `;
  ServiceCard = styled.TouchableOpacity`
    background-color: #fff;
    justify-content: center;
    align-items: center;
    margin: 20px 0px;
    height: 120px;
    width: 100%;
    box-shadow: rgba(128, 128, 128, 0.4) 0px 0px 7px;
  `;
  ServiceDetailCard = styled.View`
    background-color: #fff;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    margin: 20px 20px;
    height: 100px;
    width: 100%;
    padding: 10px;
    box-shadow: rgba(128, 128, 128, 0.4) 0px 0px 7px;
  `;
  BackBox = styled.View`
    position: absolute;
    top: 20px;
    left: 15px;
    width: 100%;
  `;
  MainContent = styled.View`
    width: 100%;
    margin: 0 auto;
    min-height: 70px;
    padding: 0 16px;
  `;
  ListBox = styled.View`
    width: 90%;
    margin: 10px auto;
  `;
  NotificationBox = styled.View`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid #ececec;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: rgba(128, 128, 128, 0.4) 3px 2px 3px;
    margin-top: 5px;
    margin-bottom: 10px;
    padding: 0;
    height: 100px;
  `;

  NotificationName = styled.View`
    flex-grow: 1.5;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
  `;
  NotificationNameText = styled.Text`
    justify-content: flex-start;
    align-items: center;
    font-size: 15px;
  `;
  NotificationRemove = styled.TouchableOpacity`
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-grow: 1;
  `;
  NotificationRemoveText = styled.Text`
    font-size: 13px;
    margin-left: 10px;
  `;
  NotificationDate = styled.TouchableOpacity`
    align-items: center;
    flex-grow: 1;
    flex-direction: row;
    justify-content: center;
    height: auto;
    border-right-width: 1px;
    border-right-color: #cecece;
  `;
  NotificationDateText = styled.Text`
    font-weight: 500;
    margin-right: 10px;
    margin-right: 0;
    font-size: 14px;
    color: ${COLORS.orange};
    `;
  NotificationBoxRight = styled.View`
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 15px;
  `;

  NotificationBoxLeft = styled.View`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    border-bottom-width: 1px;
    border-bottom-color: #cecece;
  `;
} else {
  Container = styled.View`
    width: 100%;
    margin: 0 auto;
    flex: 1;
  `;
  WideView = styled.View`
    width: 512px;
    margin: 0px 5px;
  `;
  DualView = styled.View`
    width: 257px;
    margin: 0px 5px;
  `;
  WideButtonWrapper = styled.View`
    margin-top: 20px;
    width: 448px;
  `;
  DualViewBox = styled.View`
    display: flex;
    flex-direction: row;
  `;

  ButtonLinkText = styled.Text`
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
  `;
  ButtonLinkBox = styled.View`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 40px;
    width: 544px;
    border-top-right-radius: 7px;
    border-bottom-left-radius: 7px;
    background-color: #ffffff;
    box-shadow: rgba(128, 128, 128, 0.4) 3px 2px 3px;
    align-items: center;
    padding: 0px 30px;
    margin-top: 20px;
  `;
  WideHiddenPropButtonWrapper = styled.View`
    margin-top: 20px;
    width: 279px;
  `;
  Box = styled.View`
    align-items: center;
    justify-content: center;
    height: 40px;
    background-color: #ffffff;
    width: 100%;
    border-radius: 5px;
    margin-bottom: 16px;
  `;
  InnerContainer = styled.View`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
    padding: 35px 20px;
    justify-content: center;
  `;
  ServiceCard = styled.TouchableOpacity`
    background-color: #fff;
    justify-content: center;
    align-items: center;
    margin: 20px 20px;
    height: 120px;
    width: 25%;
    box-shadow: rgba(128, 128, 128, 0.4) 0px 0px 7px;
  `;
  ServiceDetailCard = styled.View`
    background-color: #fff;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin: 20px 20px;
    height: 100px;
    width: 25%;
    padding: 10px;
    box-shadow: rgba(128, 128, 128, 0.4) 0px 0px 7px;
  `;
  BackBox = styled.View`
    position: absolute;
    top: 20px;
    left: 20px;
    width: 100%;
  `;
  MainContent = styled.View`
    width: 100%;
    min-height: 80vh;
    padding: 0 60px;
  `;
  ListBox = styled.View`
    margin: 10px 0px;
    width: 80%;
  `;
}
FormView = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 30px 0px;
`;
WideButtonView = styled.View`
  display: flex;
  align-items: center;
  justify-content: center;
`;

ButtonLinkView = styled.View`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export {
  WideView,
  DualView,
  FormView,
  WideButtonWrapper,
  WideButtonView,
  ButtonLinkBox,
  ButtonLinkView,
  DualViewBox,
  ButtonLinkText,
  Container,
  WideHiddenPropButtonWrapper,
  Box,
  InnerContainer,
  ServiceCard,
  ServiceDetailCard,
  BackBox,
  MainContent,
  ListBox,
  NotificationBox,
  NotificationName,
  NotificationNameText,
  NotificationRemove,
  NotificationRemoveText,
  NotificationDate,
  NotificationBoxLeft,
  NotificationBoxRight,
  NotificationDateText,
};
