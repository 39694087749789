import React, { useState, useEffect } from "react";

import {
  View,
  Text,
  SafeAreaView,
  Image,
  TouchableOpacity,
  Platform,
  ScrollView,
  Button,
  Linking,
  TouchableWithoutFeedback,
} from "react-native";
import {
  GreyText,
  DetailsTop,
  DetailsBox,
  PropertyLabelvarient1, PropertyLabelvarient2,
} from "./styles";
import { FormatPrice } from "../../helpers/validation.js";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CouncilTax from "./councilTax";
import moment from "moment";
import idx from "idx";
import ServiceProvider from "../../commonComponents/ServiceProvider/index.js";
import {
  GetCouncilTax,
  GetSchool,
  GetTransport,
} from "./../../redux/actions/property";
import Loader from "./../../commonComponents/activityIndicator/activityIndicator";
import School from "./school";
import Transport from "./transport";

const Tab3 = (props) => {
  const { propertyData } = props;
  const [SelectedService, setSelectedService] = useState(null);
  const [FirstTime,setFirstTime]=useState(false);
 
  useEffect(() => {
    if (props.propertyReducer?.councilData && FirstTime)
      setSelectedService("Council Tax");
 
  }, [props.propertyReducer.councilData]);
  useEffect(() => {
    if (props.propertyReducer?.schoolData && FirstTime) setSelectedService("School");

  }, [props.propertyReducer.schoolData]);
  useEffect(() => {
    if (props.propertyReducer?.transportData && FirstTime) setSelectedService("Transport");
  }, [props.propertyReducer.transportData]);
 
  
  return (
    <>
      <DetailsBox>
        <DetailsTop>
          <View style={{ marginRight: "2%", flex: 0.5 }}>
            <PropertyLabelvarient2>
              {/* {idx(propertyData, (_) => _.address_line1)},
              {propertyData?.postcode?.split(" ")[0]} */}
              {propertyData?.display_address}

            </PropertyLabelvarient2>

            {propertyData?.property_name && (
              <GreyText>{propertyData?.property_name}</GreyText>
            )}
          </View>
          <View style={{ flex: 0.5, alignItems: "flex-end" }}>
            <PropertyLabelvarient2>
              {FormatPrice(idx(propertyData, (_) => _.price))}
            </PropertyLabelvarient2>
            <GreyText>
              {moment(idx(propertyData, (_) => _.available_from)).format(
                "DD/MM/YYYY"
              )}
            </GreyText>
          </View>
        </DetailsTop>

        {SelectedService === "Council Tax" ? (
          <View
            style={{
              flex: 1,
              marginLeft: 30,
              marginRight: 30,
              marginBottom: 30,
            }}
          >
            <CouncilTax
              ResetService={(props) => setSelectedService(null)}
              data={props.propertyReducer?.councilData}
            />
          </View>
        ) : SelectedService === "School" ? (
          <View
            style={{
              flex: 1,
              marginLeft: 30,
              marginRight: 30,
              marginBottom: 30,
            }}
          >
            <School
              ResetService={(props) => setSelectedService(null)}
              data={props.propertyReducer?.schoolData}
              searchMapData={props.searchMapData}
              searchObject={props.searchObject}
              propertyData={props.propertyData}/>
          </View>
        ) : SelectedService === "Transport" ? (
          <View
            style={{
              flex: 1,
              marginLeft: 30,
              marginRight: 30,
              marginBottom: 30,
            }}
          >
            <Transport
              ResetService={(props) => setSelectedService(null)}
              data={props.propertyReducer?.transportData?.data}
              searchObject={props.searchObject}
              propertyData={props.propertyData}
            />
          </View>
        ) : (
          <>
            <DetailsTop>
              <GreyText>Current service providers</GreyText>
            </DetailsTop>
            <View
              style={{
                flex: 1,
                marginLeft: 30,
                marginRight: 30,
                marginBottom: 30,
              }}
            >
              <ServiceProvider
                onServiceSelect={(data) => {
                  
                  if (data == "Council Tax") {
                    setFirstTime(true);
                    props.GetCouncilTax(propertyData?.postcode);
                  } else if (data === "School") {
                    setFirstTime(true);
                    props.GetSchool(propertyData?.postcode);
                  } else if (data == "Transport") {
                    setFirstTime(true);
                    props.GetTransport(
                      propertyData?.location?.coordinates[0],
                      propertyData?.location?.coordinates[1]
                    );
                  }
                }}
              />
            </View>
          </>
        )}
      </DetailsBox>
      <Loader
        loading={
          props.propertyReducer.propertyCouncilLoader ||
          props.propertyReducer.propertySchoolLoader ||
          props.propertyReducer.transportLoader
        }
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  propertyReducer: state.propertyReducer,
  searchMapData: idx(state, (_) => _.propertyReducer.searchMapData.locations),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetCouncilTax,
      GetSchool,
      GetTransport,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(Tab3);
