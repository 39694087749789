import React from 'react'
import ReactPlayer from 'react-player/vimeo'
import { Text, View } from 'react-native'
const VimeoPlayer = (props) => {  
    return (
        <View style={{ flex: 1 }}>
            <ReactPlayer url={props.url} height={props.height} controls={true} width={props.width} />
        </View>
    )
}
export default VimeoPlayer;