import { View } from 'react-native';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { tdsActions } from './tdsRedux/tds.actions';
import { backgroundTdsArr } from './backgroundTdsArr';
import getPlatformType from '../../helpers/getPlatform';
import { navigateAction } from '../../helpers/navigationHelpers';
import BackGroundGenerator from '../LandingPages/common/BackGroundGenerator';
import { Container, ButtonWrapper, Typography, BoxShadow, primaryColor } from './style';
import { useIsFocused } from '@react-navigation/native';
import { COLORS } from '../../constants/colorPallete';
import { addRentalInfoTds } from '../../redux/actions/auth';
const custodialTenancies = (props) => {

  const dispatch = useDispatch();
  const [type, setType] = useState('web');
  const [confirmedTenancies, setConfirmedTenancies] = useState([]);
  const [notConfirmedTenancies, setNotConfirmedTenancies] = useState([]);
  const tenanciesResponse = useSelector((state) => state.tds?.tenancies);
  const layoutType=getPlatformType();
let focus =useIsFocused()
  useEffect(() => {
    dispatch(tdsActions.getTenancies());
  }, [focus]);

  useEffect(() => {
    if(tenanciesResponse?.items) {
      const confirmed = tenanciesResponse?.items.filter(item=> item.dpc_validated == "Yes");
      const notConfirmed = tenanciesResponse?.items.filter(item=> item.dpc_validated == "No");
      setConfirmedTenancies(confirmed.sort((a,b)=>{return new Date(b.tenancy_created_at)-new Date(a.tenancy_created_at)}));
      setNotConfirmedTenancies(notConfirmed.sort((a,b)=>{return new Date(b.tenancy_created_at)-new Date(a.tenancy_created_at)}));
    }
  }, [tenanciesResponse]);


  const addressWithoutPostcode = (address) => {
    let array = address.split(',')
    array.pop()
    return array.toString()
  };
  const onAddRentalInfo = (data) => {
    let req = {
      "tenancy_id": data?.tenancy_id? data?.tenancy_id:"",
      "rental_amount":  data?.rent_amount?data?.rent_amount:"",
      "deposit_amount":data?.deposit_amount?data?.deposit_amount: "",
      "property_address": data?.address?data?.address:"",
      "rental_start_date":  data?.tenancy_start_date? new Date(data?.tenancy_start_date):"",
      "expected_end_date": data?.expected_end_date?new Date(data?.expected_end_date):"",
      "property_name": "N/A",
      "property_post_code": "See Address"
    }
    dispatch(addRentalInfoTds(req))
  }
  const renderTenancies=()=>{
    return(
      <View style={{width:'100%'}}>
        <BoxShadow>
      <Typography color={primaryColor}>Your email is linked to these tenancies</Typography>
     
      </BoxShadow>
       <View style={{width:'100%',height:1,backgroundColor:primaryColor,marginVertical:20}}/>
        <BoxShadow>
          <Typography color={primaryColor} bottom={'15px'} align={'left'}>Confirmed Tenancies</Typography>
          <Typography bold={'500px'} size={'16px'} color={'#000'}  align={'left'}>Below are deposits held or protected by the scheme</Typography>
        </BoxShadow>
        
        {confirmedTenancies.map((tenancy,index)=>
          <BoxShadow 
          key={index}
          style={{marginVertical:20}}>
            <View style={{flexDirection:layoutType=="phone"?'column':'row',width:layoutType=="phone"?'50%':'100%',justifyContent:'space-between'}}>
              <View>
                <Typography color={primaryColor} bottom={'20px'} align={'left'}>Tenancy ID</Typography>
                <Typography bold={'500px'} size={'16px'} color={'#000'} bottom={'30px'} align={'left'}>{tenancy.dan}</Typography>
              </View>
              <View style={layoutType!="phone"?{width:'60%'}:{width:'85%'}}>
                <Typography color={primaryColor} bottom={'20px'} align={'left'}>Address</Typography>
                <Typography bold={'500px'} size={'16px'} color={'#000'} bottom={'30px'} align={'left'}>{tenancy.formatted_address}</Typography>
              </View>
            </View>
            <View style={{flexDirection:'row',width:'100%',justifyContent:'space-between'}}>
              <Typography color={primaryColor} bottom={'20px'} align={'left'}>No. of Tenants : <Typography color={'#000'}>{tenancy.number_of_tenants}</Typography> </Typography>
              <ButtonWrapper
                  bcolor={COLORS.LGREEN}
                  color={COLORS.LGREEN}
                  style={[{ width: layoutType=="phone"?'120px':'280px' },
                  layoutType=="phone" && {position:'absolute',right:-10,top:-100}]}
                  id={"TDS-CUSTO-VIEW"}
                  onPress={() => {
                    onAddRentalInfo(tenancy)
                    navigateAction(props.navigation, 'Custodial Dashboard', { 'tenancy_id': tenancy.tenancy_id, 'dan': tenancy.dan })

                  }}
              >
                <Typography 
                id={"TDS-CUSTO-VIEW-TEXT"}
                color={'white'} bold={700}>
                  VIEW
                </Typography>
              </ButtonWrapper>
            </View>
          </BoxShadow>
        )}
     
      { notConfirmedTenancies.length > 0 ?
      <>
        <BoxShadow style={{marginTop:30}}>
        <Typography color={primaryColor} bottom={'15px'} align={'left'}>Unconfirmed Tenancies</Typography>
        <Typography bold={'500px'} size={'16px'} color={'#000'}  align={'left'}>To view the tenancies below, you must confirm that you are the tenant</Typography>
        </BoxShadow>

        {notConfirmedTenancies.map(tenancy=><BoxShadow style={{marginVertical:20}}>
        <View style={{flexDirection:layoutType=="phone"?'column':'row',width:layoutType=="phone"?'50%':'100%',justifyContent:'space-between'}}>
          <View>
          <Typography color={primaryColor} bottom={'20px'} align={'left'}>Tenancy ID</Typography>
          <Typography bold={'500px'} size={'16px'} color={'#000'} bottom={'30px'} align={'left'}>{tenancy.dan}</Typography>
          </View>
          <View style={layoutType!="phone"?{width:'60%'}:{width:'85%'}}>
            <Typography color={primaryColor} bottom={'20px'} align={'left'}>Address</Typography>
            <Typography bold={'500px'} size={'16px'} color={'#000'} bottom={'30px'} align={'left'}>{addressWithoutPostcode(tenancy.formatted_address)}</Typography>
          </View>
        </View>
        <View style={{flexDirection:'row',width:'100%',justifyContent:'space-between'}}>
          <Typography color={primaryColor} bottom={'20px'} align={'left'}>No. of Tenants : <Typography color={'#000'}>{tenancy.number_of_tenants}</Typography> </Typography>
          <ButtonWrapper
              style={[{ width: layoutType=="phone"?'120px':'280px' },
              layoutType=="phone" && {position:'absolute',right:-0,top:-100}]}
              onPress={() => navigateAction(props.navigation, 'Confirm Tenancy', { tenancy_id: tenancy.tenancy_id, dan: tenancy.dan })}
          >
              <Typography color={'#FFFFFF'}  bold={700}>
                Confirm
              </Typography>
            </ButtonWrapper>
        </View>
        </BoxShadow>)}
      </>
      : null }
      </View>
    )
  }

  return (
    <>
      <BackGroundGenerator
        height={'200px'}
        infoIcon
        isTds
        redirectTDSAccount={()=> { navigateAction(props.navigation, 'Custodial Account') }}
        tdsAccountIcon
        props={props}
        layoutType={(data) => { setType(data) }}
        isTLYF
        backgroundArr={backgroundTdsArr}
      >
        <Container>
          {renderTenancies()}
        </Container>
      </BackGroundGenerator>
    </>
  );
};

export default custodialTenancies;
