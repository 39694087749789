import React, { useState } from 'react';
import {
  Pressable,
  Text,
  StyleSheet,
  TouchableWithoutFeedback,
  TouchableOpacity,
  View,
  ImageBackground,
  Image,
} from 'react-native';
import { ODSIcon } from '../ODSIcon';
import { ODSText } from '../ODSText';
import ODSButton from '../ODSButton';
import ODSBadges from '../ODSBadges';
import getScreenType from '../../../utils/getScreenType';
import { getTokens } from '../../../design-tokens/color-tokens';

const ODSTiles = ({
  subtitle,
  title,
  status,
  disabled,
  children,
  restStyle,
  rIcon,
  lIcon,
  themeColor,
  source,
  btnTheme,
  onPress,
  isRight,
  topImage,
  btnTitle,
  subtitle2,
  textColor,
  subtitleType,
  btnCOlor,
  resize,
  imageWidth,
  logosource
}) => {
  const themeObj = getTokens('light').colors;
  // console.log('themeObj', themeObj);
  const [isPressed, setIsPressed] = useState(false);

  return (
    <View style={[styles(themeObj, isRight).containerStyle, restStyle]}>
      <ImageBackground
        source={source ? source : require('./Affordablity_Hero.png')}
        style={{ width:imageWidth?imageWidth: 140}}
        resizeMode={resize? resize:"cover"}
        imageStyle={
          isRight
            ? { borderTopRightRadius: 20, borderBottomRightRadius: 12 }
            : { borderTopLeftRadius: 20, borderBottomLeftRadius: 12 }
        }
      />
      <View style={{ marginHorizontal: 12, marginVertical: 10, flex: 1 }}>
        {topImage && <Image source={topImage} style={{ width: 120, height: 60, resizeMode: 'contain' }} />}
        {status ? (
          <ODSBadges restStyle={{ marginBottom: 10, alignSelf: 'flex-end' }} type={status}>
            {status}
          </ODSBadges>
        ) : null}
        <View style={{}}>
          <ODSText marginTop={6} color={textColor?textColor:"black"} type={'h5'}>
            {title}
          </ODSText>
        {subtitle?  <ODSText marginTop={12} color={textColor?textColor: themeObj?.text.secondary} type={subtitleType?subtitleType:'sm'}>
            {subtitle}
          </ODSText>:null}
          {subtitle2 ?
            <ODSText marginTop={12} color={textColor?textColor:themeObj?.text.secondary} type={'sm'}>
              {subtitle2}
            </ODSText> : null
          }
        </View>
        <View style={{ marginTop: 20, marginBottom:logosource?0: 24 }}>
          <ODSButton
            restStyle={{ width: '100%' }}
            type="primary"
            disabled={false}
            themeColor={btnTheme}
            btnCOlor={btnCOlor?btnCOlor:""}
            onPress={onPress}>
            {btnTitle ? btnTitle : "Go"}
          </ODSButton>
        </View>

       {logosource? 
       <View style={{width:"100%",marginTop:20}}>
       <Image source={logosource}
        style={{height:49,width:"160%"}}
        resizeMode='cover'
        />
        </View>
        :null}
        
      </View>
    </View>
  );
};

const styles = (theme, isRight) =>
  StyleSheet.create({
    containerStyle: {
      display: 'flex',
      width: getScreenType() == 'phone' ? '100%' : 360,
      borderRadius: 20,
      shadowColor: '#000',
      shadowOffset: { width: 1, height: 3 },
      shadowOpacity: 0.15,
      shadowRadius: 8,
      elevation: 5,
      backgroundColor: 'white',
      flexDirection: isRight ? 'row-reverse' : 'row',
    },
  });

export default ODSTiles;
