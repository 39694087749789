
const webPath= "./web/"
const tabPath= "./tab/"
const mobilePath= "./mobile/"
export const backgroundSearchArrLogin = [
    {
      webUri: require(webPath+'Ellipse1.svg'),
      tabUri: require(tabPath+'Ellipse1.svg'),
      mobileUri: require(mobilePath+'Ellipse1.svg'),
      webStyle: {
        width: 115,
        position: 'absolute',
        left: 0,
      },
      tabStyle: {
        width: 126,
        position: 'absolute',
        left: 0,
      },
      mobileStyle: {
        width: 155,
        position: 'absolute',
        left: 0,
      },
      blurRadius:true,
    },
    {
      webUri: require(webPath+'Ellipse2.svg'),
      tabUri: require(tabPath+'Ellipse2.svg'),
      mobileUri: require(mobilePath+'Ellipse2.svg'),
      webStyle: {
        width: 400,
        position: 'absolute',
        right: 0,
      },
      tabStyle: {
        width: 230,
        position: 'absolute',
        right: 0,
      },
      mobileStyle: {
        width: 100,
        position: 'absolute',
        right: 0,
      },
      blurRadius:true,
    },
    {
      webUri: require(webPath+'homeleft.png'),
      tabUri: require(tabPath+'home.svg'),
      mobileUri: require(mobilePath+'home.svg'),
      webStyle: {
        position: 'absolute',
        top: 150,
      },
      tabStyle: {
        position: 'absolute',
        top: 740,
      },
      mobileStyle: {
        position: 'absolute',
        top: 420,
      },
    }
  ];