import React, { useRef } from 'react';
import styled from 'styled-components';
import variables from '../variables.json';
import { useDispatch } from 'react-redux';
import { LinearGradient } from 'expo-linear-gradient';
import { Text } from 'react-native';

const SolidButton = styled(LinearGradient)`
  display: flex;
  border-radius: 18px;
  width: ${(props) => (props.width ? props?.width : '156px')};
  height: 36px;
  text-align: center;
  justify-content: center;
  align-items: center;
  background: ${(props) =>
    props.isLGREEN ? '#1B8A8C' : props.color ? props.color : variables.orange};
  color: white;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu,
    'Helvetica Neue', sans-serif;
`;
const StripeButton = styled.div`
  display: flex;
  border-radius: 18px;
  width: 156px;
  height: 36px;
  font-weight: bold;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: ${(props) => (props.color ? props.color : variables.orange)};
  border: ${(props) =>
    props?.color ? `1px solid ${props.color}` : `1px solid ${variables.orange}`};
  font-size: 12px;
  cursor: pointer;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu,
    'Helvetica Neue', sans-serif;
`;
const CustomButton = styled.div`
  display: flex;
  border-radius: 18px;
  width: 156px;
  height: 36px;
  font-weight: 400;
  text-align: center;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.color};
  border: ${(props) => `1px solid ${props.color}`};
  font-size: 12px;
  cursor: pointer;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu,
    'Helvetica Neue', sans-serif;
`;

export const Button = (props) => {
  const dispatch = useDispatch();
  console.log('ButtonButton props', props);
  const inputFile = useRef();
  const onButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  if (props.type == 'solid') {
    return (
      <SolidButton
        colors={props?.isLGREEN ? ['#3DB9BB ', '#1B8A8C '] : ['#FBB1E6', variables.orange]}
        start={[0.4, 0.5]}
        end={[1, 1]}
        {...props}
      >
        <Text id={props.id} style={{ fontSize: 12, color: 'white', fontWeight: '500' }}>
          {props.title}
        </Text>
      </SolidButton>
    );
  } else if (props.type == 'stripe') {
    return <StripeButton {...props}>{props.title}</StripeButton>;
  } else if (props.type == 'Upload') {
    return (
      <React.Fragment>
        <input
          onChange={(e) => {
            let data = e.target.files;

            if (data && data.length) {
              let arr = [];
              arr.push(data[0]);
              var newImages = arr.map((file) =>
                Object.assign(file, { preview: URL.createObjectURL(file) })
              );
              dispatch(userActions.uploadFiles(newImages, 'property-images', (urlData) => {}));
            }
          }}
          style={{ display: 'none' }}
          id="select-file"
          type="file"
          ref={inputFile}
        />
        <StripeButton {...props} onClick={onButtonClick}>
          {props.title}
        </StripeButton>
      </React.Fragment>
    );
  } else if (props.type == 'pink') {
    return (
      <CustomButton
        style={{
          backgroundColor: 'linear-gradient(101.222deg, rgb(251, 177, 230), rgb(255, 181, 128))',
        }}
        {...props}
      >
        {props.title}
      </CustomButton>
    );
  } else {
    return (
      <CustomButton color={props.color} {...props}>
        {props.title}
      </CustomButton>
    );
  }
};

{
  /*
Example.(type=solid/stripe)
<Button type={"stripe"} title={"Back"} onClick={()=>alert("Ok")}/>
*/
}
