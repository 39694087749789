import React, { useState } from "react";
import {
    View,
    Text,
    SafeAreaView,
    Image,
    TouchableOpacity,
    Platform,
    ScrollView,
    Button,
} from "react-native";
import Hyperlink from 'react-native-hyperlink'

import {
    Container,
    SectionWrapper,
    DualView,
    TextBox,
    SectionTitle,
    SectionText,
    ImageBox,
    TeamsSection,
    MemberBoxWrapper,
    MemberBox,
    MemberImage,
    MemberName,
    MemberNameText,
    MemberDetails,
    MemberDetailsText,
    MemberHeader,
    TeamHeader
} from "./styles";

import getPlatformType from "../../helpers/getPlatform";
const layoutType = getPlatformType();

import Header from "../../commonComponents/headerComponent/headerComponent";
import Footer from "../../commonComponents/footerComponent/customFooter";
import TripleTabs from "../../commonComponents/tripleTabs/tripleTabs";

const TabCareers = (props) => {
    const linkStyle = { color: '#2980b9', fontSize: 16 }
    const openLink = (url) => {
        window.open(url);
    }
    return (
        <SectionWrapper>
            <DualView
                style={{
                    flexDirection: layoutType !== 'phone' ? 'row-reverse' : 'column'
                }}>
                <ImageBox

                >
                    <Image
                        source={require("../../assets/images/Careers.jpg")}
                        style={{ width: "100%", height: "100%", resizeMode: "contain" }}
                    ></Image>
                </ImageBox>
                <TextBox>
                    <SectionTitle>Careers</SectionTitle>
                    <Hyperlink linkStyle={linkStyle} onPress={(url) => openLink(url)}>
                        <SectionText>
                            At OpenBrix we are always on the look out for tomorrows next hot talent! Whilst we don’t have any current vacancies please feel free to forward a copy of your CV along with speculative cover letter to  info@openbrix.co.uk
                    </SectionText>
                    </Hyperlink>

                </TextBox>

            </DualView>
        </SectionWrapper>
    );
};


export default TabCareers;
