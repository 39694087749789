import React from "react";
import {View,Text} from 'react-native'
import styled from "styled-components/native";
import variables from "../variables.json";
import { CardBox } from "../card/index.js";
import { StatusButton } from "../statusbutton/index.js";
// import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { Ionicons } from '@expo/vector-icons';
import { Image } from "react-native";
import getPlatformType from "../../../../helpers/getPlatform";
import { COLORS } from "../../../../constants/colorPallete";
const layoutType=getPlatformType();
const Container = styled.TouchableOpacity`
  flex: 1;
  display: flex;
  flex-direction:row;
  justify-content: space-between;
  align-items: center;
  margin-top:10px;
`;

export const ListItem = (props) => {
  const {
    title,
    marginTop,
    listIcon,
    showButton,
    type,
    description,
    showArrowIcon,
  } = props;
  const defaultToProgress=()=>{
    if(title==="Deposit Protect & Home Setup"){
      if(type){
        return type
      }else{
        return 4
      }
    }else{
      return type
    }
    
  }
  return (
    <CardBox 
    style={
      {marginTop:props.marginTop?props.marginTop:0,marginBottom:props.marginBottom?props.marginBottom:0,
      width: props.width?props.width:"100%"
    }}
    hasShadow={true} hasBorderRadius={true}>
      <Container type={props.type} {...props}>
        <View style={{ flexDirection: "row", alignItems: "center",maxWidth:'60%',flex:1,marginTop:8 }}>
        
         <View style={{width:'100%',flexDirection:'row',alignItems:'center'}}>
           <Image source={listIcon} style={{width:28,height:28,marginRight:8}}/>
          <Text
            id={props.id}
            style={{
              fontSize: layoutType=="web"?"15px":'14px',
              color:COLORS.LGREEN,
              lineHeight: 18,
              paddingBottom: 4,
              fontWeight:'700',
              fontFamily:'Lato'
            }}
          >
            {title}
          </Text>
          </View>
        </View>
        {showButton && <StatusButton type={ defaultToProgress()} />}
      </Container>
      <Text style={{ marginTop: 30, fontSize: 13,marginBottom:8 }}>{description}</Text>
    </CardBox>
  );
};

     {/* <ListItem showArrowIcon showButton type="complete" title={"Tenants Details"} description={"This is a new way of selling property and our UK MLS is tailored for the UK Market, which means you can achieve higher fees from your clients."}/> */}

