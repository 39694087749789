import React, { Component, useState, useEffect } from 'react';
import { Text, Image } from 'react-native';
import { DocumentBox } from './styles';
import Dropzone from 'react-dropzone';
import { uploadFiles, clearuploadedFiles } from '../../redux/actions/auth';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ActivityIndicator } from 'react-native';
import Loader from './../../commonComponents/activityIndicator/activityIndicator';
import { MaterialIcons } from '@expo/vector-icons';
import { View } from 'react-native';
import { COLORS } from '../../constants/colorPallete';
import { getTokens } from '../../../openbrix-design-system/design-tokens/color-tokens';
import { ODSText } from '../../../openbrix-design-system/ui/atoms/ODSText';
import { ODSIcon } from '../../../openbrix-design-system/ui/atoms/ODSIcon';
const FilePicker = (props) => {
  const {
    loading,
    fileName,
    file,
    onFileSelect,
    onMultipleFileSelect,
    Idindex,
    accept,
    ...attributes
  } = props;
  const themeObj = getTokens('light').colors;
  const onDropImages = (data) => {
    console.log('data', data);
    if (data && data.length) {
      var newImages = data.map((file) =>
        Object.assign(file, { preview: URL.createObjectURL(file) })
      );
      props.uploadFiles(newImages, 'userdoc', (cb) => {
        console.log('cbcb', cb);
        if (onMultipleFileSelect) {
          onMultipleFileSelect(cb, Idindex);
        } else {
          onFileSelect(cb?.data[0], Idindex);
        }
        props.clearuploadedFiles();
      });
    }
  };

  // useEffect(() => {
  //   let { uploadedFiles} =props
  //  if(uploadedFiles&& uploadedFiles?.data && uploadedFiles?.data?.length ){
  //
  //    onFileSelect(uploadedFiles?.data[0],Idindex)
  //   props.clearuploadedFiles()
  //  }
  // },[props?.uploadedFiles]);
  console.log('fileNamefileNamefileName', fileName);
  return (
    <DocumentBox width={props?.width}>
      <Loader loading={props.fileupLoading} />
      <Dropzone
        accept={
          accept
            ? accept
            : 'image/*,application/pdf,.doc,.docx,.xls,.xlsx,.csv,.tsv,.ppt,.pptx,.pages,.odt,.rtf'
        }
        onDrop={(acceptedFiles) => {
          console.log('acceptedFiles', acceptedFiles);
          console.log('accept', accept);

          onDropImages(acceptedFiles);
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div
            id={'this-is-the-dropzone'}
            style={{
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
            }}
            {...getRootProps()}
          >
            <input {...getInputProps()} />

            {fileName ? (
              file ? (
                <View style={{ flexDirection: 'row', width: '100%', alignItems: 'center' }}>
                  <ODSText alignSelf={'center'} marginTop={10} type={'button'}>
                    {file}
                  </ODSText>
                </View>
              ) : (
                <Image source={{ uri: fileName }} style={{ width: 100, height: 100 }} />
              )
            ) : (
              // <Image
              //   source={{ uri: fileName }}
              //   style={{ width: 100, height: 100 }}
              // />
              // <Text style={{color: '#E71E82'}}>
              //   {fileName}
              // </Text>
              <View style={{ justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                <ODSIcon color={themeObj.feedback['main']} icon={'TBDownload'} size={38} />
                <ODSText marginTop={10} type={'button'}>
                  Upload your file
                </ODSText>
                <ODSText marginTop={6} type={'sm'}>
                  (jpeg, png, pdf, doc, docs, xls, xlsx)
                </ODSText>
              </View>
            )}
          </div>
        )}
      </Dropzone>
    </DocumentBox>
  );
};

const mapStateToProps = (state) => ({
  fileupLoading: state.authReducer.fileupLoading,
  uploadedFiles: state.authReducer.uploadedFiles,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      uploadFiles,
      clearuploadedFiles,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(FilePicker);
