import React from 'react';
import { Text,View } from "react-native";

import Rings from '../Rings';


const ThreeSection=(props)=>{ 
    return(
        <View style={[{justifyContent:'center',alignItems:'center',width:props?.layoutType=='web'?'25%':"90%"},props?.customStyle]}>
            <View style={{minHeight:60}}>
               {props?.icon}
            </View>
            <View style={{minHeight:60,alignItems:'center'}}>
                <Text style={{fontFamily:'Lato-Bold',fontSize:15,textAlign:'center',marginTop:props?.showRings?5:0}}>{props?.heading}</Text>
             {props?.showRings && <Rings size={30}/> }
            </View>
            <View>
                <Text style={{fontFamily:'Lato',fontSize:13,textAlign:'center'}}>{props?.description}</Text>
            </View>
         </View>
    )
}
export default ThreeSection;