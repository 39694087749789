import React, { useEffect ,useState} from "react";
import { View, Image ,TouchableOpacity} from "react-native";
import { Ionicons } from "@expo/vector-icons";
import { TextBox, RightText, Title, OuterView } from "./customTextInput.style";
import getPlatformType from "../../helpers/getPlatform";
const layoutType = getPlatformType();

const CustomTextInput = (props) => {
  const [state, setState] = useState({
    showError: false,
    passwordVisible:false
  });


  // useEffect(() => {
  //   setState({
  //     ...state,
  //     value: props.prefilledValue,
  //   });
  // }, [props?.prefilledValue]);

  const onChangeText = (value) => {
    // if(props?.onlyLetters){
    //     setState({ value: value.replace(/\s+/g, "")});
    //     props?.onChangeText(value.replace(/\s+/g, ""));
    // } 
    // else if(props?.onlyNumbers){
    //   setState({ value: value.replace(/[^0-9+]/g, '')});
    //   props?.onChangeText(value.replace(/[^0-9+]/g, ''));
    // }
    // else{
    //   setState({ value: value });
    //   props?.onChangeText(value);
    // }
    

    
  };

  const {
    bRadius,
    title,
    password,
    borderWidth,
    height,
    width,
    borderColor,
    textColor,
    textFontSize,
    keyboardType,
    placeHolder,
    disabled,
    backgroundColor,
    prefilledValue,
    paddingLeft,
    valueColor,
    valueFontSize,
    wrapperPaddingBottom,
    renderRightComponent,
    autoCapitalize,
    onBlur,
    type,
    loginReducer,
    paddingHorizontal,
    paddingVertical,
    rightText,
    showError,
    errorMessage,
    errorMessageColor,
    textSize, id,
    bColor,
    bWidth,
    titleComponent=false,
    showErrorBottom,
    eyePosition,
    renderRightImage
  } = props;
  const { value, passwordVisible } = state;
  
  return (
    <OuterView width={width}>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          paddingBottom: 8,
          position:'relative'
        }}
      >
        {titleComponent?titleComponent:null }
        {title ? <Title 
        textSize={textSize}
        >{title}</Title> : null}

        {showErrorBottom?null: rightText || showError ? (
          <View>
            <RightText style={{ color: showError ? "red" : "#000000" }}>
              {showError && errorMessage
                ? errorMessage
                : rightText
                ? rightText
                : ""}
            </RightText>
          </View>
        ) : null}
      </View>
      <View
       style={{
        position:'relative'
      }}
      >
      <TextBox
        id = {id}
        testID={id}
        autoCapitalize={autoCapitalize ? autoCapitalize : "none"}
        editable={!disabled}
        underlineColorAndroid="transparent"
        secureTextEntry={password && !passwordVisible}
        keyboardType={keyboardType ? keyboardType : "default"}
        placeholder={placeHolder}
        placeholderTextColor={"grey"}
        onBlur={() => (onBlur ? onBlur() : null)}
        style={{
          borderWidth:bColor?1:3,
          width:"100%",
          paddingLeft: paddingLeft ? paddingLeft : 5,
          paddingHorizontal: paddingHorizontal ? paddingHorizontal : 5,
          borderColor:disabled?"#d0d0d0" :bColor?bColor: showError ? "red" :"#fbebf3",
          borderRadius: bRadius?bRadius:0,
        }}
        // style={{
        //   paddingLeft: paddingLeft,
        //   paddingHorizontal: paddingHorizontal ? paddingHorizontal : 5,
        //   height: height ? height : 8,
        //   width: width ? width : "95%",
        //   backgroundColor: backgroundColor ? backgroundColor : "white",
        //   borderRadius: 5,
        //   alignSelf: "center",
        //   borderWidth: borderWidth,
        //   borderColor: borderColor,
        //   color: valueColor ? valueColor : "black",
        //   fontSize: valueFontSize ? valueFontSize : 14 ? 16 : 14,

        //   paddingVertical: disabled ? 0 : 3,
        // }}
        onChange={(e)=>props.onChangeText(e.target.value)}
        value={props.prefilledValue}
        {...props}
      />
         {renderRightImage ? (
          <TouchableOpacity
          onPress={() =>
           {password? setState({
              ...state,
              passwordVisible: !state.passwordVisible,
            }):null}
          }
            style={{
              height: '100%',
              position: 'absolute',
              right: '3%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Image
             onPress={() =>
              setState({
                ...state,
                passwordVisible: !state.passwordVisible,
              })
            }
              source={renderRightImage}
              style={{
                height: 22,
                width: 22,
              }}
              resizeMode={"contain"}
            />
          </TouchableOpacity>
        ) : null}
            {renderRightComponent ? (
         <View
           style={{
             height: '100%',
             position: 'absolute',
             right: '3%',
             justifyContent: 'center',
             alignItems: 'center',
           }}
         >
          <Ionicons
            onPress={() =>
              setState({
                ...state,
                passwordVisible: !state.passwordVisible,
              })
            }
            name={passwordVisible ? "ios-eye" : "ios-eye-off"}
            size={24}
            color="lightgrey"
          />
        </View>
      ) : null}
        </View>
      {showErrorBottom?   <RightText style={{ color: showError ? "red" : "#000000" ,marginTop: 10}}>
              {showError && errorMessage
                ? errorMessage
                : rightText
                ? rightText
                : ""}
            </RightText>:null}
    </OuterView>
  );
};

export default CustomTextInput;
