import React, { useState, useEffect } from "react";
import {
  Image,
  TouchableOpacity,
  View,
  FlatList,
  Text,
  SafeAreaView,
  ActivityIndicator,
} from "react-native";
import {
  Container,
  RightPanal,
  LeftPanal,
  CenterPanal,
  ListView,
  Section1,
  Section2,
} from "./styles.js";
import getPlatformType from "../../helpers/getPlatform";
import Slider from "../SliderComponent";
import Card from "../CardComponent";
import NoData from "../NoData/index.js";
import { COLORS } from "../../constants/colorPallete.js";
import savedProperties from "../../components/savedProperties.js";
// import NoData from "../NoData/index.js";
const layoutType = getPlatformType();
const List = (props) => {
  const {
    data,
    isExpand,
    open,
    navigate,
    savedProperties,
    onSave,
    searchObject,
    loadMore,
    sortValue,
    toggleRefresh,
    flag,
  } = props;
  const flatListRef = React.useRef();
  const [expand, setExpand] = useState(open);
  const [columnCount, setColumnCount] = useState(open ? 2 : 3);
  const [page, setPage] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const [noProp, setNoProp] = useState(flag);
  useEffect(() => {
    
    setExpand(open);
    setColumnCount(open ? 2 : 3);
  }, [props]);
  const onExpand = () => {
    setExpand(!expand);
    isExpand(!expand);
    setColumnCount(expand ? 3 : 2);
  };
  const _onLoadMore = () => {
    setPage(page + 1);
    setRefresh(true);
    toggleRefresh(true);
    props.searchAPI(searchObject, sortValue, page + 1, (response) => {
      
      if (response) {
        if (response.length > 0) {
          props.savePageData([...data, ...response]);
          setRefresh(false);
          toggleRefresh(false);
        } else {
          setNoProp(true);
        }
      } else {
        setNoProp(true);
      }
    });
  };

  const renderItem = ({ item, index }) =>
    item.id == "map" ? (
      <Container>
        <ListView>
          <TouchableOpacity onPress={() => onExpand()}>
            <Text
              style={{
                width: 138,
                height: 40,
                backgroundColor: "#f1efef",
                padding: 10,
              }}
            >
              <Image
                source={require("../../assets/images/expand.png")}
                resizeMode="contain"
                style={{ width: 13, height: 13 }}
              />{" "}
              Expand Map
            </Text>
          </TouchableOpacity>
        </ListView>
      </Container>
    ) : (
      <Container>
        <Card
          data={item}
          navigate={navigate}
          onSave={onSave}
          searchObject={searchObject}
          savePageData={props.savePageData}
          pageData={data}
        />
      </Container>
    );
  
  const _footer = () => {
    return loadMore ? (
      <View
        style={{
          marginTop: 25,
          marginBottom: 25,
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {refresh ? (
          <ActivityIndicator color={COLORS.gradientColor1} size={"large"} />
        ) : (
          <TouchableOpacity
            onPress={() => _onLoadMore()}
            style={{
              padding: 15,
              borderWidth: 2,
              borderColor: COLORS.gradientColor1,
            }}
            id={"LOAD_MORE_PROPERTIES"}
          >
            <Text style={{ fontSize: 16, color: "black" }}>
              Load more properties
            </Text>
          </TouchableOpacity>
        )}
      </View>
    ) : (
      <View />
    );
  };
  return (
    <View style={{ justifyContent: "center" }}>
      <FlatList
        data={data}
        renderItem={renderItem}
        numColumns={layoutType == "phone" ? 1 : columnCount}
        keyExtractor={(item) => item.property_id}
        ref={flatListRef}
        extraData={props.data}
        ListFooterComponent={() =>
          data && data?.length < props.totalPage && data?.length != 0 ? (
            _footer()
          ) : (
            <View />
          )
        }
        ListEmptyComponent={() => (
          <NoData
            text={
              savedProperties
                ? "No Saved Properties"
                : "No Properties Available"
            }
            onPress={() =>
              navigate("tlyfe - powered by OpenBrix")
            }
          />
        )}
      />
    </View>
  );
};

export default List;
