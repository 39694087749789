import React, { Component } from "react";
import { StyleSheet, View, Button, Text, SafeAreaView } from "react-native";
import { connect } from "react-redux";
import { changeCount } from "../actions/counts";
import { bindActionCreators } from "redux";

class DummyScreen extends Component {
  decrementCount() {
    let { count, actions } = this.props;
    count--;
    actions.changeCount(count);
  }
  incrementCount() {
    let { count, actions } = this.props;
    count++;
    actions.changeCount(count);
  }
  render() {
    const { count } = this.props;
    
    return (
      <SafeAreaView styles={styles.container}>
        <Text style={{ marginTop: "10%", textAlign: "center" }}>{count}</Text>
        <Button title="add" onPress={() => this.decrementCount()} />
        <Button title="minus" onPress={() => this.incrementCount()} />
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 100,
  },
});

const mapStateToProps = (state) => ({
  count: state.countReducer.count,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ changeCount }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DummyScreen);
