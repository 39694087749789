import React, { useEffect, useState } from "react";
import { Text, View } from "react-native";
import PropTypes from "prop-types";
import { AntDesign } from "@expo/vector-icons";
import { LinearGradient } from "expo-linear-gradient";
import { TouchableOpacity } from "react-native-gesture-handler";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { MaterialIcons } from "@expo/vector-icons";
import { Entypo } from "@expo/vector-icons";

const SkeltonComponents = (props) => {
  const {
    title,
    data,
    type,
    subTitleRow1,
    subTitleRow2,
    style,
    renderTopCustomView,
    renderBottomCustomView,
    renderActionButtonView,
    renderBody,
    columns,
    columnsData,
    renderFooterView,
    isOpen,
    renderArrow,
    showTopView=true
  } = props;
  
  useEffect(() => {}, []);
  const renderSingleButtonView = (item) => {
    
  
  return (
          <View
            style={{
              flexDirection: "row",
              marginTop:10,
              padding: 20,
              borderTopRightRadius: 15,
              borderBottomLeftRadius: 15,
              width: "100%",
              borderColor: "#979797",
              marginTop: 15,
              backgroundColor:'white',
              shadowColor: "#000",
              shadowOffset: {
                width: 0,
                height: 2,
              },
              shadowOpacity: 0.25,
              shadowRadius: 3.84,
              elevation: 5,
            }}
          >
            <View
              style={{
                flex: 0.3,
                justifyContent: "center",
              }}
            >
              <Text >{item.title}</Text>
            </View>
            <View
              style={{
                flex: 0.7,
                justifyContent: "center",
                alignItems: item.text ? "center" : "flex-end",
              }}
            >
              {item.button ? (
                <View
                  style={{
                    height: 40,
                    justifyContent: "center",
                    alignItems: "center",
                    width: 168,
                    borderRadius: 10,
                    backgroundColor: item.buttonColor,
                  }}
                >
                  <Text 
                  onPress={() => item.onClick()}
                  style={{ color: "white" }}>{item.button}</Text>
                </View>
              ) : (
                <Text style={{ color: item.textColor }}>{item.text}</Text>
              )}
            </View>
          </View>
        )
  };
  const renderButtonView = () => {
    return data
      ? data.map((item, index) => (
          <View
            key={index}
            style={{
              flexDirection: "row",

              padding: 20,
            }}
          >
            <View
              style={{
                flex: 0.3,
                justifyContent: "center",
              }}
            >
              <Text >{item.title}</Text>
            </View>
            <View
              style={{
                flex: 0.7,
                justifyContent: "center",
                alignItems: item.text ? "center" : "flex-end",
              }}
            >
              {item.button ? (
                <TouchableOpacity
                  onPress={() => item.onClick()}
                  style={{
                    height: 40,
                    justifyContent: "center",
                    alignItems: "center",
                    width: 168,
                    borderRadius: 25,
                    backgroundColor: item.buttonColor,
                  }}
                >
                  <Text style={{ color: "white" }}>{item.button}</Text>
                </TouchableOpacity>
              ) : (
                <Text style={{ color: item.textColor }}>{item.text}</Text>
              )}
            </View>
          </View>
        ))
      : null;
  };
  const renderDetailView = () => {
    return (
      <>
        <View
          style={{
            flexDirection: "row",
            paddingHorizontal: 15,
            paddingTop: 15,
          }}
        >
          <View style={{ flex: 0.7 }}>
            <Text
              style={{
                color: "#E71E82",
                fontSize: 12,
                lineHeight: 14,
              }}
            >
              {subTitleRow1}
            </Text>
          </View>
          <View
            style={{
              flex: 0.3,
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <Text
              style={{
                color: "#E71E82",
                fontSize: 12,
                lineHeight: 14,
              }}
            >
              {subTitleRow2}
            </Text>
          </View>
        </View>
        {data
          ? data.map((item, index) => (
              <View
                key={index}
                style={{
                  flexDirection: "row",
                  padding: 15,
                  borderBottomWidth: data.length - 1 === index ? 0 : 1,
                  borderBottomColor: "black",
                }}
              >
                <View style={{ flex: 0.7 }}>
                  <Text>{item.label}</Text>
                </View>
                <View
                  style={{
                    flex: 0.3,
                  }}
                >
                  <Text style={{}}>{item.value}</Text>
                </View>
              </View>
            ))
          : null}
      </>
    );
  };
  const renderSummaryView = () => {
    return (
      <>
        {data
          ? data.map((item, index) => (
              <View
                key={index}
                style={{
                  padding: 15,
                }}
              >
                <Text
                  style={{ fontWeight: "bold", fontSize: 18, lineHeight: 23 }}
                >
                  {item.label}
                </Text>
                <Text style={{}}>{item.value}</Text>
              </View>
            ))
          : null}
      </>
    );
  };
  const renderDocumentsView = () => {
    return (
      <>
        <View
          style={{
            flexDirection: "row",
            paddingHorizontal: 15,
            paddingVertical: 23,
            borderBottomWidth: 1,
            borderBottomColor: "black",
          }}
        >
          <View style={{ flex: 0.5, justifyContent: "center" }}>
            <Text
              style={{
                fontSize: 14,
                lineHeight: 17,
              }}
            >
              {subTitleRow1}
            </Text>
          </View>
          <View
            style={{
              flex: 0.5,
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Text
              style={{
                color: "#E71E82",
                fontSize: 12,
                lineHeight: 14,
              }}
            >
              {subTitleRow2}
            </Text>
            {renderArrow?<Entypo name="chevron-small-right" size={24} color="#E71E82" />:null}
          </View>
        </View>
        <View
          style={{
            flexDirection: "row",
            padding: 15,
            justifyContent:'space-around',
          }}
        >
          <TouchableOpacity
          onPress={()=>props?.onClick()}
            style={{
              flex: 0.5,
              flexDirection: "row",
              borderRightWidth: 1,
              justifyContent: "center",
              alignItems: "center",
              paddingRight: 30,
              paddingLeft: 10,

            }}
          >
            <MaterialCommunityIcons
              style={{ marginRight: "14%" }}
              name="cloud-download"
              size={20}
              color="#E71E82"
            />
            <Text>Download</Text>
          </TouchableOpacity>
          <View
            style={{
              flex: 0.5,
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <MaterialIcons
              style={{ marginRight: "14%" }}
              name="close"
              size={20}
              color="#E71E82"
            />
            <Text style={{}}>Remove</Text>
          </View>
        </View>
      </>
    );
  };
  const renderCustomView = () => {
    console.log("---",showTopView)
    return (
      <>
       {showTopView ?<View
          style={{
            flexDirection: "row",
            paddingHorizontal: 15,
            paddingVertical: 23,
            borderBottomWidth: 1,
            borderBottomColor: "#d8d8d8",
          }}
        >
          <View style={{ flex: 0.3, justifyContent: "center" }}>
            <Text
              style={{
                fontSize: 13,
                fontWeight:'bold'
              }}
            >
              {subTitleRow1}
            </Text>
          </View>
          <View
            style={{
              flex: 0.7,
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            {renderTopCustomView()}
          </View>
        </View>:null}
        <View
          style={{
            flexDirection: "row",
            padding: 15,
            justifyContent: "center",
          }}
        >
          <View
            style={{
              flex: 0.5,
              flexDirection: "row",
              borderRightWidth: 1,
              borderColor:'#d8d8d8',
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {renderBottomCustomView()}
          </View>
          <View
            style={{
              flex: 0.5,
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
           {renderActionButtonView?renderActionButtonView():null}
           </View>
        </View>
       
         {renderFooterView? isOpen?renderFooterView():null:null}
      </>
    );
  };

  const renderEmptyView = () => {
    return (
      <>
        <View
          style={{
            paddingHorizontal: 15,
            paddingVertical: 23,
          }}
        >
          {renderBody()}
        </View>
      </>
    );
  };
  const renderTable = () => {
    return (
      <>
        <View
          style={{
            paddingHorizontal: 15,
            paddingVertical: 23,
          }}
        >
          <View
            style={{
              flexDirection: "row",
              marginBottom: "6%",
              justifyContent: "center",
            }}
          >
            {columns.map((data, index) => (
              <View
                style={{
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",

                }}
              >
                <Text style={{ flexWrap: "wrap", color: "#E71E82",fontSize:14,textAlign:'center' }}>
                  {data.title}
                </Text>
              </View>
            ))}
          </View>
          {columnsData.map((data, index) => (
          <View style={{ flexDirection: "row", marginBottom: "6%" }}>
           
         
          {data.value&& data.value.map((section,index)=>
            <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text style={{ flexWrap: "wrap", color: "black",fontSize:13,textAlign:'center' }}>
              {section}
            </Text>
          </View>
          
          )}   
   
          </View>
          ))}
        </View>
      </>
    );
  };
  return (
    <View style={{ flexDirection: "column", ...props.style }}>
      {title ? (
        <Text style={{ fontSize: "16", lineHeight: 19, color: "#000" ,fontWeight:'bold'}}>
          {title}
        </Text>
      ) : null}
 {type == "singlebutton" ? data.map((item, index) => renderSingleButtonView(item)) : null}
      <View
        style={{
          borderTopRightRadius: 15,
          borderBottomLeftRadius: 15,
          width: "100%",
          borderColor: "#979797",
          marginTop: 15,
          backgroundColor:'white',
          shadowColor: "#000",
          shadowOffset: {
            width: 0,
            height: 2,
          },
          shadowOpacity: 0.25,
          shadowRadius: 3.84,
          elevation: 5,
        }}
      >
       
        {type == "button" ? renderButtonView() : null}
        {type == "detail" ? renderDetailView() : null}
        {type == "document" ? renderDocumentsView() : null}
        {type == "summary" ? renderSummaryView() : null}
        {type == "table" ? renderTable() : null}
        {type == "custom" ? renderCustomView() : null}
        {type == "empty" ? renderEmptyView() : null}
      </View>
    </View>
  );
};
SkeltonComponents.propTypes = {
  title: PropTypes.string,
  renderArrow: PropTypes.bool
};

SkeltonComponents.defaultProps = {
  type: "detail",
  renderArrow:true
};
export default SkeltonComponents;
