import styled from "styled-components/native";
import getPlatformType from "../../helpers/getPlatform";

const layoutType = getPlatformType();

let WideView,
  DualView,
  FormView,
  WideButtonWrapper,
  WideButtonView,
  ButtonLinkView,
  ButtonLinkBox,
  DualViewBox,
  WideHiddenPropButtonWrapper,
  Container,
  ButtonLinkText,
  InnerContainer,
  ServiceCard,
  ServiceDetailCard,
  BackBox,
  Box,
  ProfileWrapper,
  ProfileLogo,
  ProfileLogoBox,
  MainContent;

if (layoutType == "web") {
  Container = styled.View`
    min-height: 80vh;
    width: 100%;
    margin: 0 auto;
  `;
  ProfileWrapper = styled.View`
    width: 100%;
    padding: 2%;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  `;
  ProfileLogo = styled.View`
    flex: 0.6;
    align-item: flex-end;
  `;
  ProfileLogoBox = styled.View`
    width: 458px;
    flex-direction: row;
  `;
  WideButtonWrapper = styled.View`
    margin-top: 20px;
    width: 458px;
  `;
  WideView = styled.View`
    width: 100%;
  `;
  DualView = styled.View`
    width: 257px;
    margin: 0px 5px;
  `;
  DualViewBox = styled.View`
    display: flex;
    flex-direction: row;
  `;
  ButtonLinkText = styled.Text`
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 21px;
  `;

  ButtonLinkBox = styled.TouchableOpacity`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 40px;
    width: 554px;
    border-top-right-radius: 7px;
    border-bottom-left-radius: 7px;
    background-color: #ffffff;
    box-shadow: rgba(128, 128, 128, 0.4) 3px 2px 3px;
    align-items: center;
    padding: 0px 30px;
    margin-top: 20px;
  `;
  WideHiddenPropButtonWrapper = styled.View`
    margin-top: 20px;
    width: 279px;
  `;
  Box = styled.View`
    align-items: center;
    justify-content: center;
    height: 40px;
    background-color: #ffffff;
    width: 100%;
    border-radius: 5px;
    margin-bottom: 16px;
  `;

  InnerContainer = styled.View`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
    padding: 50px;
    justify-content: center;
  `;
  ServiceCard = styled.TouchableOpacity`
    background-color: #fff;
    justify-content: center;
    align-items: center;
    margin: 20px 20px;
    height: 120px;
    width: 20%;
    box-shadow: rgba(128, 128, 128, 0.4) 0px 0px 7px;
  `;
  ServiceDetailCard = styled.View`
    background-color: #fff;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin: 20px 20px;
    height: 100px;
    width: 20%;
    padding: 10px;
    box-shadow: rgba(128, 128, 128, 0.4) 0px 0px 7px;
  `;
  BackBox = styled.View`
    position: absolute;
    top: 20px;
    left: 40px;
    width: 100%;
  `;
  MainContent = styled.View`
    width: 1124px;
    margin: 0 auto;
    min-height: 80vh;

  `;
} else if (layoutType == "phone") {
  Container = styled.View`
    width: 100%;
  `;
  ProfileWrapper = styled.View`
    width: 100%;
    padding: 2%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 5%;
  `;
  ProfileLogo = styled.View`
    align-item: center;
  `;
  ProfileLogoBox = styled.View`
    width: 100%;
  `;
  WideView = styled.View`
    width: 311px;
    margin: 0px 5px;
  `;
  DualView = styled.View`
    width: 311px;
    margin: 0px 5px;
  `;
  WideButtonWrapper = styled.View`
    margin-top: 20px;
    width: 311px;
  `;
  DualViewBox = styled.View`
    display: flex;
    flex-direction: column;
  `;
  ButtonLinkText = styled.Text`
    color: #000000;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
  `;
  ButtonLinkBox = styled.TouchableOpacity`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 40px;
    width: 343px;
    border-top-right-radius: 7px;
    border-bottom-left-radius: 7px;
    background-color: #ffffff;
    box-shadow: rgba(128, 128, 128, 0.4) 3px 2px 3px;
    align-items: center;
    padding: 0px 30px;
    margin-top: 20px;
  `;
  WideHiddenPropButtonWrapper = styled.View`
    margin-top: 20px;
    width: 279px;
  `;
  Box = styled.View`
    align-items: center;
    justify-content: center;
    height: 40px;
    background-color: #ffffff;
    width: 100%;
    border-radius: 5px;
    margin-bottom: 16px;
  `;
  InnerContainer = styled.View`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
    padding: 35px 20px;
    justify-content: center;
  `;
  ServiceCard = styled.TouchableOpacity`
    background-color: #fff;
    justify-content: center;
    align-items: center;
    margin: 20px 0px;
    height: 120px;
    width: 100%;
    box-shadow: rgba(128, 128, 128, 0.4) 0px 0px 7px;
  `;
  ServiceDetailCard = styled.View`
    background-color: #fff;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    margin: 20px 20px;
    height: 100px;
    width: 100%;
    padding: 10px;
    box-shadow: rgba(128, 128, 128, 0.4) 0px 0px 7px;
  `;
  BackBox = styled.View`
    position: absolute;
    top: 20px;
    left: 15px;
    width: 100%;
  `;
  MainContent = styled.View`
    width: 100%;
    margin: 0 auto;
    padding: 0 16px;
  `;
} else {
  Container = styled.View`
    width: 100%;
    margin: 0 auto;
  `;
  ProfileWrapper = styled.View`
    width: 100%;
    padding: 2%;
    justify-content: center;
    align-items: center;
    padding-top: 5%;
    flex-direction: column;
  `;
  ProfileLogo = styled.View`
    align-item: center;
  `;
  ProfileLogoBox = styled.View`
    width: 100%;
  `;
  WideView = styled.View`
    width: 512px;
    margin: 0px 5px;
  `;
  DualView = styled.View`
    width: 257px;
    margin: 0px 5px;
  `;
  WideButtonWrapper = styled.View`
    margin-top: 20px;
    width: 448px;
  `;
  DualViewBox = styled.View`
    display: flex;
    flex-direction: row;
  `;

  ButtonLinkText = styled.Text`
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
  `;
  ButtonLinkBox = styled.TouchableOpacity`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 40px;
    width: 544px;
    border-top-right-radius: 7px;
    border-bottom-left-radius: 7px;
    background-color: #ffffff;
    box-shadow: rgba(128, 128, 128, 0.4) 3px 2px 3px;
    align-items: center;
    padding: 0px 30px;
    margin-top: 20px;
  `;
  WideHiddenPropButtonWrapper = styled.View`
    margin-top: 20px;
    width: 279px;
  `;
  Box = styled.View`
    align-items: center;
    justify-content: center;
    height: 40px;
    background-color: #ffffff;
    width: 100%;
    border-radius: 5px;
    margin-bottom: 16px;
  `;
  InnerContainer = styled.View`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
    padding: 35px 20px;
    justify-content: center;
  `;
  ServiceCard = styled.TouchableOpacity`
    background-color: #fff;
    justify-content: center;
    align-items: center;
    margin: 20px 20px;
    height: 120px;
    width: 25%;
    box-shadow: rgba(128, 128, 128, 0.4) 0px 0px 7px;
  `;
  ServiceDetailCard = styled.View`
    background-color: #fff;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin: 20px 20px;
    height: 100px;
    width: 25%;
    padding: 10px;
    box-shadow: rgba(128, 128, 128, 0.4) 0px 0px 7px;
  `;
  BackBox = styled.View`
    position: absolute;
    top: 20px;
    left: 20px;
    width: 100%;
  `;
  MainContent = styled.View`
    width: 100%;
    padding: 0 60px;
  `;
}
FormView = styled.View`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  padding: 30px 0px;
  flex-direction: column;
`;
WideButtonView = styled.View`
  display: flex;
  align-items: center;
  justify-content: center;
`;

ButtonLinkView = styled.View`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export {
  WideView,
  DualView,
  FormView,
  WideButtonWrapper,
  WideButtonView,
  ButtonLinkBox,
  ButtonLinkView,
  DualViewBox,
  ButtonLinkText,
  Container,
  WideHiddenPropButtonWrapper,
  Box,
  InnerContainer,
  ServiceCard,
  ServiceDetailCard,
  BackBox,
  MainContent,
  ProfileWrapper,
  ProfileLogo,
  ProfileLogoBox,
};
