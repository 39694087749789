
import { types } from "../../actionTypes";

const preReferencing = (
  state = {
    data: null,
    ccqData:null,
    ccqURLData:null,
    isLoading: false
  },
  action
) => {
  switch (action.type) {
    case types.GET_AFFORD_DATA: {
      return {
        ...state,
        isLoading:true,
        ccqData:null,
      };
    }
    case types.GET_AFFORD_DATA_RESET: {
      return {
        ...state,
        data:null
      };
    }
    case types.GET_AFFORD_SUCCESS: {
      return {
        ...state,
        isLoading:false,
        data: action.payload
      };
    }
    case types.GET_AFFORD_FAIL: {
      return {
        ...state,
        isLoading:false,
      };
    }
    case types.GET_CCQ_DATA: {
      return {
        ...state,
        isLoading:true,
        ccqData:null
      };
    }
    case types.GET_CCQ_SUCCESS: {
      return {
        ...state,
        isLoading:false,
        ccqData:action?.payload,
      };
    }
    case types.GET_CCQ_URI: {
      return {
        ...state,
        isLoading:true,
      };
    }
    case types.GET_CCQ_URI_FAIL: {
      return {
        ...state,
        isLoading:false,
      };
    }
    case types.REMOVE_AFFORDIBILITY_SUCCESS: {
      return {
        ...state,
        isLoading:false,
        removeData:action?.payload,
      };
    }
    case types.REMOVE_AFFORDIBILITY: {
      return {
        ...state,
        isLoading:true,
      };
    }
    case types.REMOVE_AFFORDIBILITY_FAIL: {
      return {
        ...state,
        isLoading:false,
      };
    }
    case types.REMOVE_CCJ_SUCCESS: {
      return {
        ...state,
        isLoading:false,
        ccjRemove:action?.payload,
      };
    }
    case types.REMOVE_CCJ: {
      return {
        ...state,
        isLoading:true,
      };
    }
    case types.REMOVE_CCJ_FAIL: {
      return {
        ...state,
        isLoading:false,
      };
    }
    case types.GET_CCQ_URI_SUCCESS: {
      return {
        ...state,
        isLoading:false,
        ccqURLData:action?.ccqURLData,
      };
    }
    
    case types.GET_CCQ_FAIL: {
      return {
        ...state,
        isLoading:false,
      };
    }
    
    case types.CCJ_PENDING: {
      return {
        ...state,
        isLoading:true,
      };
    }
    case types.CCJ_PENDING_SUCCESS: {
      return {
        ...state,
        isLoading:false,
      };
    }   case types.CCJ_PENDING_FAIL: {
      return {
        ...state,
        isLoading:false,
      };
    }
    
    default:
      return state;
  }
};

export default preReferencing;
