import React, { Component, useState } from "react";
import { View, Text, ImageBackground } from "react-native";
import {
  FormContainer,
  TitleWrapper,
  DropDownWrapper,
  DropDownSectionWrapper,
} from "./createAlertScreen.style";
import BoxSelector from "../../commonComponents/boxSelector/boxSelector";
import getPlatformType from "../../helpers/getPlatform";
import DropDownPicker from "./../../commonComponents/DropDownPicker";
import { FilterConfigObject } from "./../../helpers/objectHelpers";
import GooglePlacesSuggestion from "../../commonComponents/googlePlaces/sugg";
import CheckBox from "../../commonComponents/checkbox/checkbox";
const layoutType = getPlatformType();
const {
  prices_rental,
  furnishingOptions,
  prices_sale,
  bedrooms_min,
  bedrooms_max,
} = FilterConfigObject;
export default function Form(props) {
  const [loc, setLoc, includeinOffer] = useState(props.tab);
  const boxButtons = [
    {
      value: "All",
      text: "All",
      image: require("../../assets/images/creditScore.png"),
      setAll: true,
    },
    {
      value: "Detached",
      text: "Detached",
      image: require("../../assets/images/detached.png"),
    },
    {
      value: "Semi detached",
      text: "Semi detached",
      image: require("../../assets/images/semi-detachedNew.png"),
    },
    {
      value: "Terraced",
      text: "Terraced",
      image: require("../../assets/images/terraced.png"),
    },
    {
      value: "Flats",
      text: "Flats",
      image: require("../../assets/images/flats.png"),
    },
    {
      value: "Bungalow",
      text: "Bungalow",
      image: require("../../assets/images/bunglow.png"),
    },
    {
      value: "Farm/land",
      text: "Farm/land",
      image: require("../../assets/images/farm.png"),
    },
    {
      value: "Other",
      text: "Other",
      image: require("../../assets/images/other.png"),
    },
  ];
  const prices = loc == "Tab1" ? prices_rental : prices_sale;
  let { accomodation_type = [] } = props.formValues;
  typeof accomodation_type !== "object"
    ? JSON.parse(accomodation_type)
    : accomodation_type;

    

  return (
    <FormContainer>
      {!props.readonly && (
        <GooglePlacesSuggestion
          title={"Location"}
          selectedVal={(val) => {
            props.updateData(loc, "location", val);
            props.updateData(loc, "search_string", val ? val.description : "");
            props.updateData(loc, "LocationErrorMsg", "");
          }}
          showError={
            !props.formValues.LocationErrorMsg ||
            props.formValues.LocationErrorMsg == ""
              ? false
              : true
          }
          errorMessage={props.formValues.LocationErrorMsg}
          predictionText={props.formValues.search_string}
          clear={props.clear}
        />
      )}

      <TitleWrapper>Bedrooms</TitleWrapper>

      <DropDownWrapper style={{ zIndex: 100 }}>
        <DropDownSectionWrapper>
          <DropDownPicker
            items={bedrooms_min}
            placeholder="Minimum"
            containerStyle={{ height: 40, marginTop: 8 }}
            style={{
              borderWidth: 1,
              borderColor: "lightgray",
            }}
            zIndex={10012}
            dropDownStyle={{ backgroundColor: "white" }}
            onChangeItem={(e) => {
              
              props.updateData(loc, "bedroomMin", e.value);
            }}
            defaultValue={
              props.formValues.bedroomMin
                ? props.formValues.bedroomMin.toString()
                : "No Min"
            }
            disabled={props.readonly ? true : false}
          />
        </DropDownSectionWrapper>
        <DropDownSectionWrapper style={{ marginLeft: 8 }}>
          <DropDownPicker
            items={bedrooms_max}
            placeholder="Maximum"
            containerStyle={{ height: 40, marginTop: 8 }}
            style={{
              borderWidth: 1,
              borderColor: "lightgray",
            }}
            dropDownStyle={{ backgroundColor: "white" }}
            zIndex={1001}
            onChangeItem={(e) => {
              props.updateData(loc, "bedroomMax", e.value);
            }}
            disabled={props.readonly ? true : false}
            defaultValue={
              props.formValues.bedroomMax
                ? props.formValues.bedroomMax.toString()
                : "No Max"
            }
          />
        </DropDownSectionWrapper>
      </DropDownWrapper>
      <TitleWrapper>Price</TitleWrapper>

      <DropDownWrapper style={{ zIndex: 99 }}>
        <DropDownSectionWrapper>
          <DropDownPicker
            items={prices}
            placeholder="Minimum"
            containerStyle={{ height: 40, marginTop: 8 }}
            style={{
              borderWidth: 1,
              borderColor: "lightgray",
            }}
            zIndex={100}
            dropDownStyle={{ backgroundColor: "white" }}
            onChangeItem={(e) => {
              props.updateData(loc, "min_price", e.value);
            }}
            disabled={props.readonly ? true : false}
            defaultValue={
              props.formValues.min_price
                ? props.formValues.min_price.toString()
                : "No Min"
            }
          />
        </DropDownSectionWrapper>
        <DropDownSectionWrapper style={{ marginLeft: 8 }}>
          <DropDownPicker
            items={prices}
            placeholder="Maximum"
            containerStyle={{ height: 40, marginTop: 8, width: "100%" }}
            style={{
              borderWidth: 1,
              borderColor: "lightgray",
            }}
            dropDownStyle={{ backgroundColor: "white" }}
            onChangeItem={(e) => {
              props.updateData(loc, "max_price", e.value);
            }}
            disabled={props.readonly ? true : false}
            defaultValue={
              props.formValues.max_price
                ? props.formValues.max_price.toString()
                : "No Max"
            }
          />
        </DropDownSectionWrapper>
      </DropDownWrapper>
      {loc == "Tab1" ? (
        <>
          <TitleWrapper>Furnishings</TitleWrapper>
          <View style={{ zIndex: 90 }}>
            <DropDownPicker
              items={furnishingOptions}
              placeholder="Select All"
              onChangeItem={(e) => props.updateData(loc, "furnishing", e.value)}
              containerStyle={{ height: 40, marginTop: 8 }}
              style={{
                borderWidth: 1,
                borderColor: "lightgray",
              }}
              disabled={props.readonly ? true : false}
              dropDownStyle={{ backgroundColor: "white" }}
              defaultValue={
                props.formValues.furnishing
                  ? props.formValues.furnishing.toString()
                  : ""
              }
            />
          </View>
        </>
      ) : null}

      <BoxSelector
        boxList={boxButtons}
        boxStyle={
          layoutType == "phone"
            ? {
                height: 58,
                width: 71,
              }
            : layoutType == "web"
            ? {
                height: 58,
                width: 99,
              }
            : {
                height: 58,
                width: 81,
              }
        }
        selectedIndex={props.formValues.selectedIndex}
        onValueChange={(e) => {
          props.updateData(loc, "accomodation_type", e);
        }}
        setBoxIndex={(e) => {
          props.updateData(loc, "selectedIndex", e);
        }}
        label="Property"
        isDisabled={props.readonly ? props.readonly : false}
        prefilledValues={accomodation_type}
      ></BoxSelector>
      <CheckBox
        fontSize={10}
        checked={props.formValues.under_offer}
        label="Include under offer & sold properties in the search results"
        onPress={() =>
          props.updateData(loc, "under_offer", !props.formValues.under_offer)
        }
      />
    </FormContainer>
  );
}
