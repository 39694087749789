import React from "react";
import styled from "styled-components/native";
import { COLORS } from "../../constants/colorPallete";
import getPlatformType from "../../helpers/getPlatform";

const layoutType = getPlatformType();
let Container, Box, Title, Discription, WideButtonWrapper;

if (layoutType == "phone") {
  Container = styled.View`
  width: 100%;
  margin:0 auto;
  align-items:center; 
  padding:20px 20px;
  background:#ffffff;
  border-radius:4px;
  `;
  Box = styled.View`
  align-items:center; 
  justify-content:center;
  `;
  Title = styled.Text`
    font-size:16px;
    font-weight:bold;
    color:${COLORS.orange};
    padding:24px 0 16px 0;
    display:flex;
  align-items:center; 
  justify-content:center;
  `;
  Discription = styled.Text`
  color: #000000; 
  font-size: 15.3px;
  margin-bottom:32px;
  display:flex;
  align-items:center; 
  justify-content:center;
  `;
  WideButtonWrapper = styled.View` 
  width: 300px;
  align-items: center;
  `;
} else if (layoutType == "web") {
  Container = styled.View`
  width: 463px;
  margin:0 auto;
  align-items:center; 
  padding:32px 80px;
  background:#ffffff;
  border-radius:4px;
  `;
  Box = styled.View`
  align-items:center; 
  `;
  Title = styled.Text`
    font-size:16px;
    font-weight:bold;
    color:${COLORS.orange};
    padding:24px 0 16px 0;
    width:100%;
    display:flex;
  align-items:center; 
  justify-content:center;
  `;
  Discription = styled.Text`
  color: #000000; 
  font-size: 15.3px;
  margin-bottom:32px;
  width:100%;
  display:flex;
  align-items:center; 
  justify-content:center;
  `;
  WideButtonWrapper = styled.View` 
  width: 300px;
  align-items: center;
  `;
} else {
  Container = styled.View`
  width: 320px;
  margin:0 auto;
  align-items:center; 
  padding:32px 80px;
  background:#ffffff;
  border-radius:4px;
  `;
  Box = styled.View`
  align-items:center; 
  `;
  Title = styled.Text`
    font-size:16px;
    font-weight:bold;
    color:${COLORS.orange};
    padding:24px 0 16px 0;
    width:100%;
    display:flex;
  align-items:center; 
  justify-content:center;
  `;
  Discription = styled.Text`
  color: #000000; 
  font-size: 15.3px;
  margin-bottom:32px;
  display:flex;
  align-items:center; 
  justify-content:center;
  `;
  WideButtonWrapper = styled.View` 
  width: 300px;
  align-items: center;
  `;
}
export {
  Container, Box, Title, Discription, WideButtonWrapper
};
