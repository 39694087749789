import { Text, View } from 'react-native';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal  from "react-native-modal";
import { tdsActions } from './tdsRedux/tds.actions';
import { navigateAction, replaceAction } from '../../helpers/navigationHelpers';
import CustomTextInput from '../../commonComponents/customTextInput/customTextInput';
import { BoxShadow, ButtonWrapper, primaryColor, RowContainer, Typography } from './style';
import getPlatformType from '../../helpers/getPlatform';
import { types } from '../../redux/actionTypes';
import { useIsFocused } from '@react-navigation/native';

const ScoctLandlogin = (props) => {
  const dispatch = useDispatch();
  const [type, setType] = useState('web');
  const [state, setState] = useState({username:"",password:""});
  const [showSuccess, setShowSuccess] = useState(false);
  const tdsTokenResponse = useSelector((state) => state.tds?.tdsToken);
  const tdsLoginResponse = useSelector((state) => state.tds?.tdsLoginResponse);
  const previousDeposits = useSelector((state) => state.tds?.previousDeposits);
  const searchedDeposit = useSelector((state) => state.tds?.searchedDeposit);
  const [isSFUser, setIsSFUser] = useState(null);
  const focus = useIsFocused();
  useEffect(() => {
    setState({username:"",password:""})
  }, [focus]);
  const [modal, setModal] = useState({
    visible: false,
    text: '',
  });
  const layoutType = getPlatformType();

  useEffect(() => {
    // dispatch(tdsActions.getTDSToken());
  }, []);

  // useEffect(() => {
  //   if (tdsTokenResponse) {
  //     if (tdsTokenResponse.token) {
  //       navigateAction(props.navigation, 'Custodial Tenancies');
  //     } else {
  //       dispatch(tdsActions.getSearchedDeposit());
  //     }
  //   }
  // }, [tdsTokenResponse]);
  useEffect(() => {
    if (previousDeposits) {
      if (previousDeposits.length > 0) {
        const latestDepositSearched = previousDeposits[0];
        if (latestDepositSearched.response.scheme == 'custodial') {
          setShowSuccess(true);
        }
      }
    }
  }, [previousDeposits]);
  const loginToActivation = () => {
    // navigateAction(props.navigation, 'Custodial Activation',{isSFUser:'Yes'});
    replaceAction(props.navigation, 'Forget Password', { email: state.username,type:"activate",isSdsUser:true })

  };

  const checkUserType = (isPasswordFlow) => {
    dispatch(
      tdsActions.checkUserType(
        {
          email: state.username,
        },
        (data) => {
          if(data?.data?.active_user == 'No'){
            setState({username:"",password:""})
            dispatch({
              type: types.SHOW_ALERT,
              alertType: 'error',
              text: 'Please provide correct credentials',
            });
          }else if (data?.data?.active_user == 'Yes' && !data?.data?.last_login) {
            loginToActivation();
            setIsSFUser(data?.data);
          } else {
            setIsSFUser(data?.data);
          }
          console.log('checkUserType', data);
        },
        true
      )
    );
  };

  const renderInfoBox = () => {
    return (
      <View
        style={{
          width: '100%',
          backgroundColor: '#C4F5D8',
          justifyContent: 'center',
          alignItems: 'center',
          paddingVertical: 50,
          marginVertical: 20,
          borderRadius: 10,
        }}
      >
        <Text
          style={{
            color: type == '#4C664B',
            fontSize: 23,
            fontWeight: '600',
            textAlign: 'center',
          }}
        >
          {' '}
          {'Your deposit is Protected with TDS Custodial'}
        </Text>
      </View>
    );
  };

  const handleChange = (value, field) => {
    setState({
      ...state,
      [field]: value,
    });
  };

  useEffect(() => {
    if (tdsLoginResponse) {
      navigateAction(props.navigation, 'Custodial Tenancies');
    }
  }, [tdsLoginResponse]);

  const loginToTDS = () => {
    dispatch(
      tdsActions.loginToTDS(
        {
          username: state.username,
          password: state.password,
        },
        true
      )
    );
  };

  const userId = useSelector((state) => state.authReducer?.profile?.data?.appUser?.app_user_id);

  return (
    <>
      <Typography size={'24px'} id={'TDS-LOGIN'} color={primaryColor}>
        {' '}
        Login
      </Typography>

      {showSuccess ? renderInfoBox() : null}
      <Typography
        bold={'400'}
        top={'10px'}
        size={'18px'}
        bottom={'30px'}
        color={'#000'}
        fontWeight={'300'}
      >
        Please login below to manage your deposit.
      </Typography>
      <CustomTextInput
        id="username"
        width={'100%'}
        title="Email"
        type="text"
        name="email"
        prefilledValue={state.username}
        bColor={'#638ACC'}
        onChangeText={(value) => handleChange(value, 'username')}
      />

      {isSFUser == null ? null : (
        <CustomTextInput
          id="password"
          width={'100%'}
          title="Password"
          type="password"
          name="password"
          // prefilledValue={state.password}
          bColor={'#638ACC'}
          password
          renderRightComponent
          onChangeText={(value) => handleChange(value, 'password')}
        />
      )}
      <ButtonWrapper
        id={'TDS-LOGIN'}
        style={{width:layoutType=="phone"?"100%":"70%"}}
        onPress={() => (isSFUser == null ? checkUserType() : loginToTDS())}
      >
        <Typography id={'TDS-LOGIN-TEXT'} color={'white'}>
          Continue
        </Typography>
      </ButtonWrapper>

    <RowContainer
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 15,
        }}
      >
        <Typography style={{ fontSize: 17 }} color={'#000'}>
          Forgot password?{' '}
          <Typography
            style={{ fontSize: 17 }}
            id={'TDS-FORGOT-PASSWORD'}
            onPress={() =>{

              if(state?.username ){
                navigateAction(props.navigation, 'Forget Password', { email: state.username })
              }else{
                dispatch({
                  type: types.SHOW_ALERT,
                  alertType: 'error',
                  text: 'Please enter your email to continue!',
                });
              }      
            }}
            color={primaryColor}
          >
            Click here
          </Typography>
        </Typography>
      </RowContainer>
      <Modal
        isVisible={modal.visible}
        transparent={true}
        coverScreen={false}
        hasBackdrop={false}
        backdropOpacity={0}
        onBackdropPress={() =>
          setModal({
            visible: false,
            text: '',
          })
        }
        style={{
          margin: 0,
          width: '100%',
          flex: 1,
          backgroundColor: '#00000080',
          paddingVertical: layoutType == 'phone' ? '5%' : '1%',
          paddingHorizontal: layoutType == 'phone' ? '8%' : '3%',
        }}
      >
        <View
          style={{
            width: layoutType == 'phone' ? '365px' : '500px',
            backgroundColor: '#FAFAFA',
            borderRadius: 20,
            overflow: 'hidden',
            alignSelf: 'center',
            padding: layoutType == 'phone' ? '5%' : '2%',
          }}
        >
          <BoxShadow
            style={{
              backgroundColor: '#C4F5D8',
              paddingHorizontal: 30,
              paddingVertical: 20,
            }}
          >
            <Typography id={'TDS-FORGOT-PASSWORD-TEXT'} color={'#4C664B'} size={'23px'} bold={800}>
              Forgot Password
            </Typography>
          </BoxShadow>
          <Typography top={'33px'} color={'#000'} align={'center'} bold={800}>
            I’ve forgotten my password
          </Typography>
          <Typography top={'33px'} color={'#454545'} align={'left'} bold={500}>
            To recover it you’ll have to continue to the SDS website
          </Typography>

          <ButtonWrapper
            bcolor={'#638ACC'}
            color={'#FFFFFF'}
            width={'80%'}
            style={{ marginTop: 30 }}
            onPress={() => {
              setModal({
                visible: false,
                text: '',
              });
            }}
            id={'TDS-FORGOT-PASSWORD-CANCEL'}
          >
            <Typography color={'black'} size={'19px'} bold={700}>
              Cancel
            </Typography>
          </ButtonWrapper>
          <ButtonWrapper
            color={'#638ACC'}
            width={'80%'}
            style={{ marginTop: 20 }}
            id={'TDS-FORGOT-PASSWORD-OPEN'}
            onPress={() => {
              window.open('https://www.tenancydepositscheme.com/forgotten-password/', '_blank');
              setModal({
                visible: false,
                text: '',
              });
            }}
          >
            <Typography color={'#FFFFFF'} size={'19px'} bold={700}>
              OPEN sds website
            </Typography>
          </ButtonWrapper>
        </View>
      </Modal>
    </>
  );
};

export default ScoctLandlogin;
