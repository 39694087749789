import React from "react";
import styled from "styled-components/native";
import getPlatformType from "../../helpers/getPlatform";
import { StatusBar } from "react-native";

const layoutType = getPlatformType();
let StatusBarMessage;
let StatusBarContainer;
let StatusBarBox;
let BodyMessage;

StatusBarContainer = styled.View`
  position: absolute;
  top: 90px;
  width: 100%;
  left: 0;
  justify-content: center;
  align-items: center;
`;

StatusBarBox = styled.TouchableOpacity`
  width: 450px;
  height: 80px;

  height: auto;
  min-height: 50px;
`;
StatusBarMessage = styled.Text`
  color: white;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
`;
BodyMessage = styled.Text`
  color: white;
  font-size: 13px;
  font-weight: 400;
  text-align: center;
  margin-top:10px;
`;

if (layoutType == "phone") {
  StatusBarContainer = styled.View`
    position: absolute;
    top: 80px;
    width: 100%;
    left: 0;
    justify-content: center;
    align-items: center;
  `;

  StatusBarBox = styled.View`
    width: 100%;
    height: 80px;

    height: auto;
    min-height: 50px;
  `;
}

export { StatusBarMessage, StatusBarContainer, StatusBarBox ,BodyMessage};
