import React from "react";
import styled from "styled-components/native";
import getPlatformType from "../../helpers/getPlatform";
import { ImageBackground } from "react-native";

const layoutType = getPlatformType();
let DrawerWrapper, DrawerIcon, DrawerMenu, SubMenuItem, MenuItem;
if (layoutType == "phone") {
  DrawerWrapper = styled.View`
    flex: 1;
    border-bottom-right-radius: 40px;
    border-top-right-radius: 40px;
    align-items:flex-end
  `;
  DrawerIcon = styled.View`
    flex-direction:row;
    flex:1;
    height: 80px;
    padding: 40px 20px;
    margin-bottom: 30px;
  `;
  DrawerMenu = styled.View`
    padding-left: 25px;
    padding-right: 25px;
  `;
  MenuItem = styled.Text`
    color: #ffffff;
    font-size: 20px;
    font-weight: 900;
    margin-bottom:20px;
  `;
  SubMenuItem = styled.Text`
    color: #ffffff;
    font-size: 18px;
    font-weight: 900;
    margin-bottom: 44px;
    margin-left: 30px;
  `;
} else if (layoutType == "web") {
  DrawerWrapper = styled.View`
    flex: 1;
    border-bottom-right-radius: 40px;
    border-top-right-radius: 40px;
    width: 500px;
    align-items:flex-end
  `;
  DrawerIcon = styled.View`
    height: 107px;
    padding: 50px 60px;
    margin-bottom: 40px;
  `;
  DrawerMenu = styled.View`
    padding-left: 40px;
    padding-right: 40px;
  `;
  MenuItem = styled.Text`
    color: #ffffff;
    font-size: 18px;
    font-weight: 900;
    margin-bottom: 44px;
  `;
  SubMenuItem = styled.Text`
    color: #ffffff;
    font-size: 18px;
    font-weight: 900;
    margin-left: 30px;
  `;
} else {
  DrawerWrapper = styled.View`
    flex: 1;
    border-bottom-right-radius: 40px;
    border-top-right-radius: 40px;
  `;
  DrawerIcon = styled.View`
    height: 107px;
    padding: 50px 60px;
    margin-bottom: 40px;
  `;
  DrawerMenu = styled.View`
    padding-left: 40px;
    padding-right: 40px;
  `;
  MenuItem = styled.Text`
    color: #ffffff;
    font-size: 18px;
    font-weight: 900;
    margin-bottom: 44px;
  `;
  SubMenuItem = styled.Text`
    color: #ffffff;
    font-size: 18px;
    font-weight: 900;
    margin-bottom: 44px;
    margin-left: 30px;
  `;
}
export { DrawerWrapper, DrawerIcon, DrawerMenu, MenuItem, SubMenuItem };
