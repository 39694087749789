import React from "react";
import styled from "styled-components/native";
import getPlatformType from "../../helpers/getPlatform";
import { FlatList } from "react-native-gesture-handler";

const layoutType = getPlatformType();

let BoxButton, BoxView, BoxText, BoxWrapper, BoxTextLabel;
if (layoutType == "phone") {
  BoxTextLabel = styled.Text`
  margin-bottom:20px;
  `;
} else if (layoutType == "web") {
} else {
}
BoxWrapper = styled.View`
  padding-top: 10px;
  padding-bottom: 10px;
`;
BoxView = styled.View`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;
BoxButton = styled.TouchableOpacity`
  border: 1px solid #d0d0d0;
  border-radius: 5px;
  width: 147px;
  height: 58px;
  border-radius: 5px;
  margin: 5px 0px;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;

BoxText = styled.Text`
  color: #000000;
  font-size: 11px;
  font-weight: 700;
  letter-spacing: 0;
  text-align: center;
`;
BoxTextLabel = styled.Text`
  height: 18px;
  color: #000000;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 18px;
  text-align: left;
  margin: 5px 0px;
  padding-left: 2px;
`;

export { BoxButton, BoxView, BoxText, BoxWrapper, BoxTextLabel };
