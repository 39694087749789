import moment from "moment";
import styled from "styled-components/native";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector  } from "react-redux";

import LoanDetails from "./LoanDetails";
import PersonalInfoTab from "./PersonalInfoTab";
import getPlatformType from "../../../helpers/getPlatform";
import EmploymentInformation from "./EmploymentInformation";
import { tenantActions } from "../../openFlow/openFlowRedux/tenant.actions";
import { types } from "../../../redux/actionTypes";

const layoutType = getPlatformType();

const Text = styled.Text`
  font-size: 13px;
  color: #000;
  font-weight: 400;
  font-family: 'Lato';
`;

const TabTitle = styled(Text)`
  color: ${props => (props.isToggled ? "rgb(255, 181, 128)" : '#102047')}; 
  width: ${props => (props.platform == 'web' ? "100%" : (props.platform == 'tab') ? '100%' : 'min-content')};
  font-size: 16px;
  font-weight:700;
  text-align:center;
`;

const Container = styled.View`
  width: 100%;
  display: flex;
  flex-direction: column;
 margin: ${props => (props.platform == 'web' ? "100px auto 0" : (props.platform == 'tab') ? '100px auto 0' : '35px auto 0')};
  border-bottom-left-radius: 20px;
`;

const SubmitBtnMainContainer = styled(Container)`
  width: 100%;
  display: flex;
  margin: 50px 0 0 0;
  align-items: flex-end;
`;

const TabsContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: end;
`;

const BlockTabs = styled.View`
  display: flex;
  min-width: 60%;
`;

const Button = styled.TouchableOpacity`
  background-color: ${props => (props.isToggled ? "#fff" : 'none')};
  border: 1px solid #fff;
  border-top-left-radius: 20px;
  padding: ${props => (props.platform == 'web' ? "15px" : (props.platform == 'tab') ? '15px' : '5px')};
  text-align: center;
  min-width: ${props => (props.platform == 'web' ? "200px" : (props.platform == 'tab') ? '200px' : '100px')};
  border: none;
  cursor: pointer;
  box-sizing: content-box;
  position: relative;
  outline: none;
  box-shadow: rgba(0, 0, 0, 0.07) 10px 10px 6.68px;
  justify-content:center;
  align-items:center;
`;

const ContentTabs = styled.View`
  flex-grow : 1;
  box-shadow: rgba(0, 0, 0, 0.07) 10px 10px 6.68px;
`;

const Content = styled.View`
  background: white;
  padding: ${props => (props.platform == 'web' ? "40px" : (props.platform == 'tab') ? '20px' : '5px')};
  height: 100%;
  display: ${props => (props.isToggled ? "block" : 'none')};
  border: 1px solid #fff;
  border-bottom-left-radius: 20px;
`;

const ParagraphContent = styled.View`
  width: 100%;
  height: 100%;
`;

const SubmitBtnContainer = styled.TouchableOpacity`
  width: 118px;
  height: 42px;
  border-radius: 25px;
  justify-content: center;
  background-color: #D89F6E;
`;

const SubmitBtnTextContainer = styled(Text)`
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
`;

const ZopaForm = (props) => {

  const dispatch = useDispatch();
  const [toggleState, setToggleState] = useState(1);
  const searchedAddresses = useSelector((state) => state.tenant?.searchedAddresses);
  const requestLoanResponse = useSelector((state) => state.tenant?.requestLoanResponse);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  useEffect(() => {
    if(searchedAddresses && Array.isArray(searchedAddresses)) {
      setPostcodeData(
        searchedAddresses.map(e=> {
          return { value:JSON.stringify(e), label:e?.SUB_BUILDING+','+e?.BUILDING_NAME+','+e?.STREET_NAME }
        })
      )
    } else {
      setPostcodeData([]);
    }
  }, [searchedAddresses]);

  useEffect(() => {
    if(requestLoanResponse) {
      console.log("requestLoanResponse", requestLoanResponse);
    }
  }, [requestLoanResponse]);

  const [personalInfoData , setPersonalInfoData] = useState( {
    title: 'Mr',
    firstName: '',
    lastName: '',
    birthDate: null,
    telephone: '',
    email: '',
    maritalStatus: '',
    address1: '',
    address2: '',
    city: '',
    moveInDate: null,
    postalCode: '',
    houseNumber: '',
    houseName: '' ,
    selectedPostcode:null
  });
  const [postcodeData,setPostcodeData]=useState(null);

  const [employmentInfoData , setEmploymentInfoData] = useState( {
    jobTitle: '',
    employerName: '',
    employerSector: '1',
    employmentStatus: 'EMPLOYED_FULL_TIME',
    residentialStatus: 'RENTING',
    currentRent: '',
    annualIncome: ''
  });

  const [loanDetailsData , setLoanDetailsData] = useState( {
    loanAmount: '',
    loanTerm: '12',
    loanPurpose: ''
  });

  const [personalInfoEmptyStatus , setPersonalInfoEmptyStatus] = useState( {
    titleIsEmmpty: false,
    firstNameIsEmmpty: false,
    lastNameIsEmmpty: false,
    birthDateIsEmmpty: false,
    telephoneIsEmmpty: false,
    emailIsEmmpty: false,
    maritalStatusIsEmmpty: false,
    address1IsEmmpty: false,
    address2IsEmmpty: false,
    cityIsEmmpty: false,
    moveInDateIsEmmpty: false,
    postalCodeIsEmmpty: false,
    houseNumberIsEmmpty: false,
    houseNameIsEmmpty: false 
  });

  const [employmentInfoEmptyStatus , setEmploymentInfoEmptyStatus] = useState( {
    jobTitleIsEmmpty: false,
    employerNameIsEmmpty: false,
    currentRentIsEmmpty: false,
    annualIncomeIsEmmpty: false
  });

  const [loanDetailsEmptyStatus , setLoanDetailsEmptyStatus] = useState( {
    loanAmountIsEmmpty: false,
    loanTermIsEmmpty: false,
    loanPurposeIsEmmpty: false
  });

  const onPersonalInfoEmptyFieldsHandler = (key, value) => {
    setPersonalInfoEmptyStatus( {...personalInfoEmptyStatus, [key]: value });
  };

  const onEmploymentInfoEmptyFieldsHandler = (key, value) => {
    setEmploymentInfoEmptyStatus( {...employmentInfoEmptyStatus, [key]: value });
  };

  const onLoanDetailsEmptyFieldsHandler = (key, value) => {
    setLoanDetailsEmptyStatus( {...loanDetailsEmptyStatus, [key]: value });
  };

  const onChangePersonalInfoInputHandler = (key, value) => {
    setPersonalInfoData( {...personalInfoData, [key]: value });
  };

  const onChangeEmploymentInfoInputHandler = (key, value) => {
    setEmploymentInfoData( {...employmentInfoData, [key]: value });
  };

  const onChangeLoanDetailsInputHandler = (key, value) => {
    setLoanDetailsData( {...loanDetailsData, [key]: value });
  };

  const completeFormData = {
    personalInfo: personalInfoData,
    employmentInfo: employmentInfoData,
    loanDetailsInfo: loanDetailsData
  };

  const searchAddress = () => {
    if(personalInfoData?.postalCode!='') {
      dispatch(tenantActions.searchAddress(personalInfoData?.postalCode));
    }
    else{
      onPersonalInfoEmptyFieldsHandler('postalCodeIsEmmpty',true)
      showErrorMessage("Post code")
    }
  };

  const setHouseData=(e)=>{
    setPersonalInfoData({
      ...personalInfoData,
    address1: e?.SUB_BUILDING+','+e?.BUILDING_NAME+','+e?.STREET_NAME,
    address2: e?.TOWN_NAME,
    city: e?.TOWN_NAME,
    houseNumber: e?.BUILDING_NUMBER,
    houseName: e?.BUILDING_NAME ,
    })
  }
  const validateTab3=()=>{
    if(loanDetailsData?.loanAmount==""){
      onLoanDetailsEmptyFieldsHandler('loanAmountIsEmmpty',true);
      showErrorMessage("Loan Amount")
    }
    else if(loanDetailsData?.loanPurpose==0){
      onLoanDetailsEmptyFieldsHandler('loanPurposeIsEmmpty',true);
      showErrorMessage("Loan Purpose")
    }
    else{
      handleFormSubmit();
    }
  }

  const handleFormSubmit = () => {

    let date = moment(completeFormData.personalInfo.birthDate).format("YYYY-MM-DD");
    let date1 = moment(completeFormData.personalInfo.moveInDate).format("YYYY-MM-DD");

    const payload = {
      "loan_amount": parseInt(completeFormData.loanDetailsInfo.loanAmount),
      "loan_term": parseInt(completeFormData.loanDetailsInfo.loanTerm),
      "email": completeFormData.personalInfo.email,
      "first_name": completeFormData.personalInfo.firstName,
      "last_name": completeFormData.personalInfo.lastName,
      "title": completeFormData.personalInfo.title,
      "date_of_birth": date,
      "phone": completeFormData.personalInfo.telephone,
      "employment_status": completeFormData.employmentInfo.employmentStatus,
      "residential_status": completeFormData.employmentInfo.residentialStatus,
      "loan_purpose": completeFormData.loanDetailsInfo.loanPurpose,
      "rent": completeFormData.employmentInfo.currentRent,
      "annual_income": completeFormData.employmentInfo.annualIncome,
      "employer_name": completeFormData.employmentInfo.employerName,
      "job_title": completeFormData.employmentInfo.jobTitle,
      "addresses": [{
        "house_number": completeFormData.personalInfo.houseNumber,
        "street_1": completeFormData.personalInfo.address1.substring(0, 30),
        "street_2": completeFormData.personalInfo.address2.substring(0, 30),
        "post_town": completeFormData.personalInfo.city,
        "post_code": completeFormData.personalInfo.postalCode,
        "start_date": date1
      }]
    }
    dispatch(tenantActions.requestLoan(payload,(res)=>{
      if(res?.status==true){
        console.log("RES-->",res)
        props?.onNext(res);
      }
      else{
        dispatch({
          type: types.SHOW_ALERT,
          alertType:"error",
          text: JSON.stringify(res?.message),
        });
      }
    }));
  };

  const ButtonView=(text, onNext)=>{
    return(
      <SubmitBtnMainContainer>
      <SubmitBtnContainer
      id={"ZOPA-NEXT-SUBMIT"}
      onPress={()=>onNext()}>
          <SubmitBtnTextContainer>{text}</SubmitBtnTextContainer>
      </SubmitBtnContainer>
    </SubmitBtnMainContainer>
    )
  };
  const showErrorMessage=(value)=>{
    dispatch({
      type: types.SHOW_ALERT,
      alertType:"error",
      text: "Please enter mandatory fields",
    });
  }
  const validateTab1=(val)=>{
    if(personalInfoData?.firstName==""){
      onPersonalInfoEmptyFieldsHandler('firstNameIsEmmpty',true)
      showErrorMessage("First name")
    } else if(personalInfoData?.lastName==""){
      onPersonalInfoEmptyFieldsHandler('lastNameIsEmmpty',true)
      showErrorMessage("Last name")
    } else if(personalInfoData?.birthDate==null){
      onPersonalInfoEmptyFieldsHandler('birthDateIsEmmpty',true)
      showErrorMessage("Date of birth")
    }
    else if(personalInfoData?.telephone==""){
      onPersonalInfoEmptyFieldsHandler('telephoneIsEmmpty',true)
      showErrorMessage("Contact number")
    }else if(personalInfoData?.email==""){
      onPersonalInfoEmptyFieldsHandler('emailIsEmmpty',true)
      showErrorMessage("Email")
    }else if(personalInfoData?.postalCode==""){
      onPersonalInfoEmptyFieldsHandler('postalCodeIsEmmpty',true)
      showErrorMessage("Post code")
    }else if(personalInfoData?.address1==""){
      onPersonalInfoEmptyFieldsHandler('address1IsEmmpty',true)
      showErrorMessage("Address line 1")
    }
    else if(personalInfoData?.city==""){
      onPersonalInfoEmptyFieldsHandler('cityIsEmmpty',true)
      showErrorMessage("City")
    }
    else if(personalInfoData?.moveInDate==null){
      onPersonalInfoEmptyFieldsHandler('moveInDateIsEmmpty',true)
      showErrorMessage("Move in date")
    }
    else if(personalInfoData?.houseNumber==""){
      onPersonalInfoEmptyFieldsHandler('houseNumberIsEmmpty',true)
      showErrorMessage("House Number")
    }
    else if(personalInfoData?.address1==""){
      onPersonalInfoEmptyFieldsHandler('address1IsEmmpty',true)
      showErrorMessage("Address 1")
    }
    else if(personalInfoData?.address2==""){
      onPersonalInfoEmptyFieldsHandler('address2IsEmmpty',true)
      showErrorMessage("Address 2")
    }
    else if(personalInfoData?.city==""){
      onPersonalInfoEmptyFieldsHandler('cityIsEmmpty',true)
      showErrorMessage("City")
    }
    else if(personalInfoData?.postalCode==""){
      onPersonalInfoEmptyFieldsHandler('postalCodeIsEmmpty',true)
      showErrorMessage("Post Code")
    }
    else{
      toggleTab(val)
    }
  }
  const validateTab2=()=>{
    if(employmentInfoData?.jobTitle==''){
      onEmploymentInfoEmptyFieldsHandler('jobTitleIsEmmpty',true)
      showErrorMessage("Job title")
    }
    else if(employmentInfoData?.employerName==''){
      onEmploymentInfoEmptyFieldsHandler('employerNameIsEmmpty',true)
      showErrorMessage("Employer Name")
    }
    else if(employmentInfoData?.currentRent==''){
      onEmploymentInfoEmptyFieldsHandler('currentRentIsEmmpty',true)
      showErrorMessage("Current rent")
    }
    else if(employmentInfoData?.annualIncome==''){
      onEmploymentInfoEmptyFieldsHandler('annualIncomeIsEmmpty',true)
      showErrorMessage("Annual Income")
    }
    else {
      toggleTab(3);
    }
  }

  return (
    <>
      <Container platform={layoutType}>
        <TabsContainer>
          <BlockTabs>
            <TabsContainer>
            <Button 
            id={"Personal_Information"}
            platform={layoutType} isToggled={toggleState === 1} onPress={() => validateTab1(1)}>
              <TabTitle isToggled={toggleState === 1}>Personal Information</TabTitle>
            </Button>
            <Button 
             id={"Employment_Information"}
            platform={layoutType} isToggled={toggleState === 2} onPress={() => validateTab1(2)}>
              <TabTitle isToggled={toggleState === 2}>Employment Information</TabTitle>
            </Button>
            <Button 
              id={"Loan_Details"}
            platform={layoutType} isToggled={toggleState === 3} onPress={() => validateTab2()}>
              <TabTitle isToggled={toggleState === 3}>Loan Details</TabTitle>
            </Button>
            </TabsContainer>
          </BlockTabs>
        </TabsContainer>
       <ContentTabs style={{marginBottom:40}}>
          <Content platform={layoutType} isToggled={toggleState === 1} >
            <ParagraphContent >
           <PersonalInfoTab setHouseData={setHouseData} postcodeData={postcodeData} ButtonView={()=>ButtonView("Next",()=>validateTab1(2))}  data={personalInfoData} dataSetter={onChangePersonalInfoInputHandler} checkEmptyStatus={onPersonalInfoEmptyFieldsHandler} personalInfoEmptyStatus={personalInfoEmptyStatus} searchAddress={searchAddress} />
            </ParagraphContent>
          </Content>
          <Content  platform={layoutType} isToggled={toggleState === 2}>
            <ParagraphContent>
              <EmploymentInformation ButtonView={()=>ButtonView("Next",()=>validateTab2())} data={employmentInfoData} dataSetter={onChangeEmploymentInfoInputHandler} checkEmptyStatus={onEmploymentInfoEmptyFieldsHandler} employmentInfoEmptyStatus={employmentInfoEmptyStatus} />
            </ParagraphContent>
          </Content>
          <Content platform={layoutType} isToggled={toggleState === 3}>
            <ParagraphContent>
              <LoanDetails  ButtonView={()=>ButtonView("Submit", validateTab3)}  data={loanDetailsData} dataSetter={onChangeLoanDetailsInputHandler} checkEmptyStatus={onLoanDetailsEmptyFieldsHandler} loanDetailsEmptyStatus={loanDetailsEmptyStatus} />
            </ParagraphContent>
          </Content>
        </ContentTabs>
      </Container>
    
    </>    
  );
}

export default ZopaForm;