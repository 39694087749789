import React, { useState,useEffect } from "react";
import { StyleSheet, View, Text, ScrollView, Image } from "react-native";
import Footer from "../../../commonComponents/footerComponent/customFooter";
import Header from "../../../commonComponents/headerComponent/headerComponent";
import FilePicker from "../../../commonComponents/filePicker/filepicker";
import WideButton from "../../../commonComponents/wideButton";
import { useDispatch } from "react-redux";
import { tenantActions } from "../openFlowRedux/tenant.actions";
import getPlatformType from "../../../helpers/getPlatform";
const layoutType = getPlatformType();

const NonFCCUpload = (props) => {
    const {otp,flowId,fcc_applicant_id,document_id} = props?.route?.params;
    const [fileName, setFileName] = useState("");
    const [files, setFiles] = useState([]);
    const [success, setSuccess] = useState(false);
    const [uploadAnotherDoc, setUploadAnotherDoc] = useState(false);
    const [file, setFile]= useState("");
    const dispatch= useDispatch();

    console.log("_--_",otp+'-'+flowId+'-'+fcc_applicant_id+'-'+document_id);
    console.log("fileNames--_",files);

    return (
      <View style={{flex:1,backgroundColor:'white'}}>
           <Header
        showLogoMob
        showLogoWeb
        showLogoTab
        rightChildLabelTab
        rightChildLabelWeb
        rightChildLabelMob
        showDrawerMob
        showDrawerTab
        showNotification
        {...props}
        isTLYF
      />
        {success && !uploadAnotherDoc ?  
          <View style={{flex:1,justifyContent:'center',alignItems:'center'}}>
            <Image source={{uri:'https://i.imgur.com/ezzkgiT.gif'}} style={{marginBottom:30,height:70,width:70}}/>
            <Text style={{fontSize:20,textAlign:'center'}}>Document uploaded successfully{`\n`}You can now close this page</Text>
          </View>
        :
          <View style={{flex:1,justifyContent:'center',alignItems:'center',paddingHorizontal:20,paddingVertical:60}}>
            <Text style={{fontSize:18,textAlign:'center',color:'#FC4850',marginBottom:40}}>Tenant Reference Company -  Additional Document Submission</Text>
            <Text style={{fontSize:16,textAlign:'center',marginBottom:40}}>The tenant reference company has asked for the following documentation to be sent in order to complete your tenancy reference.
            {`\n`}{`\n`}
                      {file}
            {`\n`}{`\n`}
            Please upload the documents below.</Text>
         <FilePicker
            fileName={fileName}
            accept={'application/pdf'}
            file={file}
            onFileSelect={(val) => {
              setFile(val.name);    
              setFileName(val.openbrixName);
              console.log("ASDSADSD")
              console.log(val)
              setFiles([...files, {
                url: val.openbrixName,
                name: val.name,
                document_id: document_id,
                fcc_applicant_id: fcc_applicant_id
              }])
            }}
          />
          {fileName ? 
            <>
              <WideButton
                borderRadius={15}
                childText="Upload another Document"
                buttonType={"solid"}
                gradiant
                style={{ marginTop: '5%' }}
                width={layoutType !== "phone" ? "25%" : "70%"}
                onPress={() => {
                  setFile("")
                  setFileName("")
                  setUploadAnotherDoc(true)
                }
                } 
              />
                <WideButton
                  borderRadius={15}
                  childText="Confirm & Upload"
                  buttonType={"solid"}
                  gradiant
                  style={{ marginTop: '10%' }}
                  width={layoutType !== "phone" ? "25%" : "70%"}
                  onPress={() => dispatch(tenantActions.uploadTenantRef({otp_code: otp, documents: files}, flowId, (e) => {
                    setSuccess(true);
                    setFileName("");
                    setUploadAnotherDoc(false)
                  }))} />
            </> :
              <>
                <Text style={{ marginTop: 20, fontSize: 12, color: '#FC4850', textAlign: 'center' }}>Only one file can be uploaded per document requested {`\n`} (only pdf files are accepted)</Text>
              </>
            }
              <>
                <Text style={{ marginTop: 50, fontSize: 12, color: '#000', textAlign: 'center' }}>Documents to Upload:</Text>
                {files.map(el => <Text> {el.name} </Text>)}
              </>
          </View>
         }
        
          <Footer {...props} />
      </View> 
     
    );
  };
  export default NonFCCUpload;