import React,{ useState, useEffect }from 'react';
import { ImageBackground,View } from 'react-native';
import { Image } from 'react-native';

export default function AutoHeightImage({ uri, style,blurRadius,Uniquekey }) {
  const [paintedWidth, setPaintedWidth] = useState(0);
  const [resultHeight, setResultHeight] = useState(0);

  useEffect(() => {
    let stillMounted = true;
    Image.getSize(uri, (realW, realH) => {
      if (!paintedWidth || !stillMounted) return;
      const shrinkRatio = realW / paintedWidth;
      setResultHeight(realH / shrinkRatio);
    });
    return () => (stillMounted = false);
  }, [paintedWidth]);
  return (
   <View style={{margin:blurRadius?1:0}}>
    <ImageBackground
      key={Uniquekey}
      style={[{ width: '100%' }, style, { height: resultHeight }]}
      source={uri}
      blurRadius={blurRadius?7:0}
      onLayout={(event) => setPaintedWidth(event.nativeEvent.layout.width)}
      resizeMode=""
    />
 </View>
  );
}