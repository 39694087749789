import React from "react";
import styled from "styled-components/native";
import { COLORS } from "../../constants/colorPallete";
import getPlatformType from "../../helpers/getPlatform";

const layoutType = getPlatformType();

let Container,
  InnerContainer,
  Heading,
  HeadingText,
  AddDocBtnBox,
  DocumentBox,
  DocName,
  DocNameText,
  DocShare,
  DocShareText,
  DocRemove,
  DocRemoveText,
  DocDate,
  DocDateText,
  BackBtnBox,
  DocBoxRight,
  DocBoxLeft,
  ButtonFooter,
  ListBox,
  MainContent;

Container = styled.View`
  width: 1130px;
  margin: 10px auto;
`;
InnerContainer = styled.View`
  width: 980px;
  margin: 0 auto;
`;
Heading = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

DocBoxRight = styled.View`
  display: flex;
  flex-direction: row;
  width: 40%;
  justify-content: space-between;
`;

DocBoxLeft = styled.View`
  display: flex;
  flex-direction: row;
  width: 60%;
  justify-content: space-between;
`;
BackBtnBox = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

HeadingText = styled.Text`
  font-weight: 600;
  font-size: 16px;
`;
ListBox = styled.View`
  margin: 10px 0px;
`;
AddDocBtnBox = styled.View`
  align-self:flex-end;
  margin-top:20px;
  width: 200px;
`;
DocumentBox = styled.View`
  width: 100%;
  padding: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid #ececec;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  box-shadow: rgba(128, 128, 128, 0.4) 3px 2px 3px;
  margin-top: 5px;
  margin-bottom: 5px;
  height: 56px;
`;
DocName = styled.View`
  flex-grow: 1.5;
  border-right-width: 1px;
  border-right-color: #cecece;
  align-items: flex-start;
  justify-content: center;
  width:70%;
`;
DocNameText = styled.Text`
  justify-content: flex-start;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
`;
DocShare = styled.TouchableOpacity`
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  flex-direction: row;
  border-right-width: 1px;
  border-right-color: #cecece;
  width:30%;
`;
DocShareText = styled.Text`
  font-size: 13px;
  font-weight: 500;
  margin-left: 10px;
`;
DocRemove = styled.TouchableOpacity`
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-grow: 1;
  border-right-width: 1px;
  border-right-color: #cecece;
`;
DocRemoveText = styled.Text`
  font-size: 13px;
  font-weight: 500;
  margin-left: 10px;
`;
DocDate = styled.TouchableOpacity`
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  flex-direction: row;
`;
DocDateText = styled.Text`
  font-size: 13px;
  font-weight: 500;
  margin-right: 10px;
  color: ${COLORS.orange};
`;

if (layoutType == "phone") {
  Container = styled.View`
  width:100%;
  margin: 10px auto;
  `;
  InnerContainer = styled.View`
    width: 100%;
    margin: 0 auto;
    min-height: 60%;
  `;
  DocumentBox = styled.View`
    width: 100%;
    padding: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid #ececec;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: rgba(128, 128, 128, 0.4) 3px 2px 3px;
    margin-top: 5px;
    margin-bottom: 5px;
    height: auto;
  `;
  DocBoxRight = styled.View`
    flex-direction: column-reverse;
    flex: 1;
    justify-content: center;
    align-items: center;
  `;
  DocBoxLeft = styled.View`
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
  `;

  AddDocBtnBox = styled.View`
    width: 100%;
  `;
  DocName = styled.View`
    width: 100%;
    align-items: flex-start;
    justify-content: center;
    height: 50px;
    padding: 15px;
  `;
  DocNameText = styled.Text`
    justify-content: flex-start;
    align-items: center;
    font-size: 15px;
    font-weight: 500;
  `;
  DocShare = styled.TouchableOpacity`
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: row;
    border-top-width: 1px;
    border-top-color: #cecece;
    height: 50px;
    padding: 15px;
    border-right-width: 1px;
    border-right-color: #cecece;
  `;

  DocShareText = styled.Text`
    font-size: 13px;
    font-weight: 500;
    margin-left: 10px;
  `;
  DocRemove = styled.TouchableOpacity`
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    border-top-width: 1px;
    border-top-color: #cecece;
    height: 50px;
    padding: 15px;
  `;
  DocDate = styled.TouchableOpacity`
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    flex-direction: row;
    height: 50px;
    padding: 15px;
  `;

  DocDateText = styled.Text`
    font-size: 15px;
    font-weight: 500;
    margin-right: 10px;
    color: ${COLORS.orange};
  `;
  HeadingText = styled.Text`
    font-weight: 700;
    font-size: 16px;
  `;
  ButtonFooter = styled.View`
    background: #fff;
    padding: 20px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  `;
  MainContent = styled.View`
  width: 342px;
  margin: 0 auto;
 `;
 ListBox = styled.View`
 margin: 10px 0 100px;
`;
} else if (layoutType == "web") {
  Container = styled.View`
   width:100%;
   margin: 30px auto;
  `;
  InnerContainer = styled.View`
  `;
  MainContent = styled.View`
   width: 1130px;
   margin: 0 auto;
  `;
} else {
  Container = styled.View` 
    width:100%;
    margin: 10px auto;
  `;
  InnerContainer = styled.View`
    width: 100%;
    margin: 0 auto;
    min-height: 82vh;
  `;

  AddDocBtnBox = styled.View`
    width: 350px;
  `;
  MainContent = styled.View`
  width: 900px;
  margin: 0 auto;
 `;
}

export {
  Container,
  Heading,
  InnerContainer,
  HeadingText,
  AddDocBtnBox,
  ListBox,
  DocumentBox,
  DocName,
  DocNameText,
  DocShare,
  DocShareText,
  DocRemove,
  DocRemoveText,
  DocDate,
  BackBtnBox,
  DocBoxRight,
  DocBoxLeft,
  ButtonFooter,
  DocDateText,
  MainContent
};
