import moment from 'moment';
import { Text, View, Image, TouchableOpacity } from 'react-native';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { tdsActions } from './tdsRedux/tds.actions';
import { backgroundTdsArr } from './backgroundTdsArr';
import { navigateAction, replaceAction } from '../../helpers/navigationHelpers';
import BackGroundGenerator from '../LandingPages/common/BackGroundGenerator';
import {
  Container,
  ButtonWrapper,
  RowContainer,
  Section,
  FormText,
  Heading,
  SubHeading,
  ActivateButtonWrapper,
  primaryColor,
  Typography,
} from './style';
import { tdsLocationSelected } from '../../redux/actions/app';
import getPlatformType from '../../helpers/getPlatform';

const tdsLocation = (props) => {
  const { navigation } = props;
  const layoutType = getPlatformType();
  const dispatch = useDispatch();
  const [type, setType] = useState('web');
  const [showError, setShowError] = useState(false);
  const [state, setState] = useState({ deposit_start_date: null });
  const searchDepositResponse = useSelector((state) => state.tds?.searchedDeposit);

  const handleChange = (value, field) => {
    setState({ ...state, [field]: value });
  };





  const renderInfoBox = (type, text,routes) => {
    return (
      <View
        style={{
          width: 310,
          backgroundColor:  '#e6efff' ,
          justifyContent: 'center',
          alignItems: 'center',
          paddingVertical: 50,
          borderRadius: 10,
          margin:10,
          borderWidth:1,
          borderColor:"#194592"
        }}
        id={'TDS-INFO-BOX'}
      >
        <Text
          style={{
            color: "#194592",
            fontSize: 23,
            fontWeight: '600',
          }}
        >
          {' '}
          {text ? text : ''}
        </Text>
        <ButtonWrapper
        onPress={()=>{
          navigateAction(navigation, routes);
          dispatch(tdsLocationSelected(routes))
        }}
            id={'TDS-GO-BACK'}
            width={"80%"}
            color={"white"}
          >
            <Text style={{ color: '#194592',fontWeight:"700" }}>SELECT</Text>
          </ButtonWrapper>
      </View>
    );
  };


  const renderLocationDeposit = () => {
    return (
      <View style={{ alignItems: 'center' }}>
        <Heading>View & Manage your tenancy deposit</Heading>
        <SubHeading>
          View the details of your existing deposit protected with the government approved Tenancy
          Deposit Scheme.
        </SubHeading>
        <RowContainer style={layoutType=="phone"?{flexDirection:"column",width:"100%",marginTop:30} :{backgroundColor: "white",padding:50,borderRadius:10,marginTop: 30,flexDirection: "column"}}>
        <SubHeading style={{color:"black",marginTop:0}}>
        Please select where your deposit is located
        </SubHeading>
        <View style={layoutType=="phone"?{flexDirection:"column",alignItems:'center'} :{flexDirection:"row",marginTop:20}}>
          {renderInfoBox('fail', 'England or Wales',"TDS Search Deposit")}
          {renderInfoBox('fail', 'Scotland',"TdsScoctland")}
          </View>
        </RowContainer>
      </View>
    );
  };

  return (
    <>
      <BackGroundGenerator
        replace={'Dashboard'}
        isBack={true}
        backNavigation={() => replaceAction(navigation, 'Dashboard')}
        isTds
        props={props}
        infoIcon
        layoutType={(data) => {
          setType(data);
        }}
        isTLYF
        hideMiddleLogo={true}
        backgroundArr={backgroundTdsArr}
      >
        <Container style={{marginTop:50}}>{renderLocationDeposit()}</Container>
      </BackGroundGenerator>
    </>
  );
};

export default tdsLocation;
