import React from "react";
import styled from "styled-components/native";
import getPlatformType from "../../helpers/getPlatform";
import { ImageBackground } from "react-native";

const layoutType = getPlatformType();
let Title;
let Container;
let Background;
let TabWrapper;
let FormContainer;
let ListSection;
let TitleWrapper;
let PropertyCardWrapper;
let DropDownWrapper,
  DropDownSectionWrapper,
  IntroWrapper,
  TitleTextWrapper,
  ReviewWrapper,
  ButtomSection,
  MainContent,
  ReviewItem;
if (layoutType == "phone") {
  Container = styled.View`
    flex: 1;
    background-color: #fafafa;
  `;
  FormContainer = styled.View`
    padding: 8px 16px;
    width: 100%;
  `;
  TitleWrapper = styled.Text`
    color: #000000;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
    margin: 21px 0px 0px 0px;
  `;
  ListSection = styled.View`
    position: absolute;
    top: 10%;
    width: 100%;
    height: 100;
  `;
  Title = styled.Text`
    color: #000;
    text-align: center;
    font-size: 16px;
    margin-top: 15px;
  `;
  Background = styled.View`
    padding: 0 16px;
  `;
  TabWrapper = styled.View`
    width: 100%;
  `;
  PropertyCardWrapper = styled.View`
    height: 226px;
    margin: 0px 0px 0px 16px;
  `;
  TitleTextWrapper = styled.Text`
    margin: 30px 0px 16px 16px;
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
  `;
  IntroWrapper = styled.View`
    background-color: #ffffff;
    margin: 40px 0px 0px 0px;
    shadow-color: #000;
  `;
  ReviewWrapper = styled.View`
    padding: 0 16px 16px 16px;
  `;
  ReviewItem = styled.View`
    width: 100%;
    padding: 16px;
    margin: 8px 8px 8px 0;
  `;
  MainContent = styled.View`
    width: 100%;
    padding: 0;
  `;
} else if (layoutType == "web") {
  Container = styled.View`
    flex: 1;
    background-color: #fafafa;
  `;
  Title = styled.Text`
    color: #000;
    text-align: center;
    font-size: 16px;
    margin-top: 15px;
  `;
  Background = styled(ImageBackground)`
    height: auto;
    width: "100%";
  `;
  TabWrapper = styled.View`
    height: 669px;
    width: 459px;
    margin-left: 95px;
  `;
  FormContainer = styled.View`
    margin: 0px 16px;
  `;
  TitleWrapper = styled.Text`
    color: #000000;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
    margin: 10px 0px 0px 0px;
  `;
  PropertyCardWrapper = styled.View`
    height: auto;
  `;
  TitleTextWrapper = styled.Text`
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
    margin: 48px 0 24px;
  `;
  IntroWrapper = styled.View`
    background-color: #ffffff;
    margin: 48px 0px 0px 0px;
    shadow-color: #000;
  `;
  ReviewWrapper = styled.View`
    margin-bottom: 66px;
    flex-direction: row;
    justify-content: space-between;
  `;
  ReviewItem = styled.View`
    width: 250px;
    padding: 24px;
    margin: 10px;
  `;
  MainContent = styled.View`
    width: 100%;
    padding: 0 150px;
  `;
} else {
  Container = styled.View`
    flex: 1;
    background-color: #fafafa;
  `;
  Title = styled.Text`
    color: #000;
    text-align: center;
    font-size: 16px;
  `;
  Background = styled(ImageBackground)`
    justify-content: center;
    padding-bottom: 20px;
    width: "100%";
  `;
  TabWrapper = styled.View`
    height: 100%;
    width: 384px;
    padding-top: 20px;
    margin: 1px 123px;
  `;
  FormContainer = styled.View`
    margin: 0px 16px;
  `;
  TitleWrapper = styled.Text`
    color: #000000;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
    margin: 21px 0px 0px 0px;
  `;
  PropertyCardWrapper = styled.View`
    height: 226px;
    margin: 0px 0px 0px 40px;
  `;
  TitleTextWrapper = styled.Text`
    margin: 48px 0px 16px 40px;
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
  `;
  IntroWrapper = styled.View`
    background-color: #ffffff;
    margin: 40px 0px 0px 0px;
    shadow-color: #000;
  `;

  ReviewWrapper = styled.View`
    margin-bottom: 66px;
    flex-direction: row;
    justify-content: space-between;
    height: 153px;
    margin: 0px 40px 40px 40px;
  `;
  ReviewItem = styled.View`
    width: 220px;
    padding: 24px;
    margin: 10px;
  `;
  MainContent = styled.View`
    width: 100%;
  `;
}
DropDownWrapper = styled.View`
  flex-direction: row;
`;
DropDownSectionWrapper = styled.View`
  flex: 0.5;
`;

ButtomSection = styled.TouchableOpacity`
  margin: 10px 0px 10px;
  justify-content: flex-end;
  align-items: flex-end;
`;

export {
  Container,
  Title,
  Background,
  TabWrapper,
  FormContainer,
  ListSection,
  TitleWrapper,
  DropDownWrapper,
  DropDownSectionWrapper,
  ButtomSection,
  IntroWrapper,
  PropertyCardWrapper,
  ReviewWrapper,
  TitleTextWrapper,
  MainContent,
  ReviewItem,
};
