import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  headingText: {
    fontFamily: 'Lato',
    fontWeight: "700",
    fontSize: 39,
  },
  heading1: {
    fontFamily: 'Lato',
    fontWeight: "700",
    fontSize: 32,
  },
  heading2: {
    fontFamily: 'Lato',
    fontWeight: "700",
    fontSize: 24,
  },
  heading3: {
    fontFamily: 'Lato',
    fontWeight: "400",
    fontSize: 19,
  },
  TextCenter:{
      textAlign:"center"
  },
  container: {
    marginTop: 241,
  },

  headingWrapper: {
    alignSelf: 'center',
    alignItems:'center'
  },
  headingTextWrapper: {
    textAlign: 'center',
  },
  videoWrapper:{
    width: 644,
    height: 351,
    borderWidth:0.5,
    borderColor: "#000000",
    borderRadius: 9,
    padding:9,
    backgroundColor: "transparent",
    marginTop:176
  },
  visionWrapper:{
    marginTop:392,
      alignItems:'center',
      justifyContent:'center'
  },
  visionSubTextWrapper:{
    marginTop: 58,
    alignItems: 'center',
    justifyContent: 'center',
  },
  saperaterWrapper: {
    marginVertical: 65,
  },
  saperator: {
    height: 1,
    backgroundColor: '#6d6d6d',
  },
  valuesWrapper:{
    marginBottom: 106,
    marginLeft: 69
  },
  sectionWrapper: {
    marginBottom: 52,
  },
  section1: {
    marginBottom: 28,
  },
  section2: {
    marginBottom: 36,
  },
  manifastoWrapper:{
    marginTop: 87,
    marginBottom: 75,
    alignItems:"center"
},
manifestoText:{
    textAlign:"center"
},
cardWrapper: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems:'center',
  },
  cardSection:{
    marginTop: 77
 },
 piramidWrapper:{
  justifyContent:'center',
  alignItems: "center",
  marginBottom:70
   },
 formWrapper: {
    alignSelf: 'center',
    width: 535,
    paddingHorizontal: 35,
    paddingVertical: 46,
    borderColor: '#6D6D6D',
    borderWidth: 0.5,
    borderRadius: 13,
    marginTop:70
  },
  horizontal: {
    marginHorizontal: 25,
  },
});
