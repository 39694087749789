import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { View, Text, Image, StyleSheet } from "react-native";

import CardComponent from "./Components/cardComponent";
import { ContainerScrollView } from "../../helpers/appStyles";
import { navigateAction } from "../../helpers/navigationHelpers";
import { getDigitalVerificationStatus } from "../openFlow/helpers";
import { getCcqStatus } from "../../redux/actions/preReferencing";
import Footer from "../../commonComponents/footerComponent/customFooter";
import Header from "../../commonComponents/headerComponent/headerComponent";
const WIN_HEIGHT=Dimensions.get('window').height;
import { MainTitle, Title, TlyfeWrapper, Wrapper, BoxWrapper, CardWrapper, MainSection, OpenBrixCard } from "./style";
import { Dimensions } from "react-native";

const DashboardNew = (props) => {
  const { navigation } = props;
  const dispatch = useDispatch();
  const affordData = useSelector((state) => state?.preReferencing?.ccqData);
  const yotiRequestId = useSelector(
    (state) => state.authReducer?.profile?.data?.user_id
  );

  const userData = useSelector(
    (state) => state.authReducer?.profile?.data?.appUser
  );

  const lite_ref_status = useSelector(
      (state) => state.authReducer?.profile?.data?.appUser?.lite_referencing_status
  );
  useEffect(() => {
    dispatch(getCcqStatus(true));
  }, []);

  const getStatus = (status) => {
    if (status == 0) {
      return "NOT_STARTED";
    } else if (status == 1) {
      return "IN_PROGRESS";
    } else if (status == 2) {
      return "Completed";
    } else {
      return "NOT_STARTED";
    }
  };
  const getButtonStatus = () => {
    switch (lite_ref_status) {
      case 1:
        return "IN_PROGRESS";
        break;
      case 0:
        return "NOT_STARTED";
        break;
      case 2:
        return "Completed";
        break;
      default:
        return "NOT_STARTED";
        break;
    }
    /*if (affordData?.completion) {
      if (
        (affordData?.completion?.affordability_completed &&
          affordData?.completion?.ccj_completed) ||
        affordData?.completion?.skip_ccj
      ) {
        return "Completed";
      } else if (
        affordData?.completion?.affordability_completed ||
        affordData?.completion?.ccj_completed
      ) {
        return "IN_PROGRESS";
      }
    } else {
      return "NOT_STARTED";
    }*/
  };
  
  const getOpenflowStatusInterface = (status_code) => {
    switch (status_code) {
      case 1:
        return "IN_PROGRESS";
        break;
      case 0:
        return "NOT_STARTED";
        break;
      case 2:
        return "Completed";
        break;
      default:
        return "NOT_STARTED";
        break;
    }
  };
  return (
    <View style={{ backgroundColor: "#FAFAFA", height:WIN_HEIGHT  }}>
      <Header
        showLogoMob
        showLogoWeb
        showLogoTab
        rightChildLabelTab
        rightChildLabelWeb
        rightChildLabelMob
        showDrawerMob
        showDrawerTab
        showNotification
        {...props}
        isTLYF
      />
      <ContainerScrollView>
        <MainTitle style={{ marginTop: 10 }}>My ID & Flo's</MainTitle>
        <Wrapper>
          <TlyfeWrapper>
            <MainSection>
              <Title>Tlyfe Number</Title>
              <BoxWrapper>
                <MainTitle id="tlyfeNo">{userData?.opbrix_id}</MainTitle>
              </BoxWrapper>
            </MainSection>
            <CardWrapper>
            <CardComponent
                status={getOpenflowStatusInterface(userData?.openflow_status)}
                title={"Tenant Moving-In"}
                id={"tenant_move_in_button"}
                onCardClick={(data) => {
                  
                }}
              />
              <CardComponent
                statusTitle={
                  getDigitalVerificationStatus(userData?.idverification).Text
                }
                title={"ID Verification"}
                status={getStatus(userData?.idverification)}
                onCardClick={() => navigateAction(navigation, "IdVerification")}
              />

              <CardComponent
                status={getButtonStatus()}
                onCardClick={(data) =>
                  navigateAction(navigation, "PreReferencing")
                }
                title={"Pre - Qualification"}
              />
              <CardComponent
                status={"NOT_STARTED"}
                title={"Credit Builder"}
                onCardClick={(data) =>
                  navigateAction(navigation, "Credit Builder")
                }
              />
         
              {/* <CardComponent
                status={"NOT_STARTED"}
                title={"Tenant Insurance"}
                onCardClick={(data)=>navigateAction(navigation,"PreReferencing")}
              /> */}

              {/* <CardComponent /> */}
            </CardWrapper>
            <OpenBrixCard
              onPress={() =>
                props.navigation.navigate(
                  "tlyfe - powered by OpenBrix"
                )
              }
            >
              <Text
                style={{
                  fontSize: 18,
                  color: "black",
                  fontWeight: "600",
                }}
              >
               Go to
              </Text>
              <Image
                source={require("../../assets/images/obxLogo.png")}
                style={{
                  width: 100,
                  height: 12,
                  marginTop: 5,
                  resizeMode: "contain",
                }}
              />
              <Text
                style={{
                  fontSize: 18,
                  color: "black",
                  fontWeight: "600",
                }}
              >
                property search.
              </Text>
            </OpenBrixCard>
          </TlyfeWrapper>
        </Wrapper>

        <Footer
        isTLYF
        {...props}/>
      </ContainerScrollView>
    </View>
  );
};
const styles = StyleSheet.create({});
export default DashboardNew;
