import React, { useCallback, useEffect, useRef, useState } from "react";
import { Dimensions, ImageBackground, View } from "react-native";
import stylesMobile from "./stylesMobile";
import stylesTab from "./stylesTab";
import stylesWeb from "./stylesWeb";
import { backgroundSearchArr } from "../images/tlyfe/searchArrayTlyfe";
import BackGroundGenerator from "../common/BackGroundGenerator";
import { navigateAction } from "../../../helpers/navigationHelpers";
import YoutubePlayer from "../../../commonComponents/youtubePlayer/youtubePlayer";
import Constant from "../../tds/tdsRedux/Constant";
import { useSelector } from "react-redux";
import { Typography } from "../../openFlow/style";
import getPlatformType from "../../../helpers/getPlatform";
import CommonAuth from "./CommonAuth";
import ImageView from "./imageView";
import { ObxLogo } from "../../../commonComponents/headerComponent/headerStyle";
import VimeoPlayer from "../../../commonComponents/youtubePlayer/vimeoPlayer";


const LandingTlyfe = (props) => {

  const FEATURED = [
    {
      heading: "Pre-Qualification",
      heading2: "",
      subheading:
        "Give yourself the best chance of securing your next rental property by using our Pre-Qualification tool to become a ‘Rent Ready’ Tenant. Share the results with your agent to secure priority viewings!",
      imageUrl: require("./images/tlyfe/preq.png"),
    },
    {
      heading: "Improve Your Credit Score With Rent Payments",
      heading2: "",
      subheading:
        "Keep an ongoing digital record of all your rental payments and, provided you pay on time, help to build your credit score. Life just got a whole lot easier!",
      imageUrl: require("./images/tlyfe/payment.png"),
    },
    {
      heading: "DEPOSIT PROTECTION WITH TDS",
      heading2: "",
      subheading:
        "View your deposit protection, download your protection certificate and manage the return of your deposit at the end of your tenancy, with the Government backed Tenancy Deposit Scheme.",
      imageUrl: require("./images/tlyfe/tds.png"),
    },
    {
      heading: "Tenant Insurance",
      heading2: "",
      subheading:
        "A small price to pay for big protection! Get a good nights sleep knowing your valuables are covered with Tenant Contents Insurance!",
      imageUrl: require("./images/tlyfe/insu.png"),
    },
    {
      heading: "Verified Digital ID",
      heading2: "",
      subheading:
        "Verify your identity digitally in a secure environment without having to leave your home or risk losing ID documents in the post. Quick, safe and simple verification that works!",
      imageUrl: require("./images/tlyfe/digi.png"),
    },
    {
      heading: "Tenant Loan Solution",
      heading2: "",
      subheading:
        "Struggling with move-in costs? Whether its help with your deposit, a trip to Ikea or buying essential items on move-in day, our competitive solutions will help you get settled in your new home.",
      imageUrl: require("./images/tlyfe/loan.png"),
      onButtonClick: () => {
        scollToRef.current.scrollIntoView({ behavior: "smooth" });
      },
      buttonText: "CLICK HERE TO REGISTER/LOGIN",
      buttonStyle: { marginTop: 30 },
    },
  ];
  const scollToRef = useRef();
  const scrollViewRef = useRef();

  const [type, setType] = useState("web");
  const layoutType = getPlatformType();
  const isAnylyticsEnable = useSelector(
    (state) => state?.cookiesReducer?.customiseCookies.Analytics,
  );
  const { navigate } = props.navigation;
  const navigateTo = (params, from) => {
    if (from) {
      navigateAction(props?.navigation, params, from);
    } else {
      navigateAction(props?.navigation, params);
    }
  };
  const [property_for, setPropertyFor] = useState(1);
  const styles = type == "web" ? stylesWeb : type == "mobile" ? stylesMobile : stylesTab;
  const [state, setState] = useState({
    from: "",
  });
  const handleScrollButtonClick = useCallback(() => {
    const height=Dimensions.get('window').height;
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTo({ y:layoutType=='web'?height:height/2, animated: true });
    }
  }, []);
  useEffect(() => {
    if (props?.route?.params?.email) {
      setState({
        ...state,
        from: props?.route?.params?.email ? props?.route?.params?.email : "",
      });
    }
  }, [props?.route?.params?.email]);
  console.log("statestatestate", state);
  let BottomWrapper = type == "web" ? ImageBackground : View;
  return (
    <>
      <BackGroundGenerator
        props={props}
        layoutType={(data) => setType(data)}
        backgroundArr={backgroundSearchArr}
        isTLYF={true}
        isLanding
        cloudHeader={true}
        scrollViewRef={scrollViewRef}
      >
        <div ref={scollToRef}/>
        <CommonAuth
          heading={"RENT SMARTER\nWITH TLYFE,"}
          navigation={navigate}
          type={type}
          onScrollButtonClick={handleScrollButtonClick}
          isTLYF={true}
          subheading={"LOGIN AND/OR REGISTER TODAY!"}
          desc={""}
          hideButton={false}
          params={state}
          {...props}
        />
        <View style={[styles.imageContainer, { backgroundColor: "#FAFAFB" }]}>
          <ObxLogo
            source={require("../../../assets/images/tlyfeBig.png")}
            resizeMode="contain"
            style={{
              marginLeft: 0,
              alignSelf: "center",
              marginBottom: 20,
            }}
          />
          <Typography
            line={"35px"}
            raj
            bold={"600"}
            top={"20px"}
            color={"#1C1C1C"}
            size={"26px"}
            align={"center"}
            bottom={"20px"}
          >
            The UKs tenant lifecycle app, taking the stress out of the move in process and making move-in day that
            little bit more special!
          </Typography>
          <View style={styles.videoWrapper}>

            <VimeoPlayer url={Constant.TLYFE_URL} height={"100%"} width={"100%"}/>
          </View>
        </View>
        {FEATURED.map((item, index) => {
          return (
            <ImageView
              key={index}
              reverse={index % 2 === 0 ? false : true}
              type={type}
              heading={item.heading}
              heading2={item.heading2}
              bColor={index % 2 === 0 ? "white" : "#FAFAFB"}
              subheading={item.subheading}
              imageUrl={item.imageUrl}
              onButtonClick={item?.onButtonClick}
              buttonText={item?.buttonText}
              buttonStyle={
                item?.buttonStyle
                  ? {
                    ...item.buttonStyle,
                    marginBottom: type != "web" ? 400 : 250,
                  }
                  : {}
              }
              isTLYF={true}
            />
          );
        })}
      </BackGroundGenerator>
    </>
  );
};
export default LandingTlyfe;
