import React from "react";
import { View } from "react-native";
import BoxSelector from "../../commonComponents/boxSelector/boxSelector";
// import DropDownPicker from "./../../commonComponents/hiteshDropdown";
import DropDownPicker from "../../commonComponents/DropDownPicker";
import GooglePlacesSuggestion from "../../commonComponents/googlePlaces/sugg";
import { SERVER_URL } from "../../helpers/connectionHelpers";
import { filterMaxArray } from "../../helpers/functionHelpers";
import getPlatformType from "../../helpers/getPlatform";
import WideButton from "./../../commonComponents/wideButton";
import { FilterConfigObject } from "./../../helpers/objectHelpers";
import {
  ButtomSection, DropDownSectionWrapper, DropDownWrapper, FormContainer,

  TitleWrapper
} from "./form.style";
import { search } from "../../redux/actions/property";

// import GooglePlacesComponent from "./../../commonComponents/googlePlaces/GooglePlacesComponent";
const layoutType = getPlatformType();
const {
  distances,
  prices_rental,
  min_price_rental,
  max_price_rental,
  min_price_sale,
  max_price_sale,
  furnishingOptions,
  bedrooms_min,
  bedrooms_max,
  prices_sale,

} = FilterConfigObject;
export default function Form(props) {
  
  const { navigate, onSearch, property_for,searchObject ,updateData} = props;
  const [loc, setLoc] = React.useState("Tab1");
  const [minBed, setMinBed] = React.useState(searchObject?.filters?.min_bedroom);
  const [maxBed, setMaxBed] = React.useState(searchObject?.filters?.max_bedroom);
  const [maxPrice, setMaxPrice] = React.useState(searchObject?.filters?.max_price);
  const [minPrice, setMinPrice] = React.useState(searchObject?.filters?.min_price);
  const [furnishing, setFurnishing] = React.useState(searchObject?.filters?.furnishing);
  const [accomodation_type, setAccod] = React.useState(searchObject?.filters?.accomodation_type);
  const [city, setCity] = React.useState(null);
  const [lat, setLat] = React.useState(searchObject?.lat);
  const [lng, setLng] = React.useState(searchObject?.lon);
  const [locationError, setLocationError] = React.useState(props.locError);
  const [clear, SetClear] = React.useState(false);
  const [defaultSerach,setSearch]=React.useState(searchObject?.search)

  const _getBoxValues = (e) => {
    
    setAccod(e);
    updateData("accomodation_type",e);
  };
  const ApiCall = (key) => {
    const placeid = key;

    fetch(SERVER_URL + "/google_place_id?string=" + placeid)
      .then((response) => response.json())
      .then((responseJson) => {
        

        let data = {
          lat: responseJson.result.geometry.location.lat,
          lon: responseJson.result.geometry.location.lng,
          search: responseJson.result.formatted_address,
          filters: {
            accomodation_type: accomodation_type,
            added: null,
            max_bedroom: maxBed,
            min_bedroom:minBed,
            // bedroom_count: 1,
            // distance: 8,
            furnishing: furnishing,
            gardern: null,
            // keywords: [],
            max_price: parseInt(maxPrice),
            min_price: parseInt(minPrice),
            parking: null,
            property_for: property_for,
            retirementHomes: null,
            sharedOwnership: null,
            underOffer: null,
          },
        };
        onSearch(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const getGeo = async () => {
    await ApiCall(city.place_id);
  };
  const _onSearch = async () => {
    SetClear(true);
    // setTimeout(() => {
    //   SetClear(false);
    // }, 1000);
    if (city != null) {
      setLocationError("");
      let data = {
        lat: city.lat,
        lon: city.lon,
        user_input: city.user_input,
        types: city.types,
        search: city.description,
        filters: {
          accomodation_type: accomodation_type,
          min_bedroom:parseInt(minBed),
          max_bedroom: parseInt(maxBed),
          furnishing: furnishing,
          max_price: parseInt(maxPrice),
          min_price: parseInt(minPrice),
          property_for: property_for,
        },
      };
      

      await onSearch(data);
      SetClear(false);

      // getGeo();
    } else {
      //  alert('no')
      setLocationError("You must select the location");
    }
  };

  const boxButtons = [
    {
      value: "All",
      text: "All",
      image: require("../../assets/images/creditScore.png"),
      setAll: true,
    },
    {
      value: "Detached",
      text: "Detached",
      image: require("../../assets/images/detached.png"),
    },
    {
      value: "Semi detached",
      text: "Semi detached",
      image: require("../../assets/images/semi-detachedNew.png"),
    },
    {
      value: "Terraced",
      text: "Terraced",
      image: require("../../assets/images/terraced.png"),
    },
    {
      value: "Flats",
      text: "Flats",
      image: require("../../assets/images/flats.png"),
    },
    {
      value: "Bungalow",
      text: "Bungalow",
      image: require("../../assets/images/bunglow.png"),
    },
    {
      value: "Farm/land",
      text: "Farm/land",
      image: require("../../assets/images/farm.png"),
    },
    {
      value: "Other",
      text: "Other",
      image: require("../../assets/images/other.png"),
    },
  ];
  
  return (
    <FormContainer>
      <GooglePlacesSuggestion
        title={"Location"}
        selectedVal={(VAL) => {
          
          setCity(VAL);
          setSearch(VAL?.description);
         updateData("search",VAL?.description);
         updateData("lat",VAL?.lat);
         updateData("lon",VAL?.lon);
        }}
        predictionText={defaultSerach}
        showError={locationError == "" ? false : true}
        errorMessage={locationError}
        clear={clear}
      />

      <TitleWrapper>Bedrooms</TitleWrapper>

      <DropDownWrapper style={{ zIndex: 100 }}>
        <DropDownSectionWrapper>
          <DropDownPicker
            items={bedrooms_min}
            placeholder="Minimum"
            onChangeItem={(e) =>{
              setMinBed(e.value);
              updateData("min_bedroom",parseInt(e.value));
            } 
              }
            dropDownStyle={{ backgroundColor: "white" }}
            zIndex={1001}
             defaultValue={minBed}

          />
        </DropDownSectionWrapper>
        <DropDownSectionWrapper style={{ marginLeft: 8 }}>
          <DropDownPicker
            items={filterMaxArray(bedrooms_max,minBed)}
            placeholder="Maximum"
            containerStyle={{ height: 40, marginTop: layoutType == "phone" ? 0 : 8 }}
            style={{
              borderWidth: 1,
              borderColor: "lightgray",
            }}
            onChangeItem={(e) =>{
              setMaxBed(e.value)
              updateData("max_bedroom",parseInt(e.value));
            } }
            defaultValue={maxBed}
            dropDownStyle={{ backgroundColor: "white" }}
            zIndex={1001}
          />
        </DropDownSectionWrapper>
      </DropDownWrapper>
      <TitleWrapper>Price</TitleWrapper>

      <DropDownWrapper style={{ zIndex: 99 }}>
        <DropDownSectionWrapper>
          <DropDownPicker
            items={property_for == 1 ? min_price_rental : min_price_sale}
            placeholder="Minimum"
            containerStyle={{ height: 40, marginTop: 8 }}
            style={{
              borderWidth: 1,
              borderColor: "lightgray",
            }}
            zIndex={100}
            defaultValue={minPrice}
            onChangeItem={(e) => {
              setMinPrice(e.value)
              updateData("min_price",parseInt(e.value))
            }}
            dropDownStyle={{ backgroundColor: "white" }}
          />
        </DropDownSectionWrapper>
        <DropDownSectionWrapper style={{ marginLeft: 8 }}>
          <DropDownPicker
            items={property_for == 1 ? filterMaxArray(max_price_rental,minPrice) : filterMaxArray(max_price_sale,minPrice)}
            placeholder="Maximum"
            containerStyle={{ height: 40, marginTop: 8, width: "100%" }}
            style={{
              borderWidth: 1,
              borderColor: "lightgray",
            }}
            onChangeItem={(e) => {
              setMaxPrice(e.value)
              updateData("max_price",parseInt(e.value))
            }}
            defaultValue={maxPrice}
            dropDownStyle={{ backgroundColor: "white" }}
          />
        </DropDownSectionWrapper>
      </DropDownWrapper>
      <BoxSelector
        boxList={boxButtons}
        onValueChange={(e) => _getBoxValues(e)}
        prefilledValues= {accomodation_type}
        boxStyle={
          layoutType == "phone"
            ? {
              height: 58,
              width: 71,
            }
            : layoutType == "web"
              ? {
                height: 58,
                width: 99,
              }
              : {
                height: 58,
                width: 81,
              }
        }
        label="Property"
      ></BoxSelector>
      {/*property_for == 1 ? <TitleWrapper style={{ marginTop: 0 }}>Furnishings</TitleWrapper> : null}
      {property_for == 1 ? (
        <View style={{ zIndex: 1000 }}>
          <DropDownPicker
            items={furnishingOptions}
            placeholder="Select All"
            onChangeItem={(e) => {
              setFurnishing(e.value)
              updateData("furnishing",e.value)
            }}
            containerStyle={{ height: 40, marginTop: 8 }}
            style={{
              borderWidth: 1,
              borderColor: "lightgray",
            }}
            dropDownStyle={{ backgroundColor: "white" }}
            defaultValue={furnishing}
          />
        </View>
      ) : null*/}
    </FormContainer>
  );
}
