import React, { useEffect, useState } from 'react';
import styled from 'styled-components/native';
import getPlatformType from '../../../helpers/getPlatform';
import { DropDownSectionWrapper, DropDownWrapper } from '../../searchFilter/form.style';
import DropDownPicker from '../../../commonComponents/DropDownPicker';
import { AntDesign } from '@expo/vector-icons';
import Modal  from "react-native-modal";

import { SECTOR_LABELS, SECTOR_VALUES } from './sectordata';
import { Button } from '../../openFlow/atoms/button';
import { View } from 'react-native';

const employmentDropdownOptionsObj = {
  employerSector: [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
  ],
  employmentStatus: [
    { value: 'EMPLOYED_FULL_TIME', label: 'Employed Full Time' },
    { value: 'EMPLOYED_PART_TIME', label: 'Employed Part Time' },
    { value: 'RETIRED', label: 'Retired' },
    { value: 'SELF_EMPLOYED', label: 'Self-Employed' },
    { value: 'HOMEMAKER', label: 'Homemaker' },
    { value: 'UNEMPLOYED', label: 'Unemployed' },
  ],
  residentialStatus: [
    { value: 'RENTING ', label: 'Renting' },
    { value: 'OWNER_WITH_MORTGAGE', label: 'Owner With Mortgage' },
    { value: 'OWNER_NO_MORTGAGE', label: 'Owner Without Mortgage' },
  ],
};

const layoutType = getPlatformType();

const Text = styled.Text`
  color: #000;
  font-family: 'Lato';
`;

const Title = styled(Text)`
  font-size: 17px;
  color: rgb(255, 181, 128);
  font-weight: 600;
  font-family: 'Segoe UI';
`;

const LabelText = styled(Title)`
  font-size: 15px;
  color: #000;
  font-weight: 700;
  font-family: 'Lato';
`;

const RowContainer = styled.View`
  display: flex;
  flex-direction: ${(props) =>
    props.platform == 'web' ? 'row' : props.platform == 'tab' ? 'row' : 'column'};
`;
const Row = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const ColumnContainer = styled.View`
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: flex-end;
`;

const InputContainerBig = styled.TextInput`
  width: ${(props) =>
    props.platform == 'web' ? '250px' : props.platform == 'tab' ? 'fit-content' : '100%'};
  height: 40px;
  border-radius: 6px;
  border: 1px solid #d0d0d0;
  margin-top: 10px;
  padding-left: 20px;
  position: relative;
`;

const ErrorText = styled.Text`
  color: red;
  font-family: 'Lato';
  font-size: 12px;
  position: absolute;
  bottom: 2px;
`;

const EmploymentInformation = (data) => {
  const [modal, setModal] = useState({ visible: false, text: '' });
  let EMP_SECTOR_ARRAY = SECTOR_VALUES.map((e, i) => {
    return { value: SECTOR_VALUES[i], label: SECTOR_LABELS[i] };
  });
  const onChangeTextHandler = (fieldName, value, isEmpty) => {
    console.log('value', value);
    data.dataSetter(fieldName, value);
    if (value.length > 0) {
      data.checkEmptyStatus(isEmpty, false);
    } else {
      data.checkEmptyStatus(isEmpty, true);
    }
  };

  return (
    <>
      <RowContainer platform={layoutType}>
        <ColumnContainer>
          <LabelText>Job Title</LabelText>
          <Row>
            <InputContainerBig
            id={"ZOPA-EMP-TITLE"}
              onChangeText={(text) => onChangeTextHandler('jobTitle', text, 'jobTitleIsEmmpty')}
              value={data.data.jobTitle}
              platform={layoutType}
            />
            <AntDesign
              onPress={() =>
                setModal({
                  visible: true,
                  text: 'Please enter your job title. If you are unsure you can ask your employer.',
                  heading: 'Job Title'
                })
              }
              style={{ marginTop: 10, marginLeft: 10 }}
              name="infocirlceo"
              size={24}
              color="black"
            />
          </Row>
          {data.employmentInfoEmptyStatus.jobTitleIsEmmpty && (
            <ErrorText>This field is required</ErrorText>
          )}
        </ColumnContainer>
      </RowContainer>
      <RowContainer platform={layoutType}>
        <ColumnContainer style={{marginRight: 30}}>
          <LabelText>Employer Name</LabelText>
          <InputContainerBig
            id={"ZOPA-EMP-NAME"}
            onChangeText={(text) =>
              onChangeTextHandler('employerName', text, 'employerNameIsEmmpty')
            }
            value={data.data.employerName}
            platform={layoutType}
          />
          {data.employmentInfoEmptyStatus.employerNameIsEmmpty && (
            <ErrorText>This field is required</ErrorText>
          )}
        </ColumnContainer>
        <ColumnContainer style={{ justifyContent: 'flex-start' }}>
          <LabelText>Employer Sector</LabelText>
          <DropDownWrapper>
            <DropDownSectionWrapper style={{ flexGrow: 2, width: '274px' }}>
              <DropDownPicker
               id={"ZOPA-EMP-SECTOR"}
                items={EMP_SECTOR_ARRAY}
                defaultValue={data.data.employerSector}
                onChangeItem={(e) => data.dataSetter('employerSector', e.value)}
              />
            </DropDownSectionWrapper>
          </DropDownWrapper>
        </ColumnContainer>
      </RowContainer>
      <RowContainer platform={layoutType}>
        <ColumnContainer>
          <LabelText>Current Rent</LabelText>
          <Row>
          <InputContainerBig
           id={"ZOPA-EMP-CURRRENT"}
            onChangeText={(text) =>
              onChangeTextHandler(
                'currentRent',
                text.replace(/[^0-9+]/g, ''),
                'currentRentIsEmmpty'
              )
            }
            value={data.data.currentRent}
            platform={layoutType}
          />
           <AntDesign
              onPress={() =>
                setModal({
                  visible: true,
                  text: 'Please enter your expected current rent.',
                  heading: 'Current Rent'
                })
              }
              style={{ marginTop: 10, marginLeft: 10 }}
              name="infocirlceo"
              size={24}
              color="black"
            />
          </Row>
          {data.employmentInfoEmptyStatus.currentRentIsEmmpty && (
            <ErrorText>This field is required</ErrorText>
          )}
        </ColumnContainer>
        <ColumnContainer style={{ justifyContent: 'flex-start' }}>
          <LabelText>Employment Status</LabelText>
          <InputContainerBig 
            id={"ZOPA-EMP-STATUS"}
          platform={layoutType} style={{ display: 'none' }} />
          <DropDownWrapper>
            <DropDownSectionWrapper style={{ flexGrow: 2, width: '274px' }}>
              <DropDownPicker
                items={employmentDropdownOptionsObj.employmentStatus}
                defaultValue={data.data.employmentStatus}
                onChangeItem={(e) => data.dataSetter('employmentStatus', e.value)}
              />
            </DropDownSectionWrapper>
          </DropDownWrapper>
        </ColumnContainer>
      </RowContainer>
      <RowContainer platform={layoutType}>
        <ColumnContainer>
          <LabelText>Annual Income</LabelText>
          <Row>
            <InputContainerBig
             id={"ZOPA-EMP-ANNUALINC"}
              onChangeText={(text) =>
                onChangeTextHandler(
                  'annualIncome',
                  text.replace(/[^0-9+]/g, ''),
                  'annualIncomeIsEmmpty'
                )
              }
              value={data.data.annualIncome}
              platform={layoutType}
            />
            <AntDesign
              onPress={() =>
                setModal({
                  visible: true,
                  text: 'Please enter your annual income before tax.',
                  heading: 'Annual Income'
                })
              }
              style={{ marginTop: 10, marginLeft: 10 }}
              name="infocirlceo"
              size={24}
              color="black"
            />
          </Row>
          {data.employmentInfoEmptyStatus.annualIncomeIsEmmpty && (
            <ErrorText>This field is required</ErrorText>
          )}
        </ColumnContainer>
        <ColumnContainer style={{ justifyContent: 'flex-start' }}>
          <LabelText>Residential Status</LabelText>

          <DropDownWrapper>
            <Row>
              <DropDownSectionWrapper style={{ flexGrow: 2, width: '250px' }}>
                <DropDownPicker
                 id={"ZOPA-EMP-RESSTATUS"}
                  items={employmentDropdownOptionsObj.residentialStatus}
                  defaultValue={data.data.residentialStatus}
                  onChangeItem={(e) => data.dataSetter('residentialStatus', e.value)}
                />
              </DropDownSectionWrapper>
              <AntDesign
                onPress={() =>
                  setModal({
                    visible: true,
                    text: 'Select from the drop down list your Residential status.',
                    heading: 'Residential Status'
                  })
                }
                style={{ marginTop: 10, marginLeft: 10 }}
                name="infocirlceo"
                size={24}
                color="black"
              />
            </Row>
          </DropDownWrapper>
        </ColumnContainer>
      </RowContainer>
      {data?.ButtonView()}
      <Modal
        isVisible={modal.visible}
        transparent={true}
        coverScreen={false}
        hasBackdrop={false}
        backdropOpacity={0}
        onBackdropPress={() =>
          setModal({
            visible: false,
            text: '',
            heading:'',
          })
        }
        style={{
          margin: 0,
          width: '100%',
          flex: 1,
          backgroundColor: '#00000080',
          paddingVertical: layoutType == 'phone' ? '5%' : '1%',
          paddingHorizontal: layoutType == 'phone' ? '8%' : '3%',
        }}
      >
        <View
          style={{
            width: layoutType == 'phone' ? '365px' : '600px',
            backgroundColor: '#FAFAFA',
            borderRadius: 20,
            overflow: 'hidden',
            alignSelf: 'center',
            padding: layoutType == 'phone' ? '5%' : '2%',
          }}
        >
          <Text style={{ fontSize: 40, fontWeight: 'bold', color: '#FFB580', alignSelf: 'center' }}>
            {modal?.heading}
          </Text>
          <Text style={{ fontSize: 18, marginTop: 30, alignSelf: 'center' }}>
            {modal?.text ? modal?.text : ''}
          </Text>

          <View
            style={{
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
              marginTop: 60,
            }}
          >
            <Button
              onClick={() => {
                setModal({
                  visible: false,
                  text: '',
                });
              }}
              type={'stripe'}
              color={'#FC4850'}
              title={'Close'}
            />
          </View>
        </View>
      </Modal>
    </>
  );
};

export default EmploymentInformation;
