import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  shawow:{
    boxShadow: "0px 0px 25px #DADADA"
  },
  container: {
  justifyContent:"center",
  alignItems:"center",
  flexDirection:"row",
  paddingHorizontal:"10%",
  paddingVertical:190
  },
  formWrapper:{
    flex: 0.42,
    marginTop: 60,
    paddingLeft:35
  },
  imageContainer:{
    paddingHorizontal: '10%',
    paddingVertical:"6%"
  },
  textContainer:{
    paddingHorizontal: '16%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    paddingVertical:"6%"
  },
  searchSection: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            height: 1276,
            paddingTop: 125,
            backgroundColor:"#F9B383"
          },
          wrapper:{
marginBottom:130
          },
          videoWrapper:{
            alignSelf:"center",
            width: "60%",
            height: 422,
            borderWidth:0.5,
            borderColor: "#000000",
            borderRadius: 9,
            padding:9,
            marginLeft:100,
            backgroundColor: "transparent"
          },
});
