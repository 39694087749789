import React, { Component } from "react";
import { View, Text, ImageBackground } from "react-native";
import {
  FormContainer,
  ListSection,
  TitleWrapper,
  DropDownWrapper,
  DropDownSectionWrapper,
  ButtomSection,
} from "./rentalLandingScreen.style";
import CustomTextInput from "./../../commonComponents/customTextInput/customTextInput";
import CustomDropdown from "../../commonComponents/customDropdown/customDropdown";
import WideButton from "./../../commonComponents/wideButton";
import BoxSelector from "../../commonComponents/boxSelector/boxSelector";
import CheckBox from "../../commonComponents/checkbox/checkbox";
import getPlatformType from "../../helpers/getPlatform";
// import DropDownPicker from "./../../commonComponents/hiteshDropdown";
import DropDownPicker from "../../commonComponents/DropDownPicker";

import { FilterConfigObject } from "./../../helpers/objectHelpers";
import GooglePlacesSuggestion from "../../commonComponents/googlePlaces/sugg";
import { navigateAction } from "../../helpers/navigationHelpers";
import { SERVER_URL } from "../../helpers/connectionHelpers";
import { filterMaxArray } from "../../helpers/functionHelpers";
// import GooglePlacesComponent from "./../../commonComponents/googlePlaces/GooglePlacesComponent";
const layoutType = getPlatformType();
const {
  distances,
  prices_rental,
  min_price_rental,
  max_price_rental,
  min_price_sale,
  max_price_sale,
  furnishingOptions,
  bedrooms_min,
  bedrooms_max,
  prices_sale,
} = FilterConfigObject;

export default function Form(props) {
  
  const { navigate, onSearch, property_for } = props;
  const [loc, setLoc] = React.useState("Tab1");
  const [minBed, setMinBed] = React.useState("0");
  const [maxBed, setMaxBed] = React.useState("0");
  const [maxPrice, setMaxPrice] = React.useState("0");
  const [minPrice, setMinPrice] = React.useState("0");
  const [furnishing, setFurnishing] = React.useState("");
  const [accomodation_type, setAccod] = React.useState([
    "Detached",
    "Semi detached",
    "Terraced",
    "Flats",
    "Bungalow",
    "Other",
    "Farm/land",
  ]);
  const [city, setCity] = React.useState(null);
  const [lat, setLat] = React.useState("");
  const [lng, setLng] = React.useState("");
  const [locationError, setLocationError] = React.useState("");
  const [selectedRadius, setSelectedRadius] = React.useState("16");
  const [defaultRadius, setDefaultRadius] = React.useState("16");
  const [additionClause, setAdditionClause] = React.useState(false);

  const _getBoxValues = (e) => {
    
    setAccod(e);
  };
  const ApiCall = (key) => {
    const placeid = key;

    fetch(SERVER_URL + "/google_place_id?string=" + placeid)
      .then((response) => response.json())
      .then((responseJson) => {
        

        let data = {
          lat: responseJson.result.geometry.location.lat,
          lon: responseJson.result.geometry.location.lng,
          search: responseJson.result.formatted_address,
          filters: {
            accomodation_type: accomodation_type,
            added: null,
            max_bedroom: maxBed,
            min_bedroom: minBed,
            // bedroom_count: 1,
            distance: 16,
            furnishing: furnishing,
            gardern: null,
            // keywords: [],
            max_price: parseInt(maxPrice),
            min_price: parseInt(minPrice),
            parking: null,
            property_for: property_for,
            retirementHomes: null,
            sharedOwnership: null,
            underOffer: null,
          },
        };
        onSearch(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const getGeo = async () => {
    await ApiCall(city.place_id);
  };
  const _onSearch = async () => {
    if (city != null) {
      setLocationError("");
      let data = {
        lat: city.lat,
        lon: city.lon,
        user_input: city.user_input,
        types: city.types,
        search: city.description,
        filters: {
          accomodation_type: accomodation_type,
          min_bedroom: parseInt(minBed),
          max_bedroom: parseInt(maxBed),
          furnishing: furnishing,
          max_price: parseInt(maxPrice),
          min_price: parseInt(minPrice),
          property_for: property_for,
          distance: parseInt(selectedRadius, 10),
          additionalClauseForsstc: additionClause
        },
      };
      
      await onSearch(data);
    } else {
      setLocationError("You must select the location");
    }
  };
  const boxButtons = [
    {
      value: "All",
      text: "All",
      image: require("../../assets/images/creditScore.png"),
      setAll: true,
      id:"PROPERTY_ALL"
    },
    {
      value: "Detached",
      text: "Detached",
      image: require("../../assets/images/detached.png"),
      id:"PROPERTY_DETATACH"
    },
    {
      value: "Semi detached",
      text: "Semi detached",
      image: require("../../assets/images/semi-detachedNew.png"),
      id:"PROPERTY_SEMI_DETATACH"
    },
    {
      value: "Terraced",
      text: "Terraced",
      image: require("../../assets/images/terraced.png"),
      id:"PROPERTY_TERRRACED"
    },
    {
      value: "Flats",
      text: "Flats",
      image: require("../../assets/images/flats.png"),
      id:"PROPERTY_FLATS"
    },
    {
      value: "Bungalow",
      text: "Bungalow",
      image: require("../../assets/images/bunglow.png"),
      id:"PROPERTY_BUNGLOW"
    },
    {
      value: "Farm/land",
      text: "Farm/land",
      image: require("../../assets/images/farm.png"),
      id:"PROPERTY_FARMS"
    },
    {
      value: "Other",
      text: "Other",
      image: require("../../assets/images/other.png"),
      id:"PROPERTY_OTHERS"
    },
  ];
  const radius = [
    {
      value: "0",
      label: "This area only",
    },
    {
      value: "0.4",
      label: "1/4 mile",
    },
    {
      value: "0.8",
      label: "1/2 mile",
    },
    {
      value: "1.6",
      label: "1 mile",
    },
    {
      value: "8",
      label: "5 mile",
    },
    {
      value: "16",
      label: "10 mile",
    },
    {
      value: "34",
      label: "15 mile",
    },
    {
      value: "32",
      label: "20 mile",
    },
    {
      value: "49",
      label: "30 mile",
    },
    {
      value: "65",
      label: "40 mile",
    },
  ];
  
  return (
    <FormContainer>
      <GooglePlacesSuggestion
        title={"Location"}
        selectedVal={(VAL) => {
          
          setCity(VAL);
        }}
        showError={locationError == "" ? false : true}
        errorMessage={locationError}
      />

      <TitleWrapper style={{ marginTop: 5 }}>Bedrooms</TitleWrapper>

      <DropDownWrapper style={{ zIndex: 100 }}>
        <DropDownSectionWrapper>
          <DropDownPicker
            items={bedrooms_min}
            placeholder="Minimum"
            defaultValue={minBed}
            onChangeItem={(e) => setMinBed(e.value)}
            dropDownStyle={{ backgroundColor: "white" }}
            zIndex={1001}
            id={"PROPERTY_BED_MIN"}
          />
        </DropDownSectionWrapper>
        <DropDownSectionWrapper style={{ marginLeft: 8 }}>
          <DropDownPicker
            items={filterMaxArray(bedrooms_max, minBed)}
            placeholder="Maximum"
            id={"PROPERTY_BED_MAX"}
            onChangeItem={(e) => setMaxBed(e.value)}
            defaultValue={maxBed}
            dropDownStyle={{ backgroundColor: "white" }}
            zIndex={1001}
          />
        </DropDownSectionWrapper>
      </DropDownWrapper>
      <TitleWrapper>Price</TitleWrapper>
      <DropDownWrapper style={{ zIndex: 99 }}>
        <DropDownSectionWrapper>
          <DropDownPicker
            items={property_for == 1 ? min_price_rental : min_price_sale}
            placeholder="Minimum"
            id={"PROPERTY_PRICE_MIN"}
            defaultValue={minPrice}
            zIndex={100}
            onChangeItem={(e) => setMinPrice(e.value)}
            dropDownStyle={{ backgroundColor: "white" }}
          />
        </DropDownSectionWrapper>
        <DropDownSectionWrapper style={{ marginLeft: 8 }}>
          <DropDownPicker
            items={
              property_for == 1
                ? filterMaxArray(max_price_rental, minPrice)
                : filterMaxArray(max_price_sale, minPrice)
            }
            placeholder="Maximum"
            id={"PROPERTY_PRICE_MAX"}
            onChangeItem={(e) => setMaxPrice(e.value)}
            defaultValue={maxPrice}
            dropDownStyle={{ backgroundColor: "white" }}
          />
        </DropDownSectionWrapper>
      </DropDownWrapper>

      <TitleWrapper>Radius</TitleWrapper>
      <DropDownWrapper style={{ zIndex: 100 }}>
        <DropDownSectionWrapper style={{ flex: 1, width: "100%" }}>
          <DropDownPicker
            items={radius}
            onChangeItem={(value) =>
              // this.setState({ selectedRadius: value.value, defaultRadius: value.value })
              setSelectedRadius(value.value)
            }
            placeholder="Select"
            id={"PROPERTY_RADIUS"}
            defaultValue={selectedRadius}
            zIndex={10012}
            dropDownStyle={{ backgroundColor: "white" }}
          />
        </DropDownSectionWrapper>
      </DropDownWrapper>

      <BoxSelector
        boxList={boxButtons}
        onValueChange={(e) => _getBoxValues(e)}
        prefilledValues={accomodation_type}
   
        boxStyle={
          layoutType == "phone"
            ? {
              height: 58,
              width: 71,
            }
            : layoutType == "web"
              ? {
                height: 58,
                width: 99,
              }
              : {
                height: 58,
                width: 81,
              }
        }
        label="Property"
      ></BoxSelector>
      {property_for == 1 ? (
        <TitleWrapper 
        id={"PROPERTY_FURNISHING_LABEL"}
        style={{ marginTop: 0 }}>Furnishings</TitleWrapper>
      ) : null}
      {property_for == 1 ? (
        <View style={{ zIndex: 1000 }}>
          <DropDownPicker
            items={furnishingOptions}
            placeholder="Select All"
            onChangeItem={(e) => setFurnishing(e.value)}
            defaultValue={furnishing}
            id={"PROPERTY_FURNISHING"}
            dropDownStyle={{ backgroundColor: "white" }}
          />
        </View>
      ) : null}
      {property_for == 1 && (
        <View style={{ paddingTop: 5 }}>
          <CheckBox
            fontSize={10}
            id={"PROPERTY_ACCEPT_TERMS_LET"}
            checked={additionClause}
            label='Include "Let Agreed" properties in the search results'
            onPress={() => setAdditionClause(!additionClause)}
          />
        </View>
      )}
      {property_for == 0 && (
        <View style={{ paddingTop: 5 }}>
          <CheckBox
            id={"PROPERTY_ACCEPT_TERMS_RENT"}
            fontSize={10}
            checked={additionClause}
            label='Include "Under offer & SSTC" properties in the search results'
            onPress={() => setAdditionClause(!additionClause)}
          />
        </View>
      )}
      <ButtomSection>
        <WideButton
        gradiant
          width={132}
          childText={"Search"}
          buttonType={"solid"}
          id={"PROPERTY_SEARCH"}
          onPress={() => _onSearch()}
        />
      </ButtomSection>
    </FormContainer>
  );
}
