import React from "react";
import styled from "styled-components/native";
import getPlatformType from "../../helpers/getPlatform";

const layoutType = getPlatformType();
let Container, ThanksText;

if (layoutType == "phone") {
    Container = styled.View`
    width: 100%;
    height:200px;
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    padding: 0 16px;
    border-radius: 6px;
  `;
    ThanksText = styled.Text`
    font-size: 22px;
    font-weight: bold;
    color: #FFB581;
    margin-bottom: 16px;
    text-align:center;
  `;
} else if (layoutType == "web") {
    Container = styled.View`
    width: 35%;
    height:200px;
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    padding: 0 16px;
    border-radius: 6px;
  `;
    ThanksText = styled.Text`
    font-size: 22px;
    font-weight: bold;
    color: #FFB581;
    margin-bottom: 16px;
    text-align:center;
  `;
} else {
    Container = styled.View`
    width: 35%;
    height:200px;
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    padding: 0 16px;
    border-radius: 6px;
  `;
    ThanksText = styled.Text`
    font-size: 22px;
    font-weight: bold;
    color: #FFB581;
    margin-bottom: 16px;
    text-align:center;
  `;
}
export {
    Container, ThanksText
};
