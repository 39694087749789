import React from "react";
import styled from "styled-components/native";
import getPlatformType from "../../helpers/getPlatform";

const layoutType = getPlatformType();
let Container;
let FormContainer, ContactTitle, ContactHead, FormControls, MainContent;

if (layoutType == "phone") {
  Container = styled.View`
  justify-content:center;
   width:90%;
   align-items:center;
   padding-right:16px;
   padding-left:16px;
   margin-left:5%;
  `;
  FormContainer = styled.View`
  justify-content:center;
   width:100%;
   align-items:center;
   flex-direction:column;
  `;
  ContactTitle = styled.Text`
   font-size:22px;
   font-weight:bold;
   text-align:center;
   width: 100%;
   margin-bottom:10px;
   margin-top:20px;
   align-self:center;
   `;
  ContactHead = styled.View`
   width: 100%;
   flex-direction: row;
   margin-bottom:40px;
   margin-top:24px;
   `;
  FormControls = styled.View`
   background: #ffffff;
   margin-bottom: 16px;
   flex-direction: column;
   justify-content: center;
   `;
   MainContent = styled.View`
  
   `;

} else if (layoutType == "web") {
  Container = styled.View`
    justify-content:center;
  flex-direction: row;
   width:935px;
   align-self:center;
   align-items:center;
  `;
  FormContainer = styled.View`
  justify-content:center;
   width:100%;
   align-items:center;
   flex-direction: row;
   `;
  ContactTitle = styled.Text`
   font-size:22px;
   font-weight:bold;
   text-align:left;
   width: 100%;
   margin-bottom:24px;
   `;
  ContactHead = styled.View`
   width: 100%;
   flex-direction: row;
   margin-bottom:32px;
   `;
  FormControls = styled.View`
   background: #ffffff;
   margin-bottom: 16px;
   flex-direction: column;
   justify-content: center;
   `;
   MainContent = styled.View`
    
   `;
} else {
  Container = styled.View`
  justify-content:center;
  flex-direction: row;
  width:90%;
  align-items:center;
  padding-right:120px;
  padding-left:120px; 
  margin-left:5%;
  `;
  FormContainer = styled.View`
  justify-content:center;
  flex-direction: row;
  width:100%;
  align-items:center;
  `;
  ContactTitle = styled.Text`
  font-size:22px;
  font-weight:bold;
  text-align:left;
  width: 100%;
  margin-top:24px;
  margin-bottom:24px;
  `;
  ContactHead = styled.View`
  width: 100%;
  flex-direction: row;
  margin-bottom:32px;
  `;
  FormControls = styled.View`
   background: #ffffff;
   margin-bottom: 16px;
   flex-direction: column;
   justify-content: center;
   `;
   MainContent = styled.View`
   min-height:80vh;
   `;
}
const BottomContainer = styled.View`
  width:100%;
  height:20%;
`;


export {
  Container,
  BottomContainer,
  FormContainer, ContactTitle, ContactHead, FormControls, MainContent
};
