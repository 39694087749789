import { types } from "../../actionTypes";

const statusPopupReducer = (
  state = {
    visible: false,
    type: "error",
    text: "Something went wrong! Please try again",
  },
  action
) => {
  switch (action.type) {
    case types.SHOW_ALERT: {
      return {
        ...state,
        visible: true,
        type: action.alertType ? action.alertType : "error",
        text: action.text,
      };
    }
    case types.HIDE_ALERT: {
      return {
        ...state,
        visible: false,
        type: null,
        text: "",
      };
    }
    case types.SHOW_TOASTER_POPUP: {
      return {
        ...state,
        popupVisible: true,
      };
    }
    case types.HIDE_TOASTER_POPUP: {
      return {
        ...state,
        popupVisible: false,
      };
    }
    default:
      return state;
  }
};

export default statusPopupReducer;
