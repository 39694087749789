import React from "react";
import { Image, Text, TouchableOpacity, View } from "react-native";

import { gapi } from "gapi-script";
import { useDispatch, useSelector } from "react-redux";
import { facebookLogin, googleLogin } from "../../../redux/actions/auth";

import { FACEBOOKAPPID, GOOGELELOGIKEY } from "../../../helpers/connectionHelpers";
import { types } from "../../../redux/actionTypes";
import getPlatformType from "../../../helpers/getPlatform";

const NullComponent = (props) => null;

gapi.load("client:auth2", () => {
  gapi.client.init({
    clientId: "684202173750-alebu8h72bs0938qr9c2o4c14rp9t8oo.apps.googleusercontent.com",
    plugin_name: "chat",
  });
});

const SocialLogin = (props) => {
  const [GoogleLogin, setGoogleLogin] = React.useState(() => NullComponent);
  const dispatch = useDispatch();
  const layoutType = getPlatformType();
  const hasUserSavedCookies = useSelector((state) => state?.cookiesReducer?.userHasSavedCookiesPreferences);

  if (hasUserSavedCookies) {
    // import("react-google-login")
    // .then(({ GoogleLogin: AdBanner }) => {
    //   setGoogleLogin(() => AdBanner);
    // });
  }
  console.log("_onFacebookLogin", props);
  const _onFacebookLogin = (fbResponse) => {
    if (fbResponse?.email && fbResponse?.name) {
      dispatch(
        facebookLogin(
          {
            accessToken: fbResponse?.accessToken,
            id: fbResponse?.id,
            email: fbResponse?.email,
            name: fbResponse?.name,
            registered_from: props?.params?.from,
          },
          props?.navigation,
        ),
      );
    } else {
      dispatch({
        type: types.SHOW_ALERT,
        text: "Your facebook account doesnt have email linked!",
      });
    }
  };

  const _onGoogleLogin = (googleResponse) => {
    if (googleResponse?.accessToken) {
      dispatch(
        googleLogin(
          {
            accessToken: googleResponse?.accessToken,
            id: googleResponse?.profileObj?.googleId,
            email: googleResponse?.profileObj?.email,
            name: googleResponse?.profileObj?.name,
            registered_from: props?.params?.from,
          },
          props.navigation,
        ),
      );
    } else {
      dispatch({
        type: types.SHOW_ALERT,
        text: "Google login failed with an unknown error, please contact support, in the meantime, you can login using email and password. If you've signed up using google, you can reset your password to continue",
      });
    }
  };
  return (
    <View style={{
      flexDirection: layoutType !== "phone" ? "row" : "column",
      alignItems: layoutType !== "phone" ? "flex-end" : "unset",
    }}>
      {/* <View
        style={{
          justifyContent: "center",
          flex: 1,
          alignItems: "center",
          marginTop: 20,
          marginRight: layoutType !== "phone" ? 10 : 0,
        }}
      >
        <FacebookLogin
          appId={FACEBOOKAPPID}
          autoLoad={false}
          fields="name,email,picture"
          render={(renderProps) => (
            <TouchableOpacity
              id={"facebooklogin"}
              onPress={renderProps.onClick}
              disabled={renderProps.disabled}
              style={{
                height: 50,
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168)",
                borderRadius: "10px",
                flexDirection: "row",
                backgroundColor: "#1877F2",
                marginTop: 10,
              }}
            >
              <Image
                source={require("./Facebook.png")}
                style={{
                  marginRight: 14,
                  height: 24,
                  width: 24,
                }}
              />
              <Text style={{ color: "white" }}> Continue with facebook</Text>
            </TouchableOpacity>
          )}
          callback={(responseFacebook) => {
            _onFacebookLogin(responseFacebook);
          }}
        />
      </View> */}
      <View
        style={{
          justifyContent: "center",
          flex: 1,
          alignItems: "center",
          marginTop: 20,
          marginLeft: layoutType !== "phone" ? 10 : 0,
        }}
      >
        <GoogleLogin
          clientId={GOOGELELOGIKEY}
          icon={true}
          id={"googlelogin"}
          render={(renderProps) => (
            <TouchableOpacity
              onPress={renderProps.onClick}
              disabled={renderProps.disabled}
              style={{
                height: 50,
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168)",
                borderRadius: "10px",
                flexDirection: "row",
              }}
            >
              <Image
                source={require("./Google.png")}
                style={{
                  marginRight: 14,
                  height: 24,
                  width: 24,
                }}
              />
              <Text> Continue with Google</Text>
            </TouchableOpacity>
          )}
          onSuccess={(responseGoogle) => {
            _onGoogleLogin(responseGoogle);
          }}
          onFailure={(responseGoogle) => {
            _onGoogleLogin(responseGoogle);
          }}
          // cookiePolicy={"single_host_origin"}
        />
      </View>
    </View>
  );
};
export default SocialLogin;
