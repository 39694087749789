import React, { useState } from "react";

import {
  View,
  Text,
  SafeAreaView,
  Image,
  TouchableOpacity,
  Platform,
  ScrollView,
  Dimensions,
  Button,
} from "react-native";
import {
  Container,
  InnerContainer,
  Heading,
  AddDocBtnBox,
  HeadingText,
  ListBox,
  DocumentBox,
  DocName,
  DocNameText,
  DocShare,
  DocShareText,
  DocRemove,
  DocRemoveText,
  DocDate,
  BackBtnBox,
  DocBoxLeft,
  DocBoxRight,
  DocDateText,
  ButtonFooter,
  MainContent,
} from "./styles";
import CustomPopUp from "./../../commonComponents/customPopUp/customPopUp";

import { AntDesign } from "@expo/vector-icons";
import { Entypo } from "@expo/vector-icons";

import getPlatformType, { FULL_HEIGHT } from "../../helpers/getPlatform";
const layoutType = getPlatformType();
const WIN_HEIGHT = Dimensions.get('window').height;
import Header from "../../commonComponents/headerComponent/headerComponent";
import Footer from "../../commonComponents/footerComponent/customFooter";
import WideButton from "../../commonComponents/wideButton";

import {
  fetchDoc,
  updateSharedStatusOfDoc,
  deleteDoc,
} from "../../redux/actions/auth";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Loader from "./../../commonComponents/activityIndicator/activityIndicator";
import { BASE_URL } from "../../helpers/connectionHelpers";
import { ContainerScrollView } from "../../helpers/appStyles";
import { COLORS } from "../../constants/colorPallete";
import { validateAssetsUrl } from "../../helpers/regex";
import BackButton from "../../commonComponents/BackButton";
import TripleTabs from "../../commonComponents/tripleTabs/tripleTabs";
import MyDocs from "./myDocs";
import RentalDocs from "./rentalDocs";
import RefDocs from "./refDocs";
import BackGroundGenerator from "../LandingPages/common/BackGroundGenerator";
const tabList = [
  {
    label: "General Docs",
    target: <MyDocs />,
    targetfooter: null,
    id:"GENERAL_DOCS"
  },
  {
    label: "Reference Docs",
    targetfooter: null,
    target: <RefDocs />,
    id:"REF_DOCS"
  },
  {
    label: "Rental Docs",
    target: <RentalDocs />,
    targetfooter: null,
    id:"RENTAL_DOCS"
  },
];
class MyDocumentsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTabIndex: 0,
      isSharable: false,
      docs: [],
      popUpIsVisible: false,

    };
  }

  componentDidMount = () => {
    this.getAllDocs();
  };

  static getDerivedStateFromProps(props, state) {
    
    if (
      props.docs != undefined &&
      JSON.stringify(props.docs) != JSON.stringify(state.docs)
    ) {
      return {
        docs: props.docs,
        updateDocStatus: props.updateDocStatus,
      };
    }
  }

  getAllDocs = () => {
    this.props.fetchDoc();
  };

  modifyDate = (date) => {
    return date.substr(0, 10).split("-").join("/");
  };

  updateShareStatus = (data) => {
    
    this.props.updateSharedStatusOfDoc(data.document_id, !data.isShared);
  };

  deleteDoc = (data) => {
    this.props.deleteDoc(data.document_id);
  };

  openDoc = (doc) => {
    if (Platform.OS === "web") {
      if (validateAssetsUrl(doc.filepath)) {
        window.open(doc.filepath);
      } else {
        window.open("http://assets.openbrix.co.uk/" + `${doc.filepath}`);
      }
    }
  };
  render() {
    
    const { loading, navigation } = this.props;

    const { popUpIsVisible, docToRemove } = this.state;

    return (
      <BackGroundGenerator isTLYF props={this.props}>
          <Container>
            <MainContent>
              {layoutType !== "web" ? (
                <BackBtnBox style={{marginLeft:10,marginBottom:20}} onPress={() => navigation.navigate("My Account")}>
                  <Entypo name="chevron-left" size={18} color="#000" />
                  <Text>Back</Text>
                </BackBtnBox>
              ) : null}

              {/* <View style={{ width: '80%', borderColor: COLORS.orange, borderRadius: 5, marginTop: 15, marginBottom: 20, padding: 10, borderWidth: 2, marginLeft: "10%", justifyContent: 'center', alignItems: 'center' }}>
                <Text style={{ width: '90%', textAlign: 'center' }}>
                  Welcome to Document Share Center.
              {"\n\n"}
              Here you have the ability to upload documents such as identification, right to rent documents and other related information that can be securely and safely shared between you and your agent.
              </Text>
              </View> */}
              <TripleTabs
            isFullWidth={false}
            tabList={tabList}
            title=""
            setCurrentTabIndex={(e)=>this.setState({currentTabIndex:e})}
          />
              {/* <InnerContainer style={{ minHeight: WIN_HEIGHT - (335) }}>
                <Heading>
                  <HeadingText>Your Documents</HeadingText>

                  {layoutType !== "phone" ? (
                    <AddDocBtnBox>
                      <WideButton
                        width="100%"
                        gradiant
                        borderRadius={15}
                        childText="Add Document"
                        onPress={() =>
                          this.props.navigation.navigate("Add Documents")
                        }
                      />
                    </AddDocBtnBox>
                  ) : null}
                </Heading>
                <ListBox>
                  {this.state.docs.length ? (
                    <React.Fragment>
                      {this.state.docs.map((doc) => (
                        <DocumentBox>
                          <DocBoxLeft>
                            <DocName>
                              <DocNameText onPress={() => this.openDoc(doc)}>
                                {doc.filename}
                              </DocNameText>
                            </DocName>
                            <DocShare
                              onPress={() => this.updateShareStatus(doc)}
                            >
                              <AntDesign
                                name="eye"
                                size={22}
                                color={doc.isShared ? COLORS.orange : "#bdbdbd"}
                              />
                              <DocShareText
                                style={{
                                  color: doc.isShared ? "#000" : "#bdbdbd",
                                }}
                                onPress={() => this.updateShareStatus(doc)}
                              >
                                {" "}
                                Share
                              </DocShareText>
                            </DocShare>
                          </DocBoxLeft>
                          <DocBoxRight>
                            <DocRemove
                              onPress={() => {
                                this.setState({
                                  popUpIsVisible: true,
                                  docToRemove: doc,
                                });
                              }}
                            >
                              <Entypo name="cross" size={22} color={COLORS.orange} />
                              <DocRemoveText>Remove</DocRemoveText>
                            </DocRemove>
                            <DocDate onPress={() => this.openDoc(doc)}>
                              <DocDateText>
                                {this.modifyDate(doc.createdAt)}
                              </DocDateText>
                              <Entypo
                                name="chevron-right"
                                size={22}
                                color={COLORS.orange}
                              />
                            </DocDate>
                          </DocBoxRight>
                        </DocumentBox>
                      ))}
                    </React.Fragment>
                  ) : null}
                </ListBox>
              </InnerContainer> */}
            </MainContent>
          </Container>
      

        <Loader loading={loading} />
        <CustomPopUp
          loading={popUpIsVisible}
          primaryButtonText={"Sure"}
          secondaryButtonText={"cancel"}
          primaryText={"Remove Doc?"}
          secondaryText={" Are you sure you want to remove Doc?"}
          onPrimaryButtonPress={() => {
            this.setState({ popUpIsVisible: false }, () => {
              this.deleteDoc(docToRemove);
            });
          }}
          onSecondaryButtonPress={() => {
            this.setState({ popUpIsVisible: false });
          }}
        />
      </BackGroundGenerator>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.authReducer.docLoading,
  docs: state.authReducer.docs,
  updateDocStatus: state.authReducer.updateStatus,
  deleteStatus: state.authReducer.deleteStatus,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchDoc,
      updateSharedStatusOfDoc,
      deleteDoc,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(MyDocumentsList);
