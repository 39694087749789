import { types } from "../../actionTypes";

const ladgerReducer = (
  state = {
    ladgerLoading: false,
    ladgerData: null,
    ladgerStatus: false,
    logErrorLoading: false,
    logErrorData: null,
    logErrorStatus: false,
    clhLoading:false,
    clhData:null,
    clhStatus:false
  },
  action
) => {
  switch (action.type) {
    case types.LADGER_REQUEST: {
      return {
        ...state,
        ladgerLoading: true,
      };
    }
    case types.CLHISTORY_REQUEST: {
      return {
        ...state,
        clhLoading: true,
      };
    }
    case types.CLHISTORY_SUCCESS: {
      return {
        ...state,
        clhLoading: false,
        clhData: action.payload.data,
        clhStatus: action.payload.status,
      };
    }
    case types.LADGER_SUCCESS: {
      return {
        ...state,
        ladgerLoading: false,
        ladgerData: action.payload.data,
        ladgerStatus: action.payload.status,
      };
    }
    case types.LADGER_FAILED: {
      return {
        ...state,
        ladgerLoading: false,
        ladgerData: action.payload,
        ladgerStatus: false,
      };
    }
    case types.CLHISTORY_FAILED: {
      return {
        ...state,
        clhLoading: false,
        clhData:  null,
        clhStatus: false ,
      };
    }
    case types.LOG_ERROR_REQUEST: {
      return {
        ...state,
        logErrorLoading: true,
      };
    }
    case types.LOG_ERROR_SUCCESS: {
      return {
        ...state,
        logErrorLoading: false,
        logErrorData: action.payload,
        logErrorStatus: action.payload.status,
      };
    }
    case types.LOG_ERROR_FAILED: {
      return {
        ...state,
        logErrorLoading: false,
        logErrorData: action.payload,
        logErrorStatus: false,
      };
    }
    default:
      return state;
  }
};

export default ladgerReducer;
