import React from "react";
import styled from "styled-components/native";
import getPlatformType from "../../helpers/getPlatform";
const layoutType = getPlatformType();

let  TextContainer = styled.Text`
font-size: 14px;
color: transparent;
font-weight: bold;
  `;
const Front = styled.View`
  background: transparent
`; 


export {
TextContainer,
Front
};
