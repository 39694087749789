import { types } from "../../actionTypes";

const notificationReducer = (
  state = {
    data: [],
    archivedData:[]
  },
  action
) => {
  switch (action.type) {
    case types.ADDNOTIFICATION: {
      return {
        ...state,
        data: action.payload,
      };
    }
    case types.ADDNOTIFICATIONARCHIVED: {
      return {
        ...state,
        archivedData: action.payload,
      };
    }
    
    case types.REMOVE_NOTIFICATION_REQUEST: {
      return {
        ...state,
 Loading:true,
        data: action.payload,
      };
    }    case types.REMOVE_NOTIFICATION_FAILED: {
      return {
        ...state,
      Loading:false,
        data: action.payload,
      };
    }    case types.REMOVE_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        Loading:false,
        data: action.payload,
      };
    }
    default:
      return state;
  }
};

export default notificationReducer;
