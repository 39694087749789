import { View, TextInput } from 'react-native';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { tdsActions } from './tdsRedux/tds.actions';
import { backgroundTdsArr } from './backgroundTdsArr';
import getPlatformType from '../../helpers/getPlatform';
import { navigateAction } from '../../helpers/navigationHelpers';
import DropDownPicker from "../../commonComponents/DropDownPicker";
import BackGroundGenerator from '../LandingPages/common/BackGroundGenerator';
import CustomTextInput from '../../commonComponents/customTextInput/customTextInput';
import { DropDownSectionWrapper, DropDownWrapper } from "../searchFilter/form.style";
import { Container, ButtonWrapper, Typography, BoxShadow, primaryColor, AmountBox, CurrencyBox, InputBox } from './style';

const ConfirmTenancy = (props) => {

  const years = Array.from({length: 45}, (v, k) => {return {'value': k + 1990, 'label': k + 1990}});

  const months = [
    { value: "01", label: "January" },
    { value: "02", label: "February" },
    { value: "03", label: "March" },
    { value: "04", label: "April" },
    { value: "05", label: "May" },
    { value: "06", label: "June" },
    { value: "07", label: "July" },
    { value: "08", label: "August" },
    { value: "09", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];
  
  const dispatch = useDispatch();
  const layoutType = getPlatformType();
  const [state, setState] = useState({});
  const [type, setType] = useState('web');
  const confirmTenancySuccess = useSelector((state) => state.tds?.confirmTenancySuccess);
  const [tenancy, setTenancy] = useState({});
  const tenancyResponse = useSelector((state) => state.tds?.tenancy);

  useEffect(() => {
    dispatch(tdsActions.getTenancy(props.route.params.tenancy_id, props.route.params.dan));
  }, []);

  useEffect(() => {
    if(props.route.params.tenancy_id) {
      dispatch(tdsActions.getTenancy(props.route.params.tenancy_id, props.route.params.dan));
    }
  }, [props.route.params.tenancy_id]);

  useEffect(() => {
    if(tenancyResponse) {
      setTenancy(tenancyResponse.tenancy);
      setState({ line_item_id: tenancyResponse.tenancy.line_item_id });
    }
  }, [tenancyResponse]);

  useEffect(() => {
    if(confirmTenancySuccess) {
      navigateAction(props.navigation, 'Custodial Tenancies');
    }
  }, [confirmTenancySuccess]);

  const handleChange = (value, field) => {
    setState({ ...state, [field]: value });
  }

  const triggerConfirmTenancy = () => {
    dispatch(tdsActions.confirmTenancy( state, props.route.params.tenancy_id ))
  }

  const addressWithoutPostcode = (address) => {
    if(address) {
      let array = address?.split(',')
      array.pop()
      return array.toString()  
    } else {
      return ''
    }
  };

  return (
    <>
      <BackGroundGenerator 
        isBack={true}
        backNavigation={()=> navigateAction(props.navigation, 'Custodial Tenancies') }
        height={'200px'}
        infoIcon
        isTds
        redirectTDSAccount={()=> { navigateAction(props.navigation, 'Custodial Account') }}
        tdsAccountIcon
        props={props}
        layoutType={(data) => { setType(data) }}
        isTLYF
        backgroundArr={backgroundTdsArr}
      >
        <Container>
          <BoxShadow>
            <Typography color={primaryColor} >Confirm Tenancy</Typography>
            <Typography bold={'500'} color={'#000'} top={'30px'} >Please enter the requested information to confirm the tenancy</Typography>
          </BoxShadow>
  
          <BoxShadow style={{marginTop:'30px',marginBottom:'40px'}}>

            <View style={{flexDirection:layoutType=="phone"?'column':'row',width:layoutType=="phone"?'50%':'70%',justifyContent:'space-between'}}>
              <Typography color={primaryColor} bottom={'20px'} align={'left'}>Deposit Account Number</Typography>
              <Typography bold={'500px'} size={'16px'} color={'#000'} bottom={'30px'} align={'left'}>{tenancy.dan}</Typography>
            </View>
            <View style={{flexDirection:layoutType=="phone"?'column':'row',width:layoutType=="phone"?'50%':'70%',justifyContent:'space-between'}}>
              <Typography color={primaryColor} bottom={'20px'} align={'left'}>Address</Typography>
              <Typography bold={'500px'} size={'16px'} color={'#000'} bottom={'30px'} align={'right'}>{addressWithoutPostcode(tenancy.formatted_address)}</Typography>
            </View>
            <View style={{flexDirection:layoutType=="phone"?'column':'row',width:layoutType=="phone"?'50%':'70%',justifyContent:'space-between'}}>
              <Typography color={primaryColor} bottom={'20px'} align={'left'}>Deposit Status</Typography>
              <Typography bold={'500px'} size={'16px'} color={'#000'} bottom={'30px'} align={'left'}>{tenancy.status}</Typography>
            </View>

            <View style={{width:'100%',height:2,backgroundColor:primaryColor,marginVertical:30}}/>

            <View style={{marginTop:'10px',justifyContent:'center',alignItems:'center',width:'100%'}}>
              <View style={{width:layoutType=="phone"?'90%':'50%'}}>
                <CustomTextInput
                    title="Post Code"
                    type="number"
                    name="addresspostcode"
                    prefilledValue={state.addresspostcode}
                    bColor={'#638ACC'}
                    onChangeText={(value) => handleChange(value, 'addresspostcode')}
                  />
              </View>
              <View style={{width:layoutType=="phone"?'90%':'50%'}}>
              <Typography color={'#000'} size={'18px'} top={'20px'} bottom={'20px'} align={'left'}>Deposit amount</Typography>
              <AmountBox>
              <CurrencyBox>
                <Typography align={'left'} color={'#4C664B'} size={'20px'} bold={500}>
                  £
                </Typography>
              </CurrencyBox>
              <InputBox>
                <TextInput
                  style={{ margin: 8, padding: 6, width: '96%', height: '100%', borderWidth: 0 }}
                  placeholder=""
                  keyboardType="numeric"
                  value={state.deposit_amount}
                  onChangeText={(value)=> { handleChange(value, 'deposit_amount') }}
                />
              </InputBox>
            </AmountBox>
            </View>
            <View style={{width:layoutType=="phone"?'90%':'50%'}}>
              <Typography color={'#000'} size={'18px'} top={'20px'} bottom={'10px'} align={'left'}>Start Date</Typography>
              <Typography color={'#000'} bold={'500'} size={'18px'} bottom={'20px'} align={'left'}>Please select the date you think the tenancy started?</Typography>
              <DropDownWrapper>
                <DropDownSectionWrapper style={{ flexGrow: 2, width: layoutType == "phone" ? '100%' : '180px' }}>
                  <DropDownPicker
                    items={months}
                    defaultValue={state.tenancy_start_month}
                    onChangeItem={(e) => handleChange(e.value, 'tenancy_start_month')}
                  />
                </DropDownSectionWrapper>
              </DropDownWrapper>

              <DropDownWrapper>
                <DropDownSectionWrapper style={{ flexGrow: 2, width: layoutType == "phone" ? '100%' : '180px' }}>
                  <DropDownPicker
                    items={years}
                    defaultValue={state.tenancy_start_year}
                    onChangeItem={(e) => handleChange(e.value, 'tenancy_start_year')}
                  />
                </DropDownSectionWrapper>
              </DropDownWrapper>

            </View>

            <ButtonWrapper style={{ width: layoutType=="phone"?'150px':'280px',marginTop:40 }} onPress={() => triggerConfirmTenancy()}>
              <Typography color={'#FFFFFF'} size={'19px'} bold={700}>
                Submit
              </Typography>
            </ButtonWrapper>
        
            </View>
          
          </BoxShadow>
        </Container>
      </BackGroundGenerator>
    </>
  );
};

export default ConfirmTenancy;
