import React from 'react';
import { View } from 'react-native';
import { Svg, Circle, Text as SVGText } from 'react-native-svg';
import { BackWrapper } from './headerComponent/headerStyle';
import { Feather } from '@expo/vector-icons';
import { replaceAction, popAction } from '../helpers/navigationHelpers';
import getPlatformType from '../helpers/getPlatform';
import { COLORS } from '../constants/colorPallete';
import { useSelector } from 'react-redux';

var layoutType = getPlatformType();

const BackButton = (props) => {
  const { onBackAction, replace, navigation, styles,nativeBack,name } = props;
  const isMobile = useSelector((state) => state?.appReducer?.mobileData?.data?.isWebView);
  const getName = (val) => {
    switch (val) {
      case 'Dashboard':
        return 'My Dashboard';
        break;
      case 'PreQualification':
        return 'Rent Ready';
        break;
      default:
        return 'Back';
        break;
    }
  };
  return (
    <BackWrapper
      style={{ padding: 10, ...styles, width: 'fit-content' }}
      id={'BACK-BUTTON'}
      onPress={() => {
        if (isMobile &&nativeBack) {
      
          if (window?.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({ val: replace }));
          }
        }else{
          if (onBackAction) {
            onBackAction();
          } else {
            if (replace) {
              replaceAction(navigation, replace);
            } else {
              popAction(navigation, 1);
            }
          }
        }
      
      }}
    >
      <Feather name="arrow-left" size={18} style={{ marginRight: 10 }} color={COLORS.LGREEN} />
      {name?name: getName(replace)}
    </BackWrapper>
  );
};

export default BackButton;
