import React, { useState } from 'react';
import { StyleSheet, View, Platform } from 'react-native';
import { useTheme } from '@emotion/react';
import getScreenType from '../../../../utils/getScreenType';
import { ODSIcon } from '../../../atoms/ODSIcon';
import { ODSText } from '../../../atoms/ODSText';
import { ODSInput } from '../../../atoms/ODSInput';
import ODSButton from '../../../atoms/ODSButton';

const ReferAFriendCard = ({ onSend,shareComponent,onShare,useremail }) => {
  const { colors } = useTheme();
  const layout = getScreenType();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [showShare,setShowShare]=useState(false)
  const handleSend = () => {

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError('Please enter a valid email address.');
    } else {
      if(email&&useremail&&(email).toLowerCase()===(useremail).toLowerCase()){
        setError(`Please enter your friend's email address.`);
      }else{
        setError('');
        onSend(email);
      }
    }
  };

  return (
    <View style={{ flex: 1, ...styles.shadowBox }}>
      <View style={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'row', marginBottom: 12 }}>  
        <ODSIcon icon={'TB-Heart'} size={24} color={colors.button.main} />
        <ODSText type='h5' marginLeft={12} color={colors.text.primary}>Refer a Friend</ODSText>
      </View>
      <ODSText marginBottom={24} textAlign='center' type='sm' marginTop={12} color={colors.text.primary}>
        Love tlyfe? Why not refer a friend so they can enjoy the benefits of tlyfe?
      </ODSText>
      <View style={{ width: layout === 'web' ? '70%' : '100%', alignSelf: 'center' }}>
        <ODSInput
          label='Email address'
          placeholder='Email'
          value={email}
          onChange={(e) => setEmail(e)}
          hints={error ? [{ state: 'error', hint: error }] : []}
        />
        <ODSButton restStyle={{ marginTop: 24 }} onPress={handleSend}>Send</ODSButton>
        <ODSText textAlign='center' type='sm' marginVertical={24} color={colors.text.primary}>Or share via</ODSText>
        <ODSButton onPress={()=>onShare?onShare():setShowShare(!showShare)} type='secondary' rIcon='TB-Share'>Share</ODSButton>
      </View>
      {showShare && shareComponent && shareComponent()}
    </View>
  );
};

const styles = StyleSheet.create({
  shadowBox: {
    backgroundColor: '#fff',
    marginHorizontal: 20,
    padding: 32,
    borderRadius: 12,
    ...Platform.select({
      ios: {
        shadowColor: '#000',
        shadowOffset: { width: 1, height: 3 },
        shadowOpacity: 0.15,
        shadowRadius: 8,
      },
      android: {
        elevation: 4,
      },
      web: {
        shadowColor: '#000',
        shadowOffset: { width: 1, height: 3 },
        shadowOpacity: 0.15,
        shadowRadius: 8,
      }
    }),
  },
});

export default ReferAFriendCard;
