
export const backgroundSearchArr = [
    {
      webUri:require("./../../landingTlyfe/images/homeleftGreen.png"),
      mobileUri: require("./../../landingTlyfe/images/homeleftMobileGreen.png"),
      webStyle: {
        width: "100%",
        position: 'absolute',
        height:"90vh",
      },
      tabStyle: {
        width: "100%",
        position: 'absolute',
        height:"90vh",
      },
      mobileStyle: {
        width: "100%",
        position: 'absolute',
        height:"90vh",
      },
    },
  ];