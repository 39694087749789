import React, { useEffect } from 'react';

import {
  View,
  Text,
  Dimensions,
  SafeAreaView,
  Image,
  TouchableOpacity,
  Platform,
  ScrollView,
  FlatList,
  TextInput,
  StyleSheet,
} from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';
import { FontAwesome } from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';
import { Entypo } from '@expo/vector-icons';
import Modal  from "react-native-modal";
// import { MainSection, Section1, Section2 } from './preReferencingStyle';

// import  from './Components/skeltonComponents';
import { useSelector, useDispatch } from 'react-redux';

import { ImageBackground } from 'react-native';
import { BoxShadow, IconButton, Title2, TitleBlack, Wrapper, Line } from './styles';
import { removeCcj, getCcqStatus, removeAffordibility } from '../../redux/actions/preReferencing';
import { COLORS, generalColorsTheme } from '../../constants/colorPallete';
import Ccq from '../identifyAndFinance/ccq';
import getPlatformType from '../../helpers/getPlatform';
import Afford from '../identifyAndFinance/afford';
import { CustomButton } from '../identifyAndFinance/stylesNew';

const WIN_HEIGHT = Dimensions.get('window').height;
const PreQualification = (props) => {
  const [Affordability, setAffordablity] = React.useState('');
  const [showModal, setShowModal] = React.useState(false);

  const layoutType = getPlatformType();
  const [accFlag, setAffordFlag] = React.useState(layoutType == 'phone' ? false : true);
  const [cccFlag, setCCCFlag] = React.useState(layoutType == 'phone' ? false : true);

  const dispatch = useDispatch();
  const affordData = useSelector((state) => state?.preReferencing?.ccqData);
  const teanants = useSelector((state) => state?.authReducer?.profile?.data?.appUser);

  useEffect(() => {
    dispatch(getCcqStatus());
  }, []);
  const removeAfford = () => {
    dispatch(removeAffordibility());
  };
  const removeCCJ = () => {
    dispatch(removeCcj());
  };

  const GetBtnType = () => {
    if (affordData?.completion) {
      if (
        (affordData?.completion?.affordability_completed &&
          affordData?.completion?.ccj_completed) ||
        affordData?.completion?.skip_ccj
      ) {
        return 'Completed';
      } else if (
        affordData?.completion?.affordability_completed ||
        affordData?.completion?.ccj_completed
      ) {
        return 'In Progress';
      }
      return 'Not Started';
    } else {
      return 'Not Started';
    }
  };

  return (
    <>
      <Wrapper>
        <BoxShadow bottom={'10px'} right={'19px'}>
          <Title2 color={COLORS.LGREEN} top={'10px'}>
            Pre-qualification
          </Title2>
          {/* <View style={{ width: '100%', alignItems: 'flex-end' }}>
              <CustomButton height={'28px'} width={'110px'} color={'#009688'}>
                <FontAwesome name="check" size={14} style={{ marginRight: 5 }} color="white" />
                <TitleBlack color={'#FFFFFF'}>Completed</TitleBlack>
              </CustomButton>
            </View> */}

          <TitleBlack color={'#000000'} top={'10px'}>
            Welcome to OpenBrix pre-qualification in partnership with FCC Paragon.
          </TitleBlack>
          <TitleBlack color={'#000000'} top={'20px'}>
            With pre-qualification, you can give yourself the best chance of securing your dream
            rental property and put yourself ahead of the competition.
          </TitleBlack>
          <TitleBlack color={'#000000'} top={'20px'}>
            This provides prospective agents:
          </TitleBlack>
          <TitleBlack color={'#000000'} top={'20px'}>
            - Basic information about your profile
          </TitleBlack>
          <TitleBlack color={'#000000'} top={'10px'}>
            - Your self-certified monthly income
          </TitleBlack>
          <ImageBackground
            source={require('./../../assets/images/Image.png')}
            style={{ height: 48, width: 236, marginTop: 20 }}
          />
        </BoxShadow>
        <BoxShadow>
          <View style={{ flexDirection: 'row' }}>
            <Title2 color={COLORS.LGREEN} top={'10px'} size={'13px'}>
              {' '}
              Affordability Amount (£)
            </Title2>
            <Text
            id={"AFF_AMOUNT"}
              style={{
                width: '40%',
                marginTop: 12,
                marginLeft: 16,
                borderBottomWidth: 1,
                borderColor: '#000',
              }}
            >
              {affordData?.affordibilityCheck?.affordbility_amount.toFixed(2)}
            </Text>
          </View>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-around',
              marginTop: 25,
            }}
          >
            <View>
              <TouchableOpacity
                onPress={() => {
                  if (affordData?.buttons?.affodability_text === 'Start') {
                    setShowModal('aff');
                  }
                }}
                id={"PRE_QUALI_ACTION"}
                disabled={affordData?.buttons?.affodability_text == 'Start' ? false : true}
                style={{
                  minHeight: 30,
                  justifyContent: 'center',
                  alignItems: 'center',
                  minWidth: 98,
                  padding: '4%',
                  borderRadius: 25,
                  backgroundColor: affordData?.buttons?.affodability_color?affordData?.buttons?.affodability_color:"#DADADA",
                }}
              >
                <Text style={{ color: 'white', textAlign: 'center' }}>
                  {affordData?.buttons?.affodability_text === 'Start'
                    ? 'Start'
                    : affordData?.buttons?.affodability_text}
                </Text>
              </TouchableOpacity>
            </View>

            <View style={{ flexDirection: 'row',width:"50%",alignItems:'center',justifyContent:'space-around' }}>
              <TouchableOpacity
                  id={"PRE_QUALI_EDIT"}
                onPress={() => {
                  setShowModal('aff');
                }}
                style={{ flexDirection: 'column', alignItems: 'center' }}
              >
                <Feather name="edit" size={18} color={COLORS.LGREEN} />
                <Text style={{}}>Edit</Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => removeAfford()}
                id={"PRE_QUALI_REMOVE"}
                style={{ flexDirection: 'column', alignItems: 'center' }}
              >
                <MaterialIcons name="delete" size={18} color="#FF5A51" />
                <Text style={{}}>Remove</Text>
              </TouchableOpacity>
            </View>
          </View>
          <TitleBlack color={'#000000'} top={'25px'}>
            Affordability criteria are determined by each individual agent.
          </TitleBlack>
          <TitleBlack color={'#000000'} top={'10px'}>
            From the information you have provided, FCC Paragon can provisionally accept that you
            can afford this rental property providing you pass the full reference with the
            information you have provided.
          </TitleBlack>
        </BoxShadow>
      </Wrapper>
      {/* <MainSection>
                    <Section1>
                      <SkeltonComponents
                        type={'singlebutton'}
                        title={'Pre - Qualification'}
                        data={[
                          {
                            textColor: 'white',
                            title: 'Status',
                            button: GetBtnType(),
                            buttonColor:
                              GetBtnType() === 'Completed'
                                ? generalColorsTheme.green
                                : GetBtnType() === 'In Progress'
                                ? generalColorsTheme.yellow
                                : '#686868',
                            onClick: () => {
                              // if (GetBtnType() !== "Completed") {
                              //   navigateAction(props.navigation, "PreReferencingLite");
                              // }
                            },
                          },
                        ]}
                      />
                      <SkeltonComponents
                        type={'empty'}
                        title={''}
                        renderBody={() => {
                          return (
                            <>
                              <Text>
                                Welcome to OpenBrix pre-qualification in partnership with FCC
                                Paragon.
                              </Text>
                              <Text style={{ marginTop: 16 }}>
                                With pre-qualification, you can give yourself the best chance of
                                securing your dream rental property and put yourself ahead of the
                                competition.
                              </Text>
                              <Text style={{ marginTop: 16 }}>Pre-Qualification</Text>
                              <Text>This provides prospective agents:</Text>
                              <Text>- Basic information about your profile</Text>
                              <Text>- Your self-certified monthly income</Text>
                              <Text>- Your government-grade verified ID status</Text>
                              
                            </>
                          );
                        }}
                      />
                    </Section1>
                    <Section2>
                      <SkeltonComponents
                        type={'custom'}
                        title={'Affordability'}
                        // subTitleRow1={affordData?.affordibilityCheck?.createdAt}
                        //subTitleRow2={"22/04"}
                        subTitleRow1={'Affordability amount'}
                        isOpen={accFlag}
                        renderFooterView={() => {
                          return (
                            <View
                              style={{
                                padding: 15,
                                borderTopWidth: 1,
                                borderColor: '#d8d8d8',
                              }}
                            >
                              <Text style={{ textAlign: 'left' }}>
                                Affordability criteria are determined by each individual agent.
                                {'\n\n'}
                              </Text>
                              <Text style={{ textAlign: 'left' }}>
                                From the information you have provided, FCC Paragon can
                                provisionally accept that you can afford this rental property
                                providing you pass the full reference with the information you have
                                provided.
                              </Text>
                            </View>
                          );
                        }}
                        renderActionButtonView={() => {
                          return affordData?.affordibilityCheck ? (
                            
                          ) : (
                            <Text>N/A</Text>
                          );
                        }}
                        renderTopCustomView={() => {
                          return (
                            <React.Fragment>
                              <Text style={{ color: '#FFB580', width: '35%' }}> Amount £</Text>
                              <Text
                                style={{
                                  width: '50%',
                                  margin: 12,
                                  borderBottomWidth: 1,
                                  borderColor: '#000',
                                }}
                              >
                                {affordData?.affordibilityCheck?.affordbility_amount.toFixed(2)}
                              </Text>

                              {layoutType == 'phone' ? (
                                <Entypo
                                  onPress={() => {
                                    setAffordFlag(!accFlag);
                                  }}
                                  name={accFlag ? 'chevron-up' : 'chevron-down'}
                                  size={20}
                                  color="black"
                                />
                              ) : null}
                            </React.Fragment>
                          );
                        }}
                        renderBottomCustomView={() => {
                          return (
                            
                          );
                        }}
                      />
                    </Section2>
                  </MainSection> */}

<Modal
        isVisible={showModal}
        transparent={true}
        coverScreen={false}
        hasBackdrop={false}
        backdropOpacity={0}
        onBackdropPress={() => setShowModal(false)}
        style={{
          margin: 0,
          width: '100%',
          flex: 1,
          backgroundColor: '#00000080',
          paddingVertical: layoutType == 'phone' ? '5%' : '1%',
          paddingHorizontal: layoutType == 'phone' ? '8%' : '3%',
        }}
      >
        <View
          style={{
            width: layoutType == 'phone' ? '365px' : '600px',
            backgroundColor: '#FAFAFA',
            borderRadius: 20,
            overflow: 'hidden',
            alignSelf: 'center',
            padding: layoutType == 'phone' ? '5%' : '2%',
          }}
        >
          {showModal === 'aff' ? (
            <Afford
              showClose={true}
              onCloseClick={() => {
                setShowModal(false);
              }}
            />
          ) : (
            <Ccq
              showClose={true}
              onCloseClick={() => {
                setShowModal(false);
              }}
            />
          )}
        </View>
      </Modal>
    </>
  );
};
const styles = StyleSheet.create({});
export default PreQualification;
