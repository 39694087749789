import styled from "styled-components/native";
import getPlatformType from "../../helpers/getPlatform";

const layoutType = getPlatformType();

let NavTabs,
  NavPillBox,
  NavHeader,
  NavTouch,
  ActiveNavPill,
  ActiveNavPillText,
  NavPill,
  NavPillText,
  TabContentFooter,
  TabContent,
  ShareButtonView,
  ShareOptions;
ShareOptions = styled.TouchableOpacity`
  position: absolute;
  top: 65px;
  right: 100px;
  background-color: #fff;
  border: 1px solid grey;
  width: 200px;
  padding: 10px;
`;
if (layoutType == "web") {
  NavHeader = styled.Text`
    color: #000000;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 27px;
    text-align: center;
    margin-bottom: 10px
  `;
  ShareButtonView = styled.TouchableOpacity`
    height: 30px;
    width: 174px;
    border: 1px solid #ffffff;
    border-radius: 18px;
    background-color: rgba(255, 255, 255, 0.13);
    align-items: center;
    justify-content: center;
  `;
  NavPillText = styled.Text`
    color: #d0d0d0;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 21px;
    text-align: center;
  `;
  ActiveNavPillText = styled.Text`
    color: #000;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 21px;
    text-align: center;
  `;
  NavPill = styled.TouchableOpacity`
    justify-content: center;
    align-items: center;
    width: ${(props) => (props.isFullWidth ? "auto" : "190px")};
    flex: ${(props) => (props.isFullWidth ? "1" : "none")};
    padding-bottom: 16px;
    padding-left: 5px;
    padding-right: 5px;
  `;
  ActiveNavPill = styled.TouchableOpacity`
    background-color: #fff;
    border-color: #fff;
    width: ${(props) => (props.isFullWidth ? "auto" : "190px")};
    flex: ${(props) => (props.isFullWidth ? "1" : "none")};
    padding-bottom: 16px;
    padding-left: 5px;
    padding-right: 5px;
  `;
  NavPillBox = styled.View`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-bottom; 15px;
    height: 80px;
    width: 100%;
  `;
  NavTabs = styled.View`
    padding-left: 0;
    margin-bottom: -18px;
    flex-direction: row;
  `;
  TabContent = styled.View``;
} else if (layoutType == "phone") {
  NavHeader = styled.Text`
    color: #000000;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
  `;

  NavPillText = styled.Text`
    color: #d0d0d0;
    font-size: 11px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
  `;
  ActiveNavPillText = styled.Text`
    color: #000;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
  `;
  NavPill = styled.TouchableOpacity`
    justify-content: center;
    align-items: center;
    width: 33.33%;
    padding-bottom: 16px;
    padding-left: 5px;
    padding-right: 5px;
  `;
  ActiveNavPill = styled.TouchableOpacity`
    background-color: #fff;
    border-color: #fff;
    border-width: 1px;
    justify-content: center;
    align-items: center;
    width: 33.33%;
    padding-bottom: 16px;
    padding-left: 5px;
    padding-right: 5px;
  `;
  NavPillBox = styled.View`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-bottom; 15px;
    height: 80px;
    width: 100%;
  `;
  NavTabs = styled.View`
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: -18px;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  `;
} else {
  NavHeader = styled.Text`
    color: #000000;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
    text-align: center;
  `;
  NavPillText = styled.Text`
    color: #d0d0d0;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
  `;
  ActiveNavPillText = styled.Text`
    color: #000;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
  `;
  NavPill = styled.TouchableOpacity`
    justify-content: center;
    align-items: center;
    width: ${(props) => (props.isFullWidth ? "auto" : "190px")};
    flex: ${(props) => (props.isFullWidth ? "1" : "none")};
    padding-bottom: 16px;
    padding-left: 5px;
    padding-right: 5px;
  `;
  ActiveNavPill = styled.TouchableOpacity`
    background-color: #fff;
    border-color: #fff;
    border-top-right-radius: 20;
    border-width: 1px;
    elevation: 5;
    justify-content: center;
    align-items: center;
    width: ${(props) => (props.isFullWidth ? "auto" : "190px")};
    flex: ${(props) => (props.isFullWidth ? "1" : "none")};
    padding-bottom: 16px;
    padding-left: 10px;
    padding-right: 10px;
  `;
  NavPillBox = styled.View`
    display: flex;
    flex-direction: row;
    justify-content: ${(props) =>
      props.isFullWidth ? "flex-start" : "flex-end"};
    padding-bottom: ${(props) => (props.isFullWidth ? "15px" : 0)};
    height: 80px;
    width: ${(props) => (props.isFullWidth ? "100%" : "56%")};
  `;
  NavTabs = styled.View`
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: -18px;
    align-items: center;
    flex-direction: row;
    justify-content: ${(props) =>
      props.isFullWidth ? "center" : "space-between"};
  `;
}

TabContent = styled.View`
  border-color: #fff;
  border-bottom-left-radius: 20px;
  border-top-right-radius: ${(props) =>
    props.isActiveComponent === 2 ? "0px" : "20px"};
  background-color: #fff;
  elevation: 5;
  justify-content: center;
  align-items: center;
  padding: 0%;
`;

TabContentFooter = styled.View``;

export {
  NavTabs,
  NavPill,
  ActiveNavPill,
  NavPillBox,
  TabContent,
  NavPillText,
  TabContentFooter,
  ActiveNavPillText,
  NavHeader,
  ShareButtonView,
  ShareOptions,
};
