import React from 'react';
import styled from 'styled-components/native';
import getPlatformType from '../../helpers/getPlatform';
import variables from './atoms/variables.json';

const layoutType = getPlatformType();

let MainTitle,
  Title,
  ImageWrapper,
  OpenBrixCard,
  TlyfeWrapper,
  Wrapper,
  BoxWrapper,
  CardWrapper,
  ImageBox,
  TopHeader,
  ProfileForm,
  RentalInfoRow,
  RentalInfoBox,
  CircleList,
  CircleListPill,
  CircleText,
  InfoBoxContainer,
  HeadingMain,
  MainSection,
  Typography;
Typography = styled.Text`
  font-family: ${(props) => (props.raj ? "Raj": "Lato")};
  line-height:${(props) => (props?.line ? props?.line: "normal")};
  font-style: normal;
  font-weight: ${(props) => (props.bold ? props.bold : 800)};
  font-size: ${(props) => (props.size ? props.size : '19px')};
  text-align: ${(props) => (props.align ? props.align : 'center')};
  color: ${(props) => (props.color ? props.color : '#FAFAFA')};
  margin-top: ${(props) => (props.top ? props.top : '0px')};
  margin-left: ${(props) => (props.left ? props.left : '0px')};
  margin-bottom: ${(props) => (props.bottom ? props.bottom : '0px')};
  text-decoration-line: ${(props) => (props.underline ? 'underline' : 'none')}; ;
`;
if (layoutType == 'phone') {
  CircleList = styled.View`
    flex-direction: row;
    width: 100%;
    align-items: center;
  `;
  CircleListPill = styled.View`
    flex-direction: row;
    align-items: center;
    width: 50%;
  `;
  CircleText = styled.Text`
    font-weight: 500;
  `;
  HeadingMain = styled.Text`
    font-size: 70.5px;
    color: ${variables.orange};
  `;

  InfoBoxContainer = styled.View`
    height: auto;
    width: 100%;
  `;
  RentalInfoRow = styled.View`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid grey;
    padding: 15px 30px;
  `;
  MainTitle = styled.Text`
    font-size: 19.5px;
    line-height: 23px;
    color: #e71e82;
    text-align: center;
  `;
  ImageWrapper = styled.View`
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
  `;
  ImageBox = styled.View`
  margin-right:7px
  width:128px;
  margin-top:20px;
  `;
  Title = styled.Text`
    color: #000;
    font-size: 15px;
    line-height: 18px;
  `;
  TlyfeWrapper = styled.View`
    margin-top: 20px;
  `;
  Wrapper = styled.View`
    margin-horizontal: 20px;
    align-items: ${(props) => (props.align ? props.align : 'center')};
  `;
  BoxWrapper = styled.View`
    height: 52.12px;
    width: 323px;
    border-radius: 5px;
    border: 1px solid #D0D0D0;
    margin-top: 10px
    justify-content:center;
    align-items:center;
    `;
  CardWrapper = styled.View`
    width: 100%;
    align-items: center;
  `;
  MainSection = styled.View`
    align-items: flex-start;
    /* margin: 10px; */
    margin-bottom:20px
  `;
  OpenBrixCard = styled.TouchableOpacity`
    height: 60px;
    width: 92%;
    background-color: #ffffff;
    box-shadow: 1px 1px #d0cfd0;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    flex-direction: row;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 15px;
    align-self: center;
  `;
  TopHeader = styled.View`
    width: 100%;
    margin-top: 10px;
  `;
  ProfileForm = styled.View`
    flex-direction: column;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 50px;
  `;
  RentalInfoBox = styled.View`
    background: ${variables.white};
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    overflow: hidden;
    box-shadow: 1px 1px #d0cfd0;
  `;
} else if (layoutType == 'web') {
  HeadingMain = styled.Text`
    font-size: 50.5px;

    color: ${variables.orange};
  `;
  CircleList = styled.View`
    flex-direction: row;
    width: 100%;
    align-items: center;
  `;
  InfoBoxContainer = styled.View`
    width: 850px;
    height: auto;
  `;
  CircleListPill = styled.View`
    flex-direction: row;
    align-items: center;
    width: 50%;
  `;
  CircleText = styled.Text`
    font-weight: 500;
  `;
  RentalInfoBox = styled.View`
    background: ${variables.white};
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    overflow: hidden;
    margin-top: 16px;
  `;
  RentalInfoRow = styled.View`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #efefef;
  `;
  TopHeader = styled.View`
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
    width: 90%;
  `;
  ImageBox = styled.View`
  margin-right:46px
  width:128px;
  margin-top:20px;
  `;
  ImageWrapper = styled.View`
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
  `;
  MainTitle = styled.Text`
    font-size: 19.5px;
    line-height: 23px;
    color: #e71e82;
    text-align: center;
  `;
  Title = styled.Text`
    color: #000;
    font-size: 15px;
    line-height: 18px;
  `;
  TlyfeWrapper = styled.View`
    margin-top: 20px;
  `;
  Wrapper = styled.View`
    margin-horizontal: 20px;
    align-items: ${(props) => (props.align ? props.align : 'center')};
  `;
  BoxWrapper = styled.View`
box-sizing: border-box;
height: 52.12px;
width: 332px;
border-radius: 5px;
border: 1px solid #D0D0D0;
margin-top: 10px
justify-content:center;
align-items:center;
`;
  CardWrapper = styled.View`
    max-width: 100%;
    width: 950px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 10px;
    align-items: center;
  `;
  MainSection = styled.View`
    margin: 50px;
  `;
  OpenBrixCard = styled.TouchableOpacity`
    height: 60px;
    width: 50%;
    background-color: #ffffff;
    box-shadow: 1px 1px #d0cfd0;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    flex-direction: row;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 15px;
    align-self: center;
  `;
  ProfileForm = styled.View`
    flex-direction: row;
    width: 80%;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 100px;
    align-items: center;
  `;
} else {
  HeadingMain = styled.Text`
    font-size: 70.5px;

    color: ${variables.orange};
  `;
  CircleList = styled.View`
    flex-direction: row;
    align-items: center;
    width: 100%;
  `;
  InfoBoxContainer = styled.View`
    width: 350px;
    height: auto;
  `;
  CircleListPill = styled.View`
    flex-direction: row;
    align-items: center;
    width: 50%;
  `;
  CircleText = styled.Text`
    font-weight: 500;
  `;
  RentalInfoBox = styled.View`
    background: ${variables.white};
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    margin-top: 16px;
    overflow: hidden;
  `;
  RentalInfoRow = styled.View`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px;
    border-bottom: 1px solid grey;
  `;
  TopHeader = styled.View`
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
    width: 100%;
  `;
  ProfileForm = styled.View`
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 100px;
    flex-wrap: wrap;
  `;
  ImageBox = styled.View`
  margin-right:15px
  width:128px;
  margin-top:20px;
  `;
  ImageWrapper = styled.View`
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
  `;
  MainTitle = styled.Text`
    font-size: 19.5px;
    line-height: 23px;
    color: #e71e82;
    text-align: center;
  `;
  Title = styled.Text`
    color: #000;
    font-size: 15px;
    line-height: 18px;
  `;
  TlyfeWrapper = styled.View`
    margin-top: 20px;
  `;
  Wrapper = styled.View`
    margin-horizontal: 20px;
    align-items: ${(props) => (props.align ? props.align : 'center')};
  `;
  BoxWrapper = styled.View`
box-sizing: border-box;
height: 52.12px;
width: 332px;
border-radius: 5px;
border: 1px solid #D0D0D0;
margin-top: 10px
justify-content:center;
align-items:center;
`;
  CardWrapper = styled.View`
    width: 98%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
  `;
  MainSection = styled.View`
    margin: 50px;
  `;
  OpenBrixCard = styled.TouchableOpacity`
    height: 60px;
    width: 50%;
    background-color: #ffffff;
    box-shadow: 1px 1px #d0cfd0;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    margin-bottom: 90px;
    flex-direction: row;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 90px;
    align-self: center;
  `;
}

export const MarginTop = styled.View`
  margin-top: ${(props) => props.value || 5}px;
`;

export {
  MainTitle,
  Title,
  TlyfeWrapper,
  Wrapper,
  BoxWrapper,
  OpenBrixCard,
  CardWrapper,
  MainSection,
  ImageWrapper,
  ImageBox,
  TopHeader,
  RentalInfoRow,
  InfoBoxContainer,
  RentalInfoBox,
  ProfileForm,
  CircleList,
  CircleListPill,
  CircleText,
  HeadingMain,
  Typography,
};
