import { types } from "../../actionTypes";
import { SERVER_URL, _post, URL, _get, _put, _delete } from "../../../helpers/connectionHelpers";

export const getArticles = (page, pageSize, sortOrder,sortBy,category,source,year,searchValue, callback) => {
    
    return (dispatch) => {
        dispatch({ type: types.GET_ARTICLES });
        let url = `${SERVER_URL}${URL.getArticles}?offset=${page}&limit=${pageSize}&sortOrder=${sortOrder}&sortBy=${sortBy}&category=${category}&source=${source}&year=${year}&search=${searchValue}`;
        _get(url, {}, {}, (res) => {
            dispatch({ type: types.GET_ARTICLES_SUCCESS, payload: res?.data });
            if(callback) {
                callback(res?.data)
            }
            },
            (err) => {    
                dispatch({ type: types.GET_ARTICLES_FAILED, error: err });
                dispatch({ type: types.SHOW_ALERT, alertType: "error", text: "Something went wrong please try again!" });
            }
        );
    };
};
export const getCategories = (callback) => {
    
    return (dispatch) => {
        let url = `${SERVER_URL}${URL.getCategories}`;
        _get(url, {}, {}, (res) => {
            if(callback) {
                callback(res?.data)
            }
            },
            (err) => {    
                dispatch({ type: types.SHOW_ALERT, alertType: "error", text: "Something went wrong please try again!" });
            }
        );
    };
};
export const getSources = (callback) => {
    
    return (dispatch) => {
        let url = `${SERVER_URL}${URL.getSources}`;
        _get(url, {}, {}, (res) => {
            if(callback) {
                callback(res?.data)
            }
            },
            (err) => {    
                dispatch({ type: types.SHOW_ALERT, alertType: "error", text: "Something went wrong please try again!" });
            }
        );
    };
};

export const getVideos = (page, pageSize, sortOrder,sortBy,category,source,year,searchValue, callback) => {

    return (dispatch) => {
        const payload={
            offset:page,
            limit:pageSize,
            sortOrder:sortOrder,
            sortBy:sortBy,
            category:category,
            source:source,
            year:year,
            search:searchValue
        }
        dispatch({ type: types.GET_VIDEOS });
        let url = `${SERVER_URL}${URL.getVideos}?`;
        _get(url, {}, payload, (res) => {
            dispatch({ type: types.GET_VIDEOS_SUCCESS, payload: res?.data });
            if(callback) {
                callback(res?.data)
            }
            },
            (err) => {    
                dispatch({ type: types.GET_VIDEOS_FAILED, error: err });
                dispatch({ type: types.SHOW_ALERT, alertType: "error", text: "Something went wrong please try again!" });
            }
        );
    };
}

export const getPost = (postId) => {

    return (dispatch) => {
        dispatch({ type: types.GET_POST_BY_ID });
        let url = `${SERVER_URL}/media-hub/${postId}`;
        _get(url, {}, {}, (res) => {
            dispatch({ type: types.GET_POST_BY_ID_SUCCESS, payload: res?.data });
            },
            (err) => {    
                dispatch({ type: types.GET_POST_BY_ID_FAILED, error: err });
                dispatch({ type: types.SHOW_ALERT, alertType: "error", text: "Something went wrong please try again!" });
            }
        );
    };

}
export const postComment = (payload, cb) => {
    return (dispatch) => {
      _post(
        `${SERVER_URL}/media-hub/comment/${payload.info_id}`,
        {}, // all headers here (if)
        JSON.stringify(payload), // body here (if)
        (res) => {
          // function to perform on success
          if(res.status){
            dispatch(getPost(payload?.info_id))
            cb(res)
          }
          else{
            cb(false)
          }
        },
        (err) => {
          // function to perform on failure
          cb(false)
        //   dispatch({ type: types.SEARCHMAP_FAILED, error: err.message });
        }
      );
    };
  };
  export const deleteComment = (payload) => {

    return (dispatch) => {
        let url = `${SERVER_URL}/media-hub/comment/${payload.id}`;
        _delete(url, {}, JSON.stringify({ id: payload.id }), (res) => {
            dispatch({ type: types.SHOW_ALERT, alertType: "success", text: "Comment deleted successfully" });
            dispatch(getPost(payload?.info_id));
            },
            (err) => {    
                // dispatch({ type: types.GET_POST_BY_ID_FAILED, error: err });
                dispatch({ type: types.SHOW_ALERT, alertType: "error", text: "Something went wrong please try again!" });
            }
        );
    };

}
export const likeDislike = (payload,info_id, cb) => {
    return (dispatch) => {
      _post(
        `${SERVER_URL}/media-hub/like-dislike`,
        {}, // all headers here (if)
        JSON.stringify(payload), // body here (if)
        (res) => {
          // function to perform on success
          if(res.status){
            cb(res)
            dispatch(getPost(info_id))
          }
          else{
            cb(false)
          }
        },
        (err) => {
          // function to perform on failure
          cb(false)
        //   dispatch({ type: types.SEARCHMAP_FAILED, error: err.message });
        }
      );
    };
  };


