import { Text } from 'react-native'; 
import React, { useState, useEffect } from 'react'; 
import { useDispatch, useSelector } from 'react-redux'; 

import RepaymentText from './repaymentText'; 
import { tdsActions } from './tdsRedux/tds.actions'; 
import { backgroundTdsArr } from './backgroundTdsArr'; 
import { navigateAction } from '../../helpers/navigationHelpers';
import BackGroundGenerator from '../LandingPages/common/BackGroundGenerator'; 
import { Container, BoxShadow, Typography, LineSaperator, ButtonWrapper } from './style'; 

const viewcustodialRepayment = (props) => {
    const dispatch = useDispatch(); 
    const [type, setType] = useState('web'); 
    const [tenancy, setTenancy] = useState({}); 
    const [tenants, setTenants] = useState([]);
    const [repayment, setRepayment] = useState({}); 
    const repaymentResponse = useSelector((state) => state.tds?.repaymentProposal); 
  
    useEffect(() => {
      dispatch(tdsActions.getDepositRepaymentProposal(props.route.params.case_id, props?.route?.params?.tenancy_id, props?.route?.params?.dan));
    }, []);
   
    useEffect(() => { 
      if (repaymentResponse) { 
        setTenants(repaymentResponse?.tenancy?.tenants)
        setTenancy(repaymentResponse?.tenancy); 
        setRepayment(repaymentResponse?.repayment?.repaymentProposal)
      }
    }, [repaymentResponse]); 
    
    const renderDepositSection = () => { 
      return ( 
        <BoxShadow> 
          <Typography 
             id={"TOTAL_DEPOSIT_HELD"}
          color={'#194592'} size={'23px'} bold={700}> 
            Total Deposit Held 
          </Typography> 
          <Typography bottom={'31px'} top={'27px'} color={'#194592'} size={'38px'} bold={800}> 
            £ {tenancy?.tenancy?.deposit_amount} 
          </Typography> 
          <LineSaperator /> 
          <Typography top={'34px'} color={'#194592'} size={'23px'} bold={700}> 
            Tenancy end date 
          </Typography> 
   
          <Typography top={'19px'} bottom={'27px'} color={'#194592'} size={'20px'} bold={500}> 
            Supplied by Landlord/Agent : {repayment?.tenancy_end_date_landlord ? repayment?.tenancy_end_date_landlord: 'Not supplied' }
          </Typography> 

          <Typography top={'19px'} bottom={'27px'} color={'#194592'} size={'20px'} bold={500}> 
            Supplied by Tenant : {repayment?.tenancy_end_date_tenant ? repayment?.tenancy_end_date_tenant: 'Not supplied'  }
          </Typography> 

         </BoxShadow> 
      ); 
    }; 
   
    const renderPreviewSection = () => { 
      return ( 
        <BoxShadow bottom={'40px'} top={'40px'}> 
            <Typography bottom={'3px'} align={'left'} color={'#194592'} size={'23px'} bold={700}> 
                Repayment to Tenants 
            </Typography> 

            { tenants?.map((tenant, index)=>{
                return (
                    <RepaymentText
                        colHeading1={index == 0 ? 'Tenant Request': null }
                        colHeading={index == 0 ? 'Landlord/Agent Request': null }
                        colValue={tenant.amount_to_tenant_rr_ll}
                        colValue1={tenant.amount_to_tenant_rrt}
                        label={tenant.fullname}
                    />  
                )
            })}
   
            <RepaymentText
                colValue={repayment?.amount_to_tenants_landlord_rrll} 
                colValue1={repayment?.amount_to_tenants_rtt}
                label={'Total'}
                lColor={'#194592'}
            />
  
            <Typography  top={'60px'} bottom={'3px'} align={'left'} color={'#194592'} size={'23px'} bold={700} > 
                Repayment to Landlord 
            </Typography> 
            <Typography align="left" top={'40px'} color={'#000000'} size={'20px'} bold={500}> 
                {tenancy?.property?.membername}
            </Typography> 

            <RepaymentText
                colValue1={repayment?.amount_to_agent_or_landlord_cleaning_rtt}
                colHeading1={'Tenant Request'}
                colHeading={'Landlord/Agent Request'}
                colValue={repayment?.amount_to_agent_cleaning}
                label={'Cleaning'}
            /> 
            <RepaymentText
                colValue1={repayment?.amount_to_agent_or_landlord_damage_rtt}
                colValue={repayment?.amount_to_agent_damage}
                label={'Damage to Property/Contents'}
            /> 
            <RepaymentText
                colValue1={repayment?.amount_to_agent_or_landlord_redecoration_rtt}
                colValue={repayment?.amount_to_agent_redecoration}
                label={'Redecoration'}
            /> 
            <RepaymentText
                colValue1={repayment?.amount_to_agent_or_landlord_gardening_rtt}
                colValue={repayment?.amount_to_agent_gardening}
                label={'Gardening'}
            /> 
            <RepaymentText
                colValue1={repayment?.amount_to_agent_or_landlord_rent_arrears_rtt}
                colValue={repayment?.amount_to_agent_rent_arrears}
                label={'Rent Arrears'}
            /> 
            <RepaymentText
                colValue1={repayment?.amount_to_agent_or_landlord_other_rtt}
                colValue={repayment?.amount_to_agent_other}
                label={'Other or Unknown'}
            /> 
            <RepaymentText
                colValue1={repayment?.amount_to_agent_or_landlord_rtt}
                colValue={repayment?.amount_to_agent_rr}
                label={'Total'}
                lColor={'#194592'}
            /> 

          <ButtonWrapper
          id={"TDS_OK"}
            onPress={()=> {
                navigateAction(props.navigation, 'Custodial Dashboard', { 'tenancy_id': props?.route?.params?.tenancy_id, 'dan': props?.route?.params?.dan })
            }}
            >
              <Text 
               id={"TDS_OK_TEXT"}
              style={{ color: 'white' }}>
                Go Back
              </Text>
            </ButtonWrapper>

        </BoxShadow> 
      ); 
    }; 
   
    return ( 
      <> 
        <BackGroundGenerator 
          isTds 
          props={props} 
          layoutType={(data) => { 
            setType(data); 
          }} 
          isTLYF 
          infoIcon 
          backgroundArr={backgroundTdsArr} 
          isBack={true} 
          backNavigation={() => props?.navigation?.goBack()} 
        > 
          <Container> 
            {renderDepositSection()} 
   
            {renderPreviewSection()} 
          </Container> 
        </BackGroundGenerator> 
      </> 
    );   
};

export default viewcustodialRepayment;
