import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Text, View } from 'react-native';
import { Typography } from '../openFlow/style';
import { COLORS } from '../../constants/colorPallete';
import { BoxShadow, Wrapper } from './styles';
import { LineSaperator } from '../tds/style';
import DropDownPicker from '../../commonComponents/DropDownPicker.web';
import { openFlow } from '../../constants/openFlo';
import CustomTextInput from '../../commonComponents/customTextInput/customTextInput';
import TenantInfoFormComponent from './tenantInforFormComponent';
import { TextInput } from 'react-native';
import { teanantInfo } from '../../helpers/functionHelpers';
import WideButton from '../../commonComponents/wideButton';
import { TouchableOpacity } from 'react-native-gesture-handler';
import getPlatformType from '../../helpers/getPlatform';
import { navigate } from '../../navigation/RootNavigation';
import { Entypo, FontAwesome, AntDesign } from '@expo/vector-icons';
import { tenantActions } from '../openFlow/openFlowRedux/tenant.actions';
import { ODSText } from '../../../openbrix-design-system/ui/atoms/ODSText';
import { getTokens } from '../../../openbrix-design-system/design-tokens/color-tokens';
import { ODSInput } from '../../../openbrix-design-system/ui/atoms/ODSInput';
import ODSCombobox from '../../../openbrix-design-system/ui/atoms/ODSComboBox';
import { ODSTextArea } from '../../../openbrix-design-system/ui/atoms/ODSTextArea';
import ODSBadges from '../../../openbrix-design-system/ui/atoms/ODSBadges';
import ODSButton from '../../../openbrix-design-system/ui/atoms/ODSButton';
import { showToaster } from '../../redux/actions/toast';
const TenantInfoNew = (props) => {
  const themeObj = getTokens('light').colors;
  const layoutType = getPlatformType();
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(1);
  const [stateData, setStateData] = React.useState({
    marital_status: '',
    children: {
      formType: 'Children',
      Label: 'Children',
      value: '',
      level_1_key: 'no_of_children',
      level_1_Label: 'Number of children',
      level_2_key: 'age_of_children',
      level_2_Label: 'Age of Children',
      no_of_children:[]
    },
    additional_occupants: {
      formType: 'additional_occupants',
      Label: 'Additional Occupants',
      value: '',
      level_1_key: 'no_of_occupants',
      level_1_Label: 'Number of additional occupants',
      level_2_key: 'occupation_of_occupants',
      level_2_Label: 'Occupation of additional occupants',
      level_2_Feild: 'TextInput',
      no_of_occupants:[]
    },
    pets: {
      formType: 'pets',
      Label: 'Pets',
      value: '',
      level_1_key: 'no_of_pets',
      level_1_Label: 'Number of pets',
      level_2_key: 'type_of_pets',
      level_2_Label: 'Type of pets',
      level_2_Feild: 'TextInput',
      no_of_pets:[]
    },
    smoker: {
      formType: 'Smoker',
      Label: 'Smoker',
      value: '',
    },
    rented_accomodation: {
      formType: 'rented_accomodation',
      Label: 'Currently in rented accommodation',
      value: '',
    },
    additional_information:"",
    partners_occupation:""
  });
  console.log('stateData', stateData);
  const tenantInfoData = useSelector((state) => state?.tenant?.tenantInfoData);
  useEffect(() => {
    dispatch(tenantActions.getTenantInfo());
  }, []);
  useEffect(() => {
    syncData();
  }, [tenantInfoData]);

  const syncData = () => {
    if (tenantInfoData) {
      console.log('stateData api', tenantInfoData);
      let newState = stateData;
      if (tenantInfoData.additional_information) {
        newState.additional_information = tenantInfoData.additional_information;
      }
      if (tenantInfoData?.occupation) {
        newState.occupation = tenantInfoData?.occupation;
      }
      if (tenantInfoData?.partners_occupation) {
        newState.partners_occupation = tenantInfoData?.partners_occupation;
      }

      if (tenantInfoData?.marital_status) {
        newState.marital_status = tenantInfoData?.marital_status;
      }
      if (tenantInfoData?.smoker) {
        newState.smoker.value = 'Yes';
      } else {
        newState.smoker.value = 'No';
      }
      if (tenantInfoData?.renting) {
        newState.rented_accomodation.value = 'Yes';
      } else {
        newState.rented_accomodation.value = 'No';
      }
      if (tenantInfoData?.additional_occupants?.length) {
        newState.additional_occupants.value = 'Yes';
        newState.additional_occupants.no_of_occupants = tenantInfoData?.additional_occupants;
      } else {
        newState.additional_occupants.value = 'No';
      }
      if (tenantInfoData?.pets?.length) {
        newState.pets.value = 'Yes';
        newState.pets.no_of_pets = tenantInfoData?.pets;
      } else {
        newState.pets.value = 'No';
      }
      if (tenantInfoData?.children?.length) {
        newState.children.value = 'Yes';
        newState.children.no_of_children = tenantInfoData?.children;
      } else {
        newState.children.value = 'No';
      }
      setStateData({ ...newState });
      setPage(2);
    }
  };
  const validateData = (type) => {
    let objVal = stateData[type];
    if (objVal.value == 'Yes') {
      return false
    }else if (objVal.value == 'No') {
      return false
    }else{
      return true
    }
  };

  const renderPage1 = () => {
    return (
      <View style={{ flexDirection: 'column' }}>
        <BoxShadow wWidth={'710px'} bottom={'10px'} right={'19px'}>
          <ODSText style={{ alignSelf: 'center' }} type={'h5'}>
            Tenant Information
          </ODSText>
          <ODSText
            color={themeObj.neutral[700]}
            marginTop={10}
            style={{ alignSelf: 'center' }}
            type={'md'}
          >
            Provide some basic details about yourself (and your family), to let agents and landlords
            know who will be living in their property.
          </ODSText>

          <LineSaperator style={{ marginVertical: 32, opacity: 0.4, backgroundColor: 'black' }} />

          <ODSInput
            placeholder="Occupation"
            label="Occupation *"
            maxLength={20}
            value={stateData?.occupation}
            onChange={(occupation) => {
              console.log('occupation', occupation);
              setStateData({
                ...stateData,
                occupation,
              });
            }}
          />
          <ODSInput
            placeholder="Partners Occupation"
            label="Partners Occupation"
            top={20}
            maxLength={20}
            onChange={(partners_occupation) =>
              setStateData({
                ...stateData,
                partners_occupation,
              })
            }
            value={stateData?.partners_occupation}
          />

          <View
            style={{
              width: '100%',
              marginBottom: 10,
              marginTop: 20,
            }}
          >
            <ODSText type="sm-bold" color={themeObj.text.primary}>
              {' '}
              Marital Status
            </ODSText>
            <DropDownPicker
              items={teanantInfo?.marrital_status.map((salutation) => {
                return {
                  value: salutation,
                  label: salutation,
                };
              })}
              id="marrital_status"
              placeholder="marrital_status"
              onChangeItem={(e) => {
                if(e.value==="Please select one"){
                  setStateData((prevState) => ({
                    ...prevState,
                    marital_status: "",
                  }));
                }else{
                  setStateData((prevState) => ({
                    ...prevState,
                    marital_status: e.value,
                  }));
                }
              }}
              defaultValue={stateData.marital_status}
              dropDownStyle={{ backgroundColor: 'white' }}
              zIndex={1001}
              bColor={'#B2B2B2'}
            />
          </View>

          <LineSaperator style={{ marginVertical: 32, opacity: 0.4, backgroundColor: 'black' }} />
          <TenantInfoFormComponent
            initialValue={stateData.children}
            onSelect={(data) => {
              setStateData((prevState) => ({
                ...prevState,
                children: data,
              }));
            }}
          />
          <TenantInfoFormComponent
            initialValue={stateData.additional_occupants}
            onSelect={(data) => {
              setStateData((prevState) => ({
                ...prevState,
                additional_occupants: data,
              }));
            }}
          />
          <TenantInfoFormComponent
            initialValue={stateData.pets}
            onSelect={(data) => {
              setStateData((prevState) => ({
                ...prevState,
                pets: data,
              }));
            }}
          />
          <TenantInfoFormComponent
            initialValue={stateData.smoker}
            onSelect={(data) => {
              setStateData((prevState) => ({
                ...prevState,
                smoker: data,
              }));
            }}
          />
          <TenantInfoFormComponent
            initialValue={stateData.rented_accomodation}
            onSelect={(data) => {
              setStateData((prevState) => ({
                ...prevState,
                rented_accomodation: data,
              }));
            }}
          />
          <ODSTextArea
            width={'100%'}
            placeholder="Description"
            hints={[
              {
                hint: '  Any additional information you think might support your application',
                state: 'default',
              },
            ]}
            label=" Information to enhance your application"
            onChange={(additional_information) =>
              setStateData({
                ...stateData,
                additional_information,
              })
            }
            id={'additional_information'}
            value={stateData.additional_information}
          />
          <ODSButton
            rIcon={'TB-Share'}
            restStyle={{ width: 300, marginTop: 20, alignSelf: 'center' }}
            type="primary"
            themeColor={themeObj.main.pink}
            disabled={false}
            onPress={() => {
              console.log('stateData', stateData);
              if(!stateData?.occupation){
                dispatch(showToaster('error', 'Please complete all mandatory fields!'));
              }
               else {
                console.log('stateData', stateData);
                let childrenError = validateData('children');
                let addOccError = validateData('additional_occupants');
                let petsError = validateData('pets');
                let smokerError = validateData("smoker");
                let accomodationError = validateData("rented_accomodation");
                if(childrenError ){
                  dispatch(showToaster('error', `Please complete all mandatory fields!`));
                }else if(addOccError){
                  dispatch(showToaster('error', `Please complete all mandatory fields!`));
                }else if(petsError){
                  dispatch(showToaster('error', `Please complete all mandatory fields!`));
                }else if(smokerError){
                  dispatch(showToaster('error', `Please complete all mandatory fields!`));
                }else if(accomodationError){
                  dispatch(showToaster('error', `Please complete all mandatory fields!`));
                }
                else{
                  dispatch(
                    tenantActions.saveTenantInfo(stateData, () => {
                      setPage(2);
                    })
                  );
                }
              }
            }}
          >
            Save Information
          </ODSButton>
          {/* <WideButton
            childText={'Save Information'}
            gradiant
            isLGreen
            
            width={'70%'}
            alignSelf={'flex-end'}
            top={30}
          /> */}
        </BoxShadow>
      </View>
    );
  };
  const renderReadOnlyCard = (props) => {
    const { label, level1, level2, postFisxText, data } = props;
    console.log('stateddata 2', props);
    return (
      <>
        <ODSText color={themeObj.neutral[700]} style={{ alignSelf: 'center' }} type={'button'}>
          {label}: {data?.[level1]?.length ? data?.[level1].length :null}
        </ODSText>

        {data?.[level1]?.length
          ? data?.[level1].map((item, index) => {
            if(item?.[level2]){
              return (
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <Entypo name="dot-single" size={30} color="black" />

                  <ODSText
                    color={themeObj.neutral[700]}
                    marginTop={5}
                    style={{ alignSelf: 'center' }}
                    type={'sm'}
                  >
                    {item?.[level2]} {postFisxText}
                  </ODSText>
                </View>
              );
            }
             
            })
          : null}
      </>
    );
  };
  const renderEdit = () => {
    return (
      <ODSButton
        rIcon={'TB-Edit'}
        restStyle={{ marginBottom: 10, width: 91, height: 40, alignSelf: 'flex-end' }}
        type="secondary"
        // themeColor={themeObj.main.pink}
        disabled={false}
        onPress={() => setPage(1)}
      >
        Edit
      </ODSButton>
    );
  };
  const renderPage2 = () => {
    return (
      <View style={{ flexDirection: 'column' }}>
        <BoxShadow wWidth={'710px'} bottom={'10px'} right={'19px'}>
          {stateData?.occupation ? (
            <ODSBadges restStyle={{ marginBottom: 10, alignSelf: 'flex-end' }} type={'Completed'}>
              Completed
            </ODSBadges>
          ) : (
            <ODSBadges restStyle={{ marginBottom: 10, alignSelf: 'flex-end' }} type={'Not Started'}>
              Not Started
            </ODSBadges>
          )}
          <ODSText style={{ alignSelf: 'center' }} type={'h5'}>
            Tenant Information
          </ODSText>
          <ODSText
            color={themeObj.neutral[700]}
            marginTop={10}
            style={{ alignSelf: 'center' }}
            type={'md'}
          >
            Provide some basic details about yourself (and your family), to let agents and landlords
            know who will be living in their property.
          </ODSText>
          <LineSaperator style={{ marginVertical: 32, opacity: 0.4, backgroundColor: 'black' }} />
          {renderEdit()}

          <ODSText
            color={themeObj.neutral[700]}
            marginTop={10}
            style={{ alignSelf: 'center' }}
            type={'button'}
          >
            Occupation
          </ODSText>
          <ODSText
            color={themeObj.neutral[700]}
            marginTop={5}
            style={{ alignSelf: 'center' }}
            type={'sm'}
          >
            {stateData?.occupation}
          </ODSText>
          <ODSText
            color={themeObj.neutral[700]}
            marginTop={10}
            style={{ alignSelf: 'center' }}
            type={'button'}
          >
            Marital Status
          </ODSText>
          <ODSText
            color={themeObj.neutral[700]}
            marginTop={5}
            style={{ alignSelf: 'center' }}
            type={'sm'}
          >
            {stateData?.marital_status}
          </ODSText>

          <ODSText
            color={themeObj.neutral[700]}
            marginTop={10}
            style={{ alignSelf: 'center' }}
            type={'button'}
          >
            Partners occupation
          </ODSText>
          <ODSText
            color={themeObj.neutral[700]}
            marginTop={5}
            style={{ alignSelf: 'center' }}
            type={'sm'}
          >
            {stateData?.partners_occupation}
          </ODSText>
          <LineSaperator style={{ marginVertical: 32, opacity: 0.4, backgroundColor: 'black' }} />
          {renderReadOnlyCard({
            label: 'Children',
            level1: stateData?.children?.level_1_key,
            level2: stateData?.children?.level_2_key,
            data: stateData?.children,
            postFisxText: 'years old',
          })}
          <LineSaperator style={{ marginVertical: 32, opacity: 0.4, backgroundColor: 'black' }} />
          {renderReadOnlyCard({
            label: 'Additional occupants',
            level1: stateData?.additional_occupants?.level_1_key,
            level2: stateData?.additional_occupants?.level_2_key,
            data: stateData?.additional_occupants,
            postFisxText: '',
          })}
          <LineSaperator style={{ marginVertical: 32, opacity: 0.4, backgroundColor: 'black' }} />
          {renderReadOnlyCard({
            label: 'Pets',
            level1: stateData?.pets?.level_1_key,
            level2: stateData?.pets?.level_2_key,
            data: stateData?.pets,
            postFisxText: '',
          })}
          <LineSaperator style={{ marginVertical: 32, opacity: 0.4, backgroundColor: 'black' }} />

          <ODSText
            color={themeObj.neutral[700]}
            marginTop={10}
            style={{ alignSelf: 'center' }}
            type={'button'}
          >
            Smoker
          </ODSText>
          <ODSText
            color={themeObj.neutral[700]}
            marginTop={5}
            style={{ alignSelf: 'center' }}
            type={'sm'}
          >
            {stateData?.smoker.value}
          </ODSText>
          <ODSText
            color={themeObj.neutral[700]}
            marginTop={10}
            style={{ alignSelf: 'center' }}
            type={'button'}
          >
            Currently in rented accommodation
          </ODSText>
          <ODSText
            color={themeObj.neutral[700]}
            marginTop={5}
            style={{ alignSelf: 'center' }}
            type={'sm'}
          >
            {stateData?.rented_accomodation?.value}
          </ODSText>
          <ODSText
            color={themeObj.neutral[700]}
            marginTop={10}
            style={{ alignSelf: 'center' }}
            type={'button'}
          >
            Information to enhance your application
          </ODSText>
          <ODSText
            color={themeObj.neutral[700]}
            marginTop={5}
            style={{ alignSelf: 'center' }}
            type={'sm'}
          >
            {stateData?.additional_information}
          </ODSText>
          {renderEdit()}
        </BoxShadow>
        <View style={{ flexDirection: 'column', width: '100%', alignItems: 'flex-end' }}>
          <ODSButton
            rIcon={'TB-Share'}
            restStyle={{ width: 300, marginTop: 20, alignSelf: 'center' }}
            type="secondary"
            themeColor={themeObj.main.pink}
            disabled={false}
            onPress={() => navigate('Document Share Center', { activeIndex: 2 })}
          >
            My Share Centre
          </ODSButton>
          <ODSButton
            restStyle={{ width: 300, marginTop: 20, alignSelf: 'center' }}
            type="primary"
            themeColor={themeObj.main.pink}
            disabled={false}
            onPress={() => navigate('PreQualificationRightToRent')}
          >
            Next: Right to Rent
          </ODSButton>
        </View>
      </View>
    );
  };
  return (
    <>
      <Wrapper>{page === 1 ? renderPage1() : renderPage2()}</Wrapper>
    </>
  );
};

export default TenantInfoNew;
