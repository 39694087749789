import React, { useState } from "react";
import {
  Image,
  TouchableOpacity,
  View,
  FlatList,
  
  SafeAreaView,
} from "react-native";
import Svg, {
    LinearGradient,
    Text,
    Defs,
    Stop,
    TSpan,
    Ellipse
  } from 'react-native-svg';
import getPlatformType from "../../helpers/getPlatform";
import { TextContainer, Front } from "./styles";
let layoutType = getPlatformType();
import calculateSize from 'calculate-size'
const GradientText = (props) => {
  const { childText,fontSize,color1,color2,extraWidth } = props;
  const size = calculateSize(childText, {
    fontSize: fontSize+'px'
 })
 console.log("Sizes",size)
  return (
 <Svg style={{marginLeft:layoutType="phone"?15:0}} width={extraWidth?size?.width+extraWidth:size?.width+25} height={size?.height} {...props}>
  <Defs>
    <LinearGradient id="grad" x1="0" y1="0" x2="1" y2="0">
      <Stop offset="0" stopColor={color1?color1:'#FFD080'} stopOpacity="1" />
      <Stop offset="1" stopColor={color2?color2:'blue'} stopOpacity="1" />
    </LinearGradient>
  </Defs>
  <Text
    fill="url(#grad)"
    fontSize={fontSize?fontSize:"16"}
    fontWeight="bold"
    fontFamily="Lato-Bold"
    x="0"
    y={size?.height-10}
  >
   {childText}
  </Text>
 
</Svg>

  );
};

export default GradientText;
