import Constant from "./Constant";
import { authHeader } from "./auth-header";
import * as RootNavigation from "./../../../navigation/RootNavigation";
import { store } from "../../../redux/store/setup";
import { showToaster } from "../../../redux/actions/toast";
import { tdsActions } from "./tds.actions";

function fetchWithTimeout(url, options) {
  const timer = new Promise((resolve) => {
    setTimeout(resolve, 20000, {
      timeout: true,
    });
  });
  return Promise.race([
    fetch(url, options),
    timer
  ]).then(response => {
    if (response.timeout) {
      store.dispatch(showToaster("error", "Something went wrong!"));
    }
    return response?.timeout?null:response;
  }).catch((ERR)=>{
    console.log("error",ERR)
    return ERR

  });
}

function handleResponse(response) {
  console.log("responseresponse tds",response)
 
  return response && response.json().then((text) => {
    const data = text;
    if (!response.ok) {
      console.log("responseresponse tds",response.status)
      if ((response.status === 403)) {
        console.log("responseresponse tds-----",response.status)
store.dispatch(tdsActions.clearTdsToken())
        // auto logout if 401 response returned from api
        RootNavigation.navigate("tdsLocation");
        return response;
      }
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}


export const tdsService = {

  searchDeposit: (payload) => {
    const requestOptions = {
      method: "POST",
      headers: { ...authHeader(), "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    };
    return fetchWithTimeout(`${Constant.API_URL}/search_deposit`, requestOptions).then(handleResponse);
  },

  confirmTenancy: (payload, tenancyId) => {
    const requestOptions = {
      method: "POST",
      headers: { ...authHeader(), "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    };
    return fetchWithTimeout(`${Constant.API_URL}/tds/tenancy/${tenancyId}/confirm`, requestOptions).then(handleResponse);
  },
  checkUserType: (payload,isSds) => {
    const requestOptions = {
      method: "POST",
      headers: { ...authHeader(), "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    };
    return fetchWithTimeout(isSds? `${Constant.API_URL}/tds/sds/search`:`${Constant.API_URL}/tds/sf/search`, requestOptions).then(handleResponse);
  },
  
  
  forgotPassword: (payload) => {
    const requestOptions = {
      method: "POST",
      headers: { ...authHeader(), "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    };
    return fetchWithTimeout(`${Constant.API_URL}/tds/forgot_password`, requestOptions).then(handleResponse);
  },

  updateAccount: (payload) => {
    const requestOptions = {
      method: "POST",
      headers: { ...authHeader(), "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    };
    return fetchWithTimeout(`${Constant.API_URL}/tds/profile`, requestOptions).then(handleResponse);
  },

  getSearchedDeposit: () => {
    const requestOptions = {
      method: "GET",
      headers: { ...authHeader(), "Content-Type": "application/json" },
    };
    return fetchWithTimeout(`${Constant.API_URL}/searched_deposits`, requestOptions).then(handleResponse);
  },

  getTDSToken: () => {
    const requestOptions = {
      method: "GET",
      headers: { ...authHeader(), "Content-Type": "application/json" },
    };
    return fetchWithTimeout(`${Constant.API_URL}/tds/token/`, requestOptions).then(handleResponse);
  },

  loginToTDS: (payload,isScotland) => {
    const requestOptions = {
      method: "POST",
      headers: { ...authHeader(), "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    };
    return fetchWithTimeout(isScotland?   `${Constant.API_URL}/tds/login/sds`:`${Constant.API_URL}/tds/login`, requestOptions).then(handleResponse);
  },
  logoutToTDS: () => {
    const requestOptions = {
      method: "POST",
      headers: { ...authHeader(), "Content-Type": "application/json" },
      body: JSON.stringify({}),
    };
    return fetchWithTimeout(`${Constant.API_URL}/tds/logout/`, requestOptions).then(handleResponse);
  },

  downloadTenancyCertificate: (dpcId) => {
    const requestOptions = {
      method: "POST",
      headers: { ...authHeader(), "Content-Type": "application/json" },
      body: JSON.stringify({}),
    };
    return fetchWithTimeout(`${Constant.API_URL}/tds/certificate/${dpcId}`, requestOptions).then(handleResponse);
  },

  getTDSAccount: () => {
    const requestOptions = {
      method: "GET",
      headers: { ...authHeader(), "Content-Type": "application/json" },
    };
    return fetchWithTimeout(`${Constant.API_URL}/tds/profile/`, requestOptions).then(handleResponse);
  },

  getTenancies: () => {
    const requestOptions = {
      method: "GET",
      headers: { ...authHeader(), "Content-Type": "application/json" },
    };
    return fetchWithTimeout(`${Constant.API_URL}/tds/tenancies/`, requestOptions).then(handleResponse);
  },

  triggerResetPassword: () => {
    const requestOptions = {
      method: "POST",
      headers: { ...authHeader(), "Content-Type": "application/json" },
    };
    return fetchWithTimeout(`${Constant.API_URL}/tds/trigger/reset_password`, requestOptions).then(handleResponse);
  },

  getTenancy: (tenancyId, dan) => {
    const requestOptions = {
      method: "GET",
      headers: { ...authHeader(), "Content-Type": "application/json" },
    };
    return fetchWithTimeout(`${Constant.API_URL}/tds/tenancy/${tenancyId}/${dan}/`, requestOptions).then(handleResponse);
  },

  updateLeadPreferences: (payload) => {
    const requestOptions = {
      method: "POST",
      headers: { ...authHeader(), "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    };
    return fetchWithTimeout(`${Constant.API_URL}/tds/lead_preference/`, requestOptions).then(handleResponse);
  },

  updateMarketingPreferences: (payload) => {
    const requestOptions = {
      method: "POST",
      headers: { ...authHeader(), "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    };
    return fetchWithTimeout(`${Constant.API_URL}/tds/marketing_preference/`, requestOptions).then(handleResponse);
  },

  updateBankAccount: (payload) => {
    const requestOptions = {
      method: "POST",
      headers: { ...authHeader(), "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    };
    return fetchWithTimeout(`${Constant.API_URL}/tds/bank_account`, requestOptions).then(handleResponse);
  },

  updateInternationalBankAccount: (payload) => {
    const requestOptions = {
      method: "POST",
      headers: { ...authHeader(), "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    };
    return fetchWithTimeout(`${Constant.API_URL}/tds/international_bank_account`, requestOptions).then(handleResponse);
  },

  createDepositRepaymentProposal: (payload) => {
    const requestOptions = {
      method: "POST",
      headers: { ...authHeader(), "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    };
    return fetchWithTimeout(`${Constant.API_URL}/tds/repayment_proposal`, requestOptions).then(handleResponse);
  },

  updateDepositRepaymentProposal: (caseId) => {
    const requestOptions = {
      method: "POST",
      headers: { ...authHeader(), "Content-Type": "application/json" },
    };
    return fetchWithTimeout(`${Constant.API_URL}/tds/repayment_proposal/respond/${caseId}`, requestOptions).then(handleResponse);
  },

  getDepositRepaymentProposal: (caseId, tenancyId, dan) => {
    const requestOptions = {
      method: "GET",
      headers: { ...authHeader(), "Content-Type": "application/json" },
    };
    return fetchWithTimeout(`${Constant.API_URL}/tds/repayment_proposal/${caseId}/${tenancyId}/${dan}`, requestOptions).then(handleResponse);
  },
  verifyEmail: (payload,isSFUser) => {
    const requestOptions = {
      method: "POST",
      headers: { ...authHeader(), "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    };
    let url = isSFUser?`${Constant.API_URL}/tds/activation/verify/sf`:`${Constant.API_URL}/tds/activation/verify`
    return fetchWithTimeout(url, requestOptions).then(handleResponse);
  },
  resetPassword: (payload) => {
    const requestOptions = {
      method: "POST",
      headers: { ...authHeader(), "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    };
    return fetchWithTimeout(`${Constant.API_URL}/tds/activation/reset`, requestOptions).then(handleResponse);
  },
  sendActivationCode: (payload,isSFUser) => {
    const requestOptions = {
      method: "POST",
      headers: { ...authHeader(), "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    };
    return fetchWithTimeout(isSFUser?`${Constant.API_URL}/tds/forgot_password`: `${Constant.API_URL}/tds/activation/send`, requestOptions).then(handleResponse);
  },


};
