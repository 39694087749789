import React, { useState } from 'react';
import {
  Text,
  StyleSheet,
  View,
} from 'react-native';
import { ODSIcon } from '../ODSIcon';
import { ODSText } from '../ODSText';
import { iconList } from 'react-icomoon';
import { useTheme } from '@emotion/react';


const ODSBadges = ({ children, restStyle, rIcon, lIcon, type }) => {
  const { colors } = useTheme();
  const getColorsScheme = (type) => {
    if (type == "Action Required" || type == "Expired") {
      return {
        buttonStyle: styles(colors).button,
        color: colors.neutral["white"]
      }
    } else if (type == "Completed") {
      return {
        buttonStyle: styles(colors).secondary,
        color: colors.neutral["black"],
        iconColor: colors.feedback.main,
        iconName: "TB-Check"
      }
    }
    else if (type == "Pending" || type == "In Progress") {
      return {
        buttonStyle: styles(colors).pending,
        color: colors.neutral["black"],
        iconColor: colors.feedback.warning[400],
        iconName: "TB-Rotating"
      }
    }
    else if (type == "Not Started") {
      return {
        buttonStyle: styles(colors).notStarted,
        color: colors.text["primary"],
      }
    }
    else {
      return {
        buttonStyle: styles(colors).button,
        color: colors.neutral["white"],
        iconName: "TB-Rotating"
      }
    }

  }
  return (
    <View
      style={[getColorsScheme(type).buttonStyle, restStyle]}
    >
      {getColorsScheme(type).iconName ? <ODSIcon style={{ marginRight: 10 }} color={getColorsScheme(type).iconColor} icon={getColorsScheme(type).iconName} size={20} /> : null}
      <ODSText marginLeft={6} color={getColorsScheme(type).color} type={'xs'}>
        {children}
      </ODSText>
      {lIcon ? <ODSIcon color={"white"} icon={lIcon} size={20} /> : null}
    </View>
  );
};

const styles = (theme) =>
  StyleSheet.create({
    button: {
      height: 28,
      // paddingVertical: 12,
      paddingHorizontal: 10,
      justifyContent: 'center',
      backgroundColor: theme.feedback.error[700],
      alignItems: 'center',
      borderRadius: 1000,
      shadowColor: '#101828',
      shadowOffset: { width: 0, height: 1 },
      shadowOpacity: 0.1,
      shadowRadius: 4,
      elevation: 2,
      flexDirection: 'row',

    },
    secondary: {
      backgroundColor: '#FFF',
      borderWidth: 1,
      borderColor: theme.feedback.main,
      borderRadius: 40,
      paddingVertical: 6,
      paddingHorizontal: 16,
      alignItems: 'center',
      flexDirection: 'row',
    },
    pending: {
      backgroundColor: '#FFF',
      borderWidth: 1,
      borderColor: theme.feedback.warning[400],
      borderRadius: 40,
      paddingVertical: 6,
      paddingHorizontal: 16,
      alignItems: 'center',
      flexDirection: 'row',
    },
    notStarted: {
      backgroundColor: '#FFF',
      borderWidth: 1,
      borderColor: theme.neutral[300],
      borderRadius: 40,
      paddingVertical: 6,
      paddingHorizontal: 16,
      alignItems: 'center',
      flexDirection: 'row',
    },
    text: {
      color: '#FFFFFF',
    },
  });

export default ODSBadges;
