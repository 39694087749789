import React, { useState } from 'react';
import { View, SafeAreaView, Platform, ScrollView, Linking, Image, Text } from 'react-native';
import {
  Container,
  DualView,
  SectionText,
  TextDataBoxLeft,
  TextDataBox,
  CenterBlock,
  CenterBlockBox,
  DualViewTextBox,
  SectionHeadText,
  CharityLastBlock,
  VideoBox,
} from './styles';

import { Video } from 'expo-av';

import getPlatformType from '../../helpers/getPlatform';
const layoutType = getPlatformType();

import Header from '../../commonComponents/headerComponent/headerComponent';
import Footer from '../../commonComponents/footerComponent/customFooter';
import WideButton from '../../commonComponents/wideButton';
import BackGroundGenerator from '../LandingPages/common/BackGroundGenerator';
import { backgroundSearchArrLogin } from '../LandingPages/images/tlyfe/searchArrayTlyfeLogin';
import { backgroundSearchArr } from '../LandingPages/images/openFlo/searchArrayOpenflo';

const Charity = (props) => {
  const _redirectLink = () => {
    let url = 'https://www.justgiving.com/campaign/openbrix-shines-uk-partnership?invite=true';
    Linking.canOpenURL(url).then((supported) => {
      if (supported) {
        if (Platform.OS === 'web') {
          window.open(url);
        } else {
          Linking.openURL(url);
        }
      } else {
      }
    });
  };
  let arr = JSON.parse(JSON.stringify(backgroundSearchArr));
  return (
    <BackGroundGenerator props={props} isLanding>
      <View
        style={{
          paddingTop: layoutType == 'phone' ? 100 : 100,
          paddingBottom: 50,
        }}
      >
        <Container>
          <DualView>
            <DualViewTextBox>
              <Image
                id={'OBX_IMAGE'}
                source={require('../../assets/images/obxLogoBig.png')}
                resizeMode="contain"
                style={{ height: 26, width: 172 }}
              />
              <SectionHeadText>
                Openbrix has partnered with charity Shines UK to help make a difference!
              </SectionHeadText>
            </DualViewTextBox>
            <View style={{ marginRight: layoutType == 'phone' ? 0 : '10%' }}>
              <Image
                source={require('../../assets/images/Shines_logo.png')}
                resizeMode="contain"
                style={{
                  height: layoutType == 'phone' ? 250 : 180,
                  width: layoutType == 'phone' ? 250 : 180,
                }}
              />
            </View>
          </DualView>
          <DualView>
            <TextDataBoxLeft>
              <SectionText>
                OpenBrix is excited to partner up with Shines UK to support their work in Masindi
                District, Uganda. The ambition is to lift the people of this area out of poverty,
                sustainably, by supporting education, training, healthcare and micro-businesses.
              </SectionText>
              <SectionText>
                This is a charity that is very close to our CEO, Adam Pigott’s heart who has visited
                Uganda several times to see first-hand how money is being spent to change lives.
              </SectionText>
              <SectionText>
                Please see the below link to our JustGiving page to make a donation today along with
                a quick video highlighting the difference that your money can make!
              </SectionText>
            </TextDataBoxLeft>
            <TextDataBox style={{ padding: 0 }}>
              <Image
                source={require('../../assets/images/charity.png')}
                resizeMode="contain"
                style={{
                  height: layoutType == 'phone' ? 350 : 450,
                  width: layoutType == 'phone' ? '100%' : 600,
                }}
              />
            </TextDataBox>
          </DualView>
        </Container>
        {/*<CenterBlock>
            <Container>
              <CenterBlockBox>
                <Image
                    source={require("../../assets/images/Just_giving_logo.png")}
                    resizeMode="contain"
                    style={{height: 82, width: 344}}
                />
                <SectionHeadText>
                  Make a donation today and save lives!
                </SectionHeadText>
                <SectionText style={{textAlign: "center"}}>
                  We have teamed up with JustGiving to set up a donations page
                  to help making a difference safely and easily for everyone!
                </SectionText>
                <SectionText style={{marginBottom: 30}}>
                  <Text
                      onClick={_redirectLink}
                      style={{color: "#E71E82", cursor: "pointer"}}
                  >
                    Click here
                  </Text>{" "}
                  to make a donation, remember, every penny helps!
                </SectionText>
                <WideButton
                    width={"48%"}
                    childText="Donate Now"
                    onPress={_redirectLink}
                />
              </CenterBlockBox>
            </Container>
          </CenterBlock>*/}
        <View>
          <Container>
            <CharityLastBlock>
              <SectionHeadText style={{ width: layoutType == 'phone' ? '80%' : 'auto' }}>
                Impact of Shines UK Children’s Supporters
              </SectionHeadText>
              <SectionText style={{ width: layoutType == 'phone' ? '80%' : 'auto' }}>
                Here is a quick snippet of how your money can help to change lives!
              </SectionText>
              <VideoBox>
                <Video
                  source={{
                    uri: 'https://assets.openbrix.co.uk/shine.mp4',
                  }}
                  progressUpdateIntervalMillis={500}
                  positionMillis={0}
                  videoStyle={Platform.OS === 'web' ? { position: 'relative' } : undefined}
                  shouldPlay={false}
                  rate={1.0}
                  shouldCorrectPitch={false}
                  volume={1.0}
                  isMuted={false}
                  isLooping={false}
                  resizeMode={Video.RESIZE_MODE_COVER}
                  useNativeControls={true}
                  style={{
                    flex: 1,
                    backgroundColor: 'black',
                    justifyContent: 'center',
                  }}
                />
              </VideoBox>
            </CharityLastBlock>
          </Container>
        </View>
      </View>
    </BackGroundGenerator>
  );
};

export default Charity;
