import React from "react";
import styled from "styled-components/native";
import getPlatformType from "../../helpers/getPlatform";

const layoutType = getPlatformType();
let Container;
let CenterPanal;
let ListView;

if (layoutType == "phone") {
  ListView = styled.View`
    width: 343px;
    height: 266px;
    background-color: white;
    margin: 5px 0px 5px 0px;
    border-bottom-left-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: 1px 1px #d0cfd0;
  `;
  Container = styled.View`
    width: 320px;
    height: 266px;
    margin: 5px 0px 10px 0px;
    border-bottom-left-radius: 20px;
    border-top-right-radius: 20px;
  `;
} else if (layoutType == "web") {
  ListView = styled.View`
    width: 364px;
    height: 266px;
    background-color: white;
    margin: 10px 10px 10px 10px;
    border-bottom-left-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: 1px 1px #d0cfd0;
  `;
  Container = styled.View`
    width: 364px;
    height: 266px;
    margin: 10px 10px 10px 10px;
  `;
} else {
  ListView = styled.View`
    width: 304px;
    height: 266px;
    background-color: white;
    margin: 15px 10px 10px 15px;
    border-bottom-left-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: 1px 1px #d0cfd0;
  `;
  Container = styled.View`
    width: 304px;
    height: 266px;
    margin: 15px 10px 10px 15px;
  `;
}
const LeftPanal = styled.View`
  width: 60px;
  background-color: green;
  margin-right: 6px;
`;
const RightPanal = styled.View`
  width: 459px;
  background-color: transparent;
  margin-left: 6px;
`;
const Section1 = styled.View`
  width: 100%;
  height: 55%;
`;
const Section2 = styled.View`
  width: 100%;
  height: 45%;
`;

export {
  CenterPanal,
  LeftPanal,
  RightPanal,
  Container,
  ListView,
  Section1,
  Section2,
};
