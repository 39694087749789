import { types } from "../../actionTypes";
import {
  SERVER_URL,
  _post,
  _get,
  _delete,
} from "../../../helpers/connectionHelpers";

export const addNotification = (isArchived) => {
  return (dispatch) => {
    let url = `${SERVER_URL}/notification`;
    if(isArchived){
      url = `${SERVER_URL}/notification/archived`;
    }
    _get(
      url,
      {},
      {},
      (res) => {
        if(isArchived){
          dispatch({ type: types.ADDNOTIFICATIONARCHIVED, payload: res?.data });
        }else
        dispatch({ type: types.ADDNOTIFICATION, payload: res?.data });
      },
      (err) => {}
    );
  };
};

export const onNotificationOpen = () => {
  return (dispatch) => {
    let url = `${SERVER_URL}/notification/mark_as_read`;
    _post(
      url,
      {},
      JSON.stringify({}),
      (res) => {
        dispatch(addNotification());
      },
      (err) => {}
    );
  };
};

export const onNotificationRemove = (id,isArchioved) => {
  return (dispatch) => {
    dispatch({ type: types.REMOVE_NOTIFICATION_REQUEST });
    let url = `${SERVER_URL}/notification/${id}`;
    _delete(
      url,
      {},
      JSON.stringify({}),
      (res) => {
        if(res.status){
            dispatch(addNotification(isArchioved));
          dispatch({ type: types.REMOVE_NOTIFICATION_SUCCESS });
          dispatch({
            type: types.SHOW_ALERT,
            alertType: "success",
            text: "Notification removed successfully!",
          });
        }else{
          dispatch({
            type: types.SHOW_ALERT,
            alertType: "error",
            text: "Something went wrong!",
          });
        }
  
      },
      (err) => {
        dispatch({ type: types.REMOVE_NOTIFICATION_FAILED });
        dispatch({
          type: types.SHOW_ALERT,
          alertType: "error",
          text: "Something went wrong!",
        });
      }
    );
  };
};
