import React, { Component } from "react";

import {
  BoxButton,
  BoxView,
  BoxText,
  BoxWrapper,
  BoxTextLabel,
  TitleView,
  ImageWrapper,
  IntroImg,
  TitleText,
} from "./IntroScreenCard.style";
import { color } from "react-native-reanimated";
import { Text, View,Image } from "react-native";

import getPlatformType from "../../helpers/getPlatform";
import WideButton from "../../commonComponents/wideButton";
const layoutType = getPlatformType();

export default class IntroScreenCard extends Component {
  render() {
    const { label, boxStyle ,showButton,dataArray,title,headerTitle} = this.props;

    return (
      <BoxWrapper>

{headerTitle&&<Text
          style={{
            color: "#FFB580",
            fontSize: 36,
            fontWeight: "bold",
            textAlign: "center",
            marginTop: 20,
          }}
        >
      {headerTitle}
        </Text>}
        <TitleText>
     { title}
        </TitleText>
        <Text
          style={{
            color: "#FFB580",
            fontSize: 36,
            fontWeight: "bold",
            textAlign: "center",
            marginBottom: 20,
          }}
        >
        Key Benefits
        </Text>

        <BoxView>
          {dataArray.map((list, index) => {
            return (
              <BoxButton key={`bbn${index}`}>
                {list.image && (
                  <IntroImg 
                  source={list.image}
                  >
                    <View style={{flex:1,alignItems: "center" ,justifyContent:"center" }}>
                      <Image
                      style={{height:"40%",width:"40%"}}
                      source={list.chileimage} resizeMode="contain" />
                      </View>
                    </IntroImg>

                )}
                <Text
                  style={{
                    marginTop: 20,
                    marginBottom: 10,
                    fontSize: 15,
                    color: "black",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  {list.title}
                </Text>
                <BoxText>{list.text}</BoxText>
              </BoxButton>
            );
          })}
        </BoxView>

      {showButton&&    <View style={{ width: "100%", alignItems: "center" }}>
            <WideButton
            gradiant={["#FBB1E6","#FFB580"]}
              childText="Signup"
              onPress={() => this.props.navigation.navigate("Register")}
              width={layoutType == "phone" ? "80%" : "40%"}
            />
          </View>}

      </BoxWrapper>
    );
  }
}
