import React, { useState } from "react";

import {
    View,
    Text,
    SafeAreaView,
    Image,
    TouchableOpacity,
    Platform,
    ScrollView,
    Dimensions,
    Button,
  } from "react-native";
import { Entypo } from "@expo/vector-icons";
import WideButton from "../../commonComponents/wideButton";
import getPlatformType from "../../helpers/getPlatform";
import { useNavigation } from "@react-navigation/native";
import { AntDesign } from "@expo/vector-icons";
import CustomPopUp from "./../../commonComponents/customPopUp/customPopUp";

import {
    Container,
    InnerContainer,
    Heading,
    AddDocBtnBox,
    HeadingText,
    ListBox,
    DocumentBox,
    DocName,
    DocNameText,
    DocShare,
    DocShareText,
    DocRemove,
    DocRemoveText,
    DocDate,
    BackBtnBox,
    DocBoxLeft,
    DocBoxRight,
    DocDateText,
    ButtonFooter,
    MainContent,
  } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { validateAssetsUrl } from "../../helpers/regex";
const layoutType = getPlatformType();
import {
    fetchDoc,
    updateSharedStatusOfDoc,
    deleteDoc,
  } from "../../redux/actions/auth";

import { COLORS } from "../../constants/colorPallete";
import { tenantActions } from "../openFlow/openFlowRedux/tenant.actions";
import { openUrlHelpers } from "../../helpers/openDocHelpers";
const RefDocs =(props)=>{
    var navigation =useNavigation();
    var documents=useSelector(state=>state.authReducer?.docs);
    var dispatch=useDispatch();
    const [docToRemove,setRemoveDoc]=useState('');
    const [popUpIsVisible,setShowPopup]=useState(false);
   
    const  modifyDate = (date) => {
        return date.substr(0, 10).split("-").join("/");
      };

   const deleteDocument = (data) => {
        dispatch(tenantActions.deleteRefDocument({document_url:data.document_url}))
        setRemoveDoc(null);
      };
   const openDoc = (doc) => {

    openUrlHelpers(doc?.document_url)
        // if (Platform.OS === "web") {
        //   if (validateAssetsUrl(doc.document_url)) {
        //     window.open(doc.document_url);
        //   } else {
        //     window.open("http://assets.openbrix.co.uk/" + `${doc.document_url}`);
        //   }
        // }
      };
   const updateShareStatus = (data) => {

    dispatch(updateSharedStatusOfDoc(data?.document_id, !data?.isShared))
     };    

    return(
      <View style={{flex:1,alignSelf:'flex-start',padding:20,width:'100%'}}>
          <Text style={{fontSize:16,fontWeight:'bold'}}>Reference Documents</Text>
          <Text style={{fontSize:14,marginVertical:10,width:'100%'}}>{"Here are the documents you have uploaded during your tenant onboarding process."}</Text>
        
                <ListBox>
                  {documents?.data?.referenceDocuments.length ? (
                    <React.Fragment>
                      {documents?.data?.referenceDocuments.map((doc,index) => (
                        <DocumentBox>
                          <DocBoxLeft>
                            <DocName>
                              <DocNameText 
                              id={"REF-FILE-NAME"}
                              onPress={() => openDoc(doc)}>
                                Referencing Doc {(index+1)}
                              </DocNameText>
                            </DocName>
                            <DocShare
                            //   onPress={() => updateShareStatus(doc)}
                            >
                              {/* <AntDesign
                                name="eye"
                                size={22}
                                color={doc.isShared ? COLORS.orange : "#bdbdbd"}
                              />
                              <DocShareText
                                style={{
                                  color: doc.isShared ? "#000" : "#bdbdbd",
                                }}
                              >
                                {" "}
                                Share
                              </DocShareText> */}
                            </DocShare>
                          </DocBoxLeft>
                          <DocBoxRight>
                            <DocRemove
                               id={"REF-REMOVE"}
                              onPress={() => {
                                setRemoveDoc(doc);
                                setShowPopup(true)
                              }}
                            >
                              <Entypo name="cross" size={22} color={COLORS.orange} />
                              <DocRemoveText>Remove</DocRemoveText>
                            </DocRemove>
                            <DocDate 
                               id={"REF-DATE"}
                            onPress={() => openDoc(doc)}>
                              <DocDateText>
                                {modifyDate(doc.createdAt)}
                              </DocDateText>
                              <Entypo
                                name="chevron-right"
                                size={22}
                                color={COLORS.orange}
                              />
                            </DocDate>
                          </DocBoxRight>
                        </DocumentBox>
                      ))}
                    </React.Fragment>
                  ) : null}
                </ListBox>
             
                <CustomPopUp
          loading={popUpIsVisible}
          primaryButtonText={"Sure"}
          secondaryButtonText={"cancel"}
          primaryText={"Remove Doc?"}
          secondaryText={" Are you sure you want to remove Doc?"}
          onPrimaryButtonPress={() => {
              setShowPopup(false)
              deleteDocument(docToRemove);
          }}
          onSecondaryButtonPress={() => {
            setShowPopup(false)
          }}
        />

      </View>  
    )
}
export default RefDocs;