import React, { useState } from 'react';
import { Dimensions, StyleSheet, View, Text, TouchableOpacity } from 'react-native';
import BackButton from '../../commonComponents/BackButton';
import { COLORS } from '../../constants/colorPallete';
import getPlatformType, { getImage } from '../../helpers/getPlatform';
import { ChildrenView } from './styles';
const layoutType = getPlatformType();
import { ImageBackground } from 'react-native';
const WIN_HEIGHT = Dimensions.get('window').height;
import preBack from '../../assets/images/preBackground.png';
import BackGroundGenerator from '../LandingPages/common/BackGroundGenerator';
import RightToRent from './rightToRentNew';
import ODSButton from '../../../openbrix-design-system/ui/atoms/ODSButton';
import { replaceAction } from '../../helpers/navigationHelpers';
const PreQualificationRightToRent = (props) => {
  return (
    <BackGroundGenerator props={props} isTLYF>
    <View style={{ minHeight: WIN_HEIGHT - 300 }}>
      <View style={{ width: '100%' }}>
        <ImageBackground
          source={getImage("rtr.png")}
          style={{ width: '100%', height: 300 }}
          resizeMode={'cover'}
        >
          <ODSButton
            rIcon={'TB-Arrow-Left'}
            restStyle={{ marginTop: 20, marginLeft: 20,width: 200 }}
            type="secondary"
          
            disabled={false}
            onPress={() => replaceAction(props?.navigation, 'PreQualification')}
          >
           Rent Ready
          </ODSButton>
        </ImageBackground>
        <ChildrenView>
          <RightToRent  navigation={props.navigation} />
        </ChildrenView>
      </View>
    </View>
  </BackGroundGenerator>
  );
};
const styles = StyleSheet.create({});
export default PreQualificationRightToRent;
