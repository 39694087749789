import React, { Component } from "react";
import {
  TouchableOpacity,
} from "react-native";
import {
  BubbleTextInput,
  BubbleText,
  BubbleTextView,
  BubbleIcon,
  BubbleLabel,
  BubbleWrapper,
  BubbleSuperText,
} from "./bubbleMultiSelectInput.style";
import { Entypo } from "@expo/vector-icons";

export default class BubbleMultiSelectInput extends Component {
  constructor (props){
    super(props);
    this.state = {
      textValue: "",
      selectedValues: props.selectedValues?props.selectedValues :[],
      prefilledValue: props.prefilledValue?props.prefilledValue :[],
    };
  }
  
  setSelection = () => {
    let value = this.state.textValue;
    let selectedValues = this.state.selectedValues;
    selectedValues =
      selectedValues.indexOf(value) == -1
        ? [...selectedValues, value]
        : selectedValues;
        this.props.onValueChange(selectedValues)
    this.setState({
      selectedValues: selectedValues,
      textValue: "",
    },() =>{
      this.props.onValueChange(this.state.selectedValues)
    }
    );

  };

  setValue = (e) => {
    this.setState({
      textValue: e,
    });
  };

  deleteEntry = (text) => {
    let value = text;
    let selectedValues = this.state.selectedValues;
    selectedValues = selectedValues.filter((item) => item !== value);
    this.props.onValueChange(selectedValues)
    this.setState({
      selectedValues,
    }, ()=>{
      this.props.onValueChange(this.state.selectedValues)
    });
  };
  static getDerivedStateFromProps(props, state) {
    if(props.hasOwnProperty("prefilledValue")){
      if(!props.prefilledValue.length && !props.selectedValues.length && state.selectedValues.length){
        state.selectedValues = [];
        state.prefilledValue = [];
      }
      if(props.selectedValues && !state.selectedValues.length){
        state.selectedValues = props.selectedValues;
          //state.prefilledValue = props.prefilledValue;
      }
      if((props.prefilledValue.toString() == props.selectedValues.toString()) && state.selectedValues.length){
        state.selectedValues = props.selectedValues;
      }
    }
    return state;
    
  }
  render() {

    let bubblesText = this.state.selectedValues;
    return (
      <BubbleWrapper>
        <BubbleLabel>{this.props.title ? this.props.title : ""}</BubbleLabel>
        <BubbleTextInput
          value={this.state.textValue}
          onChangeText={(value) => this.setValue(value)}
          onSubmitEditing={this.setSelection}
          id={this.props?.id}
        ></BubbleTextInput>
        {bubblesText ? (
          <BubbleTextView>
            {bubblesText.map((text, index) => {
              return (
                  <BubbleText key={index}>
                    <BubbleIcon>
                      <TouchableOpacity
                        onPress={() => {
                          this.deleteEntry(text);
                        }}
                      >
                        <Entypo name="cross" size={17} color="#E71E82" />
                      </TouchableOpacity>
                    </BubbleIcon>
                    <BubbleSuperText key={`bubble${index}`}>
                      {text}
                    </BubbleSuperText>
                  </BubbleText>
              );
            })}
          </BubbleTextView>
        ) : null}
      </BubbleWrapper>
    );
  }
}
