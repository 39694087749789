import React, { useState, useEffect } from "react";

import {
  View,
  Text,
  SafeAreaView,
  Image,
  TouchableOpacity,
  Platform,
  ScrollView,
  Button,
  Linking,
  TouchableWithoutFeedback,
} from "react-native";
import { Entypo } from "@expo/vector-icons";
import {
  GreyText,
  DetailsTop,
  DetailsBox,
  PropertyLabelvarient1,
  PropertyLabelvarient2,
  DetailsTopIcons,
  IconBox,
  DetailsWrapper,
  DetailsCenter,
  InnerDesc,
  DetailsBottom,
  StatusBox,
} from "./styles";
import { FormatPrice } from "../../helpers/validation.js";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import idx from "idx";
import { GetCouncilTax, GetSchool } from "./../../redux/actions/property";
// import HTMLView from "react-native-htmlview";
import getPlatformType from "../../helpers/getPlatform";
import { showToaster } from "../../redux/actions/toast";

const layoutType = getPlatformType();
import { Hoverable } from "react-native-web-hooks";

const Tab1 = (props) => {
  const { propertyData } = props;
  const [isHovered, setIsHovered] = useState({
    hovered: true,
    type: null,
  });

  
  const openFloorPlan = () => {
    if (Platform.OS === "web") {
      if (
        propertyData?.floor_plan &&
        propertyData.floor_plan.indexOf(null) == -1
      ) {
        window.open(`${idx(propertyData, (_) => _.floor_plan)}`, "_blank");
      } else {
        props.showToaster(
          "error",
          "Floorplan not provided, please contact agent directly"
        );
      }
    }
  };
  const openLink = async (url) => {
    let u = url;
    if (Platform.OS === "web") {
      window.open(
        u,
        "_blank" // <- This is what makes it open in a new window.
      );
    } else {
      Linking.openURL(u).catch((err) =>
        console.error("An error occurred", err)
      );
    }
  };
  const openEPC = () => {
    if (Platform.OS === "web") {
      
      if (propertyData?.epc && propertyData.epc.indexOf(null) == -1) {
        window.open(`${idx(propertyData, (_) => _.epc)}`, "_blank");
      } else {
        props.showToaster(
          "error",
          "EPC not provided, please contact agent directly"
        );
      }
    }
  };
  const openTenantInfo = () => {
    if (Platform.OS === "web") {
      if (
        propertyData?.branch?.tenant_info &&
        propertyData.branch.tenant_info.indexOf(null) == -1
      ) {
        window.open(
          `${idx(propertyData, (_) => _.branch.tenant_info)}`,
          "_blank"
        );
      } else {
        props.showToaster(
          "error",
          "Tenant Info not provided, please contact agent directly"
        );
      }
    }
  };
  const openPropertyVideo = () => {
    if (Platform.OS === "web") {
      if (propertyData?.video_url) {
        window.open(`${idx(propertyData, (_) => _.video_url)}`, "_blank");
        // "hello".indexOf(null)
      } else {
        props.showToaster(
          "error",
          "Property Video not provided, please contact agent directly"
        );
      }
    }
  };
  const createLogger = (msg, val) => () => {
    
    
    setIsHovered(msg);
  };

  const returnStatusBox = (status) => {
    if (['sstc', 'under offer', 'let agreed'].includes(status)) {
      return <StatusBox>{status}</StatusBox>
    }
  }

  return (
    <DetailsBox>
      <DetailsTopIcons>
        <Hoverable
          onHoverIn={createLogger("Epc")}
          onHoverOut={createLogger(null)}
        >
          <IconBox
          id={"EPC_ICON_BTN"}
            style={{ justifyContent: "center", alignItems: "center" }}
            onPress={(val) => openEPC("epc")}
          >
          <Image
                style={{ width: 45, height: 45 }}
                source={require("../../assets/SVG/epc.svg")}
                resizeMode="contain"
              ></Image>
            {isHovered === "Epc" && (
              <Text
                style={{
                  marginTop: 10,
                  color: "black",
                  fontWeight: "600",
                  textAlign: "center",
                  position: "absolute",
                  top: -10,
                }}
              >
                {isHovered}
              </Text>
            )}
          </IconBox>
        </Hoverable>
        <Hoverable
          onHoverIn={createLogger("Info")}
          onHoverOut={createLogger(null)}
          id={"INFO_ICON_BTN"}
        >
          <IconBox
            style={{ justifyContent: "center", alignItems: "center" }}
            onPress={openTenantInfo}
          >
              <Image
                style={{ width: 45, height: 45 }}
                source={require("../../assets/SVG/info.svg")}
                resizeMode="contain"
              ></Image>
            {isHovered === "Info" && (
              <Text
                style={{
                  marginTop: 10,
                  color: "black",
                  fontWeight: "600",
                  textAlign: "center",
                  position: "absolute",
                  top: -10,
                }}
              >
                {isHovered}
              </Text>
            )}
          </IconBox>
        </Hoverable>
        <Hoverable
          onHoverIn={createLogger("Floor Plan")}
          onHoverOut={createLogger(null)}
          id={"FLOOR_ICON_BTN"}
        >
          <IconBox
            style={{ justifyContent: "center", alignItems: "center" }}
            onPress={openFloorPlan}
          >
         <Image
                style={{ width: 45, height: 45 }}
                source={require("../../assets/SVG/floor_plan.svg")}
                resizeMode="contain"
              ></Image>
            {isHovered === "Floor Plan" && (
              <Text
                style={{
                  marginTop: 10,
                  color: "black",
                  fontWeight: "600",
                  textAlign: "center",
                  position: "absolute",
                  top: -10,
                }}
              >
                {isHovered}
              </Text>
            )}
          </IconBox>
        </Hoverable>
        <Hoverable
          onHoverIn={createLogger("360 Tour")}
          onHoverOut={createLogger(null)}
        >
          <IconBox>
            <TouchableOpacity
              style={{
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
              }}
              id={"360_ICON_BTN"}
              onPress={() => {
                propertyData?.virtualtour_url
                  ? openLink(propertyData.virtualtour_url)
                  : props.showToaster(
                    "error",
                    "Property Video not provided, please contact agent directly"
                  );
              }}
            >
              <Image
                style={{ width: 45, height: 45 }}
                source={require("../../assets/SVG/360_tour.svg")}
                resizeMode="contain"
              ></Image>
              {isHovered === "360 Tour" && (
                <Text
                  style={{
                    marginTop: 10,
                    color: "black",
                    fontWeight: "600",
                    textAlign: "center",
                    position: "absolute",
                    top: -10,
                  }}
                >
                  {isHovered}
                </Text>
              )}
            </TouchableOpacity>
          </IconBox>
        </Hoverable>
        {returnStatusBox(propertyData?.status)}
      </DetailsTopIcons>
      <DetailsTop>
        <View style={{ marginRight: "2%", flex: 0.5 }}>
          <PropertyLabelvarient2 id={"PROPS_ADDRESS"}>
            {/* {idx(propertyData, (_) => _.address_line1)}, */}
            {propertyData?.display_address}
          </PropertyLabelvarient2>

          {propertyData?.property_name && (
            <GreyText id={"PROPS_NAME"}>{propertyData?.property_name}</GreyText>
          )}
        </View>
        <View style={{ flex: 0.5, alignItems: "flex-end" }}>
          <PropertyLabelvarient2>
            {FormatPrice(idx(propertyData, (_) => _.price))}
          </PropertyLabelvarient2>
          <GreyText>
            {moment(idx(propertyData, (_) => _.available_from)).format(
              "DD/MM/YYYY"
            )}
          </GreyText>
        </View>
      </DetailsTop>
      <DetailsWrapper>
        <DetailsCenter>
          <View style={{flex:1,flexDirection:'row',justifyContent:'space-between',marginBottom:15}}>
          <GreyText>Full description</GreyText>
          <View style={{paddingHorizontal:10,borderWidth:1,borderColor:'#b0b0b0',borderRadius:5}}>
          <GreyText>Tenure : {idx(propertyData, (_) => _.tenure)}</GreyText>

          </View>


          </View>

          {/* <InnerDesc>
              <HTMLView
                value={idx(propertyData, (_) => _.property_description)}
                onLinkPress={(url) => Linking.openURL(url)}
              />
            </InnerDesc> */}
        </DetailsCenter>
        <DetailsBottom>
          <GreyText>Key details</GreyText>
          {idx(propertyData, (_) => _.feature1) != "" &&
            idx(propertyData, (_) => _.feature1) != null ? (
              <View style={{ flexDirection: "row" }}>
                <Entypo name="dot-single" size={24} color="#E71E82" />
                <Text>{idx(propertyData, (_) => _.feature1)}</Text>
              </View>
            ) : null}
          {idx(propertyData, (_) => _.feature2) != "" &&
            idx(propertyData, (_) => _.feature2) != null ? (
              <View style={{ flexDirection: "row" }}>
                <Entypo name="dot-single" size={24} color="#E71E82" />
                <Text>{idx(propertyData, (_) => _.feature2)}</Text>
              </View>
            ) : null}
          {idx(propertyData, (_) => _.feature3) != "" &&
            idx(propertyData, (_) => _.feature3) != null ? (
              <View style={{ flexDirection: "row" }}>
                <Entypo name="dot-single" size={24} color="#E71E82" />
                <Text>{idx(propertyData, (_) => _.feature3)}</Text>
              </View>
            ) : null}
          {idx(propertyData, (_) => _.feature4) != "" &&
            idx(propertyData, (_) => _.feature4) != null ? (
              <View style={{ flexDirection: "row" }}>
                <Entypo name="dot-single" size={24} color="#E71E82" />
                <Text>{idx(propertyData, (_) => _.feature4)}</Text>
              </View>
            ) : null}
          {idx(propertyData, (_) => _.feature5) != "" &&
            idx(propertyData, (_) => _.feature5) != null ? (
              <View style={{ flexDirection: "row" }}>
                <Entypo name="dot-single" size={24} color="#E71E82" />
                <Text>{idx(propertyData, (_) => _.feature5)}</Text>
              </View>
            ) : null}
          {idx(propertyData, (_) => _.feature6) != "" &&
            idx(propertyData, (_) => _.feature6) != null ? (
              <View style={{ flexDirection: "row" }}>
                <Entypo name="dot-single" size={24} color="#E71E82" />
                <Text>{idx(propertyData, (_) => _.feature6)}</Text>
              </View>
            ) : null}
          {idx(propertyData, (_) => _.feature7) != "" &&
            idx(propertyData, (_) => _.feature7) != null ? (
              <View style={{ flexDirection: "row" }}>
                <Entypo name="dot-single" size={24} color="#E71E82" />
                <Text>{idx(propertyData, (_) => _.feature7)}</Text>
              </View>
            ) : null}
          {idx(propertyData, (_) => _.feature8) != "" &&
            idx(propertyData, (_) => _.feature8) != null ? (
              <View style={{ flexDirection: "row" }}>
                <Entypo name="dot-single" size={24} color="#E71E82" />
                <Text>{idx(propertyData, (_) => _.feature8)}</Text>
              </View>
            ) : null}
          {idx(propertyData, (_) => _.feature9) != "" &&
            idx(propertyData, (_) => _.feature9) != null ? (
              <View style={{ flexDirection: "row" }}>
                <Entypo name="dot-single" size={24} color="#E71E82" />
                <Text>{idx(propertyData, (_) => _.feature9)}</Text>
              </View>
            ) : null}
          {idx(propertyData, (_) => _.feature10) != "" &&
            idx(propertyData, (_) => _.feature10) != null ? (
              <View style={{ flexDirection: "row" }}>
                <Entypo name="dot-single" size={24} color="#E71E82" />
                <Text>{idx(propertyData, (_) => _.feature10)}</Text>
              </View>
            ) : null}
        </DetailsBottom>
      </DetailsWrapper>
    </DetailsBox>
  );
};

const mapStateToProps = (state) => ({
  propertyReducer: state.propertyReducer,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetCouncilTax,
      GetSchool,
      showToaster,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(Tab1);
