import React, { useState } from 'react';
import { StyleSheet, View, Platform, TouchableOpacity } from 'react-native';
import { useTheme } from '@emotion/react';
import { ODSIcon } from '../../../atoms/ODSIcon';
import { ODSText } from '../../../atoms/ODSText';
import { ODSInput } from '../../../atoms/ODSInput';
import ODSButton from '../../../atoms/ODSButton';

const ChangePasswordCard = ({ onClose, onChangePassword }) => {
  const { colors } = useTheme();
  const [secureText1, setSecureText1] = useState(true);
  const [secureText2, setSecureText2] = useState(true);
  const [secureText3, setSecureText3] = useState(true);

  const [form, setForm] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let valid = true;
    let tempErrors = {};

    if (!form.currentPassword) {
      tempErrors.currentPassword = 'Current password is required.';
      valid = false;
    }

    if (form.newPassword.length < 6) {
      tempErrors.newPassword = 'New password must be at least 6 characters long.';
      valid = false;
    }

    if (form.newPassword !== form.confirmPassword) {
      tempErrors.confirmPassword = 'New password and confirm password do not match.';
      valid = false;
    }

    setErrors(tempErrors);
    return valid;
  };

  const handleChangePassword = () => {
    if (validateForm()) {
      onChangePassword(form);
      // onClose();
    }
  };

  return (
    <View style={{ flex: 1 }}>
      <View style={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'row', marginBottom: 24 }}>
        <ODSIcon icon={'TB-Lock'} size={24} color={colors.button.main} />
        <ODSText type='h5' marginLeft={12} color={colors.text.primary}>Change Password</ODSText>
      </View>
      <ODSInput
        label='Current Password'
        value={form.currentPassword}
        onChange={(value) => setForm({ ...form, currentPassword: value })}
        hints={errors.currentPassword ? [{ state: 'error', hint: errors.currentPassword }] : []}
        secureTextEntry={secureText1}
        renderRightIcon={
          <TouchableOpacity
            onPress={() => {
              setSecureText1(!secureText1);
            }}>
            <ODSIcon icon={secureText1 ? 'eye-blocked' : 'eye'} size={20} />
          </TouchableOpacity>
        }
      />
      <ODSInput
        label='New Password'
        value={form.newPassword}
        onChange={(value) => setForm({ ...form, newPassword: value })}
        hints={errors.newPassword ? [{ state: 'error', hint: errors.newPassword }] : []}
        secureTextEntry={secureText2}
        renderRightIcon={
          <TouchableOpacity
            onPress={() => {
              setSecureText2(!secureText2);
            }}>
            <ODSIcon icon={secureText2 ? 'eye-blocked' : 'eye'} size={20} />
          </TouchableOpacity>
        }
      />
      <ODSInput
        label='Confirm New Password'
        value={form.confirmPassword}
        onChange={(value) => setForm({ ...form, confirmPassword: value })}
        hints={errors.confirmPassword ? [{ state: 'error', hint: errors.confirmPassword }] : []}
        secureTextEntry={secureText3}
        renderRightIcon={
          <TouchableOpacity
            onPress={() => {
              setSecureText3(!secureText3);
            }}>
            <ODSIcon icon={secureText3 ? 'eye-blocked' : 'eye'} size={20} />
          </TouchableOpacity>
        }
      />
      <ODSButton restStyle={{ marginTop: 32 }} onPress={handleChangePassword}>
        Change Password
      </ODSButton>
      <ODSButton onPress={() => onClose()} restStyle={{ marginTop: 24 }} type='secondary'>
        Close
      </ODSButton>
    </View>
  );
};

const styles = StyleSheet.create({
  shadowBox: {
    backgroundColor: '#fff',
    marginHorizontal: 20,
    padding: 32,
    borderRadius: 12,
    ...Platform.select({
      ios: {
        shadowColor: '#000',
        shadowOffset: { width: 1, height: 3 },
        shadowOpacity: 0.15,
        shadowRadius: 8,
      },
      android: {
        elevation: 4,
      },
      web: {
        shadowColor: '#000',
        shadowOffset: { width: 1, height: 3 },
        shadowOpacity: 0.15,
        shadowRadius: 8,
      }
    }),
  },
});

export default ChangePasswordCard;
