import React from "react";
import { Dimensions, Image, SafeAreaView, Text, TouchableOpacity, View } from "react-native";
import { CardContainer, ClickHereImage, IntroText, MainContainer } from "./styles";
import getPlatformType from "../../helpers/getPlatform";
import Header from "../../commonComponents/headerComponent/headerComponent";
import Footer from "../../commonComponents/footerComponent/customFooter";
import { CardBox } from "../openFlow/atoms/card";
import { ContainerScrollView } from "../../helpers/appStyles";

const layoutType = getPlatformType();
const Height = Dimensions.get("window").height;

const verifiedImage = require("../../assets/images/Verfied.png");
const pointSvg = require("../../assets/SVG/point.svg");
const rightImage = require("../../assets/images/right.png");
const rightSvg = require("../../assets/SVG/right.svg");
const improveImage = require("../../assets/images/improve.png");
const improveSvg = require("../../assets/SVG/improve.svg");
const tenantloanImage = require("../../assets/images/tenantloan.png");
const tenantloanSvg = require("../../assets/SVG/tenantloan.svg");
const autoImage = require("../../assets/images/auto.png");
const autoSvg = require("../../assets/SVG/auto.svg");
const depositImage = require("../../assets/images/deposit.png");
const depositSvg = require("../../assets/SVG/deposit.svg");
const strategicImage = require("../../assets/images/strategic.png");
const strategicImageMobile = require("../../assets/images/strategicMobile.png");

const partners = require("../../assets/images/partners.png");
const interactiveImage = require("../../assets/images/interactive.png");
const interactiveSvg = require("../../assets/SVG/interactive.svg");
const lifetimeImage = require("../../assets/images/lifetime.png");
const lifetimeSvg = require("../../assets/SVG/lifetime.svg");

const VERIFY_DIGITAL_ID = "Digital ID verification, all without having to leave your home or risk losing ID documents in the post. Quick, secure and simple verificaiton that works! ";
const RIGHT_TO_RENT = "Prove your right to rent at the click of a button, taking the hassle out of making you look good!";
const TENANT_LOAN = "Struggling with move-in costs? Whether its help with your deposit, a trip to Ikea or pizza on move-in day, our low-cost solutions will help you get a good night’s sleep in your new home.";
const IMPROVE = "Keep an ongoing digital record of all your rental payments and provided you pay on time, help to build your credit score. Life just got a whole lot easier!";
const DEPOSIT = "Make sure your deposit is kept safe with the Government backed Tenancy Deposit Scheme, total protection, and complete peace of mind!";
const AUTO = "Keep your personal documents stored securely and shared directly with your agents, leaving more time to concentrate on the things that really matter like what colour to paint the spare room? ";
const LIFETIME = "Helping Tenants to transition between properties without waiting for deposits to come back from agents, automating the movement of money from one agent to another, say goodbye to the bank of mum and dad!";
const INTERACTIVE = "Confused and worried about what to do and when to do it in the move in process? With our interactive step by step guide we will help you and your agent through every step of the process!";
const TlyfeLanding = (props) => {
  const CardElement = (image, contain, svg, twoline = false) => {
    return (
      <CardBox hasBorderRadius>
        <CardContainer>
          <Image source={image} style={{
            width: "80%",
            height: twoline ? 50 : 30,
            resizeMode: "contain",
            alignSelf: "flex-start",
          }}/>
          <Text style={{
            marginTop: 30,
            color: "black",
            height: "50%",
            fontSize: 15,
            fontWeight: "500",
            fontFamily: "Lato",
          }}>{contain}</Text>
          <Image source={svg} style={{
            width: "40%",
            height: 150,
            resizeMode: "contain",
            marginLeft: "auto",
            marginTop: "auto",
            height: "35%",
            alignSelf: "center",
          }}/>
        </CardContainer>
      </CardBox>
    );
  };
  return (
    <SafeAreaView style={{
      backgroundColor: "#FAFAFA",
      height: Height,
    }}>
      <ContainerScrollView>
        <Header
          showLogoMob
          showLogoWeb
          showLogoTab
          showDrawerMob
          showDrawerTab
          isTLYF
          {...props}
        />
        <View style={{
          flex: 1,
          alignItems: "center",
        }}>
          {layoutType == "web" && <Image source={require("../../assets/images/mainBg.png")} style={{
            position: "absolute",
            marginTop: -10,
            height: "130vh",
            width: "100%",
          }}/>}
          {layoutType == "phone" && <Image source={require("../../assets/images/mainBgMobile.png")} style={{
            position: "absolute",
            height: "70vh",
            marginTop: -15,
            width: "100%",
          }}/>}
          {layoutType == "tab" && <Image source={require("../../assets/images/mainBgTablet.png")} style={{
            position: "absolute",
            marginTop: -10,
            height: "70vh",
            width: "100%",
          }}/>}
          <MainContainer>
            <Image source={require("../../assets/images/rentsmarter.png")} style={{
              width: layoutType == "phone" ? "250px" : "300px",
              height: "100px",
              marginTop: layoutType == "phone" ? 80 : 70,
              resizeMode: "contain",
            }}/>
            <Image source={require("../../assets/images/intro.png")} style={{
              width: "200px",
              height: "30px",
              marginTop: layoutType == "phone" ? 0 : 10,
              resizeMode: "contain",
            }}/>
            <IntroText>The UK's most innovative tenant lifecycle app, taking the stress out of the move-in process and
              helping make move in day that little bit more special!' apart from that its good to go!
            </IntroText>
            <TouchableOpacity style={{ width: "100%" }}
                              onPress={() => window.open("https://openbrix.co.uk/register", "_blank")}>
              <ClickHereImage
                source={layoutType == "phone" ? require("../../assets/images/clickhereMobile.png") : require("../../assets/images/clickhere.png")}/>
            </TouchableOpacity>
            <View style={{
              justifyContent: layoutType == "phone" ? "center" : "space-between",
              alignItems: "center",
              width: "100%",
              flexDirection: "row",
              flexWrap: "wrap",
              marginTop: layoutType == "phone" ? "40px" : "80px",
            }}>
              {CardElement(verifiedImage, VERIFY_DIGITAL_ID, pointSvg)}
              {layoutType == "phone" && <View style={{
                height: "20px",
                width: "100%",
              }}/>}
              {CardElement(rightImage, RIGHT_TO_RENT, rightSvg)}
            </View>
            {layoutType == "phone" ?
              <View style={{ marginTop: "20px" }}>
                {CardElement(lifetimeImage, LIFETIME, lifetimeSvg)}
                <View style={{ marginTop: "20px" }}/>
                {CardElement(interactiveImage, INTERACTIVE, interactiveSvg)}
              </View>
              : <View style={{
                width: "100%",
                marginTop: 20,
              }}>
                <CardBox hasBorderRadius>
                  <View style={{
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}>
                    <View style={{
                      width: "75%",
                      height: 200,
                      justifyContent: "center",
                    }}>
                      <Image source={lifetimeImage} style={{
                        width: "60%",
                        height: 30,
                        resizeMode: "contain",
                        alignSelf: "flex-start",
                      }}/>
                      <Text style={{
                        marginTop: 30,
                        color: "black",
                        height: "50%",
                        fontSize: 15,
                        fontWeight: "500",
                        fontFamily: "Lato",
                      }}>{LIFETIME}</Text>
                    </View>
                    <Image source={lifetimeSvg} style={{
                      width: "20%",
                      height: 120,
                      resizeMode: "contain",
                    }}/>
                  </View>
                </CardBox>
                <CardBox hasBorderRadius style={{ marginTop: "20px" }}>
                  <View style={{
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}>
                    <Image source={interactiveSvg} style={{
                      width: "20%",
                      height: 120,
                      resizeMode: "contain",
                    }}/>
                    <View style={{
                      width: "75%",
                      height: 200,
                      justifyContent: "center",
                    }}>
                      <Image source={interactiveImage} style={{
                        width: "60%",
                        height: 30,
                        resizeMode: "contain",
                        alignSelf: "flex-start",
                      }}/>
                      <Text style={{
                        marginTop: 30,
                        color: "black",
                        height: "50%",
                        fontSize: 15,
                        fontWeight: "500",
                        fontFamily: "Lato",
                      }}>{INTERACTIVE}</Text>
                    </View>
                  </View>
                </CardBox>
              </View>}
            <View style={{
              justifyContent: layoutType == "phone" ? "center" : "space-between",
              alignItems: "center",
              width: "100%",
              flexDirection: "row",
              flexWrap: "wrap",
              marginTop: "20px",
            }}>
              {CardElement(tenantloanImage, TENANT_LOAN, tenantloanSvg)}
              {layoutType == "phone" && <View style={{
                height: "20px",
                width: "100%",
              }}/>}
              {CardElement(improveImage, IMPROVE, improveSvg, true)}
            </View>
            <View style={{
              justifyContent: layoutType == "phone" ? "center" : "space-between",
              alignItems: "center",
              width: "100%",
              flexDirection: "row",
              flexWrap: "wrap",
              marginTop: "20px",
            }}>
              {CardElement(depositImage, DEPOSIT, depositSvg, true)}
              {layoutType == "phone" && <View style={{
                height: "20px",
                width: "100%",
              }}/>}
              {CardElement(autoImage, AUTO, autoSvg)}
            </View>
            <TouchableOpacity style={{
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }} onPress={() => window.open("https://openbrix.co.uk/register", "_blank")}>
              <ClickHereImage style={{
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
                              source={layoutType == "phone" ? require("../../assets/images/clickhereMobile.png") : require("../../assets/images/clickhere.png")}/>
            </TouchableOpacity>
            <View style={{
              width: "90%",
              marginBottom: 30,
              marginTop: "50px",
            }}>
              <CardBox hasBorderRadius>
                <View style={{
                  width: "100%",
                  padding: 15,
                }}>
                  <Image source={strategicImage} style={{
                    width: layoutType == "phone" ? "70%" : "35%",
                    height: 30,
                    resizeMode: "contain",
                    alignSelf: "flex-start",
                  }}/>
                  <Image source={layoutType == "phone" ? strategicImageMobile : partners} style={{
                    width: "100%",
                    height: layoutType == "phone" ? "300px" : 200,
                    marginTop: layoutType == "phone" ? "20px" : "50px",
                    resizeMode: "contain",
                    alignSelf: "flex-start",
                  }}/>
                </View>
              </CardBox>
            </View>

          </MainContainer>
        </View>
        <Footer {...props} />
      </ContainerScrollView>

    </SafeAreaView>
  );
};

export default TlyfeLanding;
