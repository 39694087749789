import React from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import {
  BubbleError,
  BubbleIcon,
  BubbleLabel,
  BubbleSuperText,
  BubbleText,
  BubbleTextInput,
  BubbleTextView,
} from "./sugg.style";
import { Entypo } from "@expo/vector-icons";

import { SERVER_URL } from "../../helpers/connectionHelpers";

export default class GooglePlacesSuggestion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      loc: "",
      predictionText: "",
    };

  }

  static getDerivedStateFromProps(props, state) {
    
    if (props.predictionText && state) {
      if (props.predictionText !== state.predictionText) {
        state.predictionText = props.predictionText;
      }
    }

    return null;
  }

  componentDidMount() {
  }

  ApiCall = (key) => {
    const search = key;

    try {

      return fetch(SERVER_URL + "/locations?string=" + search)
        .then((response) => response.json())
        .then((responseJson) => {

          this.setState(
            {
              isLoading: false,
              dataSource: responseJson.data,
            },
            function () {
            },
          );
        })
        .catch((error) => {

          //throw  (error);
          console.error(error);
        });
    } catch (e) {

    }
  };

  GetCordinates = (prediction) => {
    const placeid = prediction.place_id;

    return fetch(SERVER_URL + "/google_place_id?string=" + placeid)
      .then((response) => response.json())
      .then((responseJson) => {

        let Cordinates = {
          lat: responseJson.data.geometry.location.lat,
          lon: responseJson.data.geometry.location.lng,
        };

        this.props.selectedVal({
          ...prediction,
          ...Cordinates,
          user_input: this.state.loc,
        });
        this.setState({
          dataSource: null,
          predictionText: prediction.description,
          loc: "",
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  render() {
    const data = this.state.dataSource;
    let {
      loc,
      predictionText,
    } = this.state;

    return (
      <>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <BubbleLabel>{this.props.title ? this.props.title : ""}</BubbleLabel>
          {this.props.showError ? (
            <BubbleError>{this.props.errorMessage}</BubbleError>
          ) : null}
        </View>
        <BubbleTextInput
          value={this.state.loc}
          id={"LOCATIO_SEARCH_INPUT"}
          onChangeText={(val) => {
            this.setState({ loc: val }, () => this.ApiCall(val));
          }}
          style={{
            borderColor: this.props.showError ? "red" : "#d0d0d0",
            width: this.props.width ? this.props.width : "100%",
          }}
        ></BubbleTextInput>

        {data && data.length ? (
          data.map((prediction) => {
            return (
              <TouchableOpacity
                onPress={() => {
                  this.GetCordinates(prediction);
                }}
                style={{ margin: "2%" }}
                key={"map" + prediction.place_id}
              >
                <Text style={{ textAlign: "left" }}>
                  {prediction.description}
                </Text>
              </TouchableOpacity>
            );
          })
        ) : data && data.length === 0 && loc ? (
          <View style={{
            margin: "2%",
            alignItems: "center",
          }}>
            <Text style={{
              textAlign: "left",
              color: "red",
            }}>
              Location not fond!
            </Text>
          </View>
        ) : null}

        {predictionText ? (
          <BubbleTextView>
            <>
              <BubbleText>
                <BubbleIcon>
                  <TouchableOpacity
                  id={"PROPERTY_LOCATION"}
                    onPress={() => {
                      this.props.selectedVal(null);
                      this.setState({ predictionText: null });
                    }}
                  >
                    <Entypo name="cross" size={17} color="#E71E82"/>
                  </TouchableOpacity>
                </BubbleIcon>
                <BubbleSuperText  id={"PROPERTY_LOCATION_TEXT"}>
                  {predictionText}
                </BubbleSuperText>
              </BubbleText>
            </>
          </BubbleTextView>
        ) : null}
      </>
    );
  }
}

const styles = StyleSheet.create({
  dropStyle: {
    paddingLeft: "1%",
    paddingRight: "2%",
    paddingBottom: "3%",
    paddingTop: "3%",
    borderRadius: 3,
    borderBottomColor: "#eee",
    borderColor: "#eee",
  },
});
