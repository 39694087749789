import React from "react";
import { FlatList, Image, View } from "react-native";
import stylesMobile from "./stylesMobile";
import stylesTab from "./stylesTab";
import stylesWeb from "./stylesWeb";
import { Typography } from "../../openFlow/style";
import WideButton from "../../../commonComponents/wideButton";

const ImageView = (props) => {
  const {
    type,
    heading,
    subheading,
    onButtonClick,
    buttonText,
    imageUrl,
    reverse,
    heading2,
    bColor,
    buttonStyle,
    isTLYF,
    list,
    bottomList,
    bottomHeading,
    testID,
  } = props;

  const styles = type == "web" ? stylesWeb : type == "mobile" ? stylesMobile : stylesTab;

  return (
    <View style={[styles.imageContainer, { backgroundColor: bColor ? bColor : "white" }]}>
      {list && list.length ? (
        <>
          {heading ? (
            <Typography
              raj
              bold={"700"}
              color={"#1C1C1C"}
              size={type !== "web" ? "30px" : "48px"}
              line={type !== "web" ? "35px" : "52px"}
              align={type == "web" ? "left" : "center"}
            >
              {heading ? heading.toUpperCase() : ""}
            </Typography>
          ) : null}
          {heading2 ? (
            <Typography
              top={"20px"}
              align={type == "web" ? "left" : "center"}
              raj
              bold={"700"}
              color={"#737373"}
              size={type !== "web" ? "20px" : "28px"}
              line={type !== "web" ? "26px" : "33px"}
            >
              {heading2}
            </Typography>
          ) : null}
          {list.map((item, index) => {
            return (
              <View
                key={index}
                style={[{
                  flexDirection: "row",
                  alignItems: "center",
                  alignSelf: "flex-start",
                }]}
              >
                <Image
                  source={imageUrl}
                  style={{
                    height: 28,
                    width: 28,
                    marginRight: 16,
                  }}
                  resizeMode={"contain"}
                />

                <Typography
                  bottom={"20px"}
                  top={"20px"}
                  align={"left"}
                  raj
                  bold={"500"}
                  color={"#737373"}
                  size={type !== "web" ? "24px" : "26px"}
                  line={type !== "web" ? "31px" : "33px"}
                >
                  {item}
                </Typography>
              </View>
            );
          })}
        </>
      ) : imageUrl ? (
        <View
          style={[
            { flex: 1 },
            type == "web"
              ? {
                flexDirection: reverse ? "row-reverse" : "row",
                backgroundColor: bColor ? bColor : "white",
              }
              : { flexDirection: "column" },
          ]}
        >
          <View
            style={{
              flex: 0.5,
              marginRight: type == "web" ? "4%" : 0,
              marginBottom: type == "web" ? 0 : 30,
            }}
          >
            <Image
              source={imageUrl}
              style={
                type == "web" ? {
                  height: 500,
                  width: "100%",
                } : {
                  height: 309,
                  width: "100%",
                }
              }
              resizeMode={"contain"}
            />
          </View>
          <View
            style={{
              flex: 0.5,
              justifyContent: "center",
              marginLeftt: "4%",
              flexDirection: "column",
            }}
          >
            {heading ? (
              <Typography
                raj
                bold={"700"}
                color={"#1C1C1C"}
                size={type !== "web" ? "30px" : "48px"}
                line={type !== "web" ? "35px" : "52px"}
                align={type == "web" ? "left" : "center"}
              >
                {heading.toUpperCase()}
              </Typography>
            ) : null}
            {heading2 ? (
              <Typography
                top={"20px"}
                align={type == "web" ? "left" : "center"}
                raj
                bold={"700"}
                color={"#737373"}
                size={type !== "web" ? "20px" : "28px"}
                line={type !== "web" ? "26px" : "33px"}
              >
                {heading2}
              </Typography>
            ) : null}
            <Typography
              bottom={"20px"}
              top={"20px"}
              align={type == "web" ? "left" : "center"}
              raj
              bold={"500"}
              color={"#737373"}
              size={type !== "web" ? "24px" : "26px"}
              line={type !== "web" ? "31px" : "33px"}
            >
              {subheading}
            </Typography>
          </View>
        </View>
      ) : null}

      {bottomList && bottomList.length ? (
     <FlatList
            data={bottomList}
            horizontal={true}
            contentContainerStyle={{
              flexGrow: 1,
              justifyContent: 'center',
              marginTop:30
          }}
            style={{
              overscrollBehaviorY: "auto",
              width: "100%",
              touchAction: "auto",
            }}
            renderItem={({
              item,
              index,
            }) => (
              <View
                style={[
                  {
                    width: 270,
                    borderRadius: 20,
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 39,
                    marginRight: 71,
                    backgroundColor: "white",
                    marginTop: index % 2 === 0 ? 20 : 0,
                  },
                ]}
                id={index}
              >
                <Image
                  resizeMode={"contain"}
                  source={item?.imageUrl}
                  style={{
                    width: 200,
                    height: 97,
                  }}
                />
              </View>
            )}
          />
      
      ) : null}
      {bottomHeading ? (
        <Typography
          raj
          bold={"700"}
          color={"#1C1C1C"}
          size={type !== "web" ? "20px" : "40px"}
          line={type !== "web" ? "35px" : "52px"}
          align={"center"}
          style={{maxWidth:860,alignSelf:'center'}}
        >
          {bottomHeading}
        </Typography>
      ) : null}
      {onButtonClick ? (
        <View style={buttonStyle ? buttonStyle : {}}>
          <WideButton
            width={type == "web" ? "471px" : "307px"}
            onPress={() => {
              onButtonClick();
            }}
            testID={testID}
            gradiant
            isLGreen={isTLYF}
            childText={buttonText ? buttonText : "CLICK HERE"}
            height={"65px"}
            gradBorderRadius={50}
            isRaj={true}
            alignSelf={"center"}
            textSize={type == "web" ? 24 : 18}
          />
        </View>
      ) : null}
    </View>
  );
};
export default ImageView;
