import React, { useState } from 'react';
import { Text, StyleSheet, TouchableOpacity, View, ImageBackground, FlatList } from 'react-native';

import { useTheme } from '@emotion/react';
import getScreenType from '../../../../utils/getScreenType';
import { ODSIcon } from '../../../atoms/ODSIcon';
import { ODSText } from '../../../atoms/ODSText';


const MyAccountPanel = ({
  onPress,
  selectedId
}) => {
  const { colors } = useTheme();
  const layout = getScreenType();
  const TOP_DATA=[{
    id:'profile',
    title:'My Profile',
    description:'Keep your profile updated',
    icon:'TB-Profile-Info'
  },
  {
    id:'My_Rental_Information',
    title:'My Rental Information',
    description:'Keep your profile updated',
    icon:'rentaloffer'
  },
  {
    id:'invoice',
    title:'My Invoices',
    description:'Find all your invoices here',
    icon:'TB-Book'
  },
  {
    id:'saved',
    title:'Saved Properties',
    description:'Your saved properties',
    icon:'TB-House-Fav'
  },
  {
    id:'alerts',
    title:'Property Alerts',
    description:'Manage your property alerts',
    icon:'TB-Alerts'
  },

]
const SETTING_DATA=[{
    id:'settings',
    title:'Notification Setting',
    icon:'TB-Notifications-Settings'
  },
  {
    id:'change-password',
    title:'Change Password',
    icon:'TB-Lock'
  },
  {
    id:'contact-us',
    title:'Contact Us',
    icon:'email'
  }
]

  const renderLeftCard=(item)=>{
    return(
       <TouchableOpacity onPress={()=>{
        onPress(item.id);
       }}>
       <View key={item.id} style={{ borderRadius:12,marginVertical:3 ,paddingVertical: 20, paddingHorizontal: 24, flexDirection: 'row', alignItems: 'center', borderWidth: 1, borderColor:item.isDelete?colors.feedback.error[200]:colors.other['border-neutral'],backgroundColor:selectedId==item.id?colors.elements['color-bg']:'#fff' }}>
        <ODSIcon icon={item.icon} size={24} color={item.isDelete?colors.feedback.error[500]:colors.button.main} />
        <View style={{ width: 20 }} />
        <View style={{ flex: 1 }}>
          <ODSText color={colors.text.primary} type={'sm-bold'}>{item.title}</ODSText>
         {item.description && <ODSText width={'100%'} marginTop={4} color={colors.text.secondary} type={'sm'}>{item.description}</ODSText>}
        </View>
        <View style={{ width: 20 }} />
          <ODSIcon icon="TB-Chevron-Right" size={24} color={item.isDelete?colors.feedback.error[500]:colors.button.main} />
        </View> 
        </TouchableOpacity>
    )
  }

  return (
    <View style={{ flex:1}}>
        {TOP_DATA.map((item)=>renderLeftCard(item))}
        <View style={{width:'100%',height:1,backgroundColor:'#D0D6DD',marginVertical:24}}/>
        {renderLeftCard({id:'refer',icon:'TB-Heart',title:'Refer a Friend',description:'Love tlyfe? Why not refer a friend so they can enjoy the benefits of tlyfe?'})}
        <ODSText marginVertical={24} type='md-bold' color={colors.text.primary}>Settings</ODSText>
        {SETTING_DATA.map((item)=>renderLeftCard(item))}
        <View style={{width:'100%',height:1,backgroundColor:'#D0D6DD',marginVertical:24}}/>
        {renderLeftCard({id:'delete',icon:'TB-Trash',title:'Delete',description:'This action cannot be undone!',isDelete:true})}    
    </View>
  );
};


export default MyAccountPanel;
