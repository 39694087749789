import React from "react";
import styled from "styled-components/native";
import getPlatformType from "../../helpers/getPlatform";

const layoutType = getPlatformType();
let Container;
let FormContainer, ContactTitle, ContactHead, FormControls;

if (layoutType == "phone") {
  Container = styled.View`
    justify-content: center;
    width: 100%;
    align-items: center;
    padding-right: 16px;
    padding-left: 16px;
  `;
  FormContainer = styled.View`
    justify-content: center;
    width: 100%;
    align-items: center;
    flex-direction: column;
    z-index: 999;
  `;
  ContactTitle = styled.Text`
    font-size: 22px;
    font-weight: bold;
    text-align: left;
    width: 100%;
    margin-bottom: 24px;
  `;
  ContactHead = styled.View`
    width: 100%;
    flex-direction: row;
    margin-bottom: 40px;
    margin-top: 24px;
  `;
  FormControls = styled.View`
    background: #ffffff;
    margin-bottom: 16px;
    flex-direction: column;
    justify-content: center;
  `;
} else if (layoutType == "web") {
  Container = styled.View`
    justify-content: center;
    flex-direction: row;
    width: 935px;
    align-self: center;
    align-items: center;
  `;
  FormContainer = styled.View`
    justify-content: center;
    width: 100%;
    align-items: center;
    flex-direction: row;
    z-index: 999;
  `;
  ContactTitle = styled.Text`
    font-size: 22px;
    font-weight: bold;
    text-align: left;
    width: 100%;
    margin-bottom: 24px;
  `;
  ContactHead = styled.View`
    width: 100%;
    flex-direction: row;
    margin-bottom: 32px;
  `;
  FormControls = styled.View`
    background: #ffffff;
    margin-bottom: 16px;
    flex-direction: column;
    justify-content: center;
  `;
} else {
  Container = styled.View`
    justify-content: center;
    flex-direction: row;
    width: 100%;
    align-items: center;
    padding-right: 120px;
    padding-left: 120px;
  `;
  FormContainer = styled.View`
    justify-content: center;
    flex-direction: row;
    width: 100%;
    align-items: center;
    z-index: 999;
  `;
  ContactTitle = styled.Text`
    font-size: 22px;
    font-weight: bold;
    text-align: left;
    width: 100%;
    margin-top: 24px;
    margin-bottom: 24px;
  `;
  ContactHead = styled.View`
    width: 100%;
    flex-direction: row;
    margin-bottom: 32px;
  `;
  FormControls = styled.View`
    background: #ffffff;
    margin-bottom: 16px;
    flex-direction: column;
    justify-content: center;
  `;
}
const BottomContainer = styled.View`
  width: 100%;
  height: 20%;
`;
const LeftPanal = styled.View`
  flex-grow: 1;
  margin-right: 6px;
`;
const BackTextWrapper = styled.Text`
  height: 17px;
  color: #000000;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
`;
export {
  Container,
  BottomContainer,
  FormContainer,
  ContactTitle,
  ContactHead,
  FormControls,
  LeftPanal,
  BackTextWrapper,
};
