import React, { useState } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { ODSIcon } from '../ODSIcon';
import { useTheme } from '@emotion/react';
import { ODSText } from '../ODSText';

const ODSCheckbox = ({ checked, onPress, label, color, disabled }) => {
    const theme = useTheme();
    const [selected, setSelected] = useState(checked);

    const onChecked = () => {
        if (!disabled) {
            setSelected(!selected);
            onPress && onPress(!selected);
        }
    };

    return (
        <TouchableOpacity
            style={{
                flexDirection: 'row',
                alignItems: 'center',
                opacity: disabled ? 0.5 : 1,
            }}
            onPress={onChecked}
            disabled={disabled}
        >
            <View
                style={{
                    width: 24,
                    height: 24,
                    borderColor: disabled ? theme.colors.disabled.text : selected ? theme.colors.button.contrast : theme.colors.other['border-neutral'],
                    borderWidth: selected ? 2 : 1,
                    borderRadius: 4,
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {selected && (
                    <ODSIcon
                        icon="TB-Check"
                        color={disabled ? theme.colors.disabled.text : theme.colors.button.contrast}
                        size={16}
                    />
                )}
            </View>
            <ODSText type="sm" marginLeft={8} color={theme.colors.text.primary}>
                {label}
            </ODSText>
        </TouchableOpacity>
    );
};

export default ODSCheckbox;
