import { Alert, Platform } from "react-native";
import { types } from "../../actionTypes";

export const statusPopupAction = () => {
  return (dispatch) => {
    dispatch({ type: types.HIDE_ALERT });
  };
};
export const hideToasterPopUp = () => {
  return (dispatch) => {
    dispatch({ type: types.HIDE_TOASTER_POPUP });
  };
};
