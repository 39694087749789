import React, { useState } from "react";
import {
  View,
  Text,
  Dimensions,
  SafeAreaView,
  Image,
  TouchableOpacity,
  Platform,
} from "react-native";
//import { Title } from "./Tabs.style";
import ShareMedia from "../../commonComponents/shareMedia/shareMedia.web";
import {
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  LinkedinShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  EmailShareButton,
  TwitterIcon,
  LinkedinIcon,
  TelegramIcon,
  WhatsappIcon,
  EmailIcon,
} from "react-share";
import {
  NavTabs,
  NavPill,
  ActiveNavPill,
  NavPillBox,
  TabContent,
  NavPillText,
  ActiveNavPillText,
  TabContentFooter,
  NavHeader,
  ShareButtonView,
  ShareOptions,
} from "./tripleTabStyles";
import getPlatformType from "../../helpers/getPlatform";
import { COLORS } from "../../constants/colorPallete";
const WIN_HEIGHT = Dimensions.get("window").height;

const TripleTabs = (props) => {
  //let { tab1, tab2, tab3 } = props.renderScene;
  let {
    isFullWidth = true,
    title,
    tabList,
    paddingTop,
    paddingBottom,
    setCurrentTabIndex,
    titlePink = false,
    hasShare,
    activeTab,
    activeTabIndex = 0,
    tabHeight = WIN_HEIGHT - 260
  } = props;

  const [isActiveComponent, setActiveComponentIndex] = useState(0);
  const [activeComponent, setActiveComponent] = useState(tabList[0].target);
  const [ActiveFooterComponent, setActiveFooterComponent] = useState(
    tabList[0].targetfooter
  );
  const [shareOptions, setShareOptions] = useState(false);

  React.useEffect(() => {
    if(activeTab && typeof activeTab==='number'){
      setActiveComponent(tabList[activeTab].target);
      setActiveComponentIndex(activeTab);
    }    
  }, [activeTab]);

  const layoutType = getPlatformType();
  return (
    <View
      style={{
        minHeight: tabHeight,
        // paddingTop: paddingTop ? paddingTop : "3%",
        paddingBottom: paddingBottom ? paddingBottom : "3%",
      }}
    >
      <NavTabs
        isFullWidth={isFullWidth}
        style={{
          justifyContent: isFullWidth == false ? "space-between" : "center",
        }}
      >
        {layoutType !== "phone" && !isFullWidth ? (
          <View>
            <NavHeader style={{ color: titlePink ? COLORS.orange : "#000" }}>
              {title}
            </NavHeader>
          </View>
        ) : null}

        <NavPillBox
          isFullWidth={isFullWidth}
          style={{
            width:
              isFullWidth == false && layoutType !== "phone" ? "unset" : "100%",
          }}
        >
          {tabList.map((tab, index) => {
            let v =
              isActiveComponent == index ? (
                <ActiveNavPill
                  key={index}
                  isFullWidth={isFullWidth}
                  style={{
                    backgroundColor: "white",
                    borderColor: "white",
                    borderTopRightRadius: 20,
                    borderWidth: 1,
                    shadowColor: "grey",
                    shadowOffset: {
                      width: 0,
                      height: 5,
                    },
                    shadowOpacity: 0.9,
                    shadowRadius: 6.68,
                    elevation: 11,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ActiveNavPillText
                    id={tab.id}
                    style={{ color: props.activeTitleColor || "#FC4850" ,textAlign:'center'}}
                  >
                    {tab.label}
                  </ActiveNavPillText>
                  {tab?.showMark &&
                  <View style={{width:20,height:20,borderRadius:1000,backgroundColor:'#FC4850',justifyContent:'center',alignItems:'center'}}>
                   <NavPillText style={{ fontWeight: "bold", fontSize: 14,  color: 'white'}}>
                    !
                    </NavPillText>
                    </View>}
                </ActiveNavPill>
              ) : (
                <NavPill
                  onPress={() => {
                    setActiveComponentIndex(index);
                    setActiveComponent(tab.target);
                    setActiveFooterComponent(tab.targetfooter);
                    {
                      setCurrentTabIndex ? setCurrentTabIndex(index) : null;
                    }
                  }}
                  key={index}
                  isFullWidth={isFullWidth}
                  id={tab?.id}
                >
                  <NavPillText  id={tab.id} style={{ fontWeight: "normal", fontSize: 11,textAlign:'center',  color: props.defaultTitleColor || "#000"}}>
                    {tab.label}
                  </NavPillText>
                 {tab?.showMark &&
                  <View style={{width:20,height:20,borderRadius:1000,backgroundColor:'#FC4850',justifyContent:'center',alignItems:'center'}}>
                   <NavPillText style={{ fontWeight: "bold", fontSize: 14,  color: 'white'}}>
                    !
                    </NavPillText>
                    </View>}
                </NavPill>
              );
            return v;
          })}
        </NavPillBox>
      </NavTabs>
      <TabContent
        isActiveComponent={isActiveComponent}
        style={{
          backgroundColor: "white",
          borderColor: "white",
          borderTopRightRadius: isActiveComponent == 2 ? 0 : 20,
          backgroundColor: "white",
          borderWidth: 1,
          shadowColor: "grey",
          shadowOffset: {
            width: 0,
            height: 6,
          },
          shadowOpacity: 0.9,
          shadowRadius: 6.68,
          elevation: 11,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {activeComponent}
      </TabContent>
      {ActiveFooterComponent ? (
        <View>
          <TabContentFooter>{ActiveFooterComponent}</TabContentFooter>
        </View>
      ) : null}
      {hasShare ? (
        <View
          style={{ width: "100%", marginTop: 30, justifyContent: "center" }}
        >
          <Text style={{ fontSize: 18, color: "black", fontWeight: "bold" }}>
            Share Media hub
          </Text>
          <View style={{ marginTop: 10, flexDirection: "row" }}>
            <FacebookMessengerShareButton
              url={window.location.href}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FacebookMessengerIcon size={32} round />
            </FacebookMessengerShareButton>
            <TwitterShareButton
              url={window.location.href}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TwitterIcon size={32} round />
            </TwitterShareButton>
            <TelegramShareButton
              url={window.location.href}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TelegramIcon size={32} round />
            </TelegramShareButton>
            <WhatsappShareButton
              url={window.location.href}
              separator=":: "
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
            <LinkedinShareButton
              url={window.location.href}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LinkedinIcon size={32} round />
            </LinkedinShareButton>
          </View>
        </View>
      ) : null}
    </View>
  );
};

export default TripleTabs;
