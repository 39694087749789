import DatePicker from 'react-datepicker';
import React, { useEffect, useState } from 'react';
import './style.web.css';
import { StyleSheet, View, Text } from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import 'react-datepicker/dist/react-datepicker.css';
import moment from "moment"
const DatePickerComponent = (props) => {
  const focus = useIsFocused();
  const [state, setState] = useState({
    disabled: props.disabled ? props.disabled : false,
    date: props.value ? props.value : null,
    placeholder: props.placeholder ?? '',
    property: props.property ?? 'date',
  });
  const onChange = (date) => {
    setState({
      ...state,
      date,
    });
    props.onUpdateInput(date, state.property);
  };
  if (focus) {
    return (
      <View style={styles.input}>
        {state.placeholder ? (
          <Text style={props?.inputLabel ? props?.inputLabel : styles.inputLabel}>
            {state.placeholder}
          </Text>
        ) : null}
        <View
          style={{
            width: '100%',
            height: 40,
            borderWidth: 1,
            justifyContent: 'center',
            padding: 5,
            borderColor: '#d0d0d0',
            borderRadius: 5,
            display: 'flex',
            marginTop:10
          }}
        >
          <DatePicker
            onChange={(data) => onChange(data)}
            selected={state.date}
            portalId="root-portal"
            dateFormat="dd/MM/yyyy"
            yearDropdownItemNumber={100}
            scrollableYearDropdown={true}
            showYearDropdown
            showMonthDropdown
            maxDate={props?.is18yrs? new Date(moment().subtract(18, "years")):null}
            disabled={state.disabled}
          />
        </View>
      </View>
    );
  } else {
    return <></>;
  }
};
const styles = StyleSheet.create({
  input: {
    width: '100%',
  },
  inputLabel: {
    fontSize: 15,
    color: '#000',
    fontWeight: 500,
    fontFamily: 'Lato',
    marginBottom: 10,
  },
});
export default DatePickerComponent;
