import React,{ useState } from "react";
import { useNavigation } from "@react-navigation/native";

import Quote from "./Quote";
import ZopaWelcome from "./ZopaWelcome";
import ZopaApplication from "./ZopaApplication";

const Zopa=(props)=>{

    const navigation = useNavigation();
    const [initialScreen, setScreen] = useState('Welcome');
    const [navProps,setProps]=useState(null);

    if(initialScreen == "Welcome") {
        return (
            <ZopaWelcome
                navigation={navigation}
                onNext={()=>setScreen("Application")}
                {...props}
            /> 
        )
    }
    else if(initialScreen == "Application") {
        return (
            <ZopaApplication
                navigation={navigation}
                goBack={()=>setScreen("Welcome")}
                onNext={(value)=>{
                    setProps(value);
                    setScreen("Quote")}}
                {...props}
            /> 
        )
    }
    else if(initialScreen == "Quote") {
        return (
            <Quote
                navigation={navigation}
                data={navProps}
                goBack={()=>setScreen("Welcome")}
                {...props}
            /> 
        )
    }
};

export default Zopa;