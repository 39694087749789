import React from 'react';
import { useTheme } from '@emotion/react';
import { ReactElement } from 'react';
import IcoMoon from 'react-icomoon';
import { Path, Svg } from 'react-native-svg';
import iconSet from './selection.json';

interface IconProps{
    icon:string;
    color:string;
    size:any
}

export const ODSIcon = ({
  icon,
  color = '#454F59',
  size,
}: IconProps): ReactElement => {
  const theme = useTheme();
  function handleColor(): string {
    const { colors } = theme;
    if (colors.text[color]) return colors.text[color];

    return color;
  }
  return (
    <IcoMoon
      native
      SvgComponent={Svg}
      PathComponent={Path}
      iconSet={iconSet}
      icon={icon}
      color={color?color: handleColor()}
      size={size}
    />
  );
};