import React, { Component, useEffect } from 'react';
import {
  View,
  Text,
  Dimensions,
  SafeAreaView,
  Image,
  TouchableOpacity,
  Platform,
} from 'react-native';
import { Title, TabName } from './Tabs.style';
import getPlatformType from '../../helpers/getPlatform';
import { COLORS } from '../../constants/colorPallete';
import { AntDesign } from '@expo/vector-icons';
import { DocShare, DocShareText } from '../../components/myDocumentsList/styles';
const layoutType = getPlatformType();
export function Tabs(props) {
  let { tab1, tab2 } = props.renderScene;
  let { tabTitle1, tabTitle2 } = props.title;
  let { setActiveTab, isFullWidth = true, tabName, isTLYE, isShared, eyeIcon ,onShareClick} = props;

  const [isActiveComponent, setActiveComponent] = React.useState('Tab1');
  const SELECTEDCOLOR = isTLYE ? COLORS.LGREEN : COLORS.LGREEN;

  let selectedMenuUI = function (value) {
    if (isActiveComponent === 'Tab1' && isActiveComponent === value) {
      return {
        backgroundColor: 'white',
        borderColor: 'white',
        borderTopRightRadius: 20,
        backgroundColor: 'white',
        borderWidth: 1,
        shadowColor: 'grey',
        shadowOffset: {
          width: 0,
          height: 2,
        },
        shadowOpacity: 0.09,
        shadowRadius: 6.68,
        elevation: 11,
        flex: 0.5,
        justifyContent: 'center',
        alignItems: 'center',
      };
    } else if (isActiveComponent === 'Tab2' && isActiveComponent === value) {
      return {
        backgroundColor: 'white',
        borderColor: 'white',
        borderTopLeftRadius: 20,
        backgroundColor: 'white',
        borderWidth: 1,
        shadowColor: 'grey',
        shadowOffset: {
          width: 0,
          height: 2,
        },
        shadowOpacity: 0.09,
        shadowRadius: 6.68,
        elevation: 11,
        flex: 0.5,
        justifyContent: 'center',
        alignItems: 'center',
      };
    }
    return {
      flex: 0.5,
      justifyContent: 'center',
      alignItems: 'center',
    };
  };
  useEffect(() => {
    props.defaultActiveTab ? setActiveComponent(props.defaultActiveTab) : null;
  }, [props.defaultActiveTab]);
  console.log("eyeIcon props",props)
  return (
    <View style={{ flex: 1, width: '100%' }}>
      <View
        isFullWidth={isFullWidth}
        style={{
          height: 70,
          flexDirection: 'row',
          backgroundColor: 'transparent',
          justifyContent: isFullWidth == false ? 'space-between' : 'center',
        }}
      >
        {layoutType !== 'phone' && !isFullWidth ? (
          <View
            style={{
              flex: 1,
              justifyContent: 'center',
              alignItems: 'flex-start',
            }}
          >
            <TabName>{tabName}</TabName>
          </View>
        ) : null}
        <TouchableOpacity
          onPress={() => {
            setActiveComponent('Tab1');
            setActiveTab ? setActiveTab('Tab1') : null;
          }}
          style={{
            ...selectedMenuUI('Tab1'),
          }}
        >
          <Title
            style={{
              color:
                isActiveComponent === 'Tab1'
                  ? tabTitle1 == 'Renter/Buyer'
                    ? COLORS.orange
                    : SELECTEDCOLOR
                  : '#000',
            }}
          >
            {tabTitle1}
          </Title>
          {eyeIcon ? (
            <TouchableOpacity style={{flexDirection:"row",justifyContent:"center",alignItems:"center"}}onPress={() => onShareClick()}>
              <AntDesign name="eye" size={22} color={isShared ? COLORS.orange : '#bdbdbd'} />
              <DocShareText
                style={{
                  color: isShared ? '#000' : '#bdbdbd',
                }}
              >
                {' '}
               { isShared?"Shared":"Share"}
              </DocShareText>
            </TouchableOpacity>
          ) : null}
        </TouchableOpacity>
       {tab2 ? <TouchableOpacity
          onPress={() => {
            setActiveComponent('Tab2');
            setActiveTab ? setActiveTab('Tab2') : null;
          }}
          style={{
            ...selectedMenuUI('Tab2'),
          }}
        >
          <Title style={{ color: isActiveComponent === "Tab2" ? SELECTEDCOLOR : '#000', }}>{tabTitle2}</Title>
        </TouchableOpacity>:<View style={{
            ...selectedMenuUI("Tab2"),
          }}/>}
      </View>
      <View
        style={{
          borderColor: 'white',
          borderBottomLeftRadius: 20,
          borderTopRightRadius: isActiveComponent === 'Tab2' ? 0 : 20,
          backgroundColor: 'white',
          shadowColor: 'grey',
          shadowOpacity: 0.09,
          shadowRadius: 6.68,
          shadowOffset: {
            width: 0,
            height: 2,
          },
          elevation: 11,
          justifyContent: 'center',
          alignItems: 'center',
          padding: '0%',
        }}
      >
        {isActiveComponent == 'Tab1' ? tab1 : tab2}
      </View>
    </View>
  );
}
