import React from "react";
import { View, Image } from "react-native";
import { Checkbox } from "react-native-paper";

import getPlatformType from "../../helpers/getPlatform";
import Footer from "../../commonComponents/footerComponent/customFooter";
import Header from "../../commonComponents/headerComponent/headerComponent";
import { Card, Container, Content, ContentWrap, HeaderRow, HestiaLogo, Main, NextBtn, NormalText, TextContainer } from "./styles";
import BackButton from "../../commonComponents/BackButton";
import { useNavigation } from "@react-navigation/native";


const ZopaWelcome = (props) => {
    const navigation=useNavigation();
    const layoutType = getPlatformType();
    const [checkbox,setCheckbox]=React.useState(false);
    const [checkbox2,setCheckbox2]=React.useState(false);
    const checkBoxView = () => {
        return (
            <View style={{ flexDirection: 'row', alignItems: 'center',marginTop:40 }}>
                <Checkbox
                id={"ZOPA_TNC"}
                status={checkbox?'checked':'unchecked'} onPress={()=>setCheckbox(!checkbox)} />
                <NormalText  id={"ZOPA_TNC"}>
                   
                    I have read and agreed to the Hestia Money terms <br />
                    and conditions.
       
                </NormalText>
            </View>
        )
    }
    const checkBoxView2 = () => {
        return (
            <View style={{ flexDirection: 'row', alignItems: 'center',marginTop:20 }}>
                <Checkbox
                id={"ZOPA_TNC"}
                status={checkbox2?'checked':'unchecked'} onPress={()=>setCheckbox2(!checkbox2)} />
                <NormalText  id={"ZOPA_ALLOW"}>
                <br /><br />
                    Allow Openbrix to share my data with Zopa.
                    <br /><br />
                    For more information <a id={"ZOPA_CLICKHERE"} target='_blank' href='https://assets.openbrix.co.uk/Hestia_Terms.pdf'>click here</a>
                </NormalText>
            </View>
        )
    }
    return (
        <View style={{ flex: 1 }}>
            <Header MenuAccountWeb showDrawerMob showDrawerTab showLogoMob showLogoWeb showLogoTab rightChildLabelTab isTLYF  {...props}/>
           <BackButton 
             replace={"Dashboard"}
             navigation={props?.navigation}
         />
            <Container>
                <Main>
                    <HeaderRow>
                        <TextContainer>Welcome to<br />
                            Hestia Money Centre</TextContainer>
                        <HestiaLogo source={require("../../assets/SVG/hestia.svg")} resizeMode="contain" />
                    </HeaderRow>
                    <Content>
                        <NormalText style={{ marginTop: 30, marginBottom: 20 }}>tlyfe and Hestia Money have joined forces to bring you financial products and services from Zopa Bank Ltd that are tailored to your needs.</NormalText>
                        <ContentWrap>
                            <View>
                                <Card style={{ marginTop: 30 }}>
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20 }}>
                                        <NormalText style={{ fontWeight: '700' }}>Zopa loan</NormalText>
                                        <Image source={require('../../assets/images/zopa_green_logo.png')} style={{ width: 55, height: 30 }} />
                                    </View>
                                    <NormalText>
                                        This is a regular unsecured loan, which will allow you to borrow money, to help you fund your home move.
                                    </NormalText>
                                </Card>
                                {layoutType != "phone" && checkBoxView()}
                                {layoutType != "phone" && checkBoxView2()}
                            </View>
                            <View>
                                <Card style={{ marginTop: 30 }}>
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20 }}>
                                        <NormalText style={{ fontWeight: '700' }}>Credit file</NormalText>
                                    </View>
                                    <NormalText>
                                        At this point you are requesting a Zopa Loan quote tailored to you.<br /><br />

                                        Upon submission Zopa will check your eligibility for a loan, including using the personal information that you provide for the purpose of credit reference searches and fraud prevention.
                                        <br /><br />
                                        A soft credit search will be conducted to generate a quote which you can see but lenders cannot. This will not affect your credit score.
                                        <br /><br />
                                        If we are able to provide you a quote, you are under no obligation to proceed with a loan application.<br /><br />
                                        <b>Commission Disclosure Statement</b>
                                        <br /><br />
                                        For Zopa loans we do not charge you a fee for the introduction to Zopa.
                                        <br /><br />
                                        Hestia Money income is derived from  commission paid by Zopa for any successful introductions made of up to 1% of the loan value.
                                        <br /><br />
                                        This arrangement will not impact your loan application.
                                    </NormalText>
                                </Card>
                                {layoutType == "phone" && checkBoxView()}
                                {layoutType == "phone" && checkBoxView2()}
                                <NextBtn 
                                id={"ZOPA_STATRT"}
                                onPress={() =>checkbox&&checkbox2?props.onNext():alert("Please accept the terms and conditions")}>
                                    <NormalText style={{ textAlign: 'center', fontWeight: '700' }} platform={layoutType}>Start</NormalText>
                                </NextBtn>
                            </View>

                        </ContentWrap>

                    </Content>
                    <NormalText style={{ marginVertical: 30 }}>

                        Hestia Money Limited (trading as Hestia Money) ("we” or “our”) is registered in England and Wales (company number: 13661675). Our registered office is: 3rd Floor, 1 Ashley Road, Altrincham, Cheshire WA14 2DT. Our Postal address is 4-5 Quay Point, Northarbour Road, Portsmouth PO6 3TD.
                        <br /><br />
                        We are:
                        •	a credit broker authorised and regulated by the Financial Conduct Authority (“FCA”) and we are entered on the Financial Services Register under reference number: 968541; and<br />
                        •	an Appointed Representative of Paragon Advance Ltd (company registration number: 3679691 and FCA firm reference no: 304595).
                        <br /><br />
                        •	Please note that we are not a lender.
                    </NormalText>
                </Main>
                <Footer isTLYF />

            </Container>

        </View>
    )
}
export default ZopaWelcome;