import React, { Component } from "react";

import {
  BoxButton,
  BoxView,
  BoxText,
  BoxWrapper,
  BoxTextLabel,
  TitleView,
  ImageWrapper,
  IntroImg,
  TitleText,
} from "./IntroScreenCard.style";
import { color } from "react-native-reanimated";
import { Text, View,Image } from "react-native";

import getPlatformType from "../../helpers/getPlatform";
import WideButton from "../../commonComponents/wideButton";
import { ImageBackground } from "react-native";
import GradientText from "../../commonComponents/gradientText";
const layoutType = getPlatformType();

export default class IntroScreenCard extends Component {
  render() {
    const { onButtonClick,label, boxStyle ,showButton,dataArray,title,headerTitle,passParams,header,svg,style} = this.props;

    return (
      <BoxWrapper>

{headerTitle&&   <ImageBackground
    source={headerTitle}
    style={{height:60,width:130,marginTop:50,alignSelf:"center"}}
    resizeMode={"contain"}
    />}
    {svg && <Image source={svg} style={[style,{marginTop:layoutType=="phone"?10:30,alignSelf:'center'}]}/>}
     {/* {header && <GradientText extraWidht={50}  color1={"#FBB1E6"} style={{marginTop:layoutType=="phone"?10:30,marginLeft:15,marginBottom:0,alignSelf:'center'}} color2={"#FFB580"} childText={header} fontSize={layoutType=="phone"?50:60}/>} */}
        <TitleText style={{fontFamily:"Lato-Bold",marginBottom:layoutType=="phone"?20:40,width:layoutType=="phone"?"80%":"90%",alignSelf:'center'}}>
     {title}
        </TitleText>
        <BoxView>
          {dataArray.map((list, index) => {
            return (
              <BoxButton key={`bbn${index}`}>
                {list.image && (
                 <View style={{height:layoutType=="phone"?90:120}}>
                 <IntroImg 
                  source={list.image}
                  >
                    <View style={{flex:1,alignItems: "center" ,justifyContent:"center" }}>
                      <Image
                      style={{height:"40%",width:"40%"}}
                      source={list.chileimage} resizeMode="contain" />
                      </View>
                    </IntroImg>
                    </View>

                )}
            <View style={{height:30}}>
                <Text
                  style={{
                    marginBottom: 10,
                    fontSize: 15,
                    color: "black",
                    fontWeight: "bold",
                    textAlign: "center",
                    fontFamily:"Lato-Bold"
                  }}
                >
                  {list.title}
                </Text>
                </View>
                <View style={{height:layoutType=="phone"?60:120}}>
                <BoxText style={{fontFamily:"Lato"}}>{list.text}</BoxText>
                </View>
              </BoxButton>
            );
          })}
        </BoxView>

      {showButton&&    <View style={{ width: "100%", alignItems: "center",marginBottom:layoutType=="phone"?0:30,marginTop:layoutType=="phone"?20:0 }}>
            <WideButton
            gradiant={["#FBB1E6","#FFB580"]}
              childText="Register / Sign In"
              onPress={() => {
                onButtonClick?onButtonClick():
                this.props.navigation.navigate("RegisterEmail",{email:passParams})}}
              width={layoutType == "phone" ? "80%" : "40%"}
            />
          </View>}

      </BoxWrapper>
    );
  }
}
