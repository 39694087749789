import React, { useState, useEffect } from "react";
import {
  Image,
  TouchableOpacity,
  View,
  FlatList,
  Text,
  SafeAreaView,
} from "react-native";
import {
  Container,
  RightPanal,
  LeftPanal,
  CenterPanal,
  ListView,
  Section1,
  Section2,
} from "./styles.js";
import getPlatformType from "../../helpers/getPlatform";

const layoutType = getPlatformType();

import GoogleMap from "google-map-react";
import Card from "../CardComponent";
import idx from "idx";
import ServiceCard from "../serviceCard/index.js";

const mapStyles = {
  width: "100%",
  height: "100vh",
  position: "relative",
};

class GoogleMaps extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showingInfoWindow: false, //Hides or the shows the infoWindow
      activeMarker: {}, //Shows the active marker upon click
      selectedPlace: {}, //Shows the infoWindow to the selected place upon a marker,
      showCard: false,
      isMapExpanded: false,
      showServiceCard:false,
      serviceData:null
    };
  }
  // displayMarkers(stores, selectedPropsId) {
  //   var defaultIcon = {
  //     url:
  //       "http://chart.googleapis.com/chart?chst=d_map_spin&chld=1.15|0|0091ff|40|_|%E2%80%A2", // url
  //     scaledSize: new this.props.google.maps.Size(20, 30), // scaled size
  //   };
  //   var highlightedIcon = {
  //     url:
  //       "http://chart.googleapis.com/chart?chst=d_map_spin&chld=1.15|0|FFFF24|40|_|%E2%80%A2", // url
  //     scaledSize: new this.props.google.maps.Size(20, 30), // scaled size
  //   };
  //   return stores?.map((place, index) => {
  //     return (
  //       <div
  //         onClick={() =>
  //           this.props.showOnly ? null : this.onMarkerClick(place)
  //         }
  //         key={index}
  //         id={index}
  //         position={{
  //           lat: place?.lat,
  //           lng: place?.lng,
  //         }}
  //         icon={
  //           selectedPropsId !== place.property_id
  //             ? defaultIcon
  //             : highlightedIcon
  //         }
  //       >
  //         asd
  //       </div>
  //     );
  //   });
  // }
  onServiceMakerClick = (place) => {
      
      this.setState({ showServiceCard: true,serviceData:place });
    
  };
  onMarkerClick = (place) => {
    if (this.props?.selectedPlace?.property_id) {
      if (this.props?.selectedPlace?.property_id != place?.property_id) {
        this.setState({ showCard: true });
        this.props.onMarkerSelect(place?.property_id);
      } else {
        this.setState({ showCard: false });
        this.props.onMarkerRemove();
      }
    } else {
      this.props.onMarkerSelect(place?.property_id);
      this.setState({ showCard: true });
    }
  };
  onClose = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
      });
    }
  };
  closeCard = () => {
    this.setState({
      showCard: false,
      selectedPlace: "",
      activeMarker: "",
      showingInfoWindow: false,
      showServiceCard:false
    });
  };
  onGoogleApiLoaded = (map, maps) => {
    // Construct the polygon.
    try {
      console.log("this.props.polygon[0]", this.props.polygon[0]);
      var polygon = new maps.Polygon({
        paths: this.props.polygon[0],
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.35,
      });
      polygon.setMap(map);
    }catch (e){
      console.log("could not set polygon on the map ")
    }
  };

  onBoundsChanged = () => {
    var isFullScreen =
      document.fullScreen ||
      document.mozFullScreen ||
      document.webkitIsFullScreen;
    this.setState({
      isMapExpanded: isFullScreen,
    });
  };

  render() {
    const { data, searchObject, selectedPlace, schools, transports, searchMapData, polygon } = this.props;
    console.log("polygon", polygon);
    console.log("polygon", polygon);
    const showCard = this.state.showCard;
    const isMapExpanded = this.state.isMapExpanded;

    const places = data?.map((place) => {
      const { property_id, price, ...coords } = place;
      return (
        <div
          onClick={() =>
            this.props.showOnly ? null : this.onMarkerClick(place)
          }
          key={property_id}
          {...coords}
          style={{
            backgroundColor: "white",
            height: 20,
            width: this.props.showOnly ? 80 : 50,
            borderRadius: 10,
            boxShadow: "1px 1px 1px #9E9E9E",
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Text
            style={{
              fontSize: 11,
              textAlign: "center",
              fontWeight: "bold",
              color: "black",
            }}
          >
            {this.props.showOnly ? this.props.price : price}
          </Text>
        </div>
      );
    });
    const homeMarker = () => {
      return (
        <div
          lat={searchObject?.lat}
          lng={searchObject?.lon}
          style={{ width: 30, height: 30, backgroundColor: "pink" }}
        >
          <Image
            source={{ uri: "https://i.imgur.com/lSZQNzu.png" }}
            style={{ width: 30, height: 30 }}
          />
        </div>
      );
    };
    const schoolColorCheck=(schooltype,phase)=>{
      if(schooltype=="independent"){
        return "800080"
      }
      else {
        if(phase=="Primary"){
          return "00782A"
        }
        else{
         return "003688"
        }
      }
    }
    const transportType=(type)=>{
      if(type=="train_station"){
        return '0098D4'
      }
      else if(type=="tram_stop"){
        return '84B817'
      }
      else {
        return '9B0056'
      }
    }
    const schoolMarker = schools?.map((school) => {
    
      return (
        <div
          lat={parseFloat(school.lat) + 0.01}
          lng={parseFloat(school.lng)+0.01}
          style={{ width: 42, height: 42 }}
        >
           <Image
            source={{uri:"https://img.icons8.com/ios-filled/50/"+schoolColorCheck(school?.schoolType,school?.phase)+"/marker.png"}}
            style={{ width: 42, height: 42 }}
          />
        </div>
      );
    });
    const transportMarker = transports?.map((item) => {
      return (
        <div
          lat={parseFloat(item.latitude + 0.005)}
          lng={parseFloat(item.longitude) + 0.005}
          style={{ width: 42, height: 42 }}
        >
         <Image
            source={{uri:"https://img.icons8.com/ios-filled/50/"+transportType(item?.type)+"/marker.png"}}
            style={{ width: 42, height: 42 }}
          />
        </div>
      );
    });

    return (
      <Container style={{ position: "relative" }}>
        <GoogleMap
          bootstrapURLKeys={{
           // key: "AIzaSyAYGl-c0UaC0NYwwKTEju3NGGHTYmrPWfQ",
            key: "AIzaSyCVqJ_a6VwkCi7_qHFvx0K5n1-tExiaLmQ",
            // v: '3.31',
          }}
          center={[
            searchObject?.lat ? searchObject?.lat : data && data[0].lat,
            searchObject?.lon ? searchObject?.lon : data && data[0].lng,
          ]}
          defaultZoom={17}
          hoverDistance={40 / 2}
          //onBoundsChange={this._onBoundsChange}
          // onChildClick={this._onChildClick}
          // onChildMouseEnter={this._onChildMouseEnter}
          // onChildMouseLeave={this._onChildMouseLeave}
          onGoogleApiLoaded={({ map, maps }) =>
            this.onGoogleApiLoaded(map, maps)
          }
          id="abc"
          onBoundsChange={this.onBoundsChanged}
        >
          {/* {homeMarker} */}
          {schoolMarker}
          {transportMarker}
          {this.props.showOnly ? null : (
            <div lat={searchObject?.lat ? searchObject?.lat : data && data[0].lat} lng={searchObject?.lon ? searchObject?.lon : data && data[0].lng}>
              <Image
                source={{ uri: "https://i.imgur.com/lSZQNzu.png" }}
                style={{ width: 30, height: 30 }}
              />
            </div>
          )}
          {schools || transports?null: places}
          {showCard && isMapExpanded ? (
            <div
              className="mapboxview"
              style={{
                backgroundColor: "white",
                height: "283px",
                width: "362px",
                borderRadius: 10,
                boxShadow: "1px 1px 1px #9E9E9E",
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Card
                data={this.props.selectedPlace}
                navigate={this.props.navigate}
                onSave={this.props.onSave}
                searchObject={this.props.searchObject}
                showCloseIcon={true}
                closeCard={this.closeCard}
              />
            </div>
          ) : null}
           
        </GoogleMap>
        {showCard && !isMapExpanded ? (
          <View
            style={{
              position: "absolute",
              bottom: 10,
              width: "90%",
              left: "5%",
              height: 220,
            }}
          >
            <Card
              data={this.props.selectedPlace}
              navigate={this.props.navigate}
              onSave={this.props.onSave}
              searchObject={this.props.searchObject}
              showCloseIcon={true}
              closeCard={this.closeCard}
            />
          </View>
        ) : null}
        {this.state.showServiceCard ? (
         <div
         className="mapboxview"
         style={{
           backgroundColor: "transparent",
           position:'absolute',
           bottom:0,
           height: "243px",
           width: "100%",
           borderRadius: 10,
           boxShadow: "1px 1px 1px #9E9E9E",
           justifyContent: "center",
           display: "flex",
           alignItems: "center",
         }}
         >
         <div
              className="mapboxview"
              style={{
                backgroundColor: "transparent",
                
                height: "243px",
                width: "362px",
                borderRadius: 10,
                boxShadow: "1px 1px 1px #9E9E9E",
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
              }}
            >
              <ServiceCard
                data={this.state.serviceData}
                navigate={this.props.navigate}
                onSave={this.props.onSave}
                searchObject={this.props.searchObject}
                showCloseIcon={true}
                colorCode={schoolColorCheck(this.state.serviceData?.schoolType,this.state.serviceData?.phase)}
                closeCard={this.closeCard}
              />
            </div>
            </div>
          ) : null}
        
      </Container>
    );
  }
}

export default GoogleMaps;
