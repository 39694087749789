import React from "react";
import styled from "styled-components/native";
import getPlatformType from "../../helpers/getPlatform";

const layoutType = getPlatformType();
let ClickHereImage,
 MainContainer,
 CardContainer,
  IntroText;

if (layoutType == "phone") {
 CardContainer = styled.View`
  width: 300px;
  height:320px;
  padding-horizontal:10px;
  padding-vertical:15px;
  `;
  ClickHereImage=styled.Image`
   width:100%;
   height:80px;
   margin-top:50px;
   resize-mode:contain;
  `;
  MainContainer = styled.View`
  width: 100%;
  justify-content:center;
  align-items:center;
  `;
  IntroText = styled.Text`
   width:80%;
   margin-top:25px;
   color:black;
   font-size:14px;
   font-weight:bold;
   text-align:center;
   font-family:Lato-Bold;
  `;
} else if (layoutType == "web") {
  CardContainer = styled.View`
  width: 300px;
  height:320px;
  padding-horizontal:10px;
  padding-vertical:15px;
  `;
  ClickHereImage=styled.Image`
   width:60%;
   height:60px;
   margin-top:50px;
   resize-mode:contain;
   align-self:center;
  `;
  IntroText = styled.Text`
  width:55%;
  margin-top:20px;
  color:black;
  font-size:18px;
  font-weight:bold;
  text-align:center;
  font-family:Lato-Bold;
 `;
  MainContainer = styled.View`
  width: 720px;
  justify-content:center;
  align-items:center;
  `;
} else {
  CardContainer = styled.View`
  width: 270px;
  height:320px;
  padding-horizontal:10px;
  padding-vertical:15px;
  `;
  ClickHereImage=styled.Image`
   width:90%;
   height:60px;
   margin-top:50px;
   resize-mode:contain;
   align-self:center;
  `;
  IntroText = styled.Text`
  width:50%;
  margin-top:20px;
  color:black;
  font-size:18px;
  font-weight:bold;
  text-align:center;
  font-family:Lato-Bold;
 `;
  MainContainer = styled.View`
  width: 640px;
  justify-content:center;
  align-items:center;
  `;
  
}

export {
  IntroText,
  ClickHereImage,
  MainContainer,
  CardContainer
};
