import React, { Component } from "react";
import {
  View,
  Text,
  Dimensions,
  SafeAreaView,
  Image,
  TouchableOpacity,
  Platform,
  ScrollView,
  FlatList,
  TextInput,
  Linking,
} from "react-native";
import Footer from "../../commonComponents/footerComponent/customFooter";

import { MaterialIcons } from "@expo/vector-icons";
import getPlatformType from "../../helpers/getPlatform";
import Header from "../../commonComponents/headerComponent/headerComponent";
import {
  Container,
  LeftPanal,
  BackTextWrapper,
  ContentWrapper,
  Content,
  Wrapper,
  SpacingView,
  TopContainer,
  NotificationTitle,
  NotificationData,
  NotificationProperty,
  BottomContainer,
  BottomContainerInner,
  NotificationText,
  NotificationPara,
  NotificationDataList,
  NotificationDataItem,
  NotificationDataItemValue,
} from "./styles";
import WideButton from "../../commonComponents/wideButton";
const layoutType = getPlatformType();
import { login, getData } from "../../redux/actions/auth";
import { logError } from "../../redux/actions/Ladger";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import CustomTextInput from "../../commonComponents/customTextInput/customTextInput";
import NoDataNotification from "../NoDataFound/noData"; // "../NoDataFound/noData";
import { Entypo } from "@expo/vector-icons";
import { NotificationBox } from "./myAccountStyles";
// export default class ReportIssue extends Component {
class NotificationDetails extends Component {
  render() {
    const { navigation } = this.props;

    return (
      <SafeAreaView
        style={{
          flex: 1,
          backgroundColor: "#fafafa",
        }}
      >
        <Header
          MenuAccountWeb
          showLogoWeb
          showLogoTab
          showBackButtonMob
          showBackButtonTab
          {...this.props}
        />
      <Container>
          {layoutType === "web" && (
            <View
              style={{
                flexDirection: "column",
                width: "100%",
                alignItems: "center",
              }}
            >
              <View style={{ width: 1130, paddingVertical: 10 }}>
                <LeftPanal>
                  <TouchableOpacity
                    onPress={() => {
                      this.props.navigation.goBack();
                    }}
                  >
                    <BackTextWrapper>
                      <Entypo
                        name="chevron-left"
                        size={18}
                        color="#000"
                        style={{}}
                      />{" "}
                      Back
                    </BackTextWrapper>
                  </TouchableOpacity>
                </LeftPanal>
              </View>
            </View>
          )}
          <TopContainer>
            <NotificationTitle>Marketplace Notification</NotificationTitle>
            <NotificationData>
              <NotificationDataList
                style={{ borderBottomWidth: 1, borderBottomColor: "#979797" }}
              >
                <NotificationDataItem>Date Opened</NotificationDataItem>
                <NotificationDataItemValue
                  style={{ color: "#e71e82", fontWeight: 500 }}
                >
                  22/04/20
                </NotificationDataItemValue>
              </NotificationDataList>
              <NotificationDataList
                style={{ borderBottomWidth: 1, borderBottomColor: "#979797" }}
              >
                <NotificationDataItem>Company</NotificationDataItem>
                <NotificationDataItemValue>
                  Company Name
                </NotificationDataItemValue>
              </NotificationDataList>
              <NotificationDataList style="">
                <NotificationDataItem></NotificationDataItem>
                <NotificationDataItemValue>
                  Notification Title
                </NotificationDataItemValue>
              </NotificationDataList>
            </NotificationData>
            {layoutType != "phone" && (
              <NotificationProperty>
                <Image
                  style={{ height: 70, width: 110 }}
                  source={require("../../assets/images/mediaDummy.png")}
                />
                <View
                  style={{
                    paddingTop: 10,
                    paddingBottom: 10,
                    paddingRight: 20,
                    paddingLeft: 20,
                  }}
                >
                  <Text
                    style={{ padding: 2, color: "#e71e82", fontWeight: 500 }}
                  >
                    35Maygroveroad,NW6
                  </Text>
                  <Text style={{ padding: 2 }}>5 bedroom, detached house</Text>
                </View>
              </NotificationProperty>
            )}
          </TopContainer>
          <BottomContainer>
            <BottomContainerInner>
              <NotificationText>Notification Information</NotificationText>
              <NotificationPara>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
                dolor in reprehenderit in voluptate velit esse cillum dolore eu
                fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                proident, sunt in culpa qui officia deserunt mollit anim id est
                laborum.
              </NotificationPara>
            </BottomContainerInner>
          </BottomContainer>
          <View>
            {layoutType === "phone" && (
              <NotificationProperty>
                <Image
                  style={{ height: 70, width: 110 }}
                  source={require("../../assets/images/mediaDummy.png")}
                />
                <View
                  style={{
                    paddingTop: 10,
                    paddingBottom: 10,
                    paddingRight: 20,
                    paddingLeft: 20,
                  }}
                >
                  <Text
                    style={{ padding: 2, color: "#e71e82", fontWeight: 500 }}
                  >
                    35Maygroveroad,NW6
                  </Text>
                  <Text style={{ padding: 2 }}>5 bedroom, detached house</Text>
                </View>
              </NotificationProperty>
            )}
          </View>
        </Container>>
        {layoutType !== "phone" ? <Footer {...this.props} /> : null}
      </SafeAreaView>
    );
  }
}

export default NotificationDetails;
