import React, { useEffect } from 'react';
import { View, Text, Platform, StyleSheet } from 'react-native';
import { Button } from '../../atoms/button';
import { useDispatch, useSelector } from 'react-redux';
import CustomTextInput from '../../../../commonComponents/customTextInput/customTextInput';
import DropDownPicker from '../../../../commonComponents/DropDownPicker';
import { types } from '../../../../redux/actionTypes';
import getPlatformType from '../../../../helpers/getPlatform';
import { openFlow } from '../../../../constants/openFlo';
import { COLORS } from '../../../../constants/colorPallete';
import { tenantActions } from '../../openFlowRedux/tenant.actions';
import { MainSection, Typography } from '../../style';
import Regex from '../../../../helpers/regex';
import { BoxShadow } from '../../../tds/style';
import { ODSText } from '../../../../../openbrix-design-system/ui/atoms/ODSText';
import ODSButton from '../../../../../openbrix-design-system/ui/atoms/ODSButton';
import { ODSInput } from '../../../../../openbrix-design-system/ui/atoms/ODSInput';
import { getTokens } from '../../../../../openbrix-design-system/design-tokens/color-tokens';
const layoutType = getPlatformType();
const Gurantor = (props) => {
  const { teanant, flowId } = props;
  const [Id, setFlowId] = React.useState(null);
  const [teanants, setUser] = React.useState(teanant);
  const data = useSelector((state) => state.authReducer?.profile?.data?.appUser);
  const dispatch = useDispatch();

  const _onSave = () => {
    if (!teanants?.open_flow_guarantor?.first_name) {
      dispatch({
        type: types.SHOW_ALERT,
        alertType: 'error',
        text: 'First name is required',
      });
    } else if (!teanants?.open_flow_guarantor?.correspondece_address) {
      dispatch({
        type: types.SHOW_ALERT,
        alertType: 'error',
        text: 'Correspond address is required',
      });
    } else if (!teanants?.open_flow_guarantor?.post_code) {
      dispatch({
        type: types.SHOW_ALERT,
        alertType: 'error',
        text: 'Postcode is required',
      });
    } else if (
      !teanants?.open_flow_guarantor?.email ||
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(teanants?.open_flow_guarantor?.email)
    ) {
      dispatch({
        type: types.SHOW_ALERT,
        alertType: 'error',
        text: 'Invalid email address',
      });
    } else if (!teanants?.open_flow_guarantor?.correspondece_address) {
      dispatch({
        type: types.SHOW_ALERT,
        alertType: 'error',
        text: 'Address is required',
      });
    } else if (
      teanants?.open_flow_guarantor?.number?.length < 7 ||
      !teanants?.open_flow_guarantor?.number
    ) {
      dispatch({
        type: types.SHOW_ALERT,
        alertType: 'error',
        text: 'Invalid contact number',
      });
    } else {
      const payload = {
        title: teanants?.open_flow_guarantor?.title,
        email: teanants?.open_flow_guarantor?.email,
        post_code: teanants?.open_flow_guarantor?.post_code,
        last_name: teanants?.open_flow_guarantor?.last_name,
        number: teanants?.open_flow_guarantor?.number,
        first_name: teanants?.open_flow_guarantor?.first_name,
        correspondece_address: teanants?.open_flow_guarantor?.correspondece_address,
        open_flow_sub_tenant_id: teanants?.open_flow_sub_tenant_id,
        open_flow_guarantor_id: teanants.open_flow_guarantor?.open_flow_guarantor_id,
      };
      dispatch(tenantActions.saveIdentityData({ gaurantor: payload }, Id,()=>{
setIsEdit(!isEdit)
      }));
    }
  };

  const handleChange = (value, label) => {
    let newArr = { ...teanants };
    newArr.open_flow_guarantor = {
      ...newArr.open_flow_guarantor,
      [label]: value,
    };
    setUser(newArr);
  };
  useEffect(() => {
    if (teanant) {
      setUser(teanant);
    }
  }, [teanant]);
  useEffect(() => {
    if (flowId) {
      setFlowId(flowId);
    }
  }, [flowId]);
  const themeObj = getTokens('light').colors;

  const [isEdit, setIsEdit] = React.useState(false);
  const renderViewOnlyForm = () => {
    return (
      <View style={{ flexDirection: 'column'}}>
        
        <View style={[styles.rowFlexView, { borderWidth: 0 }]}>
          <View style={styles.rowItem}>
            <ODSText color="black" type={'button'}>
              Title
            </ODSText>
            <ODSText marginTop={8} color="black" type={'sm'}>
              {teanants?.open_flow_guarantor?.title}
            </ODSText>
          </View>
          <View style={styles.rowItem}>
            <ODSText color="black" type={'button'}>
              First Name
            </ODSText>
            <ODSText marginTop={8} color="black" type={'sm'}>
              {teanants?.open_flow_guarantor?.first_name}
            </ODSText>
          </View>
        </View>

        <View style={[styles.rowFlexView, { borderWidth: 0 }]}>
          <View style={styles.rowItem}>
            <ODSText color="black" type={'button'}>
              Last Name
            </ODSText>
            <ODSText marginTop={8} color="black" type={'sm'}>
              {teanants?.open_flow_guarantor?.last_name}
            </ODSText>
          </View>
          <View style={styles.rowItem}>
            <ODSText color="black" type={'button'}>
              Contact Number
            </ODSText>
            <ODSText marginTop={8} color="black" type={'sm'}>
              {teanants?.open_flow_guarantor?.number}
            </ODSText>
          </View>
        </View>
        <View style={[styles.rowFlexView, { borderWidth: 0 }]}>
          <View style={styles.rowItem}>
            <ODSText color="black" type={'button'}>
              Current Address
            </ODSText>
            <ODSText marginTop={8} color="black" type={'sm'}>
              {teanants?.open_flow_guarantor?.correspondece_address}
            </ODSText>
          </View>
          <View style={styles.rowItem}>
            <ODSText color="black" type={'button'}>
              Post Code
            </ODSText>
            <ODSText marginTop={8} color="black" type={'sm'}>
              {teanants?.open_flow_guarantor?.post_code}
            </ODSText>
          </View>
        </View>
        <View style={[styles.rowFlexView, { borderWidth: 0 }]}>
          <View style={styles.rowItem}>
            <ODSText color="black" type={'button'}>
              Email Address
            </ODSText>
            <ODSText marginTop={8} color="black" type={'sm'}>
              {teanants?.open_flow_guarantor?.email}
            </ODSText>
          </View>
        </View>
           {teanants?.is_guarantor_information_requested ||
        teanants?.open_flow_guarantor?.open_flow_guarantor_id ? ( <View
          style={{
            flexDirection: layoutType === 'phone' ? 'column' : 'row',
            width: layoutType !== 'phone' ? '100%' : '100%',
            alignItems: layoutType !== 'phone' ? 'center' : 'normal',
            justifyContent: 'space-between',
            marginTop: 20,
          }}
        >
          <View style={{ flex: 1 }}>
            <ODSButton
              rIcon={'TB-Edit'}
              restStyle={{
                width: layoutType == 'phone' ? '100%' : 300,
                alignSelf: 'flex-end',
                marginTop: 30,
              }}
              type="secondary"
              disabled={false}
              onPress={() => setIsEdit(true)}
            >
              {'Edit'}
            </ODSButton>
          </View>
        </View>):null}
      </View>
    );
  };
  const renderEditForm = () => {
    return (
      <View style={{ flexDirection: 'column' }}>
        <View
          style={{
            marginBottom: 10,
            width: '100%',
          }}
        >
          <ODSText
            type="sm-bold"
            color={!isEdit ? themeObj.disabled['text'] : themeObj.text['primary']}
            paddingVertical={4}
          >
            Title
          </ODSText>
          <DropDownPicker
            items={openFlow?.titles.map((salutation) => {
              return { value: salutation, label: salutation };
            })}
            id="openflo-Title"
            placeholder="Title"
            defaultValue={teanants?.open_flow_guarantor?.title}
            onChangeItem={(e) => {
              handleChange(e?.value, 'title');
        
            }}
            dropDownStyle={{ backgroundColor: 'white' }}
            zIndex={1001}
           
          />
        </View>

        <ODSInput
          placeholder="First Name"
          label="First Name"
          value={teanants?.open_flow_guarantor?.first_name}
       
          width={'100%'}
          onChange={(value) => {
            handleChange(value, 'first_name');
          }}
          hints={
            teanants?.open_flow_guarantor?.firstName
              ? []
              : [{ hint: 'First Name is a required Field.', state: 'error' }]
          }
          error={teanants?.open_flow_guarantor?.firstName ? false : true}
        />
        <ODSInput
          placeholder="Last Name"
          label="Last Name"
          value={teanants?.open_flow_guarantor?.last_name}
     
          width={'100%'}
          onChange={(value) => {
            handleChange(value, 'last_name');
          }}
          hints={
            teanants?.open_flow_guarantor?.lastName ? [] : [{ hint: 'Last Name is a required Field.', state: 'error' }]
          }
          error={teanants?.open_flow_guarantor?.lastName ? false : true}
        />
        <ODSInput
          placeholder="Contact Number"
          label="Contact Number"
          value={teanants?.open_flow_guarantor?.number}
         
          width={'100%'}
          onChange={(value) => {
            handleChange(value.replace(/[^0-9+]/g, ''), 'number');
          }}
        />
        <ODSInput
          placeholder="Email Address"
          label="Email address"
          value={teanants?.open_flow_guarantor?.email}
         
          width={'100%'}
          onChange={(value) => {
            handleChange(value, 'email');
          }}
        />

        <ODSInput
          placeholder="Correspondece Address"
          label="Correspondece Address"
          value={teanants?.open_flow_guarantor?.correspondece_address}
        
          width={'100%'}
          onChange={(value) => {
            handleChange(value, 'correspondece_address');
          }}
        />
        <ODSInput
          placeholder="Post Code"
          label="Post Code"
          value={teanants?.open_flow_guarantor?.post_code}
       
          width={'100%'}
          onChange={(value) => {
            handleChange(value, 'post_code');
          }}
        />
        {teanants?.is_guarantor_information_requested ||
        teanants?.open_flow_guarantor?.open_flow_guarantor_id ? (
          <View
            style={{
              flex: 1,
            }}
          >
            <ODSButton
              restStyle={{
                width: layoutType == 'phone' ? '100%' : 300,
                alignSelf: 'flex-end',
                marginTop: 30,
              }}
              type="primary"
              disabled={false}
              onPress={() => _onSave()}
            >
              Save Information
            </ODSButton>
          </View>
        ) : null}
      </View>
    );
  };

  return (
    <MainSection
      style={{
        flexDirection: layoutType == 'phone' ? 'column' : 'row',
        justifyContent: 'space-between',
        marginTop: 0,
      }}
    >
      <BoxShadow style={{ padding: 10 }}>
        <View style={{ padding: 20 }}>
          <ODSText marginTop={10} type={'h5'}>
            Guarantor Information
          </ODSText>
          <ODSText marginTop={20} marginBottom={20} type={'sm'}>
            If it is requested in the future, you will be expected to provide the information below.
          </ODSText>

          {true ? (!isEdit ? renderViewOnlyForm() : renderEditForm()) : null}
        </View>
      </BoxShadow>
    </MainSection>
  );
};
const styles = StyleSheet.create({
  rowFlexView:
    layoutType === 'phone'
      ? {
          flexDirection: 'row',
          alignItems: 'center',
          borderBottomWidth: 1,
          borderColor: '#D9D9D9',
          marginVertical: 10,
        }
      : {
          flexDirection: 'row',
          alignItems: 'center',
          borderBottomWidth: 1,
          borderColor: '#D9D9D9',
          marginVertical: 10,
        },
  centeredView: {
    flex: 1,
    padding: 20,
    marginTop: 22,
    backgroundColor: 'white',
    borderRadius: 20,
  },
  whiteColor12Regular: { color: '#FFFFFF', fontSize: 12 },
  rowItem: { flexDirection: 'column', flex: 5, alignItems: 'flex-start' },
});
export default Gurantor;
