import { store } from "../../../redux/store/setup";


export function authHeader() {
    // return authorization header with jwt token
    let token =  store?.getState().appReducer?.loginData?.data?.token;
    if (token) {
        return { 'Authorization': 'Bearer ' + token };
    } else {
        return {};
    }
}