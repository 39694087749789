import { ACCEPT_GA_COOKIES, SHOW_BANNER_COOKIES, SHOW_BANNER_CUSTOMISE, SAVE_COOKIES_PREFERENCES, SET_CUSTOMISE_COOKIES } from "../../../constants/index";

const initialState = {
  accept_cookies: true,
  show_banner_cookies: true,
  show_banner_customise: false,
  userHasSavedCookiesPreferences: false,
  customiseCookies:{
    Essential:false,
    Analytics:false,
    Functional:false,
    Advertising:false,
    Personalisation:false
  }
};

const cookiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_BANNER_COOKIES:
      return {
        ...state,
        show_banner_cookies: action.payload,
      };
    case SHOW_BANNER_CUSTOMISE:
      return {
        ...state,
        show_banner_customise: action.payload,
      };
    case ACCEPT_GA_COOKIES:
      return {
        ...state,
        accept_cookies: action.payload,
      };
    case SAVE_COOKIES_PREFERENCES:
      return {
        ...state,
        userHasSavedCookiesPreferences: action.payload,
      };
     case SET_CUSTOMISE_COOKIES:
        return {
          ...state,
          customiseCookies: action.payload,
        };
    default:
      return state;
  }
};
export default cookiesReducer;
