import React, { useState } from "react";
import {
  Image,
  TouchableOpacity,
  View,
  FlatList,
  Text,
  SafeAreaView,
} from "react-native";
import { Video } from "expo-av";
import { MaterialIcons } from "@expo/vector-icons";
import getPlatformType from "../../helpers/getPlatform";
const layoutType = getPlatformType();
const Slider = (props) => {
  const [index, SetIndex] = useState(0);

  const {
    data,
    borderTopRightRadius,
    videoHeight,
    imageArray,
    videoUrl,
  } = props;
  const [sliderData, setSliderData] = useState([]);
  React.useEffect(() => {
    let newData = [];
    if(imageArray){
      newData = newData.concat(imageArray)
    }else if(videoUrl){
      newData.push(videoUrl)
    }
    setSliderData(newData);
  }, []);
  const _renderItem = () => {
    
    

    if (sliderData[index].indexOf("youtube.com") != "-1") {
      return (
        <iframe
          width="100%"
          height="100%"
          src={
            sliderData[index] + "?rel=0&modestbranding=1&autohide=1&showinfo=0"
          }
          frameBorder="0"
          allow="accelerometer autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      );
    } else if (sliderData[index].indexOf(".mp4") != "-1") {
      return (
        <Video
          source={{ uri: sliderData[index] }}
          progressUpdateIntervalMillis={500}
          positionMillis={0}
          shouldPlay={false}
          rate={1.0}
          shouldCorrectPitch={false}
          volume={1.0}
          isMuted={false}
          isLooping={false}
          resizeMode={Video.RESIZE_MODE_COVER}
          useNativeControls={true}
          style={{
            flex: 1,
            backgroundColor: "black",
            justifyContent: "center",
          }}
        />
      );
    } else {
      
      return(
        <Image
          source={{ uri: sliderData[index] }}
          defaultSource={{uri: "https://assets.openbrix.co.uk/nophoto.jpg"}}
          style={{
            width: "100%",
            height: "100%",
            borderTopRightRadius: borderTopRightRadius
              ? borderTopRightRadius
              : 20,
          }}
        />

      )
    }
  };
  
  return (
    <View
      style={{
        width: "100%",
        height: "100%",
        justifyContent: "center",
      }}
    >
      <View style={{ width: "100%", height: "100%" }}>
        {sliderData.length > 0 ? (
          _renderItem()
        ) : (
          <Image
            source={{ uri: "https://assets.openbrix.co.uk/nophoto.jpg" }}
            style={{
              width: "100%",
              height: "100%",
              borderTopRightRadius: borderTopRightRadius
                ? borderTopRightRadius
                : 20,
            }}
          />
        )}
      </View>
      {index == 0 ? null : (
        <TouchableOpacity
          onPress={() => SetIndex(index - 1)}
          style={{ position: "absolute", left: 10 }}
        >
          <MaterialIcons name="navigate-before" size={28} color="white" />
        </TouchableOpacity>
      )}
      {index == sliderData.length - 1 ? null : (
        <TouchableOpacity
          onPress={() => SetIndex(index + 1)}
          style={{ position: "absolute", right: 10 }}
        >
          <MaterialIcons name="navigate-next" size={28} color="white" />
        </TouchableOpacity>
      )}
    </View>
  );
};

export default Slider;
