import React, { useState } from 'react';
import {
    Text,
    StyleSheet,
    TouchableOpacity,
    View,
    ImageBackground,
    Image
} from 'react-native';
import { ODSIcon } from '../ODSIcon';
import { ODSText } from '../ODSText';
import getScreenType from '../../../utils/getScreenType';
import { useTheme } from '@emotion/react';
import ODSButton from '../ODSButton';

const ODSRentReadyTile = ({
    rentReadyData,
    onPress,
}) => {
    const theme = useTheme();
    const getIcon = (type) => {
        if (type == 2) {
            return (
                <View style={{ width: 20, height: 20, borderRadius: 20, backgroundColor: theme.colors.feedback.main, justifyContent: 'center', alignItems: 'center' }}>
                    <ODSIcon color='white' icon="TB-Check" size={12} />
                </View>
            )
        }
        else if (type == 1) {
            return (
                <ODSIcon color={theme.colors.feedback.warning[400]} icon="TB-Rotating" size={24} />
            )
        } else {
            return (
                <View style={{ width: 20, height: 20, borderRadius: 20, backgroundColor: theme.colors.disabled.text, justifyContent: 'center', alignItems: 'center' }}>
                    <ODSIcon color='white' icon="TB-Check" size={12} />
                </View>
            )
        }
    }

    return (
        <View style={[styles(theme.colors).containerStyle]}>
            <Image source={require('../../../images/dashboard/tiles/rr.png')} style={{ width: '100%', height: 109, borderTopLeftRadius: 12, borderTopRightRadius: 12 }} />
            <ODSText type='h3' left={24} top={12} color={theme.colors.text.inverted} zIndex={1000} position='absolute'>Rent{`\n`}Ready</ODSText>
            <View style={{ padding: 24 }}>
                <ODSText type='sm' color={theme.colors.text.primary}>Be a more desirable tenant by using our pre-qualification tool.</ODSText>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', marginTop: 24, marginBottom: 12 }}>
                    {rentReadyData.map((e, index) =>
                        <TouchableOpacity onPress={e.onPress} key={index} style={{ flexDirection: 'row', alignItems: 'center', alignSelf: 'flex-start', marginBottom: 16 }}>
                            {getIcon(e.state)}
                            <ODSText textDecorationLine={'underline'} marginLeft={8} type='md' color={theme.colors.text.primary}>{e.title}</ODSText>
                        </TouchableOpacity>
                    )}
                </View>
                <ODSButton
                    restStyle={{ alignSelf: 'center' }}
                    type="primary"
                    disabled={false}
                    onPress={onPress}
                >
                    Go to Rent Ready
                </ODSButton>
            </View>
        </View>
    );
};

const styles = (theme) =>
    StyleSheet.create({
        containerStyle: {
            width: getScreenType() == "phone" ? "100%" : 360,
            borderRadius: 12,
            shadowColor: '#101828',
            shadowOffset: { width: 0, height: 1 },
            shadowOpacity: 0.1,
            shadowRadius: 4,
            elevation: 2,
            backgroundColor: theme.neutral[100],
            justifyContent: 'center',
            alignItems: 'center',
        },
        button: {
            height: 28,
            // paddingVertical: 12,
            paddingHorizontal: 10,
            justifyContent: 'center',
            backgroundColor: theme.feedback.error[700],
            alignItems: 'center',
            borderRadius: 1000,
            shadowColor: '#101828',
            shadowOffset: { width: 0, height: 1 },
            shadowOpacity: 0.1,
            shadowRadius: 4,
            elevation: 2,
            flexDirection: 'row',

        }
    });

export default ODSRentReadyTile;
