import React from 'react';
import styled from '@emotion/native';
interface ComboboxItemProps {
    value: any;
    selectedValue: any;
    action: () => void;
    children: React.ReactNode;
     height?:number;
    backgroundColor?: string;
    selectedBackgroundColor?: string;
  }
  
const ComboboxItemContainer = styled.TouchableOpacity<
  Omit<ComboboxItemProps, 'selectedValue' | 'action' | 'children'>
>`
  flex-direction: row;
  align-items: center;
  padding-horizontal: 10px;
  position: relative;
  background-color: ${(props) =>
    props.value
      ? props?.selectedBackgroundColor || props.theme.colors.hover['bg-neutral']
      : props?.backgroundColor || 'transparent'};
    height:${props=>props.height?`${props.height}px`:'44px'};
`;

export function ComboboxItem({
  value,
  selectedValue,
  action,
  children,
  backgroundColor,
  selectedBackgroundColor,
  height,
  ...props
}: ComboboxItemProps) {
  return (
    <ComboboxItemContainer
      backgroundColor={backgroundColor}
      selectedBackgroundColor={selectedBackgroundColor}
      onPress={action}
      height={height}
      value={selectedValue.value === value.value}
      {...props}
    >
      {children}
    </ComboboxItemContainer>
  );
}