import React, { useCallback, useEffect, useRef, useState } from "react";
import { Dimensions, ImageBackground, View } from "react-native";
import stylesMobile from "./stylesMobile";
import stylesTab from "./stylesTab";
import stylesWeb from "./stylesWeb";
import { backgroundSearchArr } from "../images/tlyfe/searchArrayTlyfe";
import BackGroundGenerator from "../common/BackGroundGenerator";
import { navigateAction } from "../../../helpers/navigationHelpers";
import { useSelector } from "react-redux";
import { Typography } from "../../openFlow/style";
import { COLORS } from "../../../constants/colorPallete";
import getPlatformType from "../../../helpers/getPlatform";
import CommonAuth from "./CommonAuth";
import ImageView from "./imageView";

const LandingPrequalification = (props) => {

  const scollToRef = useRef();
  const FEATURED = [
    {
      heading: "Affordability",
      heading2: "Understand how much rent you can afford. ",
      subheading:
        "Affordability is the first step towards becoming a ‘Rent-Ready’ Tenant.  Affordability is based upon your annual income, which can include salary, pension and savings.",
      imageUrl: require("./images/prequalImages/aff.png"),
    },
    {
      heading: "Right to Rent",
      heading2: "You are required by law to prove your ‘right to rent’ in England",
      subheading:
        "Using our quick and easy to follow process you can validate your right to rent at the click of a button, ideal for overseas Tenants!",
      imageUrl: require("./images/prequalImages/rtr.png"),
    },
    {
      heading: "ID Verification",
      heading2: "Verify your ID and share it securely with your Agent or Landlord",
      subheading:
        "Once you have your ID verified you can share your verified identity as many times as you like. You decide who sees this information, giving you complete control!",
      imageUrl: require("./images/prequalImages/idVer.png"),
    },
    {
      heading: "Rent Ready Reference",
      heading2: "Validate that you are ‘Rent-Ready’ and give yourself a head start in the rental process.",
      subheading:
        "Before viewing properties, you can present your agent or landlord with evidence of your affordability and confirmation that you meet the criteria required to rent a property, all verified by our trusted partner!",
      imageUrl: require("./images/prequalImages/prequal.png"),
      onButtonClick: () => {
        scollToRef.current.scrollIntoView({ behavior: "smooth" });
      },
      buttonText: "CLICK HERE TO REGISTER/LOGIN",
      buttonStyle: { marginTop: 30 },
    },
  ];

  const [type, setType] = useState("web");
  const scrollViewRef = useRef();

  const layoutType = getPlatformType();
  const isAnylyticsEnable = useSelector(
    (state) => state?.cookiesReducer?.customiseCookies.Analytics,
  );
  const { navigate } = props.navigation;
  const navigateTo = (params, from) => {
    if (from) {
      navigateAction(props?.navigation, params, from);
    } else {
      navigateAction(props?.navigation, params);
    }
  };
  const [property_for, setPropertyFor] = useState(1);
  const styles = type == "web" ? stylesWeb : type == "mobile" ? stylesMobile : stylesTab;
  const [state, setState] = useState({
    from: "",
  });
  const handleScrollButtonClick = useCallback(() => {
    const height=Dimensions.get('window').height;
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTo({ y:layoutType=='web'?height:height/2, animated: true });
    }
  }, []);
  useEffect(() => {
    if (props?.route?.params?.from) {
      setState({
        ...state,
        from: props?.route?.params?.from ? props?.route?.params?.from : "",
      });
    }
  }, [props?.route?.params?.from]);
  console.log("type", type);
  let BottomWrapper = type == "web" ? ImageBackground : View;
  return (
    <>

      <BackGroundGenerator
        props={props}
        layoutType={(data) => setType(data)}
        backgroundArr={backgroundSearchArr}
        isTLYF={true}
        isLanding
        cloudHeader={true}
        scrollViewRef={scrollViewRef}
      >
        <div
          ref={scollToRef}
        />
        <CommonAuth
          heading={"SECURE YOUR DREAM HOME WITH TLYFE"}
          navigation={navigate}
          type={type}
          onScrollButtonClick={handleScrollButtonClick}
          isTLYF={true}
          subheading={"Be Rent Ready. Pre-qualify yourself today!".toUpperCase()}
          desc={""}
          hideButton={false}
          {...props}
        />
        <View style={[styles.textContainer, { backgroundColor: "#FAFAFB" }]}>
          <View
            style={[
              styles.shawow,
              {
                width: "100%",
                flexDirection: "column",
                paddingHorizontal: "6%",
                borderRadius: 23,
                borderBottomWidth: 3,
                borderColor: COLORS.GREEN,
                alignItems: "center",
              },
            ]}
          >
            <Typography
              line={"35px"}
              raj
              bold={"600"}
              top={"40px"}
              color={"#1C1C1C"}
              size={"26px"}
              align={"center"}
              bottom={"40px"}
            >
              Give yourself the best chance of securing your next rental property by using our Pre-Qualification tool to
              become a ‘Rent Ready’ Tenant. Share the results with your Agent to put yourself ahead of the crowd!
            </Typography>
          </View>
        </View>

        {FEATURED.map((item, index) => {
          return (
            <ImageView
              key={index}
              reverse={index % 2 === 0 ? false : true}
              type={type}
              heading={item.heading}
              heading2={item.heading2}
              bColor={index % 2 === 0 ? "white" : "#FAFAFB"}
              subheading={item.subheading}
              imageUrl={item.imageUrl}
              onButtonClick={item.onButtonClick}
              buttonText={item?.buttonText}
              buttonStyle={item?.buttonStyle ? {
                ...item.buttonStyle,
                marginBottom: type != "web" ? 400 : 250,
              } : {}}
              isTLYF={true}

            />
          );
        })}

      </BackGroundGenerator>
    </>
  );
};
export default LandingPrequalification;
