import React from 'react';
import { View, StyleSheet,Image } from 'react-native';
import { Typography } from '../../../tds/style';
import { COLORS } from '../../../../constants/colorPallete';
const NameAvatar = ({ size, name ,verified=false}) => {
  const fontSize = size / 2;

  function generateInitials(name) {
    const names = name.split(' ').filter(Boolean);
    let initials = '';

    if (names.length > 0) {
      initials += names[0].charAt(0).toUpperCase();

      if (names.length > 1) {
        if (names[names.length - 1].length > 1) {
          initials += names[names.length - 1].charAt(0).toUpperCase();
        }
      }
    }

    return initials.slice(0, 2);
  }

  return (
    <>
    <View style={[styles.circle, { width: size, height: size, backgroundColor: COLORS.LGREEN }]}>
      <Typography bold={'700'} color={'#fff'} fontSize={fontSize}>
        {generateInitials(name)}
      </Typography>
    {verified &&  <Image source={require('../../../../assets/images/infohub/verified.png')} style={{width:20,height:20,position:'absolute',right:-8,top:-3}}/>}
    </View>
    </>
  );
};

const styles = StyleSheet.create({
  circle: {
    borderRadius: 999,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default NameAvatar;
