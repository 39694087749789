import React from "react";
import styled from "styled-components/native";
import { COLORS } from "../../constants/colorPallete";
import getPlatformType from "../../helpers/getPlatform";
const layoutType = getPlatformType();

let Container, Box, Title, Discription, WideButtonWrapper;
//
// ─── MOBILE SPECIFIC CSS ────────────────────────────────────────────────────────
//

if (layoutType == "phone") {
    Container = styled.View`
    width: 100%;
    margin:auto;
    align-items:center; 
    padding:20px 20px;
    background:#ffffff;
    border-radius:4px;
    text-align:center;
    `;
    Box = styled.View`
    align-items:center; 
    justify-content:center;
    text-align:center;
    `;
    Title = styled.Text`
    font-size:16px;
    font-weight:bold;
    color: ${COLORS.LGREEN};
    padding:24px 0 16px 0;
  `;
    Discription = styled.Text`
  color: #000000; 
  font-size: 15.3px;
  margin-bottom:32px;
  align-items:center; 
  justify-content:center;
  text-align:center;
  `;
    WideButtonWrapper = styled.View` 
  width: 300px;
  margin-bottom:10px;
  align-items: center;
  `;
}
//
// ─── WEB SPECIFIC CSS ───────────────────────────────────────────────────────────
//
else if (layoutType == "web") {
    Container = styled.View`
    width: 463px;
    margin:auto;
    align-items:center; 
    padding:32px 80px;
    background:#ffffff;
    border-radius:4px;
    `;
    Box = styled.View`
    align-items:center;
    text-align:center;
    `;
    Title = styled.Text`
    font-size:20px;
    font-weight:bold;
    color: ${COLORS.LGREEN};
    padding:24px 0 16px 0;
    width:100%;
  `;
    Discription = styled.Text`
  color: #000000; 
  font-size: 15.3px;
  margin-bottom:32px;
  width:100%;
  `;
    WideButtonWrapper = styled.View` 
  width: 300px;
  margin-bottom:10px;
  align-items: center;
  `;
}

//
// ─── TABLET SPECIFIC CSS ────────────────────────────────────────────────────────
//
else {
    Container = styled.View`
    width: 463px;
    margin:auto;
    align-items:center; 
    padding:32px 80px;
    background:#ffffff;
    border-radius:4px;
    `;
    Box = styled.View`
    align-items:center; 
    `;
    Title = styled.Text`
    font-size:18px;
    font-weight:bold;
    color: ${COLORS.LGREEN};
    padding:24px 0 16px 0;
    width:100%;
  `;
    Discription = styled.Text`
  color: #000000; 
  font-size: 16px;
  margin-bottom:32px;
  align-items:center; 
  justify-content:center;
  text-align:center;
  `;
    WideButtonWrapper = styled.View` 
  width: 300px;
  margin-bottom:10px;
  align-items: center;
  `;
}

export {
    Container, Box, Title, Discription, WideButtonWrapper
};
