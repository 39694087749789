import React, { useState, useEffect, useReducer } from "react";
import { Entypo } from "@expo/vector-icons";
import { View, Text, ScrollView } from "react-native";
import {
  Container,
  TabWrapper,
  MainContent,
  Row,
  Col,
  FilterInnerView,
  DualOptionalView,
  ErrorMsg,
  BackBtnBox,
  UpperContainer,
} from "./createAlertScreen.style";
import {
  BoxSelectorWrapper,
  FilterHeadingBox,
  SearchText,
  TitleWrapper,
  DropDownWrapper,
  DropDownSectionWrapper,
} from "../searchFilter/searchFilterStyles";
import { showToaster } from "../../redux/actions/toast";

import Header from "../../commonComponents/headerComponent/headerComponent";
import DropDownPicker from "./../../commonComponents/DropDownPicker";
import BubbleMultiSelectInput from "../../commonComponents/bubbleMultiSelectInput/bubbleMultiSelectInput";
import BoxSelector from "../../commonComponents/boxSelector/boxSelector";
import WideButton from "../../commonComponents/wideButton";
import Loader from "./../../commonComponents/activityIndicator/activityIndicator";

import { Tabs } from "../../commonComponents/TabComponent/Tabs";
import Footer from "../../commonComponents/footerComponent/customFooter";
import Form from "./form";
import CheckBox from "../../commonComponents/checkbox/checkbox";
import { LocationValidation } from "../../helpers/validation";
import { FilterConfigObject } from "./../../helpers/objectHelpers";
import getPlatformType from "../../helpers/getPlatform";
const layoutType = getPlatformType();

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import idx from "idx";
import {
  createAlert,
  getAlertById,
  updateAlert,
  clearAlert,
} from "../../redux/actions/createAlert";

const CreateAlertScreen = (props) => {
  const {
    dateListed,
    alertOptions,
    distances,
    boxCategoriesRent,
    boxCategoriesSale,
    authDataFromMobile
  } = FilterConfigObject;
  const { loading, alertByIdData, navigation, searchObject } = props;
  const [clearData, setClearData] = useState(false);
  const { alert_id = null } = props.route.params ? props.route.params : {};
  const accomodation_type_list = [
    "Detached",
    "Semi detached",
    "Terraced",
    "Flats",
    "Bungalow",
    "Other",
    "Farm/land",
  ];
  const initialState = {
    activeTab: "Tab1",
    showAlertPrefMsg: false,
    customDropdown: {
      isOpen: false,
      currentDropdownList: "",
      radius: "",
      dateListed: "",
      rentalTerm: "",
      currentDropdown: "",
    },
    rentData: {
      location: "",
      lat: "",
      lon: "",
      search_string: searchObject?.search,
      bedroomMin: "",
      bedroomMax: "",
      min_price: "",
      max_price: "",
      user_id: 0,
      accomodation_type: [...accomodation_type_list],
      under_offer: false,
      selectedIndex: [],
      LocationErrorMsg: false,
      furnishing: "",
      selectedCatIndex: [],
      hide: [],
    },

    globalData: {
      keywords: [],
      distance: "",
      dateListed: "",
      alertType: [],
      agreeToContact: false,

      bathroom_count: 0,
      garden: "",
      parking: "",
      property_for: "",
      retirementHomes: "",
      sharedOwnership: "",
      selectedAlertIndex: [],
    },
    buyerData: {
      location: "",
      lat: "",
      lon: "",
      search_string: searchObject?.search,
      bedroomMin: "",
      bedroomMax: "",
      min_price: "",
      max_price: "",
      user_id: 1,
      accomodation_type: [...accomodation_type_list],
      under_offer: false,
      selectedIndex: [],
      furnishing: "",
      LocationErrorMsg: false,
      selectedCatIndex: [],
      hide: [],
    },
  };
  const { property_for } = props.alertByIdData ? props.alertByIdData.data : {};
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      ...initialState,
    }
  );

  const updateData = (type, field, value) => {
    type = type !== "globalData" ? state.activeTab : type;
    if (type == "Tab2") {
      const { buyerData } = state;
      buyerData[field] = value;
      setState({
        buyerData,
      });
    } else if (type == "Tab1") {
      const { rentData } = state;
      rentData[field] = value;
      setState({
        rentData,
      });
    } else if (type == "globalData") {
      const { globalData } = state;
      globalData[field] = value;
      setState({
        globalData,
      });
    }
    if (field === "agreeToContact" || field === "alertType") {
      setState({
        showAlertPrefMsg: false,
      });
    }
  };
  const createAlertCall = () => {
    let data = state.activeTab == "Tab1" ? state.rentData : state.buyerData;
    data = { ...data, ...state.globalData };
    const property_for = state.activeTab == "Tab1" ? 1 : 0;
    const locationValidate = LocationValidation(data.search_string);
    let showAlertPrefMsg = false;
    if (data.alertType.length && !data.agreeToContact) {
      showAlertPrefMsg = true;
      setState({
        showAlertPrefMsg,
      });
    }
    if (locationValidate.status && !showAlertPrefMsg) {
      const postData = {
        search_string: data.search_string,
        lat: data.location.lat,
        lon: data.location.lon,
        bedroomMin: parseInt(data.bedroomMin),
        bedroomMax: parseInt(data.bedroomMax),
        min_price: parseInt(data.min_price),
        max_price: parseInt(data.max_price),
        accomodation_type: data.accomodation_type,
        under_offer: data.under_offer ? 1 : 0,

        keywords: data.keywords
          ? typeof data.keywords == "object"
            ? data.keywords.join(",")
            : data.keywords
          : "",
        distance: parseFloat(data.distance),
        dateListed: parseInt(data.dateListed),
        hide: data.hide
          ? typeof data.hide == "object"
            ? data.hide.join(",")
            : data.hide
          : "",
        alertType: data.alertType
          ? typeof data.alertType == "object"
            ? data.alertType.join(",")
            : data.alertType
          : "",
        bathroom_count: 0,
        furnishing: data.furnishing,
        garden: 0,
        parking: 0,
        property_for: property_for,
        retirementHomes: 0,
        sharedOwnership: 0,
      };

      if (alert_id) {
        props.updateAlert(postData, navigation.navigate, alert_id);
      } else {
        props.createAlert(postData, navigation.navigate);
      }
    } else {
      let error = locationValidate.status ? "" : locationValidate.msg;
      updateData(state.activeTab, "LocationErrorMsg", error);
      props.showToaster("error", "Please complete all mandatory fields");
    }
  };

  const { navigate, goBack } = navigation;
  const { agreeToContact } = state.globalData;

  useEffect(() => {
    return () => setState({ ...initialState });
  }, []);

  useEffect(() => {
    const unsubscribe = navigation.addListener("focus", () => {
      if (alert_id) {
        setClearData(false);
        props.getAlertById(alert_id);
      } else {
        setState({
          ...initialState,
        });
      }
    });
    setClearData(false);
    return unsubscribe;
  }, [props]);

  useEffect(() => {
    if (alert_id && alertByIdData) {
      setClearData(false);
      const newData = { ...alertByIdData.data };
      const rentData = { ...initialState.rentData };
      const buyerData = { ...initialState.buyerData };
      const globalData = { ...initialState.globalData };
      for (let key in newData) {
        if (newData.property_for == 1) {
          setState({
            activeTab: "Tab1",
          });
          if (rentData.hasOwnProperty(key)) {
            rentData[key] = newData[key];
          } else if (globalData.hasOwnProperty(key)) {
            globalData[key] = newData[key];
          }
        } else {
          setState({
            activeTab: "Tab2",
          });
          if (buyerData.hasOwnProperty(key)) {
            buyerData[key] = newData[key];
          } else if (globalData.hasOwnProperty(key)) {
            globalData[key] = newData[key];
          }
        }
      }
      setState({
        rentData,
        buyerData,
        globalData,
      });
    }
  }, [alertByIdData]);

  return (
    <Container>
      <Header
        showLogoWeb
        showLogoTab
        rightChildLabelTab
        rightChildLabelWeb
        showBackButtonTab
        showBackButtonMob
        onBackAction={() => props.navigation.navigate("My Alerts")}
        {...props}
      />
      <ScrollView>
        <UpperContainer>
          {layoutType == "web" ? (
            <BackBtnBox onPress={() => props.navigation.navigate("My Alerts")}>
              <Entypo name="chevron-left" size={18} color="#000" />
              <Text>Back</Text>
            </BackBtnBox>
          ) : null}
        </UpperContainer>
        <Row>
          <Col>
            <MainContent style={{ paddingTop: 20 }}>
              <TabWrapper>
                <Tabs
                  renderScene={{
                    tab1: (
                      <Form
                        key="1"
                        navigate={navigate}
                        updateData={updateData}
                        ischecked={state.rentData.under_offer}
                        formValues={state.rentData}
                        tab={"Tab1"}
                        clear={clearData}
                      />
                    ),
                    tab2: (
                      <Form
                        key="2"
                        navigate={navigate}
                        updateData={updateData}
                        ischecked={state.buyerData.under_offer}
                        formValues={state.buyerData}
                        tab={"Tab2"}
                        clear={clearData}
                      />
                    ),
                  }}
                  title={{
                    tabTitle1: "Rent",
                    tabTitle2: "Buy",
                  }}
                  defaultActiveTab={state.activeTab}
                  setActiveTab={(e) => {
                    setState({ activeTab: e });
                  }}
                />
              </TabWrapper>
            </MainContent>
          </Col>
          {/* </Background> */}
          <Col
            style={{
              marginLeft: layoutType !== "phone" ? 16 : 0,
              paddingTop: 20,
            }}
          >
            <MainContent
              style={{
                shadowColor: "grey",
                shadowOffset: {
                  width: 0,
                  height: 2,
                },
                shadowOpacity: 0.09,
                shadowRadius: 6.68,
                elevation: 11,
                backgroundColor: "white",
                borderRadius: 6,
                padding: 16,
              }}
            >
              {/* <FilterHeading> */}
              <View
                style={{
                  backgroundColor: "white",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                <FilterHeadingBox>
                  <SearchText style={{ paddingLeft: 0 }}>
                    Search Filter
                  </SearchText>
                </FilterHeadingBox>
              </View>
              {/* </FilterHeading> */}
              <DualOptionalView>
                <FilterInnerView>
                  <TitleWrapper style={{ marginTop: 12 }}>Radius</TitleWrapper>

                  <DropDownWrapper style={{ zIndex: 100 }}>
                    <DropDownSectionWrapper style={{ flex: 1, width: "100%" }}>
                      <DropDownPicker
                        items={distances}
                        placeholder="Select"
                        containerStyle={{ height: 40, marginTop: 8 }}
                        style={{
                          borderWidth: 1,
                          borderColor: "lightgray",
                        }}
                        zIndex={10012}
                        dropDownStyle={{ backgroundColor: "white" }}
                        onChangeItem={(e) => {
                          updateData("globalData", "distance", e.value);
                        }}
                        defaultValue={
                          state.globalData.distance
                            ? state.globalData.distance.toString()
                            : ""
                        }
                      />
                    </DropDownSectionWrapper>
                  </DropDownWrapper>


                  {/*
                  <TitleWrapper style={{ marginTop: 12 }}>
                    Date listed
                  </TitleWrapper>
                  <DropDownWrapper style={{ zIndex: 90 }}>
                    <DropDownSectionWrapper>
                      <DropDownPicker
                        items={dateListed}
                        placeholder="Select"
                        containerStyle={{ height: 40, marginTop: 8 }}
                        style={{
                          borderWidth: 1,
                          borderColor: "lightgray",
                        }}
                        dropDownStyle={{ backgroundColor: "white" }}
                        zIndex={1001}
                        onChangeItem={(e) => {
                          updateData("globalData", "dateListed", e.value);
                        }}
                        defaultValue={
                          state.globalData.dateListed
                            ? state.globalData.dateListed.toString()
                            : ""
                        }
                      />
                    </DropDownSectionWrapper>
                  </DropDownWrapper>
                  */}

                  <BubbleMultiSelectInput
                    title="Keyword"
                    onValueChange={(e) => {
                      updateData("globalData", "keywords", e);
                    }}
                    selectedValues={
                      state.globalData.keywords
                        ? typeof state.globalData.keywords !== "object"
                          ? state.globalData.keywords.split(",")
                          : state.globalData.keywords
                        : []
                    }
                    prefilledValue={
                      state.globalData.keywords
                        ? typeof state.globalData.keywords !== "object"
                          ? state.globalData.keywords.split(",")
                          : state.globalData.keywords
                        : []
                    }
                  />
                </FilterInnerView>

                <FilterInnerView>
                  <BoxSelectorWrapper>
                    <BoxSelector
                      boxList={
                        state.activeTab == "Tab1"
                          ? boxCategoriesRent
                          : boxCategoriesSale
                      }
                      label="Hide from search"
                      // onValueChange={(e) => {
                      //   updateData("globalData", "hide", e.join(","));
                      // }}
                      boxStyle={{ width: layoutType == "web" ? 220 : 140 }}
                      selectedIndex={
                        state.activeTab == "Tab1"
                          ? state.rentData.selectedCatIndex
                          : state.buyerData.selectedCatIndex
                      }
                      onValueChange={(e) => {
                        updateData(state.activeTab, "hide", e);
                      }}
                      setBoxIndex={(e) => {
                        updateData(state.activeTab, "selectedCatIndex", e);
                      }}
                      prefilledValues={
                        state.activeTab == "Tab1"
                          ? typeof state.rentData.hide !== "object"
                            ? state.rentData.hide.split(",")
                            : state.rentData.hide
                          : typeof state.buyerData.hide !== "object"
                          ? state.buyerData.hide.split(",")
                          : state.buyerData.hide
                      }
                      style={{ justifyContent: "center" }}
                    ></BoxSelector>
                  </BoxSelectorWrapper>
                </FilterInnerView>
              </DualOptionalView>
            </MainContent>
          </Col>
        </Row>
        <Row>
          <MainContent
            style={{
              marginTop: 16,
              marginBottom: 32,
              shadowColor: "grey",
              shadowOffset: {
                width: 0,
                height: 2,
              },
              shadowOpacity: 0.09,
              shadowRadius: 6.68,
              elevation: 11,
              backgroundColor: "white",
              borderRadius: 6,
              padding: 16,
            }}
          >
            <DualOptionalView>
              <View
                style={{
                  backgroundColor: "white",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                <FilterHeadingBox>
                  <SearchText style={{ paddingLeft: 0 }}>
                    Alert Preferences
                  </SearchText>
                </FilterHeadingBox>
              </View>
              <FilterInnerView>
                <BoxSelectorWrapper
                  style={{ width: layoutType !== "phone" ? 450 : "100%" }}
                >
                  <BoxSelector
                    boxList={alertOptions}
                    label="Choose how you would like alerts sent to you"
                    onValueChange={(e) => {
                      updateData("globalData", "alertType", e);
                    }}
                    prefilledValues={
                      state.globalData.alertType
                        ? typeof state.globalData.alertType !== "object"
                          ? state.globalData.alertType.split(",")
                          : state.globalData.alertType
                        : []
                    }
                    selectedIndex={state.globalData.selectedAlertIndex}
                    setBoxIndex={(e) => {
                      updateData("globalData", "selectedAlertIndex", e);
                    }}
                    boxStyle={{ width: 140, marginTop: 20 }}
                  ></BoxSelector>
                </BoxSelectorWrapper>
                <CheckBox
                  height={"40px"}
                  fontSize={14}
                  checked={agreeToContact}
                  label={
                    "Agree to be contacted and updated using the above choosen method"
                  }
                  onPress={() => {
                    updateData("globalData", "agreeToContact", !agreeToContact);
                  }}
                />
                {state.showAlertPrefMsg ? (
                  <View>
                    <ErrorMsg>Please agree to receive alerts</ErrorMsg>
                  </View>
                ) : null}
              </FilterInnerView>
            </DualOptionalView>
            <View
              style={{
                paddingVertical: 20,
                alignItems: "flex-end",
                justifyContent: "flex-end",
              }}
            >
              <WideButton
                width="50%"
                onPress={createAlertCall}
                gradiant
                buttonType={'solid'}
                childText={alert_id ? "Update Alert" : "Create Alert"}
              />
            </View>
          </MainContent>
        </Row>
      {authDataFromMobile?null:  <Footer isTLYF {...props} />}
      </ScrollView>
      <Loader loading={loading} />
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: idx(state, (_) => _.alertReducer.createAlertLoading),
    createAlertStatus: idx(state, (_) => _.alertReducer.createAlertStatus),
    alertByIdData: state.alertReducer.alertByIdData,
    alertByIdStatus: idx(state, (_) => _.alertReducer.alertByIdStatus),
    searchObject: idx(state, (_) => _.appReducer.searchObject),
    authDataFromMobile: state?.appReducer?.mobileData?.data?.isWebView,

  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createAlert,
      getAlertById,
      updateAlert,
      clearAlert,
      showToaster,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(CreateAlertScreen);
