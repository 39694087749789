import React from 'react';
import getPlatformType from '../../helpers/getPlatform';

var __html = require('./policy.html');
var template = { __html: __html };
const layoutType = getPlatformType();
const Privacy=()=>{
  return(

    <div style={ layoutType == 'phone' ?{width:"92%",background:'white'} :{width:"92%",alignSelf:'flex-start',padding:40,background:'white'}} dangerouslySetInnerHTML={template} />
  )
}
export default Privacy;
