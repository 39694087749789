import React, { Component } from "react";
import {
  View,
  Text,
  Dimensions,
  SafeAreaView,
  Image,
  TouchableOpacity,
  Platform,
  ScrollView,
} from "react-native";
import List from "../commonComponents/ListComponent";
import Header from "../commonComponents/headerComponent/headerComponent";
import getPlatformType from "../helpers/getPlatform";
import { ButtonView } from "./searchFilter/searchFilterStyles";
import ButtonWithTitle from "../commonComponents/buttonWithTitle/buttonWithTitle";
import WideButton from "../commonComponents/wideButton";
const layoutType = getPlatformType();
import { MaterialIcons, FontAwesome } from "@expo/vector-icons";
import { Tabs } from "../commonComponents/TabComponent/Tabs";
import { getSavedProperties, saveProperty } from "../redux/actions/property";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import idx from "idx";
import { Container, MainContent } from "./savedPropertiesStyle";
import Footer from "../commonComponents/footerComponent/customFooter";
import { ContainerScrollView } from "../helpers/appStyles";
const WIN_HEIGHT = Dimensions.get("window").height;

class SavedProperties extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expand: false,
      currentView: "list",
      open: false,
      data: [
        {
          id: "1",
        },
        {
          id: "2",
        },
        {
          id: "3",
        },
        {
          id: "4",
        },
        {
          id: "5",
        },
        {
          id: "6",
        },
      ],
    };
    this.props.getSavedProperties();
  }

  render() {
    const { navigate } = this.props.navigation;
    const { expand, data, currentView } = this.state;
    const {
      searchObject,
      savedData,
      rentSavedData,
      saleSavedData,
    } = this.props;

    return (
      <SafeAreaView style={{ backgroundColor: "#FAFAFA" }}>
        <Header
          MenuAccountWeb
          showDrawerMob
          showDrawerTab
          showLogoWeb
          showLogoTab
          pageTitleMob="My saved properties"
          {...this.props}
        />
        <ContainerScrollView>
          <Container style={{ minHeight: WIN_HEIGHT - 300 }}>
            <MainContent>
              <Tabs
                title={{
                  tabTitle1: "Renting",
                  tabTitle2: "Buying",
                }}
                tabName="My saved properties"
                isFullWidth={false}
                renderScene={{
                  tab1: (
                    <List
                      navigate={navigate}
                      searchObject={searchObject}
                      onSave={this.props.saveProperty}
                      data={rentSavedData}
                      savedProperties
                    />
                  ),
                  tab2: (
                    <List
                      navigate={navigate}
                      searchObject={searchObject}
                      onSave={this.props.saveProperty}
                      data={saleSavedData}
                      savedProperties
                    />
                  ),
                }}
              />
            </MainContent>
          </Container>
          <Footer isTLYF {...this.props} />
        </ContainerScrollView>
      </SafeAreaView>
    );
  }
}
const mapStateToProps = (state) => ({
  // loading: state.propertyReducer.searchLoading,
  rentSavedData: idx(
    state,
    (_) => _.propertyReducer.savedData.data.rentProperties
  ),
  saleSavedData: idx(
    state,
    (_) => _.propertyReducer.savedData.data.saleProperties
  ),
  searchObject: idx(state, (_) => _.propertyReducer.searchObject),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getSavedProperties,
      saveProperty,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(SavedProperties);
