interface TypographyStylesProps {
  fontSize: number;
  lineHeight: string;
}
export const typographyStyles = ({ fontSize }: any) => ({
  regular: {
    // fontFamily: 'Lato-Medium',
    fontSize,
    fontStyle: 'normal',
    fontWeight: '300',
    // lineHeight:20.8,
  },
  bold: {
    // fontFamily: 'Lato-Bold',
    fontSize,
    fontStyle: 'normal',
    fontWeight: 'bold',
    // lineHeight:20.8,
  },
});

const shadowStyles = (
  offsetX: number,
  offsetY: number,
  blurRadius: number,
  spreadRadius: number,
  color: string,
  opacity: number,
) => ({
  boxShadow: `${offsetX}px ${offsetY}px ${blurRadius}px ${spreadRadius}px rgba(${color}, ${opacity})`,
});

export const baseTokens = {
  colors: {},
  mode: 'dark',
  cornerRadius: {
    outerSurface: { 24: '24px' },
    innerSurface: { 1: '16px', 2: '12px', 3: '8px', 4: '4px' },
    button: '360px',
  },
  spacing: {
    0.5: '2px',
    1: '4px',
    2: '8px',
    3: '12px',
    4: '16px',
    5: '20px',
    6: '24px',
    8: '32px',
    10: '40px',
    12: '48px',
    16: '64px',
    20: '80px',
    24: '96px',
  },

  typography: {
    '2xs': typographyStyles({ fontSize: '10px', lineHeight: '14px' }),
    xs: typographyStyles({ fontSize: '12px', lineHeight: '16.8px' }),
    sm: typographyStyles({ fontSize: '14px', lineHeight: '19.6px' }),
    md: typographyStyles({ fontSize: '16px', lineHeight: '22.4px' }),
    lg: typographyStyles({ fontSize: '18px', lineHeight: '25.2px' }),
    xl: typographyStyles({ fontSize: '20px', lineHeight: '28px' }),
    '2xl': typographyStyles({ fontSize: '24px', lineHeight: '33.6px' }),
    '3xl': typographyStyles({ fontSize: '30px', lineHeight: '42px' }),
    '4xl': typographyStyles({ fontSize: '36px', lineHeight: '50.4px' }),
    '5xl': typographyStyles({ fontSize: '48px', lineHeight: '67.2px' }),
    '6xl': typographyStyles({ fontSize: '60px', lineHeight: '84px' }),
  },
  heading: {
    xs: typographyStyles({ fontSize: '14px', lineHeight: '19.6px' }).bold,
    sm: typographyStyles({ fontSize: '16px', lineHeight: '22.4px' }).bold,
    md: typographyStyles({ fontSize: '18px', lineHeight: '25.2px' }).bold,
    lg: typographyStyles({ fontSize: '20px', lineHeight: '28px' }).bold,
    xl: typographyStyles({ fontSize: '24px', lineHeight: '33.6px' }).bold,
    '2xl': typographyStyles({ fontSize: '30px', lineHeight: '42px' }).bold,
    '3xl': typographyStyles({ fontSize: '32px', lineHeight: '50.4px' }).bold,
    '4xl': typographyStyles({ fontSize: '48px', lineHeight: '67.2px' }).bold,
    '5xl': typographyStyles({ fontSize: '60px', lineHeight: '84px' }).bold,
  },
  effect: {
    hardShadow: {
      1: shadowStyles(0, 30, 50, -20, '38, 38, 38', 0.2),
      2: shadowStyles(0, 32, 100, -8, '38, 38, 38', 0.2),
      3: shadowStyles(2, 20, 70, -8, '38, 38, 38', 0.2),
      4: shadowStyles(0, 20, 50, -8, '38, 38, 38', 0.2),
    },
    softShadow: {
      1: shadowStyles(0, 26, 50, -18, '0, 0, 0', 0.15),
      2: shadowStyles(0, 26, 50, -18, '38, 38, 38', 0.1),
      3: shadowStyles(0, 15, 50, -18, '38, 38, 38', 0.1),
      4: shadowStyles(0, 20, 50, -25, '38, 38, 38', 0.1),
    },
  },
};

export interface BaseTokensProps {
  colors: object;
  typography: object;
  heading: object;
  mode: 'light' | 'dark';
  effect: object;
}
