import React from "react";
import styled from "styled-components/native";
import { COLORS } from "../../constants/colorPallete";
import getPlatformType from "../../helpers/getPlatform";

const layoutType = getPlatformType();
let Container,
  SectionWrapper,
  TeamHeader,
  MemberHeader,
  DualView,
  TextBox,
  SectionTitle,
  SectionText,
  ImageBox,
  TeamsSection,
  MemberBoxWrapper,
  MemberBox,
  MemberImage,
  MemberName,
  MemberNameText,
  MemberDetails,
  MemberDetailsText,
  ListCol,
  ColTitle,
  CenterSection,
  CenterImage,
  TitleLogo,
  SectionImage,
  ListText,
  ContentContainer,
  Section,
  ColorText,
  Title,
  Description,
  DesktopImage,
  CircleImage,
  Left,
  Right,
  DFlex;


 export let TopSection=styled.View`
   width:100%;
   justify-content:center;
   align-items:center;
  `;
  export let LogoImage=styled.Image`
   width:200px;
   height:40px;
   resize-mode:contain;
   margin-top:20px;
  `;
  
  Container = styled.View`
  width: 1130px;
  margin: 0 auto;
  margin-bottom:100px;
`;

SectionWrapper = styled.View`
  width: 100%;
  padding: 30px;
`;
MemberHeader = styled.View`
  text-align: center;
  padding: 25px;
`;
TeamHeader = styled.Text`
  color: #000000;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 27px;
  text-align: center;
`;

DualView = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 32px;
`;
TextBox = styled.View`
  height: 100%;
  width: 526px;
`;
SectionTitle = styled.Text`
  color: #000000;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
`;
SectionText = styled.Text`
  color: #000000;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 26px;
  margin-top: 10px;
  text-align:center;
`;
ImageBox = styled.View`
  height: 415px;
  width: 526px;
`;
TeamsSection = styled.View`
  background-color: #fff;
  box-shadow: rgba(128, 128, 128, 0.9) 0px 1px 4.68px;
  margin-top: 20px;
`;
MemberBoxWrapper = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  padding-top: 0px;
`;
MemberBox = styled.View`
  width: 250px;
  text-align: center;
  align-items: center;
`;
MemberImage = styled.View`
  height: 202px;
  width: 200px;
`;
MemberName = styled.View`
  padding: 10px 0px;
`;
MemberNameText = styled.Text`
  color: #e71e82;
  font-size: 18px;
  font-weight: 600;
`;
MemberDetails = styled.View``;
MemberDetailsText = styled.Text`
  color: #000000;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
`;


if (layoutType == "phone") {
  ContentContainer=styled.View`
    width: 80%;
    justify-content: center;
    align-items: center;
      `;
  Section=styled.View`
    width: 100%;
    justify-content: center;
    align-items: center;
   
  `;
  CenterImage=styled.Image`
  width:100%;
  height:200px;
  resize-mode:contain;
  margin-bottom:30px;
  `;
  DesktopImage=styled.Image`
  width:90%;
  height:250px;
  resize-mode:contain;
  margin-top:40px;
  `;
  CenterSection = styled.View`
    flex-direction:column-reverse;
  `;
  Container = styled.View`
    width: 94%;
    margin: 0 auto;
  `;
  Title = styled.Text`
    color: ${COLORS.orange};
    font-size: 30px;
    font-weight: bold;
    text-align:center;
  `;
  Description=styled.Text`
  color: #000000;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 19px;
  text-align:center;

`;

  TeamHeader = styled.Text`
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;
  `;
  TextBox = styled.View`
    width: 100%;
  `;
  ImageBox = styled.View`
    height: 240px;
    width: 100%;
    margin: 12px 0;
  `;
  SectionWrapper = styled.View`
    width: 100%;
    padding: 10px;
  `;
  DualView = styled.View`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  `;
  MemberBox = styled.View`
    width: 150px;
    text-align: center;
    align-items: center;
    margin-bottom: 20px;
  `;
  MemberBoxWrapper = styled.View`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px;
    flex-wrap: wrap;
  `;
  MemberImage = styled.View`
    height: 140px;
    width: 140px;
  `;
  MemberDetailsText = styled.Text`
    color: #000000;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 22px;
    text-align: center;
  `;
  ListCol = styled.View`
    width: 100%;
    padding: 16px;
    border-radius: 6px;
    align-items: center;
    margin-top: 24px;
  `;
  ColTitle = styled.Text`
    font-size: 18px;
    font-weight: 500;
  `;
  ColorText = styled.Text`
    color: #e71e82;
    font-size: 20px;
    font-weight: 500;
    margin: 16px 0;
  `;
  TitleLogo = styled.Image`
    height: 11px;
    width: 81px;
    vertical-align: middle;
  `;
  ListText = styled.Text`
    margin-bottom: 16px;
    font-size: 15px;
    text-align: center;
    color: #333333;
  `;
  DFlex = styled.View`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 24px;
  `;
  Left=styled.View`
  width:100%;
  justify-content:center;
  align-items:center;

 `;
 Right=styled.View`
  width:100%;
  align-items:center;
  
  
 `;
} else if (layoutType == "web") {
  ListCol = styled.View`
    width: 352px;
    padding: 16px;
    border-radius: 6px;
    align-items: center;
    height: 100%;
    margin: 24px 18px;
  `;
  Left=styled.View`
   width:30%;

  `;
  Right=styled.View`
   width:70%;
   align-items:center;
   
  `;
  CenterImage=styled.Image`
  width:100%;
  height:400px;
  resize-mode:contain;
  align-self:flex-end;
  `;
  DesktopImage=styled.Image`
  width:90%;
  height:350px;
  resize-mode:contain;
  
  `;
  Section=styled.View`
    width: 30%;
  `;
  SectionImage=styled.View`
    width: 60%;
    height:400px;
    align-items:flex-end;
    margin-top:20px;
  `;
  CenterSection = styled.View`
  width:100%;
    flex-direction:row;
    padding-horizontal:30px;
  `;
  Title = styled.Text`
    color: ${COLORS.orange};
    font-size: 30px;
    font-weight: bold;
  `;
  Description=styled.Text`
  color: #000000;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 19px;
`;
  ContentContainer=styled.View`
    width: 30%;
  `;
  ColTitle = styled.Text`
    font-size: 18px;
    font-weight: 500;
  `;
  ListText = styled.Text`
    margin-bottom: 16px;
    font-size: 15px;
    text-align: center;
    color: #333333;
  `;
  ColorText = styled.Text`
    color: #e71e82;
    font-size: 20px;
    font-weight: 500;
    margin: 16px 0;
  `;
  TitleLogo = styled.Image`
    height: 11px;
    width: 81px;
    vertical-align: middle;
  `;
  DFlex = styled.View`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 24px;
  `;
} else {
  TextBox = styled.View`
    height: 100%;
    width: 48%;
  `;
  ContentContainer=styled.View`
    width: 30%;
  `;
  Section=styled.View`
    width: 35%;
    margin-right:20px;
  `;
  CenterImage=styled.Image`
  width:70%;
  height:400px;
  resize-mode:contain;
  `;
  DesktopImage=styled.Image`
  width:70%;
  height:300px;
  resize-mode:contain;
  `;
  CenterSection = styled.View`
    flex-direction:row;
  `;
  
  Title = styled.Text`
    color: ${COLORS.orange};
    font-size: 30px;
    font-weight: bold;
  `;
  Description=styled.Text`
  color: #000000;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 19px;
`;
  ImageBox = styled.View`
    height: 100%;
    min-height: 280px;
    width: 48%;
  `;
  SectionTitle = styled.Text`
    color: #000000;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
  `;
  SectionText = styled.Text`
    color: #000000;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 26px;
    margin-top: 20px;
  `;
  Container = styled.View`
    width: 94%;
    margin: 0 auto;
  `;
  TeamHeader = styled.Text`
    color: #000000;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
  `;
  ListCol = styled.View`
    width: 48%;
    padding: 16px;
    border-radius: 6px;
    align-items: center;
    height: 100%;
    margin-top: 24px;
  `;
  ColTitle = styled.Text`
    font-size: 18px;
    font-weight: 500;
  `;
  ListText = styled.Text`
    font-size: 15px;
    margin-bottom: 16px;
    text-align: center;
    color: #333333;
  `;
  ColorText = styled.Text`
    color: #e71e82;
    font-size: 20px;
    font-weight: 500;
    margin: 16px 0;
  `;
  TitleLogo = styled.Image`
    height: 11px;
    width: 81px;
    vertical-align: middle;
  `;
  DFlex = styled.View`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 24px;
  `;
  Left=styled.View`
   width:30%;

  `;
  Right=styled.View`
   width:70%;
   align-items:center;
   justify-content:center;
   
  `;
}

export {
  Container,
  SectionWrapper,
  TeamHeader,
  MemberHeader,
  DualView,
  TextBox,
  Title,
  CenterImage,
  Description,
  SectionTitle,
  SectionText,
  ContentContainer,
  Section,
  ImageBox,
  TeamsSection,
  SectionImage,
  MemberBoxWrapper,
  MemberBox,
  MemberImage,
  CenterSection,
  MemberName,
  MemberNameText,
  MemberDetails,
  MemberDetailsText,
  DesktopImage,
  ListCol,
  ColTitle,
  TitleLogo,
  ListText,
  ColorText,
  DFlex,
  Right,
  Left
};
