import React, { useState } from 'react';
import { Text, View, TextInput } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import CustomTextInput from '../../../commonComponents/customTextInput/customTextInput';
import { EmailValidation, NameValidation, NumValidation } from '../../../helpers/validation';
import { contactUs } from '../../../redux/actions/auth';
import BackGroundGenerator from '../common/BackGroundGenerator';
import QuadRings from '../common/QuadRings';
import Rings from '../common/Rings';
import ThreeSection from '../common/ThreeSection';
import TwistedButton from '../common/TwistedButton';
import { backgroundSearchArr } from '../images/contact/searchArray';
import Loader from '../../../commonComponents/activityIndicator/activityIndicator';
import stylesMobile from './stylesMobile';
import stylesTab from './stylesTab';
import stylesWeb from './stylesWeb';
import { Typography } from '../../openFlow/style';
import { COLORS } from '../../../constants/colorPallete';
import WideButton from '../../../commonComponents/wideButton';

const Sectiom = [
  {
    heading: 'Recognised contribution',
    sub: 'We believe everyone has value to add and their effort and hard work should be recognised and fairly rewarded.',
  },
  {
    heading: 'Respect and fairness',
    sub: 'We believe everyone deserves to be respected as an individual and judged on their own merits - free from discrimination. A fair and level playing field.',
  },
  {
    heading: 'Restless curiosity',
    sub: "We believe in the power of questions and not being afraid to say 'I dont know'.",
  },
  {
    heading: '(R)Evolution and change',
    sub: 'We believe we should never be unwilling to change – everything constantly evolves and so should we.',
  },
  {
    heading: 'Together for harmony',
    sub: 'We believe that everyone has a contribution to make and everyone is better off when we all work together.',
  },
  {
    heading: 'Integrity & Accountability',
    sub: 'We believe talking should be backed up with doing people should do what they promised.',
  },
];
const listSection = [
  {
    heading: 'The right to a happy home',
    sub: 'We believe everyone has the right to a happy home. To spend time with family, friends and loved ones undisturbed.',
  },
  {
    heading: 'The right to privacy',
    sub: 'We believe everyone has the right to privacy. To live free from intrusion and to have control of their data and information. ',
  },
  {
    heading: 'The right to recognition as an individual, free from discrimination',
    sub: 'We believe everyone has the right to be treated as an individual, recognised for who they are and free from discrimination because of a category they fall into. ',
  },
];
const LandingContact = (props) => {
  const [type, setType] = useState('web');
  const [loading, setLoading] = useState(false);

  const [state, setState] = useState({
    first_Name: '',
    last_Name: '',
    email: '',
    nameErrorMessage: '',
    emailErrorMessage: '',
    lastNameErrorMessage: '',
    numErrorMessage: '',
    contact_Number: '',
  });
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (props?.route?.params?.from) {
      setState({
        ...state,
        from: props?.route?.params?.from ? props?.route?.params?.from : '',
      });
    }
  }, [props?.route?.params?.from]);
  const styles = type == 'web' ? stylesWeb : type == 'mobile' ? stylesMobile : stylesTab;

  const validate = () => {
    let emailCheck = EmailValidation(state?.email);
    let nameCheck = NameValidation(state?.first_Name);
    let lnameCheck = NameValidation(state?.last_Name);
    let numberCheck = NumValidation(state?.contact_Number);

    if (emailCheck?.status && nameCheck?.status && lnameCheck.status && numberCheck.status) {
      setLoading(true);
      dispatch(
        contactUs(
          {
            email: state?.email,
            topic: '',
            name: state?.first_Name + ' ' + state?.last_Name,
            message: state?.message,
            contact_Number: state?.contact_Number,
          },
          (data) => {
            setLoading(false);
            setState({
              first_Name: '',
              last_Name: '',
              email: '',
              contact_Number: '',
              nameErrorMessage: '',
              emailErrorMessage: '',
              lastNameErrorMessage: '',
              message:""
            });
          }
        )
      );
    } else {
      setState({
        ...state,
        emailErrorMessage: emailCheck?.status ? '' : emailCheck?.msg,
        lastNameErrorMessage: lnameCheck?.status ? '' : 'This is a required field',
        nameErrorMessage: nameCheck?.status ? '' : 'This is a required field',
        numErrorMessage: numberCheck?.status ? '' : 'This is invalid number',
      });
    }
  };
  console.log('state', state);
  const token = useSelector((state) => state?.appReducer?.loginData?.data?.token);
  let Tag = token?BackGroundGenerator:View

  return (
    <Tag isTLYF props={props}>
      
      <Loader loading={loading} />
      <View style={token? {width:"50%",alignSelf:'center',marginTop:"5%",borderRadius:20,backgroundColor:"white",padding:30}:[styles.formWrapper]}>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <CustomTextInput
            width="48%"
            id={'CONTACT_FIRST_NAME'}
            showError={state?.nameErrorMessage == '' ? false : true}
            prefilledValue={state?.first_Name}
            errorMessage={state?.nameErrorMessage}
            onChangeText={(first_Name) =>
              setState({
                ...state,
                first_Name: first_Name,
              })
            }
            bRadius={50}
            bColor={'#E2E2E2'}
            titleComponent={
              <Typography color={COLORS.BLACK} raj size={'16px'} bold={'600'}>
                First Name
              </Typography>
            }
          />
          <CustomTextInput
            width="48%"
            bRadius={50}
            bColor={'#E2E2E2'}
            titleComponent={
              <Typography color={COLORS.BLACK} raj size={'16px'} bold={'600'}>
                Last Name
              </Typography>
            }
            id={'CONTACT_LAST_NAME'}
            showError={state?.lastNameErrorMessage == '' ? false : true}
            prefilledValue={state?.last_Name}
            errorMessage={state?.lastNameErrorMessage}
            onChangeText={(last_Name) =>
              setState({
                ...state,
                last_Name: last_Name,
              })
            }
          />
        </View>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <CustomTextInput
            width="48%"
            bRadius={50}
            bColor={'#E2E2E2'}
            titleComponent={
              <Typography color={COLORS.BLACK} raj size={'16px'} bold={'600'}>
                Email{' '}
              </Typography>
            }
            id={'CONTACT_EMAIL'}
            showError={state?.emailErrorMessage == '' ? false : true}
            prefilledValue={state?.email}
            errorMessage={state?.emailErrorMessage}
            onChangeText={(email) =>
              setState({
                ...state,
                email: email,
              })
            }
          />
          <CustomTextInput
            width="48%"
            bRadius={50}
            bColor={'#E2E2E2'}
            titleComponent={
              <Typography color={COLORS.BLACK} raj size={'16px'} bold={'600'}>
                Telephone{' '}
              </Typography>
            }
            onlyLetters
            id={'CONTACT_TELE'}
            rightText="optional"
            showError={state?.numErrorMessage == '' ? false : true}
            errorMessage={state?.numErrorMessage}
            prefilledValue={state?.contact_Number}
            onChangeText={(contact_Number) =>
              setState({
                ...state,
                contact_Number: contact_Number,
              })
            }
          />
        </View>
        <View style={{ width: '100%' }}>
          <View>
            <Typography align={'left'} color={COLORS.BLACK} raj={token?false:true} size={'16px'} bold={'600'}>
              Write your message here
            </Typography>
            <Text style={{ fontWeight: 'bold', marginBottom: 10 }}></Text>
            <View
              style={{
                width: '100%',
                height: 150,
                borderColor: '#b2b2b2',
                borderWidth: 1,
                borderRadius: 15,
              }}
            >
              <TextInput
                style={{
                  width: '100%',
                  height: '100%',
                  padding: 10,
                  borderRadius: 15,
                }}
                id={'C_SUBJECTOFMESSAGEFULL'}
                maxLength={2000}
                multiline
                onChangeText={(text) =>
                  setState({
                    ...state,
                    message: text,
                  })
                }
                value={state?.message}
              />
            </View>
          </View>
        </View>
        <View style={{ marginTop: 20 }}>
          <WideButton
            width={'100%'}
            id="loginBtn"
            onPress={() => validate()}
            gradiant
            isLGreen={token}
            childText="Submit"
            height={'55px'}
            gradBorderRadius={50}
            isRaj={token?false:true}
            textSize={25}
          />
        </View>
      </View>
    </Tag>
  );
};
export default LandingContact;
