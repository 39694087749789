import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStripe, useElements, PaymentElement, AddressElement } from '@stripe/react-stripe-js';
import { Image, TouchableOpacity, View } from 'react-native';
import WideButton from '../../commonComponents/wideButton';
import { tenantConstants } from '../openFlow/openFlowRedux/tenant.constants';
import ODSButton from '../../../openbrix-design-system/ui/atoms/ODSButton';
import { getTokens } from '../../../openbrix-design-system/design-tokens/color-tokens';
export const CheckoutForm = (props) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const profile = useSelector((state) => state.authReducer.profile);
  const themeObj = getTokens('light').colors;
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    try {
      dispatch({ type: tenantConstants.MAKE_PAYMENTS });
      const address = await elements.getElement('address').getValue();
      console.log({ shipping: address.value });
      const response = await stripe.confirmPayment({
        elements,
        redirect: 'if_required',
        confirmParams: {
          shipping: address.value,
        },
      });
      switch (response.paymentIntent.status) {
        case 'succeeded':
          props.onSuccessToken(true);
          break;
        case 'processing':
          props.onSuccessToken(true);
          break;
        case 'requires_payment_method':
          props.onSuccessToken(false);
          break;
        default:
          props.onSuccessToken(false);
      }
      dispatch({ type: tenantConstants.MAKE_PAYMENTSSUCCESS });
    } catch (error) {
      console.log(error);
      dispatch({ type: tenantConstants.MAKE_PAYMENTSSUCCESS });
      props.onSuccessToken(false);
    }
  };
  const renderPayByCard = () => {
    return (
      <form onSubmit={handleSubmit}>
        <PaymentElement />
        <AddressElement
          options={{
            mode: 'billing',
            allowedCountries: ['UK'],
            blockPoBox: true,
            defaultValues: {
              name: profile?.data?.first_name
                ? profile?.data?.first_name + ' ' + profile?.data?.last_name
                : '',
              address: {
                country: 'GB',
              },
            },
            fields: {
              phone: 'always',
            },
            validation: {
              phone: {
                required: 'never',
              },
            },
          }}
        />
        <Image
          source={require('./../../assets/images/stripe.png')}
          resizeMode="contain"
          style={{
            height: 26,
            width: 119,
            alignSelf: 'flex-end',
            marginTop: 24,
          }}
        />

        <View
          style={{
            marginVertical: 20,
            alignSelf: 'flex-end',
          }}
        >
          <ODSButton
            lIcon={'TB-Arrow-Right'}
            restStyle={{ width: 300,  alignSelf: 'flex-end' }}
            type="primary"
            themeColor={props?.theme?props.theme: themeObj.main.purple}
            disabled={!stripe || !elements}
            onPress={handleSubmit}
          >
            Submit order
          </ODSButton>
        </View>
      </form>
    );
  };

  return <View>{renderPayByCard()}</View>;
};

export default CheckoutForm;
