import { Alert, Platform } from "react-native";
import { types } from "../../actionTypes";
import {
  SERVER_URL,
  _post,
  URL,
  _get,
  _delete,
  _put,
  PROPERTY_DATA_URL,
  TRANSPORT_DATA_URL,
} from "../../../helpers/connectionHelpers";
import { store } from "../../store/setup";
import ReactGA from "react-ga4";

export const getMaintanenceDetails = (id) => {
  return (dispatch) => {
    dispatch({ type: types.GET_MAINTANANCE });
    let url = `${SERVER_URL}${URL.getMaintanenceDetails}${id}`;
    _get(
      url,
      {}, // all headers here (if)
      {}, // body here (if)
      (res) => {
        // function to perform on success
        
        dispatch({
          type: types.GET_MAINTANANCE_SUCCESS,
          payload: res?.data,
        });
      },
      (err) => {
        // function to perform on failure
        
        dispatch({ type: types.GET_MAINTANANCE_FAIL, error: err });
        dispatch({
          type: types.SHOW_ALERT,
          alertType: "error",
          text: "Something went wrong please try again!",
        });
      }
    );
  };
};


export const removeMapDetails = (property_id) => {
  return (dispatch) => {
    dispatch({ type: types.REMOVE_PROPERTY_CARD });
  };
};

export const GetCouncilTax = (postcode) => {
  return (dispatch) => {
    dispatch({ type: types.GET_PROPERTY_COUNCIL_REQUEST });
    let url = `${PROPERTY_DATA_URL}${postcode}`;
    _get(
      url,
      {}, // all headers here (if)
      {}, // body here (if)
      (res) => {
        // function to perform on success
        
        dispatch({
          type: types.GET_PROPERTY_COUNCIL_SUCCESS,
          payload: res.data,
        });
      },
      (err) => {
        // function to perform on failure
        
        dispatch({ type: types.GET_PROPERTY_COUNCIL_FAILED, error: err });
        dispatch({
          type: types.SHOW_ALERT,
          alertType: "error",
          text: "Something went wrong please try again!",
        });
      }
    );
  };
};

export const GetTransport = (lat, lng) => {
  return (dispatch) => {
    dispatch({ type: types.GET_TRANSPORT_REQUEST });
    let url = `${SERVER_URL}${URL.getTransport}lat=${lat}&lon=${lng}`;
    
    _get(
      url,
      {}, // all headers here (if)
      {}, // body here (if)
      (res) => {
        // function to perform on success
        
        dispatch({
          type: types.GET_TRANSPORT_SUCCESS,
          payload: res,
        });
      },
      (err) => {
        // function to perform on failure
        
        dispatch({ type: types.GET_TRANSPORT_FAILED, error: err });
        dispatch({
          type: types.SHOW_ALERT,
          alertType: "error",
          text: "Something went wrong please try again!",
        });
      }
    );
  };
};
export const GetSchool = (postcode) => {
  return (dispatch) => {
    dispatch({ type: types.GET_PROPERTY_SCHOOL_REQUEST });
    let url = `${SERVER_URL}${URL.getSchool}${postcode}`;
    _get(
      url,
      {}, // all headers here (if)
      {}, // body here (if)
      (res) => {
        // function to perform on success
        
        dispatch({
          type: types.GET_PROPERTY_SCHOOL_SUCCESS,
          payload: res.data,
        });
      },
      (err) => {
        // function to perform on failure
        
        dispatch({ type: types.GET_PROPERTY_SCHOOL_FAILED, error: err });
        dispatch({
          type: types.SHOW_ALERT,
          alertType: "error",
          text: "Something went wrong please try again!",
        });
      }
    );
  };
};

export const mapDetails = (property_id) => {
  return (dispatch) => {
    dispatch({ type: types.GET_PROPERTY_CARD_REQUEST });

    _get(
      `${SERVER_URL}${URL.getPropertyCard}${property_id}`,
      {}, // all headers here (if)
      {}, // body here (if)
      (res) => {
        // function to perform on success
        
        dispatch({
          type: types.GET_PROPERTY_CARD_SUCCESS,
          payload: res,
        });
      },
      (err) => {
        // function to perform on failure
        
        dispatch({ type: types.GET_PROPERTY_CARD_FAILED, error: err });
        Alert.alert(err.message);
      }
    );
  };
};
export const mapSearch = (bodyofRequest, params, pageNo, cb) => {
  return (dispatch) => {
    dispatch({ type: types.SEARCHMAP_REQUEST });
    _post(
      `${SERVER_URL}${URL.searchURLMAP}`,
      {}, // all headers here (if)
      JSON.stringify(bodyofRequest), // body here (if)
      (res) => {
        // function to perform on success
        
        let responseObject = res;
        if (responseObject.status) {
          dispatch({
            type: types.SEARCHMAP_SUCCESS,
            payload: res.data,
          });
        } else {
          dispatch({
            type: types.SEARCHMAP_FAILED,
            payload: responseObject,
          });
        }
      },
      (err) => {
        // function to perform on failure
        
        dispatch({ type: types.SEARCHMAP_FAILED, error: err.message });
      }
    );
  };
};
export const propertyNearMe = (cord) => {
  
  return (dispatch) => {
    dispatch({ type: types.PROPERTYNEARME_REQUEST });
    _post(
      `${SERVER_URL}${URL.propertyNearMeUrl}`,
      {}, // all headers here (if)
      JSON.stringify(cord), // body here (if)
      (res) => {
        // function to perform on success
        
        let responseObject = res;
        if (responseObject.status) {
          dispatch({
            type: types.PROPERTYNEARME_SUCCESS,
            payload: res.data,
          });
        } else {
          dispatch({
            type: types.PROPERTYNEARME_FAIL,
            payload: responseObject,
          });
        }
      },
      (err) => {
        // function to perform on failure
        
        dispatch({ type: types.PROPERTYNEARME_FAIL, error: err.message });
      }
    );
  };
};
export const search = (bodyofRequest, params, pageNo, cb) => {
  console.log("params",params)
  let val = params ? params : "&sortBy=distance&order=ASC";
  let page = pageNo ? "&page=" + pageNo : "&page=1";
  return (dispatch) => {
    dispatch({ type: types.SEARCH_REQUEST });

    let prop_for;
    if (bodyofRequest?.filters.property_for == 1) prop_for = "rent";
    else prop_for = "sales";

    // ReactGA.initialize("G-084ENPRZN7");
      ReactGA.event({
        category: "Search",
        action: prop_for,
        label: bodyofRequest.search, // optional
      });

    _post(
      `${SERVER_URL}${URL.searchURL}` + page + val,
      {}, // all headers here (if)
      JSON.stringify(bodyofRequest), // body here (if)
      (res) => {
        // function to perform on success
        let responseObject = res;
        if (responseObject.status) {
          dispatch({
            type: types.SEARCH_SUCCESS,
            payload: res,
          });
          cb(res.data);
        } else {
          cb([]);
          dispatch({
            type: types.SEARCH_FAILED,
            payload: responseObject,
          });
        }

        // Alert.alert(JSON.stringify(res));
      },
      (err) => {
        // function to perform on failure
        
        cb([]);
        dispatch({ type: types.SEARCH_FAILED, error: err.message });
        Alert.alert(err.message);
      }
    );
  };
};
export const getPropertyById = (property_id) => {
  return (dispatch) => {
    dispatch({ type: types.GET_PROPERTY_REQUEST });

    _get(
      `${SERVER_URL}${URL.getProperty}${property_id}`,
      {}, // all headers here (if)
      {}, // body here (if)
      (res) => {
        // function to perform on success
        
        dispatch({
          type: types.GET_PROPERTY_SUCCESS,
          payload: res,
        });
      },
      (err) => {
        // function to perform on failure
        
        dispatch({ type: types.GET_PROPERTY_FAILED, error: err });
        Alert.alert(err.message);
      }
    );
  };
};
export const saveSearchObject = (obj) => {
  return (dispatch) => {
    dispatch({
      type: types.SAVE_SEARCH_OBJECT,
      payload: obj,
    });
  };
};
export const saveProperty = (property_id, bodyofRequest) => {
  return (dispatch) => {
    dispatch({ type: types.SAVE_PROPERTY_REQUEST });

    _put(
      `${SERVER_URL}${URL.saveProperty}${property_id}`,
      {}, // all headers here (if)
      JSON.stringify({}), // body here (if)
      (res) => {
        // function to perform on success
        
        dispatch(reSearch(bodyofRequest));
        dispatch(getSavedProperties());
        dispatch({
          type: types.SAVE_PROPERTY_SUCCESS,
          payload: res,
        });
      },
      (err) => {
        // function to perform on failure
        
        dispatch({ type: types.SAVE_PROPERTY_FAILED, error: err });
        Alert.alert(err.message);
      }
    );
  };
};
export const reSearch = (bodyofRequest) => {
  let params = store.getState()?.appReducer?.sortValue
    ? store.getState()?.appReducer?.sortValue
    : "&sortBy=distance&order=ASC";

  return (dispatch) => {
    dispatch({ type: types.RE_SEARCH_REQUEST });

    _post(
      `${SERVER_URL}${URL.searchURL}` + params,
      {}, // all headers here (if)
      JSON.stringify(bodyofRequest), // body here (if)
      (res) => {
        // function to perform on success
        
        let responseObject = res;

        dispatch({
          type: types.RE_SEARCH_SUCCESS,
          payload: responseObject,
        });
        // Alert.alert(JSON.stringify(res));
      },
      (err) => {
        // function to perform on failure
        
        dispatch({ type: types.RE_SEARCH_FAILED, error: err.message });
        Alert.alert(err.message);
      }
    );
  };
};
export const getSavedProperties = () => {
  return (dispatch) => {
    dispatch({ type: types.GET_SAVED_REQUEST });

    _get(
      `${SERVER_URL}${URL.getSavedProperties}`,
      {}, // all headers here (if)
      {}, // body here (if)
      (res) => {
        // function to perform on success
        
        dispatch({
          type: types.GET_SAVED_SUCCESS,
          payload: res,
        });
      },
      (err) => {
        // function to perform on failure
        
        dispatch({ type: types.GET_SAVED_FAILED, error: err });
        Alert.alert(err.message);
      }
    );
  };
};
export const savePageData = (data) => {
  return (dispatch) => {
    dispatch({
      type: types.PAGE_DATA,
      payload: data,
    });
  };
};
export const removeTenantFromProperty = (opridId, navigation) => {
  return (dispatch) => {
    dispatch({ type: types.REMOVE_TENANT });
    const url = URL.removeTenant.replace("{opridId}", opridId);
    
    _delete(
      `${SERVER_URL}${url}`,
      {}, // all headers here (if)
      JSON.stringify({}), // body here (if)
      (res) => {
        // function to perform on success
        
        dispatch({
          type: types.SHOW_ALERT,
          alertType: "success",
          text: "Property has been removed from your profile",
        });
        // Alert.alert(res.data);
        navigation("My Account");
      },
      (err) => {
        // function to perform on failure
        
        dispatch({ type: types.REMOVE_TENANT_FAIL, error: err });
        Alert.alert(err.message);
      }
    );
  };
};
