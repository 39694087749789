import React, { useState } from "react";
import {
  Image,
  TouchableOpacity,
  View,
  SafeAreaView,
  FlatList,
  Text,
} from "react-native";

import getPlatformType from "../../helpers/getPlatform";
// import { TextContainer, Front } from "./styles";
const layoutType = getPlatformType();
const serviceProvider = [
  {
    key: 1,
    service: "Council Tax",
    provider: "Click to view details",
    icon: require("../../assets/SVG/council.svg"),
    id:"SERVICE_COUNCIL"
  },
  {
    key: 2,
    service: "School",
    provider: "Click to view details",
    icon: require("../../assets/SVG/school.svg"),
    id:"SERVICE_SCHOOL"
  },
  {
    key: 3,
    service: "Transport",
    provider: "Click to view details",
    icon: require("../../assets/SVG/transport.svg"),
    id:"SERVICE_TRANSPORT"
  },
  // {
  //   key: 3,
  //   service: "Gas Provider",
  //   provider: "Coming Soon..",
  //   icon: require("../../assets/images/gas.png"),
  // },
  // {
  //   key: 4,
  //   service: "Electricty Provider",
  //   provider: "Coming Soon..",
  //   icon: require("../../assets/images/electricity.png"),
  // },
];
const ServiceProvider = (props) => {
  const { childText, fontSize, color1, color2 } = props;
  
  return (
    <View style={{ flex: 1 }}>
      <FlatList
        data={serviceProvider}
        renderItem={({ item }) => (
          <TouchableOpacity
          
            onPress={() => props.onServiceSelect(item.service)}
            style={{
              width: "100%",
              height: 60,
              flexDirection: "row",
              borderWidth: 1,
              borderRadius: 7,
              borderColor: "#b4b4b4",
              marginTop: 10,
              justifyContent: "center",
              alignItems: "center",
            }}
            id={item.id}
          >
            <View
              style={{
                flex: 0.5,
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <View style={{ width: "20%", height: "100%" }}>
                <Image
                  resizeMode={"contain"}
                  source={item.icon}
                  style={{ width: 26, height: 26 }}
                />
              </View>

              <Text
                style={{
                  width: "60%",
                  textAlign: "left",
                  marginLeft: 10,
                  color: "black",
                  fontWeight: "bold",
                }}
                id={item?.id+"_TEXT"}
              >
                {item.service}
              </Text>
            </View>
            <View
              style={{ width: 1, height: "40%", backgroundColor: "grey" }}
            />
            <View
              style={{
                flex: 0.5,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text style={{ color: "black", fontWeight: "bold" }}>
                {item.provider}
              </Text>
            </View>
          </TouchableOpacity>
        )}
      />
    </View>
  );
};

export default ServiceProvider;
