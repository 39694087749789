import styled from "styled-components/native";
import getPlatformType from "../../helpers/getPlatform";

const layoutType = getPlatformType();

let ReportWrapper,
  ReportBox,
  ReportDateName,
  ReportDate,
  ReportNameText,
  ReportDetails,
  ReportDetailsText,
  ReportLabel,
  ReportLabelBox,
  ReportDetailsBox,
  Container;


if (layoutType == "phone") {
  Container = styled.View`
  justify-content:center;
   width:100%;
   padding-right:16px;
   padding-left:16px;
   padding-bottom:16px;
  `;
  ReportWrapper = styled.View`
  background-color: #ffffff;
  box-shadow: rgba(128, 128, 128, 0.4) 3px 2px 3px;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
    margin-bottom: 50px;
`;
  ReportBox = styled.View`
  width: 744px;
  padding: 10px 0px;
`;
  ReportDateName = styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding: 15px 0px;
`;
  ReportDate = styled.Text`
  color: #000000;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
`;
  ReportNameText = styled.Text`
  color: #e71e82;
`;
  ReportDetailsBox = styled.View`
  background-color: #ffff;
  padding: 10px 10px 10px 10px;
`;
  ReportDetails = styled.View`
  width: "85%";
  background-color: #ffffff;
  border: 1px solid #d0d0d0;
  border-radius: 5px;
  margin: 10px 10px 10px 10px;
  padding: 10px 10px 10px 10px;
`;
  ReportDetailsText = styled.Text`
  color: #000000;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 20px;
`;
  ReportLabelBox = styled.View`
  padding: 10px 0px 20px 0px;
`;
  ReportLabel = styled.Text`
color: #000000;
font-size: 14px;
letter-spacing: 0;
line-height: 17px;
`;
}
if (layoutType == "web") {
  Container = styled.View`
  width: 1124px;
  max-width: 1124px;
  margin: 0 auto;
  margin-bottom:10;
  `;
  ReportWrapper = styled.View`
  background-color: #ffffff;
  box-shadow: rgba(128, 128, 128, 0.4) 3px 2px 3px;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
  margin-bottom: 50px;
  min-height:50vh;
`;
  ReportBox = styled.View`
  width: 744px;
  padding: 10px 0px;
`;
  ReportDateName = styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding: 15px 0px;
`;
  ReportDate = styled.Text`
  color: #000000;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
`;
  ReportNameText = styled.Text`
  color: #e71e82;
`;
  ReportDetailsBox = styled.View`
`;
  ReportDetails = styled.View`
  width: 744px;
  border: 1px solid #d0d0d0;
  border-radius: 5px;
  padding: 20px;
`;
  ReportDetailsText = styled.Text`
  color: #000000;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 20px;
`;
  ReportLabelBox = styled.View`
padding: 15px 0px;
`;
  ReportLabel = styled.Text`
color: #000000;
font-size: 14px;
letter-spacing: 0;
line-height: 17px;
`;
}
else {
  Container = styled.View`
  width: 1124px;
  max-width: 1124px;
  margin: 0 auto;
  margin-bottom:10;
  `;
  ReportWrapper = styled.View`
  background-color: #ffffff;
  box-shadow: rgba(128, 128, 128, 0.4) 3px 2px 3px;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
    margin-bottom: 50px;
`;
  ReportBox = styled.View`
  width: 744px;
  padding: 10px 0px;
`;
  ReportDateName = styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding: 15px 0px;
`;
  ReportDate = styled.Text`
  color: #000000;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
`;
  ReportNameText = styled.Text`
  color: #e71e82;
`;
  ReportDetailsBox = styled.View`
`;
  ReportDetails = styled.View`
  width: 744px;
  border: 1px solid #d0d0d0;
  border-radius: 5px;
  padding: 20px;
`;
  ReportDetailsText = styled.Text`
  color: #000000;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 20px;
`;
  ReportLabelBox = styled.View`
padding: 15px 0px;
`;
  ReportLabel = styled.Text`
color: #000000;
font-size: 14px;
letter-spacing: 0;
line-height: 17px;
`;
}

export {
  ReportWrapper,
  ReportBox,
  ReportDateName,
  ReportDate,
  ReportNameText,
  ReportDetails,
  ReportDetailsText,
  ReportLabel,
  ReportLabelBox,
  ReportDetailsBox,
  Container
}



// import React from "react";
// import styled from "styled-components/native";
// import getPlatformType from "../../helpers/getPlatform";

// const layoutType = getPlatformType();
// let Container, ReportWrapper, ReportBox;
// let FormContainer, ContactTitle, ContactHead, FormControls;

// if (layoutType == "phone") {
//   Container = styled.View`
//   justify-content:center;
//    width:100%;
//    align-items:center;
//    padding-right:16px;
//    padding-left:16px;
//   `;
//   FormContainer = styled.View`
//   justify-content:center;
//    width:100%;
//    align-items:center;
//    flex-direction:column;
//    z-index:999;
//   `;
//   ContactTitle = styled.Text`
//    font-size:22px;
//    font-weight:bold;
//    text-align:left;
//    width: 100%;
//    margin-bottom:24px;
//    `;
//   ContactHead = styled.View`
//    width: 100%;
//    flex-direction: row;
//    margin-bottom:40px;
//    margin-top:24px;
//    `;
//   FormControls = styled.View`
//    background: #ffffff;
//    margin-bottom: 16px;
//    flex-direction: column;
//    justify-content: center;
//    `;

// } else if (layoutType == "web") {
//   // Container = styled.View`
//   //   justify-content:center;
//   // flex-direction: row;
//   //  width:935px;
//   //  align-self:center;
//   //  align-items:center;
//   // `;
//   // FormContainer = styled.View`
//   // justify-content:center;
//   //  width:100%;
//   //  align-items:center;
//   //  flex-direction: row;
//   //  z-index:999;
//   //  `;
//   // ContactTitle = styled.Text`
//   //  font-size:22px;
//   //  font-weight:bold;
//   //  text-align:left;
//   //  width: 100%;
//   //  margin-bottom:24px;
//   //  `;
//   // ContactHead = styled.View`
//   //  width: 100%;
//   //  flex-direction: row;
//   //  margin-bottom:32px;
//   //  `;
//   // FormControls = styled.View`
//   //  background: #ffffff;
//   //  margin-bottom: 16px;
//   //  flex-direction: column;
//   //  justify-content: center;
//   //  `;





//   Container = styled.View`
//   width: 1124px;
//   max-width: 1124px;
//   margin: 0 auto;
//   margin-bottom:10;
//   `;
//   ReportWrapper = styled.View`
//   background-color: #ffffff;
//   box-shadow: rgba(128, 128, 128, 0.4) 3px 2px 3px;
//   justify-content: center;
//   align-items: center;
//   padding-bottom: 50px;
//     margin-bottom: 50px;
// `;
//   ReportBox = styled.View`
//   width: 744px;
//   padding: 10px 0px;
// `;
//   ReportDateName = styled.View`
//   flex-direction: row;
//   justify-content: space-between;
//   padding: 15px 0px;
// `;
//   ReportDate = styled.Text`
//   color: #000000;
//   font-size: 14px;
//   letter-spacing: 0;
//   line-height: 17px;
// `;
//   ReportNameText = styled.Text`
//   color: #e71e82;
// `;
//   ReportDetailsBox = styled.View`
// `;
//   ReportDetails = styled.View`
//   width: 744px;
//   border: 1px solid #d0d0d0;
//   border-radius: 5px;
//   padding: 20px;
// `;
//   ReportDetailsText = styled.Text`
//   color: #000000;
//   font-size: 13px;
//   letter-spacing: 0;
//   line-height: 20px;
// `;
//   ReportLabelBox = styled.View`
// padding: 15px 0px;
// `;
//   ReportLabel = styled.Text`
// color: #000000;
// font-size: 14px;
// letter-spacing: 0;
// line-height: 17px;
// `;


// } else {
//   Container = styled.View`
//   justify-content:center;
//   flex-direction: row;
//   width:100%;
//   align-items:center;
//   padding-right:120px;
//   padding-left:120px; 
//   `;
//   FormContainer = styled.View`
//   justify-content:center;
//   flex-direction: row;
//   width:100%;
//   align-items:center;
//   z-index:999;
//   `;
//   ContactTitle = styled.Text`
//   font-size:22px;
//   font-weight:bold;
//   text-align:left;
//   width: 100%;
//   margin-top:24px;
//   margin-bottom:24px;
//   `;
//   ContactHead = styled.View`
//   width: 100%;
//   flex-direction: row;
//   margin-bottom:32px;
//   `;
//   FormControls = styled.View`
//    background: #ffffff;
//    margin-bottom: 16px;
//    flex-direction: column;
//    justify-content: center;
//    `;
// }
// const BottomContainer = styled.View`
//   width:100%;
//   height:20%;
// `;
// const LeftPanal = styled.View`
//   flex-grow: 1;
//   margin-right: 6px;
// `;
// const BackTextWrapper = styled.Text`
//   height: 17px;
//   color: #000000;
//   font-family: Lato;
//   font-size: 14px;
//   letter-spacing: 0;
//   line-height: 17px;
// `;
// export {
//   Container, ReportWrapper, ReportBox,
//   BottomContainer,
//   FormContainer, ContactTitle, ContactHead, FormControls,
//   LeftPanal,
//   BackTextWrapper
// };













