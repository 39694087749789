import moment from 'moment';
import { Text, View, Image, TouchableOpacity } from 'react-native';
import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { tdsActions } from './tdsRedux/tds.actions';
import { backgroundTdsArr } from './backgroundTdsArr';
import { navigateAction, replaceAction } from '../../helpers/navigationHelpers';
import BackGroundGenerator from '../LandingPages/common/BackGroundGenerator';
import CustomTextInput from '../../commonComponents/customTextInput/customTextInput';
import DatePickerComponent from '../../commonComponents/datepicker/DatePickerComponent';
import {
  Container,
  ButtonWrapper,
  RowContainer,
  Section,
  FormText,
  Heading,
  SubHeading,
  ActivateButtonWrapper,
  primaryColor,
  Typography,
  BoxShadow,
} from './style';
import TdsLogin from './login';
import { MainSection } from '../openFlow/style';
import getPlatformType from '../../helpers/getPlatform';
import ScoctLandlogin from './ScoctLandlogin';
import { tdsLocationSelected } from '../../redux/actions/app';
import { COLORS } from '../../constants/colorPallete';
const layoutType = getPlatformType();
const TdsScoctland = (props) => {
  const { navigation } = props;
  const dispatch = useDispatch();
  const [type, setType] = useState('web');
  const [showError, setShowError] = useState(false);
  const [state, setState] = useState({ deposit_start_date: null });
  const searchDepositResponse = useSelector((state) => state.tds?.searchedDeposit);

  const handleChange = (value, field) => {
    setState({ ...state, [field]: value });
  };

  const renderInfoBox = (type, text) => {
    return (
      <View
        style={{
          width: '100%',
          backgroundColor: type == 'success' ? '#C4F5D8' : '#F5C4C4',
          justifyContent: 'center',
          alignItems: 'center',
          paddingVertical: 50,
          marginVertical: 20,
          borderRadius: 10,
        }}
        id={'TDS-INFO-BOX'}
      >
        <Text
          style={{
            color: type == 'success' ? '#4C664B' : '#5E0D0D',
            fontSize: 23,
            fontWeight: '600',
          }}
        >
          {' '}
          {text ? text : ''}
        </Text>
      </View>
    );
  };
  function checkValue(value) {
    handleChange(handleDecimalsOnValue(value), 'deposit_amount');
  }

  function handleDecimalsOnValue(value) {
    const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,2})/s;
    return value.match(regex)[0];
  }

  const renderHeaderContent = () => {
    return (
      <MainSection
        style={{
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignSelf: 'center',
          marginHorizontal: 16,
          width:  layoutType=="phone"?"90%": 652,
        }}
      >
        <View style={{ width: layoutType=="phone"?"95%": 428, alignSelf: 'center',justifyContent:'center',alignItems:"center" }}> 
        <ButtonWrapper
            color={'white'}
            bcolor={COLORS.tds}
            style={{ marginTop: 0, width: 250, height: 49 }}
            onPress={() => {
              navigateAction(navigation, 'tdsLocation');
              dispatch(tdsLocationSelected(null));
            }}
          >
            <Text style={{ color: COLORS.tds }}>Switch to England and Wales</Text>
          </ButtonWrapper>
        <Typography
          style={{ width: layoutType=="phone"?"90%":428, alignSelf: 'center' }}
          bold={800}
          align={'center'}
          top={'10px'}
          size={'18px'}
          id={'TDS-LOGIN'}
          color={'white'}
        >
          Please click here if your property is located in England or Wales and your deposit is
          protected by Tenancy Deposit Scheme.{' '}
        </Typography>
        </View>
      </MainSection>
    );
  };
  return (
    <>
      <BackGroundGenerator
        replace={'Dashboard'}
        isBack={true}
        backNavigation={() => replaceAction(navigation, 'Dashboard')}
        isTds
        props={props}
        hideMiddleLogo={false}
        layoutType={(data) => {
          setType(data);
        }}
        isTLYF
        backgroundArr={backgroundTdsArr}
        paddingtop={150}
      >
        {renderHeaderContent()}
        <MainSection
          style={
            layoutType == 'phone'
              ? {
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  marginTop: 10,
                  alignSelf: 'center',
                  marginHorizontal: 16,
                }
              : {
                  flexDirection: 'row',
                  justifyContent: 'center',
                  marginTop: 10,
                  width: '70%',
                  alignSelf: 'center',
                }
          }
        >
          <View style={layoutType == 'phone' ? { flex: 1, marginTop: 26 } : { flex: 0.5 }}>
            <BoxShadow style={{ padding: 16 }}>
              <ScoctLandlogin navigation={navigation} />
            </BoxShadow>
          </View>
        </MainSection>
      </BackGroundGenerator>
    </>
  );
};

export default TdsScoctland;
