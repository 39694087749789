import React from "react";
import styled from "styled-components/native";
import getPlatformType from "../../helpers/getPlatform";
const layoutType = getPlatformType();
import variables from '../../components/openFlow/atoms/variables.json'
let HeaderWrapper;
let Container;
let DrawerIcon;
let ObxLogo;
let MainMenu;
let MainHeader;
let LoginText;
let SearchText;
let BottomBar,
  AlertCol,
  FilterCol,
  BackBtn,
  EditSearch,
  BackTextWrapper,
  BackWrapper,
  MenuItem,
  MenuItemActive,
  MenuDropDownItemActive,
  ObxLogoWrapper,
  MyAccount;
//
// ─── MOBILE SPECIFIC CSS ────────────────────────────────────────────────────────
//

if (layoutType == "phone") {
  HeaderWrapper = styled.View`
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    margin-bottom: ${(props) =>
      props.noBottomMargin ? '2px' : "12px"};
    background-color: ${(props) =>
      props.headerColor ? props.headerColor : "#ffffff"};
  `;

  Container = styled.View`
    width: 100%;
    padding-right: 16px;
    padding-left: 16px;
    margin-right: auto;
    margin-left: auto;
    position: relative;
  `;

  DrawerIcon = styled.Image`
    width: 24px;
    height: 18px;
    display: flex;
    flex-grow: 1;
    margin: auto;
    z-index: 999;
  `;

  ObxLogoWrapper = styled.TouchableOpacity`
    width: 105px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 9;
    margin: auto;
  `;
  ObxLogo = styled.Image`
    width: 105px;
    height: 50px;
  `;
  MainMenu = styled.View`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  `;
  MainHeader = styled.View`
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    padding: 15px 0;
  `;
  // justify-content: flex-end;
  LoginText = styled.Text`
    font-size: 14px;
    color: #000000;
    font-weight: bold;
  `;
  SearchText = styled.Text`
    font-size: 14px;
    color: #000000;
    font-weight: bold;
    margin-right: 64px;
    display: none;
  `;
  BottomBar = styled.View`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 0 16px;
    display: flex;
  `;
  // border-top: 1px solid #D0D0D0;

  AlertCol = styled.View`
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
  `;
  FilterCol = styled.View`
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
  `;

  BackBtn = styled.Text`
    margin-right: 12px;
    font-size: 15px;
    color: #000000;
  `;

  EditSearch = styled.Text`
    margin-right: 12px;
    font-size: 15px;
    color: #000000;
  `;
  BackWrapper=styled.Text`
  display: flex;
  margin-top:30px;
  margin-left:20px;
  border-color:${variables.GREEN};
  border-width:1.5px;
  border-radius:1000;
  width:130px;
  height:40px;
  justify-content:center;
  align-items:center;
  background-color:white;
`;
  BackTextWrapper = styled.Text`
    display: flex;
    z-index: 20;
  `;
  MyAccount = styled.Text`
    
  `;
}
//
// ─── WEB SPECIFIC CSS ───────────────────────────────────────────────────────────
//
else if (layoutType == "web") {
  HeaderWrapper = styled.View`
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
     background-color: ${(props) =>
      props.headerColor ? props.headerColor : "#ffffff"};
  `;

  Container = styled.View`
    width: 90%;
    margin-right: auto;
    margin-left: auto;
  `;

  DrawerIcon = styled.Image`
    display: none;
  `;

  ObxLogo = styled.Image`
    width: 100px;
    height: 42px;
  `;
  ObxLogoWrapper = styled.TouchableOpacity`
    width: 172px;
    height: 50px;
  `;
  MainMenu = styled.View`
    display: flex;
    flex-grow: 9;
    flex-direction: row;
    justify-content: flex-end;
  `;
  MainHeader = styled.View`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: 16px 0 16px;
  `;
  LoginText = styled.Text`
    font-size: 15px;
    color: ${(props) => (props.color ? props.color : "#000000")};
    font-weight: bold;
  `;
  SearchText = styled.Text`
    font-size: 15px;
    color: ${(props) => (props.color ? props.color : "#000000")};
    font-weight: bold;
    margin-right: 64px;
  `;
  BottomBar = styled.View`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 0 16px;
  `;

  AlertCol = styled.View`
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
  `;
  FilterCol = styled.View`
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
  `;

  BackBtn = styled.Text`
    margin-right: 12px;
    font-size: 15px;
    color: #000000;
  `;
  EditSearch = styled.Text`
    margin-right: 12px;
    font-size: 15px;
    color: #000000;
  `;
  BackWrapper=styled.Text`
  display: flex;
  margin-left:60px;
  margin-top:30px;
  border-color:${variables.GREEN};
  border-width:1.5px;
  border-radius:1000;
  width:120px;
  height:40px;
  justify-content:center;
  align-items:center;
  background-color:white;
`;
  BackTextWrapper = styled.Text`
  display:none
    `;
  MenuItemActive = styled.Text`
    font-size: 15px;
    color: ${(props) => (props.color ? props.color : "#000000")};
    font-weight: bold;
    margin-left: 64px;
  `;
  MenuDropDownItemActive = styled.Text`
    font-size: 15px;
    color: ${(props) => (props.color ? props.color : "#000000")};
    font-weight: bold;
  `;
  MenuItem = styled.Text`
    font-size: 15px;
    color: ${(props) => (props.color ? props.color : "#000000")};
    font-weight: bold;
    margin-left: 64px;
  `;
}

//
// ─── TABLET SPECIFIC CSS ────────────────────────────────────────────────────────
//
else {
  HeaderWrapper = styled.View`
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    margin-bottom: ${(props) =>
      props.noBottomMargin ? '4px' : "12px"};
    background-color: ${(props) =>
      props.headerColor ? props.headerColor : "#ffffff"};
  `;

  Container = styled.View`
    width: 100%;
    padding-right: 60px;
    padding-left: 60px;
    margin-right: auto;
    margin-left: auto;
  `;

  DrawerIcon = styled.Image`
    width: 24px;
    height: 18px;
    display: flex;
    flex-grow: 1;
  `;

  ObxLogo = styled.Image`
    width: 172px;
    height: 40px;
  `;
  ObxLogoWrapper = styled.TouchableOpacity`
    width: 172px;
    height: 40px;
    display: flex;
    justify-content: center;
    flex-grow: 9;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
  `;
  MainMenu = styled.View`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    position: absolute;
    right: 0;
  `;
  MainHeader = styled.View`
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    padding: 32px 0;
  `;
  LoginText = styled.Text`
    font-size: 15px;
    color: #000000;
    font-weight: bold;
  `;
  SearchText = styled.Text`
    font-size: 15px;
    color: #000000;
    font-weight: bold;
    margin-right: 64px;
    display: none;
  `;
  BottomBar = styled.View`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 18px 0 16px;
  `;
  // border-top: 1px solid #D0D0D0;

  AlertCol = styled.View`
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
  `;
  FilterCol = styled.View`
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
  `;

  BackBtn = styled.Text`
    margin-right: 12px;
    font-size: 15px;
    color: #000000;
  `;

  EditSearch = styled.Text`
    margin-right: 12px;
    font-size: 15px;
    color: #000000;
  `;
  BackTextWrapper = styled.Text`
    display: flex;
    z-index: 20;
  `;
  BackWrapper=styled.Text`
  display: flex;
  margin-left:40px;
  margin-top:30px;
  border-color:${variables.GREEN};
  border-width:1.5px;
  border-radius:1000;
  width:120px;
  height:40px;
  justify-content:center;
  align-items:center;
  background-color:white;
`;
  MyAccount = styled.Text`
    position: absolute;
    right: 0;
  `;
}

export {
  HeaderWrapper,
  Container,
  DrawerIcon,
  MainMenu,
  ObxLogo,
  MainHeader,
  LoginText,
  SearchText,
  BottomBar,
  AlertCol,
  FilterCol,
  BackBtn,
  EditSearch,
  BackTextWrapper,
  MenuItem,
  MenuItemActive,
  MenuDropDownItemActive,
  ObxLogoWrapper,
  MyAccount,
  BackWrapper
};
