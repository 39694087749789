import React, { useState } from "react";

import {
  View,
  Text,
  SafeAreaView,
  Image,
  TouchableOpacity,
  Platform,
  ScrollView,
  Button,
  Linking,
  TouchableWithoutFeedback,
} from "react-native";
import { Entypo } from "@expo/vector-icons";

import { GreyText, DetailsTop, BackTextWrapper } from "./styles";

const CouncilTax = (props) => {
  const { data } = props;
  return (
    <>
      <BackTextWrapper onPress={() => props.ResetService()}>

          <Entypo
              name="chevron-left"
              size={18}
              color="#000"
              // style={{ verticalAlign: "bottom" }}
          />
        {" "}
        Back
      </BackTextWrapper>

      <GreyText>Council Tax</GreyText>

      <View
        style={{
          width: "100%",
          minHeight: 60,
          flexDirection: "row",
          borderWidth: 1,
          borderRadius: 7,
          borderColor: "#b4b4b4",
          marginTop: 10,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <View
          style={{
            flex: 0.5,
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <Text
            style={{
              width: "60%",
              textAlign: "left",
              marginLeft: 10,
              color: "black",
              fontWeight: "bold",
            }}
            id={"COUNCIL_ID"}
          >
            Council
          </Text>
        </View>
        <View style={{ width: 1, backgroundColor: "grey", height: "80%" }} />
        <View
          style={{
            flex: 0.5,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text style={{ color: "black", fontWeight: "bold" }}>
            {data?.council ? data.council : <Text>Information Not Available</Text>}
          </Text>
        </View>
      </View>

      <View
        style={{
          width: "100%",
          minHeight: 60,

          flexDirection: "row",
          borderWidth: 1,
          borderRadius: 7,
          borderColor: "#b4b4b4",
          marginTop: 10,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <View
          style={{
            flex: 0.5,
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <Text
            style={{
              width: "60%",
              textAlign: "left",
              marginLeft: 10,
              color: "black",
              fontWeight: "bold",
            }}
          >
            Council Tax Rating
          </Text>
        </View>
        <View style={{ width: 1, backgroundColor: "grey", height: "80%" }} />
        <View
          style={{
            flex: 0.5,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text style={{ color: "black", fontWeight: "bold" }}>
            {data?.council_rating ? data?.council_rating : <Text>Information Not Available</Text>}
          </Text>
        </View>
      </View>
      <View
        style={{
          width: "100%",
          minHeight: 60,

          flexDirection: "row",
          borderWidth: 1,
          borderRadius: 7,
          borderColor: "#b4b4b4",
          marginTop: 10,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <View
          style={{
            flex: 0.5,
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <Text
            style={{
              width: "60%",
              textAlign: "left",
              marginLeft: 10,
              color: "black",
              fontWeight: "bold",
            }}
          >
            Annunal Charges
          </Text>
        </View>
        <View style={{ width: 1, backgroundColor: "grey", height: "80%" }} />
        <View
          style={{
            flex: 0.5,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text style={{ color: "black", fontWeight: "bold" }}>
            {data?.annual_change ? data?.annual_change : <Text>Information Not Available</Text>}
          </Text>
        </View>
      </View>
      <View
        style={{
          width: "100%",
          minHeight: 60,

          flexDirection: "row",
          borderWidth: 1,
          borderRadius: 7,
          borderColor: "#b4b4b4",
          marginTop: 10,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <View
          style={{
            flex: 0.5,
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
         <View
         style={{
          width: "60%",

         }}>
          <Text
            style={{
              textAlign: "left",
              color: "black",
              fontWeight: "bold",
            }}
          >
            Price Bands
          </Text>
          <Text
            style={{
              textAlign: "left",
              color: "black",
              fontWeight: "bold",
              marginTop:5
            }}
          >
            (Approximate Costs)
          </Text>
        </View> 
        </View>
        <View style={{ width: 1, backgroundColor: "grey", height: "80%" }} />
        <View
          style={{
            flex: 0.5,
            justifyContent: "center",
            alignItems: "center",
            paddingVertical: 20,
          }}
        >
          {data?.council_tax ?
            Object.keys(data?.council_tax).map(function (keyName, keyIndex) {
              
              let kn=keyName.split("_").join(" ");
              let Cap_Kn=kn.charAt(0).toUpperCase() + kn.slice(1);
              return (
                <Text style={{ color: "black", fontWeight: "bold",width:'100%',textAlign:'center' }}>
                  {Cap_Kn.substring(0, Cap_Kn.length- 1)+Cap_Kn.charAt(Cap_Kn.length-1).toUpperCase()} : £{data?.council_tax[keyName]}
                </Text>
              );
            }) : <Text style={{ color: "black", fontWeight: "bold" }}>Information Not Available</Text>}  
        </View>
      </View>
    </>
  );
};
export default CouncilTax;
