import React from "react";
import styled from "styled-components/native";
import getPlatformType from "../../helpers/getPlatform";

const layoutType = getPlatformType();

let Container, Heading, HeadingText, FormBox, ButtonBox, TextBox, Title, DocumentBox, BackBtnBox;

Container = styled.View`
  width: 1130px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;
Heading = styled.View`
  width: 100%;
`;
FormBox = styled.View`
  width: 550px;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 30px 20px;
  background-color: #ffffff;
  margin-top: 20px;
  margin-bottom: 30px;
`;

ButtonBox = styled.View`
  width: 100%;
  margin-top: 20px;
  align-items:center;
  justify-content: space-around;
`;
TextBox = styled.View`
  width: 100%;
`;
HeadingText = styled.Text`
  font-weight: 600;
  font-size: 23px;
  margin-bottom: 30px;
  align-self:center;
`;
Title = styled.Text`
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
    padding-bottom: 8px;
  `;

  DocumentBox = styled.TouchableOpacity`
    border: 1px dashed #d0d0d0;
    width: 100%;
    height: 100px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  `;

BackBtnBox = styled.TouchableOpacity`
display: flex;
flex-direction: row;
justify-content: flex-start;
align-items: center;
`;
if (layoutType == "phone") {
  Container = styled.View`
    width: 342px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-top: 20px;
  `;
  FormBox = styled.View`
    width: 100%;
    height: 400px;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 20px;
    background-color: #ffffff;
    margin-top: 15px;
  `;
  Title = styled.Text`
    height: 18px;
    color: #000000;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
  `;
} else if (layoutType == "web") {
} else {
  Container = styled.View`
  width: 944px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;
Title = styled.Text`
    height: 17px;
    color: #000000;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 17px;
  `;
}

export { Container, Heading, HeadingText, FormBox, ButtonBox, TextBox, Title, DocumentBox, BackBtnBox};
