import React from "react";
import styled from "styled-components/native";
import getPlatformType from "../../helpers/getPlatform";

const layoutType = getPlatformType();

let BubbleTextInput,
  BubbleText,
  BubbleTextView,
  BubbleIcon,
  BubbleLabel,
  BubbleSuperText,
  BubbleError,
  BubbleWrapper;

BubbleWrapper = styled.View`
  padding-top: 10px;
  padding-bottom: 10px;
  flex-wrap: wrap;
`;

BubbleLabel = styled.Text`
  height: 18px;
  color: #000000;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 18px;
  text-align: left;
  margin: 5px 0px;
  padding-left: 2px;
`;
BubbleError = styled.Text`
  height: 18px;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0;
  text-align: right;
  margin: 5px 0px;
  padding-right: 2px;
  color: red;

`;
BubbleTextInput = styled.TextInput`
  height: 45px;
  border: 1px solid #d0d0d0;
  border-radius: 5px;
  color: #000000;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 18px;
  padding: 10px;
`;

BubbleText = styled.View`
  border: 1px solid #d0d0d0;
  border-radius: 16px;
  position: relative;
  padding: 5px 10px;
  min-height: 35px;
  min-width: 100px;
  margin: 5px 10px 5px 0px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  max-width: 50%;
`;
BubbleSuperText = styled.Text`
  text-align: left;
  color: #000000;
  font-size: 13px;
  letter-spacing: 0.41px;
  line-height: 16px;
  padding-left: 5px;
  padding-right: 5px;
`;

BubbleTextView = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 8px;
`;

BubbleIcon = styled.View``;

export {
  BubbleTextInput,
  BubbleText,
  BubbleTextView,
  BubbleIcon,
  BubbleLabel,
  BubbleWrapper,
  BubbleSuperText,
  BubbleError
};
