import React from "react";
import { TouchableOpacity, Text, ActivityIndicator, View } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { COLORS } from "../../constants/colorPallete";
import { TextBox, RightText, Title, OuterView } from "./buttonWithTitle.style";
import WideButton from "../wideButton";

export default class ButtonWithTitle extends React.Component {
  render() {
    const {
      backgroundColor,
      childText,
      onPress,
      height,
      loadingState,
      borderColor,
      borderWidth,
      childColor,
      width,
      whiteButton,
      borderRadius,
      title,
      rightText,
      button1Label,
      button2Label,
      button1BorderColor,
      button1BorderWidth,
      button2BorderColor,
      button2BorderWidth,
      button1Action,
      button2Action,
    } = this.props;
    return (
      <OuterView width={width}>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            paddingVertical: "2%",
          }}
        >
          {title ? <Title>{title}</Title> : null}

          {rightText ? (
            <View
              style={{
                paddingHorizontal: "2%",
              }}
            >
              <RightText>{rightText}</RightText>
            </View>
          ) : null}
        </View>
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <WideButton
            width={"48%"}
            gradBorderWidth={0.00000000001}
            whiteButton
            childText={button1Label}
            onPress={() => button1Action()}
            borderColor={button1BorderColor ? button1BorderColor : "#d0d0d0"}
            borderWidth={button1BorderWidth ? button1BorderWidth : 2}
          />
          <WideButton
            width={"48%"}
            gradBorderWidth={0.00000000001}
            whiteButton
            childText={button2Label}
            borderColor={button2BorderColor ? button2BorderColor : "#d0d0d0"}
            borderWidth={button2BorderWidth ? button2BorderWidth : 2}
            onPress={() => button2Action()}
          />
        </View>
      </OuterView>
    );
  }
}
