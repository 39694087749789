import moment from "moment";
import React, { useEffect, useState } from "react";
import { AntDesign } from "@expo/vector-icons";
import Modal  from "react-native-modal";
import { useDispatch, useSelector } from "react-redux";
import { FlatList, ScrollView, Switch, Text, TouchableOpacity, View } from "react-native";

import { Typography } from "../../style";
import { types } from "../../../../redux/actionTypes";
import { Wrapper } from "../../../idVerification/styles";
import { COLORS } from "../../../../constants/colorPallete";
import getPlatformType from "../../../../helpers/getPlatform";
import { BoxShadow } from "../../../identifyAndFinance/stylesNew";
import { tenantActions } from "../../openFlowRedux/tenant.actions";
import CustomTextInput from "../../../../commonComponents/customTextInput/customTextInput";
import { LineSaperator } from "../../../tds/style";

const ShareCode = () => {

  const dispatch = useDispatch();
  const layoutType = getPlatformType();
  const [shareStep, setShareStep] = React.useState(0);
  const [agentData, setAgentData] = React.useState([]);
  const [activeShareCode, setActiveShareCode] = React.useState([]);
  const [documents, setDocuments] = React.useState([]);
  const [agentEmail, setAgentEmail] = React.useState("");
  const globalState = useSelector((state) => state?.tenant);
  const docs = useSelector((state) => state?.authReducer?.docs);
  const [showShareModal, setShowShareModal] = React.useState(false);
  const [showError, setShowError] = useState(false);
  const [showExpired, setShowExpired] = useState(false);

  const [shareSettings, setShareSettings] = React.useState({
    share_affordability: false,
    share_pre_qualification: false,
    share_rental_ledger: false,
    share_right_to_rent: false,
    share_yoti_id: false,
  });

  const [points, setPoints] = useState([
    {
      name: "Affordability",
      isShared: false,
    },
    {
      name: "Rent Ready Reference",
      isShared: false,
    },
    {
      name: "Rent Record",
      isShared: false,
    },
    {
      name: "Right to Rent",
      isShared: false,
    },
    {
      name: "ID Verification",
      isShared: false,
    },
  ]);

  useEffect(() => {
    if (globalState?.sharedCodes?.share_codes.length) {
      setAgentData(sortObjectsByExpireDate(globalState?.sharedCodes?.share_codes));
      const activeShareCodes = sortObjectsByExpireDate(globalState?.sharedCodes?.share_codes)
      .filter((shareCode) => {
        const remainingTime = getRemainingTime(shareCode.expire_date);
        return remainingTime !== false;
      });
      setActiveShareCode(activeShareCodes);
    }
    if (globalState?.tenant?.sharedCodes?.share_settings) {
      setShareSettings(globalState?.tenant?.sharedCodes?.share_settings);
    }
  }, [globalState]);

  useEffect(() => {
    if (docs) {
      if (docs?.status) {
        setDocuments(docs?.data?.myDocuments);
      }
    }
  }, [docs]);

  const onSave = () => {
    if (!agentEmail) {
      dispatch({
        type: types.SHOW_ALERT,
        alertType: "error",
        text: "Email Address is required",
      });
    } else {
      const documentIds = [];
      documents.map((document) => {
        if (document.isShared) {
          documentIds.push(document.document_id);
        }
      });

      dispatch(
        tenantActions.saveAgentEmail({
            email: agentEmail,
            points,
            documents: documentIds,
          },
          (data) => {
            setAgentEmail("");
            dispatch(tenantActions.getCodesStatus());
            setShareStep(0);
            setShowShareModal(false);
          },
        ),
      );
    }
  };

  useEffect(() => {
    dispatch(tenantActions.getCodesStatus());
  }, []);

  function sortObjectsByExpireDate(objects) {
    return objects.sort((a, b) => new Date(b.expire_date) - new Date(a.expire_date));
  }

  function getRelativeTime(expire_date) {
    const now = moment();
    const expiration = moment(expire_date);
    const diffInDays = expiration.diff(now, "days");
    const diffInHours = expiration.diff(now, "hours") % 24; // Calculate remaining hours
    const diffInMinutes = expiration.diff(now, "minutes") % 60; // Calculate remaining minutes
    const daysRemaining = diffInDays > 0 ? diffInDays + " days " : "";
    const hoursRemaining = diffInHours > 0 ? diffInHours + " hours " : "";
    const minutesRemaining = diffInMinutes > 0 ? diffInMinutes + " minutes " : "";

    if (diffInDays > 0) {
      return daysRemaining + hoursRemaining + "remaining";
    }

    if (diffInHours > 0) {
      return hoursRemaining + minutesRemaining + "remaining";
    }

    if (diffInMinutes > 0) {
      return minutesRemaining + "remaining";
    }
    return "Expired";
  }

  const formatTime = (seconds) => {
    return new Date(seconds * 1000).toISOString()
    .slice(11, 19);
  };

  const getCompletedTime = (createdAt) => {
    const now = moment();
    createdAt = moment(createdAt);
    return 86400 - now.diff(createdAt, "seconds");
  };

  const getRemainingTime = (expire_date) => {
    const now = moment();
    const expiration = moment(expire_date);
    const diff = expiration.diff(now, "seconds");
    return diff > 0 ? diff : false;
  };

  const DetailCard = ({
    item,
    index,
  }) => {
    const remainingTime1 = getRemainingTime(item.expire_date);

    let {
      unique_string_id,
      email,
      expire_date,
    } = item;
    return (
      <>
        <View key={index} style={{
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: 20,
          width: "100%",
          paddingHorizontal: 30,
        }}>
          <View style={{
            flexDirection: "column",
            flex: 0.6,
          }}>
            {/* <Typography align={"left"} color={COLORS.BLACK} size={"16px"} bold={"700"}>
              {unique_string_id ? unique_string_id : "-"}
            </Typography> */}
            <Typography top={"6px"} align={"left"} color={COLORS.BLACK} size={"14px"} bold={"400"}>
              Shared with:
            </Typography>
            <Typography top={"4px"} align={"left"} color={COLORS.BLACK} size={"14px"} bold={"800"}>
              {email}
            </Typography>
            <TouchableOpacity
              onPress={() => {
                if (unique_string_id) {
                  window.open("https://agent.openbrix.co.uk/preview-tenants/" + unique_string_id, "_blank");
                }
              }}
            >
              <Typography align={"left"} top={"8px"} color={COLORS.LGREEN} size={"16px"} bold={"500"}>
                {getRelativeTime(expire_date) === "Expired" ? "" : "preview"}
              </Typography></TouchableOpacity>
          </View>
          <View style={{
            flexDirection: "column",
            flex: 0.4,
            alignItems: "flex-end",
          }}>
            {/* {remainingTime1 ? (
              <CountdownCircleTimer
                isPlaying={true}
                duration={86400}
                initialRemainingTime={getCompletedTime(item.createdAt)}
                size={100}
                strokeWidth={5}
                colors={[COLORS.LGREEN]}>
                {({ remainingTime }) => (
                  <View>
                    <Text>Expires in </Text>
                    <Text style={{ alignSelf: 'center' }}>{formatTime(remainingTime)}</Text>
                  </View>
                )}
              </CountdownCircleTimer>
            ) : (
              <Text style={{  color: 'red' }}>Expired</Text>
            )} */}
            {remainingTime1 ?
              <View style={{
                width: 80,
                height: 30,
                borderWidth: 1,
                borderRadius: 20,
                borderColor: COLORS.LGREEN,
                justifyContent: "center",
                alignItems: "center",
              }}>
                <Typography size={"15px"} color={COLORS.LGREEN}>Active</Typography>
              </View> :
              <View style={{
                width: 80,
                height: 30,
                borderWidth: 1,
                borderColor: "red",
                borderRadius: 20,
                justifyContent: "center",
                alignItems: "center",
              }}>
                <Typography size={"15px"} color={"red"}>Expired</Typography>
              </View>
            }
            <Typography top={"6px"} align={"right"} color={COLORS.BLACK} size={"14px"} bold={"400"}>
              {getRelativeTime(expire_date) == "Expired" ? moment(expire_date)
              .format("DD/MM/YYYY") : getRelativeTime(expire_date)}
            </Typography>

          </View>
        </View>
        <View style={{
          width: "100%",
          height: 0.5,
          backgroundColor: "#B2B2B2",
          marginTop: 20,
        }}/>
      </>
    );
  };

  const handleShare = (point: any) => {
    setPoints(
      points.map((point1) => {
        if (point.name == point1.name) {
          point1.isShared = !point1.isShared;
        }
        return point1;
      }),
    );
  };
  const [selectAll, setSelectAll] = useState(false);
  // Check if all items are selected
  const isAllSelected = points.every((point) => point.isShared);
  const [selectAllDocs, setSelectAllDocs] = useState(false);
  // Check if all items are selected
  const isAllSelectedDocs = documents.every((point) => point.isShared);
  const toggleSelectAll = () => {
    setSelectAll(!isAllSelected); // Toggle the "Select All" checkbox

    const updatedPoints = points.map((point) => ({
      ...point,
      isShared: !isAllSelected, // Toggle the selection state of all items
    }));
    setPoints(updatedPoints);
  };
  useEffect(() => {
    setSelectAll(isAllSelected);
  }, [isAllSelected]);

  const renderStep0 = () => {
    return (
      <View style={{ marginTop: 0 }}>
        <Text style={{
          fontSize: 16,
          fontWeight: "bold",
          marginVertical: 20,
        }}>
          Please select the items you want to share
        </Text>


        {/* Add Select All checkbox or switch */}
        <View style={{
          flexDirection: "row",
          alignItems: "center",
        }}>
          <View style={{
            flexDirection: "row",
            height: 56,
            alignItems: "center",
          }}>
            <View style={{
              flexDirection: "row",
              alignItems: "center",
            }}>

              <Switch
                onValueChange={toggleSelectAll}
                value={selectAll}
              />
            </View>

            <Typography align={"left"} color={COLORS.BLACK} size={"15px"} bold={"500"} left={30}>
              <Text>Select All</Text>
            </Typography>
          </View>
        </View>
        <LineSaperator color={COLORS.LGREEN}/>

        <View style={{ width: layoutType == "phone" ? "100%" : 358 }}>
          {points.map((item, index) =>
            <View style={{
              flexDirection: "row",
              height: 56,
              alignItems: "center",
            }}>
              <View style={{
                flexDirection: "row",
                alignItems: "center",
              }}>
                {/* <TouchableOpacity onPress={()=> handleShare(item)}>
                  <View style={{ width: 18, height: 18, backgroundColor:item.isShared?COLORS.LGREEN:'white',borderWidth:1 ,borderRadius: 2, justifyContent: "center", alignItems: "center" }}>
                  {item?.isShared &&  <FontAwesome name="check" size={10} color={"white"}/>}
                  </View>
                </TouchableOpacity> */}
                <Switch
                  onValueChange={() => handleShare(item)}
                  value={item.isShared ? true : false}
                />
              </View>

              <Typography align={"left"} color={COLORS.BLACK} size={"15px"} bold={"500"} left={30}>
                {item?.name}
              </Typography>
            </View>,
          )}
        </View>

        <TouchableOpacity
          activeOpacity={0.9}
          onPress={() => {
            setShareStep(1);
          }}
          style={{
            width: "100%",
            marginTop: 20,
            backgroundColor: COLORS.LGREEN,
            borderRadius: 100,
            alignItems: "center",
            justifyContent: "center",
            height: 40,
          }}
        >
          <Text style={{ color: "white" }}>Next</Text>
        </TouchableOpacity>
      </View>
    );
  };
  const toggleSelectAllDocs = () => {
    setSelectAllDocs(!isAllSelectedDocs); // Toggle the "Select All" checkbox

    const updatedPoints = documents.map((point) => ({
      ...point,
      isShared: !isAllSelectedDocs, // Toggle the selection state of all items
    }));
    setDocuments(updatedPoints);
  };
  useEffect(() => {
    setSelectAllDocs(isAllSelectedDocs);
  }, [isAllSelectedDocs]);
  const handleShare1 = (document: any) => {
    setDocuments(
      documents.map((document1) => {
        if (document.document_id == document1.document_id) {
          document1.isShared = !document1.isShared;
        }
        return document1;
      }),
    );
  };

  const renderItem1 = (document) => {
    return (
      <View key={document.document_id} style={{}}>
        <View style={{
          flexDirection: "row",
          height: 56,
          alignItems: "center",
        }}>
          <View style={{
            flexDirection: "row",
            alignItems: "center",
          }}>
            {/* <TouchableOpacity onPress={() => { handleShare1(document) }}>
              <View style={{ width: 18, height: 18, backgroundColor:document.isShared ? COLORS.LGREEN:'white', borderWidth:1, borderRadius: 2, justifyContent: "center", alignItems: "center" }}>
                {document.isShared  &&  <FontAwesome name="check" size={10} color={"white"}/>}
              </View>
            </TouchableOpacity> */}
            <Switch
              onValueChange={() => handleShare1(document)}
              value={document.isShared ? true : false}
            />
          </View>
          <TouchableOpacity onPress={() => handleShare1(document)}>
            <Typography align={"left"} color={COLORS.BLACK} size={"15px"} bold={"500"} left={30}>
              {document.filename}
            </Typography>
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  const renderStep1 = () => {
    const isDocumentSelected = documents.some((item: any) => item.isShared === true);
    const isPointsSelected = points.some((item: any) => item.isShared === true);

    return (
      <View style={{ margin: 0 }}>
        <Text style={{
          fontSize: 16,
          fontWeight: "bold",
          marginVertical: 20,
        }}>
          Please select documents you want to share
        </Text>
        {/* Add Select All checkbox or switch */}
        <View style={{
          flexDirection: "row",
          alignItems: "center",
        }}>
          <View style={{
            flexDirection: "row",
            height: 56,
            alignItems: "center",
          }}>
            <View style={{
              flexDirection: "row",
              alignItems: "center",
            }}>

              <Switch
                onValueChange={toggleSelectAllDocs}
                value={selectAllDocs}
              />
            </View>

            <Typography align={"left"} color={COLORS.BLACK} size={"15px"} bold={"500"} left={30}>
              <Text>Select All</Text>
            </Typography>
          </View>
        </View>
        <LineSaperator color={COLORS.LGREEN}/>
        <ScrollView style={{
          width: layoutType == "phone" ? "100%" : 358,
          maxHeight: 400,
        }}>
          {documents.length
            ? documents.map((document) => {
              return renderItem1(document);
            })
            : null}

        </ScrollView>
        {showError ? (
          (isDocumentSelected || isPointsSelected) ? null : (
            <Text style={{
              color: "red",
              marginVertical: 10,
              alignSelf: "center",
            }}>
              Please select atleast one document or item to proceed!
            </Text>
          )
        ) : null}
        <View style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: 20,
          width: "100%",
        }}>
          <TouchableOpacity
            activeOpacity={0.9}
            onPress={() => setShareStep(0)}
            style={{
              width: 150,
              justifyContent: "center",
              alignItems: "center",
              borderWidth: 1,
              borderColor: COLORS.LGREEN,
              borderRadius: 100,
              height: 40,
            }}
          >
            <Text style={{ color: "black" }}>Back</Text>
          </TouchableOpacity>
          <TouchableOpacity
            activeOpacity={0.9}
            onPress={() => {
              if (isDocumentSelected || isPointsSelected) {
                setShowError(false);
                setShareStep(2);
              } else {
                setShowError(true);
              }
            }}
            style={{
              width: 150,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: COLORS.LGREEN,
              borderRadius: 100,
              height: 40,
            }}
          >
            <Text style={{ color: "white" }}>Next</Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  const renderStep2 = () => {
    return (
      <View style={{ margin: 0 }}>

        <Typography top={"40px"} align={"left"} color={COLORS.BLACK} size={"16px"} bold={"400"}>
          We will send an email containing a link to the secure portal where your shared documents
          can be viewed for the next <b>48 hours</b>.
        </Typography>
        <Typography top={"12px"} align={"left"} color={COLORS.BLACK} size={"16px"} bold={"400"}>
          Please unshare any documents you do not wish to be viewed.
        </Typography>
        <Typography top={"12px"} align={"left"} color={COLORS.BLACK} size={"16px"} bold={"400"}>
          A share code is displayed below alongside a link to preview your share portal.
          Please be careful who you share this code with.
        </Typography>
        <Typography top={"12px"} align={"left"} color={COLORS.BLACK} size={"16px"} bold={"400"} bottom={"30px"}>
          Your documents can be shared as many times as you like.
        </Typography>
        <CustomTextInput
          id="agentEmail"
          bRadius={20}
          width={"100%"}
          // title="Receiver's email"
          onChangeText={(value) => setAgentEmail(value)}
          name="email"
          placeholder={"Receiver's email"}
          prefilledValue={agentEmail}

          bColor={COLORS.GREY1}
        />

        <View style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: 20,
          width: "100%",
        }}>
          <TouchableOpacity
            activeOpacity={0.9}
            onPress={() => setShareStep(1)}
            style={{
              width: 200,
              justifyContent: "center",
              alignItems: "center",
              borderWidth: 1,
              borderColor: COLORS.LGREEN,
              borderRadius: 100,
              height: 40,
            }}
          >
            <Text style={{ color: "black" }}>Back</Text>
          </TouchableOpacity>
          <TouchableOpacity
            activeOpacity={0.9}
            onPress={() => {
              setShowError(false);
              onSave();
            }}
            style={{
              width: 300,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: COLORS.LGREEN,
              borderRadius: 100,
              height: 40,
            }}
          >
            <Text style={{ color: "white" }}>Send email</Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  return (
    <>
      <Wrapper>
        <View style={layoutType != "web" ? {} : {
          flexDirection: "column",
          width: 650,
        }}>
          {/* {
            agentData && agentData.length ?
              null
            :
              <Typography raj bold={"300"} color={"#1C1C1C"} size={"18px"} bottom={"30px"} top={"10px"} align={"center"}>
                Generate a temporary share code to allow the receiver (eg. your agent) to view your shared documents in a secure portal.
              </Typography>
          } */}
          <Typography bold={"300"} color={"#1C1C1C"} left={30} size={"16px"} bottom={"10px"} top={"10px"}
                      align={"left"}>
            Generate a temporary share code to allow the receiver (eg. your agent){"\n"}to view your shared documents in
            a secure portal.
          </Typography>
          <View style={{
            width: "100%",
            height: 0.5,
            backgroundColor: "#B2B2B2",
            marginTop: 10,
          }}/>
          <Typography size={"18px"} color={COLORS.LGREEN} top={"25px"} bottom={"15px"}>
            Share Codes Generated
          </Typography>
          {agentData && agentData.length ? <FlatList
              data={showExpired ? agentData : activeShareCode}
              renderItem={({
                item,
                index,
              }) => <DetailCard item={item} index={index}/>}
            />
            : null}
          {agentData.length && <TouchableOpacity onPress={() => setShowExpired(!showExpired)}>
            <Typography color={"black"} underline size={"15px"} top={10}
                        align="right"> {showExpired ? "Hide" : "Show"} Expired</Typography>
          </TouchableOpacity>}
          <TouchableOpacity onPress={() => setShowShareModal(true)} style={{
            justifyContent: "center",
            alignItems: "center",
            marginTop: 30,
            backgroundColor: COLORS.LGREEN,
            width: "100%",
            height: 60,
          }}>
            <Typography size={"20px"} color={"white"} bold={"600"}>+ New Share</Typography>
            {/* <WideButton isLGreen width="171px" gradiant borderRadius={15} childText="+ Share Code" onPress={() => setShowShareModal(true)}/> */}
          </TouchableOpacity>
        </View>
        <Modal fullScreen={false} isVisible={showShareModal} onBackdropPress={() => {
          setShowShareModal(false);
          setShareStep(0);
        }}>
          <BoxShadow style={{
            alignItems: "center",
            borderRadius: 0,
            justifyContent: "center",
            flexDirection: "column",
            padding: 20,
            width: layoutType == "phone" ? "98%" : 450,
            alignSelf: "center",
          }}>
            <View style={{
              width: "100%",
              paddingBottom: 15,
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}>
              <Typography top={"6px"} align={"center"} color={COLORS.LGREEN} size={"20"} bold={"600"}>

              </Typography>
              <Typography top={"6px"} align={"center"} color={COLORS.LGREEN} size={"20"} bold={"600"}>
                New Share Code
              </Typography>
              <TouchableOpacity onPress={() => setShowShareModal(false)}>
                <AntDesign name="close" size={24} color="grey"/>
              </TouchableOpacity>
            </View>
            <View style={{
              flexDirection: "row",
              width: "70%",
              justifyContent: "space-between",
              marginTop: 15,
            }}>
              {[1, 2, 3].map(e =>
                <View style={{
                  flexDirection: "row",
                  alignItems: "center",
                }}>
                  <View style={{
                    width: 35,
                    height: 35,
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 30,
                    borderWidth: 2,
                    borderColor: e == (shareStep + 1) ? COLORS.LGREEN : "#A3D3CF",
                    backgroundColor: (shareStep + 1) > e ? COLORS.LGREEN : "white",
                  }}>
                    <Text style={{
                      color: (shareStep + 1) > e ? "white" : COLORS.LGREEN,
                      fontWeight: "bold",
                    }}>{e}</Text>
                  </View>
                  {e != 3 && <View style={{
                    width: layoutType == "phone" ? 50 : 80,
                    height: 2,
                    backgroundColor: (shareStep + 1) > e ? COLORS.LGREEN : "#A3D3CF",
                    marginHorizontal: 10,
                  }}/>}
                </View>,
              )}
            </View>

            {
              shareStep == 0 ? renderStep0() : null
            }
            {
              shareStep == 1 ? renderStep1() : null
            }
            {
              shareStep == 2 ? renderStep2() : null
            }
          </BoxShadow>
        </Modal>
      </Wrapper>
    </>
  );
};

export default ShareCode;
