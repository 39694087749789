import React from "react";
import { Image, Text, TouchableOpacity, View } from "react-native";
import { Entypo, FontAwesome } from "@expo/vector-icons";

import { ListView, Section1, Section2, StatusBox } from "./styles.js";
import getPlatformType from "../../helpers/getPlatform";
import idx from "idx";
import ImageSlider from "../ImageSlider/index.js";
import { FormatDate, FormatPrice } from "../../helpers/validation.js";

const layoutType = getPlatformType();

const Card = (props) => {
  const {
    data,
    isExpand,
    navigate,
    onSave,
    searchObject,
    showCloseIcon,
    notTouchable,
    savePageData,
    pageData,
  } = props;
  const [save, SetSave] = React.useState(idx(data, (_) => _.isSaved));
  const saveProperty = () => {
    SetSave(!save);
    if (savePageData) {
      const elementsIndex = pageData.findIndex(
        (element) => element.property_id == data.property_id,
      );
      let newArray = [...pageData];
      newArray[elementsIndex] = {
        ...newArray[elementsIndex],
        isSaved: !save,
      };
      savePageData(newArray);
    }
    onSave(data.property_id, searchObject);
  };

  const returnStatusBox = (status) => {
    if (["sstc", "under offer", "let agreed"].includes(status)) {
      return <StatusBox>{status}</StatusBox>;
    }
  };

  return (
    <ListView
      onPress={() => {
        notTouchable
          ? null
          : navigate("Property Details", {
            property_id: data.property_id,
          });
      }}
      style={{
        shadowOffset: {
          width: 0,
          height: 2,
        },
        shadowOpacity: 0.09,
        shadowRadius: 6.68,
        elevation: 11,
      }}
    >
      <Section1>
        <ImageSlider
          data={data?.property_images ? data.property_images : null}
        />
        {returnStatusBox(data?.status)}

        {showCloseIcon && (
          <TouchableOpacity
            style={{
              position: "absolute",
              right: 10,
              top: 5,
              zIndex: 999,
            }}
            onPress={() => {
              props.closeCard();
            }}
          >
            <Entypo name="cross" size={20} color="#E71E82"/>
          </TouchableOpacity>
        )}
      </Section1>
      <Section2>
        <View
          style={{
            flex: 0.5,
            flexDirection: "row",
            padding: 10,
          }}
        >
          <View
            style={{
              flex: 0.5,
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <Text style={{
              fontSize: 14,
              color: "black",
              fontWeight: "bold",
            }}>
              {/* {data.postcode} {data.city} */}
              {idx(data, (_) => _.postcode_city)}
            </Text>
            <Text style={{
              fontSize: 12,
              marginTop: 5,
              color: "#B0B0B0",
            }}>
              {idx(data, (_) => _.name)}
            </Text>
          </View>
          <View
            style={{
              flex: 0.5,
              justifyContent: "center",
              alignItems: "flex-end",
            }}
          >
            <Text style={{
              fontSize: 14,
              color: "black",
              fontWeight: "bold",
            }}>
              {FormatPrice(idx(data, (_) => _.price))}
            </Text>
            <Text style={{
              fontSize: 12,
              marginTop: 5,
              color: "#B0B0B0",
            }}>
              {FormatDate(idx(data, (_) => _.available_from))}
            </Text>
          </View>
        </View>
        <View
          style={{
            flex: 0.5,
            flexDirection: "row",
            borderTopWidth: 1,
            borderColor: "#D8D8D8",
          }}
        >
          <TouchableOpacity
            style={{
              flex: 0.65,
              borderRightWidth: 1,
              borderRightColor: "#d8d8d8",
              backgroundColor: data?.background_color
                ? data?.background_color
                : "#ffffff",
              borderBottomLeftRadius: 20,
            }}
          >
            <Image
              source={{ uri: idx(data, (_) => _.agent_logo) }}
              resizeMode={"contain"}
              style={{
                width: "100%",
                height: "100%",
              }}
            />
          </TouchableOpacity>
          {/* <TouchableOpacity
            style={{
              flex: 0.25,
              justifyContent: "center",
              alignItems: "center",
              borderRightWidth: 1,
              borderColor: "#D8D8D8",
            }}
          >
            <Text>Hide</Text>
          </TouchableOpacity> */}
          <TouchableOpacity
            onPress={() => (notTouchable ? null : saveProperty())}
            style={{
              flex: 0.35,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "white",
              flexDirection: "row",
            }}
          >
            {save ? (
              <FontAwesome name="bookmark" size={20} color="#E71E82"/>
            ) : (
              <FontAwesome name="bookmark" size={20} color="#cdcdcd"/>
            )}

            {save ? (
              <Text style={{ marginLeft: 10 }}>Saved</Text>
            ) : (
              <Text style={{ marginLeft: 10 }}>Save</Text>
            )}
          </TouchableOpacity>
        </View>
      </Section2>
    </ListView>
  );
};

export default Card;
