import { Alert, Platform } from "react-native";
import { types } from "../../actionTypes";
import {
  SERVER_URL,
  _post,
  URL,
  _get,
  _put,
  _delete,
} from "../../../helpers/connectionHelpers";
export const createAlert = (bodyofRequest, navigate, alert_id) => {
  return (dispatch) => {
    dispatch({ type: types.CREATE_ALERT_REQUEST });

    _put(
      `${SERVER_URL}${URL.createAlert}`,
      {}, // all headers here (if)
      JSON.stringify(bodyofRequest), // body here (if)
      (res) => {
        // function to perform on success
        
        let responseObject = res;
        if (responseObject.status) {
          dispatch({
            type: types.CREATE_ALERT_SUCCESS,
            payload: responseObject,
          });
          dispatch({
            type: types.SHOW_ALERT,
            alertType: "success",
            text: res.data,
          });
          navigate("My Alerts");
        } else {
          dispatch({ type: types.CREATE_ALERT_FAILED, error: res.error });
          dispatch({
            type: types.SHOW_ALERT,
            alertType: "error",
            text: res.error,
          });
        }
        // Alert.alert(JSON.stringify(res));
      },
      (err) => {
        // function to perform on failure
        dispatch({ type: types.CREATE_ALERT_FAILED, error: err.message });
        dispatch({
          type: types.SHOW_ALERT,
          alertType: "error",
          text: err.message,
        });
        Alert.alert(err.message);
      }
    );
  };
};
export const updateAlert = (bodyofRequest, navigate, alert_id) => {
  return (dispatch) => {
    dispatch({ type: types.CREATE_ALERT_REQUEST });

    _post(
      `${SERVER_URL}${URL.createAlert}${alert_id}`,
      {}, // all headers here (if)
      JSON.stringify(bodyofRequest), // body here (if)
      (res) => {
        // function to perform on success
        
        let responseObject = res;
        if (responseObject.status) {
          dispatch({
            type: types.CREATE_ALERT_SUCCESS,
            payload: responseObject,
          });
          dispatch({
            type: types.SHOW_ALERT,
            alertType: "success",
            text: res.data,
          });
          navigate("My Alerts");
        } else {
          dispatch({ type: types.CREATE_ALERT_FAILED, error: res.error });
          dispatch({
            type: types.SHOW_ALERT,
            alertType: "error",
            text: res.error,
          });
        }
        // Alert.alert(JSON.stringify(res));
      },
      (err) => {
        // function to perform on failure
        dispatch({ type: types.CREATE_ALERT_FAILED, error: err.message });
        dispatch({
          type: types.SHOW_ALERT,
          alertType: "error",
          text: err.message,
        });
        Alert.alert(err.message);
      }
    );
  };
};

export const myAlerts = (bodyofRequest, navigate) => {
  return (dispatch) => {
    dispatch({ type: types.GET_MY_ALERTS_REQUEST });

    _get(
      `${SERVER_URL}${URL.createAlert}`,
      {}, // all headers here (if)
      JSON.stringify(bodyofRequest), // body here (if)
      (res) => {
        // function to perform on success
        
        let responseObject = res;
        if (responseObject.status) {
          dispatch({
            type: types.GET_MY_ALERTS_SUCCESS,
            payload: responseObject,
          });
          //dispatch({ type: types.SHOW_ALERT, alertType: "success", text: res.data });
          //navigate("Password Reset - Confirmation");
        } else {
          dispatch({ type: types.GET_MY_ALERTS_FAILED, error: res.error });
          dispatch({
            type: types.SHOW_ALERT,
            alertType: "error",
            text: "You have no alerts!",
          });
        }
        // Alert.alert(JSON.stringify(res));
      },
      (err) => {
        // function to perform on failure
        dispatch({ type: types.GET_MY_ALERTS_FAILED, error: err.message });
        dispatch({
          type: types.SHOW_ALERT,
          alertType: "error",
          text: err.message,
        });
        Alert.alert(err.message);
      }
    );
  };
};

export const getAlertById = (alert_id, navigate) => {
  
  return (dispatch) => {
    dispatch({ type: types.GET_ALERT_BY_ID_REQUEST });

    _get(
      `${SERVER_URL}${URL.createAlert}${alert_id}`,
      {}, // all headers here (if)
      {}, // body here (if)
      (res) => {
        // function to perform on success
        
        let responseObject = res;
        if (responseObject.status) {
          dispatch({
            type: types.GET_ALERT_BY_ID_SUCCESS,
            payload: responseObject,
          });
          //dispatch({ type: types.SHOW_ALERT, alertType: "success", text: res.data });
          //navigate("Create Alert");
        } else {
          dispatch({ type: types.GET_ALERT_BY_ID_FAILED, error: res.error });
          dispatch({
            type: types.SHOW_ALERT,
            alertType: "error",
            text: res.error,
          });
        }
        // Alert.alert(JSON.stringify(res));
      },
      (err) => {
        // function to perform on failure
        dispatch({ type: types.GET_ALERT_BY_ID_FAILED, error: err.message });
        dispatch({
          type: types.SHOW_ALERT,
          alertType: "error",
          text: err.message,
        });
        Alert.alert(err.message);
      }
    );
  };
};

export const delAlert = (id, navigate) => {
  return (dispatch) => {
    dispatch({ type: types.DEL_ALERT_REQUEST });

    _delete(
      `${SERVER_URL}${URL.createAlert}${id}`,
      {}, // all headers here (if)
      JSON.stringify({}), // body here (if)
      (res) => {
        // function to perform on success
        
        let responseObject = res;
        if (responseObject.status) {
          dispatch({
            type: types.DEL_ALERT_SUCCESS,
            payload: responseObject,
          });
          dispatch({
            type: types.SHOW_ALERT,
            alertType: "success",
            text: res.data,
          });
          dispatch(myAlerts());
          //navigate("Password Reset - Confirmation");
        } else {
          dispatch({ type: types.DEL_ALERT_FAILED, error: res.error });
          dispatch({
            type: types.SHOW_ALERT,
            alertType: "error",
            text: res.error,
          });
        }
        // Alert.alert(JSON.stringify(res));
      },
      (err) => {
        // function to perform on failure
        dispatch({ type: types.DEL_ALERT_FAILED, error: err.message });
        dispatch({
          type: types.SHOW_ALERT,
          alertType: "error",
          text: err.message,
        });
        Alert.alert(err.message);
      }
    );
  };
};
export const clearAlert = () => {
  return (dispatch) => {
    dispatch({ type: types.CLEAR_ALERT });
  };
};
