import {FontAwesome} from '@expo/vector-icons';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ActivityIndicator, Text, TextInput, TouchableOpacity, View} from 'react-native';

import {Typography} from '../openFlow/style';
import {COLORS} from '../../constants/colorPallete';
import {openUrlHelpers} from "../../helpers/openDocHelpers";
import {tenantActions} from '../openFlow/openFlowRedux/tenant.actions';

const BillingBetter = React.memo(({ profile ,postCodeFromTeancy,fullContent}:any) => {
  const dispatch = useDispatch();
  const [packages, setPackages] = useState([]);
  const [postcode, setPostCode] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const postCodeData = useSelector((state: any) => state?.tenant?.postCodeData);
  const [error,setError]=useState('');

  useEffect(() => {
    if (profile?.appUser?.new_postcode_search) {
      dispatch(tenantActions.setPostCodeAction({ value: profile?.appUser?.new_postcode_search, error: '', isSubmit: true }));
    }else if(postCodeFromTeancy){
      dispatch(tenantActions.setPostCodeAction({ value: postCodeFromTeancy, error: '', isSubmit: true }));
    }
  }, [profile,postCodeFromTeancy]);

  useEffect(() => {
    if (postCodeData?.isSubmit) {
      getBillingBetterPackages1(postCodeData?.value);
    }
  }, [postCodeData?.isSubmit]);

  const getBillingBetterPackages1 = (postcode1: string) => {
    setIsLoading(true);
      dispatch(
        tenantActions.getBillingBetterPackages({ postcode: postcode1 }, (response) => {
          setIsLoading(false);
          if (response) {
            setPostCode(postcode1);
            setPackages(response.packages);
          }
        }),
      );
  };

  const getBillingBetterIframeURL1 = () => {
    dispatch(
      tenantActions.getBillingBetterIframeURL({ postcode }, (response) => {
        if (response.url) {
        setTimeout(() => {
          window.open(response.url, "_blank");
        }, 100);   
        }else{

        }
      }),
    );
  };

  const renderPackage = (item, index) => {
    return (
      <View key={index} style={{ marginBottom: 10.0 }}>
        <Typography align={'left'} size={16} color={COLORS.LGREEN} numberOfLines={1} >
          {item.name} Package
        </Typography>
        <Typography align={'left'} size={13} bold={'400'} bottom={10} color={'black'}>
          {item.description}
        </Typography>
      </View>
    );
  };

  const renderPackages = () => {
    return (
      <TouchableOpacity onPress={() => { }}>
        <View style={{}}>
          {packages.map((item, index) => {
            return renderPackage(item, index);
          })}
          <TouchableOpacity
            onPress={getBillingBetterIframeURL1}
            style={{
              width: 170,
              height: 40,
              borderRadius: 1000,
              borderWidth: 1,
              borderColor: COLORS.LGREEN,
              justifyContent: 'center',
              alignItems: 'center',
              marginTop:'auto',
              marginBottom:-5
            }}>
            <Typography fontWeight={'bold'} color={'black'} size={14}>
              Explore More
            </Typography>
          </TouchableOpacity>
        </View>
      </TouchableOpacity>
    );
  };

  const renderResultPage = () => {
    return (
      <>
        <Typography top={10} size={13} bottom={10} bold={'400'} color={'black'} align={'left'}>
          All your bills in a single monthly payment
        </Typography>

        <View>{renderPackages()}</View>
      </>
    );
  };

  const renderInitialPage = () => {
    return (
      <View style={{ flex: 1 }}>
        <Typography align={'left'} bold={'400'} color={'black'} size={'13px'} style={{ marginTop: 10 }}>
          All your bills, in a single monthly payment
        </Typography>

        <Typography  align={'left'} bold={'400'} color={'black'} size={'13px'} style={{ marginTop: 10.0 }}>
          Enter your postcode for an instant quote!
        </Typography>
        <View style={{ marginTop: 10 }}>
          {nameTextField()}
          <TouchableOpacity
            onPress={() => {
              if(validatePostcode(postCodeData?.value)){
                if (postCodeData?.value) {
                  dispatch(
                    tenantActions.setPostCodeAction({ value: postCodeData?.value, error: '', isSubmit: true }),
                  );
                  setError('');
                }
              }
              else{
                setError('Invalid Postcode')
              }
              
            }}
            style={{
              width: 100,
              height: 45,
              marginTop: 20,
              borderRadius: 1000,
              backgroundColor: COLORS.LGREEN,
              justifyContent: 'center',
              alignItems: 'center',
              alignSelf: 'center',
            }}>
            {isLoading ? (
              <ActivityIndicator color={'#ffffff'} />
            ) : (
              <Typography size={14}>GO</Typography>
            )}
          </TouchableOpacity>
        </View>
      </View>
    );
  };
  function validatePostcode(postcode) {
    // Define the regular expression for a valid England postcode
    const postcodeRegex = /^[A-Z]{1,2}\d{1,2}[A-Z]?\s?\d[A-Z]{2}$/i;
  
    // Remove any spaces and convert the input to uppercase
    const cleanedPostcode = postcode.replace(/\s/g, '').toUpperCase();
  
    // Check if the postcode matches the regular expression
    if (postcodeRegex.test(cleanedPostcode)) {
      return true; // Valid postcode
    } else {
      return false; // Invalid postcode
    }
  }
  function nameTextField() {
    return (
      <View style={{}}>
        <View style={{  width: '100%', marginTop: 10 }}>
           <TextInput
                        style={{ height:40,borderColor:'lightgrey',borderWidth:1,borderRadius:10,padding:8 }}
                        placeholder='Enter postcode'
                        value={postCodeData?.value}
                        onChangeText={(text) => {
                           dispatch(tenantActions.setPostCodeAction({ value: text, error: '', isSubmit: false }));
                        }}
                        cursorColor={COLORS.LGREEN}>

                    </TextInput>
        </View>
        {error!="" && <Text style={{marginTop:5,color:'red'}}>{error}</Text>}

        {
          postCodeData?.error ? (
            <Text style={{}}>{postCodeData?.error}</Text>
          ) : null
        }
      </View >
    );
  }

  return (
      <View style={{ paddingHorizontal: 15, paddingVertical: 25 }}>
                  <View style={{backgroundColor:'white',height:60,borderTopRightRadius:8,borderTopLeftRadius:8,position:'absolute',width:'100%',top:0,zIndex:-100,left:0}}/>
        <View>
          <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
            <Typography fontWeight={'bold'} color={COLORS.LGREEN} fontSize={18} align={'left'}>
              Billing Better
            </Typography>
            {postcode ? (
              <View style={{ flexDirection: 'row', justifyContent: 'flex-end',alignItems:'center' }}>
                <Typography size={14} bold={'400'} color={'black'}>POSTCODE: </Typography>
                <Typography size={14} style={{ textTransform: 'uppercase'}} left={5} color={'black'} >{postcode}</Typography>
                <TouchableOpacity
                  onPress={() => {
                    setPostCode(null);
                    dispatch(tenantActions.setPostCodeAction({ value: '', error: '', isSubmit: false }));
                  }}>
                  <FontAwesome name="pencil-square-o" size={20} color={COLORS.LGREEN}  style={{marginLeft:10}}/>
                </TouchableOpacity>
              </View>
            ) : null}
          </View>
        </View>
        <View style={{marginTop:10}}>
          <View style={{ width: fullContent?"100%" :"60%" }}>
            {postcode ? renderResultPage() : renderInitialPage()}
          </View>
        </View>
      </View>
  );
});

export default BillingBetter;
