import React, { useEffect, useRef, useState } from "react";
import {
  View,
  Text,
  Platform,
  SafeAreaView,
} from "react-native";
import {

  Container,
  Background,

  IntroWrapper,

  ReviewItem,

  BannerImage1,
  BannerImage2,
  BannerImage3,
  BannerText2,
  BannerText1
} from "./landingPageTlyfe.style";

import Header from "../../commonComponents/headerComponent/headerComponent";

import { LinearGradient } from "expo-linear-gradient";
import getPlatformType from "../../helpers/getPlatform";

const layoutType = getPlatformType();

import Footer from "../../commonComponents/footerComponent/customFooter";
import IntroScreenCard from "./introScreenCard";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { search, propertyNearMe } from "../../redux/actions/property";
import { saveSearchObject } from "../../redux/actions/app";
import NoDataFound from "../NoDataFound";

import { ContainerScrollView } from "../../helpers/appStyles";


function LandingPageTlyfe(props) {

  return (
    <SafeAreaView style={{ backgroundColor: "#FAFAFA", height: "100%" }}>
      <Container>
        <Header
          showLogoMob
          showLogoWeb
          showLogoTab
          rightChildLabelTab
          rightChildLabelWeb
          rightChildLabelMob
          showDrawerMob
          showDrawerTab
          {...props}
        />
        <ContainerScrollView>
          <Background
            resize-mode={"cover"}
            source={require("../../assets/images/tlyfBanner.png")}
          >
            <LinearGradient
              colors={["rgba(255,181,128,0.9)", "rgba(251,175,230,0.6)"]}
              start={{ x: 0, y: 1 }}
              end={{ x: 1, y: 1 }}
              style={{
                height: "100%",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  flexWrap: "wrap",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingHorizontal: getPlatformType()==="phone"? "30%":"10%"
                }}
              >
                <BannerImage1
                  resizeMode="contain"
                  source={require("../../assets/images/tlyfe/tlyfe.png")}
                />
         
              </View>
              <BannerText1>we put you in control of </BannerText1>
              <BannerText2>property rental </BannerText2>

            </LinearGradient>
          </Background>
          <IntroWrapper
            style={{
              shadowOffset: {
                width: 0,
                height: 2,
              },
              shadowOpacity: 0.09,
              shadowRadius: 6.68,
              elevation: 11,
            }}
          >
            <IntroScreenCard
              showButton
              navigation={props.navigation}
              headerTitle={"Introducing tlyfe"}
              title={"The only tenant ID and passport that you will ever need!"}
              dataArray={
               [
                 {
               
                   text: "Providing you with an unlimited and secure storage facility to share docu-mentation directly with your agents, without any data-mining.",
                   image: require("../../assets/images/tlyfeImageText.png"),
                   chileimage: require("../../assets/images/tlyfeImageText2.png"),
                   title: "Document Storage",
                 },
                 {
               
                   text: "A Government Grade verified and secure Digital ID owned by you the Consumer where you can pre-reference and qualify yourselves. R2R and KYC/AML",
                   image: require("../../assets/images/tlyfeImageText.png"),
                   chileimage: require("../../assets/images/tlyfeImageText1.png"),
               
                   title: "Verified Digital ID",
                 },
                 {
               
                   text: "Gives you the tenat a lifetime record of validated rental payments and access to increase your credit scores when paying your rent.",
                   image: require("../../assets/images/tlyfeImageText.png"),
                   chileimage: require("../../assets/images/tlyfeImageText3.png"),
                   title: "Rental Passport",
                 },
               ]
              }
            />
          </IntroWrapper>          
          <Footer {...props} />
        </ContainerScrollView>
       
      </Container>
    </SafeAreaView>
  );
}

const mapStateToProps = (state) => ({

});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {

    },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LandingPageTlyfe);
