import React, { useState, useEffect } from "react";
import Header from "../../commonComponents/headerComponent/headerComponent";
import Footer from "../../commonComponents/footerComponent/customFooter";
import {
  View,
  Text,
  Dimensions,
  SafeAreaView,
  Image,
  TouchableOpacity,
  Platform,
  ScrollView,
  FlatList,
  TextInput,
  StyleSheet,
  Linking,
} from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { Entypo } from "@expo/vector-icons";
import getPlatformType from "../../helpers/getPlatform";
import { getCcjText } from "./constants";
const layoutType = getPlatformType();
import { MainSection, Section1, Section2 } from "./preReferencingStyle";
import { MainTitle } from "./style";
import CardComponent from "./Components/cardComponent";
import { ContainerScrollView } from "../../helpers/appStyles";
import SkeltonComponents from "./Components/skeltonComponents";
import { useSelector, useDispatch } from "react-redux";
import {
  getCcqUrl,
  skipCcj,
  setCCjPending,
} from "./../../redux/actions/preReferencing";

const Ccq = (props) => {
  const { onCloseClick, showClose } = props;
  const [ccqCheck, setCcqCheck] = useState(null);
  const [monthlyIncome, setMonthlyIncome] = useState(null);
  const [amount, setAmount] = useState(null);
  const [isCcjTable, setIsCcjTable] = useState(null);
  const dispatch = useDispatch();
  const ccqData = useSelector(
    (state) =>
      state?.preReferencing?.ccqURLData?.ApplicationDetails?.Application
  );
  const ccqProps = useSelector((state) => state?.preReferencing?.ccqData);

  const ccqLoader = useSelector((state) => state?.preReferencing?.isLoading);

  useEffect(() => {
    
    if (
      ccqProps?.agreements &&
      ccqProps?.agreements.length &&
      ccqProps?.judgments &&
      ccqProps?.judgments.length
    ) {
      setIsCcjTable(true);
    } else {
      setIsCcjTable(false);
    }
  }, [ccqProps?.agreements, ccqProps?.judgments, ccqProps?.ccj]);

  const getformattedData = (arrData, type) => {
    if (type === "a") {
      return arrData.map((data, index) => {
        let tempD =
          layoutType === "phone"
            ? [data?.court_name, data?.order_type, data?.order_date]
            : [
                data?.court_name,
                data?.order_date,
                data?.order_type,
                data?.status,
              ];
        return {
          value: tempD,
        };
      });
    } else if (type === "j") {
      return arrData.map((data, index) => {
        

        let tempD =
          layoutType === "phone"
            ? [data?.court_name, data?.amount, data?.judgment_date]
            : [
                data?.court_name,
                data?.amount,
                data?.judgment_date,
                data?.status,
              ];
        return {
          value: tempD,
        };
      });
    }
  };

  
  return (
    <MainSection style={{ marginBottom: 0 }}>
      <Section1>
        <MainTitle style={{ marginTop: 10, textAlign: "left" }}>
          County Court Judgements (CCJ)
        </MainTitle>
        <SkeltonComponents
          type={"empty"}
          renderBody={() => {
            return (
              <>
                <Text>Complete CCJ Check?</Text>
                <View
                  style={{ flexDirection: "row", flex: 1, marginTop: "4%" }}
                >
                  <View
                    style={{
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      flex: 0.5,
                    }}
                  >
                    <TouchableOpacity
                      onPress={() => {
                        dispatch(skipCcj());
                        setCcqCheck("NO");
                        setMonthlyIncome(null);
                        setAmount(null);
                      }}
                      style={{
                        height: 24,
                        justifyContent: "center",
                        alignItems: "center",
                        width: 98,
                        borderRadius: 25,
                        backgroundColor: "#FC4850",
                      }}
                    >
                      <Text style={{ color: "white" }}>No</Text>
                    </TouchableOpacity>
                  </View>
                  <View
                    style={{
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      flex: 0.5,
                    }}
                  >
                    <TouchableOpacity
                      disabled={
                        ccqCheck === "YES" && ccqData?.referencingURL
                          ? true
                          : false
                      }
                      onPress={() => {
                        if (ccqData?.referencingURL) {
                          setCcqCheck("YES");
                        } else {
                          dispatch(
                            getCcqUrl((data) => {
                              setCcqCheck("YES");
                            })
                          );
                        }
                      }}
                      style={{
                        height: 24,
                        justifyContent: "center",
                        alignItems: "center",
                        width: 98,
                        borderRadius: 25,
                        backgroundColor:
                          ccqData?.referencingURL && ccqCheck === "YES"
                            ? "#dadada"
                            : "#00B401",
                      }}
                    >
                      <Text style={{ color: "white" }}>
                        {ccqLoader ? "Loading..." : "Yes"}
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </>
            );
          }}
        />

        {ccqCheck === "YES" ? (
          <TouchableOpacity
            style={[
              {
                padding: 10,
                marginVertical: 40,
                height: 42,
                width: 168,
                borderRadius: 25,
                marginRight: 10,
                backgroundColor: "#00B401",

                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
              },
            ]}
            onPress={() => {
              dispatch(setCCjPending(ccqData?.referencingURL));
            }}
          >
            <Text style={{ color: "black" }}>Submit</Text>
          </TouchableOpacity>
        ) : null}

        {ccqProps?.buttons?.ccj_text != "Start CCJ" ? (
          <>
            {ccqProps?.buttons?.ccj_text ? (
              <SkeltonComponents
                type={"empty"}
                renderBody={() => {
                  return (
                    <>
                      <View
                        style={{ flexDirection: "row", marginBottom: "6%" }}
                      >
                        <View
                          style={{
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "flex-start",
                            flex: 0.3,
                          }}
                        >
                          <Text style={{ color: "black", fontWeight: "bold" }}>
                            CCJ
                          </Text>
                        </View>
                        <View
                          style={{
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "flex-end",
                            flex: 0.7,
                          }}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              setCcqCheck(true);
                            }}
                            style={{
                              height: 24,
                              justifyContent: "center",
                              alignItems: "center",
                              width: "70%",
                              borderRadius: 25,
                              backgroundColor: ccqProps?.buttons?.ccj_color
                                ? ccqProps?.buttons?.ccj_color
                                : "#FC4850",
                            }}
                          >
                            <Text style={{ color: "white" }}>
                              {ccqProps?.buttons?.ccj_text}
                            </Text>
                          </TouchableOpacity>
                        </View>
                      </View>

                      <Text>{getCcjText(ccqProps?.buttons?.ccj_text)}</Text>
                    </>
                  );
                }}
              />
            ) : null}
          </>
        ) : null}
      </Section1>
      <Section2>
        <>
          {ccqCheck === "NO" ? (
            <SkeltonComponents
              type={"empty"}
              renderBody={() => {
                return (
                  <>
                    <View style={{ flexDirection: "row", marginBottom: "6%" }}>
                      <View
                        style={{
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "flex-start",
                          flex: 0.3,
                        }}
                      >
                        <Text style={{ color: "black", fontWeight: "bold" }}>
                          CCJ
                        </Text>
                      </View>
                      <View
                        style={{
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "flex-end",
                          flex: 0.7,
                        }}
                      >
                        <TouchableOpacity
                          style={{
                            height: 24,
                            justifyContent: "center",
                            alignItems: "center",
                            width: "70%",
                            borderRadius: 25,
                            backgroundColor: ccqProps?.buttons?.ccj_color
                              ? ccqProps?.buttons?.ccj_color
                              : "#FC4850",
                          }}
                        >
                          <Text style={{ color: "white" }}>{"N/A"}</Text>
                        </TouchableOpacity>
                      </View>
                    </View>

                    <Text>{getCcjText("N/A")}</Text>
                  </>
                );
              }}
            />
          ) : null}
          {ccqProps?.judgments && ccqProps?.judgments.length ? (
            <SkeltonComponents
              style={{ marginTop: 40 }}
              type={"table"}
              title={"Judgements"}
              columns={layoutType === "phone" ? judgmentsMobCol : judgmentsCol}
              columnsData={getformattedData(ccqProps.judgments, "j")}
            />
          ) : null}
          {ccqProps?.agreements && ccqProps?.agreements.length ? (
            <SkeltonComponents
              type={"table"}
              style={{ marginTop: 20 }}
              title={"Bankruptcy & Aggreements"}
              columns={
                layoutType === "phone" ? agreementsMobileCol : agreementseCol
              }
              columnsData={getformattedData(ccqProps.agreements, "a")}
            />
          ) : null}
        </>
        {showClose ? (
          <TouchableOpacity
            style={[
              {
                padding: 10,
                marginVertical: 40,
                height: 42,
                width: 168,
                borderRadius: 25,
                marginRight: 10,
                backgroundColor: "red",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
              },
            ]}
            onPress={() => {
              onCloseClick();
            }}
          >
            <Text style={{ color: "white" }}>Close</Text>
          </TouchableOpacity>
        ) : null}
      </Section2>
    </MainSection>
  );
};
export default Ccq;

const judgmentsMobCol = [
  { title: "Court" },
  { title: "Amount" },
  { title: "Jugment Date" },
];
const judgmentsCol = [
  { title: "Court" },
  { title: "Amount" },
  { title: "Jugment Date" },
  { title: "Status" },
];
const agreementsMobileCol = [
  { title: "Court" },
  { title: "Type" },
  { title: "Date" },
];

const agreementseCol = [
  { title: "Court" },
  { title: "Order Date" },
  { title: "Order type" },
  { title: "Status" },
];
