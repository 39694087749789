import { BaseTokensProps, baseTokens } from './base-tokens';

export interface TokensProps extends BaseTokensProps { }

const lightColors = {
  neutral: {
    white: '#FFFFFF',
    100: '#F9FAFB',
    200: '#F3F6F9',
    300: '#D0D6DD',
    400: '#AEBAC9',
    500: '#8794A5',
    600: '#5C697B',
    700: '#3F4D5A',
    800: '#2B3541',
    900: '#131C28',
    1000: '#182A4E',
    black: '#081F42',
  },
  main: {
    gold:{
      100: '#FEF7E3',  // Lightest shade
      200: '#FDEDC6',  // Lighter shade
      300: '#FAD88F',  // Light shade
      400: '#F7C352',  // Medium shade
      500: '#FBBC23',  // Base color
      700: '#E0A61C',  // Darker shade
      gradient: ['#FBBC23', '#E0A61C'],  // Gradient
    },
    blue: {
      100: '#E3F8FA',
      200: '#C5EDF3',
      300: '#9EE1E8',
      400: '#64C9D3',
      500: '#1FA6B2',
      700: '#06909E',
      gradient: ['#1FA6B2', '#06909E'],
    },
    purple: {
      100: '#F3E9FB',
      200: '#EADAF6',
      300: '#D7BCEB',
      400: '#BA91D9',
      500: '#A070C4',
      700: '#8557A8',
      gradient: ['#A070C4', '#8557A8'],
    },
    salmon: {
      100: '#FFE4E8',
      200: '#FDBEC7',
      300: '#EE9DA8',
      400: '#E57887',
      500: '#CB5C6C',
      700: '#AE4756',
      gradient: ['#CB5C6C', '#AE4756'],
    },
    pink: {
      100: '#FFEBF3',
      200: '#FDD9E8',
      300: '#FBACCD',
      400: '#F786B7',
      500: '#EF5D9B',
      700: '#CB4D82',
      gradient: ['#EF5D9B', '#CB4D82'],
    },
    red: {
      100: '#EB3754',
      200: '#EB3754',
      300: '#EB3754',
      400: '#EB3754',
      500: '#EB3754',
      700: '#EB3754',
      gradient: ['#EF5D9B', '#CB4D82'],
    },
    others: {
      'tea-green': '#CBE7C1',
      home: '#EB3754',
      inverted: '#fff',
    },
  },
  feedback: {
    main: '#58D48E',
    contrast: '#28B967',
    bg: '#B8EED3',
    success: {
      100: '#B8EED3',
      200: '#B8EED3',
      400: '#58D48E',
      500: '#28B967',
      700: '#0C9749',
    },
    warning: {
      100: '#FEF2C5',
      200: '#FEF2C5',
      400: '#FCD14A',
      500: '#EEB400',
      700: '#CE9F0C',
    },
    error: {
      100: '#FCBDB9',
      200: '#FCBDB9',
      400: '#FF766A',
      500: '#F44D3F',
      700: '#DA3023',
    },
  },
  text: {
    primary: '#2B3541',
    secondary: '#3F4D5A',
    inverted: '#FFF',
  },
  button: {
    main: '#1FA6B2',
    contrast: '#06909E',
    'switch-button': '#F9FAFB',
    'switch-button-on': '#1FA6B2',
    'switch-line': '#8794A5',
    'switch-line-on': '#9EE1E8',
    highlight: '#FCD14A',
  },
  elements: {
    neutral: '#3F4D5A',
    'neutral-light': '#5C697B',
    'neutral-bg': '#F3F6F9',
    color: '#1FA6B2',
    'color-light': '#64C9D3',
    'color-bg': '#E3F8FA',
    invert: '#fff',
  },
  other: {
    'border-neutral': '#D0D6DD',
    'border-color': '#64C9D3',
    tiles: '#fff',
    'background-neutral': '#F9FAFB',
    'background-green': '#CBE7C1',
  },
  disabled: {
    text: '#AEBAC9',
    element: '#AEBAC9',
    button: '#D0D6DD',
    background: '#F3F6F9',
    'switch-button': '#F3F6F9',
    'switch-line': '#AEBAC9',
    'switch-button-on': '#9EE1E8',
    'switch-line-on': '#C5EDF3',
  },
  hover: {
    button: '#06909E',
    'button-feedback': '#0C9749',
    'bg-color': '#0C9749',
    'bg-background': '#EBF9F2',
    'bg-neutral': '#F3F6F9',
  },
  transparency: {
    blue: 'rgba(31, 166, 178, 0.70)',
    purple: 'rgba(160, 112, 196, 0.70)',
    yellow: 'rgba(238, 180, 0, 0.70)',
    pink: 'rgba(239, 93, 155, 0.70)',
  },
  theme: {
    blue: '#1FA6B2',
    purple: '#A070C4',
    yellow: '#EEB400',
    pink: '#EF5D9B',
  },
};

const darkColors = {
  neutral: {
    white: '#FFFFFF',
    100: '#F9FAFB',
    200: '#F3F6F9',
    300: '#D0D6DD',
    400: '#AEBAC9',
    500: '#8794A5',
    600: '#5C697B',
    700: '#3F4D5A',
    800: '#2B3541',
    900: '#131C28',
    1000: '#182A4E',
    black: '#081F42',
  },
  main: {
    gold:{
      100: '#FEF7E3',  // Lightest shade
      200: '#FDEDC6',  // Lighter shade
      300: '#FAD88F',  // Light shade
      400: '#F7C352',  // Medium shade
      500: '#FBBC23',  // Base color
      700: '#E0A61C',  // Darker shade
      gradient: ['#FBBC23', '#E0A61C'],  // Gradient
    },
    blue: {
      100: '#E3F8FA',
      200: '#C5EDF3',
      300: '#9EE1E8',
      400: '#64C9D3',
      500: '#1FA6B2',
      700: '#06909E',
      gradient: ['#1FA6B2', '#06909E'],
    },
    purple: {
      100: '#F3E9FB',
      200: '#EADAF6',
      300: '#D7BCEB',
      400: '#BA91D9',
      500: '#A070C4',
      700: '#8557A8',
      gradient: ['#A070C4', '#8557A8'],
    },
    salmon: {
      100: '#FFE4E8',
      200: '#FDBEC7',
      300: '#EE9DA8',
      400: '#E57887',
      500: '#CB5C6C',
      700: '#AE4756',
      gradient: ['#CB5C6C', '#AE4756'],
    },
    pink: {
      100: '#FFEBF3',
      200: '#FDD9E8',
      300: '#FBACCD',
      400: '#F786B7',
      500: '#EF5D9B',
      700: '#CB4D82',
      gradient: ['#EF5D9B', '#CB4D82'],
    },
    red: {
      100: '#EB3754',
      200: '#EB3754',
      300: '#EB3754',
      400: '#EB3754',
      500: '#EB3754',
      700: '#EB3754',
      gradient: ['#EF5D9B', '#CB4D82'],
    },
    others: {
      'tea-green': '#CBE7C1',
      home: '#EB3754',
      inverted: '#fff',
    },
  },
  feedback: {
    main: '#58D48E',
    contrast: '#28B967',
    bg: '#B8EED3',
    success: {
      100: '#B8EED3',
      200: '#B8EED3',
      400: '#58D48E',
      500: '#28B967',
      700: '#0C9749',
    },
    warning: {
      100: '#FEF2C5',
      200: '#FEF2C5',
      400: '#FCD14A',
      500: '#EEB400',
      700: '#CE9F0C',
    },
    error: {
      100: '#FCBDB9',
      200: '#FCBDB9',
      400: '#FF766A',
      500: '#F44D3F',
      700: '#DA3023',
    },
  },
  text: {
    primary: '#2B3541',
    secondary: '#3F4D5A',
    inverted: '#FFF',
  },
  button: {
    main: '#1FA6B2',
    contrast: '#06909E',
    'switch-button': '#F9FAFB',
    'switch-button-on': '#1FA6B2',
    'switch-line': '#8794A5',
    'switch-line-on': '#9EE1E8',
    highlight: '#FCD14A',
  },
  elements: {
    neutral: '#3F4D5A',
    'neutral-light': '#5C697B',
    'neutral-bg': '#F3F6F9',
    color: '#1FA6B2',
    'color-light': '#64C9D3',
    'color-bg': '#E3F8FA',
    invert: '#fff',
  },
  other: {
    'border-neutral': '#D0D6DD',
    'border-color': '#64C9D3',
    tiles: '#fff',
    'background-neutral': '#F9FAFB',
    'background-green': '#CBE7C1',
  },
  disabled: {
    text: '#AEBAC9',
    element: '#AEBAC9',
    button: '#D0D6DD',
    background: '#F3F6F9',
    'switch-button': '#F3F6F9',
    'switch-line': '#AEBAC9',
    'switch-button-on': '#9EE1E8',
    'switch-line-on': '#C5EDF3',
  },
  hover: {
    button: '#06909E',
    'button-feedback': '#0C9749',
    'bg-color': '#0C9749',
    'bg-background': '#EBF9F2',
    'bg-neutral': '#F3F6F9',
  },
  transparency: {
    blue: 'rgba(31, 166, 178, 0.70)',
    purple: 'rgba(160, 112, 196, 0.70)',
    yellow: 'rgba(238, 180, 0, 0.70)',
    pink: 'rgba(239, 93, 155, 0.70)',
  },
  theme: {
    blue: '#1FA6B2',
    purple: '#A070C4',
    yellow: '#EEB400',
    pink: '#EF5D9B',
  },
};
export const getTokens = (mode: 'light' | 'dark' = 'light'): TokensProps => {
  if (mode === 'light') {
    return {
      ...baseTokens,
      colors: lightColors,
      mode: 'light',
    };
  } else {
    return {
      ...baseTokens,
      colors: darkColors,
      mode: 'dark',
    };
  }
};
