import idx from "idx";
import moment from "moment";
import { View } from "react-native";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getProfile } from "../../redux/actions/auth";
import { COLORS } from "../../constants/colorPallete";
import { showToaster } from "../../redux/actions/toast";
import getPlatformType from "../../helpers/getPlatform";
import WideButton from "../../commonComponents/wideButton";
import { BoxShadow } from "../identifyAndFinance/stylesNew";
import CircularProgress from "../../commonComponents/circle";
import BillingBetter from "../identifyAndFinance/BillingBetter";
import { removeTenantFromProperty } from "../../redux/actions/property";
import CustomPopUp from "./../../commonComponents/customPopUp/customPopUp";
import { callIfAvailable, sendEmail } from "../../helpers/callEmailHelper";
import { getCreditLadderHistory, getLadger } from "../../redux/actions/Ladger";
import { ButtonWrapper, CircularWrapper, ProgressCard, RowContainer, RText, RValue, Section } from "./styles";

const layoutType = getPlatformType();

const RentalPortal = (props) => {
  const type = "web";
  const dispatch = useDispatch();
  const [flowIdInfo, setFlowIdInfo] = React.useState(null);
  const globalState = useSelector((state) => state?.tenant);
  const [popUpIsVisible, setPopupVisible] = React.useState(false);
  const profile = useSelector((state) => state.authReducer.profile);

  useEffect(() => {
    setFlowIdInfo(globalState?.flowIdInfo);
  }, [globalState]);

  useEffect(() => {
    dispatch(getProfile({}, props.navigation.navigate));
    dispatch(getLadger({}, props.navigation.navigate));
    dispatch(getCreditLadderHistory({}, props.navigation.navigate));
  }, []);

  const calculateDueAmount = () => {
    let total = parseFloat(profile?.data?.rental_amount_per_tenant);
    if (total) {
      return "£" + total.toFixed(2);
    } else {
      return "-";
    }
  };

  const renderProgressComponent = () => {
    let residents;
    if (flowIdInfo?.tenants.length) {
      residents = flowIdInfo.tenants.length + " residents";
    } else if (profile?.data?.tenantDetails?.opbrixIds.length) {
      residents = profile?.data?.tenantDetails?.opbrixIds.length + " residents";
    } else {
      residents = "N/A";
    }

    let duration;
    if (flowIdInfo?.open_flow_rental_offer?.tenancy_start_date) {
      duration = new Date(flowIdInfo.open_flow_rental_offer.tenancy_end_date).getMonth() - new Date(flowIdInfo.open_flow_rental_offer.tenancy_start_date).getMonth();
    } else if (profile?.data?.tenantDetails?.property.duration) {
      duration = profile?.data?.tenantDetails?.property.duration;
    }

    let amount;
    if (flowIdInfo?.open_flow_rental_offer?.rental_amount) {
      amount = flowIdInfo?.open_flow_rental_offer.rental_amount;
    } else if (profile?.data?.tenantDetails?.property.AgreedRentalAmount) {
      amount = profile?.data?.tenantDetails?.property.AgreedRentalAmount;
    }
    return (
      <>
        <ProgressCard>
          <View
            style={{
              marginTop: 11,
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <RText>Amount Due</RText>
            <RValue>
              {calculateDueAmount()}
            </RValue>
          </View>
          <View
            style={{
              marginTop: 11,
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <RText>Rental Period</RText>
            <RValue>
              {profile?.data.tenantDetails?.property?.rentalStartDate
                ? moment(profile?.data.tenantDetails?.property?.rentalStartDate)
                .format("DD-MM-YYYY")
                : "-"}{" "}
              -{" "}
              {type === "mobile"
                ? ""
                : profile?.data.tenantDetails?.property?.rentalEndDate
                  ? moment(profile?.data.tenantDetails?.property?.rentalEndDate)
                  .format("DD-MM-YYYY")
                  : "-"}
            </RValue>
            {type === "mobile" ? (
              <RValue>
                {profile?.data.tenantDetails?.property?.rentalEndDate
                  ? moment(profile?.data.tenantDetails?.property?.rentalEndDate)
                  .format("DD-MM-YYYY")
                  : "-"}
              </RValue>
            ) : null}
          </View>

          <View
            style={{
              marginTop: 11,
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <RText>Number of residents</RText>
            <RValue>
              {residents}
            </RValue>
          </View>
          <View
            style={{
              marginTop: 11,
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <RText>Rental Amount</RText>
            <RValue>
              {amount && "£ "}
              {amount
                ? parseFloat(
                  amount,
                )
                .toFixed(2)
                : "N/A"}
            </RValue>
          </View>
          <View
            style={{
              marginTop: 11,
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <RText>Agent</RText>
            <RValue>
              {idx(profile, (_) => _.data.agentDetails.branch.company.name)
                ? profile.data.agentDetails.branch.company.name
                : "OBX"}
            </RValue>
          </View>
        </ProgressCard>


        <CircularWrapper>
          <CircularProgress
            size={150}
            strokeWidth={10}
            progressPercent={profile?.data.tenantDetails?.property?.rentalDayPercent || 0}
            pgColor={COLORS.orange}
            pointerColor={COLORS.orange}
            text={profile?.data.days_left || "-"}
            textColor={"#FFB580"}
            textSize={"27"}
          ></CircularProgress>
        </CircularWrapper>
      </>
    );
  };

  const emailAgent = async () => {
    let objectdetails = {
      agentDetails: profile?.data,
      addressLine:
        profile.data?.tenantDetails?.property?.address_line1 +
        " " +
        profile?.data?.tenantDetails?.property?.postcode,
      branchName: profile?.data?.agentDetails?.branch?.name,
      username: profile?.data?.first_name + " " + profile?.data?.last_name,
    };

    if (!profile?.data?.agentDetails?.branch?.name) {
      let noAgentAssociatedObjData = {
        branch: {
          email: "Info@openbrix.co.uk",
          name: "OBX",
        },
        property_name: "...",
      };
      sendEmail(noAgentAssociatedObjData, false, objectdetails);
      // let status = await sendEmail(noAgentAssociatedObjData, false, objectdetails);
      // if (!status) {
      //   dispatch(showToaster("error", "Sorry, agent email data not available"));
      // }
    } else {
      sendEmail(profile?.data?.agentDetails, true, objectdetails);
      // let status = await sendEmail(profile?.data?.agentDetails, true, objectdetails);
      // if (!status) {
      //   dispatch(showToaster("error", "Sorry, agent email data not available"));
      // }
    }
  };

  const callAgent = async (x) => {
    let status = false;
    if (x) {
      callIfAvailable(x);
      // status = await callIfAvailable(x);
      // if (!status) {
      //   dispatch(showToaster("error", "Sorry, agent's contact unavailable"));
      // }
    } else if (!status) {
      dispatch(showToaster("error", "Sorry, agent's contact unavailable"));
    }
  };

  const removeTenant = () => {
    const data = idx(profile, (_) => _.data.tenantDetails.property);
    dispatch(removeTenantFromProperty(data?.oprid_id, props?.navigation.navigate));
  };

  const renderButtomComponent = () => {
    return (
      <ButtonWrapper>
        <View
          style={{
            flex: 0.5,
            paddingRight: 6,
          }}
        >
          <WideButton
            childText={"Call Agent"}
            buttonType={"solid"}
            gradiant
            isLGreen
            onPress={() =>
              callAgent(
                profile?.data?.agentDetails?.branch?.contact_number
                  ? profile.data.agentDetails.branch.contact_number
                  : "0203 740 4721",
              )
            }
          />
        </View>
        <View
          style={{
            flex: 0.5,
            paddingleft: 6,
          }}
        >
          <WideButton
            childText={"Email Agent"}
            buttonType={"solid"}
            gradiant
            onPress={() => emailAgent()}
            isLGreen
          />
        </View>
      </ButtonWrapper>
    );
  };

  return (
    <>
      <RowContainer>
        <Section style={{}}>
          {renderProgressComponent()}
          {renderButtomComponent()}
        </Section>
        <Section style={{}}>
          <BoxShadow style={{
            marginTop: 0,
            marginRight: 0,
          }}>
            <View style={{
              flex: 1,
              minWidth: layoutType == "phone" ? "100%" : "49.6%",
              height: 263,
              marginRight: 0,
            }}>
              <BillingBetter
                postCodeFromTeancy={props?.postCode}
                fullContent
                profile={profile?.data}/>
            </View>
          </BoxShadow>
        </Section>
      </RowContainer>
      <CustomPopUp
        loading={popUpIsVisible}
        primaryButtonText={"Yes"}
        secondaryButtonText={"Cancel"}
        primaryText={"Remove?"}
        secondaryText={
          "Are you sure you wish to remove yourself from the property? You will no longer be able to create payments history connected to this property"
        }
        onPrimaryButtonPress={() => {
          setPopupVisible(false);
          removeTenant();
        }}
        onSecondaryButtonPress={() => {
          setPopupVisible(false);
        }}
      />
    </>
  );
};
export default RentalPortal;
