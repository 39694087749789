import React from "react";
import styled from "styled-components/native";
import getPlatformType from "../../helpers/getPlatform";

const layoutType = getPlatformType();
let Container;
let CenterPanal;
let ListView;

if (layoutType == "phone") {
  ListView = styled.TouchableOpacity`
    width: 100%;
    height: 100%;
    background-color: white;
    border-bottom-left-radius: 20px;
    border-top-right-radius: 20px;
  `;
  Container = styled.View`
    margin: 16px 16px;
    align-items: center;
  `;
} else if (layoutType == "web") {
  ListView = styled.TouchableOpacity`
    width: 100%;
    height: 100%;
    background-color: white;
    border-bottom-left-radius: 20px;
    border-top-right-radius: 20px;
  `;
  Container = styled.View`
    margin: 16px 16px;
    flex-direction: row;
    align-items: center;
  `;
} else {
  ListView = styled.TouchableOpacity`
    width: 100%;
    height: 100%;
    background-color: white;
    border-bottom-left-radius: 20px;
    border-top-right-radius: 20px;
  `;
  Container = styled.View`
    margin: 16px 16px;
    flex-direction: row;
    align-items: center;
  `;
}
const LeftPanal = styled.View`
  width: 60px;
  background-color: green;
  margin-right: 6px;
`;
const RightPanal = styled.View`
  width: 459px;
  background-color: pink;
  margin-left: 6px;
`;
const Section1 = styled.View`
  width: 100%;
  height: 55%;
`;
const Section2 = styled.View`
  width: 100%;
  height: 45%;
`;

export {
  CenterPanal,
  LeftPanal,
  RightPanal,
  Container,
  ListView,
  Section1,
  Section2,
};
