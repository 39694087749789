import React, { useCallback, useEffect, useRef, useState } from "react";
import { Dimensions, Image, ImageBackground, View } from "react-native";
import stylesMobile from "./stylesMobile";
import stylesTab from "./stylesTab";
import stylesWeb from "./stylesWeb";
import { backgroundSearchArr } from "../images/tlyfe/searchArrayTlyfe";
import BackGroundGenerator from "../common/BackGroundGenerator";
import { navigateAction } from "../../../helpers/navigationHelpers";
import YoutubePlayer from "../../../commonComponents/youtubePlayer/youtubePlayer";
import Constant from "../../tds/tdsRedux/Constant";
import { useSelector } from "react-redux";
import { Typography } from "../../openFlow/style";
import getPlatformType from "../../../helpers/getPlatform";
import CommonAuth from "./CommonAuth";
import ImageView from "./imageView";

const tiles = [
  {
    image: require("./images/agent/digital.png"),
    subheading: "Comprehensive digital referencing in real-time! ",
    heading: "DIGITAL REFERERNCE",
  },
  {
    image: require("./images/agent/id.png"),
    subheading: "Proving a tenants right-to-rent in a quick and efficient digital manner",
    heading: "ID VERIFICATION",
  },
  {
    image: require("./images/agent/crm.png"),
    subheading: "Reduced administration and double-keying through our industry wide CRM integration",
    heading: "CRM INTEGRATION",
  },
  {
    image: require("./images/agent/audit.png"),
    subheading: "Fully secure and auditable document storage and process management, ensuring full compliance",
    heading: "AUDIT TRAIL",
  },
  {
    image: require("./images/agent/credit.png"),
    subheading: "Enabling tenants to improve their credit score with rent payment tracking and reporting",
    heading: "IMPROVED CREDIT SCORE",
  },
  {
    image: require("./images/agent/use.png"),
    subheading: "Simple, intuitive and easy to use dashboard, helping to make life easier for lettings agents whilst also providing security and compliance",
    heading: "EASY TO USE",
  },
];
const LandingAgent = (props) => {
  const FEATURED = [
    {
      onButtonClick: () => {
        scollToRef.current.scrollIntoView({ behavior: "smooth" });
        window.open('https://agent.openbrix.co.uk','_blank')
      },
      buttonText: "CLICK HERE TO REGISTER/LOGIN",
      buttonStyle: { marginTop: 30 },
      bottomHeading:
        "AUTOMATED, INNOVATIVE, COMPLIANT AND COMPLETE! BRINGING HARMONY TO AGENTS AND THEIR TENANTS!",
    },
  ];
  const scollToRef = useRef();
  const scrollViewRef = useRef();

  const [type, setType] = useState("web");
  const layoutType = getPlatformType();
  const isAnylyticsEnable = useSelector(
    (state) => state?.cookiesReducer?.customiseCookies.Analytics,
  );
  const { navigate } = props.navigation;
  const navigateTo = (params, from) => {
    if (from) {
      navigateAction(props?.navigation, params, from);
    } else {
      navigateAction(props?.navigation, params);
    }
  };
  const [property_for, setPropertyFor] = useState(1);
  const styles = type == "web" ? stylesWeb : type == "mobile" ? stylesMobile : stylesTab;
  const [state, setState] = useState({
    from: "",
  });
  const handleScrollButtonClick = useCallback(() => {
    const height=Dimensions.get('window').height;
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTo({ y:layoutType=='web'?height:height/2, animated: true });
    }
  }, []);
  useEffect(() => {
    if (props?.route?.params?.from) {
      setState({
        ...state,
        from: props?.route?.params?.from ? props?.route?.params?.from : "",
      });
    }
  }, [props?.route?.params?.from]);
  console.log("type", type);
  let BottomWrapper = type == "web" ? ImageBackground : View;
  return (
    <>
      <BackGroundGenerator
        props={props}
        layoutType={(data) => setType(data)}
        backgroundArr={backgroundSearchArr}
        isLanding
        cloudHeader={true}
        scrollViewRef={scrollViewRef}
      >
        <div
          ref={scollToRef}
        />
        <CommonAuth
          heading={"SMART TENANT ON BOARDING  WITH OPENFLO"}
          navigation={navigate}
          onScrollButtonClick={handleScrollButtonClick}
          type={type}
          subheading={""}
          desc={""}
          hideButton={false}
          hideAuth={true}
          logoName={"openflo"}
          
          {...props}
        />
        <View style={[styles.imageContainer, { backgroundColor: "#FAFAFB" }]}>
          <Typography
            raj
            bold={"700"}
            color={"#1C1C1C"}
            size={type !== "web" ? "30px" : "48px"}
            line={type !== "web" ? "35px" : "52px"}
            align={"center"}
          >
            Introducing openflo
          </Typography>
          <Typography
            line={"35px"}
            raj
            bold={"600"}
            top={"20px"}
            color={"#1C1C1C"}
            size={"26px"}
            align={"center"}
            bottom={"10px"}
          >
            The next generation tenant onboarding solution.
          </Typography>
          <Typography
            line={"35px"}
            raj
            bold={"600"}
            top={"10px"}
            color={"#1C1C1C"}
            size={"26px"}
            align={"center"}
            bottom={"20px"}
          >
            The innovative and interactive onboarding management solution designed specifically for
            letting agents, ensuring the tenant onboarding process is completed in a smooth, timely
            and compliant manner.
          </Typography>

          <View style={styles.videoWrapper}>
            <YoutubePlayer url={Constant.OPENFLO_URL} height={"100%"} width={"100%"}/>
          </View>

        </View>

        <View style={[styles.imageContainer, {
          backgroundColor: "white",
          paddingHorizontal: type == "web" ? 150: 0,
        }]}>
          <View style={ {
          maxWidth:1070,
          alignSelf:"center",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "center",
        }}>
          {tiles.map((item, index) => {
            return (
              <View
                key={index}
                style={[
                  styles.shawow,
                  {
                    width: 315,
                    padding: 20,
                    paddingVertical: 40,
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 20,
                    margin: 20,
                  },
                ]}
              >
                <Image
                  source={item.image}
                  style={{
                    width: 146,
                    height: 146,
                  }}
                  resizeMode={"contain"}
                />
                <Typography
                  line={"35px"}
                  raj
                  bold={"600"}
                  top={"10px"}
                  color={"#1C1C1C"}
                  size={"26px"}
                  align={"center"}
                >
                  {item.heading}
                </Typography>
                <Typography
                  line={"26px"}
                  raj
                  bold={"500"}
                  top={"6px"}
                  color={"#737373"}
                  size={"20px"}
                  align={"center"}
                >
                  {item.subheading}
                </Typography>
              </View>
            );
          })}
          </View>
        </View>
        <View style={[styles.imageContainer, { backgroundColor: "#FAFAFB" }]}>
          <Image
            source={
              type == "web"
                ? require("./images/agent/agentInfo.png")
                : require("./images/agent/agentInfoMobile.png")
            }
            style={
              type !== "web" ? {
                width: "100%",
                height: 1200,
              } : {
                width: "100%",
                height: 450,
              }
            }
            resizeMode={"contain"}
          />
        </View>
        {FEATURED.map((item, index) => {
          return (
            <ImageView
              key={index}
              reverse={index % 2 === 0 ? false : true}
              type={type}
              heading={item.heading}
              heading2={item.heading2}
              bColor={index % 2 === 0 ? "white" : "#FAFAFB"}
              subheading={item.subheading}
              imageUrl={item.imageUrl}
              onButtonClick={item?.onButtonClick}
              buttonText={item?.buttonText}
              bottomHeading={item.bottomHeading}
              buttonStyle={
                item?.buttonStyle
                  ? {
                    ...item.buttonStyle,
                    marginBottom: type != "web" ? 400 : 250,
                  }
                  : {}
              }
              testID={"clickregister"}
            />
          );
        })}
      </BackGroundGenerator>
    </>
  );
};
export default LandingAgent;
