import React, { useState } from "react";
import {
  Image,
  TouchableOpacity,
  View,
  FlatList,
  Text,
  SafeAreaView,
} from "react-native";
import { Entypo } from "@expo/vector-icons";

import {
  Container,
  RightPanal,
  LeftPanal,
  CenterPanal,
  ListView,
  Section1,
  Section2,
} from "./styles.js";
import getPlatformType from "../../helpers/getPlatform";
const layoutType = getPlatformType();
import idx from "idx";
import ImageSlider from "../ImageSlider/index.js";
import { FontAwesome } from "@expo/vector-icons";
import { FormatDate, FormatPrice } from "../../helpers/validation.js";
const ServiceCard = (props) => {
  const {
    data,
    isExpand,
    navigate,
    onSave,
    searchObject,
    showCloseIcon,
    notTouchable,
    savePageData,
    pageData,
    colorCode
  } = props;
  const [save, SetSave] = React.useState(idx(data, (_) => _.isSaved));
  
  
  

  return (
    <ListView
      style={{
        shadowOffset: {
          width: 0,
          height: 2,
        },
        shadowOpacity: 0.09,
        shadowRadius: 6.68,
        elevation: 11,
        flexDirection:'row'
      }}
    >
      <View style={{flex:0.5}}>
         <View style={{width:'100%',height:'40%',justifyContent:'center',padding:15}}>
         <Text style={{color:'black',fontSize:15,fontWeight:'500'}}>{data?.name}</Text>
            <Text style={{color:'#ccc',fontSize:13,marginTop:15}}>{data?.distance.toFixed(1)} miles</Text>

         </View>
         <View style={{width:'100%',height:'45%',justifyContent:'center',padding:15}}>
         <Text style={{color:'black',fontSize:15,fontWeight:'500'}}>{data?.type}</Text>
         <Text style={{color:'black',fontSize:15,fontWeight:'500'}}>{data?.sixth_form}</Text>

         </View>
         <View style={{width:'100%',height:'15%',justifyContent:'center',alignItems:'center',backgroundColor:'#'+colorCode}}>
         <Text style={{color:'white',fontSize:15,fontWeight:'500'}}>{data?.phase} School</Text>

         </View>

      </View>
      <View style={{flex:0.5}}>
      <View style={{width:'100%',height:'40%',justifyContent:'center',padding:15,alignItems:'center'}}>
            <TouchableOpacity onPress={()=>window.open(data?.url, "_blank")}>
            <Text style={{color:'black',textDecorationLine:'underline',fontSize:13}}>View report</Text>
            </TouchableOpacity>
            
            <Text style={{color:'black',fontSize:15,fontWeight:'500',marginTop:10}}>{data?.rating}</Text>


         </View>
         <View style={{width:'100%',height:'45%',justifyContent:'center',padding:15}}>
         

         </View>
         <View style={{width:'100%',height:'15%',justifyContent:'center',alignItems:'center',borderTopWidth:1,borderTopColor:'#cdcdcd'}}>
         <Text style={{color:'black',fontSize:15,fontWeight:'500'}}>{data?.schoolType} School</Text>
         </View>
     </View>
     {showCloseIcon && (
          <TouchableOpacity
            style={{ position: "absolute", right: 10, top: 5, zIndex: 999 }}
            onPress={() => {
              props.closeCard();
            }}
          >
            <Entypo name="cross" size={20} color="#E71E82" />
          </TouchableOpacity>
        )}
      
    </ListView>
  );
};

export default ServiceCard;
