import React, { Component } from "react";
import {
  View,
  Text,
  Dimensions,
  SafeAreaView,
  Image,
  Button,
  TouchableOpacity,
  Platform,
  ScrollView,
  StyleSheet,
} from "react-native";
import ImageResizeMode from "react-native/Libraries/Image/ImageResizeMode";
import { Linking } from "react-native";
import { AntDesign } from "@expo/vector-icons";
import { COLORS } from "../constants/colorPallete";
export default class IntroScreen extends Component {
  state = {
    currentTab: 0,
    tlyfeTab:0

  };
  handleClick = (e, index) => {
    e.preventDefault();
    
    this.setState({
      currentTab: e.target.getAttribute("data-target"),
      tlyfeTab: e.target.getAttribute("tlyfe-target"),

    });
  };
  handleClickLeft = () => {
    this.setState({
      currentTab: this.state.currentTab==0?(this.agentArr.length-1):this.state.currentTab - 1,
    });
  };
  handleClickRight = () => {
    this.setState({
      currentTab: this.state.currentTab==(this.agentArr.length-1)?0:this.state.currentTab +1,
    });
  };
  handleTlyfeLeft = () => {
    this.setState({
      tlyfeTab: this.state.tlyfeTab==0?(this.tlyfeArr.length-1):this.state.tlyfeTab - 1,
    });
  };
  handleTlyfeRight = () => {
    this.setState({
      tlyfeTab: this.state.tlyfeTab==(this.tlyfeArr.length-1)?0:this.state.tlyfeTab +1,
    });
  };
  sliderConfig = {
    screens: 5,
  };
   tlyfeArr = [
    {
      value:
        "Find your perfect property on one of the UKs leading property portals",
      text:
        "Providing you with an unlimited and secure storage facility to share docu-mentation directly with your agents, without any data-mining.",
      image: require("../assets/images/tlyfeImageText2.png"),
      title:"Document Storage"
    },
    {
      value:
        "We empower tenants, buyers & agents through transparent engagement & innovative technology",
      text:
        "A Government Grade verified and secure Digital ID owned by you the Consumer where you can pre-reference and qualify yourselves. R2R and KYC/AML",
      image: require("../assets/images/tlyfeImageText1.png"),
      title:"Verified Digital ID"
    },
    {
      value: "Improve your credit rating just from paying your rent on time ",
      text: "Gives you the tenat a lifetime record of validated rental payments and access to increase your credit scores when paying your rent.",
      image: require("../assets/images/tlyfeImageText3.png"),
      title:"Rental Passport"
    }
  ];
  agentArr = [
    {
      value:
        "Find your perfect property on one of the UKs leading property portals",
      text:
        "Agents can integrate and interact with approved Suppliers with ease.",
      image: require("../assets/images/marketplace.png"),
      title:"MarketPlace"
    },
    {
      value:
        "We empower tenants, buyers & agents through transparent engagement & innovative technology",
      text:
        "Seamless integration to ‘best in class’ approved suppliers and the facility to earn substantial referral fees transparently",
      image: require("../assets/images/thirdparty.png"),
      title:"3rd Party Partnerships"
    },
    {
      value: "Improve your credit rating just from paying your rent on time ",
      text: "Hare properties with other Agents and international Agents. Collaborate together, work for buyers, share commissions all whilst maintaining control as the primary agent",
      image: require("../assets/images/multilisting.png"),
      title:"Multi-Listing Service"
    },
    {
      value:
        "Manage maintenance tickets and all agent communication on one simple to use platform",
      text: "An end to end administration process for the agent to onboard a tenant all in one secure platform with a Rent Collection Service as an option",
      image: require("../assets/images/workflowtool.png"),
      title:"Tenant Onboard\nWork-Flow Tool"
    },
    {
      value:
        "We empower tenants, buyers & agents through transparent engagement & innovative technology",
      text:
        "Agents staff receive an ID and can take and retain their learning material, workbooks, CPD and qualifications on their portable devices. Blockchain evidence repository for complaint handling.Coming Soon!",
      image: require("../assets/images/learning.png"),
      title:"Learning"
    }
    
  ];
  sliderData = [
    {
      heading: "Benefits of OpenBrix",
      cardImg: "../assets/images/introScreenIcon.png",
      benefitName: "Test",
      benefitDetails:
        "Find your perfect property on one of the Uk's leading property portals",
      ctaLink: "#",
    },
    {
      heading: "Benefits of OpenBrix",
      cardImg: "../assets/images/introScreenIcon.png",
      benefitName: "Technology",
      benefitDetails:
        "We empower tenants, buyers & agents through transparent engagement & innovative technology",
      ctaLink: "#",
    },
    {
      heading: "Benefits of OpenBrix",
      cardImg: "../assets/images/introScreenIcon.png",
      benefitName: "Credit Score",
      benefitDetails:
        "Improve your credit rating just from paying your rent on time",
      ctaLink: "#",
    },
    {
      heading: "Benefits of OpenBrix",
      cardImg: "../assets/images/introScreenIcon.png",
      benefitName: "Agent Communication",
      benefitDetails:
        "Manage maintenance tickets and all agent communication on one simple to use platform",
      ctaLink: "#",
    },
  ];

  render() {
    const styles = styles_tab;
    let currentSlide = parseInt(this.state.currentTab);
    let currentTlyfeSlide=parseInt(this.state.tlyfeTab)
    
    return this.props?.show=="Tab1"?<View style={styles.introScreenBox}>
        <AntDesign
          onPress={() => this.handleTlyfeLeft()}
          name="caretleft"
          size={24}
          color={COLORS.orange}
        />
        <View style={styles.container}>
          <View style={[styles.wrappers,{flexDirection:'column',alignItems:'center'}]}>
            <Text style={[styles.introheaderText,{color:COLORS.orange}]}>Introducing</Text>
            <Image
                  source={require("../assets/images/tlyfeBig.png")}
                  resizeMode="contain"
                  style={{ width: 80, height: 50}}
                />
          </View>
          <View
            style={{
             height:79.2,
             width:79.2,
             borderRadius:4000,
             backgroundColor:'#fff',
             alignItems:'center',
             justifyContent:"center",
              marginTop: 10,
              shadowOffset: {
                width: 0,
                height: 2,
              },
              shadowOpacity: 0.09,
              shadowRadius: 6.68,
              elevation: 11,
              marginBottom:10
            }}
          >
           
              <Image
                source={this.tlyfeArr[currentTlyfeSlide].image}
                resizeMode={ImageResizeMode.contain}
                style={{ width: 40, height: 40,resizeMode:'contain' }}
              ></Image>
          </View>
          <View style={styles.wrappers}>
            <Text style={styles.benefitName}>
              {this.tlyfeArr[currentTlyfeSlide].title}
            </Text>
            <Text style={styles.benefitDetails}>
              {this.tlyfeArr[currentTlyfeSlide].text}
            </Text>
          
          </View>
          <View style={styles.sliderControlBox}>
            {this.tlyfeArr.map((slide, index) => {
              return (
                <Text
                  onPress={this.handleClick}
                  tlyfe-target={index}
                  title=" "
                  style={[
                    styles.sliderControl,
                    {
                      backgroundColor:
                      currentTlyfeSlide == index ? COLORS.orange : "#dddddd",
                    },
                  ]}
                  key={index}
                ></Text>
              );
            })}
          </View>
        </View>
        <TouchableOpacity onPress={() => this.handleTlyfeRight()}>
          <AntDesign name="caretright" size={24} color={COLORS.orange} />
        </TouchableOpacity>
      </View>
    :<View style={styles.introScreenBox}>
        <AntDesign
          onPress={() => this.handleClickLeft()}
          name="caretleft"
          size={24}
          color="#E71E82"
        />
        <View style={styles.container}>
          <View style={styles.wrappers}>
            <Text style={[styles.introheaderText,{color:'#E71E82'}]}>Agent Benefits</Text>
            {/* <View style={{ marginTop: 7 }}>
              <Text style={styles.introheaderText}>
                with
                <Image
                  source={require("../assets/images/")}
                  resizeMode="contain"
                  style={{ width: 107, height: 17, marginLeft: 8 }}
                />
              </Text>
            </View> */}
          </View>
          <View
            style={{
            
              marginTop: 10,
            }}
          >
              <Image
                source={this.agentArr[currentSlide].image}
                resizeMode={ImageResizeMode.contain}
                style={{ width: 120, height: 120 }}
              ></Image>
          </View>
          <View style={styles.wrappers}>
            <Text style={styles.benefitName}>
              {this.agentArr[currentSlide].title}
            </Text>
            <Text style={styles.benefitDetails}>
              {this.agentArr[currentSlide].text}
            </Text>
          
          </View>
          <View style={styles.sliderControlBox}>
            {this.agentArr.map((slide, index) => {
              return (
                <Text
                  onPress={this.handleClick}
                  data-target={index}
                  title=" "
                  style={[
                    styles.sliderControl,
                    {
                      backgroundColor:
                        currentSlide == index ? "#E71E82" : "#dddddd",
                    },
                  ]}
                  key={index}
                ></Text>
              );
            })}
          </View>
        </View>
        <TouchableOpacity onPress={() => this.handleClickRight()}>
          <AntDesign name="caretright" size={24} color="#E71E82" />
        </TouchableOpacity>
      </View>
  }
}
const styles_tab = StyleSheet.create({
  introScreenBox: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    backgroundColor: "#fff",
    alignItems: "center",
    height: "100%",
  },
  container: {
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: 236,
    // paddingLeft: '24',
    // paddingRight: '24',
  },
  introheaderText: {
    fontSize: 21.6,
    color: "#000000",
    fontWeight: "bold",
    letterSpacing: 0,
    textAlign: "center",
  },
  introheaderImgbox: {
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 5,
    width: "250",
  },
  introheaderImg: {
    height: "auto",
    width: "100%",
    marginLeft: 10,
    //position: "relative"
  },
  sliderMainImg: {
    height: 337,
    width: 187,
  },
  wrappers: {
    marginTop: 10,
    marginBottom: 10,
  },
  benefitName: {
    color: "#000",
    fontSize: 16,
    fontWeight: "bold",
    textAlign: "center",
    marginTop: 5,
    marginBottom: 5,
  },
  benefitDetails: {
    color: "#000000",
    fontSize: 12.3,
    letterSpacing: 0,
    lineHeight: 18,
    textAlign: "center",
    marginTop: 5,
    marginBottom: 5,
    minHeight: 36,
  },
  ctaLink: {
    color: "#000000",
    fontSize: 11.7,
    fontWeight: "bold",
    letterSpacing: 0,
    lineHeight: 14,
    textAlign: "center",
    marginTop: 5,
    marginBottom: 5,
  },
  sliderControlBox: {
    display: "flex",
    flexDirection: "row",
    marginTop: 25,
    marginBottom: 25,
  },
  sliderControl: {
    height: 9,
    width: 9,
    backgroundColor: "#D8D8D8",
    borderRadius: 50,
    color: "transparent",
    marginLeft: 3,
    marginRight: 3,
  },
});
