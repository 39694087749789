import React, { Component } from "react";
import { Image, View } from "react-native";
import getPlatformType from "../../helpers/getPlatform";
import { Box, Container, Discription, Title, WideButtonWrapper } from "./styles";

import WideButton from "../../commonComponents/wideButton";

const layoutType = getPlatformType();

export default class NoDataNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onButtonPress = () => {
    this.props.onPress();
  };

  render() {
    const {
      navigation,
      title,
      description,
      ctaText,
      imageIcon,
      buttonDisable = "false",
      btnId,
      noimage,
    } = this.props;

    return (
      <Container style={{
        marginBottom: 10,
      }}>
        <Box>
          {noimage ? <View style={{
              height: 52,
              display: "flex",
              marginTop: 10,
              marginBottom: 10,

            }}/> :
            <View style={{
              width: 108,
              height: 108,
              borderRadius: 108,
              display: "flex",
              backgroundColor: "#f1f1f1",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 10,
              marginBottom: 10,

            }}>
              <View style={{
                width: 90,
                height: 90,
                borderRadius: 90,
                display: "flex",
                backgroundColor: "#fff",
                alignItems: "center",
                justifyContent: "center",
              }}>
                <Image
                  source={imageIcon ? imageIcon : require("../../assets/images/creditScore.png")}
                  resizeMode={"contain"}
                  style={{
                    width: 45,
                    height: 45,
                    alignItems: "center",
                    justifyContent: "center",
                  }}

                ></Image>
              </View>
            </View>
          }
          <Title style={{
            textAlign: "center",
            color: "black",
          }}>{title}</Title>
          <Discription style={{ textAlign: "center" }}>{description}</Discription>
          {buttonDisable != "true" && <WideButtonWrapper>
            <WideButton
              buttonType={"solid"}
              gradiant
              borderRadius={15}
              isLGreen
              id={btnId}
              childText={ctaText ? ctaText : "Back to search"}
              onPress={() => this.onButtonPress()}
            />
          </WideButtonWrapper>}

        </Box>
      </Container>
    );
  }
}
