import React from "react";
import styled from "styled-components/native";
import getPlatformType from "./getPlatform";
import { Dimensions } from "react-native";
const WIN_HEIGHT = Dimensions.get("window").height;

const layoutType = getPlatformType();
let ContainerScrollView;

if (layoutType == "phone") {
  ContainerScrollView = styled.ScrollView`
    height: ${(props) =>
      props.height ? WIN_HEIGHT - props.height : WIN_HEIGHT - 70};
  `;
} else if (layoutType == "web") {
  ContainerScrollView = styled.ScrollView`
    height: ${(props) =>
      props.height ? WIN_HEIGHT - props.height : WIN_HEIGHT - 110};
  `;
} else {
  ContainerScrollView = styled.ScrollView`
    height: ${(props) =>
      props.height ? WIN_HEIGHT - props.height : WIN_HEIGHT - 110};
  `;
}

export { ContainerScrollView };
