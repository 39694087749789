import React from "react";
import styled from "styled-components/native";
import variables from "../variables.json";
import { Feather } from '@expo/vector-icons';
import { MaterialCommunityIcons } from '@expo/vector-icons'; 
import { FontAwesome } from '@expo/vector-icons'; 

import { OPEN_FLO_THEME ,OPEN_FLO_TEXT_THEME, generalColorsTheme} from "../../../../constants/colorPallete";
import { LinearGradient } from "expo-linear-gradient";
import {Text} from 'react-native';

const CompleteButton = styled(LinearGradient)`
  display: flex;
  flex-direction:row;
  border-radius: 1000px;
  width: 120px;
  height: 28px;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.color ? props.color : variables.primary)};
  padding-left:5px;
  padding-right:5px;
  align-items: center;
  justify-content: center;
`;
const HeaderTitle = styled.Text`
  color: ${variables.black};
  font-size: 12px;
  font-weight: 500;
`;
const InCompleteButton = styled.View`
  display: flex;
  border-radius: 1000px;
  width: ${(props) => (props.width ? props.width : "120px")};
  height: 28px;
  border: ${(props) => (props.color ? "1px solid "+props.color : "1px solid #000000")};
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;
export const StatusButton = props => {
  if (props.type ===1) {
    return (
      <InCompleteButton 
       {...props}>
      <FontAwesome name="check" color="#1B8A8C" size={14} style={{marginRight:5}} />
        <Text id="openflo-complete" style={{fontSize:13,fontWeight:"500",color:'#1B8A8C'}}>{props?.customText?props?.customText:"Completed"}</Text>
      </InCompleteButton>
    );
  }
  else if (props.type == 0) {
    return <InCompleteButton  
    textColor={"#000000"}
    border={"#000000"}{...props}><HeaderTitle id="openflo-NotStarted-1"> Not Started</HeaderTitle></InCompleteButton>;
  }
  else if (props.type == 4) {
    return <InCompleteButton 
    {...props}>
            <MaterialCommunityIcons name="progress-clock" color="#FFC059" size={20} style={{marginRight:5}} />

    <HeaderTitle id="openflo-InProcess" style={{color:'#FFC059'}}>In Progress</HeaderTitle></InCompleteButton>;
  }
  else if (props.type == 5) {
    return <CompleteButton colors={[generalColorsTheme.red,generalColorsTheme.red]}
    textColor={OPEN_FLO_TEXT_THEME.actionrequired}
    color={OPEN_FLO_THEME.actionrequired} {...props}><HeaderTitle id="openflo-actionRequired" style={{color:'white',fontSize:11}}>Action Required</HeaderTitle></CompleteButton>;
  }
  else if (props.type == 6) {
    return <InCompleteButton 
    border={OPEN_FLO_THEME.notstarted}
    textColor={OPEN_FLO_TEXT_THEME.notstarted}
    width={"120px"} {...props}><HeaderTitle id="noActionRequired">No Action Required</HeaderTitle></InCompleteButton>;
  }
   else {
    return <InCompleteButton   
    textColor={"#000000"}
    border={"#000000"} {...props}><HeaderTitle id="openflo-notStarted-2">{props?.customText?props?.customText:"Not Started"}</HeaderTitle></InCompleteButton>;
  }
};

{
  /*
Example.(type=complete/incomplete)
<StatusButton type={"incomplete"}/>
*/
}
