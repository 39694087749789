import React from "react";
import styled from "styled-components/native";
import getPlatformType from "../../helpers/getPlatform";
const layoutType = getPlatformType();

let TextBox;
let Title;
let RightText;
let OuterView;

//
// ─── MOBILE SPECIFIC CSS ────────────────────────────────────────────────────────
//

if (layoutType == "phone") {
  OuterView = styled.View`
    width: ${(props) => (props.width ? props.width : "100%")};
    height: ${(props) => (props.height ? props.height : "30px")};
  `;
  TextBox = styled.TextInput`
    height: 45px;
    width: ${(props) => props.width};
    border: 1px solid #d0d0d0;
    border-radius: 5px;
    background-color: white;
  `;

  Title = styled.Text`
    height: 18px;
    color: #000000;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
  `;
}
//
// ─── WEB SPECIFIC CSS ───────────────────────────────────────────────────────────
//
else if (layoutType == "web") {
  OuterView = styled.View`
    width: ${(props) => props.width};
    padding-bottom: 10px;
  `;
  TextBox = styled.TextInput`
    height: 45px;
    width: ${(props) => props.width};
    border: 1px solid #d0d0d0;
    border-radius: 5px;
  `;
  Title = styled.Text`
    height: 19px;
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
  `;
}

//
// ─── TABLET SPECIFIC CSS ────────────────────────────────────────────────────────
//
else {
  OuterView = styled.View`
    width: ${(props) => props.width};
    padding-bottom: 10px;
  `;
  TextBox = styled.TextInput`
    height: 40px;
    width: ${(props) => props.width};
    border: 1px solid #d0d0d0;
    border-radius: 5px;
  `;

  Title = styled.Text`
    height: 17px;
    color: #000000;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 17px;
  `;
}

export { TextBox, Title, RightText, OuterView };
