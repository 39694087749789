import React from 'react';
import { Text,View } from "react-native";
import { TouchableOpacity } from "react-native";

const TwistedButton=(props)=>{
    return(
        <TouchableOpacity 
        id={props?.id}
        onPress={props?.onPress} style={[{justifyContent:'center',alignItems:'center'},props?.customStyle]}>
           <View style={{transform:[{rotate:'2deg'}],width:'285px',borderRadius:1000,height:'56px',borderWidth:0.5,borderColor:'#6D6D6D',justifyContent:'center',alignItems:'center'}}/>           
           <View style={{transform:[{rotate:'178deg'}],width:'285px',position:'absolute',borderRadius:1000,height:'56px',borderWidth:0.5,borderColor:'#6D6D6D',justifyContent:'center',alignItems:'center'}}/>
        <Text style={{position:'absolute',fontFamily:'Lato-Bold',fontSize:15}}>{props?.title}</Text>
        </TouchableOpacity>
      
    )
}
export default TwistedButton;