import React from "react";
import { TouchableOpacity, Text, ActivityIndicator, View } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { COLORS } from "../constants/colorPallete";
export default class TlyfeButton extends React.Component {
  render() {
    const {
      backgroundColor,
      childText,
      onPress,
      height,
      loadingState,
      borderColor,
      borderWidth,
      childColor,
      width,
      whiteButton,
      borderRadius,
      gradBorderRadius,
      gradBorderWidth,
    } = this.props;
    return (
      <TouchableOpacity
        disabled={loadingState}
        onPress={onPress}
        style={{
          backgroundColor: backgroundColor ? backgroundColor : "transparent",
          height: height ? height : 30,
          width: width ? width : "95%",
          borderRadius: borderRadius ? borderRadius : 40,
          borderColor: borderColor ? borderColor : null,
          borderWidth: borderWidth ? borderWidth : null,
        }}
      >
        <LinearGradient
          colors={
            borderColor
              ? [borderColor, borderColor]
              : ['#FBB1E6',COLORS.orange]
              
          }
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            height: "100%",
            width: "100%",
            borderRadius: gradBorderRadius ? gradBorderRadius : 20,

            padding: gradBorderWidth ? gradBorderWidth : 2,
          }}
          start={[0.4, 0.5]}
          end={[1, 1]}
        >
          <View
            style={{
              height: "100%",
              width: "100%",
              backgroundColor: whiteButton ? "white" : "transparent",
              borderRadius: gradBorderRadius ? gradBorderRadius : 30,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {loadingState ? (
              <ActivityIndicator color="white" />
            ) : (
              <Text
                style={{
                  color: childColor
                    ? childColor
                    : whiteButton
                    ? "black"
                    : "white",
                  fontSize: 16,
                }}
              >
                {childText}
              </Text>
            )}
          </View>
        </LinearGradient>
      </TouchableOpacity>
    );
  }
}
