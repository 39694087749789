import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { tdsActions } from './tdsRedux/tds.actions';
import { backgroundTdsArr } from './backgroundTdsArr';
import { navigateAction } from '../../helpers/navigationHelpers';
import { Container, ButtonWrapper, Typography, BoxShadow, primaryColor } from './style';
import BackGroundGenerator from '../LandingPages/common/BackGroundGenerator';
import CustomTextInput from '../../commonComponents/customTextInput/customTextInput';
import { types } from '../../redux/actionTypes';
import Regex from '../../helpers/regex';

const custodialAddInternationalBankAccount = (props) => {
  const dispatch = useDispatch();
  const [type, setType] = useState('web');
  const [account, setAccount] = useState({});
  const tdsAccount = useSelector((state) => state.tds?.tdsAccount);

  useEffect(() => {
    dispatch(tdsActions.getTDSAccount());
  }, []);

  const updateInternationBankAccount = () => {
    if(account.international_name_on_account=="" || account.international_name_on_account==null){
      dispatch({
        type: types.SHOW_ALERT,
        alertType:"error",
        text:"Name on account is required",
      });
    }
    else if(account.international_bank_name=="" || account.international_bank_name==null){
      dispatch({
        type: types.SHOW_ALERT,
        alertType:"error",
        text:"Bank name is required",
      });
    }
    else if(account.international_bank_identification_code=="" || account.international_bank_identification_code==null){
      dispatch({
        type: types.SHOW_ALERT,
        alertType:"error",
        text:"Identification Code is required",
      });
    }
    else if(account.international_iban=="" || account.international_iban==null){
      dispatch({
        type: types.SHOW_ALERT,
        alertType:"error",
        text:"IBAN Code is required",
      });
    }
    else if(account.password=="" || account.password==null){
      dispatch({
        type: types.SHOW_ALERT,
        alertType:"error",
        text:"Password is required",
      });
    }
    else{
      dispatch(tdsActions.updateInternationalBankAccount(account));
    }

  };

  const handleChange = (value, field) => {
    setAccount({ ...account, [field]: value });
  };

  useEffect(() => {
    if (tdsAccount) {
      const bankDetails = {
        international_iban: tdsAccount.person.international_iban,
        international_bank_name: tdsAccount.person.international_bank_name,
        international_swift_code: tdsAccount.person.international_swift_code,
        international_bank_address: tdsAccount.person.international_bank_address,
        international_name_on_account: tdsAccount.person.international_name_on_account,
        international_bank_identification_code:
          tdsAccount.person.international_bank_indentification_code,
      };
      setAccount(bankDetails);
    }
  }, [tdsAccount]);

  return (
    <>
      <BackGroundGenerator
        isTds
        props={props}
        layoutType={(data) => {
          setType(data);
        }}
        isTLYF
        infoIcon
        backgroundArr={backgroundTdsArr}
        isBack={true}
        backNavigation={()=> navigateAction(props.navigation, 'Custodial Account') }
      >
        <Container>
          <BoxShadow>
            <Typography color={primaryColor} size={'23px'} bold={700}>
              Add your International bank details
            </Typography>
            <Typography top={'24px'} color={'#454545'} size={'20px'} bold={400}>
            If you need the scheme to make an international payment, please complete the details below.
            </Typography>
            <Typography top={'24px'} color={'#454545'} size={'20px'} bold={400}>
              Due to regulations in place by the Chinese authorities, we are unable to refund
              deposits to bank accounts in the People’s Republic of China.
            </Typography>
          </BoxShadow>

          <BoxShadow top={'23px'} bottom={'100px'}>
            <CustomTextInput
              width={'100%'}
              title="Name on Account"
              type="text"
              name="account_name"
              prefilledValue={account.international_name_on_account}
              bColor={'#638ACC'}
              onChangeText={(value) =>Regex.validateBankName(value) && handleChange(value, 'international_name_on_account')}
            />

            <CustomTextInput
              width={'100%'}
              title="Bank Name"
              type="text"
              name="bank_name"
              prefilledValue={account.international_bank_name}
              bColor={'#638ACC'}
              onChangeText={(value) =>Regex.validateBankName(value) &&  handleChange(value, 'international_bank_name')}
            />

            <CustomTextInput
              width={'100%'}
              title="Bank Address"
              type="text"
              name="bank_address"
              prefilledValue={account.international_bank_address}
              bColor={'#638ACC'}
              onChangeText={(value) => handleChange(value, 'international_bank_address')}
            />

            <CustomTextInput
              width={'100%'}
              title="Identification Code"
              type="text"
              name="identification_code"
              prefilledValue={account.international_bank_identification_code}
              bColor={'#638ACC'}
              onChangeText={(value) =>
                handleChange(value, 'international_bank_identification_code')
              }
            />

            <CustomTextInput
              width={'100%'}
              title="Swift code"
              type="text"
              name="swift_code"
              prefilledValue={account.international_swift_code}
              bColor={'#638ACC'}
              onChangeText={(value) => handleChange(value, 'international_swift_code')}
            />

            <CustomTextInput
              width={'100%'}
              title="IBAN Code"
              type="text"
              name="iban_code"
              prefilledValue={account.international_iban}
              bColor={'#638ACC'}
              onChangeText={(value) => handleChange(value, 'international_iban')}
            />

            <CustomTextInput
              width={'100%'}
              title="Password"
              type="password"
              name="password"
              password
              prefilledValue={account.password}
              bColor={'#638ACC'}
              onChangeText={(value) => handleChange(value, 'password')}
            />
            <ButtonWrapper id={'TDS-SAVE-INTERNATIONAL-BANK'} onPress={() => updateInternationBankAccount()}>
              <Typography color={'#FFFFFF'} size={'19px'} bold={700}>
                SAVE
              </Typography>
            </ButtonWrapper>
          </BoxShadow>
        </Container>
      </BackGroundGenerator>
    </>
  );
};

export default custodialAddInternationalBankAccount;
