import React from 'react';
import styled from 'styled-components/native';
import { COLORS } from '../../constants/colorPallete';
import getPlatformType from '../../helpers/getPlatform';
import { ImageBackground } from 'react-native';

const layoutType = getPlatformType();

let Background, Wrapper, ActionText, BoxShadow, CircularWrapper, BottomWrapper, ActionWrapper;

let CustomButton = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  border-radius: 18px;
  width:  ${(props) => (props.width ? props.width : `100%`)};
  height: ${(props) => (props.height ? props.height : `40px`)};
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.color};
  border: ${(props) => (props.bcolor ? `1px solid ${props.bcolor}` : `0px solid white`)};
  cursor: pointer;
  margin-top:  ${(props) => (props.top ? props.top : '0px')};
`;
let ButtonText = styled.Text`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  color: ${(props) => (props.color ? props.color : '#FAFAFA')};
  margin-bottom: 4px;
`;
let Heading1 = styled.Text`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 22px;
  color: ${(props) => (props.color ? props.color : COLORS.LGREEN)};
  margin-top: ${(props) => (props.top ? props.top : '0px')};
`;
let Heading2 = styled.Text`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: ${(props) => (props.color ? props.color : '#FAFAFA')};
  margin-top: ${(props) => (props.top ? props.top : '0px')};
  text-decoration-line: ${(props) => (props?.underline ? 'underline' : 'none')}; ;
`;
let Block = styled.View``;
if (layoutType == 'phone') {
  Wrapper = styled.View`
    margin: 15px 16px 10px 16px;
    background: #fafafa;
    align-items: center;
  `;
  CircularWrapper = styled.View`
  height: 53px;
  width: 53px;
  border-radius: 100px;
  background: ${COLORS.LGREEN};
  align-items: center;
  justify-content: center;
`;
  Background = styled(ImageBackground)`
    min-height:${(props) => (props?.height ? props.height : '218px')}; ;
    width: "100%";
    padding: ${(props) => (props.padding ? props.padding : '0px 23px 10px 38px')};
  `;
  BoxShadow = styled.View`
    display: flex;
    background: #ffffff;
    /* box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.15);
    border-radius: 15px 15px; */
    width: fit-content;
    margin-top: ${(props) => (props.top ? props.top : '16px')};
    width:100%
  `;
  ActionText = styled.Text`
    font-family: 'Lato';
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    /* red state */
    color: #ff5a51;
  `;
  BottomWrapper = styled.View`
    display: flex;
    width: 100%;
    justify-content: space-evenly;
  `;
  ActionWrapper = styled.View`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top:20px;
  `;
} else if (layoutType == 'web') {
  Wrapper = styled.View`
    margin: 23px 10% 20px 10%;
    background: #fafafa;
    align-items: center;
  `;
  CircularWrapper = styled.View`
    height: 63.66px;
    width: 63.66px;
    border-radius: 100px;
    background: ${COLORS.LGREEN};
    align-items: center;
    justify-content: center;
  `;
  Background = styled(ImageBackground)`
    min-height: ${(props) => (props.height ? props.height : '264px')};
    width: ${(props) => (props.width ? props.width : '432px')};
    padding: ${(props) => (props.padding ? props.padding : '0px 138px 10px 38px')};
  `;
  BoxShadow = styled.View`
    display: flex;
    flex-direction: row;
    background: #ffffff;
    box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.15);
    border-radius: 15px 15px;
 margin-right: ${(props) => (props.right ? props.right : '40px')};
    margin-top:20px;

  `;
  ActionText = styled.Text`
    font-family: 'Lato';
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #ff5a51;
  `;
  BottomWrapper = styled.View`
    display: flex;
    flex-direction: row;
    margin-top: 32px;
  `;
    ActionWrapper = styled.View`
    width: 302px;
    padding-horizontal: 17px;
    padding-top: 55px;
    align-items: 'center';
    position:absolute;
    right:0;
    bottom:30;
  `;
} else {
  Wrapper = styled.View`
    background: #fafafa;
    align-items: center;
  `;
  CircularWrapper = styled.View`
    height: 63.66px;
    width: 63.66px;
    border-radius: 100px;
    background:  ${COLORS.LGREEN};
    align-items: center;
    justify-content: center;
  `;
  Background = styled(ImageBackground)`
    min-height: ${(props) => (props.height ? props.height : '264px')};
    width: ${(props) => (props.width ? props.width : '432px')};
    padding: ${(props) => (props.padding ? props.padding : '0px 138px 10px 38px')};
  `;
  ActionText = styled.Text`
    font-family: 'Lato';
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #ff5a51;
  `;
  BoxShadow = styled.View`
    display: flex;
    flex-direction: row;
    background: #ffffff;
    box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.15);
    border-radius: 15px 15px;
    width: fit-content;
    margin-right: ${(props) => (props.right ? props.right : '40px')};
    margin-top:20px;
  `;
  BottomWrapper = styled.View`
   display: flex;
    flex-direction: row;
    margin-top: 32px;
  `;
  ActionWrapper = styled.View`
  width: 302px;
    padding-horizontal: 17px;
    padding-top: 55px;
    align-items: 'center';
    position:absolute;
    right:0;
    bottom:30;
`;
}

export {
  Block,
  Wrapper,
  Background,
  ActionText,
  BoxShadow,
  CustomButton,
  ButtonText,
  Heading1,
  Heading2,
  CircularWrapper,
  BottomWrapper,
  ActionWrapper
};
