import React from "react";
import styled from "styled-components/native";
import { COLORS } from "../../constants/colorPallete";
import getPlatformType from "../../helpers/getPlatform";

const layoutType = getPlatformType();

let MainTitle,
  Title,
  ImageWrapper,
  OpenBrixCard,
  TlyfeWrapper,
  Wrapper,
  BoxWrapper,
  CardWrapper,
  ImageBox,
  MainSection;

if (layoutType == "phone") {
  MainTitle = styled.Text`
    font-size: 19.5px;
    line-height: 23px;
    color: #000000;
    text-align: center;
  `;
  ImageWrapper = styled.View`
  flex-direction:row;
  align-items:center;
  justify-content:center;
  flex-wrap:wrap;
  width:100%
  `;
  ImageBox= styled.View`
  margin-right:7px

  margin-top:20px;
  `
  Title = styled.Text`
    color: #000;
    font-size: 15px;
    line-height: 18px;
  `;
  TlyfeWrapper = styled.View`
    margin-top: 20px;
  `;
  Wrapper = styled.View`
    margin-horizontal: 20px;
    align-items: center;
  `;
  BoxWrapper = styled.View`
height: 52.12px;
width: 323px;
border-radius: 5px;
border: 1px solid #D0D0D0;
margin-top: 10px
justify-content:center;
align-items:center;
`;
  CardWrapper = styled.View`
    width: 100%;
    align-items: center;
  `;
  MainSection = styled.View`
    align-items: flex-start;
    margin: 10px;
  `;
  OpenBrixCard = styled.TouchableOpacity`
    height: 60px;
    width: 92%;
    background-color: #ffffff;
    box-shadow: 1px 1px #d0cfd0;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    flex-direction: row;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 15px;
    align-self: center;
  `;
} else if (layoutType == "web") {
  ImageBox= styled.View`
  margin-right:46px
  margin-top:20px;
  `
  ImageWrapper = styled.View`
  flex-direction:row;
  align-items:center;
  justify-content:center;
  flex-wrap:wrap;
  width:100%
  `;
  MainTitle = styled.Text`
    font-size: 19.5px;
    line-height: 23px;
    color: #000000;
    text-align: center;
  `;
  Title = styled.Text`
    color: #000;
    font-size: 15px;
    line-height: 18px;
  `;
  TlyfeWrapper = styled.View`
    margin-top: 20px;
    min-height: 80vh;
  `;
  Wrapper = styled.View`
    margin-horizontal: 20px;
    align-items: center;
  `;
  BoxWrapper = styled.View`
box-sizing: border-box;
height: 52.12px;
width: 332px;
border-radius: 5px;
border: 1px solid #D0D0D0;
margin-top: 10px
justify-content:center;
align-items:center;
`;
  CardWrapper = styled.View`
    max-width: 1000px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top: 10px;
  `;
  MainSection = styled.View`
    align-items: center;
    margin: 10px;
  `;
  OpenBrixCard = styled.TouchableOpacity`
    height: 60px;
    width: 750px;
    background-color: #ffffff;
    box-shadow: 1px 1px #d0cfd0;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    flex-direction: row;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 15px;
    align-self: center;
  `;
} else {
  ImageBox= styled.View`
  margin-right:15px

  margin-top:20px;
  `
  ImageWrapper = styled.View`
  flex-direction:row;
  align-items:center;
  justify-content:center;
  flex-wrap:wrap;
  width:100%
  `;
  MainTitle = styled.Text`
    font-size: 19.5px;
    line-height: 23px;
    color: #000000;
    text-align: center;
  `;
  Title = styled.Text`
    color: #000;
    font-size: 15px;
    line-height: 18px;
  `;
  TlyfeWrapper = styled.View`
    margin-top: 20px;
    min-height: 80vh; 
  `;
  Wrapper = styled.View`
    margin-horizontal: 20px;
    align-items: center;
  `;
  BoxWrapper = styled.View`
box-sizing: border-box;
height: 52.12px;
width: 332px;
border-radius: 5px;
border: 1px solid #D0D0D0;
margin-top: 10px
justify-content:center;
align-items:center;
`;
  CardWrapper = styled.View`
    width: 98%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
  `;
  MainSection = styled.View`
    align-items: center;
    margin: 10px;
  `;
  OpenBrixCard = styled.TouchableOpacity`
    height: 60px;
    width: 50%;
    background-color: #ffffff;
    box-shadow: 1px 1px #d0cfd0;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    margin-bottom: 90px;
    flex-direction: row;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 90px;
    align-self: center;
  `;
}

export {
  MainTitle,
  Title,
  TlyfeWrapper,
  Wrapper,
  BoxWrapper,
  OpenBrixCard,
  CardWrapper,
  MainSection,
  ImageWrapper,
  ImageBox
};
