import React, { useEffect } from "react";
import { View, Text } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { COLORS } from "../../constants/colorPallete";

import getPlatformType from "../../helpers/getPlatform";
const layoutType = getPlatformType();
import { StatusBarMessage, StatusBarContainer, StatusBarBox } from "./styles";
function StatusBarComponent(props) {
  const { text, type } = props;
  useEffect(() => {}, []);
  return (
    <StatusBarContainer>
      <StatusBarBox>
        <LinearGradient
          colors={
            type == "error"
              ? [COLORS.GREY1, COLORS.GREY1]
              : [COLORS.gradientColor3,COLORS.gradientColor4]
          }
          style={{
            height: "100%",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "row",
            padding: 12,
            borderBottomLeftRadius: layoutType == "phone" ? 0 : 20,
            borderBottomRightRadius: layoutType == "phone" ? 0 : 20,
          }}
          start={[0.25, 0.5]}
          end={[1, 1]}
        >
          <StatusBarMessage
          id={"STATUS_TEXT"}
          >{text}</StatusBarMessage>
        </LinearGradient>
      </StatusBarBox>
    </StatusBarContainer>
  );
}

export default StatusBarComponent;
