import React, { Component } from "react";
import {
  View,
  TouchableWithoutFeedback,
  TouchableOpacity,
  findNodeHandle,
  Button,
  SafeAreaView,
  ScrollView,
  Text,
} from "react-native";
import { Entypo } from "@expo/vector-icons";

import getPlatformType from "../../helpers/getPlatform";
import CustomDropdown from "../../commonComponents/customDropdown/customDropdown";
import WideButton from "../../commonComponents/wideButton";
import BoxSelector from "../../commonComponents/boxSelector/boxSelector";
import DropDownPicker from "./../../commonComponents/DropDownPicker";
import BubbleMultiSelectInput from "../../commonComponents/bubbleMultiSelectInput/bubbleMultiSelectInput";
import {
  FlatListDropdown,
  FlatListOption,
} from "../../commonComponents/customDropdown/customDropdown.style";
import {
  FilterView,
  FilterHeading,
  DualOptionalView,
  FilterInnerView,
  ButtonView,
  ButtonWrapper,
  BoxSelectorWrapper,
  FilterHeadingBox,
  FilterHeadingClearText,
  FilterHeaderText,
  FilterHeadingClearBox,
  SearchText,
  FilterContent,
  TitleWrapper,
  DropDownWrapper,
  DropDownSectionWrapper,
  FormContainer
} from "./searchFilterStyles";
import Form from "./form";
const layoutType = getPlatformType();
export default class SearchFilter extends Component {
  state = {
    locError: '',
    customDropdown: {
      isOpen: false,
      currentDropdownList: "",
      radius: "",
      dateListed: "",
      rentalTerm: "",
      currentDropdown: "",
      selectedRadius: 16,
      keyword: [],

    },
    searchData: {
      lat: this.props.searchObject?.lat,
      lon: this.props.searchObject?.lon,
      search: this.props.searchObject?.search,
      filters: {
        min_bedroom: this.props.searchObject?.filters.min_bedroom,
        max_bedroom: this.props.searchObject?.filters.max_bedroom,
        min_price: this.props.searchObject?.filters.min_price,
        max_price: this.props.searchObject?.filters.max_price,
        accomodation_type: this.props.searchObject?.filters.accomodation_type,
        furnishing: this.props.searchObject?.filters.furnishing,
        property_for: this.props.searchObject?.filters.property_for
      },
      types:this.props.searchObject?.types

    },
    defaultRadius: this.props.searchObject?.distance ? this.props.searchObject?.distance : "16",
    defaultDate: this.props.searchObject?.date ? this.props.searchObject?.date : "0",
    defaultKeyword: this.props.searchObject?.keywords ? this.props.searchObject?.keywords : []

  };
  componentDidMount() {

    // this.setState({

    // })
  }
  filterApply = () => {
    const { keyword, selectedRadius, dateListed, searchData, defaultRadius, defaultKeyword, defaultDate } = this.state;
    
    // let radius;
    // if (selectedRadius == undefined) radius = 1;
    // else radius = selectedRadius;
    if (searchData["search"] == null || searchData["search"] == "") {
      alert("Select Location")
      this.setState({ locError: "You must select the location" });
    }
    else {
      this.props.onApply({
        keywords: defaultKeyword,
        distance: defaultRadius,
        date: defaultDate,
      }, searchData);
    }


  };
  actionOnRow = (value) => {
    const current = this.state.customDropdown.currentDropdown;
    let customDropdown = { ...this.state.customDropdown };
    customDropdown[current] = value;
    customDropdown.isOpen = !customDropdown.isOpen;
    this.setState({
      customDropdown,
    });
  };

  setCurrentDropdown = (list, currentDropdown, position) => {
    let customDropdown = { ...this.state.customDropdown };
    customDropdown.isOpen = !customDropdown.isOpen;
    customDropdown.currentDropdownList = list;
    customDropdown.currentDropdown = currentDropdown;
    customDropdown.position = position;

    this.setState({
      customDropdown,
    });
  };
  closeModal = () => {
    this.props.setModalVisible(false);
  };

  //pt 1
  radius = [
    {
      value: "0",
      label: "This area only",
    },
    {
      value: "0.4",
      label: "1/4 mile",
    },
    {
      value: "0.8",
      label: "1/2 mile",
    },
    {
      value: "1.6",
      label: "1 mile",
    },
    {
      value: "8",
      label: "5 mile",
    },
    {
      value: "16",
      label: "10 mile",
    },
    {
      value: "34",
      label: "15 mile",
    },
    {
      value: "32",
      label: "20 mile",
    },
    {
      value: "49",
      label: "30 mile",
    },
    {
      value: "65",
      label: "40 mile",
    },
  ];

  dateListed = [
    {
      value: "7",
      label: "7 days",
    },
    {
      value: "15",
      label: "15 days",
    },
    {
      value: "30",
      label: "30 days",
    },
    {
      value: "0",
      label: "Any Time",
    },
  ];

  boxButtons = [
    {
      value: "Shared Ownership",
      text: "Shared Ownership",
    },
    {
      value: "Category",
      text: "Category",
    },
    {
      value: "Category",
      text: "Category",
    },
    {
      value: "Category",
      text: "Category",
    },
    {
      value: "Category",
      text: "Category",
    },
    {
      value: "Category",
      text: "Category",
    },
  ];

  boxCategoriesSale = [
    {
      value: "Shared Ownership",
      text: "Shared Ownership",
      id:"SHAREOWNERSHIP"
    },
    {
      value: "Retirement Homes",
      text: "Retirement Homes",
      id:"RETIRMENT_HOMES"
    },
  ];
  boxCategoriesRent = [
    {
      value: "Student Let",
      text: "Student Let",
      id:"STUDENT_LET"
    },
    {
      value: "House Share",
      text: "House Share",
      id:"HOUSE_SHARE"
    },
  ];

  clearAllFilter = () => {
    this.setState({
      keyword: []
      , selectedRadius: 0,
      dateListed: '',
      defaultKeyword: [],
      defaultRadius: 16,
      defaultDate: ''
    })
  }
  updateData = (field, value) => {
    const { searchData } = this.state;
    if (field == "lat" || field == "lon" || field == "search") {
      searchData[field] = value;
    }
    else {
      searchData["filters"][field] = value;
    }
    this.setState({
      searchData
    });
  }
  render() {
    
    return (
      <ScrollView style={{}}>
        <FilterView
          style={{
            shadowOffset: {
              width: 0,
              height: 4,
            },
            shadowOpacity: 0.09,
            shadowRadius: 6.68,
            elevation: 11,
          }}
        >
          <FilterContent>
            {layoutType == "phone" ? (
              <FilterHeadingClearText>
                <FilterHeadingClearBox>
                  <TouchableOpacity onPress={() => this.props.onBack()}>
                    <FilterHeaderText><Entypo name="chevron-left" size={18} color="#000" /> Back</FilterHeaderText>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={this.clearAllFilter}>
                    <FilterHeaderText>Clear all</FilterHeaderText>
                  </TouchableOpacity>
                </FilterHeadingClearBox>
              </FilterHeadingClearText>
            ) : null}

            <FilterHeading>
              <FilterHeadingBox>
                <SearchText>Search Filter</SearchText>
                <TouchableOpacity onPress={() => this.props.onBack()}>
                  <FilterHeaderText style={{ paddingRight: layoutType == "tab" ? 18 : 0 }}>
                    <Text style={{ color: "#E71E82" }}>&times;</Text> Close
                  </FilterHeaderText>
                </TouchableOpacity>
              </FilterHeadingBox>
            </FilterHeading>
            <DualOptionalView>


              <FilterInnerView>

                <Form
                  property_for={this.props.propertyFor}
                  searchObject={this.props.searchObject}
                  updateData={(field, value) => this.updateData(field, value)}
                  locError={this.state.locError}
                />


              </FilterInnerView>
              <FilterInnerView>
                {/* <CustomDropdown
                  dropdownValue={this.radius} //object of data to render in dropdown
                  setCurrentDropdown={this.setCurrentDropdown} //function call to set the Dropdown list for current DD
                  valueSelected={this.state.customDropdown.radius} //selected value
                  title="Radius" //Label text
                  dropdownName="radius" //name of the property we are maintaining in state
                  textright="miles"
                  isOpen={this.state.customDropdown.isOpen} //list flag
                /> */}
                {/* <CustomDropdown
                  dropdownValue={this.dateListed} //object of data to render in dropdown
                  setCurrentDropdown={this.setCurrentDropdown} //function call to set the Dropdown list for current DD
                  valueSelected={this.state.customDropdown.dateListed} //selected value
                  title="Date listed" //Label text
                  dropdownName="dateListed" //name of the property we are maintaining in state
                  isOpen={this.state.customDropdown.isOpen} //list flag
                /> */}
                <FormContainer>
                  <TitleWrapper>Radius</TitleWrapper>
                  <DropDownWrapper style={{ zIndex: 100 }}>
                    <DropDownSectionWrapper style={{ flex: 1, width: "100%" }}>
                      <DropDownPicker
                       id={"ADVANCE_SEARCHR_RADIUS"}
                        items={this.radius}
                        onChangeItem={(value) =>
                          this.setState({ selectedRadius: value.value, defaultRadius: value.value })
                        }
                        placeholder="Select"
                        containerStyle={{ height: 40, marginTop: 8 }}
                        style={{
                          borderWidth: 1,
                          borderColor: "lightgray",
                        }}
                        defaultValue={this.state?.defaultRadius}
                        zIndex={10012}
                        dropDownStyle={{ backgroundColor: "white" }}
                      />
                    </DropDownSectionWrapper>
                  </DropDownWrapper>

                  {/*

                  <TitleWrapper style={{ marginTop: 12 }}>Date listed</TitleWrapper>
                  <DropDownWrapper style={{ zIndex: 90 }}>
                    <DropDownSectionWrapper>
                      <DropDownPicker
                        items={this.dateListed}
                        placeholder="Select"
                        onChangeItem={(value) =>
                          this.setState({ dateListed: value.value, defaultDate: value.value })
                        }
                        defaultValue={this.state?.defaultDate}
                        containerStyle={{ height: 40, marginTop: 8 }}
                        style={{
                          borderWidth: 1,
                          borderColor: "lightgray",
                        }}
                        dropDownStyle={{ backgroundColor: "white" }}
                        zIndex={1001}
                      />
                    </DropDownSectionWrapper>
                  </DropDownWrapper>

                  */}
                  <BubbleMultiSelectInput
                    title="Keyword"
                    id={"KEYWORDS"}
                    onValueChange={(val) => {
                      this.setState({ keyword: val, defaultKeyword: val });
                    }}
                    selectedValues={this.state?.defaultKeyword ? this.state.defaultKeyword : []}
                    prefilledValue={this.state?.defaultKeyword ? this.state.defaultKeyword : []}
                  />
                  <BoxSelectorWrapper>
                    <BoxSelector
                      boxList={this.props.propertyFor ? this.boxCategoriesRent
                        : this.boxCategoriesSale}
                      label="Hide from search"
                      boxStyle={{ width: layoutType == "web" ? 220 : 140 }}

                    ></BoxSelector>
                  </BoxSelectorWrapper>
                  <ButtonWrapper>
                  {layoutType=="phone"?<ButtonView> 
                       <WideButton
                        onPress={() => this.props.onBack()}
                        childText="Close"
                        whiteButton
                        backgroundColor="white"
                        width="100%"
                      />
                    </ButtonView>:null}
                    {layoutType=="phone"?<View style={{width:3}}/>:null}
                    <ButtonView>
                      <WideButton
                        onPress={() => this.filterApply()}
                        childText="Apply Filter"
                        width="100%"
                      />
                     </ButtonView>
                   
                  </ButtonWrapper>
                </FormContainer>
              </FilterInnerView>
            </DualOptionalView>
            {this.state.customDropdown.isOpen ? (
              <FlatListDropdown
                data={this.state.customDropdown.currentDropdownList}
                style={{
                  top:
                    this.state.customDropdown.position.y +
                    this.state.customDropdown.position.height,
                  left:
                    this.state.customDropdown.position.x - layoutType == "phone"
                      ? 0
                      : layoutType == "web"
                        ? 75
                        : 23,
                  width: this.state.customDropdown.position.width,
                }}
                renderItem={({ item }) => (
                  <TouchableWithoutFeedback
                    key={item.key}
                    onPress={() => {
                      this.actionOnRow(item.value);
                    }}
                  >
                    <View
                      style={{
                        backgroundColor:
                          this.state.customDropdown[
                            this.state.customDropdown.currentDropdown
                          ] == item.value
                            ? "#faebd7"
                            : "#fff",
                      }}
                    >
                      <FlatListOption>{item.value}</FlatListOption>
                    </View>
                  </TouchableWithoutFeedback>
                )}
              />
            ) : null}
          </FilterContent>
        </FilterView>
      </ScrollView>
    );
  }
}
