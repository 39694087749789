import React, { FC, useState, ReactElement } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  ViewStyle,
  TextStyle,
  ScrollView,
} from "react-native";

const search = (keyName, activeStep) => {
 if(activeStep >= keyName){
  return true
 }
  return false;
};

const Stepper = (props) => {
  const {
    active,
    content,
    onBack,
    title,
    onNext,
    onFinish,
    wrapperStyle,
    stepStyle,
    stepTextStyle,
    buttonStyle,
    buttonTextStyle,
    showButton = true,
  } = props;
  const [step, setStep] = useState([0]);
  const pushData = (val) => {
    setStep((prev) => [...prev, val]);
  };

  const removeData = () => {
    setStep((prev) => {
      prev.pop();
      return prev;
    });
  };
  return (
    <View style={{width:"100%"}}>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
  
        }}
      >
        {content.map((_, i) => {
          return (
            <React.Fragment key={i}>
              {i !== 0 && (
                <View
                  style={{
                    flex: 1,
                    height: 1,
                    backgroundColor: "grey",
                    opacity: 1,
                    marginHorizontal:1,
                  }}
                />
              )}
              <View
                style={[
                  {
                    backgroundColor: search(i, active) ? "#FC4850" : "#8B8B8B",
                    width: 10,
                    height: 10,
                    borderRadius: 5,
                    justifyContent: "center",
                    alignItems: "center",
                    opacity: search(i, active) ? 1 : 0.3,
                  },
                  stepStyle,
                ]}
              >
              </View>
             
           
            </React.Fragment>
          );
        })}
        
      </View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
  
        }}
      >
      {title.map((item, i) => {
          return (
            <React.Fragment key={i}>
              {i !== 0 && (
                <View
                  style={{
                    flex: 1,
                    height: 1,
                    opacity: 1,
                  }}
                />
              )}
             <Text style={{marginTop:5,color:search(i, active)? "#FC4850" : "#8B8B8B",fontWeight:search(i, active)? "bold" : "400"}}>{item}</Text>
             
            </React.Fragment>
          );
        })}
      </View>  
      <ScrollView 
      showsVerticalScrollIndicator={false}>
        {content[active]}
      </ScrollView>
      {showButton && (
        <View
          style={{
            flexDirection: "row",
            width: "100%",
            justifyContent: "flex-end",
            marginTop:20
          }}
        >
          {active !== 0 && (
            <TouchableOpacity
              style={[
                {
                  padding: 10,
                  height: 42,
                  width: 98,
                  borderRadius: 40,
                  marginRight: 10,
                  backgroundColor: "white",
                  borderWidth:1,
                  borderColor:"#FC4850",
                  justifyContent:"center",
                  alignItems:"center"
                },
              ]}
              onPress={() => {
                removeData();
                onBack();
              }}
            >
              <Text style={[{ color: "black" }, buttonTextStyle]}>Back</Text>
            </TouchableOpacity>
          )}
          {content.length - 1 !== active && (
            <TouchableOpacity
              style={[
                {
                    padding: 10,
                  height: 42,
                  width: 98,
                  borderRadius: 40,
                  marginRight: 10,
                  backgroundColor: "white",
                  borderWidth:1,
                  borderColor:"#00B401",
                  justifyContent:"center",
                  alignItems:"center"
                },
                buttonStyle,
              ]}
              onPress={() => {
                pushData(active + 1);
                onNext();
              }}
            >
              <Text style={[{ color: "black" }, buttonTextStyle]}>Next</Text>
            </TouchableOpacity>
          )}
          {content.length - 1 === active && (
            <TouchableOpacity
              style={[
                {
                    padding: 10,
                    height: 42,
                    width: 98,
                    borderRadius: 40,
                    marginRight: 10,
                    backgroundColor: "white",
                    borderWidth:1,
                    borderColor:"#00B401",
                    justifyContent:"center",
                    alignItems:"center"
                }
              ]}
              onPress={() => onFinish()}
            >
              <Text style={[{ color: "black" }, buttonTextStyle]}>Finish</Text>
            </TouchableOpacity>
          )}
        </View>
      )}
    </View>
  );
};

export default Stepper;
