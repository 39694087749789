import React, { useEffect } from "react";
import { Platform, Text, View } from "react-native";
import moment from "moment";
import Modal  from "react-native-modal";
import { useDispatch, useSelector } from "react-redux";
import DatePickerComponent from "../../commonComponents/datepicker/DatePickerComponent";
import getPlatformType from "../../helpers/getPlatform";
import CustomTextInput from "../../commonComponents/customTextInput/customTextInput";
import { Button } from "../openFlow/atoms/button";
import { RTR_URL } from "../../helpers/connectionHelpers";
import { tenantActions } from "../openFlow/openFlowRedux/tenant.actions";
import { BoxShadow, Line, TextWrapper, Title2, TitleBlack, Wrapper } from "./styles";
import { getProfile } from "../../redux/actions/auth";
import { COLORS } from "../../constants/colorPallete";
import { showToaster } from "../../redux/actions/toast";
import Portal from "../../../openbrix-design-system/ui/atoms/ODSModal/potral";

const layoutType = getPlatformType();

const RightToRent = (props) => {
  const dispatch = useDispatch();
  const { showDocsOnSingleSection } = props;
  const data = useSelector((state) => state.authReducer?.profile?.data?.appUser);
  const [showModal, setShowModal] = React.useState(false);
  const [rtr_code, setRtr_code] = React.useState("");
  const [rtr_dob, setRtr_dob] = React.useState("");

  const [teanants, setUser] = React.useState(null);

  useEffect(() => {
    if (data) {
      setUser(data);
    }
  }, [data]);
  console.log("teanantsteanants", teanants);
  const openLinkRTR = async (id) => {
    let u = RTR_URL;

    if (Platform.OS === "web") {
      window.open(
        u,
        "_blank", // <- This is what makes it open in a new window.
      );
    } else {
      Linking.openURL(u)
      .catch((err) => console.error("An error occurred", err));
    }
  };

  const getRtrCode = () => {
    if (teanants) {
      if (teanants.right_to_rent) {
        return teanants.right_to_rent;
      } else {
        return "N/A";
      }
    } else {
      return "N/A";
    }
  };
  const getRtrCodeDob = () => {
    if (teanants) {
      if (teanants.right_to_rent_dob) {
        return moment(teanants.right_to_rent_dob)
        .format("DD-MM-YYYY");
      } else {
        return "N/A";
      }
    } else {
      return "N/A";
    }
  };
  const renderRtr = () => {
    return (
      <>
        <TextWrapper style={{ width: "100%" }}>
          <TitleBlack color={"#000000"} top={"10px"}>
            Date of Birth
          </TitleBlack>
          <View style={{ alignItems: "center" }}>
            <Title2
              id={"RTR_DOB_LABEL"}
              color={COLORS.LGREEN} size={"15px"}>
              {getRtrCodeDob()}
            </Title2>
            <Line/>
          </View>
        </TextWrapper>
        <TextWrapper style={{
          width: "100%",
          marginTop: 30,
        }}>
          <TitleBlack color={"#000000"} top={"10px"}>
            Rtr code
          </TitleBlack>
          <View style={{ alignItems: "center" }}>
            <Title2
              id={"RTR_RTR_LABEL"}
              color={COLORS.LGREEN} size={"15px"}>
              {getRtrCode()}
            </Title2>
            <Line/>
          </View>
        </TextWrapper>
        <Button
          color={COLORS.LGREEN}
          style={{
            width: "100%",
            marginTop: 43,
          }}
          onClick={() => setShowModal(true)}
          type={"stripe"}
          title={"Add Code"}
          id={"RTR_ADD_CODE"}
        />

        <Button
          style={{
            width: "100%",
            marginTop: 30,
          }}
          width={"100%"}
          isLGREEN
          onClick={() => openLinkRTR()}
          type={"solid"}
          title={"Get started"}
          id={"RTR_GET_STARTED"}
        />
      </>
    );
  };
  return (
    <>
      <Wrapper>
        <BoxShadow bottom={"10px"} right={"19px"}>
          <View style={{
            width: "100%",
            alignItems: "flex-end",
          }}>
            <Button
             id={"RENT_TO_REC_START_BUTTON"} isLGREEN onClick={() => openLinkRTR()} type={"solid"}
                    title={"Start"}/>
          </View>
          <Title2 color={COLORS.LGREEN} top={"10px"}>
            Right-to-Rent
          </Title2>
          <TitleBlack color={"#000000"} top={"10px"}>
            You will need to verify your Right to Rent. If you hold a UK or Irish passport, (or have
            EUSS settled status or have been granted indefinite leave to remain) you should provide
            a copy via the app, otherwise you will need to provide a government share code
          </TitleBlack>
          <TitleBlack color={"#000000"} top={"10px"}>
            Please click "start" to begin the process.
          </TitleBlack>
          <TitleBlack color={"#000000"} top={"10px"}>
            Once you complete the process, you can then add your government issued share code, which
            will be shared with your letting agent.
          </TitleBlack>
          {showDocsOnSingleSection ? <View style={{ marginTop: 30 }}>{renderRtr()}</View> : null}
        </BoxShadow>
        {showDocsOnSingleSection ? null : <BoxShadow>{renderRtr()}</BoxShadow>}
      </Wrapper>
<Portal>
      <Modal
        isVisible={showModal}
        transparent={true}
        coverScreen={false}
        hasBackdrop={true}
        backdropOpacity={0}
        onBackdropPress={() => setShowModal(false)}
        style={{
          margin: 0,
          width: "100%",
          flex: 1,
          backgroundColor: "#00000080",
          paddingVertical: layoutType == "phone" ? "5%" : "1%",
          paddingHorizontal: layoutType == "phone" ? "8%" : "3%",
        }}
      >
        <View
          style={{
            width: layoutType == "phone" ? "365px" : "600px",
            backgroundColor: "#FAFAFA",
            borderRadius: 20,
            overflow: "hidden",
            alignSelf: "center",
            padding: layoutType == "phone" ? "5%" : "2%",
          }}
        >
          <Text style={{
            fontSize: 40,
            fontWeight: "bold",
            color: COLORS.LGREEN,
          }}>
            Right to Rent
          </Text>
          <Text style={{
            fontSize: 18,
            marginTop: 30,
          }}>
            Have you completed your right to rent process via gov.uk site?
          </Text>
          <Text style={{
            fontSize: 18,
            marginTop: 30,
          }}>
            If you have not, please close this pop up and click "start" on the right to rent
            section.
          </Text>
          <Text style={{
            fontSize: 18,
            marginTop: 30,
            marginBottom: 40,
          }}>
            If you have completed the process and have you share code please add it below.
          </Text>
          <CustomTextInput
            textSize={18}
            width={"100%"}
            id={"rtr-code"}
            title="Enter Share code below"
            onChangeText={(value) => setRtr_code(value)}
            name="rtr_code"
            prefilledValue={rtr_code}
            bColor={COLORS.LGREEN}
          />

          <DatePickerComponent
            key="datepicker"
            id={"rtr-date"}
            placeholder={"Enter Date of Birth"}
            inputLabel={{
              fontSize: 16,
              color: "#000",
              fontWeight: "bold",
            }}
            value={rtr_dob}
            disabled={status == "review"}
            is18yrs={true}
            onUpdateInput={(value) => {
              setRtr_dob(value);
            }}
          />
          <View
            style={{
              width: "100%",
              marginTop: 30,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              id={"rtr-save"}
              onClick={() => {
                if (!rtr_dob) {
                  return dispatch(showToaster("error", "Please fill in the date of birth"));
                }
                if (!rtr_code) {
                  return dispatch(showToaster("error", "Please fill in the right to rent code"));
                }
                dispatch(
                  tenantActions.postRtrCode(
                    {
                      right_to_rent_code: rtr_code,
                      right_to_rent_dob: rtr_dob,
                    },
                    (data) => {
                      setShowModal(false);
                      setRtr_code("");
                      setRtr_dob("");
                    },
                    getProfile,
                  ),
                );
              }
              }
              type={"solid"}
              isLGREEN
              title={"Save"}
            />
            <Button
              id={"rtr-close"}
              onClick={() => {
                setShowModal(false);
                setRtr_code("");
                setRtr_dob("");
              }}
              type={"stripe"}
              color={COLORS.LGREEN}
              title={"Close"}
              style={{ marginTop: 20 }}
            />
          </View>
        </View>
      </Modal>
      </Portal>
    </>
  );
};

export default RightToRent;
