import React, { useState } from "react";
import {
    View,
    Text,
    SafeAreaView,
    Image,
    TouchableOpacity,
    Platform,
    ScrollView,
    Button,
} from "react-native";
import Hyperlink from 'react-native-hyperlink'

import {
    Container,
    SectionWrapper,
    DualView,
    TextBox,
    SectionTitle,
    SectionText,
    ImageBox,
    TeamsSection,
    MemberBoxWrapper,
    MemberBox,
    MemberImage,
    MemberName,
    MemberNameText,
    MemberDetails,
    MemberDetailsText,
    MemberHeader,
    TeamHeader
} from "./styles";

import getPlatformType from "../../helpers/getPlatform";
const layoutType = getPlatformType();

import Header from "../../commonComponents/headerComponent/headerComponent";
import Footer from "../../commonComponents/footerComponent/customFooter";
import TripleTabs from "../../commonComponents/tripleTabs/tripleTabs";

const TabInvestor = (props) => {
    const linkStyle = { color: '#2980b9', fontSize: 16 }
    const openLink = (url) => {
        window.open(url);
    }
    return (
        <SectionWrapper>
            <DualView
                style={{
                    flexDirection: layoutType !== 'phone' ? 'row-reverse' : 'column'
                }}>
                <ImageBox

                >
                    <Image
                        source={require("../../assets/images/Investors.jpg")}
                        style={{ width: "100%", height: "100%", resizeMode: "contain" }}
                    ></Image>
                </ImageBox>
                <TextBox>
                    <SectionTitle>Investor Relations</SectionTitle>
                    <SectionText>
                        Openbrix Ltd is rapidly progressing from a development company primarily funded by its principle founders, to an operating company funded by private investors with a vast experience in the sector.          </SectionText>
                    <SectionText>
                        OpenBrix have hired the expertise of The Shoreditch Partnership to help with fundraising. This is done by way of ordinary equity additional funding propelling it to the next stage of its growth, creating revenues, income and true value for the business, its shareholders and customers within its eco-system platform.          </SectionText>
                    <Hyperlink linkStyle={linkStyle} onPress={(url) => openLink(url)}>
                        <SectionText>
                            For further infomation please contact: paul.finnigan@theshoreditchpartnership.co.uk                    </SectionText>
                    </Hyperlink>
                </TextBox>

            </DualView>
        </SectionWrapper>
    );
};


export default TabInvestor;
