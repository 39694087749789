import React from "react";
import styled from "styled-components/native";
import getPlatformType from "../../helpers/getPlatform";

const layoutType = getPlatformType();
let Container, ContentWrapper, Content, Wrapper, SpacingView,CardContainer,RowContainer,CardBig;

if (layoutType == "phone") {
  Container = styled.View``;
  SpacingView = styled.View``;
  ContentWrapper = styled.View`
    margin: 26px 16px;
  `;
  RowContainer = styled.View`
   width:100%;
   justify-content:center;
   align-items:center;
   position:absolute;
   top:170px;
  `;
  CardContainer=styled.View`
width: 348px;
background: #FFFFFF;
padding:15px;
box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.15);
border-radius: 8px;
margin:10px;
  `;
  CardBig=styled.View`
width: 348px;
margin:10px;
padding:15px;
background: #FFFFFF;
box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.15);
border-radius: 8px;
  `;
  Content = styled.View`
    box-shadow: rgba(128, 128, 128, 0.4) 0px 0px 7px;
    background-color: white;
    border-bottom-left-radius: 20px;
    border-top-right-radius: 20px;
  `;
  Wrapper = styled.View``;
} else if (layoutType == "web") {
  Container = styled.View`
    justify-content: center;
    flex-direction: row;
    align-self: center;
    align-items: center;
  `;
  RowContainer = styled.View`
   width:100%;
   flex-direction:row;
   justify-content:center;
   position:absolute;
   top:130px;
  `;
  CardContainer=styled.View`
  width: 646px;
  background: #ffffff;
  box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding:25px;
  margin:15px;
    `;
    CardBig=styled.View`
  width: 548px;
  background: #ffffff;
  box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding:25px;
  margin:15px;
    `;
  ContentWrapper = styled.View`
    width: 444px;
    margin-top: 64px;
  `;
  Content = styled.View`
    box-shadow: rgba(128, 128, 128, 0.4) 0px 0px 7px;
    background-color: white;
    border-bottom-left-radius: 20px;
    border-top-right-radius: 20px;
  `;
  Wrapper = styled.View`
    flex-direction: row;
  `;
  SpacingView = styled.View`
    margin-right: 274.8px;
  `;
} else {
  Container = styled.View`
    justify-content: center;
    flex-direction: row;
    align-self: center;
    align-items: center;
  `;
  RowContainer = styled.View`
   width:100%;
   flex-direction:row;
   justify-content:center;
   position:absolute;
   top:130px;
  `;
  CardContainer=styled.View`
  width: 300px;
  background: #ffffff;
  box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding:25px;
  margin:15px;
    `;
    CardBig=styled.View`
  width: 448px;
  background: #ffffff;
  box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding:25px;
  margin:15px;
    `;
  ContentWrapper = styled.View`
    width: 343px;
    margin-top: 68px;
  `;
  Content = styled.View`
    box-shadow: rgba(128, 128, 128, 0.4) 0px 0px 7px;
    background-color: white;
    border-bottom-left-radius: 20px;
    border-top-right-radius: 20px;
  `;
  Wrapper = styled.View`
    flex-direction: row;
  `;
  SpacingView = styled.View`
    margin-right: 70px;
  `;
}

const LeftPanal = styled.View``;
const BackTextWrapper = styled.Text`
  height: 17px;
  color: #000000;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
`;
export {
  Container,
  LeftPanal,
  BackTextWrapper,
  ContentWrapper,
  Content,
  Wrapper,
  SpacingView,
  CardContainer,
  RowContainer,
  CardBig
};
