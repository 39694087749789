import styled from 'styled-components/native';
import getPlatformType from '../../helpers/getPlatform';
import { generalColorsTheme } from './../../constants/colorPallete';

const layoutType = getPlatformType();

let Container,
  BoxShadow,
  BoxContainer,
  DownloadButtonWrapper,
  Heading,
  TabsWrapper,
  TabHeader,
  TabBody,
  SubHeading,
  RowContainer,
  Section,
  ButtonWrapper,
  FormText,
  ActivateButtonWrapper,
  CertificateWrapper,
  LineSaperator,
  AmountBox,
  CurrencyBox,
  InputBox,
  BlueBox,
  Typography,
  Avtar,
  primaryColor;
primaryColor = '#638ACC';

TabsWrapper = styled.View`
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  margin-top: 23px;
  margin-bottom: 27px;
`;

Avtar = styled.View`
  width: 190px;
  height: 190px;
  border-radius: 206;
  background: #d9d9d9;
`;
BlueBox = styled.View`
  width: 100%;
  padding: 15px;
  margin-top: ${(props) => (props.top ? props.top : '51px')};
  flex-direction: row;
  justify-content: ${(props) => (props.justify ? props.justify : 'space-between')};
  align-items: center;
  background: #638acc;
  border-radius: 9px;
`;
AmountBox = styled.View`
  height: ${(props) => (props.height ? props.height : '54px')};
  border-radius: 11px;
  flex-direction: row;
`;
CurrencyBox = styled.View`
  flex: 0.3;
  height: 90%;
  background: ${(props) => (props.color ? props.color : 'rgba(196, 245, 216, 0.55)')};
  border: 0.5px solid #638acc;
  border-radius: 11px 11px 11px 11px;
  justify-content: center;
  align-items: center;
`;
InputBox = styled.View`
  flex: 0.7;
  height: 90%;
  background: #ffffff;
  border: 0.5px solid #638acc;
  border-radius: 11px 11px 11px 11px;
  justify-content: center;
  align-items: center;
`;
LineSaperator = styled.View`
  width: 100%;
  background: ${(props) => (props.color ? props.color : '#194592')};
  height: 1px;
`;
BoxShadow = styled.View`
  background: #ffffff;
  box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  padding: ${(props) => (props.padding ? props.padding : '40px')};
  width: 100%;
  margin-top: ${(props) => (props.top ? props.top : '0px')};
  margin-bottom: ${(props) => (props.bottom ? props.bottom : '0px')};
`;
BoxContainer = styled.View`
  background: #ffffff;
  box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  padding: 26px;
`;
TabHeader = styled.TouchableOpacity`
  flex: 0.5;
  justify-content: center;
  align-items: ${(props) => (props.align ? props.align : 'center')};
  border-right-width: ${(props) => (props.hideBoderLeft ? '0px' : '1px')};
  border-right-color: ${() => generalColorsTheme.active};
  margin-left: ${(props) => (props.left ? props.left : '0px')};
`;
TabBody = styled.View`
  width: 100%;
  flex-direction: ${(props) => (props.direction ? 'column' : 'row')};
`;
DownloadButtonWrapper = styled.TouchableOpacity`
  width: ${layoutType=="phone"?'250px':'362px'};
  height: ${layoutType=="phone"?'50px':'67px'};
  background: #c4f5d8;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
  border-radius: 37px;
  justify-content: center;
  margin-top: 27px;
`;
Typography = styled.Text`
  font-family: 'Lato';
  font-style: normal;
  font-weight: ${(props) => (props.bold ? props.bold : 800)};
  font-size: ${(props) =>
    props.size
      ? layoutType == 'phone'
        ? parseInt(props.size) - 5 + 'px'
        : layoutType == 'tab'
        ? parseInt(props.size) - 1 + 'px'
        : parseInt(props.size) - 1 + 'px'
      : '19px'};
  text-align: ${(props) => (props.align ? props.align : 'center')};
  color: ${(props) => (props.color ? props.color : '#FAFAFA')};
  margin-top: ${(props) => (props.top ? props.top : '0px')};
  margin-bottom: ${(props) => (props.bottom ? props.bottom : '0px')};
  text-decoration-line:${(props) => (props.underline ? 'underline':"none")}; ;
`;
if (layoutType == 'web') {
  Container = styled.View`
    position: absolute;
    top: 0;
    align-items: center;
    left: 0;
    right: 0;
    width:  ${(props) => (props.width ? props.width : '736px')}; 
    margin: auto;
    margin-top: 150px;
  `;
  Heading = styled.Text`
    font-family: 'Lato';
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    margin-top: 30px;
    margin-bottom: 30px;
  `;
  SubHeading = styled.Text`
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    margin-top: 16;
    width: 622px;
  `;
  RowContainer = styled.View`
    flex-direction: row;
  `;
  Section = styled.View`
    width: 736px;
    margin: 8px;
    margin-top: 61px;
    background: #FFFFFF;
    justify-content:'center'
    box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    padding:24px;
  `;
  ActivateButtonWrapper = styled.TouchableOpacity`
    margin-top: 20px;
    flex-direction: row;
    width: 446px;
    align-self: center;
    padding:10px;
    border-radius: 25px;
    background: #a4d4a2;
    justify-content: center;
    align-items: center;
  `;
  ButtonWrapper = styled.TouchableOpacity`
    margin-top: ${(props) => (props.top ? props.top : '20px')};
    flex-direction: row;
    width: ${(props) => (props.width ? props.width : '446px')};
    align-self: ${(props) => (props.align ? props.align : 'center')};
padding:10px;
    border-radius: ${(props) => (props.radius ? props.radius : '25px')};
    border: 2px solid ${(props) => (props.bcolor ? props.bcolor : '#638ACC')};
    background: ${(props) => (props.color ? props.color : '#638ACC')};
    justify-content: center;
    align-items: center;
  `;
  FormText = styled.Text`
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 23px;
    margin-top: 30px;
    margin-bottom: 30px;
    color: #000000;
    text-align: center;
    align-self: center;
    line-height: 37px;
    width: 400px;
  `;
} else if (layoutType == 'phone') {
  Container = styled.View`
    position: absolute;
    top: 0;
    align-self: center;
    width: 94%;
    margin: 180px 0px;
  `;
  Heading = styled.Text`
    width: 350px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
    line-height: 35px;
    color: #ffffff;
    margin-top: 100px;
    text-align: center;
  `;
  SubHeading = styled.Text`
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    margin-top: 16;
    width: 347px;
  `;
  RowContainer = styled.View`
    flex-direction: row;
  `;
  Section = styled.View`
    width: 349px;
    margin: 8px;
    margin-top: 61px;
    background: #FFFFFF;
    justify-content:'center'
    box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    padding:24px;
  `;
  ActivateButtonWrapper = styled.TouchableOpacity`
    margin-top: 20px;
    flex-direction: row;
    width: 307px;
    align-self: center;
    height: 55px;
    border-radius: 25px;
    background: #a4d4a2;
    justify-content: center;
    align-items: center;
  `;
  ButtonWrapper = styled.TouchableOpacity`
    margin-top: ${(props) => (props.top ? props.top : '20px')};
    flex-direction: row;
    width: ${(props) => (props.width ? props.width : '100%')};
    align-self: ${(props) => (props.align ? props.align : 'center')};
    padding:10px;
    border-radius: ${(props) => (props.radius ? props.radius : '25px')};
    border: 2px solid ${(props) => (props.bcolor ? props.bcolor : '#638ACC')};
    background: ${(props) => (props.color ? props.color : '#638ACC')};
    justify-content: center;
    align-items: center;
  `;
  FormText = styled.Text`
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 23px;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #000000;
    text-align: center;
    align-self: center;
    line-height: 37px;
    width: 316px;
  `;
} else {
  Container = styled.View`
    position: absolute;
    top: 0;
    align-self: center;
    width: 80%;
    margin: 150px 0px;
  `;
  Heading = styled.Text`
    width: 350px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
    line-height: 35px;
    color: #ffffff;
    margin-top: 62px;
    text-align: center;
  `;
  SubHeading = styled.Text`
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    margin-top: 16;
    width: 606px;
  `;
  RowContainer = styled.View`
    flex-direction: row;
  `;
  Section = styled.View`
width: 546px;
margin: 8px;
margin-top: 61px;
background: #FFFFFF;
justify-content:'center'
box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.15);
border-radius: 8px;
padding:24px;
`;
  ActivateButtonWrapper = styled.TouchableOpacity`
    margin-top: 20px;
    flex-direction: row;
    width: 276px;
    align-self: center;
    height: 52px;
    border-radius: 25px;
    background: #a4d4a2;
    justify-content: center;
    align-items: center;
  `;

  ButtonWrapper = styled.TouchableOpacity`
    margin-top: 20px;
    flex-direction: row;
    width: 276px;
    align-self: center;
    border-radius: 25px;
    align-self: ${(props) => (props.align ? props.align : 'center')};
    padding:10px;
    border-radius: ${(props) => (props.radius ? props.radius : '25px')};
    border: 2px solid ${(props) => (props.bcolor ? props.bcolor : '#638ACC')};
    background: ${(props) => (props.color ? props.color : '#638ACC')};
    justify-content: center;
    align-items: center;
  `;
  FormText = styled.Text`
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 23px;
    margin-top: 10;
    margin-bottom: 20px;
    color: #000000;
    text-align: center;
    align-self: center;
    line-height: 37px;
    width: 316px;
  `;
}
export {
  Container,
  SubHeading,
  RowContainer,
  Section,
  Heading,
  ButtonWrapper,
  ActivateButtonWrapper,
  FormText,
  TabsWrapper,
  DownloadButtonWrapper,
  Typography,
  TabHeader,
  TabBody,
  BoxContainer,
  BoxShadow,
  LineSaperator,
  AmountBox,
  CurrencyBox,
  InputBox,
  BlueBox,
  primaryColor,
  Avtar,
};
