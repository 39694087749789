import _ from 'lodash';
import moment from 'moment';
export const filterMaxArray = (array, key) => {
  return array.filter((val) => parseInt(val.value) >= parseInt(key) || parseInt(val.value) == 0);
};

export const createArray = (type) => {
  if (type == 'childrenCount') {
    return ['Please Select', ..._.range(1, 11)];
  } else if (type == 'childrenAge') {
    return ['Please Select', ..._.range(1, 18)];
  } else return ['Please Select', ..._.range(1, 6)];
};
export const timeAgo = (time) => {

  if (time) {
    switch (typeof time) {
      case 'number':
        break;
      case 'string':
        time = +new Date(time);
        break;
      case 'object':
        if (time.constructor === Date) time = time.getTime();
        break;
      default:
        time = +new Date();
    }
    var time_formats = [
      [60, 'seconds', 1],
      [120, '1 minute ago', '1 minute from now'],
      [3600, 'minutes', 60],
      [7200, '1 hour ago', '1 hour from now'],
      [86400, 'hours', 3600],
      [172800, 'Yesterday', 'Tomorrow'],
      [604800, 'days', 86400],
      [1209600, 'Last week', 'Next week'],
      [2419200, 'weeks', 604800],
      [4838400, 'Last month', 'Next month'],
      [29030400, 'months', 2419200],
      [58060800, 'Last year', 'Next year'],
      [2903040000, 'years', 29030400],
      [5806080000, 'Last century', 'Next century'],
      [58060800000, 'centuries', 2903040000]
    ];
    var seconds = (+new Date() - time) / 1000,
      token = 'ago',
      list_choice = 1;

    if (seconds == 0) {
      return 'Just now'
    }
    if (seconds < 0) {
      seconds = Math.abs(seconds);
      token = 'from now';
      list_choice = 2;
    }
    var i = 0,
      format;
    while (format = time_formats[i++])
      if (seconds < format[0]) {
        if (typeof format[2] == 'string')
          return format[list_choice];
        else
          return Math.floor(seconds / format[2]) + ' ' + format[1] + ' ' + token;
      }
    return time;
  } else {
    return ''
  }
}
export const formatDate = (date) => {
  if (date) {
    return moment(date).format('DD/MM/YYYY');
  } else {
    return ``;
  }
};
export const teanantInfo = {
  marrital_status: [
    'Please select one',
    'Married',
    'Cohabiting',
    'Civil Partnership',
    'Single',
    'Widowed',
    'Divorced',
  ],
};
