import React, { useState, useEffect } from "react";
import Header from "../../commonComponents/headerComponent/headerComponent";
import Footer from "../../commonComponents/footerComponent/customFooter";
import {
  View,
  Text,
  Dimensions,
  SafeAreaView,
  Image,
  TouchableOpacity,
  Platform,
  ScrollView,
  FlatList,
  TextInput,
  StyleSheet,
} from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { navigateAction, popAction } from "../../helpers/navigationHelpers";

import { Entypo } from "@expo/vector-icons";
import getPlatformType from "../../helpers/getPlatform";
import { useSelector, useDispatch } from "react-redux";
const layoutType = getPlatformType();
import { MainSection, Section1, Section2 } from "./preReferencingStyle";
import CardComponent from "./Components/cardComponent";
import { ContainerScrollView } from "../../helpers/appStyles";
import SkeltonComponents from "./Components/skeltonComponents";
import Stepper from "./Components/Stepper";
import Afford from "./afford";
import Ccq from "./ccq";
import Complete from "./complete";
import Loader from "./../../commonComponents/activityIndicator/activityIndicator";

import { getCcqStatus } from "./../../redux/actions/preReferencing";

const PreReferencingLite = (props) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state?.preReferencing?.isLoading);
  const ccqData = useSelector((state) => state?.preReferencing?.ccqData);
  const globalState = useSelector((state) => state?.preReferencing);
  
  const [Affordability, setAffordablity] = useState("");
  const [active, setActive] = useState(0);

  const content = [<Afford />, <Ccq />, <Complete />];

  useEffect(() => {
    dispatch(getCcqStatus(true));
  }, []);


  useEffect(() => {
if(ccqData?.autoNavigation){
 if (ccqData?.completion?.ccj_completed || ccqData?.completion?.skip_ccj) {
    setActive(2);
  }   
  else if (ccqData?.completion?.affordability_completed) {
    setActive(1);
  }}
  
    
  }, [
    ccqData?.completion?.affordability_completed,
    ccqData?.completion?.ccj_completed,
  ]);

  return (
    <View style={{ backgroundColor: "#FAFAFA" }}>
      <Loader loading={isLoading} />
      {/* <Header
        showLogoMob
        showLogoWeb
        showLogoTab
        rightChildLabelTab
        rightChildLabelWeb
        rightChildLabelMob
        showDrawerMob
        showDrawerTab
        showNotification
        {...props}
      /> */}
      <ContainerScrollView>
        <MainSection>
          <Stepper
            active={active}
            title={["Afford","CCJ","Complete"]}
            content={content}
            stepTextStyle={{ backgroundColor: "#8B8B8B" }}
            onBack={() => setActive((p) => p - 1)}
            onFinish={() => navigateAction(props.navigation,"PreReferencing")}
            onNext={() =>{
              if(active==1){
                dispatch(getCcqStatus(false));
              }
              setActive((p) => p + 1)}}
          />
        </MainSection>

        {/* {layoutType === "web" && <Footer {...props} />} */}
      </ContainerScrollView>
    </View>
  );
};
const styles = StyleSheet.create({});
export default PreReferencingLite;
