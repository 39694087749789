import React,{useState} from 'react';
import { Switch } from 'react-native';
import { View,Text } from 'react-native-web';
 const Settings=(props)=>{
    const [state,setState]=useState({
        property_alert:props?.property_alert,
        maintenance_alert:props?.maintenance_alert,
     rental_alert:props?.rental_alert,
    marketplace_alert:props?.marketplace_alert,
    openflow_alert:props?.openflow_alert
    });
   const _onSwitch=(val)=>{
        props.onChange(val)
    }

    return(
        <View style={{ width: "100%" }}>
        <View
          style={{
            width: "100%",
            padding: 20,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View
            style={{
              width: "50%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text style={{ fontWeight: "Bold", fontSize: 16, color: "black" }}>
              Property Alert
            </Text>
          </View>
          <View
            style={{
              width: "50%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Switch
              trackColor={{ false: "#767577", true: "#81b0ff" }}
              thumbColor={state.property_alert ? "#f5dd4b" : "#f4f3f4"}
              style={{
                backgroundColor: state.property_alert
                  ? "tranparent"
                  : "#cdcdcd",
                borderRadius: 30,
              }}
              onValueChange={async (e) =>{
                setState({...state,property_alert:e})
                _onSwitch({
                    property_alert:state.e,
                    maintenance_alert:state.maintenance_alert,
                    rental_alert:state.rental_alert,
                    marketplace_alert:state.marketplace_alert,
                    openflow_alert:state.openflow_alert
                });
              }
              }
              value={state.property_alert}
            />
          </View>
        </View>
        <View
          style={{
            width: "100%",
            padding: 20,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View
            style={{
              width: "50%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text style={{ fontWeight: "Bold", fontSize: 16, color: "black" }}>
              Marketplace
            </Text>
          </View>
          <View
            style={{
              width: "50%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Switch
              trackColor={{ false: "#767577", true: "#81b0ff" }}
              thumbColor={state.marketplace_alert ? "#f5dd4b" : "#f4f3f4"}
              style={{
                backgroundColor: state.marketplace_alert
                  ? "tranparent"
                  : "#cdcdcd",
                borderRadius: 30,
              }}
              onValueChange={(e) =>{
                setState({...state,marketplace_alert:e})
                _onSwitch({
                    property_alert:state.property_alert,
                    maintenance_alert:state.maintenance_alert,
                    rental_alert:state.rental_alert,
                    marketplace_alert:e,
                    openflow_alert:state.openflow_alert
                });
              }
              }
              value={state.marketplace_alert}
            />
          </View>
        </View>
        <View
          style={{
            width: "100%",
            padding: 20,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View
            style={{
              width: "50%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text style={{ fontWeight: "Bold", fontSize: 16, color: "black" }}>
              Rental Transaction
            </Text>
          </View>
          <View
            style={{
              width: "50%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Switch
              trackColor={{ false: "#767577", true: "#81b0ff" }}
              thumbColor={state.rental_alert ? "#f5dd4b" : "#f4f3f4"}
              style={{
                backgroundColor: state.rental_alert
                  ? "tranparent"
                  : "#cdcdcd",
                borderRadius: 30,
              }}
              onValueChange={(e) =>{
                setState({...state,rental_alert:e})   
                _onSwitch({
                    property_alert:state.property_alert,
                    maintenance_alert:state.maintenance_alert,
                    rental_alert:e,
                    marketplace_alert:state.marketplace_alert,
                    openflow_alert:state.openflow_alert
                });
              }
              }
              value={state.rental_alert}
            />
          </View>
        </View>
        <View
          style={{
            width: "100%",
            padding: 20,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View
            style={{
              width: "50%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text style={{ fontWeight: "Bold", fontSize: 16, color: "black" }}>
              Maintenance
            </Text>
          </View>
          <View
            style={{
              width: "50%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Switch
              trackColor={{ false: "#767577", true: "#81b0ff" }}
              thumbColor={state.maintenance_alert ? "#f5dd4b" : "#f4f3f4"}
              style={{
                backgroundColor: state.maintenance_alert
                  ? "tranparent"
                  : "#cdcdcd",
                borderRadius: 30,
              }}
              onValueChange={(e) =>{
                setState({...state,maintenance_alert:e})   
                _onSwitch({
                    property_alert:state.property_alert,
                    maintenance_alert:e,
                    rental_alert:state.rental_alert,
                    marketplace_alert:state.marketplace_alert,
                    openflow_alert:state.openflow_alert
                });
              }

              }
              value={state.maintenance_alert}
            />
          </View>
        </View>
        <View
          style={{
            width: "100%",
            padding: 20,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View
            style={{
              width: "50%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text style={{ fontWeight: "Bold", fontSize: 16, color: "black" }}>
              OpenFlow
            </Text>
          </View>
          <View
            style={{
              width: "50%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Switch
              trackColor={{ false: "#767577", true: "#81b0ff" }}
              thumbColor={true ? "#f5dd4b" : "#f4f3f4"}
              style={{
                backgroundColor: state.openflow_alert
                  ? "tranparent"
                  : "#cdcdcd",
                borderRadius: 30,
              }}
              onValueChange={(e) =>{
                setState({...state,openflow_alert:e})   
                  _onSwitch({
                    property_alert:state.property_alert,
                    maintenance_alert:state.maintenance_alert,
                    rental_alert:state.rental_alert,
                    marketplace_alert:state.marketplace_alert,
                    openflow_alert:e
                });
              }
              }
              value={state.openflow_alert}
            />
          </View>
        </View>
      </View>
    )
};
export default Settings;