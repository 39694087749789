import React from "react";
import { View } from "react-native";
import { Svg, Circle, Text as SVGText } from "react-native-svg";
import getScreenType from "../../../utils/getScreenType";
import { useTheme } from "@emotion/react";
const layoutType = getScreenType();
const ODSKnob = (props) => {
  const { size, strokeWidth, text } = props;
  const pointerRadius = props.pointerRadius ? props.pointerRadius : 5
  const radius = (size - strokeWidth) / 2 - pointerRadius;
  const circum = radius * 2 * Math.PI;
  const svgProgress = 100 - props.progressPercent;
  const angle = props.progressPercent * 360 / 100;
  const pointerX = (size / 2) + (radius * Math.sin(angle * Math.PI / 180));
  const pointerY = (size / 2) - (radius * Math.cos(angle * Math.PI / 180));
  const theme = useTheme();

  return (
    <View style={{ margin: 10, borderWidth: 0, backgroundColor: theme.colors.elements.color, borderRadius: 100, }}>
      <Svg width={size} height={size}>
        {/* Background Circle */}
        <Circle
          stroke={props.bgColor ? props.bgColor : theme.colors.elements.invert}
          fill="none"
          cx={size / 2}
          cy={size / 2}
          r={radius}
          {...{ strokeWidth }}
        />
        <Circle
          stroke={props.pgColor ? props.pgColor : theme.colors.elements['color-light']}
          fill="none"
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeDasharray={`${circum} ${circum}`}
          strokeDashoffset={radius * Math.PI * 2 * (svgProgress / 100)}
          strokeLinecap="round"
          transform={`rotate(-90, ${size / 2}, ${size / 2})`}
          {...{ strokeWidth }}
        />
        <SVGText
          id="percentage"
          fontSize={layoutType == "phone" ? 14 : 14}
          // fontFamily= 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif'
          x={size / 2}
          y={size / 1.8}
          textAnchor="middle"
          fill={props.textColor ? props.textColor : theme.colors.elements.invert}
          fontWeight={"bold"}
        >
          {props.progressPercent + "%"}
        </SVGText>


      </Svg>
    </View>
  );
};

export default ODSKnob;
{/* 
  example...
  <Knob
size={100}
strokeWidth={10}
progressPercent={50}
text={"Complete"}
textColor={variables.primary}
pgColor={variables.primary}
></Knob> */}