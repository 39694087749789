import React, { useState } from "react";
import {
  View,
  Text,
  SafeAreaView,
  Image,
  TouchableOpacity,
  Platform,
  ScrollView,
  Button,
} from "react-native";
import {
  Container,
  SectionWrapper,
  DualView,
  TextBox,
  SectionTitle,
  SectionText,
  ImageBox,
  TeamsSection,
  CenterSection,
  Section,
  MemberBoxWrapper,
  MemberBox,
  MemberImage,
  MemberName,
  MemberNameText,
  Title,
  Description,
  MemberDetailsText,
  MemberHeader,
  TeamHeader,
  ContentContainer,
  TopSection,
  LogoImage,
  CenterImage,
  DesktopImage,
} from "./styles";

import getPlatformType from "../../helpers/getPlatform";
const layoutType = getPlatformType();

import Header from "../../commonComponents/headerComponent/headerComponent";
import Footer from "../../commonComponents/footerComponent/customFooter";
import TripleTabs from "../../commonComponents/tripleTabs/tripleTabs";
import { COLORS } from "../../constants/colorPallete";
import TlyfeIntroCard from "../../commonComponents/tlyfeIntro/tlyfeIntroCard";
import { useNavigation } from "@react-navigation/native";
import GradientText from "../../commonComponents/gradientText";
const mlsDescr=`The UK MLS has been designed by UK Agents for UK Agents. Quintessentially British. 
We give the power and control to the Primary Agent to engage with other agents on their own terms but equally provide the security on those termsagreed to the “Claiming Agent” by utilising immutable blockchain technology.
`;
const moreDesc=`Share properties with other UK Estate Agents and International Agents. Collaborate together,work for buyers and share commissions. 

Agents have the control to:
- Select which of their properties they wish to share.
- Select which Agent/s they wish to engage with.
- How much of their commission they will share
- Select the marketing right of the “claiming” Agent
- Set-up Multiple Networks or share to the whole MLS.
`;
const desktop=require('../../assets/images/aboutus/mls.png');

const   Mls = (props) => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const navigation =useNavigation();
  const content=(title,description)=>{
    return(
      <ContentContainer style={{width:'100%'}}>
      <Title style={{marginTop:30}}>
        {title}
      </Title>
      <Description style={{marginTop:15}}>
        {description}
      </Description>
      </ContentContainer>
    )
  }

  return (
    <SectionWrapper>
      <TopSection style={{justifyContent:'flex-start',alignItems:'flex-start'}}>
      {/* {layoutType=="phone"?<View style={{justifyContent:'center',alignItems:'center',width:'100%'}}>
      <GradientText extraWidth={40} color1={"#FBB1E6"} style={{marginTop:10,marginLeft:15}} color2={"#FFB580"} childText={"mls"} fontSize={35}/>
      </View>: <GradientText extraWidth={40} color1={"#FBB1E6"} style={{marginTop:10,marginLeft:0}} color2={"#FFB580"} childText={"mls"} fontSize={35}/>
} */}
   <Image source={require('../../assets/SVG/mls.svg')} style={{width:60,marginTop:30,marginBottom:20,alignSelf:layoutType=='phone'?'center':'flex-start',height:30,resizeMode:'contain'}}/>
      
      
      </TopSection>
      <View style={{width:layoutType=="phone"?'80%':'100%',marginLeft:layoutType=='phone'?'10%':0}}>
      <SectionText style={{fontFamily:"Lato-Bold",textAlign:layoutType=="phone"?'center':'left'}}>The UK MLS has been designed by UK Agents for UK Agents. Quintessentially British! 
       </SectionText>
      <SectionText style={{fontFamily:"Lato",marginTop:20,textAlign:layoutType=="phone"?'center':'left'}}>{`We give the power and control to the Primary Agent to engage with other agents on their own terms but equally provide the security on those terms agreed to the “Claiming Agent” by utilising immutable blockchain technology.
      
Share properties with other UK Estate Agents and International Real Estate Agents. Collaborate together, work for buyers and share commissions. 
`}
</SectionText>
      </View>
     

<View style={{flexDirection:layoutType=='phone'?'column':'row',width:'100%'}}>

  <View style={{width:layoutType=='phone'?'100%':'50%'}}>
  <SectionText style={{ textAlign: 'left', fontFamily: "Lato-Bold", marginTop: 60 ,width:layoutType=="phone"?"80%": "100%",marginLeft:layoutType=="phone"?"10%":0}}>Agents have the control to:</SectionText>
          {["Select which of their properties they wish to share","Select which Agent/s they wish to engage with","Choose how much of their commission they wish to share","Select the marketing right for the “claiming” Agent","Set-up multiple bespoke networks or share to the whole MLS"].map((e) => <View style={{ flexDirection: 'row',width:layoutType=="phone"?"80%": "100%",marginLeft:layoutType=="phone"?"10%":0, marginTop: 25, alignItems: 'center' }}>
            <Image source={require('../../assets/images/aboutus/checkbox.png')} style={{ height: 18, width: 18, resizeMode: 'contain' }} />
            <Description style={{ marginLeft: 10, fontFamily: "Lato", textAlign: 'justify' }}>
              {e}
            </Description>
          </View>)}
    
  </View>
  <View style={{width:layoutType=='phone'?'100%':'50%',justifyContent:'center',alignItems:'center'}}>
  <DesktopImage style={{width:'60%',marginTop:30}} source={desktop}  />
  </View>


</View>
      
     
      <CenterSection style={{marginTop:20,justifyContent:'space-around',flexWrap:'wrap',marginBottom:30,width:layoutType=="phone"?'80%':'100%',marginLeft:layoutType=="phone"?'10%':0}}>
        <Section>
          <SectionText style={{width:'100%',textAlign:'left',fontFamily:'Lato-Bold',marginBottom:0}}>Share Properties</SectionText>
        <SectionText style={{textAlign:'left',fontFamily:'Lato'}}>{`The MLS works like an “agent-only” portal, giving Agents control to share property with whom they wish, securely and under their agreed terms.`}</SectionText>
        </Section>
        <Section>
        <SectionText style={{textAlign:'left',width:'100%',fontFamily:'Lato-Bold',marginBottom:0}}>Increase Stock</SectionText>
        <SectionText style={{textAlign:'left',fontFamily:'Lato'}}>{`Increase your stock – Increase your sales – offer the MLS to vendors and pitch for a higher fee – work for buyers and receive a fee`}</SectionText>
        </Section>
        <Section>
        <SectionText style={{fontFamily:'Lato-Bold',width:'100%',marginBottom:0,textAlign:'left'}}>Better Service</SectionText>
        <SectionText style={{textAlign:'left',fontFamily:'Lato'}}>{`Offer your vendors and buyers an enhanced service and a more collaborative approach and increase your revenue.`}</SectionText>
        </Section>
      </CenterSection>
    </SectionWrapper>
  );
};

export default Mls;
