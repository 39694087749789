import React, { useState } from 'react';
import {
    Dimensions, StyleSheet, View,
    Text,
    TouchableOpacity
} from 'react-native';
import BackButton from '../../commonComponents/BackButton';
import { COLORS } from '../../constants/colorPallete';
import getPlatformType from '../../helpers/getPlatform';
import { ChildrenView, TabContainer } from './styles';
const layoutType = getPlatformType();
import { ImageBackground } from 'react-native';
const WIN_HEIGHT = Dimensions.get("window").height;
import IdBack from '../../assets/images/idBackground.png'
import preBack from '../../assets/images/preBackground.png'
import rentBack from '../../assets/images/rentBackground.png'
import RightToRent from './rightToRent';
import IdVerification from './idverification';
import PreQualification from './preQualification';
import BackGroundGenerator from '../LandingPages/common/BackGroundGenerator';

const LandingPage = (props) => {

    const [selectedIndex, setSelectedIndex] = useState(0);
    const TabView = () => {
        return (
            <TabContainer>
                <TouchableOpacity 
                id={"ID_Verification"}
                onPress={() => setSelectedIndex(0)} style={{ flex: 1 / 3, justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ fontSize: layoutType == "phone" ? 14 : 18, color: 'white', fontFamily: selectedIndex == 0 ? 'Lato-Bold' : 'Lato' }}>ID Verification</Text>
                    {selectedIndex == 0 && <View style={{ width: layoutType == "phone" ? '85%' : '30%', height: 2, backgroundColor: 'white', marginTop: 8 }} />}
                </TouchableOpacity>
                <TouchableOpacity 
                  id={"Pre_Qualification"}
                onPress={() => setSelectedIndex(1)} style={{ flex: 1 / 3, justifyContent: 'center', alignItems: 'center', borderLeftWidth: 1, borderRightWidth: 1, borderColor: layoutType == "phone" ? 'white' : 'white' }}>
                    <Text style={{ fontSize: layoutType == "phone" ? 14 : 18, color: 'white', fontFamily: selectedIndex == 1 ? 'Lato-Bold' : 'Lato' }}>Rent Ready</Text>
                    {selectedIndex == 1 && <View style={{ width: layoutType == "phone" ? '85%' : '30%', height: 2, backgroundColor: 'white', marginTop: 8 }} />}
                </TouchableOpacity>
                <TouchableOpacity
                 id={"Right-to-Rent"}
                onPress={() => setSelectedIndex(2)} style={{ flex: 1 / 3, justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ fontSize: layoutType == "phone" ? 14 : 18, color: 'white', fontFamily: selectedIndex == 2 ? 'Lato-Bold' : 'Lato' }}>Right-to-Rent</Text>
                    {selectedIndex == 2 && <View style={{ width: layoutType == "phone" ? '85%' : '30%', height: 2, backgroundColor: 'white', marginTop: 8 }} />}
                </TouchableOpacity>
            </TabContainer>
        )
    }

    return (
<BackGroundGenerator
 props={props}
 isTLYF
>
                <View style={{ minHeight: WIN_HEIGHT - 300 }}>
                    <TabView />
                    <View style={{ width: '100%' }}>
                        <ImageBackground source={selectedIndex==0?IdBack:selectedIndex==1?preBack:selectedIndex==2?rentBack:rentBack} style={{ width: '100%', height: 300 }} resizeMode={'cover'}>
                            <BackButton navigation={props.navigation} replace={'Dashboard'} />
                        </ImageBackground>
                        <ChildrenView>
                           {selectedIndex==0  &&  <IdVerification />}
                            {selectedIndex==1  &&  <PreQualification/>}
                            {selectedIndex==2  &&  <RightToRent/>}
                        </ChildrenView>
                    </View>

                </View>
                </BackGroundGenerator>
               
    );
};
const styles = StyleSheet.create({});
export default LandingPage;
