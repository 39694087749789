import React from "react";
import styled from "styled-components/native";
import getScreenType from "../../../../openbrix-design-system/utils/getScreenType";

const layoutType = getScreenType();
let Container;
if (layoutType == "phone") {
Container = styled.View`
width: 100%;
height:74px;
position:absolute;
top:0;
zIndex:1000;
flex-direction:row;
justify-content:space-between;
align-items:center;
padding-horizontal:24px;
`;
} else if (layoutType == "web") {

Container = styled.View`
width: 100%;
height:82px;
position:absolute;
top:0;
zIndex:1000;
flex-direction:row;
justify-content:space-between;
align-items:center;
padding-horizontal:100px;
`;

} else {
Container = styled.View`
width: 100%;
height:74px;
position:absolute;
top:0;
zIndex:1000;
flex-direction:row;
justify-content:space-between;
align-items:center;
padding-horizontal:40px;
`;
  
  
}

export {
 Container
};
