import React from "react";
import styled from "styled-components/native";
import getPlatformType from "../../helpers/getPlatform";

const layoutType = getPlatformType();
let Container,
  SectionWrapper,
  TeamHeader,
  MemberHeader,
  DualView,
  TextBox,
  SectionTitle,
  SectionText,
  ImageBox,
  TeamsSection,
  MemberBoxWrapper,
  MemberBox,
  MemberImage,
  MemberName,
  MemberNameText,
  MemberDetails,
  MemberDetailsText,
  MainContent;

Container = styled.View`
  width: 1130px;
  margin: 0 auto;
`;

SectionWrapper = styled.View`
  width: 100%;
  padding: 30px;
`;
MemberHeader = styled.View`
  text-align: center;
  padding: 25px;
`;
TeamHeader = styled.Text`
  color: #000000;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 27px;
  text-align: center;
`;

DualView = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
TextBox = styled.View`
  height: 415px;
  width: 526px;
  padding-top: 40px;
`;
SectionTitle = styled.Text`
  color: #000000;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
`;
SectionText = styled.Text`
  color: #000000;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 26px;
  margin-top: 20px;
`;
ImageBox = styled.View`
  height: 415px;
  width: 526px;
`;
TeamsSection = styled.View`
  background-color: #fff;
  box-shadow: rgba(128, 128, 128, 0.9) 0px 1px 4.68px;
  margin-top: 20px;
`;
MemberBoxWrapper = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  padding-top: 0px;
`;
MemberBox = styled.View`
  width: 250px;
  text-align: center;
  align-items: center;
`;
MemberImage = styled.View`
  height: 202px;
  width: 200px;
`;
MemberName = styled.View`
  padding: 10px 0px;
`;
MemberNameText = styled.Text`
  color: #e71e82;
  font-size: 18px;
  font-weight: 600;
`;
MemberDetails = styled.View``;
MemberDetailsText = styled.Text`
  color: #000000;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
`;

if (layoutType == "phone") {
  Container = styled.View`
    width: 342px;
    margin: 0 auto;
  `;

  TeamHeader = styled.Text`
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;
  `;
  TextBox = styled.View`
    width: 312px;
    padding-top: 0px;
  `;
  ImageBox = styled.View`
    height: 240px;
    width: 312px;
  `;
  SectionWrapper = styled.View`
    width: 100%;
    padding: 10px;
  `;
  DualView = styled.View`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  `;
  MemberBox = styled.View`
    width: 150px;
    text-align: center;
    align-items: center;
    margin-bottom: 20px;
  `;
  MemberBoxWrapper = styled.View`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px;
    flex-wrap: wrap;
  `;
  MemberImage = styled.View`
    height: 140px;
    width: 140px;
  `;
  MemberDetailsText = styled.Text`
    color: #000000;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 22px;
    text-align: center;
  `;
  MainContent = styled.View`
    min-height: 70vh;
  `;
} else if (layoutType == "web") {
  MainContent = styled.View`
    min-height: 70vh;
  `;
} else {
  TextBox = styled.View`
    height: 429px;
    width: 430px;
    padding-top: 40px;
  `;
  ImageBox = styled.View`
    height: 429px;
    width: 430px;
  `;
  SectionTitle = styled.Text`
    color: #000000;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
  `;
  SectionText = styled.Text`
    color: #000000;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 26px;
    margin-top: 20px;
  `;
  Container = styled.View`
    width: 944px;
    margin: 0 auto;
  `;
  TeamHeader = styled.Text`
    color: #000000;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
  `;
  MainContent = styled.View`
    min-height: 80vh;
  `;
}

export {
  Container,
  SectionWrapper,
  TeamHeader,
  MemberHeader,
  DualView,
  TextBox,
  SectionTitle,
  SectionText,
  ImageBox,
  TeamsSection,
  MemberBoxWrapper,
  MemberBox,
  MemberImage,
  MemberName,
  MemberNameText,
  MemberDetails,
  MemberDetailsText,
  MainContent,
};
