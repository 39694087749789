import { types } from "../../actionTypes";

const mediaHubReducer = (
    state = {
        loading: false,
        post:null
    },
    action
) => {
    switch (action.type) {
        case types.GET_ARTICLES: {
            return {
                ...state,
                loading: true,
            };
        }
        case types.GET_ARTICLES_SUCCESS: {
            return {
                ...state,
                loading: false,
            };
        }
        case types.GET_ARTICLES_FAILED: {
            return {
                ...state,
                loading: false,
            };
        }
        case types.GET_VIDEOS: {
            return {
                ...state,
                loading: true,
            };
        }
        case types.GET_VIDEOS_SUCCESS: {
            return {
                ...state,
                loading: false,
            };
        }
        case types.GET_VIDEOS_FAILED: {
            return {
                ...state,
                loading: false,
            };
        }
        case types.GET_POST_BY_ID: {
            return {
                ...state,
                loading: true,
            };
        }
        case types.GET_POST_BY_ID_SUCCESS: {
            return {
                ...state,
                loading: false,
                post:action?.payload
            };
        }
        case types.GET_POST_BY_ID_FAILED: {
            return {
                ...state,
                loading: false,
            };
        }
        default:
            return state;
    }
};

export default mediaHubReducer;
