import { Text, View } from 'react-native';
import React, { useState, useEffect } from 'react';
import { useDispatch  } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import Constant from './tdsRedux/Constant';
import { backgroundTdsArr } from './backgroundTdsArr';
import getPlatformType from '../../helpers/getPlatform';
import { navigateAction } from '../../helpers/navigationHelpers';
import { Container, ButtonWrapper, Typography, BoxShadow } from './style';
import BackGroundGenerator from '../LandingPages/common/BackGroundGenerator';
import CustomTextInput from '../../commonComponents/customTextInput/customTextInput';
import { types } from '../../redux/actionTypes';
import { useRoute } from '@react-navigation/native';
import { tdsActions } from './tdsRedux/tds.actions';

const createPassword = (props) => {

  const dispatch = useDispatch(); 
  const routes=useRoute();
  const layoutType = getPlatformType();
  const params=routes.params;
  const [type, setType] = useState('web');
  const [state,setState]=useState({
    password:'',
    rePassword:''
  });

  useEffect(() => {}, []);

  const resendActivationLink = () => {
    window.open('https://www.tenancydepositscheme.com/forgotten-password/')
  }
  
  const handleChange = (value, field) => {
    setState({ ...state, [field]: value });
  };
  const handleOnChange = (value) => {
    setState({
      ...state,
      recaptchaValue: true,
      captchaToken:value
    });
  };

  const validatePassword=()=>{
    let regex=/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
    if(regex.test(state?.password)){
        if(state?.rePassword==state?.password){
          if(state.captchaToken){
            if(params){
              let payload={
                  activation_code:params?.activation_code.trim(),
                  email:params?.email.trim(),
                  password:state?.password,
                  password_confirm:state?.rePassword,
                  captchaToken:state?.captchaToken
              }
              dispatch(tdsActions.resetPassword(payload,props?.navigation))
          }
          else{
              dispatch({
                  type: types.SHOW_ALERT,
                  alertType: "error",
                  text: "Session Expired",
                });
          }
          }else{
            dispatch({
              type: types.SHOW_ALERT,
              alertType: "error",
              text: "Please validate recaptcha first!",
            });
          }
           
        }
        else{
            dispatch({
                type: types.SHOW_ALERT,
                alertType: "error",
                text: "Password not matched",
              });
        }
    }
    else{
        dispatch({
            type: types.SHOW_ALERT,
            alertType: "error",
            text: "Invalid password",
          });
    }
  }

  const renderBoxSection = () => {
    return (
      <View
        style={{
          width:layoutType!="web"?'100%':'70%',
          marginTop: 50,
          marginBottom: 50,
        }}
      >
        <BoxShadow  bottom={'30px'} style={layoutType == 'phone' ?{}:{ marginRight: 8 ,flex:0.5}}>
          <Typography top={'20px'} color={'#0000002'} size={'23px'} bold={700} bottom={40}>
          Create Password
          </Typography>
          <Typography align={'flex-start'} top={'30px'} color={'#454545'} size={'20px'} bold={400} text>
          Your password must contain at least:          </Typography>
          <Typography align={'flex-start'} top={'30px'} color={'#454545'} size={'20px'} bold={400} bottom={40}>
• one capital letter {'\n'}
• one lower case letter {'\n'}
• one number{'\n'}
• one special character from {`@$!%*?&£|^~#+=-`}{'\n'}
• a total of 8 characters or more{'\n\n\n'}

N.B - Your password cannot contain any spaces or a full stop (.)
              </Typography>
              <Typography top={'20px'} color={'#0000002'} size={'23px'} bottom={40} align={'flex-start'} bold={700}>
          Please enter your password
          </Typography>
          <CustomTextInput
            id="password"
            width={'100%'}
            placeHolder="Enter Password"
            type="password"
            name="password"
            // prefilledValue={state?.password}
            bColor={'#638ACC'}
            password
            renderRightComponent
            onChangeText={(value) => handleChange(value, 'password')}
          />
           <CustomTextInput
            id="rePassword"
            width={'100%'}
            placeHolder="Re-enter Password"
            type="password"
            name="rePassword"
            password
            renderRightComponent
            // prefilledValue={state?.rePassword}
            bColor={'#638ACC'}
            onChangeText={(value) => handleChange(value, 'rePassword')}
          />
        <View
              style={{
                paddingTop: "2%",

              }}
            >
              <ReCAPTCHA
                id={"register-recaptcha"}
                sitekey={Constant.RECAPTCHA_KEY}
                onChange={handleOnChange}
              />
            </View>
          <ButtonWrapper
            width={'50%'}
            style={{ marginTop: 30 }}
            onPress={() =>validatePassword() }
          >
            <Typography color={'#FFFFFF'} size={'19px'} bold={700}>
            SUBMIT
            </Typography>
          </ButtonWrapper>
        </BoxShadow>
                </View>
    );
  };

  return (
    <>
      <BackGroundGenerator
        infoIcon
        isTds
        isBack={true}
        props={props}
        layoutType={(data) => {
          setType(data);
        }}
        isTLYF
        backgroundArr={backgroundTdsArr}
        backNavigation={()=> navigateAction(props?.navigation,'Custodial Activation') }
      >
        <Container
        width={"1080px"}
        >
          {renderBoxSection()}
        
        </Container>
      </BackGroundGenerator>
    </>
  );
};

export default createPassword;
