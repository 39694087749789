import React, { useState } from 'react';
import {
  Pressable,
  Text,
  StyleSheet,
  TouchableWithoutFeedback,
  TouchableOpacity,
  View,
  ImageBackground,
  Image,
} from 'react-native';


import getScreenType from '../../../utils/getScreenType';
import ODSBadges from '../../atoms/ODSBadges';
import { ODSText } from '../../atoms/ODSText';
import ODSButton from '../../atoms/ODSButton';
import { getTokens } from '../../../design-tokens/color-tokens';

const PropertyBox = ({
  subtitle,
  title,
  status,
  disabled,
  children,
  restStyle,
  rIcon,
  lIcon,
  themeColor,
  source,
  btnTheme,
  onPress,
  isRight,
  onEmailPress,
  uri,
  name,
  address,
  agentName,
  onCallPress,
}) => {
  const themeObj = getTokens('light').colors;
  // console.log('themeObj', themeObj);
  const [isPressed, setIsPressed] = useState(false);

  return (
    <View style={[styles(themeObj).containerStyle, restStyle]}>
      <ODSText textAlign={'center'} marginTop={6} color="black" type={'h5'}>
        My Tenancy
      </ODSText>
      <View style={{ width: '100%', flexDirection: 'row', marginTop: 20 }}>
        <View style={{ marginRight: 16 }}>
          <Image
            source={
              uri
                ? {
                  uri: uri,
                }
                : require('./../../../images/Bitmap.png')
            }
            resizeMode="cover"
            style={{ width: 85, height: 67, borderRadius: 10 }}
          />
        </View>
        <View style={{ justifyContent: 'center', flex: 1 }}>
          <ODSText marginTop={6} color="black" type={'button'}>
            {name}
          </ODSText>
          <ODSText marginTop={6} color="black" type={'sm'}>
            {address}
          </ODSText>
        </View>
      </View>
      <View
        style={{
          width: '100%',
          height: 1,
          backgroundColor: themeObj.neutral[300],
          marginVertical: 24,
        }}
      />
      <ODSText marginTop={6} color="black" type={'sm'}>
        Agent: {agentName ? agentName : '-'}
      </ODSText>

      <View style={{ marginTop: 20, flexDirection: 'row', justifyContent: 'space-between' }}>
        <View style={{ flex: 0.45 }}>
          <ODSButton
            rIcon={'TBPhone'}
            restStyle={{ width: '100%', height: 50 }}
            type="secondary"
            disabled={false}
            themeColor={btnTheme}
            onPress={onCallPress}>
            Call
          </ODSButton>
        </View>
        <View style={{ flex: 0.45 }}>
          <ODSButton
            rIcon={'email'}
            restStyle={{ width: '100%', height: 50 }}
            type="secondary"
            disabled={false}
            themeColor={btnTheme}
            onPress={onEmailPress}>
            Email
          </ODSButton>
        </View>
      </View>
    </View>
  );
};

const styles = (theme: object) =>
  StyleSheet.create({
    containerStyle: {
      width: '100%',
      borderRadius: 12,
      shadowColor: '#101828',
      shadowOffset: { width: 0, height: 1 },
      shadowOpacity: 0.1,
      shadowRadius: 2,
      elevation: 2,
      backgroundColor: theme.neutral['white'],
      padding: 20,
      borderWidth: 1,
      borderColor: '#D0D6DD',
    },
  });

export default PropertyBox;
