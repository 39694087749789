import React, { useState } from "react";
import {
  View,
  Text,
  SafeAreaView,
  Image,
  TouchableOpacity,
  Platform,
  ScrollView,
  Button,
} from "react-native";
import {
  Container,
  SectionWrapper,
  DualView,
  TextBox,
  SectionTitle,
  SectionText,
  ImageBox,
  TeamsSection,
  MemberBoxWrapper,
  MemberBox,
  MemberImage,
  MemberName,
  MemberNameText,
  MemberDetails,
  MemberDetailsText,
  MemberHeader,
  TeamHeader,
  MainContent,
} from "./styles";

import getPlatformType from "../../helpers/getPlatform";
const layoutType = getPlatformType();

import Header from "../../commonComponents/headerComponent/headerComponent";
import Footer from "../../commonComponents/footerComponent/customFooter";
import TripleTabs from "../../commonComponents/tripleTabs/tripleTabs";

import TabAgentPartnership from "./TabAgentPartnership";
import TabCareers from "./TabCareers";
import TabInvestor from "./TabInvestor";

const ServiceScreen = (props) => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const tab1 = (
    <SectionWrapper>
      <DualView
        style={{
          flexDirection: layoutType !== "phone" ? "row-reverse" : "column",
        }}
      >
        <ImageBox>
          <Image
            source={{
              uri:
                "https://www.jagranimages.com/images/newimg/09122019/09_12_2019-house_pexels_19829618.jpg",
            }}
            style={{ width: "100%", height: "100%", resizeMode: "contain" }}
          ></Image>
        </ImageBox>
        <TextBox>
          <SectionTitle>Section title</SectionTitle>
          <SectionText>
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem
            accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
            quae ab illo inventore veritatis et quasi architecto beatae vitae
            dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
            aspernatur aut odit aut fugit, sed quia consequuntur magni dolores
            eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est,
            qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit.
          </SectionText>
          <SectionText>
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem
            accusantium doloremque laudantium, totam rem aperiam, eaque.
          </SectionText>
        </TextBox>
      </DualView>
      <DualView>
        <ImageBox>
          <Image
            source={{
              uri:
                "https://www.jagranimages.com/images/newimg/09122019/09_12_2019-house_pexels_19829618.jpg",
            }}
            style={{ width: "100%", height: "100%", resizeMode: "contain" }}
          ></Image>
        </ImageBox>
        <TextBox>
          <SectionTitle>Section title</SectionTitle>
          <SectionText>
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem
            accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
            quae ab illo inventore veritatis et quasi architecto beatae vitae
            dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
            aspernatur aut odit aut fugit, sed quia consequuntur magni dolores
            eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est,
            qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit.
          </SectionText>
          <SectionText>
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem
            accusantium doloremque laudantium, totam rem aperiam, eaque.
          </SectionText>
        </TextBox>
      </DualView>
    </SectionWrapper>
  );
  const tabList = [
    {
      label: "Agent Partnership",
      target: <TabAgentPartnership />,
      targetfooter: null,
    },
    {
      label: "Careers",
      targetfooter: null,
      target: <TabCareers />,
    },
    {
      label: "Investors",
      target: <TabInvestor />,
      targetfooter: null,
    },
  ];
  const setCurrentTab = (index) => {
    
    setCurrentTabIndex(index);
  };

  return (
    <SafeAreaView style={{ backgroundColor: "#FAFAFA" }}>
      <ScrollView>
        <Header
          showLogoWeb
          showLogoTab
          rightChildLabelWeb
          showDrawerMob
          showDrawerTab
          pageTitleMob="Our Services"
          {...props}
        />
        <MainContent>
          <Container>
            <TripleTabs
              isFullWidth={false}
              tabList={tabList}
              title="Our Services"
              setCurrentTabIndex={setCurrentTab}
            />
          </Container>
          {currentTabIndex == 0 ? (


              <TeamsSection>
                {/*}
              <MemberHeader>
                <TeamHeader>Meet our team</TeamHeader>
              </MemberHeader>
              <Container>
                <MemberBoxWrapper>
                  <MemberBox>
                    <MemberImage
                      style={{
                        borderTopRightRadius: 100,
                        borderTopLeftRadius: 100,
                        borderBottomRightRadius: 100,
                        borderBottomLeftRadius: 100,
                      }}
                    >
                      <Image
                        source={require("../../assets/images/Adam.jpg")}
                        style={{
                          width: "100%",
                          height: "100%",
                          resizeMode: "cover",
                        }}
                      ></Image>
                    </MemberImage>
                    <MemberName>
                      <MemberNameText>Adam Pigott</MemberNameText>
                    </MemberName>
                    <MemberDetails>
                      <MemberDetailsText>
                        Co-Founder / Chief Commercial Officer
                      </MemberDetailsText>
                    </MemberDetails>
                  </MemberBox>
                  <MemberBox>
                    <MemberImage
                      style={{
                        borderTopRightRadius: 100,
                        borderTopLeftRadius: 100,
                        borderBottomRightRadius: 100,
                        borderBottomLeftRadius: 100,
                      }}
                    >
                      <Image
                        source={require("../../assets/images/Shahad.jpg")}
                        style={{
                          width: "100%",
                          height: "100%",
                          resizeMode: "cover",
                        }}
                      ></Image>
                    </MemberImage>
                    <MemberName>
                      <MemberNameText>Shahad Choudhury</MemberNameText>
                    </MemberName>
                    <MemberDetails>
                      <MemberDetailsText>
                        Founder / Chief Innovation Officer
                      </MemberDetailsText>
                    </MemberDetails>
                  </MemberBox>
                  <MemberBox>
                    <MemberImage
                      style={{
                        borderTopRightRadius: 100,
                        borderTopLeftRadius: 100,
                        borderBottomRightRadius: 100,
                        borderBottomLeftRadius: 100,
                      }}
                    >
                      <Image
                        source={require("../../assets/images/Jhon.png")}
                        style={{
                          width: "100%",
                          height: "100%",
                          resizeMode: "cover",
                        }}
                      ></Image>
                    </MemberImage>
                    <MemberName>
                      <MemberNameText>John Hards</MemberNameText>
                    </MemberName>
                    <MemberDetails>
                      <MemberDetailsText>
                        Senior Strategic Advisor and Chair of Governance
                      </MemberDetailsText>
                    </MemberDetails>
                  </MemberBox>
                  {layoutType == "phone" ? (
                    <MemberBox>
                      <MemberImage
                        style={{
                          borderTopRightRadius: 100,
                          borderTopLeftRadius: 100,
                          borderBottomRightRadius: 100,
                          borderBottomLeftRadius: 100,
                        }}
                      >
                        <Image
                          source={{
                            uri:
                              "https://www.jagranimages.com/images/newimg/09122019/09_12_2019-house_pexels_19829618.jpg",
                          }}
                          style={{
                            width: "100%",
                            height: "100%",
                            resizeMode: "cover",
                          }}
                        ></Image>
                      </MemberImage>
                      { <MemberName>
                        <MemberNameText>Adam Pigott</MemberNameText>
                      </MemberName>
                      <MemberDetails>
                        <MemberDetailsText>
                          beatae vitae dicta sunt explicabo. Nemo enim ipsam
                          voluptatem quia voluptas sit aspernatur aut odit aut
                          fugit, sed quia consequuntur magni dolores eos qui
                          ratione voluptatem sequi
                      </MemberDetailsText>
                      </MemberDetails> }
                    </MemberBox>
                  ) : null}
                </MemberBoxWrapper>
              </Container>

                */ }
            </TeamsSection>


          ) : null}
        </MainContent>
        <Footer {...props} />
      </ScrollView>
    </SafeAreaView>
  );
};

export default ServiceScreen;
