import moment from "moment";
import { COLORS, generalColorsTheme, OPEN_FLO_TEXT_THEME } from "../../../constants/colorPallete";
import variables from "../atoms/variables.json";

export const totalDaysLeft = (movinDate) => {
  //IF(MOVEINDATE) MOVEINDATE -CURR DATE
  //ELSE TENANCYSTARTDATE-CURR DATE
  // return ((Monthly_Rental_Amount * 12) / 52).toFixed(2);
};
export const getStatus = (code_type) => {
  switch (code_type) {
    case 0:
      return {
        Text: "Incomplete",
      };
    case 1:
      return {
        Text: "Complete",
      };
    case 4:
      return {
        Text: "In Progress",
      };
    case 5:
      return {
        Text: "Action Required",
      };
    case 6:
      return {
        Text: "No Action Required",
      };
    case 2:
      return {
        Text: "Action Required",
      };
    default:
      return {
        Text: "In Progress",
      };
  }
};
export const getHoldingDeposite = (Monthly_Rental_Amount) => {
  return ((Monthly_Rental_Amount * 12) / 52).toFixed(2);
};
export const GetTenancyDeposit = (Monthly_Rental_Amount,FREQUENCY) => {

  let rentalAmount =Monthly_Rental_Amount;
  

    if(FREQUENCY == 'week') {
        rentalAmount = (Monthly_Rental_Amount * 52) /12
    } else if(FREQUENCY == 'quarterly') {
        rentalAmount = (Monthly_Rental_Amount * 4) /12
    } else if(FREQUENCY == '6 monthly') {
        rentalAmount = (Monthly_Rental_Amount * 2) /12        
    } else if(FREQUENCY == 'annually') {
        rentalAmount = Monthly_Rental_Amount/12
    }
    
  return (((rentalAmount * 12) / 52)*5).toFixed(2);
};
export const TotalAmountPayable = (initialrental, Despoit, holdingdeposit) => {
  return initialrental + Despoit - holdingdeposit;
};
export const GetFilename = (url) => {
  if (url) {
    return url
      ? url.split("/").pop().split("#").shift().split("?").shift()
      : null;
  }
  return "";
};
export const getDisplayDate = (date) => {
  if (date) {
    return moment(date).format("DD-MM-YYYY");
  } else {
    return moment().format("DD-MM-YYYY");
  }
};
export const getHoldingDepositStatusData = (code_type) => {
  switch (code_type) {
    case 0:
      return {
        Text: "Not Initiated",
        color: OPEN_FLO_TEXT_THEME.notInitiated,
        textColor: OPEN_FLO_TEXT_THEME.notInitiated,
        type:"stripe"
      };

      break;
    case 1:
      return {
        Text: "I've Paid",
        color: generalColorsTheme.yellow,
        textColor:  generalColorsTheme.white,
        type:"solid"

      };
      break;
    case 2:
      return {
        Text: "In Progress",
        color: generalColorsTheme.yellow,
        textColor: generalColorsTheme.white,
        type:"solid"
      };
      break;
    case 3:
      return {
        Text: "Confirmed",
        color:generalColorsTheme.green,
        textColor: generalColorsTheme.white,
        type:"solid"

      };
      break;
    default:
      return {
        Text: "Not Initiated",
        color: OPEN_FLO_TEXT_THEME.notInitiated,
        textColor: OPEN_FLO_TEXT_THEME.notInitiated,
        type:"stripe"

      };
      break;
  }
};

export const getIdStatusData = (code_type) => {
  switch (code_type) {
    case 0:
      return {
        Text: "Not Started",
        color: generalColorsTheme.red,
      };
    case 1:
      return {
        Text: "Pending",
        color: generalColorsTheme.yellow,
      };
    case 2:
      return {
        Text: "Completed",
        color: generalColorsTheme.green,
      };
    default:
      return {
        Text:"NA",
        color:generalColorsTheme.pink
      };
      break;  
  }
};

export const getRentalOfferStatus = (code_type) => {
  switch (code_type) {
    case 0:
      return {
        Text: "Accept",
        statusButtonText:"Not Accepted",
        statusButtonColor:variables.yellow,
        color: variables.puregreen,
        type:"stripe"
      };
    case 1:
      return {
        Text: "Accept Offer",
        statusButtonText:"Not Accepted",
        statusButtonColor:variables.yellow,
        color: variables.puregreen,
        type:"stripe"
      };
    case 2:
      return {
        Text: "Accepted",
        statusButtonText:"Accepted",
        statusButtonColor:variables.puregreen,
        color: variables.lightgrey,
        type:"solid"
      };
    default:
      return {
        Text: "Not Initiated",
        color: generalColorsTheme.pink,
        type:"stripe"
      };
      break;
  }
};
export const getMoveinOfferStatus = (code_type) => {
  switch (code_type) {
    case 0:
      return {
        Text: "Not Initiated",
        color: "#9F78FF",
      };

    case 1:
      return {
        Text: "I'VE PAID",
        color: "#D3760C",
      };
    case 2:
      return {
        Text: "In Progress",
        color: "#FC4850",
      };
      case 3:
        return {
          Text: "CONFIRMED",
          color: variables.puregreen,
        };
    default:
      return {
        Text: "Not Initiated",
        color: "#9F78FF",
      };
      break;
  }
};
export const getAppUserDetails = (arr, id,key) => {
  if(key){
    return arr.filter((data) => data[key] === id);

  }else
  return arr.filter((data) => data.app_user_id === id);
};


export const getDigitalVerificationStatus = (code_type, yoti_reason, yoti_redirect = undefined ) => {
  if (location.search.indexOf("yoti_redirect")!=-1){
    return {
      BtnText: "Pending",
      StatusText: "Pending",
      color: variables.yellow,
      type:"custom"
    };
  }
  switch (code_type) {
    case 0:
      return {
        BtnText: "Start",
        StatusText: "Not Started",
         color: variables.lightgrey,
        type:"custom"
      };
      break;
    case 1:
      return {
        BtnText: "Started",
        StatusText: "Pending",
        color: variables.yellow,
        type:"custom"
      };
      break;
    case 2:
      return {
        BtnText: "Completed",
        StatusText: "Verified",
        color: variables.green,
        textColor: "white",
        type:"custom"
      };
      break;
    case 3:
      return {
        BtnText: "Completed",
        StatusText: "Manually Verified",
        color: variables.puregreen,
        textColor: "white",
        type:"custom"

      };
      break;
    case 5:
      return {
        BtnText: "Start",
        StatusText: "Requested",
        color: generalColorsTheme.pink,
        type:"solid"
      };
      break;
    case 9:
      return {
        BtnText: "Start Again",
        StatusText: yoti_reason,
        color: generalColorsTheme.red,
        type:"custom"
      };
      break;
    default:
      return {
        BtnText: "Start",
        StatusText: "Not Started",
        color:COLORS.LGREEN,
        type:"stripe"
      };
      break;
  }
};



export const getFullReferencingVerificationStatus = (code_type) => {
  
  switch (code_type) {
    case 0:
      return {
        Text: "Not Started",
        color: variables.lightgrey,
        textColor:variables.darkgrey,
        type:"solid"
      };
      break;
    case 1:
      return {
        Text: "Open Reference",
        color: generalColorsTheme.pink,
        type:"solid"
      };
      break;
    case 2:
      return {
        Text: "Referenced",
        color: variables.puregreen,
        type:"solid"
      };
      break;
    case 3:
      return {
        Text: "Mannually Referenced",
        color: variables.puregreen,
        type:"solid"
      };
      break;
    case 5:
      return {
        Text: "Get started",
        color: generalColorsTheme.pink,
        type:"solid"
      };
      break;
    case 9:
      return {
        Text: "Referencing Error",
        color: generalColorsTheme.red,
        type:"solid"
      };
      break;
    default:
      return {
        Text: "Not Started",
        color: generalColorsTheme.pink,
        type:"solid"
      };
      break;
  }
};




export const getLiteRefrencing = (code_type) => {
  
  switch (code_type) {
    case 0:
      return {
        Text: "Start Verification",
        color: "#9F78FF",
      };

      break;
    case 1:
      return {
        Text: "Pending",
        color: "#D3760C",
      };
      break;
    case 2:
      return {
        Text: "Verified",
        color: "#FC4850",
      };
      break;
    case 3:
      return {
        Text: "Mannually Verified",
        color: "#FC4850",
      };
      break;
    case 5:
      return {
        Text: "Start Verification",
        color: "#FC4850",
      };
      break;
    case 9:
      return {
        Text: "Verification Error",
        color: "#FC4850",
      };
      break;
    default:
      return {
        Text: "Start Verification",
        color: "#9F78FF",
      };
      break;
  }
};

const getMoneyFull=(hour)=>{
  switch (hour) {
    case 1:
      return {
        pounds: 60,
        hour: 1,
      };
      break;
    case 24:
      return {
        pounds: 40,
        hour: 24,
      };
      break;
    case 48:
      return {
        pounds: 20,
        hour: 48,
      };
      break;
    default:
      return {
        pounds: 20,
        hour: 48,
      };
      break;
  }
}
export const moneyValues= [
  1,24,48
]