import React, { useState } from "react";
import {
  View,
  Text,
  SafeAreaView,
  Image,
  TouchableOpacity,
  Platform,
  ScrollView,
  Button,
} from "react-native";
import {
  Container,
  SectionWrapper,
  DualView,
  TextBox,
  SectionTitle,
  SectionText,
  ImageBox,
  TeamsSection,
  CenterSection,
  Section,
  MemberBoxWrapper,
  MemberBox,
  MemberImage,
  MemberName,
  MemberNameText,
  Title,
  Description,
  MemberDetailsText,
  MemberHeader,
  TeamHeader,
  ContentContainer,
  TopSection,
  LogoImage,
  CenterImage,
  DesktopImage,
} from "./styles";

import getPlatformType from "../../helpers/getPlatform";
const layoutType = getPlatformType();

import Header from "../../commonComponents/headerComponent/headerComponent";
import Footer from "../../commonComponents/footerComponent/customFooter";
import TripleTabs from "../../commonComponents/tripleTabs/tripleTabs";
import { COLORS } from "../../constants/colorPallete";
import GradientText from "../../commonComponents/gradientText";

const openfloDesc=`Openflo is the new, innovative and  interactive onboarding journey. 

It pulls and pushes the information needed, dynamically with the tenant app, tlyfe. 

Openflo and tlyfe are fully integrated Agent-Tenant app that seamlessly works together, providing the most dynamic, compliant and secure moving in process.  
`;

const  OpenFlo = (props) => {
  return (
    <SectionWrapper>
      <TopSection>
      <Image source={require('../../assets/SVG/openflo.svg')} style={{width:190,marginTop:30,marginBottom:0,alignSelf:layoutType=='phone'?'center':'center',height:60,resizeMode:'contain'}}/>
        <SectionText style={{fontFamily:"Lato-Bold"}}>The next generation tenant onboarding solution</SectionText>
       
      </TopSection>
      <CenterSection style={{flexDirection:layoutType=="phone"?"column":"row",marginBottom:20}}>
        <ContentContainer  style={{width:layoutType=="phone"?'80%':'50%',marginLeft:layoutType=='phone'?'10%':0}}>
        <Image source={require('../../assets/SVG/agent.svg')} style={{width:170,marginTop:30,marginBottom:20,alignSelf:layoutType=='phone'?'center':'flex-start',height:70,resizeMode:'contain'}}/>
      <Description style={{marginTop:25,fontFamily:'Lato',marginBottom:25,textAlign:'justify'}}>
        {openfloDesc}
       </Description>
       {["Comprehensive digital reference - swift, in real-time and cost effective","ID verification for Right to Rent – improved efficiency","CRM integration – reduce administration and double-keying","Fully secure document audit trail and storage","Offer your tenants proof of rental payments / record and ability to increase their credit score","Easy to use interface","Built on secure blockchain technology"].map((e)=> <View style={{flexDirection:'row',width:'100%',marginTop:20,alignItems:'center'}}>
        <Image source={require('../../assets/images/aboutus/checkbox.png')} style={{height:18,width:18,resizeMode:'contain'}}/>
        <Description style={{marginLeft:10,fontFamily:"Lato",textAlign:'justify'}}>
        {e}
        </Description>
      </View>)}
   

          </ContentContainer>
        <View style={{justifyContent:'center',alignItems:'center',width:layoutType=="phone"?'100%':'60%'}}>
          <DesktopImage source={require('../../assets/images/aboutus/desktop.png')}  />
          <Image source={require('../../assets/SVG/automated.svg')} style={{width:layoutType=="phone"?270:400,marginTop:10,marginBottom:20,alignSelf:layoutType=='phone'?'center':'center',height:130,resizeMode:'contain'}}/>            
        </View>
      </CenterSection>
  
    </SectionWrapper>
  );
};

export default OpenFlo;
