import React from 'react';
import styled from 'styled-components/native';
import { COLORS } from '../../../constants/colorPallete';
import getPlatformType from '../../../helpers/getPlatform';

const layoutType = getPlatformType();

let MainContainer, Wrapper, PropertyDetailsSection;

if (layoutType == 'phone') {
  MainContainer = styled.View`
    justify-content: center;
    align-items: center;

    margin-bottom: 70px;
  `;
  Wrapper = styled.View`
    width: 95%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  `;

  PropertyDetailsSection = styled.View``;
} else if (layoutType == 'web') {
  MainContainer = styled.View`
    justify-content: center;
    align-items: center;

    margin-bottom: 100px;
  `;
  Wrapper = styled.View`
    width: 1046px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  `;

  PropertyDetailsSection = styled.View`
    width: 70%;
    height: 100%;
    background: ${COLORS.LGREEN};
    opacity: 0.9;
    margin-left:53px;
    justify-content: center;
    align-items: center;
  `;
} else {
  MainContainer = styled.View`
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;
  `;
  Wrapper = styled.View`
    width: 90%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  `;

  PropertyDetailsSection = styled.View`
  width: 70%;
    height: 100%;
    background: ${COLORS.LGREEN};
    opacity: 0.9;
    margin-left:53px;
    justify-content: center;
    align-items: center;
  `;
}

export { MainContainer, Wrapper, PropertyDetailsSection };
