import React from 'react';
import { View, TextInput } from 'react-native';
import getPlatformType from '../../helpers/getPlatform';

import { Typography, AmountBox, CurrencyBox, InputBox, RowContainer } from './style';

const RepaymentText = (props) => {

  const { colHeading, colValue, label, lColor, colHeading1, colValue1 } = props;
  const layoutType=getPlatformType();

  return (
    <RowContainer style={{ marginTop: 30 }}>
      <View style={{ flexDirection: 'column', flex: 0.4, justifyContent: 'center' }}>
        <Typography top={'20px'} align={'left'} color={lColor? lColor:'#000000'} size={'20px'} bold={lColor?800: 500}>
          {label}
        </Typography>
      </View>
      <View style={{ flexDirection: 'row', flex: 0.6, justifyContent: 'center' }}>
      { colValue1 || colValue1 == 0 ?
          <View style={{ flex: 0.5, paddingRight: 10 }}>
            {colHeading1? 
              <Typography style={{height:25}} bottom={layoutType=="phone"?'23px':'10px'} align={'left'} color={'#000000'} size={'16px'} bold={700}>
                {colHeading1}
              </Typography>
            : null}
            <AmountBox>
              <CurrencyBox>
                <Typography align={'left'} color={'#4C664B'} size={'20px'} bold={500}>
                  £
                </Typography>
              </CurrencyBox>
              <InputBox>
                <TextInput
                  style={{ margin: 8, padding: 6, width: '96%', height: '100%', borderWidth: 0 }}
                  placeholder=""
                  keyboardType="numeric"
                  value={colValue1}
                  onChangeText={() => {}}
                />
              </InputBox>
            </AmountBox>
          </View>
        : null}

        <View style={{ flex: 0.5, paddingRight: 10 }}>
          {colHeading? (
            <Typography style={{height:25}} bottom={layoutType=="phone"?'23px':'10px'} align={'left'} color={'#000000'} size={'16px'} bold={700}>
              {colHeading}
            </Typography>
          ) : null}
          <AmountBox>
            <CurrencyBox>
              <Typography align={'left'} color={'#4C664B'} size={'20px'} bold={500}>
                £
              </Typography>
            </CurrencyBox>
            <InputBox>
              <TextInput
                style={{ margin: 8, padding: 6, width: '96%', height: '100%', borderWidth: 0 }}
                placeholder=""
                keyboardType="numeric"
                value={colValue}
                onChangeText={() => {}}
              />
            </InputBox>
          </AmountBox>
        </View>
      </View>
    </RowContainer>
  );
};

export default RepaymentText;
