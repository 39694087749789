import { Dimensions } from 'react-native';
const { height, width } = Dimensions.get('window');

function getScreenType() {
  if (width <= 767) {
    return 'phone';
  } else if (width >= 768 && width <= 1024) {
    return 'tab';
  } else if (width >= 1025) {
    return 'web';
  }
}
export function getImage(name, isSingle) {
  // if (isSingle) {
  //   return require(`../images/${name}`);
  // } else {
  //   if (width <= 767) {
  //     return require(`../images/banner/mobile/${name}`);
  //   } else if (width >= 768 && width <= 1024) {
  //     return require(`../images/banner/tab/${name}`);
  //   } else if (width >= 1025) {
  //     return require(`../images/banner/web/${name}`);
  //   }
  // }
}
export default getScreenType;
