import React, { useState } from "react";

import {
  View,
  Text,
  SafeAreaView,
  Image,
  TouchableOpacity,
  Platform,
  ScrollView,
  Button,
  Linking,
  TouchableWithoutFeedback,
} from "react-native";
import idx from "idx";
import GoogleMaps from "../../commonComponents/MapViewComponent/mapView";
import {
  MaterialCommunityIcons,
  Entypo,
  MaterialIcons,
  FontAwesome,
} from "@expo/vector-icons";
import { GreyText, DetailsTop, BackTextWrapper } from "./styles";
import tube from '../../assets/images/Tube.png';
import tram from '../../assets/images/Tram.png';
import rail from '../../assets/images/Rail.png';



const Transport = (props) => {
  const { data, searchMapData } = props;
  const [currentView, SetCurrentView] = useState("list");
  const _onPress = (val) => {
    SetCurrentView(val);
  };
  
  const transportType=(type)=>{
    if(type=="train_station"){
      return rail
    }
    else if(type=="tram_stop"){
      return tram
    }
    else {
      return tube
    }
  }
  const renderCard = (item, index) => {
    if (item.type == "tube_station" || item.type == "train_station" || item.type=="tram_stop" || item.type=="subway_station")
      return (
        <View
          key={index}
          style={{
            width: "100%",
            flexDirection: "row",
            marginTop: 20,
          }}
        >
          <View
            style={{
              flex: 0.333,
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            {/* <MaterialCommunityIcons name="train" size={50} color="black" /> */}
            <Image source={transportType(item?.type)} style={{width:42,height:42}}/>
            
          </View>
          <View style={{ width: 1, backgroundColor: "grey", height: "80%" }} />
          <View
            style={{
              flex: 0.333,
              justifyContent: "center",
              alignItems: "center",
              paddingVertical: 20,
            }}
          >
            <Text
              style={{
                color: "black",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              {item?.name}
            </Text>
          </View>
          <View style={{ width: 1, backgroundColor: "grey", height: "80%" }} />
          <View
            style={{
              flex: 0.333,
              justifyContent: "center",
              alignItems: "center",
              paddingVertical: 20,
            }}
          >
            <Text
              style={{
                color: "black",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              {(item?.distance / 1609).toFixed(1)}
            </Text>
          </View>
        </View>
      );
  };
  return (
    <>
      <BackTextWrapper onPress={() => props.ResetService()}>
        <Entypo
          name="chevron-left"
          size={18}
          color="#000"
          // style={{ verticalAlign: "bottom" }}
        />{" "}
        Back
      </BackTextWrapper>

      <GreyText>Transport</GreyText>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "flex-end",
          marginTop: 10,
        }}
      >
        <TouchableOpacity onPress={() => _onPress("list")}>
          <MaterialIcons
            name="format-list-bulleted"
            size={22}
            color={currentView == "list" ? "black" : "grey"}
          />
        </TouchableOpacity>
        <View
          style={{
            width: 1,
            height: "100%",
            backgroundColor: "grey",
            marginLeft: 8,
            marginRight: 10,
          }}
        />
        <TouchableOpacity onPress={() => _onPress("map")}>
          <FontAwesome
            name="map-o"
            size={20}
            color={currentView == "map" ? "black" : "grey"}
          />
        </TouchableOpacity>
      </View>
      <View
        style={{
          width: "100%",
          minHeight: 60,

          flexDirection: "column",
          borderWidth: 1,
          borderRadius: 7,
          borderColor: "#b4b4b4",
          marginTop: 10,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {data.member && currentView == "map" ? (
          <View
            style={{
              width: "100%",
            }}
          >
            <GoogleMaps
              data={[
                {
                  lat: idx(
                    props.propertyData,
                    (_) => _.location.coordinates[0]
                  ),
                  lng: idx(
                    props.propertyData,
                    (_) => _.location.coordinates[1]
                  ),
                },
              ]}
              schools={[]}
              transports={data.member}
              searchObject={null}
            />
          </View>
        ) : (
          <>
            <View
              style={{
                width: "100%",
                flexDirection: "row",
                marginTop: 20,
              }}
            >
              <View
                style={{
                  flex: 0.333,
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{
                    color: "#D81E6C",
                    fontWeight: "bold",
                    textAlign: "center",
                    marginTop: "4%",
                  }}
                >
                  Type
                </Text>
              </View>
              <View
                style={{ width: 1, backgroundColor: "grey", height: "80%" }}
              />
              <View
                style={{
                  flex: 0.333,
                  justifyContent: "center",
                  alignItems: "center",
                  paddingVertical: 20,
                }}
              >
                <Text
                  style={{
                    color: "#D81E6C",
                    fontWeight: "bold",
                    textAlign: "center",
                    marginTop: "4%",
                  }}
                >
                  Name
                </Text>
              </View>
              <View
                style={{ width: 1, backgroundColor: "grey", height: "80%" }}
              />
              <View
                style={{
                  flex: 0.333,
                  justifyContent: "center",
                  alignItems: "center",
                  paddingVertical: 20,
                }}
              >
                <Text
                  style={{
                    color: "#D81E6C",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Distance (miles)
                </Text>
              </View>
            </View>
            {data.member &&
              data.member.map((item, index) => renderCard(item, index))}
          </>
        )}
      </View>
    </>
  );
};
export default Transport;
