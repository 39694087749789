import React, { useState } from "react";
import ShareMedia from "../../commonComponents/shareMedia/shareMedia";
import {
  View,
  Text,
  SafeAreaView,
  Image,
  TouchableOpacity,
  Platform,
  ScrollView,
  Button,
  Linking,
  TouchableWithoutFeedback,
} from "react-native";
import {
  Container,
  SliderWrapper,
  SliderBox,
  ImageThumbnailBox,
  ImageThumbnail,
  AgentInfoBox,
  AgentImage,
  AgentCta,
  DetailsBox,
  HeaderText,
  DetailsTop,
  GreyText,
  InnerDesc,
  DetailsBottom,
  DetailsCenter,
  AgentCtaView,
  TopScrollView,
  DetailsWrapper,
  SliderWrapperBottom,
  BookmarkView,
  FloorPlanView,
  ShareButtonView,
  BackLinkView,
  ShareButtonText,
  FloorPlanText,
  BackLinkText,
  Cordinates,
  CordText,
  Sub,
  ServicesList,
  MapWrapper,
  ServicesListTextBox,
  ServicesListTextBoxLeft,
  ServicesBox,
  ServiceText,
  ImageBox,
  ShareOptions,
  DetailsTopIcons,
  IconBox,
  PropertyLabelvarient1,
  propertyLabelvarient2,
  PropertyLabelvarient2,
  ContentBox,
} from "./styles.js";
import { FlatList } from "react-native-gesture-handler";

import { MaterialIcons, MaterialCommunityIcons } from "@expo/vector-icons";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import getPlatformType, { FULL_HEIGHT } from "../../helpers/getPlatform";
import ReactGA from "react-ga4";
import { sendEmail, callIfAvailable } from "../../helpers/callEmailHelper";

const layoutType = getPlatformType();
import Loader from "./../../commonComponents/activityIndicator/activityIndicator";

import TripleTabs from "../../commonComponents/tripleTabs/tripleTabs";
import Slider from "../../commonComponents/SliderComponent";
import GoogleMap from "../../commonComponents/MapViewComponent/mapView";
import Header from "../../commonComponents/headerComponent/headerComponent";
import Footer from "../../commonComponents/footerComponent/customFooter";
import { getPropertyById } from "../../redux/actions/property";
import { showToaster } from "../../redux/actions/toast";
import idx from "idx";
import moment from "moment";
import { saveProperty } from "../../redux/actions/property";
import { FormatPrice } from "../../helpers/validation.js";
import { Analytics, Event } from "expo-analytics";
import Tab3 from "./tab3";
import Tab1 from "./tab1";
import { ContainerScrollView } from "../../helpers/appStyles";

const PropertyDetails = (props) => {
  const { property_id } = idx(props, (_) => _.route.params);
  
  const [shareOptions, setShareOptions] = useState(false);

  const { goBack, navigate } = props.navigation;
  const [save, setSave] = React.useState(null);
  React.useEffect(() => {
    props.getPropertyById(property_id);
  }, [property_id]);
  const { propertyData, loading, saveProperty, searchObject } = props;
  React.useEffect(() => {
    setSave(propertyData?.isSaved);
  }, [propertyData?.isSaved]);
  const _onSave = () => {
    setSave(!save);
    saveProperty(property_id, searchObject);
  };
  const callAgent = async () => {
  callIfAvailable(propertyData.branch.contact_number);
  ReactGA.event({
    category: "Lead",
    action: "Call",
    label: propertyData.branch.branch_id, // optional
  });

  };
  const emailAgent = async () => {
   sendEmail(propertyData);
    ReactGA.event({
      category: "Lead",
      action: "Call",
      label: propertyData.branch.branch_id, // optional
    });
  };

  const tab2 = (
    <MapWrapper>
      <Cordinates>
        <View style={{ width: "50%", paddingRight: 10 }}>
          <PropertyLabelvarient2>
            {/*{idx(propertyData, (_) => _.address_line1)},
            {propertyData?.postcode?.split(" ")[0]}*/}
            {propertyData?.display_address}
          </PropertyLabelvarient2>
        </View>
        <View style={{ width: "50%", alignItems: "flex-end" }}>
          <CordText>{FormatPrice(idx(propertyData, (_) => _.price))}</CordText>
        </View>
      </Cordinates>
      <GoogleMap
        style={{ height: "100%" }}
        showOnly={true}
        price={FormatPrice(idx(propertyData, (_) => _.price))}
        data={[
          {
            lat: idx(propertyData, (_) => _.location.coordinates[0]),
            lng: idx(propertyData, (_) => _.location.coordinates[1]),
          },
        ]}
      />
    </MapWrapper>
  );
  const onShare = async () => {
    try {
      const result = await Share.share({
        title: "Hey look at this property i just found on OpenBrix",
        message: `Hey,\nI just found this ${propertyData.bedrooms} bed property for ${propertyData.property_for} on OpenBrix.\nCheck it out!\n\n${window.location.href}\n\nKindly regards,`,
        url: `Hey,\nI just found this ${propertyData.bedrooms} bed property for ${propertyData.property_for} on OpenBrix.\nCheck it out!\n\n${window.location.href}`,
      });
      if (result.action === Share.sharedAction) {
        if (result.activityType) {
          // shared with activity type of result.activityType
        } else {
          // shared
        }
      } else if (result.action === Share.dismissedAction) {
        // dismissed
      }
    } catch (error) {
      alert(error.message);
    }
  };
  const thumbnailImages = propertyData
    ? [
        idx(propertyData, (_) => _.property_images).length > 1
          ? idx(propertyData, (_) => _.property_images[1]).replace(/["[]+/g, "")
          : "https://assets.openbrix.co.uk/nophoto.jpg",
        idx(propertyData, (_) => _.property_images).length > 2
          ? idx(propertyData, (_) => _.property_images[2]).replace(/["[]+/g, "")
          : "https://assets.openbrix.co.uk/nophoto.jpg",
        idx(propertyData, (_) => _.property_images).length > 3
          ? idx(propertyData, (_) => _.property_images[3]).replace(/["[]+/g, "")
          : "https://assets.openbrix.co.uk/nophoto.jpg",
      ]
    : [];
  if (loading) {
    return <Loader loading={loading} />;
  }
  return (
    <TouchableWithoutFeedback onPress={() => setShareOptions(false)}>
      <View
        style={{
          backgroundColor: "#fafafa",
        }}
      >
       

        <SafeAreaView style={{height:FULL_HEIGHT}}>
        {layoutType !== "phone" ? (
          <Header
            showLogoMob
            showLogoWeb
            showLogoTab
            MenuWeb
            rightChildLabelTab
            showBackButtonTab
            onBackAction={() => navigate("Search")}
            {...props}
          />
        ) : null}
          <ContainerScrollView>
            <View style={{ flex: 1, alignItems: "center" }}>
              <Container>
                <SliderWrapper>
                  <SliderBox>
                    <Slider
                      videoHeight="472"
                      //videoUrl="https://www.youtube.com/embed/tgbNymZ7vqY"
                      //videoUrl="http://techslides.com/demos/sample-videos/small.mp4"
                      videoUrl={idx(propertyData, (_) => _.video_url)}
                      imageArray={idx(propertyData, (_) => _.property_images)}
                    />
                    <View
                      style={{
                        position: "absolute",
                        paddingHorizontal: 15,
                        alignItems: "center",
                        justifyContent: "space-between",
                        flexDirection: "row",
                        top: 0,
                        width: "100%",
                        height: 60,
                        backgroundColor: "#00000060",
                      }}
                    >
                      <TouchableOpacity
                        onPress={() => navigate("Search")}
                        style={{ flexDirection: "row", alignItems: "center" }}
                      >
                        <MaterialIcons
                          name="keyboard-arrow-left"
                          size={35}
                          color="white"
                        />
                        <BackLinkText>Back</BackLinkText>
                      </TouchableOpacity>
                      <View
                        style={{ flexDirection: "row", alignItems: "center" }}
                      >
                        <ShareButtonView
                        id={"PROP_SHARE_BTN"}
                          onPress={() =>
                            Platform.OS == "web"
                              ? setShareOptions(!shareOptions)
                              : onShare()
                          }
                        >
                          <ShareButtonText>Share</ShareButtonText>
                        </ShareButtonView>
                        {shareOptions ? (
                          <ShareOptions onPress={() => setShareOptions(true)}>
                            <ShareMedia
                              shareUrl={`Hey,\nI just found this ${propertyData.bedrooms} bed property for ${propertyData.property_for} on OpenBrix.\nCheck it out!\n\n${window.location.href}`}
                              emailSubject={
                                "Hey look at this property i just found on OpenBrix"
                              }
                              emailBody={`Hey,\nI just found this ${propertyData.bedrooms} bed property for ${propertyData.property_for} on OpenBrix.\nCheck it out!\n\n${window.location.href}\n\nKindly regards,`}
                            />
                          </ShareOptions>
                        ) : null}
                        <BookmarkView onPress={() => _onSave()}>
                          <MaterialIcons
                            name="bookmark"
                            size={24}
                            color={save == true ? "#E71E82" : "white"}
                          />
                        </BookmarkView>
                      </View>
                    </View>
                  </SliderBox>
                  {layoutType !== "phone" ? (
                    <ImageThumbnailBox>
                      {thumbnailImages.map((image, index) => {
                        return (
                          <ImageThumbnail key={index + "img"}>
                            <Image
                              source={{ uri: image }}
                              style={{ width: "100%", height: "100%" }}
                            />
                          </ImageThumbnail>
                        );
                      })}
                    </ImageThumbnailBox>
                  ) : null}
                </SliderWrapper>
                <SliderWrapperBottom>
                  <ContentBox>
                    <TripleTabs
                      tabList={[
                        {
                          label: "Details",
                          target: <Tab1 propertyData={propertyData} />,
                          targetfooter: null,
                          id:"PROPERTY_DATAILS_TAB"
                        },
                        {
                          label: "Map",
                          targetfooter: null,
                          target: tab2,
                          id:"PROPERTY_MAP_TAB"
                        },
                        {
                          label: "Services",
                          target: (
                            <Tab3
                              propertyData={propertyData}
                              searchObject={props.searchObject}
                            />
                          ),
                          targetfooter: null,
                          id:"PROPERTY_SERVICE_TAB"
                        },
                      ]}
                      isFullWidth={true}
                      paddingTop="0%"
                      paddingBottom="0%"
                    />
                  </ContentBox>
                  {layoutType == "phone" ? (
                    <View
                      style={{
                        width: "100%",
                        height: 120,
                        marginTop: 15,
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "white",
                      }}
                    >
                      <Image
                        source={{ uri: propertyData?.agent_logo }}
                        style={{
                          width: 150,
                          height: "100%",
                          resizeMode: "contain",
                        }}
                      />
                    </View>
                  ) : null}
                  {layoutType !== "phone" ? (
                    <AgentInfoBox
                      style={{
                        shadowColor: "grey",
                        shadowOffset: {
                          width: 0,
                          height: 2,
                        },
                        shadowOpacity: 0.9,
                        shadowRadius: 6.68,
                        elevation: 11,
                        backgroundColor: propertyData?.background_color
                          ? propertyData?.background_color
                          : "#ffffff",
                      }}
                    >
                      <AgentImage>
                        <Image
                          source={{ uri: propertyData?.agent_logo }}
                          style={{
                            width: "100%",
                            height: "100%",
                            resizeMode: "contain",
                          }}
                        />
                      </AgentImage>
                      <AgentCtaView>
                        <AgentCta onPress={() => callAgent()}>
                          <Text style={{ color: "#e71e82" }}>Call Agent</Text>
                        </AgentCta>
                        <AgentCta
                          style={{ marginTop: "1%" }}
                          onPress={() => emailAgent()}
                        >
                          <Text style={{ color: "#e71e82" }}>Email Agent</Text>
                        </AgentCta>
                      </AgentCtaView>
                    </AgentInfoBox>
                  ) : null}
                </SliderWrapperBottom>
              </Container>
            </View>
          </ContainerScrollView>
        </SafeAreaView>
        {layoutType == "phone" ? (
          <AgentCtaView
            style={{ marginVertical: 0, position: "fixed", bottom: 0 }}
          >
            <AgentCta
              onPress={() => {
                propertyData.branch && propertyData.branch.contact_number
                  ? callIfAvailable(propertyData.branch.contact_number)
                  : null;
              }}
            >
              <Text style={{ color: "#e71e82" }}>Call Agent</Text>
            </AgentCta>
            <AgentCta onPress={() => emailAgent()}>
              <Text style={{ color: "#e71e82" }}>Email Agent</Text>
            </AgentCta>
          </AgentCtaView>
        ) : null}
      </View>
    </TouchableWithoutFeedback>
  );
};

const mapStateToProps = (state) => ({
  loading: state.propertyReducer.propertyLoading,
  propertyData: idx(state, (_) => _.propertyReducer.propertyData.data),
  searchObject: idx(state, (_) => _.propertyReducer.searchObject),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getPropertyById,
      saveProperty,
      showToaster,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(PropertyDetails);
