import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  headingText: {
    fontFamily: 'Lato',
    fontWeight: '700',
    fontSize: 49,
  },
  heading1: {
    fontFamily: 'Lato',
    fontWeight: '700',
    fontSize: 40,
  },
  heading2: {
    fontFamily: 'Lato',
    fontWeight: '700',
    fontSize: 27,
  },
  heading3: {
    fontFamily: 'Lato',
    fontWeight: '400',
    fontSize: 19,
  },
  TextCenter: {
    textAlign: 'center',
  },
  manifestoText: {},
  container: {
    marginTop: 260,
  },
  headingWrapper: {
    flexDirection:'row',
    alignItems:'center',
  },
  videoWrapper:{
    width: "60%",
    height: 422,
    borderWidth:0.5,
    borderColor: "#000000",
    borderRadius: 9,
    padding:9,
    marginLeft:100,
    backgroundColor: "transparent"
  },

  headingTextWrapper: {},
  visionWrapper: {
    marginTop: 270,
    alignItems: 'center',
    justifyContent: 'center',
  },
  visionSubTextWrapper: {
    marginTop: 71,
    alignItems: 'center',
    justifyContent: 'center',
  },
  saperaterWrapper: {
    marginVertical: 65,
  },
  saperator: {
    height: 0.5,
    backgroundColor: '#6d6d6d',
  },
  valuesWrapper: {
    marginBottom: 133,
  },
  sectionWrapper: {
    marginBottom: 48,
  },
  section1: {
    marginBottom: 22,
  },
  section2: {
    marginBottom: 37,
  },
  manifastoWrapper: {
    marginTop: 96,
    marginBottom: 121,
  },
  cardWrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap:'wrap'
  },
  cardSection:{
    marginHorizontal: 30,
    marginVertical:30
 },
 piramidWrapper:{
justifyContent:'center',
alignItems: "center",
marginBottom: 84
 },
  formWrapper: {
    alignSelf: 'center',
    width: 615,
    paddingHorizontal: 74,
    paddingVertical: 47,
    borderColor: '#6D6D6D',
    borderWidth: 0.5,
    borderRadius: 13,
    marginTop:70
  },
  horizontal: {
    marginHorizontal: 122,
  },
});
