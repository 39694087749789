import { authHeader } from "./auth-header";
import Constant from "./Constant";
import * as RootNavigation from "./../../../navigation/RootNavigation";
import { store } from "../../../redux/store/setup";
import { showToaster } from "../../../redux/actions/toast";

function fetchWithTimeout(url, options) {
  const timer = new Promise((resolve) => {
    setTimeout(resolve, 15000, {
      timeout: true,
    });
  });
  return Promise.race([
    fetch(url, options),
    timer,
  ])
  .then(response => {
    if (response.timeout) {
      store.dispatch(showToaster("error", "Something went wrong!"));
    }
    return response?.timeout ? null : response;
  });
}

const getDocuments = (id) => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetchWithTimeout(
    `${Constant.API_URL}/openflow/certificates/?flow_id=${id}`,
    requestOptions,
  )
  .then(handleResponse);
};
function handleResponse(response) {
  return response && response.json()
  .then((text) => {
    const data = text;

    if (!response.ok) {
      if (response.status === 403) {
      
        // auto logout if 401 response returned from api
        RootNavigation.navigate("Sign in");
        return response;
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
const getHoldingDeposit = (id) => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetchWithTimeout(
    // `${Constant.API_URL}/openflow/deposits?flow_id=${id}`,

    `${Constant.API_URL}/openflow/deposits/?flow_id=${id}`,
    requestOptions,
  )
  .then(handleResponse);
};

const getOnboardedTeanants = () => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetchWithTimeout(`${Constant.API_URL}/openflow/flows`, requestOptions)
  .then(
    handleResponse,
  );
};
const getTeanants = (ID) => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetchWithTimeout(
    `${Constant.API_URL}/openflow/tenant/?flow_id=${ID}`,
    requestOptions,
  )
  .then(handleResponse);
};
const getFlowInfo = (id) => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetchWithTimeout(
    `${Constant.API_URL}/openflow/flow`,
    requestOptions,
  )
  .then(handleResponse);
};

const getRentalOffers = (id) => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetchWithTimeout(
    `${Constant.API_URL}/openflow/rentaloffer/?flow_id=${id}`,
    requestOptions,
  )
  .then(handleResponse);
};
const getIdentityData = (id) => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetchWithTimeout(
    `${Constant.API_URL}/openflow/verificationreferencing/?flow_id=${id}`,
    requestOptions,
  )
  .then(handleResponse);
};

function saveIdentityData(payload, id) {

  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  };
  return fetchWithTimeout(
    `${Constant.API_URL}/add-guarantor?flow_id=${id}`,
    requestOptions,
  )
  .then(handleResponse);
}

function addRentalOffer(id) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
    body: undefined,
  };
  return fetchWithTimeout(
    `${Constant.API_URL}/openflow/acceptrentaloffer?flow_id=${id}`,
    requestOptions,
  )
  .then(handleResponse);
}

function downloadVerifiedDocuments(queryParams) {
  const requestOptions = {
    method: "GET",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  };
  return fetchWithTimeout(
    `${Constant.API_URL}/yoti/getDocument?` + new URLSearchParams(queryParams),
    requestOptions,
  );
}


const getMoveIn = (id) => {
  const requestOptions = {
    method: "GET",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  };
  return fetchWithTimeout(
    `${Constant.API_URL}/openflow/movein/?flow_id=${id}`,
    requestOptions,
  )
  .then(handleResponse);
};

function addMoveIn(payload) {

  const requestOptions = {
    method: "POST",
    //   headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  };
  return fetchWithTimeout(
    `${Constant.MOCKUP_BASEURL}/openflow/movein`,
    requestOptions,
  )
  .then(handleResponse);
}

const getTenancyInfo = (id) => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetchWithTimeout(
    `${Constant.API_URL}/openflow/tenancyinformation/?flow_id=${id}`,
    requestOptions,
  )
  .then(handleResponse);
};
const getNHA = () => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetchWithTimeout(
    `${Constant.MOCKUP_BASEURL}/openflow/getNHA`,
    requestOptions,
  )
  .then(handleResponse);
};
const getAST = (id) => {
  const requestOptions = {
    method: "GET",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  };
  return fetchWithTimeout(
    `${Constant.API_URL}/openflow/tenancyinformation/getAST?flow_id=${id}`,
    requestOptions,
  )
  .then(handleResponse);
};



const getTdsData = (id) => {
  const requestOptions = {
    method: "GET",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  };
  return fetchWithTimeout(
    `${Constant.API_URL}/openflow/tds`,
    requestOptions,
  )
  .then(handleResponse);
};

function acceptMoveinApi(flowId) {

  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
    body: undefined,
  };
  return fetchWithTimeout(
    `${Constant.API_URL}/openflow/acceptmovein?flow_id=${flowId}`,
    requestOptions,
  )
  .then(handleResponse);
}

function addTenancyInfo(payload, id) {

  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  };
  return fetchWithTimeout(
    `${Constant.API_URL}/openflow/tenancyinformation/?flow_id=${id}`,
    requestOptions,
  )
  .then(handleResponse);
}

function saveMoveinData(payload, id) {

  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  };
  return fetchWithTimeout(
    `${Constant.API_URL}/openflow/movein/?flow_id=${id}`,
    requestOptions,
  )
  .then(handleResponse);
}

function arrangeCheckIn(payload, id) {

  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  };
  return fetchWithTimeout(
    `${Constant.API_URL}/openflow/arrangeCheckIn/?flow_id=${id}`,
    requestOptions,
  )
  .then(handleResponse);
}

function saveSlectedProperty(payload) {

  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ property_id: payload }),
  };
  return fetchWithTimeout(`${Constant.API_URL}/openflow/flow`, requestOptions)
  .then(
    handleResponse,
  );
}

function confirmGaurantor(payload, id) {

  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ property_id: payload }),
  };
  return fetchWithTimeout(`${Constant.API_URL}openflow/confirm-gaurantor/?flow_id=${id}`, requestOptions)
  .then(
    handleResponse,
  );
}

function saveTeanantDetails(payload) {

  const requestOptions = {
    method: "PUT",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  };
  return fetchWithTimeout(
    `${Constant.API_URL}/profile`,
    requestOptions,
  )
  .then(handleResponse);
}


function toggleSeenAll(flowId) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  };
  let url = `/openflow/certificate/view?flow_id=${flowId}&readAll=${true}`
  return fetchWithTimeout(`${Constant.API_URL}${url}`, requestOptions)
  .then(
    handleResponse,
  );
}
function toggleSeen(certificate_url, flwid,isHowTorent) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
    body:isHowTorent?JSON.stringify({ howtorent: true, is_seen: true }):  certificate_url? JSON.stringify({ certificate_url, is_seen: true }):null,
  };
  let url = "/openflow/certificate/view?flow_id=" + flwid;
  if (certificate_url) {
    url = "/openflow/certificate/view?flow_id=" + flwid;
  }
  return fetchWithTimeout(`${Constant.API_URL}${url}`, requestOptions)
  .then(
    handleResponse,
  );
}
function viewOpenFlowDepositCertificate(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  let url = "/openflow/certificate/view" 
  return fetchWithTimeout(`${Constant.API_URL}${url}`, requestOptions)
  .then(
    handleResponse,
  );
}

function deleteDoc(flwid) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  };
  let url = "/openflow/manualAst?flow_id=" + flwid;
  return fetchWithTimeout(`${Constant.API_URL}${url}`, requestOptions)
  .then(
    handleResponse,
  );
}

function confirmDeposit(flowId) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  };
  return fetchWithTimeout(
    `${Constant.API_URL}/openflow/deposits/confirm?flow_id=${flowId}`,
    requestOptions,
  )
  .then(handleResponse);
}

function requestHoldingDeposit(payload, id) {

  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  };
  return fetchWithTimeout(
    `${Constant.API_URL}/openflow/deposit/requestdeposit/?flow_id=${id}`,
    requestOptions,
  )
  .then(handleResponse);
}


function buyCer(cerId, data, id) {

  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  return fetchWithTimeout(
    `${Constant.API_URL}/openflow/certificates/:${cerId}/buy?flow_id=${id}`,
    requestOptions,
  )
  .then(handleResponse);
}

function delCer(data, id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  return fetchWithTimeout(
    `${Constant.API_URL}/openflow/certificates/?flow_id=${id}`,
    requestOptions,
  )
  .then(handleResponse);
}

function uploadManualAst(data, id) {

  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  return fetchWithTimeout(
    `${Constant.API_URL}/openflow/uploadManualAst?flow_id=` + id,
    requestOptions,
  )
  .then(handleResponse);
}

function postRtrCode(data) {

  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  return fetchWithTimeout(
    `${Constant.API_URL}/openflow/save-rtr-code`,
    requestOptions,
  )
  .then(handleResponse);
}

function uploadRefDocs(data, id) {

  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  return fetchWithTimeout(
    `${Constant.API_URL}/openflow/uploadreferencingdocs?flow_id=${id}`,
    requestOptions,
  )
  .then(handleResponse);
}

function uploadRightToRentDocs(data) {

  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  return fetchWithTimeout(
    `${Constant.API_URL}/openflow/tenant-upload-right-to-rent-document`,
    requestOptions,
  )
  .then(handleResponse);
}

function getFccRequestedReferenceDocs(id) {
  const requestOptions = {
    method: "GET",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  };
  return fetchWithTimeout(`${Constant.API_URL}/fcc-requested-docs?flow_id=${id}`, requestOptions).then(handleResponse);
}

function uploadTenantRef(data, id) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  return fetchWithTimeout(
    `${Constant.API_URL}/upload-ref-docs?flow_id=` + id,
    requestOptions,
  )
  .then(handleResponse);
}

function deleteRefDoc(data, id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  return fetchWithTimeout(
    `${Constant.API_URL}/openflow/uploadreferencingdocs`,
    requestOptions,
  )
  .then(handleResponse);
}



function getVerificationReferencing(response) {
  const requestOptions = {
    method: "GET",
    // headers: authHeader()
  };
  return fetchWithTimeout(
    `${Constant.MOCKUP_BASEURL}/openflow/verificationreferencing`,
    requestOptions,
  )
  .then(handleResponse);
}

function paywithordo() {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };
  return fetchWithTimeout(
    `${Constant.API_URL}/openflow/paywithordo`,
    requestOptions,
  )
  .then(handleResponse);
}

const searchDeposit = (payload) => {
  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  };
  return fetchWithTimeout(
    `${Constant.API_URL}/search_deposit`,
    requestOptions,
  )
  .then(handleResponse);
};

const searchAddress = (postCode) => {
  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ postCode }),
  };
  return fetchWithTimeout(
    `${Constant.API_URL}/aw/find_oprid?postCode=${postCode}`,
    requestOptions,
  )
  .then(handleResponse);
};

const requestLoan = (payload) => {
  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  };
  return fetchWithTimeout(
    `${Constant.API_URL}/zopa/request-loan`,
    requestOptions,
  )
  .then(handleResponse);
};
const zopaFollowup = (id) => {
  const requestOptions = {
    method: "GET",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  };
  return fetchWithTimeout(
    `${Constant.API_URL}/zopa/followup/${id}`,
    requestOptions,
  )
  .then(handleResponse);
};
const getPackedge = (url) => {
  const requestOptions = {
    method: "GET",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  };
  return fetchWithTimeout(
    `${Constant.API_URL}${url}`,
    requestOptions,
  )
  .then(handleResponse);
};

const startPayment = (payload,isPaymentTypeRewards) => {
  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  };
  return fetchWithTimeout(
    isPaymentTypeRewards?`${Constant.API_URL}/vaboo/subscription/start`:  `${Constant.API_URL}/pre-qualification/stripe/start-payment`,
    requestOptions,
  )
  .then(handleResponse);
};

const getPreQualificationStatus = () => {
  const requestOptions = {
    method: "GET",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  };
  return fetchWithTimeout(
    `${Constant.API_URL}${"/pre-qualification/current-status"}`,
    requestOptions,
  )
  .then(handleResponse);
};
const saveAgentEmail = (payload) => {
  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  };
  return fetchWithTimeout(
    `${Constant.API_URL}/document/shareCode`,
    requestOptions,
  )
  .then(handleResponse);
};
const getCodesStatus = () => {
  const requestOptions = {
    method: "GET",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  };
  return fetchWithTimeout(
    `${Constant.API_URL}${"/document/shareCodes"}`,
    requestOptions,
  )
  .then(handleResponse);
};
const getRewardsSubsStatus = () => {
  const requestOptions = {
    method: "GET",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  };
  return fetchWithTimeout(
    `${Constant.API_URL}${"/vaboo/check_subs_status"}`,
    requestOptions,
  )
  .then(handleResponse);
};
const getRewardsUrl = () => {
  const requestOptions = {
    method: "GET",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  };
  return fetchWithTimeout(
    `${Constant.API_URL}${"/vaboo/provision_and_get_link"}`,
    requestOptions,
  )
  .then(handleResponse);
};
const getPreRefUri = () => {
  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  };
  return fetchWithTimeout(
    `${Constant.API_URL}${"/pre-qualification/start-digital-reference"}`,
    requestOptions,
  )
  .then(handleResponse);
};

const postCodesStatus = (payload) => {
  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  };
  return fetchWithTimeout(
    `${Constant.API_URL}/document/updateShareSettings`,
    requestOptions,
  )
  .then(handleResponse);
};

const addGuarantorToReference = (payload, tenant_prequal_id) => {
  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  };
  return fetchWithTimeout(
    `${Constant.API_URL}/pre-qualification/add-guarantor-to-digital-reference/${tenant_prequal_id}`,
    requestOptions,
  )
  .then(handleResponse);
};

const getInsuranceClicked = () => {
  const requestOptions = {
    method: "GET",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  };
  return fetchWithTimeout(
    `${Constant.API_URL}/tenant_insurance_clicked`,
    requestOptions,
  )
  .then(handleResponse);
};
const getTiles = () => {
  const requestOptions = {
    method: "GET",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  };
  return fetchWithTimeout(
    `${Constant.API_URL}/dashboard/tiles`,
    requestOptions,
  )
  .then(handleResponse);
};
const getBroadband = (payload) => {
  const { postcode, page } = payload; 

  const requestOptions = {
    method: "GET",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  };

  const queryString = new URLSearchParams({ postcode, page }).toString(); 

  return fetchWithTimeout(
    `${Constant.API_URL}/broadband/recommendations?${queryString}`, 
    requestOptions
  )
    .then(handleResponse);
};
const getPackages = (payload) => {
  const { postcode } = payload; 

  const requestOptions = {
    method: "GET",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  };

  const queryString = new URLSearchParams({ postcode }).toString(); 

  return fetchWithTimeout(
    `${Constant.API_URL}/billingbetter/packages?${queryString}`, 
    requestOptions
  )
    .then(handleResponse);
};
const getIFrameURL = (payload) => {
  const { postcode } = payload; 
  const requestOptions = {
    method: "GET",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  };
  const queryString = new URLSearchParams({ postcode }).toString(); 
  return fetchWithTimeout(
    `${Constant.API_URL}/billingbetter/iframe?${queryString}`, 
    requestOptions
  )
    .then(handleResponse);
};
const getInsurancePurchase = () => {
  const requestOptions = {
    method: "GET",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  };
  return fetchWithTimeout(
    `${Constant.API_URL}/insurance-purchases`,
    requestOptions,
  )
  .then(handleResponse);
};
const saveTenantInfo = (payload) => {
  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  };
  return fetchWithTimeout(
    `${Constant.API_URL}/tenant_info`,
    requestOptions,
  )
  .then(handleResponse);
};

const getTenantInfo = () => {
  const requestOptions = {
    method: "GET",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  };
  return fetchWithTimeout(
    `${Constant.API_URL}/tenant_info`,
    requestOptions,
  )
  .then(handleResponse);
};
const payWithVoucher = (payload) => {
  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  };
  return fetchWithTimeout(
    `${Constant.API_URL}/pre-qualification/paywithvoucher`,
    requestOptions,
  )
  .then(handleResponse);
};
const getOpenflowData = (flowId) => {
  const requestOptions = {
    method: "GET",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  };
  return fetchWithTimeout(
    `${Constant.API_URL}/openflow/flow?flow_id=${flowId}`,
    requestOptions,
  )
  .then(handleResponse);
};
const getSignAstStatus = (flowId) => {
  const requestOptions = {
    method: "GET",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  };
  return fetchWithTimeout(
    `${Constant.API_URL}/openflow/signAstStatus?flow_id=${flowId}`,
    requestOptions,
  )
  .then(handleResponse);
};

export const TenantService = {
  getSignAstStatus,
  getOpenflowData,
  getTenantInfo,
  saveTenantInfo,
  getPreRefUri,
  postCodesStatus,
  addGuarantorToReference,
  getCodesStatus,
  saveAgentEmail,
  getPreQualificationStatus,
  startPayment,
  postRtrCode,
  getRentalOffers,
  addRentalOffer,
  getOnboardedTeanants,
  getMoveIn,
  addMoveIn,
  arrangeCheckIn,
  getTenancyInfo,
  getNHA,
  getAST,
  addTenancyInfo,
  getVerificationReferencing,
  getFlowInfo,
  getTeanants,
  getDocuments,
  saveSlectedProperty,
  saveTeanantDetails,
  toggleSeen,
  getHoldingDeposit,
  requestHoldingDeposit,
  confirmDeposit,
  delCer,
  paywithordo,
  buyCer,
  getIdentityData,
  saveIdentityData,
  getTdsData,
  saveMoveinData,
  confirmGaurantor,
  uploadManualAst,
  acceptMoveinApi,
  uploadRefDocs,
  uploadTenantRef,
  getFccRequestedReferenceDocs,
  searchDeposit,
  downloadVerifiedDocuments,
  deleteDoc,
  deleteRefDoc,
  searchAddress,
  requestLoan,
  uploadRightToRentDocs,
  zopaFollowup,
  getPackedge,
  getInsuranceClicked,
  getTiles,
  getBroadband,
  getPackages,
  getIFrameURL,
  payWithVoucher,
  getInsurancePurchase,
  getRewardsSubsStatus,
  payWithVoucher,
  getRewardsUrl,
  viewOpenFlowDepositCertificate,
  toggleSeenAll
};
