import React, { useState } from "react";
import Header from "../../commonComponents/headerComponent/headerComponent";
import Footer from "../../commonComponents/footerComponent/customFooter";
import {
  View,
  Text,
  Dimensions,
  SafeAreaView,
  Image,
  TouchableOpacity,
  Platform,
  ScrollView,
  FlatList,
  TextInput,
  StyleSheet,
} from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { Entypo } from "@expo/vector-icons";
import { getCcjText } from "./constants";
import { MainSection, Section1, Section2 } from "./preReferencingStyle";
import { MainTitle } from "./style";
import CardComponent from "./Components/cardComponent";
import { ContainerScrollView } from "../../helpers/appStyles";
import SkeltonComponents from "./Components/skeltonComponents";
import { useSelector } from "react-redux";

const Complete = (props) => {
  const preFilledData = useSelector(
    (state) => state?.preReferencing?.ccqData?.affordibilityCheck
  );
  const ccqProps = useSelector((state) => state?.preReferencing?.ccqData);

  const [Affordability, setAffordablity] = useState(null);
  const [monthlyIncome, setMonthlyIncome] = useState(333000);
  const [isSubmit, setIsSubmit] = useState(false);
  const [amount, setAmount] = useState(null);

  return (
    <MainSection style={{ marginBottom: 0 }}>
      <Section1>
        <MainTitle style={{ marginTop: 10, textAlign: "left" }}>
          Lite Reference
        </MainTitle>
        <SkeltonComponents
          type={"empty"}
          renderBody={() => {
            return (
              <>
                <Text>Here is the overview of your Lite Reference.</Text>
              </>
            );
          }}
        />

        <SkeltonComponents
          type={"empty"}
          renderBody={() => {
            return (
              <>
                <Text>Self-certified gross Yearly income</Text>
                <View
                  style={{
                    flexDirection: "row",
                    marginTop: "5%",
                    alignItems: "center",
                  }}
                >
                  <Text style={{ color: "#E71E82" }}>Amount £</Text>
                  <Text
                    style={{
                      width: "60%",
                      margin: 12,
                      borderBottomWidth: 1,
                      borderColor: "#E71E82",
                    }}
                    value={"sss"}
                  >
                    {preFilledData?.salary}
                  </Text>
                </View>
              </>
            );
          }}
        />
      </Section1>
      <Section2>
        <>
          {ccqProps?.buttons?.ccj_text ? (
            <SkeltonComponents
              type={"empty"}
              renderBody={() => {
                return (
                  <>
                    <View style={{ flexDirection: "row", marginBottom: "6%" }}>
                      <View
                        style={{
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "flex-start",
                          flex: 0.3,
                        }}
                      >
                        <Text style={{ color: "black", fontWeight: "bold" }}>
                          CCJ
                        </Text>
                      </View>
                      <View
                        style={{
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "flex-end",
                          flex: 0.7,
                        }}
                      >
                        <TouchableOpacity
                          style={{
                            height: 24,
                            justifyContent: "center",
                            alignItems: "center",
                            width: "70%",
                            borderRadius: 25,
                            backgroundColor: ccqProps?.buttons?.ccj_color
                              ? ccqProps?.buttons?.ccj_color
                              : "#FC4850",
                          }}
                        >
                          <Text style={{ color: "white" }}>
                            {ccqProps?.buttons?.ccj_text}
                          </Text>
                        </TouchableOpacity>
                      </View>
                    </View>

                    <Text>{getCcjText(ccqProps?.buttons?.ccj_text)}</Text>
                  </>
                );
              }}
            />
          ) : null}
          <SkeltonComponents
            type={"empty"}
            style={{ marginBottom: 20 }}
            renderBody={() => {
              return (
                <>
                  <View style={{ flexDirection: "row", marginBottom: "6%" }}>
                    <View
                      style={{
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flex: 0.3,
                      }}
                    >
                      <Text style={{ color: "black", fontWeight: "bold" }}>
                        Affordability
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-end",
                        flex: 0.7,
                      }}
                    >
                      <TouchableOpacity
                        onPress={() => {
                          setAffordablity(true);
                        }}
                        style={{
                          height: 24,
                          justifyContent: "center",
                          alignItems: "center",
                          width: "70%",
                          borderRadius: 25,
                          backgroundColor: ccqProps?.buttons?.affodability_color
                            ? ccqProps?.buttons?.affodability_color
                            : "#00B401",
                        }}
                      >
                        <Text style={{ color: "white" }}>
                          {ccqProps?.buttons?.affodability_text}
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </View>

                  <Text>
                    Affordability criteria is determined by each individual
                    agent. From the information provided we would suggest you
                    speak to an estate agent to discuss your affordability From
                    your declared monthly income amount, the normal affordable
                    rental amount would be:
                  </Text>
                </>
              );
            }}
          />
        </>
      </Section2>
    </MainSection>
  );
};
export default Complete;
