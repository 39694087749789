// @ts-nocheck
import { applyMiddleware, createStore, Platform } from "redux";
import { createLogger } from "redux-logger";
import { persistReducer, persistStore } from "redux-persist";
import storageLocal from "./localStorage";
import thunk from "redux-thunk";
import rootReducer from "../reducers";
import whitelist from "./whitelist";

export const storeObj = {};
const persistConfig = {
  timeout: 1000,
  key: "root",
  whitelist,
  storage: storageLocal,
};

const middlewares = [];

if (__DEV__) {
  middlewares.push(createLogger());
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  persistedReducer,
  undefined,
  applyMiddleware(...middlewares, ...[thunk])
);

export const persistor = persistStore(store, {}, () => {
  return store;
});
