import { useDispatch, useSelector } from 'react-redux';
import { Entypo, FontAwesome } from '@expo/vector-icons';
import React, { Fragment, useEffect, useState } from 'react';
import { FlatList, Image, ImageBackground, Text, TouchableOpacity, View } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import getPlatformType from '../../helpers/getPlatform';
import { CardBig, CardContainer, RowContainer } from './styles';
import BackGroundGenerator from '../LandingPages/common/BackGroundGenerator';
import BackButton from '../../commonComponents/BackButton';
import { Typography } from '../openFlow/style';
import { COLORS } from '../../constants/colorPallete';
import { AntDesign } from '@expo/vector-icons';
import WideButton from '../../commonComponents/wideButton';
import { tenantActions } from '../openFlow/openFlowRedux/tenant.actions';
import { openUrlHelpers } from '../../helpers/openDocHelpers';
import { Feather } from '@expo/vector-icons';
import { getProfile } from '../../redux/actions/auth';
import moment from 'moment';
import { navigateAction } from '../../helpers/navigationHelpers';

const TenantInsurance = (props) => {
  const dispatch = useDispatch();
  const layoutType = getPlatformType();
  const authDataFromMobile = useSelector((state) => state?.appReducer?.mobileData?.data?.isWebView);
  const profile_data = useSelector((state) => state?.authReducer);
  const [tlyfeID,setTlyfeId]=useState('');
  const [openInsuranceDoc, setOpenInsuranceDoc] = useState(false);
  const [insurance_data,setInsuranceData]=useState(null);
  useEffect(()=>{
    dispatch(getProfile());
    dispatch(tenantActions.getInsuranceDocs((data)=>{
     data && setInsuranceData(data[0])
    }))
  },[])
  useEffect(()=>{
    if(profile_data?.profile){
      setTlyfeId(profile_data?.profile?.data?.appUser?.opbrix_id)
    }
  },[profile_data])


  return (
    <BackGroundGenerator isTLYF props={props}>
      <ImageBackground
        source={require('../../assets/images/tenantInsurance.png')}
        style={{
          width: '100%',
          height: '270px',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
        resizeMode="cover"
      >
        {authDataFromMobile ? null : (
          <BackButton
            nativeBack={true}
            replace={'Dashboard'}
            navigation={props?.navigation}
            styles={{ marginTop: 20 }}
          />
        )}
      </ImageBackground>
      <View
        style={{
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'center',
          position: 'absolute',
          flexWrap: 'wrap',
          top: 130,
        }}
      >
        <View
          style={{
            width:layoutType=="phone"?'95%':475,
            background: '#ffffff',
            boxShadow: '1px 3px 8px rgba(0, 0, 0, 0.15)',
            borderRadius: 8,
            minHeight:350,
            margin: 15,
          }}
        >
          <Image
            source={require('./images/contentInsurance.png')}
            style={{
              width: '100%',
              height: layoutType=="phone"?100:140,
              resizeMode: 'cover',
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
            }}
          />
        {insurance_data?.policy_documents?.length?<View style={{ paddingHorizontal: 25, paddingVertical: 20 }}>
          <Typography
            top={'10px'}
            bold={'400'}
            color={'#000000'}
            align={'justify'}
            size={'18px'}
          >{`Check out our insurance options below for more peace of mind during your tenancy.
      `}</Typography>
          <Typography top={'10px'} bold={'400'} color={'#000000'} align={'justify'} size={'18px'}>
            Any insurance docs will be stored below and you can share them on{' '}
          <Typography
              top={'10px'}
              bold={'600'}
              underline
              color={COLORS.LGREEN}
              align={'justify'}
              size={'18px'}
              onPress={()=>navigateAction(props?.navigation, 'Document Share Center')}
            >
              My Documents and Share Centre.
            </Typography>{' '}
          </Typography>
          <Typography color={COLORS.LGREEN} align={'left'} top={15} size={'18px'}>
            Policy Number: <Typography color={'#000000'} size={18} left={10}>{insurance_data?.policy_id}</Typography>
          </Typography>
          <Typography  align={'left'} top={15}  color={COLORS.LGREEN} size={'18px'}>
            Expiry Date: <Typography color={'#000000'} size={18} left={10}>{moment(insurance_data?.renewal_date).format('DD-MM-YYYY')}</Typography>
          </Typography>
          <Image
                source={require('../../assets/images/fccparagon.png')}
                style={{ width: 130, height: 40,marginTop:20 }}
              />
          <View
            style={{
              width: layoutType=="phone"?"100%":'100%',
              paddingHorizontal: 20,
              minHeight: 50,
              borderWidth: 1,
              borderColor: '#B2B2B2',
              borderRadius: 8,
              marginTop: 40,
              alignSelf:layoutType=="phone"?'center' :'flex-end',
            }}
          >
            <View
              style={{
                justifyContent: 'space-between',
                flexDirection: 'row',
                alignItems: 'center',
                height: 50,
                borderBottomWidth: openInsuranceDoc ? 1 : 0,
                borderBottomColor: '#B2B2B2',
              }}
            >
              <Typography color={COLORS.LGREEN} >
                My Insurance Docs
              </Typography>
              <TouchableOpacity onPress={() => setOpenInsuranceDoc(!openInsuranceDoc)}>
                <Feather
                  name={openInsuranceDoc ? 'chevron-up' : 'chevron-down'}
                  size={24}
                  color={COLORS.LGREEN}
                />
              </TouchableOpacity>
            </View>
            {openInsuranceDoc && <FlatList
            data={insurance_data?.policy_documents}
            keyExtractor={(item,index) => index}
            renderItem={({item,index})=><TouchableOpacity onPress={()=>window.open(item?.document)} style={{ borderBottomWidth:insurance_data?.policy_documents?.length==index+1?0:1,
              borderBottomColor: '#B2B2B2'}}>
              <Typography color={COLORS.LGREEN} align={'left'} top={20} underline bottom={20}>{item?.documentType}</Typography>
            </TouchableOpacity>}
            />}
            </View>
           {insurance_data?.renew_url && <TouchableOpacity onPress={()=>window.open(insurance_data?.renew_url)} style={{width:120,height:40,borderColor:'red',borderWidth:1,marginTop:20,borderRadius:100,justifyContent:'center',alignItems:'center'}}>
              <Typography color={'red'} bold={'400'}>Renew</Typography>
            </TouchableOpacity>}
        </View>:<View style={{ paddingHorizontal: 25, paddingVertical: 20 }}>
            <Typography
              top={'10px'}
              bold={'800'}
              color={'#000000'}
              align={'justify'}
              size={'16px'}
            >{`Could you afford to replace your possessions if they were stolen or destroyed by flood or fire?`}</Typography>
            <Typography top={'15px'} bold={'400'} color={'#000000'} align={'justify'} size={'16px'}>
              Brought to you by tlyfe and FCC Paragon.
            </Typography>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: 20,
              }}
            >
              <Image
                source={require('../../assets/images/openflow/homelogo.png')}
                resizeMode='contain'
                style={{ width: 130, height: 40, alignSelf: 'flex-end' }}
              />
              <WideButton
                childText={'Learn more'}
                buttonType={'stripe'}
                isLGreen
                backgroundColor={COLORS.LGREEN}
                width={layoutType=="phone"?'130px':'250px'}
                onPress={() => {
                  dispatch(tenantActions.getInsuranceClicked());
                  openUrlHelpers(
                    "https://quotes.paragonsecure.com/sales/home/"
                  );
                }}
              />
            </View>
          </View>}
        </View>
        <View
          style={{
            width:layoutType=="phone"?'95%':475,
            background: '#ffffff',
            boxShadow: '1px 3px 8px rgba(0, 0, 0, 0.15)',
            borderRadius: 8,
            margin: 15,
            height:350
          }}
        >
          <Image
            source={require('./images/petInsurance.png')}
            style={{
              width: '100%',
              height: layoutType=="phone"?100:140,
              resizeMode: 'cover',
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
            }}
          />
          <View style={{ paddingHorizontal: 25, paddingVertical: 20 }}>
           
            <Typography top={'15px'} bold={'400'} color={'#000000'} align={'justify'} size={'16px'}>
            Coming Soon! We are in the process of partnering with a market leading pet insurance company, to get you the best pet insurance cover. We will keep you updated. Please come back later.
            </Typography>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: 20,
              }}
            >
              {/* <Image
                source={require('../../assets/images/fccparagon.png')}
                style={{ width: 130, height: 40, alignSelf: 'flex-end' }}
              /> */}
              <WideButton
                childText={'Coming soon'}
                buttonType={'stripe'}
                isLGreen
                backgroundColor={COLORS.GREY1}
                width={layoutType=="phone"?'130px':'250px'}
                onPress={() => {
                  // dispatch(tenantActions.getInsuranceClicked());
                  // openUrlHelpers(
                  //   'https://portal.paragonsecure.com/mailings/clickthrough.cfm?clickID=50A071BA-BDBD-ACC5-9CDB416F2BD30CBB'
                  // );
                }}
              />
            </View>
          </View>
        </View>
      </View>
    </BackGroundGenerator>
  );
};
export default TenantInsurance;
