import React, { useCallback, useEffect, useRef, useState } from "react";
import { Dimensions, ImageBackground, View } from "react-native";
import stylesMobile from "./stylesMobile";
import stylesTab from "./stylesTab";
import stylesWeb from "./stylesWeb";
import { backgroundSearchArr } from "../images/tlyfe/searchArrayTlyfe";
import BackGroundGenerator from "../common/BackGroundGenerator";
import { navigateAction } from "../../../helpers/navigationHelpers";
import { useSelector } from "react-redux";
import getPlatformType from "../../../helpers/getPlatform";
import CommonAuth from "./CommonAuth";
import ImageView from "./imageView";

const LandingInsurance = (props) => {
  const scollToRef = useRef();
  const FEATURED = [
    {
      heading2: "Could you afford to replace your possessions if they were stolen or destroyed by flood or fire?",
      subheading:
        "Our Tenant Contents Insurance policy will protect you against loss or damage to your belongings, for insurable perils such as fire, theft, escape of water and much more. In addition, the policy will automatically provide cover of up to £10,000 for accidental damage to your Landlord’s fixtures and fittings which you may be responsible for under the terms of your tenancy agreement.",
      imageUrl: require("./images/insurance/ins.png"),
    },
    {
      heading: "Features and Benefits",
      
      list: [
        "Tenants liability cover up to £5,000 - This provides cover for accidental damage to landlords fixtures and fittings which you may be responsible for as noted in your tenancy agreement.",
        "Legal Liability cover up to £2,000,000",
        "Personal money cover up to £1,000",
        "Credit cards cover up to £500",
        "Freezer contents cover up to £1,000",
        "Key and lock replacement up to £1000",
        "Alternative accommodation cover up to £3000",
        "Optional accidental damage cover",
        "Option to cover items away from the home (Personal Possessions)",
        "Optional pedal cycle cover - Maximum 5 cycles, maximum limit £750 for any one bicycle",
        "'New for old' cover",
        "24 hour, 365 days a year claims helpline",
      ],
      imageUrl: require("./images/insurance/check.png"),
    },
    {
      heading2: "Did you know?",
      subheading:
        "For a small additional premium you can also add optional extras to your policy such as Personal Possessions cover. This means that the items you take outside your home and carry with you everyday would be covered if they were lost, damaged or stolen anywhere in the world.",
      imageUrl: require("./images/insurance/know.png"),
      onButtonClick: () => {
        scollToRef.current.scrollIntoView({ behavior: "smooth" });
      },
      buttonText: "CLICK HERE TO REGISTER/LOGIN",
      buttonStyle: { marginTop: 30 },
    },
  ];
  const scrollViewRef = useRef();
  const [type, setType] = useState("web");
  const layoutType = getPlatformType();
  const isAnylyticsEnable = useSelector(
    (state) => state?.cookiesReducer?.customiseCookies.Analytics,
  );
  const { navigate } = props.navigation;
  const navigateTo = (params, from) => {
    if (from) {
      navigateAction(props?.navigation, params, from);
    } else {
      navigateAction(props?.navigation, params);
    }
  };
  const [property_for, setPropertyFor] = useState(1);
  const styles = type == "web" ? stylesWeb : type == "mobile" ? stylesMobile : stylesTab;
  const [state, setState] = useState({
    from: "",
  });
  const handleScrollButtonClick = useCallback(() => {
    const height=Dimensions.get('window').height;
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTo({ y:layoutType=='web'?height:height/2, animated: true });
    }
  }, []);
  useEffect(() => {
    if (props?.route?.params?.from) {
      setState({
        ...state,
        from: props?.route?.params?.from ? props?.route?.params?.from : "",
      });
    }
  }, [props?.route?.params?.from]);
  console.log("type", type);
  let BottomWrapper = type == "web" ? ImageBackground : View;
  return (
    <>

      <BackGroundGenerator
        props={props}
        layoutType={(data) => setType(data)}
        backgroundArr={backgroundSearchArr}
        isTLYF={true}
        isLanding
        cloudHeader={true}
        scrollViewRef={scrollViewRef}
      >
        <div
          ref={scollToRef}
        />

        <CommonAuth
          heading={"TENANT INSURANCE WITH TLYFE"}
          navigation={navigate}
          onScrollButtonClick={handleScrollButtonClick}
          type={type}
          isTLYF={true}
          subheading={"Get a good nights sleep knowing your valuables are protected with rental insurance!.".toUpperCase()}
          desc={""}
          hideButton={false}
          {...props}
        />
        {FEATURED.map((item, index) => {
          return (
            <ImageView
              key={index}
              reverse={index % 2 === 0 ? false : true}
              type={type}
              heading={item.heading}
              heading2={item.heading2}
              bColor={index % 2 === 0 ? "white" : "#FAFAFB"}
              subheading={item.subheading}
              imageUrl={item.imageUrl}
              onButtonClick={item?.onButtonClick}
              buttonText={item?.buttonText}
              buttonStyle={item?.buttonStyle ? {
                ...item.buttonStyle,
                marginBottom: type != "web" ? 400 : 250,
              } : {}}
              isTLYF={true}
              list={item?.list ? item.list : []}

            />
          );
        })}

      </BackGroundGenerator>
    </>
  );
};
export default LandingInsurance;
