import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, TouchableOpacity, View, Image } from 'react-native';
import variables from '../atoms/variables.json';
import getPlatformType from '../../../helpers/getPlatform';
import { MaterialIcons } from '@expo/vector-icons';
import { MainSection, Typography } from '../style';
import { useDispatch, useSelector } from 'react-redux';
import { tenantActions } from './../openFlowRedux/tenant.actions';
import BackGroundGenerator from '../../LandingPages/common/BackGroundGenerator';
import WideButton from '../../../commonComponents/wideButton';
import { BoxShadow } from '../../tds/style';
import { COLORS } from '../../../constants/colorPallete';
import moment from 'moment';
import { formatDate } from '../../../helpers/functionHelpers';
import { TabContainer } from '../../idVerification/styles';
import FilePicker from '../../../commonComponents/filePicker/filepicker';
import { ODSText } from '../../../../openbrix-design-system/ui/atoms/ODSText';
import ODSTabs from '../../../../openbrix-design-system/ui/atoms/ODSTabs';
import { getTokens } from '../../../../openbrix-design-system/design-tokens/color-tokens';
import ODSdocumentCard from '../../../../openbrix-design-system/ui/molecules/propertyBox/ODSdocumentCard';
import ODSButton from '../../../../openbrix-design-system/ui/atoms/ODSButton';

const layoutType = getPlatformType();
const howToRentGuide = 'https://assets.publishing.service.gov.uk/media/65159e567c2c4a001395e1ea/DLUHC_How_to_rent_Oct2023.pdf';
const yotiSignatureBaseURL = 'https://www.yotisign.com/sign/';

const ReviewAST = (props) => {
  const userId = useSelector((state) => state.authReducer?.profile?.data?.appUser?.app_user_id);
  const user = useSelector((state) => state.authReducer?.profile?.data);
  const dispatch = useDispatch();
  const [openFlow, setOpenFlow] = React.useState({});
  const [rentalOffer, setRentalOffer] = React.useState({});
  const [selected, setSelected] = React.useState('ast');
  const [signAstStatus, setSignAstStatus] = React.useState([]);
  const [parsedBespokeContracts, setParsedBespokeContracts] = React.useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const getCurrentTenant = () => {
    return openFlow?.tenants?.find((tenant) => tenant.app_user_id == userId);
  };

  useEffect(() => {
    getOpenflowData();
  }, [props?.route?.params?.id]);
  const getOpenflowData = () => {
    if (props?.route?.params?.id) {
      dispatch(
        tenantActions.getOpenflowData(props?.route?.params?.id, (openflow) => {
          if (openflow) {
            setOpenFlow(openflow);
            setRentalOffer(openflow.open_flow_rental_offer);
            if (openflow.is_ast_generated) {
              setSelected('ast');
            } else if (openflow.is_nha_generated) {
              setSelected('nha');
            } else if (openflow.bespoke_contract_url) {
              setParsedBespokeContracts(JSON.parse(openflow.bespoke_contract_url));
              setSelected('bespoke');
            } else {
              setSelected('ast');
              setParsedBespokeContracts([]);
            }
          }
        })
      );
      dispatch(tenantActions.getSignAstStatus(props?.route?.params?.id, getSignAstStatusCallBack));
    }
  };
  const getContractName = () => {
    if (selected == 'ast') {
      return 'Assured Shorthold Tenancy (AST)';
    } else if (selected == 'nha') {
      return 'Non Housing Act (NHA)';
    } else if (openFlow?.bespoke_contract_url) {
      return 'Bespoke';
    }
  };
  const getSignAstStatusCallBack = (response) => {
    console.log('asdasd', response);
    if (response && response?.details) {
      console.log('asdasd', response?.details?.recipients);
      setSignAstStatus(response?.details?.recipients);
    }
  };

  const getAgentName = () => {
    return `${openFlow?.branch?.name}, ${openFlow?.branch?.company?.name}`;
  };

  const openCertificate = () => {
    if (!rentalOffer?.is_seen_howtorent) {
      dispatch(
        tenantActions.toggleSeen(
          null,
          props?.route?.params?.id,
          () => {
            getOpenflowData();
          },
          true
        )
      );
      //   dispatch(viewOpenFlowCertificate(howToRentGuide, props?.route?.params?.openflow_id));
    }
    window.open(howToRentGuide, '_blank', 'noreferrer');
  };

  const renderContracts = () => {
    if (selected == 'ast') {
      return renderASTContract();
    } else if (selected == 'nha') {
      return renderNHAContract();
    } else if (openFlow.bespoke_contract_url) {
      return renderBespokeContract();
    }
  };

  const renderNHAContract = (item, index) => {
    return (
      <ODSdocumentCard
        title={`Tenancy Agreement`}
        description={formatDate(openFlow?.createdAt)}
        onPress={() => {
          window.open(openFlow?.nha_contract_url, '_blank', 'noreferrer');
        }}
        is_seen={rentalOffer?.is_seen_howtorent}
        style={{ marginTop: 30 }}
      />
    );
  };
  const renderBespokeContract = (item, index) => {
    return parsedBespokeContracts.map((bespokeContract, index) => {
      return (
        <ODSdocumentCard
          title={`Tenancy Agreement ${index + 1}`}
          description={formatDate(openFlow?.createdAt)}
          onPress={() => {
            window.open(bespokeContract[0], '_blank', 'noreferrer');
          }}
          is_seen={rentalOffer?.is_seen_howtorent}
          style={{ marginTop: 30 }}
        />
      );
    });
  };
  const renderASTContract = (item, index) => {
    const certificates = 1;
    return (
      <>
        <ODSdocumentCard
          title={'Prescribed Info'}
          description={formatDate(openFlow?.createdAt)}
          onPress={() => {
            window.open(openFlow?.prescribed_contract_url, '_blank', 'noreferrer');
          }}
          is_seen={rentalOffer?.is_seen_howtorent}
          style={{ marginTop: 30 }}
        />
        <ODSdocumentCard
          title={'Tenancy Agreement'}
          description={formatDate(openFlow?.createdAt)}
          onPress={() => {
            window.open(openFlow?.ast_contract_url, '_blank', 'noreferrer');
          }}
          is_seen={rentalOffer?.is_seen_howtorent}
          style={{ marginTop: 30 }}
        />
      </>
    );
  };
  const renderCertificates = (item, index) => {
    const certificates = 1;
    return (
      <ODSdocumentCard
        title={'How to Rent Guide'}
        description={formatDate(openFlow?.createdAt)}
        onPress={() => {
          openCertificate();
        }}
        is_seen={rentalOffer?.is_seen_howtorent}
        style={{ marginTop: 30 }}
      />
    );
  };
  const getContractSentAt = () => {
    const currentTenant = getCurrentTenant();
    if (currentTenant) {
      if (currentTenant.contract_sent_at) {
        return moment(currentTenant.contract_sent_at).format('Do MMM YYYY');
      } else if (currentTenant.ast_sent_at) {
        return moment(currentTenant.ast_sent_at).format('Do MMM YYYY');
      } else {
        return 'N/A';
      }
    } else {
      return 'N/A';
    }
  };
  const getInitialRentalAmount = () => {
    if (openFlow.open_flow_rental_offer && openFlow.open_flow_rental_offer?.initial_rental_amount) {
      return openFlow.open_flow_rental_offer?.initial_rental_amount;
    } else {
      return 0;
    }
  };
  const getTenancyDepositAmount = () => {
    if (
      openFlow.open_flow_rental_offer &&
      openFlow.open_flow_rental_offer?.tenancy_deposit_amount
    ) {
      return openFlow.open_flow_rental_offer?.tenancy_deposit_amount;
    } else {
      return 0;
    }
  };

  const getHoldingDeposit = () => {
    if (
      openFlow.open_flow_holding_deposit &&
      openFlow.open_flow_holding_deposit?.holding_desposit_amount &&
      openFlow.open_flow_holding_deposit?.is_holding_deposit_selected
    ) {
      return openFlow.open_flow_holding_deposit.holding_desposit_amount;
    } else {
      return 0;
    }
  };
  const getTotalAmount = () => {
    const total = getInitialRentalAmount() + getTenancyDepositAmount() - getHoldingDeposit();
    return total.toFixed(2);
  };

  const getMoveInMoneyDueFromYou = () => {
    if (openFlow?.move_in_money_from_lead_tenant) {
      const currentTenant = getCurrentTenant();
      if (currentTenant?.is_lead) {
        return getTotalAmount();
      } else {
        return 0;
      }
    } else {
      return getAmountPerApplicant();
    }
  };
  const getAmountPerApplicant = () => {
    const total = getTotalAmount();
    if (openFlow?.tenants?.length > 0) {
      return (total / openFlow?.tenants?.length).toFixed(2);
    } else {
      return total;
    }
  };

  const renderDetailsCard = () => {
    const currentTenant = getCurrentTenant();
    return (
      <View style={styles.paddingStyle}>
        <ODSText marginBottom={10} type={'h5'}>
          Tenancy Agreement
        </ODSText>

        <View style={[styles.rowFlexView]}>
          <View style={styles.rowItem}>
            <ODSText type={'button'}>Date sent on</ODSText>
            <ODSText marginTop={8} type={'sm'}>
              {getContractSentAt()}
            </ODSText>
          </View>
        </View>
        <View style={[styles.rowFlexView]}>
          <View style={styles.rowItem}>
            <ODSText type={'button'}>Agent</ODSText>
            <ODSText marginTop={8} type={'sm'}>
              {getAgentName()}
            </ODSText>
          </View>
        </View>
        <ODSText marginBottom={10} marginTop={10} type={'h5'}>
          Move in Monies
        </ODSText>
        <View style={[styles.rowFlexView]}>
          <View style={styles.rowItem}>
            <ODSText type={'button'}>Date sent on</ODSText>
            <ODSText marginTop={8} type={'sm'}>
              {currentTenant?.movein_money_sent_at
                ? moment(currentTenant?.movein_money_sent_at).format('Do MMM YYYY')
                : 'N/A'}
            </ODSText>
          </View>
        </View>
        <View style={[styles.rowFlexView]}>
          <View style={styles.rowItem}>
            <ODSText type={'button'}>Total Amount</ODSText>
            <ODSText marginTop={8} type={'sm'}>
              £ {getMoveInMoneyDueFromYou()}
            </ODSText>
          </View>
        </View>
        <View style={[styles.rowFlexView]}>
          <View style={styles.rowItem}>
            <ODSText type={'button'}>Rent amount per Applicant</ODSText>
            <ODSText marginTop={8} type={'sm'}>
              £ {getAmountPerApplicant()}
            </ODSText>
          </View>
        </View>
      </View>
    );
  };

  const openEsignatureUrl = () => {
    const currentTenant = getCurrentTenant();
    const esignatureURL = `${yotiSignatureBaseURL}${currentTenant.envtoken}`;
    window.open(esignatureURL, '_blank', 'noreferrer');
  };

  const renderStatus = () => {
    const user1 = signAstStatus.find((item) => item.email == user?.email);
    console.log('user1', user1);
    console.log('signAstStatus', signAstStatus);
    console.log('user', user);

    if (user1) {
      if (user1.sign_status == 'UNSIGNED') {
        return (
          <View
            style={{
              flexDirection: 'column',
              width: layoutType == 'web' ? 400 : '100%',
              alignSelf: 'center',
            }}
          >
            <TouchableOpacity
              activeOpacity={0.9}
              style={{
                borderRadius: 6,
                paddingVertical: 10,
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: COLORS.YELLOW,

                width: '70%',
                marginBottom: 16,
                alignSelf: 'center',
              }}
            >
              <ODSText type={'sm'}>e-sign requested</ODSText>
            </TouchableOpacity>
            <ODSButton
              restStyle={{ width: '100%' }}
              type="primary"
              disabled={false}
              onPress={() => {
                openEsignatureUrl();
              }}
            >
              ESIGN AGREEMENT
            </ODSButton>
          </View>
        );
      } else {
        return (
          <View
            style={{
              flexDirection: 'column',
              width: layoutType == 'web' ? 400 : '100%',
              alignSelf: 'center',
            }}
          >
            <ODSButton
              restStyle={{ width: '100%' }}
              type="primary"
              disabled={false}
              onPress={() => {}}
            >
              AGREEMENT SIGNED
            </ODSButton>
          </View>
        );
      }
    }
  };
  const themeObj = getTokens('light').colors;
  const TabView = () => {
    return (
      <ODSTabs
        items={['Tenancy Agreement', ' Upload Agreement']}
        activeTab={selectedIndex}
        selectedColor={themeObj.main.blue[700]}
        color={themeObj.main.blue[500]}
        onTabChange={(val) => setSelectedIndex(val)}
        restStyle={{
          borderRadius: 0,
          borderTopEndRadius: 8,
          borderTopStartRadius: 8,
          minHeight: 68,
          marginBottom: 20,
        }}
      />
    );
  };
  const [fileName, setFileName] = useState('');
  const [file, setFile] = useState('');

  const deleteUplodedDoc = () => {
    dispatch(
      tenantActions.deleteDoc(openFlow?.openflow_id, () => {
        setFile('');
        setFileName('');
        getOpenflowData();
      })
    );
  };
  const renderUploadAst = () => {
    const currentTenant = getCurrentTenant();
    return (
      <View style={styles.paddingStyle}>
         <ODSText marginBottom={20} type={'h5'}>
          Tenancy Agreement
        </ODSText>
        <ODSText marginBottom={10} type={'sm'}>
        If you have chosen not to use the digital signature then please upload your signed tenancy agreement here.
        </ODSText>
        {currentTenant?.uploaded_ast_contract_url ? (
          <>
                  <ODSdocumentCard
          title={'Tenancy Agreement'}
          description={""}
          is_seen={rentalOffer?.is_seen_howtorent}
          onDeletePress={deleteUplodedDoc}
          isDelete={true}
          onPress={() => {
            window.open(currentTenant?.uploaded_ast_contract_url, '_blank', 'noreferrer');
          }}
          style={{ marginTop: 30 }}
        />
           
          </>
        ) : (
          <>
            <View style={[styles.rowFlexView, { borderWidth: 0, marginTop: 1 }]}>
              <View
                style={{
                  width: '100%',
                  marginTop: 15,
                  flexDirection: 'row',
                  marginBottom: 25,
                }}
              >
                <FilePicker
                  fileName={fileName}
                  width={"100%"}
                  file={file}
                  onFileSelect={(val) => {
                    setFile(val.name);
                    setFileName(val.openbrixName);
                  }}
                />
              </View>
            </View>
            <View
          style={{
            flexDirection: 'column',
            width: layoutType == 'web' ? 400 : '100%',
            alignSelf: 'center',
          }}
        >
         {fileName? <ODSButton
            restStyle={{ width: '100%' }}
            type="primary"
            disabled={false}
            onPress={() =>
              dispatch(
                tenantActions.uploadManualAST(
                  { ast_url: fileName },
                  props?.route?.params?.id,
                  () => {
                    getOpenflowData();
                  }
                )
              )
            }
          >
          Confirm and upload
          </ODSButton>:null}
        </View>
          </>
        )}
      </View>
    );
  };
  const renderRentalForm = () => {
    return (
      <View style={styles.paddingStyle}>
        <ODSText marginBottom={20} type={'h5'}>
          {getContractName()}
        </ODSText>

        <ODSText marginBottom={10} type={'sm'}>
          Below are the documents that your agent has sent, relating to your tenancy.
        </ODSText>
        <ODSText marginBottom={10} type={'sm'}>
          You can choose to digitally sign it using the YOTI verified signature process or you can
          download the document, sign it and manually upload it.
        </ODSText>

        {renderCertificates()}
        {renderContracts()}

        <View style={styles.paddingStyle}>{renderStatus()}</View>
      </View>
    );
  };
  const renderTermsCard = () => {
    return (
      <>
        <TabView />
        {selectedIndex == 0 ? renderRentalForm() : null}
        {selectedIndex == 1 ? renderUploadAst() : null}
      </>
    );
  };

  return (
    <BackGroundGenerator
      props={props}
      isTLYF
      isOpenflowContainer
      openFlowData={{
        title: 'Tenancy Agreement',
        desc: 'Once you are happy to proceed, please click on the “sign” button to digitally sign your agreement.',
        buttonText: openFlow?.landlord_status,
        message: openFlow?.landlord_message,
        listIcon: 'agrement',
      }}
    >
      <MainSection
        style={{
          flexDirection: layoutType == 'phone' ? 'column' : 'row',
          justifyContent: 'space-between',
        }}
      >
        <View style={layoutType == 'phone' ? { width: '100%' } : { flex: 0.48 }}>
          <BoxShadow style={{ padding: 0 }}>{renderDetailsCard()}</BoxShadow>
        </View>
        <View
          style={layoutType == 'phone' ? { flex: 1, marginTop: 26, width: '100%' } : { flex: 0.48 }}
        >
          <BoxShadow style={{ padding: 0 }}>{renderTermsCard()}</BoxShadow>
        </View>
      </MainSection>
    </BackGroundGenerator>
  );
};
const styles = StyleSheet.create({
  paddingStyle: {
    padding: layoutType == 'phone' ? 12 : 20,
  },
  rowFlexView: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 10,
  },
  centeredView: {
    flex: 1,
    padding: 20,
    marginTop: 22,
    backgroundColor: 'white',
    borderRadius: 20,
  },
  whiteColor12Regular: { color: '#FFFFFF', fontSize: 12 },
  rowItem: { flexDirection: 'column', flex: 5, alignItems: 'flex-start' },
});
export default ReviewAST;
