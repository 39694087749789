import React from "react";
import styled from "styled-components/native";

export const Container = styled.View`
  flex: 1;
  background-color: #fff;
  align-items: center;
  justify-content: center;
`;
export const Title = styled.Text`
  text-align: center;
  font-size: 16px;
  font-weight: bold;
`;
export const TabName = styled.Text`
  font-size: 22px;
  font-weight: bold;
`;

