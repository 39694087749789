import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  headingText: {
    fontFamily: 'Lato',
    fontWeight: "700",
    fontSize: 28,
  },
  heading1: {
   fontFamily: 'Lato',
   fontWeight: "700",
   fontSize: 22,
 },
 heading2: {
   fontFamily: 'Lato',
   fontWeight: "700",
   fontSize: 19,
 },
 heading3: {
   fontFamily: 'Lato',
   fontWeight: "400",
   fontSize: 17,
 },
 TextCenter:{
     textAlign:"center"
 },
  container: {
    marginTop: 152,
  },
  headingWrapper: {
    alignSelf: 'center',
    alignItems:'center'
  },
  headingTextWrapper: {
    textAlign: 'center',
  },
  visionWrapper:{
   marginTop:251,
   alignItems:'center'
},
visionSubTextWrapper:{
   marginTop: 52,
   alignItems: 'center',
   justifyContent: 'center',
 },
 saperaterWrapper: {
   marginVertical: 50,
 },
 saperator: {
   height: 0.5,
   width:"100%",
   backgroundColor: '#6d6d6d',
 },
 valuesWrapper:{
   marginBottom: 85,
   alignItems:'center'
 },

sectionWrapper: {
   marginBottom: 49,
   alignItems:'center'
 },
 section1: {
   marginBottom: 28,
   textAlign:'center'
 },
 section2: {
   marginBottom: 36,
   textAlign:'center'
 },
 manifastoWrapper:{
   marginTop: 107,
   marginBottom: 88,
   alignItems:"center"
},
manifestoText:{
   textAlign:"center"
},
cardWrapper: {
   flexDirection: 'column',
   justifyContent: 'space-between',
   alignItems:'center',
   marginBottom: 113,
 },
 cardSection:{
    marginTop: 61
 },
 formWrapper: {
  paddingHorizontal: 20,
  paddingVertical: 20,
 },
  horizontal: {
    marginHorizontal: 14,
        backgroundColor:"white"
  },
});
