import React, { useState } from "react";
import {
  Image,
  TouchableOpacity,
  View,
  FlatList,
  Text,
  SafeAreaView,
} from "react-native";
import { MaterialIcons, FontAwesome } from "@expo/vector-icons";
import getPlatformType from "../../helpers/getPlatform";
import { navigateAction, popAction } from "../../helpers/navigationHelpers";
import WideButton from "../wideButton";
import { FilterConfigObject } from "./../../helpers/objectHelpers";
import DropDownPicker from "../../commonComponents/DropDownPicker";
import { AntDesign } from '@expo/vector-icons';
const { sort_by } = FilterConfigObject;

const layoutType = getPlatformType();
const ListHeader = (props) => {
  const { _onValue, navigation } = props;
  const [currentView, SetCurrentView] = useState("list");
  const _onPress = (value) => {
    _onValue(value);
  };

  return (
    <View
      style={{
        width: "100%",
        height: 50,
        paddingHorizontal: 30,
        flexDirection: "row",
        marginTop: 10,
        justifyContent: "space-between",
      }}
    >
      <WideButton
        width={"40%"}
        childText={"Create alert"}
        onPress={() => navigation.navigate("Create Alert", { alert_id: "" })}
      />

      <View
        style={{
          flexDirection: "row",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {props.showFilter && (
          <>
          <DropDownPicker
            items={sort_by}
            placeholder="Sort by"
            onChangeItem={(e) => {
              props.sortBy(e.value);
            }}
            defaultValue={props.sortValue}
            dropDownStyle={{ backgroundColor: "white" }}
            noBorder={true}
          />
          <TouchableOpacity
          onPress={props.filterButtonAction}
          style={{ marginLeft: 20}}
        >
          <AntDesign name="filter" size={22} color="black" />
        </TouchableOpacity>
        <View
          style={{
            width: 1,
            height: "40%",
            backgroundColor: "grey",
            marginLeft: 8,
            marginRight: 10,
          }}
        />
        </>
        )}

        <TouchableOpacity
          onPress={() => _onPress("list")}
        >
          <MaterialIcons
            name="format-list-bulleted"
            size={22}
            color={currentView == "list" ? "black" : "grey"}
          />
        </TouchableOpacity>
        <View
          style={{
            width: 1,
            height: "40%",
            backgroundColor: "grey",
            marginLeft: 8,
            marginRight: 10,
          }}
        />
        <TouchableOpacity onPress={() => _onPress("map")}>
          <FontAwesome
            name="map-o"
            size={20}
            color={currentView == "map" ? "black" : "grey"}
          />
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default ListHeader;
