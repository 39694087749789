import React from 'react';
import {
  TouchableOpacity,
  View,
  Image,
  Text,
  Dimensions,
  StyleSheet,
  Platform,
  Linking,
} from 'react-native';
import { COLORS } from '../../constants/colorPallete';
import { LinearGradient } from 'expo-linear-gradient';
import {
  FooterWrapper,
  Container,
  FooterLogo,
  FooterLinks1,
  FooterLinks2,
  SocialLinks,
  TextContent,
  SocialImg,
  SocialImgBox,
  ObxWhiteImg,
} from './footerStyle';
import * as Device from 'expo-device';
import { navigateAction, popAction } from '../../helpers/navigationHelpers';
import { Link } from '@react-navigation/native';
import getPlatformType from '../../helpers/getPlatform';
let deviceType = '';
const layoutType = getPlatformType();
const { height: WINDOW_HEIGHT, width: WINDOW_WIDTH } = Dimensions.get('window');
export default class FooterLanding extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount = async () => {
    deviceType = await Device.getDeviceTypeAsync();
  };

  _redirectSocial = (url) => {
    Linking.canOpenURL(url).then((supported) => {
      if (supported) {
        if (Platform.OS === 'web') {
          window.open(url);
        } else {
          Linking.openURL(url);
        }
      } else {
      }
    });
  };

  render() {
    const { navigation, isTLYF=true  } = this.props;

    return (
      <LinearGradient
        colors={isTLYF ? ['#A4D4A2', '#90CBCC'] :['#FBB1E6', '#FFB580']}
        style={{
          height: '100px',
          width: '100%',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        start={[0.25, 0.5]}
        end={[1, 1]}
      >
        <View style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 20 }}>
          <Link
              id={"FOOTOR_LOGO"}
            to={isTLYF ? '/home' : '/home'}
            style={{ width: layoutType == 'phone' ? 100 : 130 }}
          >
            {isTLYF ? (
              <ObxWhiteImg
                source={require('../../assets/images/tlyfeWhite.png')}
                resizeMode="contain"
              />
            ) : (
              <ObxWhiteImg
                source={require('../../assets/images/obxWhite.png')}
                resizeMode="contain"
              />
            )}
          </Link>
          <SocialLinks>
            <SocialImgBox
              id={"FOOTOR_LINKDIN"}
              onPress={() => this._redirectSocial('https://www.linkedin.com/company/openbrix/')}
            >
              <SocialImg
                source={require('../../assets/images/linkedInNew.png')}
                resizeMode="contain"
              />
            </SocialImgBox>
            <SocialImgBox
              id={"FOOTOR_MATA"}
              onPress={() => this._redirectSocial('https://www.facebook.com/openbrix/')}
            >
              <SocialImg
                source={require('../../assets/images/facebookBig.png')}
                resizeMode="contain"
              />
            </SocialImgBox>
            <SocialImgBox 
            id={"FOOTOR_OPENBRIX"}
            onPress={() => this._redirectSocial('https://twitter.com/OpenBrix')}>
              <SocialImg
                source={require('../../assets/images/twitterBig.png')}
                resizeMode="contain"
              />
            </SocialImgBox>
            <SocialImgBox
              id={"FOOTOR_INSTA"}
              onPress={() => this._redirectSocial('https://www.instagram.com/openbrix/')}
            >
              <SocialImg
                source={require('../../assets/images/instagramBig.png')}
                resizeMode="contain"
              />
            </SocialImgBox>
          </SocialLinks>
        </View>
        <View style={{ flexDirection: 'row', marginRight: 20 }}>
     

          <TextContent 
                id={"CONTACT_US"}
          onPress={() => navigateAction(navigation, 'Contact Us')}>
            Contact Us
          </TextContent>
          <TextContent 
           id={"MEDIA_HUB"}
          onPress={() => navigateAction(navigation, 'MediaHub')}>
            Info Hub
          </TextContent>

          <TextContent
           id={"PRIVACY_POLICY"}
          onPress={() => navigateAction(navigation, 'Legal Bits',{to:"policy"})}>
            Privacy Policy
          </TextContent>

          <TextContent 
           id={"TERMS_AND_CONTACT"}
          onPress={() => navigateAction(navigation, 'Legal Bits',{to:"t/c"})}>
            T &amp; C's
          </TextContent>
          <TextContent 
           id={"CHARITY"}
          onPress={() => navigateAction(navigation, 'Charity')}>Charity</TextContent>
        </View>
      </LinearGradient>
    );
  }
}
