import DatePicker from 'react-datepicker';
import React, { useEffect, useState } from 'react';
import './style.web.css';
import { StyleSheet, View, Text } from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import 'react-datepicker/dist/react-datepicker.css';
import moment from "moment"
import { ODSText } from '../ODSText';
import { useTheme } from '@emotion/react';
const DatePickerComponent = (props) => {
  const focus = useIsFocused();
  const [state, setState] = useState({
    disabled: props.disabled ? props.disabled : false,
    date: props.value ? props.value : null,
    placeholder: props.placeholder ?? '',
    property: props.property ?? 'date',
  });
  const theme = useTheme();
  const onChange = (date) => {
    setState({
      ...state,
      date,
    });
    props.onUpdateInput(date, state.property);
  };
  const getHintTextColor = (state) => {
    switch (state) {
      case 'disabled':
        return theme.colors.disabled.text;
      case 'error':
        return theme.colors.feedback.error[700];
      case 'success':
        return theme.colors.feedback.success[700];
      default:
        return theme.colors.text.secondary;
    }
  };
  const {hints} =props
  if (focus) {
    return (
      <View style={styles.input}>
        {props?.label ? (
          <ODSText
            type="sm-bold"
            color={props?.disabled ? theme.colors.disabled.text : theme.colors.text.primary}
            paddingVertical={4}
            marginBottom={6}
            >
            {props?.label}
          </ODSText>
        ) : (
          null
        )}
        <View
          style={{
            width: '100%',
            height: 40,
            borderWidth: 1,
            justifyContent: 'center',
            padding: 5,
            borderColor: '#d0d0d0', 
            borderRadius: 5,
            display: 'flex',
          }}
        >
          <DatePicker
            onChange={(data) => onChange(data)}
            selected={state.date}
            portalId="root-portal"
            dateFormat="dd/MM/yyyy"
            yearDropdownItemNumber={100}
            scrollableYearDropdown={true}
            showYearDropdown
            showMonthDropdown
            maxDate={props?.is18yrs ? new Date(moment().subtract(18, "years")) : null}
            disabled={state.disabled}
          />
        </View>
        {hints !== null &&
        hints !== undefined &&
        hints.map((item, index) => (
          <ODSText paddingVertical={10} type="xs" color={getHintTextColor(item.state)}>
            {item.hint}
          </ODSText>
        ))}
      </View>
    );
  } else {
    return <></>;
  }
};
const styles = StyleSheet.create({
  input: {
    width: '100%',
  },
  inputLabel: {
    fontSize: 15,
    color: '#000',
    fontWeight: 500,
    fontFamily: 'Lato',
    marginBottom: 10,
  },
});
export default DatePickerComponent;
