import React from "react";
import styled from "styled-components/native";
import getPlatformType from "../../helpers/getPlatform";

const layoutType = getPlatformType();
let Container;
let CenterPanal;
let RightPanal;
let LoginText;
let SignUpBtn;
if (layoutType == "phone") {
  CenterPanal = styled.View`
    background-color: transparent;
  `;
  Container = styled.View`
    width: 100%;
    margin: 0 auto;
    flex: 1;
  `;
  LoginText = styled.Text`
    color: rgb(235, 89, 54);
    font-weight: bold;
  `;
  SignUpBtn = styled.View`
    width: 100%;
    align-items: center;
  `;
} else if (layoutType == "web") {
  CenterPanal = styled.View`
    flex-grow: 5;
  `;
  Container = styled.View`
    margin: 12px auto 48px;
    width: 100%;
    padding: 0 150px;
    flex-direction: row;
  `;
  RightPanal = styled.View`
    flex-grow: 4;
    margin-left: 16px;
    align-items: flex-end;
  `;
  LoginText = styled.Text`
    color: rgb(235, 89, 54);
    font-weight: bold;
  `;
  SignUpBtn = styled.View`
    width: 132px;
  `;
} else {
  CenterPanal = styled.View`
    flex-grow: 7;
    padding-right: 15px;
    margin-top:100px;
  `;
  Container = styled.View`
    margin: 16px 37px;
    flex-direction: row;
  `;
  RightPanal = styled.View`
    flex-grow: 3;
  `;
  LoginText = styled.Text`
    color: rgb(235, 89, 54);
    font-weight: bold;
  `;
  SignUpBtn = styled.Text`
    width: 132px;
  `;
}
const LeftPanal = styled.View`
  flex-grow: 1;
  margin-right: 6px;
`;
const TextWrapper = styled.Text`
  height: 17px;
  color: #000000;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
  margin-bottom: 16px;
`;
const BackTextWrapper = styled.Text`
  height: 17px;
  color: #000000;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
`;

const TermsLinks = styled.Text`
  color: #eb5936;
  font-weight: bold;
`;

export {
  CenterPanal,
  LeftPanal,
  RightPanal,
  Container,
  TextWrapper,
  BackTextWrapper,
  LoginText,
  SignUpBtn,
  TermsLinks
};
