import React from "react";
import styled from "styled-components/native";
import getPlatformType from "../../helpers/getPlatform";
import { FlatList } from "react-native-gesture-handler";

const layoutType = getPlatformType();

let ShareComponentView, ShareComponentList, ShareComponentText, ShareComponentTitle;

ShareComponentView = styled.View`
    align-items: flex-start;
`;
ShareComponentList = styled.View`
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 5px 0px;
`;
ShareComponentText = styled.Text`
    margin-left: 10px;
    font-weight: 500;
`;
ShareComponentTitle = styled.Text`
    font-weight: 600;
    margin: 5px 0px;

`;

export {ShareComponentView, ShareComponentList, ShareComponentText, ShareComponentTitle}