import React, { useState } from 'react';

import {
  View,
  Text,
  Image,
  TouchableOpacity,
} from 'react-native';
import { Entypo } from '@expo/vector-icons';
import { MaterialIcons, FontAwesome } from '@expo/vector-icons';
import GoogleMaps from '../../commonComponents/MapViewComponent/mapView';

import idx from 'idx';

import { GreyText, BackTextWrapper } from './styles';
import secondary from '../../assets/images/secondary.png';
import independent from '../../assets/images/independent.png';
import primary from '../../assets/images/primary.png';

const School = (props) => {
  const { data, searchMapData } = props;
  const [currentView, SetCurrentView] = useState('list');
  const _onPress = (val) => {
    SetCurrentView(val);
  };
  const checkSchoolType = (schooltype, phase) => {
    if (schooltype == 'independent') {
      return independent;
    } else {
      if (phase == 'Primary') {
        return primary;
      } else {
        return secondary;
      }
    }
  };
  return (
    <>
      <BackTextWrapper onPress={() => props.ResetService()}>
        <Entypo
          name="chevron-left"
          size={18}
          color="#000"
        />{' '}
        Back
      </BackTextWrapper>

      <GreyText>School</GreyText>

      <View
        style={{
          width: '100%',
          minHeight: 60,

          flexDirection: 'row',
          borderWidth: 1,
          borderRadius: 7,
          borderColor: '#b4b4b4',
          marginTop: 10,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <View
          style={{
            flex: 0.5,
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          <Text
            style={{
              width: '60%',
              textAlign: 'left',
              marginLeft: 10,
              color: 'black',
              fontWeight: 'bold',
            }}
            id={"AVG_SCHOOL_ID"}
          >
            Average School Rating
          </Text>
        </View>
        <View style={{ width: 1, backgroundColor: 'grey', height: '80%' }} />
        <View
          style={{
            flex: 0.5,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Text style={{ color: 'black', fontWeight: 'bold' }}>{data?.rating}</Text>
        </View>
      </View>
      <View
        style={{
          width: '100%',
          minHeight: 60,

          flexDirection: 'row',
          borderWidth: 1,
          borderRadius: 7,
          borderColor: '#b4b4b4',
          marginTop: 10,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <View
          style={{
            flex: 0.5,
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          <Text
            style={{
              width: '60%',
              textAlign: 'left',
              marginLeft: 10,
              color: 'black',
              fontWeight: 'bold',
            }}
          >
            Average Score
          </Text>
        </View>
        <View style={{ width: 1, backgroundColor: 'grey', height: '80%' }} />
        <View
          style={{
            flex: 0.5,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Text style={{ color: 'black', fontWeight: 'bold' }}>{data?.average_score}</Text>
        </View>
      </View>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'flex-end',
          marginTop: 10,
        }}
      >
        <TouchableOpacity onPress={() => _onPress('list')}>
          <MaterialIcons
            name="format-list-bulleted"
            size={22}
            color={currentView == 'list' ? 'black' : 'grey'}
          />
        </TouchableOpacity>
        <View
          style={{
            width: 1,
            height: '100%',
            backgroundColor: 'grey',
            marginLeft: 8,
            marginRight: 10,
          }}
        />
        <TouchableOpacity onPress={() => _onPress('map')}>
          <FontAwesome name="map-o" size={20} color={currentView == 'map' ? 'black' : 'grey'} />
        </TouchableOpacity>
      </View>
      <View
        style={{
          width: '100%',
          minHeight: 60,

          flexDirection: 'column',
          borderWidth: 1,
          borderRadius: 7,
          borderColor: '#b4b4b4',
          marginTop: 10,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {data.schools && currentView == 'map' ? (
          <View
            style={{
              width: '100%',
            }}
          >
            <GoogleMaps
              data={[
                {
                  lat: idx(props.propertyData, (_) => _.location.coordinates[0]),
                  lng: idx(props.propertyData, (_) => _.location.coordinates[1]),
                },
              ]}
              schools={data.schools}
              transports={[]}
              searchObject={null}
            />
          </View>
        ) : (
          <>
            <View
              style={{
                width: '100%',
                flexDirection: 'row',
                marginTop: 20,
              }}
            >
              <View
                style={{
                  flex: 0.333,
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'row',
                }}
              >
                <Text
                  style={{
                    color: '#D81E6C',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    marginTop: '4%',
                  }}
                >
                  Type
                </Text>
              </View>
              <View style={{ width: 1, backgroundColor: 'grey', height: '80%' }} />
              <View
                style={{
                  flex: 0.333,
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingVertical: 20,
                }}
              >
                <Text
                  style={{
                    color: '#D81E6C',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    marginTop: '4%',
                  }}
                >
                  Name
                </Text>
              </View>
              <View style={{ width: 1, backgroundColor: 'grey', height: '80%' }} />
              <View
                style={{
                  flex: 0.333,
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingVertical: 20,
                }}
              >
                <Text
                  style={{
                    color: '#D81E6C',
                    fontWeight: 'bold',
                    textAlign: 'center',
                  }}
                >
                  Distance (miles)
                </Text>
              </View>
            </View>
            {data.schools &&
              data.schools.map((item, index) => (
                <View
                  key={index}
                  style={{
                    width: '100%',
                    flexDirection: 'row',
                    marginTop: 20,
                  }}
                >
                  <View
                    style={{
                      flex: 0.333,
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'row',
                    }}
                  >
                    {/* <Ionicons
                      name="md-school"
                      size={50}
                      color={item?.schoolType === "state" ? "green" : "#204375"}
                    /> */}
                    <Image
                      source={checkSchoolType(item?.schoolType, item?.phase)}
                      style={{ width: 60, height: 60 }}
                    />
                    {/* <Text
                  style={{
                    width: "60%",
                    textAlign: "center",
                    marginLeft: 10,
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  Type: {item.type}
                </Text> */}
                  </View>
                  <View style={{ width: 1, backgroundColor: 'grey', height: '80%' }} />
                  <View
                    style={{
                      flex: 0.333,
                      justifyContent: 'center',
                      alignItems: 'center',
                      paddingVertical: 20,
                    }}
                  >
                    <Text
                      style={{
                        color: 'black',
                        fontWeight: 'bold',
                        textAlign: 'center',
                      }}
                    >
                      {item?.name}
                    </Text>
                    <Text
                      style={{
                        color: '#dadada',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        marginTop: '4%',
                      }}
                    >
                      Rating: {item?.rating}
                    </Text>
                  </View>
                  <View style={{ width: 1, backgroundColor: 'grey', height: '80%' }} />
                  <View
                    style={{
                      flex: 0.333,
                      justifyContent: 'center',
                      alignItems: 'center',
                      paddingVertical: 20,
                    }}
                  >
                    <Text
                      style={{
                        color: 'black',
                        fontWeight: 'bold',
                        textAlign: 'center',
                      }}
                    >
                      {item?.distance.toFixed(1)}
                    </Text>
                  </View>
                </View>
              ))}
          </>
        )}
      </View>
    </>
  );
};
export default School;
