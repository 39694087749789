import { Alert, Platform } from "react-native";
import { types } from "../../actionTypes";
import idx from "idx";
import { store } from "../../store/setup";

import {
  SERVER_URL,
  _post,
  URL,
  _get,
  _put,
  _delete,
} from "../../../helpers/connectionHelpers";
import Linking from "../../../helpers/CustomLinking";

export const resetStatus = ()=>{
  return(dispatch)=>{
    dispatch({ type: types.GET_AFFORD_DATA_RESET });
  }
}
export const getCcqStatus=(autoNavigation )=>{
  return (dispatch)=>{
    dispatch({ type: types.GET_CCQ_DATA });

    _get(
      `${SERVER_URL}${URL.ccqStatus}`,
      {},
      {}, 
      (res) => {
        
        dispatch({
          type: types.GET_CCQ_SUCCESS,
          payload: {...res?.data,...{autoNavigation:autoNavigation}},
        });
      },
      (err) => {
        // function to perform on failure
        
        dispatch({ type: types.GET_CCQ_FAIL, error: err.message });
        Alert.alert(err.message);
      }
    );
  }
}
export const skipCcj =()=>{
  return (dispatch) => {
    dispatch({ type: types.SKIP_CCQ_DATA });
    _post(
      `${SERVER_URL}${URL.skipCcj}`,
      null,
      undefined, 
      (res) => {
        
        dispatch({
          type: types.SKIP_CCQ_DATA_SUCCESS
        });
      },
      (err) => {
        // function to perform on failure
        
        dispatch({ type: types.GET_CCQSKIP_CCQ_DATA_FAIL_FAIL, error: err.message });
        Alert.alert(err.message);
      }
    );
  }
}
export const setCCjPending=(data)=>{
  return (dispatch) => {
  dispatch({ type: types.CCJ_PENDING });
  _post(
    `${SERVER_URL}${URL.setccjPending}`,
    null,
    undefined, 
    (res) => {
      
      dispatch({
        type: types.CCJ_PENDING_SUCCESS,
        ccqURLData: res?.data,
      });
      dispatch(getCcqStatus())
      Linking.openURL(data, '_blank').catch((err) =>
      console.error('An error occurred', err),
    );
    },
    (err) => {
      // function to perform on failure
      
      dispatch({ type: types.CCJ_PENDING_FAIL, error: err.message });
      Alert.alert(err.message);
    }
  );
  }
}
export const getCcqUrl =(cb)=>{
  return (dispatch) => {
  
    dispatch({ type: types.GET_CCQ_URI });

    _post(
      `${SERVER_URL}${URL.ccqURL}`,
      null,
      undefined, 
      (res) => {
        
        dispatch({
          type: types.GET_CCQ_URI_SUCCESS,
          ccqURLData: res?.data,
        });
        cb(res)
      },
      (err) => {
        // function to perform on failure
        
        dispatch({ type: types.GET_CCQ_URI_FAIL, error: err.message });
        Alert.alert(err.message);
      }
    );
  }

}
export const removeAffordibility =(isGuarantor)=>{
  return (dispatch) => {
  
    dispatch({ type: types.REMOVE_AFFORDIBILITY });
    _delete(
      `${SERVER_URL}${isGuarantor? URL.guarantor_affordibility: URL.removeAffordibility}`, {}, // all headers here (if)
      JSON.stringify({}),
        (res) => {
        
        dispatch({
          type: types.REMOVE_AFFORDIBILITY_SUCCESS,
          payload: res,
        });
        dispatch(getCcqStatus())
      },
      (err) => {
        // function to perform on failure
        
        dispatch({ type: types.REMOVE_AFFORDIBILITY_FAIL, error: err.message });
        Alert.alert(err.message);
      }
    );
  }

}
export const removeCcj =()=>{
  return (dispatch) => {
  
    dispatch({ type: types.REMOVE_CCJ });
    _delete(
      `${SERVER_URL}${URL.removeCcj}`,{}, // all headers here (if)
      JSON.stringify({}),
        (res) => {
        
        dispatch({
          type: types.REMOVE_CCJ_SUCCESS,
          payload: res,
        });
        dispatch(getCcqStatus())

      },
      (err) => {
        // function to perform on failure
        
        dispatch({ type: types.REMOVE_CCJ_FAIL, error: err.message });
        Alert.alert(err.message);
      }
    );
  }

}
export const getAffordStatus = (bodyofRequest,data,isGuarantor) => {
    return (dispatch) => {
      dispatch({ type: types.GET_AFFORD_DATA });
      _post(
        `${SERVER_URL}${isGuarantor?URL.guarantor_affordibility: URL.affordibility}`,
        {}, // all headers here (if)
        JSON.stringify(bodyofRequest), // body here (if)
        (res) => {
          // function to perform on success
          
          let responseObject = res;
          if (responseObject.status) {
            dispatch({
              type: types.GET_AFFORD_SUCCESS,
              payload: responseObject?.data,
            });
            dispatch(getCcqStatus(data))
            // dispatch({
            //   type: types.SHOW_ALERT,
            //   alertType: "success",
            //   text: res.data,
            // });
            // navigate("My Alerts");
          } else {
            dispatch({ type: types.GET_AFFORD_FAIL, error: res.error });
            dispatch({
              type: types.SHOW_ALERT,
              alertType: "error",
              text: "Someting went wrong.Please try again later.",
            });
          }
        },
        (err) => {
          dispatch({ type: types.GET_AFFORD_FAIL, error: err.message });
          dispatch({
            type: types.SHOW_ALERT,
            alertType: "error",
            text: err.message,
          });
        }
      );
    };
  };
