import { Linking, Platform } from "react-native";

export const callIfAvailable = (contact) => {
    if (contact) {
      let url = `tel:${contact}`;
       window.location.href = url;
    }
};

export const sendEmail = async (data, isPropertyAdded, teanentData) => {
  try {
    let email = data.branch.email;
    let name = data.branch.name;
    let ref = data.property_name;

    

    let body = `Dear ${name} %0A %0A
          I have recently viewed property ${
            ref + " on " + data.postcode
          } on OpenBrix. %0A %0A
                    Please call me on my details below, so that I can arrange a viewing. %0A %0A
          Thank you %0A`;
    let subject = `OpenBrix Enquiry - ${ref + " " + data.postcode}`;

    if (isPropertyAdded) {
   body = 
  `
Dear ${teanentData?.branchName} Branch, \n %0A

%0A I am current renting\n %0A %0A

${teanentData?.addressLine}\n %0A %0A

I would like to discuss the following...\n %0A %0A %0A


Kind regards,\n %0A

${teanentData?.username}
`;
     subject = `Tenant Enquiry - ${teanentData?.addressLine}`;

    }
    //const userInfo = await SessionService.userInfo();

    //   if (userInfo) {
    //     body = `${body} ${userInfo.first_name}, ${userInfo.email}`;
    //   }

    const maildata = body;
    if (email) {
      let mailtoUrl = `mailto:${email}`;
      if (subject) {
        mailtoUrl += `?subject=${encodeURIComponent(subject)}`;
        if (body) {
          mailtoUrl += `&body=${encodeURIComponent(maildata)}`;
        }
      }
      window.open(mailtoUrl, '_blank');
    }
    // const url = `mailto: ${email}?body=${maildata}&subject=${subject}`;
    // Linking.openURL(url).catch((err) => {
    //   console.error("An error occurred", err);
    //   return false;
    // });
    return true;
  } catch {
    return false;
  }
};

export const sendEmailRentalOffer = async (isPropertyAdded, data) => {
  try {
    let email = data.email;
    let subject="Openflo Rental Offer Query – "+data?.postCode
    
    const url = `mailto: ${email}?subject=${subject}`;
    Linking.openURL(url).catch((err) => {
      console.error("An error occurred", err);
      return false;
    });
    return true;
  } catch {
    return false;
  }
};