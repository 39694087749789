"use strict";
var Regex = {
  validateEmail: function (val) {
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      val
    );
  },

  validateEmoji: function (text) {
    var reg = /[\uD83C-\uDBFF\uDC00-\uDFFF]+/g;
    return reg.test(text);
  },

  validateMobile: function (text) {

    var phoneno = /((07)|((\+|00)447)){1}[0-9]{9}\b/;
    if ((text.match(phoneno))) {
      return true;
    }
    else {
      return false;
    }

  },


  validateCardYear: function (text) {
    if (text && text.length > 1) {
      return true;
    } else {
      return false;
    }
  },

  validateCardCvv: function (text) {
    if (text && text.length == 3) {
      return true;
    } else {
      return false;
    }
  },

  validateMobileWithoutCC: function (val) {
    return /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/.test(
      val
    );
  },

  validateString: function (val) {
    return /^[a-zA-Z\x20]{3,25}$/.test(val);
  },

  validateStringMinimumLength2: function (val) {
    return /^[a-zA-Z\x20]{2,25}$/.test(val);
  },

  validatePassword: function (val) {
    return /^.{6,}$/.test(val);
  },

  validateNumbers: function (val) {
    return /^[0-9]{0,}$/.test(val);
  },

  validateName: function (val) {
    return /^.{3,}$/.test(val);
  },

  validateOtp: function (val) {
    return /^.{6,}$/.test(val);
  },

  validateYear: function (val) {
    return /^.{4,}$/.test(val);
  },

  validateTruck: function (val) {
    return /^.{1,}$/.test(val);
  },

  validateURL: function (url) {
    return /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/.test(
      url
    );
  },

  validateApptPrice(val) {
    if (val.length < 2) {
      return false;
    } else {
      return true;
    }
  },

  validatePrice(val) {
    return /^(\d*([.,](?=\d{1}))?\d+)?$/.test(val);
  },

  validateAlphaNumberic(val) {
    return /^[a-zA-Z0-9]*$/.test(val);
  },

  getNumbericValuesFromString(val) {
    return val.match(/^\d+|\d+\b|\d+(?=\w)/g);
  },

  validateDecimalNumbers(val) {
    return /^((\d|[1-9]\d+)(\.\d{0,1})?|\.\d{0,1})$/.test(val);
  },

  validateAddress: function (text) {
    return text.length > 200 ? false : true;
  },


  validateAssetsUrl: function (val) {
    const subUrlRegex = new RegExp('http://assets.openbrix.co.uk*');
    return subUrlRegex.test(val);
  },
  validateBankName:function(val){
    return /^[A-Za-z\s]+$/.test(val) || val === ''
  },
  validateWithSpecialName:function(val){
    return /^[A-Za-z\sÀ-ÖØ-öø-ÿ]+$/.test(val) || val === ''
  },
  validateUkPhone:function(val){
    return /^(?:\+44|0)[1-9]\d{9}$/.test(val) || val === ''
  }

};

module.exports = Regex;
