import React from 'react';
import { Text,View } from "react-native";
import { TouchableOpacity } from "react-native";

const Rings=(props)=>{
    let width=props?.size;
    let height=props?.size;
    return(
        <View style={{position:'absolute',zIndex:-100}}>
            <View style={{transform:[{rotate:'28deg'},{scaleX:2}],width,height,borderRadius:props?.size/2,borderWidth:0.5,borderColor:'#6D6D6D70',justifyContent:'center',alignItems:'center'}}/>           
           <View style={{transform:[{rotate:'18deg'},{scaleX:2}],width,height,position:'absolute',zIndex:-1,borderRadius:props?.size/2,borderWidth:0.5,borderColor:'#6D6D6D70',justifyContent:'center',alignItems:'center'}}/>
        </View>
    )
}
export default Rings;