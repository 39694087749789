import React from 'react';
import { View, Image, TouchableOpacity, Linking } from 'react-native';

const StoreButtons = () => {
  const handleImageClick = (url) => {
    Linking.openURL(url,'_blank');
  };

  return (
    <View style={{ flexDirection: 'row', justifyContent: 'space-evenly',marginTop:20 }}>
      <TouchableOpacity onPress={() => handleImageClick('https://apps.apple.com/in/app/tlyfe/id6449941281')}>
        <Image
           source={require('../../../assets/images/store/app.png')}
          style={{ width: 120, height: 70,resizeMode:'contain' }}
        />
      </TouchableOpacity>

      <TouchableOpacity onPress={() => handleImageClick('https://play.google.com/store/apps/details?id=com.tlyfe&hl=en_IN&gl=US')}>
        <Image
          source={require('../../../assets/images/store/play.png')}
          style={{ width: 150, height: 70,resizeMode:'contain' }}
        />
      </TouchableOpacity>
    </View>
  );
};

export default StoreButtons;
