import { connect } from "react-redux";
import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { Entypo } from "@expo/vector-icons";
import { View, Text, SafeAreaView, Dimensions } from "react-native";

import { uploadDoc } from "../../redux/actions/auth";
import getPlatformType from "../../helpers/getPlatform";
import { showToaster } from "../../redux/actions/toast";
import { NameValidation } from "../../helpers/validation";
import WideButton from "../../commonComponents/wideButton";
import { ContainerScrollView } from "../../helpers/appStyles";
import FilePicker from "../../commonComponents/filePicker/filepicker";
import Footer from "../../commonComponents/footerComponent/customFooter";
import Header from "../../commonComponents/headerComponent/headerComponent";
import CustomTextInput from "../../commonComponents/customTextInput/customTextInput";
import { Container, Heading, HeadingText, FormBox, ButtonBox, TextBox, Title, BackBtnBox } from "./styles";
import Modal  from "react-native-modal";
import { COLORS } from "../../constants/colorPallete";
import { Button } from "../openFlow/atoms/button";
import { ODSText } from "../../../openbrix-design-system/ui/atoms/ODSText";
import { useTheme } from "@emotion/react";
import { ODSInput } from "../../../openbrix-design-system/ui/atoms/ODSInput";
import ODSButton from "../../../openbrix-design-system/ui/atoms/ODSButton";


const AddDocument = (props) => {
  const [name, setName] = useState("");
  const [file, setFile] = useState("");
  const layoutType = getPlatformType();
  const [fileName, setFileName] = useState();
  const [nameErrorMsg, setNameErrorMsg] = useState("");
  const [fileErrorMsg, setFileErrorMsg] = useState("");
  const { height: WINDOW_HEIGHT } = Dimensions.get("window");
  const {showModal,setShowModal}=props
  const {colors}=useTheme();
  const upload = (props) => {
    props.uploadDoc({ name, file }, props.navigation.navigate,()=>{
      setShowModal(false)
    });
  };

  const _onSubmit = () => {
    var nameCheck = NameValidation(name);
    if (nameCheck.status) {
      if (file != "") {
        setName("");
        upload(props);
        setFile("");
        setFileName("");
      } else {
        props.showToaster("error", "Please select the file.");
      }
    } else {
      setNameErrorMsg(nameCheck.msg);
    }
  };

  return (
    
    <Modal
    isVisible={showModal}
    transparent={true}
    coverScreen={false}
    hasBackdrop={false}
    backdropOpacity={0}
    onBackdropPress={() => setShowModal(false)}
    style={{
      margin: 0,
      width: '100%',
      flex: 1,
      backgroundColor: '#00000080',
      paddingHorizontal: layoutType == 'phone' ? '8%' : '3%',
      alignSelf:"center"
    }}
    >
       <View 
          style={{  
            backgroundColor: '#FAFAFA', 
            borderRadius: 20, 
            overflow: 'hidden', 
            alignSelf: 'center', 
            paddingVertical:20
          }} 
        > 
          <FormBox>
            <Heading>
             <ODSText textAlign='center' type="h5" color={colors.text.primary}>Add Document</ODSText>
            </Heading>
            <TextBox>
              <ODSInput
               width="100%"
               value={name}
               label={"Title"}
               onChange={(name) => {
                 setName(name);
                 setNameErrorMsg("");
               }}
               showError={nameErrorMsg == "" ? false : true}
               errorMessage={nameErrorMsg}
               hints={[{hint:nameErrorMsg,state:'error'}]}
              />
            </TextBox>
            <TextBox>

              
              <FilePicker
              width={'100%'}
                file={fileName}
                fileName={fileName}
                onFileSelect={(val) => {
                  setFile(val);
                  setFileName(val.name);
                  setFileErrorMsg("");
                }}
              />
            </TextBox>

            <ButtonBox>
            <ODSButton
           restStyle={{width:'100%'}}
            type="primary"
            onPress={_onSubmit}
            themeColor={colors.main.purple}
            disabled={false}
          >
           Save
          </ODSButton>
            <ODSButton
            type="secondary"
            onPress={() => {
              setShowModal(false);
            }}
            restStyle={{ marginTop:16,width:'100%'}}
            themeColor={colors.main.purple}
            disabled={false}
          >
           Cancel
          </ODSButton>
            </ButtonBox>
          </FormBox>
          </View>
          </Modal>
      
  );
};

const mapStateToProps = (state) => ({
  profileLoading: state.authReducer.profileLoading,
  uploadDoc: state.authReducer.uploadDoc,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      uploadDoc,
      showToaster,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(AddDocument);
