import React, { useState } from "react";
import {
  View,
  Text,
  SafeAreaView,
  Image,
  TouchableOpacity,
  Platform,
  ScrollView,
  Button,
} from "react-native";
import {
  Container,
  SectionWrapper,
  DualView,
  TextBox,
  SectionTitle,
  SectionText,
  ImageBox,
  TeamsSection,
  CenterSection,
  Section,
  MemberBoxWrapper,
  MemberBox,
  MemberImage,
  MemberName,
  MemberNameText,
  Title,
  Description,
  MemberDetailsText,
  MemberHeader,
  TeamHeader,
  ContentContainer,
  TopSection,
  LogoImage,
  CenterImage,
  DesktopImage,
} from "./styles";

import getPlatformType from "../../helpers/getPlatform";
const layoutType = getPlatformType();

import Header from "../../commonComponents/headerComponent/headerComponent";
import Footer from "../../commonComponents/footerComponent/customFooter";
import TripleTabs from "../../commonComponents/tripleTabs/tripleTabs";
import { COLORS } from "../../constants/colorPallete";
import TlyfeIntroCard from "../../commonComponents/tlyfeIntro/tlyfeIntroCard";
import { useNavigation } from "@react-navigation/native";
import GradientText from "../../commonComponents/gradientText";
const tlyfeDecr = `Tenants using Tlyfe are able to:
- Pre-reference themselves via an innovative and approved smart new digital reference, 
- Verify and validate their ID, 
- Perform their Right to Rent obligations and share all this with an Agent securely and directly on the platform. 

They can also share their validated rental payment, deposit and their credit histories. 

OpenBrix registered applicants stand out from the rest. This saves agents administration time and associated costs and can also act as a form of pre-qualification in the tenant selection process.`;
const moreDesc = `A digital ID where the data is not controlled by “big tech”, but by the tenant. 

Data is shared by the tenant or applicant to their chosen party on the platform directly and securely

Tenants are guided through an interactive step by step process to “move-in” to their new home.

Rental payments can help improve their credit scores.

Tenants can pre-qualify themselves and validate their ID. 

Accepted in 168 countries.`;
const desktop = require('../../assets/images/aboutus/tlyfeDesktop.png');
const mobile = require('../../assets/images/aboutus/phone.png')

const Tlyfe = (props) => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const navigation = useNavigation();

  return (
    <SectionWrapper>
      <TopSection style={{ justifyContent: 'flex-start', alignItems: 'flex-start' }}>
      <Image source={layoutType=="phone"?require('../../assets/SVG/tenantMobile.svg'):require('../../assets/SVG/tenant.svg')} style={{width:190,marginTop:30,marginBottom:20,alignSelf:layoutType=='phone'?'center':'flex-start',height:100,resizeMode:'contain'}}/>


        <SectionText style={{ textAlign: layoutType=="phone"?"center":'left', fontFamily: "Lato-Bold", fontSize: 22 }}>A Multifunctional Digital ID and Rental Passport for Tenants</SectionText>
        <SectionText style={{ textAlign: 'left', fontFamily: "Lato",marginLeft:layoutType=="phone"?"10%":0 ,width:layoutType=="phone"?"80%": "60%", marginTop: 20 }}>{`tlyfe registered applicants stand out from the rest. 

This saves agents administration time and associated costs and can also act as a form of pre-qualification in the tenant selection process.`}</SectionText>
      </TopSection>
      <CenterSection style={{ flexDirection: layoutType == "phone" ? "column-reverse" : "row", marginBottom: 20, padding: 0 }}>
        <View style={{ width: layoutType=="phone"?"100%":"50%" }}>
         
          <SectionText style={{ textAlign: 'left', fontFamily: "Lato-Bold", marginTop: 60 ,width:layoutType=="phone"?"80%": "100%",marginLeft:layoutType=="phone"?"10%":0}}>Tenants using tlyfe are able to:</SectionText>
          {["Pre-qualify themselves via an innovative and approved smart new digital reference", "Verify their ID from 168 Countries & Verify UK Right to Rent status", "Share all this with an Agent securely and directly on the platform", "Data is shared by the tenant or applicant to their chosen party on the platform directly and securely", "Share their validated rental payment record, deposit history and their credit scores ", "A portable digital app where the data is controlled by the tenant", "An app that guides a tenant through an interactive step by step process to “move-in” to their new home"].map((e) => <View style={{ flexDirection: 'row',width:layoutType=="phone"?"80%": "100%",marginLeft:layoutType=="phone"?"10%":0, marginTop: 25, alignItems: 'center' }}>
            <Image source={require('../../assets/images/aboutus/checkbox.png')} style={{ height: 18, width: 18, resizeMode: 'contain' }} />
            <Description style={{ marginLeft: 10, fontFamily: "Lato", textAlign: 'justify' }}>
              {e}
            </Description>
          </View>)}
        </View>
        <View style={{ width:layoutType=="phone"?"100%":"50%", justifyContent: 'center', alignItems: 'center' }}>
          <DesktopImage source={layoutType == "phone" ? mobile : desktop} />
        </View>
      </CenterSection>
      <TlyfeIntroCard hideMovein showButton={false} hideHeader navigation={navigation} />

    </SectionWrapper>
  );
};

export default Tlyfe;
