import React, { useState } from "react";
import { SafeAreaView, Dimensions, ScrollView,View } from "react-native";
import {
  Container,
  Heading,
  HeadingText,
  FormBox,
  ButtonBox,
  TextBox,
} from "./styles";
import { MaterialIcons } from '@expo/vector-icons'; 

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import idx from "idx";

import { resetPassword } from "../../redux/actions/auth";

import getPlatformType from "../../helpers/getPlatform";
const layoutType = getPlatformType();

const { height: WINDOW_HEIGHT, width: WINDOW_WIDTH } = Dimensions.get("window");

import Header from "../../commonComponents/headerComponent/headerComponent";
import Footer from "../../commonComponents/footerComponent/customFooter";
import CustomTextInput from "../../commonComponents/customTextInput/customTextInput";
import WideButton from "../../commonComponents/wideButton";
import Loader from "./../../commonComponents/activityIndicator/activityIndicator";
import { EmailValidation } from "../../helpers/validation";
import { COLORS } from "../../constants/colorPallete";
import { Typography } from "../openFlow/style";

const ResetPassword = (props) => {
  const [email, setEmail] = useState("");
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const resetPassword = () => {
    let emailCheck = EmailValidation(email);
    if (emailCheck.status) {
      props.resetPassword(
        {
          email,
          user_type: 0,
        },
        props.navigation.navigate,
        ()=>{
          setEmail("")
          props?.closePopup()
        }
      );
    } else {
      setEmailErrorMsg(emailCheck.status ? "" : emailCheck.msg);
    }
  };
  const {isTLYF} =props
  return (
    <View style={{
      width: "100%",

    }}>
      <View style={{backgroundColor:"white"}}>
      <MaterialIcons name="close" size={24} color="black"
      onPress={()=>{
        setEmail("")
        props?.closePopup()
      }}
        style={{position:"absolute",top:"8%",right:18}}
      />
      <Typography
          color={isTLYF ? COLORS.LGREEN : COLORS.LIGHTPINK1}
          raj
          size={"40px"}
          bold={"900"}
          top={"20px"}
        >
       RESET PASSWORD
        </Typography>

        <View
          style={{
            height: 0.5,
            width: "100%",
            backgroundColor: COLORS.GREY1,
            marginTop: 20,
          }}
        />
        </View>
        <View style={{ padding:20}}>
            <TextBox>
              <CustomTextInput
                height={WINDOW_HEIGHT * 0.06}
                width="100%"
                borderWidth={1}
                borderColor="grey"
                title={""}
                onChangeText={(emailOfUser) => {
                  setEmail(emailOfUser);
                }}
                value={email}
                bRadius={50}
                bColor={"#E2E2E2"}
                titleComponent={
                  <Typography color={COLORS.BLACK} raj size={"20px"} bold={"600"}>
                    Email Address
                  </Typography>
                }
                showError={emailErrorMsg == "" ? false : true}
                errorMessage={emailErrorMsg}
                id={"RESET_PASSWORD_email_address"}
              ></CustomTextInput>
            </TextBox>
            <ButtonBox>
              <WideButton
               width={"100%"}
                borderRadius={15}
                gradiant={['#FBB1E6',COLORS.orange]}
                childText="RESET PASSWORD"
                onPress={resetPassword}
                id={"RESET_PASSWORD"}
              />
            </ButtonBox>
            </View>
          </View>
  );
};

const mapStateToProps = (state) => ({
  loading: idx(state, (_) => _.authReducer.resetPasswordLoading),
  resetPasswordStatus: idx(state, (_) => _.authReducer.resetPasswordStatus),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resetPassword,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
