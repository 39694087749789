import React from 'react';
import {

  Dimensions,

  Platform,
  Linking,
} from 'react-native';

import {
  
  SocialLinks,

  SocialImg,
  SocialImgBox,

} from './footerStyle';
import * as Device from 'expo-device';

import getPlatformType from '../../helpers/getPlatform';
let deviceType = '';


export default class SocialIcons extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount = async () => {
    deviceType = await Device.getDeviceTypeAsync();
  };

  _redirectSocial = (url) => {
    Linking.canOpenURL(url).then((supported) => {
      if (supported) {
        if (Platform.OS === 'web') {
          window.open(url);
        } else {
          Linking.openURL(url);
        }
      } else {
      }
    });
  };


  render() {
  
    let {iconSize}= this.props
    return (
   
            <SocialLinks>
              <SocialImgBox
                onPress={() => this._redirectSocial('https://www.linkedin.com/company/openbrix/')}
              >
                <SocialImg
                  source={require('../../assets/images/linkedInNew.png')}
                  resizeMode="contain"
                size={iconSize}
                />
              </SocialImgBox>
              <SocialImgBox
                onPress={() => this._redirectSocial('https://www.facebook.com/openbrix/')}
              >
                <SocialImg
                  source={require('../../assets/images/facebookBig.png')}
                  resizeMode="contain"
                  size={iconSize}
                />
              </SocialImgBox>
              <SocialImgBox onPress={() => this._redirectSocial('https://twitter.com/OpenBrix')}>
                <SocialImg
                  source={require('../../assets/images/twitterBig.png')}
                  resizeMode="contain"
                  size={iconSize}
                />
              </SocialImgBox>
              <SocialImgBox
                onPress={() => this._redirectSocial('https://www.instagram.com/openbrix/')}
              >
                <SocialImg
                  source={require('../../assets/images/instagramBig.png')}
                  resizeMode="contain"
                  size={iconSize}
                />
              </SocialImgBox>
            </SocialLinks>
    );
  }
}
