import React from "react";
import styled from "styled-components/native";
import getPlatformType from "../../helpers/getPlatform";
import { FlatList } from "react-native-gesture-handler";

const layoutType = getPlatformType();
let Container,
  SliderWrapper,
  SliderBox,
  ContentBox,
  ImageThumbnailBox,
  ImageThumbnail,
  AgentInfoBox,
  AgentImage,
  AgentCta,
  DetailsCenter,
  DetailsBox,
  HeaderText,
  DetailsTop,
  GreyText,
  InnerDesc,
  SliderWrapperBottom,
  AgentCtaView,
  TopScrollView,
  DetailsWrapper,
  BookmarkView,
  FloorPlanView,
  ShareButtonView,
  BackLinkView,
  ShareButtonText,
  FloorPlanText,
  BackLinkText,
  Cordinates,
  MapWrapper,
  CordText,
  Sub,
  ServicesList,
  ServicesBox,
  ServicesListTextBox,
  ImageBox,
  ServicesListTextBoxLeft,
  ServiceText,
  DetailsBottom,
  DetailsTopIcons,
  IconBox,
  PropertyLabelvarient1,
  PropertyLabelvarient2,
  BackTextWrapper,
  StatusBox,
  ShareOptions;

StatusBox = styled.Text`
  position: absolute;
  right: -50px;
  top: 0px;
  padding: 6px 14px;
  background: #ccc;
  font-weight: 600;
  font-size: 12px;
  border-top-right-radius: 6px;
  border-bottom-left-radius: 6px;
`;

BackTextWrapper = styled.Text`
  display: flex;
  margin-top: 20px;
`;
ShareOptions = styled.TouchableOpacity`
  position: absolute;
  top: 65px;
  right: 100px;
  background-color: #fff;
  border: 1px solid grey;
  width: 200px;
  padding: 10px;
`;
PropertyLabelvarient1 = styled.Text`
  color: #000;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
`;
PropertyLabelvarient2 = styled.Text`
  color: #e71e82;
  font-size: 20px;
  font-weight: bold;
  line-height: 18px;
`;
if (layoutType == "web") {
  Container = styled.View`
    width: 1130px;
  `;
  IconBox = styled.TouchableOpacity`
    margin-right: 10px;
    width: 80px;
    height: 85px;
  `;
  TopScrollView = styled.ScrollView`
    padding-bottom: 84px;
  `;
  SliderWrapper = styled.View`
    justify-content: space-between;
    flex-direction: row;
    height: 472px;
  `;
  SliderWrapperBottom = styled.View`
    justify-content: space-between;
    flex-direction: row;
    margin-top: 1%;
  `;
  SliderBox = styled.View`
    width: 838px;
    position: relative;
  `;
  ContentBox = styled.View`
    width: 838px;
    position: relative;
  `;
  ImageThumbnailBox = styled.View`
    width: 269px;
    height: 100%;
    justify-content: space-between;
  `;
  AgentInfoBox = styled.View`
    width: 269px;
    margin-top: 50px;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 260px;
  `;
  AgentImage = styled.View`
    width: 100%;
    height: 155px;
  `;
  AgentCta = styled.TouchableOpacity`
    height: 52px;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border: 1px solid #e7e7e7;
    border-bottom-width: 0px;
  `;
  ImageThumbnail = styled.View`
    width: 269px;
    height: 153px;
  `;
  DetailsBox = styled.View`
    padding: 30px 80px;
    width: 100%;
  `;

  DetailsTop = styled.View`
    flex-direction: row;
  `;
  DetailsTopIcons = styled.View`
    flex-direction: row;
    margin-bottom: 20px;
  `;
  DetailsCenter = styled.View`
    margin-top: 10px;
  `;
  DetailsBottom = styled.View`
    margin-top: 10px;
  `;
  AgentCtaView = styled.View`
    display: flex;
    flex-direction: column;
  `;
  DetailsWrapper = styled.View``;
  ShareButtonView = styled.TouchableOpacity`
    height: 30px;
    width: 174px;
    border: 1px solid #ffffff;
    border-radius: 18px;
    background-color: rgba(255, 255, 255, 0.13);
    align-items: center;
    justify-content: center;
  `;
  FloorPlanView = styled.TouchableOpacity`
    position: absolute;
    bottom: 30px;
    right: 30px;
    height: 30px;
    width: 236px;
    border: 1px solid #ffffff;
    border-radius: 18px;
    background-color: rgba(255, 255, 255, 0.13);
    align-items: center;
    justify-content: center;
  `;
  Cordinates = styled.View`
    position: absolute;
    width: 60%;
    z-index: 2;
    background: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 25px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    left: 0;
    top: 0;
    right: 0;
    margin: auto;
    align-items: center;
    min-height: 80px;
  `;
  ServicesList = styled.View`
    border: 1px solid #d0d0d0;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 5px 0px;
    width: 664px;
  `;
} else if (layoutType == "phone") {
  Container = styled.View`
    width: 100%;
  `;
  IconBox = styled.TouchableOpacity`
    padding: 20px 0px;
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
  `;
  TopScrollView = styled.ScrollView`
    padding-bottom: 10px;
  `;
  SliderBox = styled.View`
    width: 100%;
    height: 100%;
    position: relative;
  `;
  ContentBox = styled.View`
    width: 100%;
    position: relative;
  `;

  SliderWrapper = styled.View`
    height: 300px;
    z-index: 10;
  `;
  SliderWrapperBottom = styled.View`
    margin-top: -5px;
  `;
  AgentInfoBox = styled.View`
    flex-direction: column-reverse;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 85px;
  `;
  AgentImage = styled.View`
    width: 100%;
    height: 44px;
  `;

  AgentCta = styled.TouchableOpacity`
    height: 52px;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    flex: 1;
    border: 1px solid #e7e7e7;
    border-bottom-width: 0px;
  `;
  ImageThumbnail = styled.View`
    width: 269px;
    height: 153px;
  `;
  DetailsBox = styled.View`
    padding: 0;
    width: 100%;
  `;
  DetailsCenter = styled.View`
    padding: 20px 30px;
    background-color: #fff;
  `;
  DetailsBottom = styled.View`
    padding: 20px 30px;
    background-color: #fff;
  `;
  DetailsTop = styled.View`
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 30px 0;
    background: #fff;
  `;
  DetailsTopIcons = styled.View`
    flex-direction: row;
    justify-content: space-between;
    padding: 0px;
    background: #fff;
    box-shadow: rgba(128, 128, 128, 0.4) 3px 0px 14px;
    margin-top: 10px;
    padding-top: 30px;
  `;
  AgentCtaView = styled.View`
    display: flex;
    flex-direction: row;
    width: 100%;
  `;
  DetailsWrapper = styled.View`
    margin-top: 20px;
  `;
  ShareButtonView = styled.TouchableOpacity`
  height: 30px;
  width: 68px
  border: 1px solid #ffffff;
  border-radius: 18px;
  background-color: rgba(255, 255, 255, 0.13);
  align-items: center;
  justify-content: center;
`;
  FloorPlanView = styled.TouchableOpacity`
  position: absolute;
  bottom: 30px;
  right: 30px;
  height: 30px;
  width: 94px
  border: 1px solid #FFFFFF;
  border-radius: 18px;
  background-color: rgba(255,255,255,0.13);
  align-items: center;
  justify-content: center;
`;
  Cordinates = styled.View`
    position: absolute;
    z-index: 2;
    background: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 25px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    top: 0;
    left: 0px;
    min-height: 80px;
    align-items: center;
    width: 100%;
  `;
  ServicesList = styled.View`
    border: 1px solid #efefef;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 5px 0px;
    width: 100%;
  `;
  StatusBox = styled.Text`
    position: absolute;
    right: 10px;
    top: 10px;
    padding: 6px 14px;
    background: #ccc;
    font-weight: 600;
    font-size: 12px;
    border-top-right-radius: 6px;
    border-bottom-left-radius: 6px;
  `;
} else {
  Container = styled.View`
    width: 942px;
  `;
  IconBox = styled.TouchableOpacity`
    margin-right: 30px;
    width: 80px;
    height: 85px;
  `;
  TopScrollView = styled.ScrollView`
    padding-bottom: 84px;
  `;
  SliderWrapper = styled.View`
    height: 397px;
    justify-content: space-between;
    flex-direction: row;
  `;
  SliderWrapperBottom = styled.View`
    justify-content: space-between;
    flex-direction: row;
    margin-top: 1%;
  `;
  SliderBox = styled.View`
    width: 705px;
    position: relative;
  `;
  ContentBox = styled.View`
    width: 705px;
    position: relative;
  `;
  ImageThumbnailBox = styled.View`
    width: 231px;
    justify-content: space-between;
  `;
  AgentInfoBox = styled.View`
    width: 224px;
    margin-top: 50px;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 260px;
  `;
  AgentImage = styled.View`
    width: 100%;
    height: 158px;
  `;
  AgentCta = styled.TouchableOpacity`
    height: 52px;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border: 1px solid #e7e7e7;
    border-bottom-width: 0px;
  `;

  ImageThumbnail = styled.View`
    width: 231px;
    height: 130px;
  `;
  DetailsBox = styled.View`
    padding: 40px 60px;
    width: 100%;
  `;

  DetailsTop = styled.View`
    flex-direction: row;
  `;
  DetailsTopIcons = styled.View`
    flex-direction: row;
    margin-bottom: 20px;
  `;
  DetailsCenter = styled.View`
    margin-top: 10px;
  `;
  DetailsBottom = styled.View`
    margin-top: 10px;
  `;
  AgentCtaView = styled.View`
    display: flex;
    flex-direction: column;
  `;
  DetailsWrapper = styled.View``;
  ShareButtonView = styled.TouchableOpacity`
    position: absolute;
    top: -4px;
    right: 100px;
    height: 30px;
    width: 144px;
    border: 1px solid #ffffff;
    border-radius: 18px;
    background-color: rgba(255, 255, 255, 0.13);
    align-items: center;
    justify-content: center;
  `;
  FloorPlanView = styled.TouchableOpacity`
    position: absolute;
    bottom: 30px;
    right: 30px;
    height: 30px;
    width: 198px;
    border: 1px solid #ffffff;
    border-radius: 18px;
    background-color: rgba(255, 255, 255, 0.13);
    align-items: center;
    justify-content: center;
  `;
  Cordinates = styled.View`
    position: absolute;
    z-index: 2;
    width: 60%;
    background: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 25px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    left: 0;
    top: 0;
    right: 0;
    margin: auto;
    min-height: 80px;
    align-items: center;
  `;
  ServicesList = styled.View`
    border: 1px solid #d0d0d0;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 5px 0px;
    width: 544px;
  `;
  StatusBox = styled.Text`
    position: absolute;
    right: -40px;
    top: -20px;
    padding: 6px 14px;
    background: #ccc;
    font-weight: 600;
    font-size: 12px;
    border-top-right-radius: 6px;
    border-bottom-left-radius: 6px;
  `;
}

HeaderText = styled.Text`
  color: #e71e82;
  font-size: 18px;
  margin: 5px 0px;
  width: 100%;
  font-weight: bold;
`;

GreyText = styled.Text`
  color: #b0b0b0;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 16px;
  margin: 5px 0px;
`;

InnerDesc = styled.Text`
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  margin: 5px 0px;
`;

ShareButtonText = styled.Text`
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
  color: white;
  font-weight: bold;
`;

FloorPlanText = styled.Text`
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
  color: white;
  font-weight: bold;
`;
BookmarkView = styled.TouchableOpacity`
  margin-left: 30px;
  margin-right: 15px;
`;

BackLinkView = styled.TouchableOpacity`
  position: absolute;
  top: 30px;
  left: 30px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;
BackLinkText = styled.Text`
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
  font-weight: bold;
`;

MapWrapper = styled.View`
  height: 475px;
  width: 100%;
`;

CordText = styled.Text`
  font-size: 20px;
  color: #e71e82;
  font-weight: bold;
  position: relative;
`;
Sub = styled.Text`
  position: absolute;
  bottom: 0px;
  font-size: 14px;
  color: #e71e82;
  font-weight: 600;
`;

ServicesBox = styled.View`
  flex-direction: row;
`;
ServicesListTextBox = styled.View`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-left: 10px;
`;
ServicesListTextBoxLeft = styled.View`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

ImageBox = styled.View`
  height: 32px;
  width: 32px;
  margin-right: 20px;
  justify-content: center;
  align-items: center;
`;

ServiceText = styled.Text`
  color: #000000;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 17px;
`;
export {
  Container,
  SliderWrapper,
  SliderBox,
  ContentBox,
  ImageThumbnailBox,
  ImageThumbnail,
  AgentInfoBox,
  AgentImage,
  AgentCta,
  DetailsBox,
  HeaderText,
  DetailsTop,
  GreyText,
  InnerDesc,
  DetailsBottom,
  DetailsCenter,
  AgentCtaView,
  TopScrollView,
  DetailsWrapper,
  SliderWrapperBottom,
  BookmarkView,
  FloorPlanView,
  ShareButtonView,
  BackLinkView,
  ShareButtonText,
  FloorPlanText,
  BackLinkText,
  Cordinates,
  CordText,
  Sub,
  ServicesList,
  MapWrapper,
  ServicesListTextBox,
  ServicesListTextBoxLeft,
  ServicesBox,
  ServiceText,
  ImageBox,
  ShareOptions,
  DetailsTopIcons,
  IconBox,
  PropertyLabelvarient1,
  PropertyLabelvarient2,
  BackTextWrapper,
  StatusBox,
};
