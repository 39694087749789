import React from "react";
import styled from "styled-components/native";
import { ImageBackground } from "react-native";
import getScreenType from "../../../openbrix-design-system/utils/getScreenType";

const layoutType = getScreenType();
let HeaderImage,
HeaderContent,
Body,
Picture1,
Content,
VideoWrapper,
DoYouKnow,
LoginCard,
RegisterCard
;


if (layoutType == "phone") {
HeaderImage = styled(ImageBackground)`
width: 100%;
height:${props=>props?.type=="rent"?'558px':props.type=='intro'?'520px':props.type=='login'?'243px':'670px'};
justify-content:flex-end;
padding-left:24px;
padding-right:70px;
`;
HeaderContent = styled.View`
width: 100%;
align-self:flex-end;
margin-bottom:24px;
`;
Body = styled.View`
width: 100%;
`;
VideoWrapper = styled.View`
width: 100%;
height:185px;
margin-bottom:-80px;
z-index:1000;
margin-top:24px;
`;
Picture1 = styled.Image`
width: 100%;
height:400px;
`;
Content = styled.View`
width: 100%;
`;
DoYouKnow=styled.View`
border-radius: 12;
box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.15);
padding:24px;
background-color:white;
width:90%;
z-index:1000;
align-self:center;
margin-top:-150px;
margin-bottom:30px;
`;
LoginCard=styled.View`
padding:24px;
background-color:white;
width:100%;
z-index:1000;
align-self:center;
margin-bottom:10px;
`;
RegisterCard=styled.View`
padding:24px;
background-color:white;
width:100%;
z-index:1000;
align-self:center;
margin-bottom:10px;
`;
} else if (layoutType == "web") {
HeaderImage = styled(ImageBackground)`
    width: 100%;
    height:417px;
    justify-content:flex-end;
    padding-horizontal:100px;
    padding-bottom:70px;
    // justify-content: flex-start;
    `;
    HeaderContent = styled.View`
    width: 50%;
    align-self:flex-end;
    // margin-bottom:48px;
    `;
    Body = styled.View`
padding-top:56px;
padding-horizontal:100px;
padding-bottom:10px;
`;
Picture1 = styled.Image`
width: 299px;
height:${props=>props.type=="rent"?'394px':props?.type=="intro"?'322px':'270px'};
border-radius:12px;
`;
Content = styled.View`
width: 585px;
`;
VideoWrapper = styled.View`
width: 840px;
height:480px;
align-self:center;
margin-bottom:-100px;
z-index:1000;
margin-top:64px;
`;
DoYouKnow=styled.View`
border-radius: 12;
box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.15);
padding:24px;
background-color:white;
width:409px;
z-index:1000;
`;
LoginCard=styled.View`
border-radius: 12;
box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.15);
padding:40px;
background-color:white;
width:400px;
z-index:1000;
margin-top:-100px;
align-self:center;
margin-bottom:100px;
`;
RegisterCard=styled.View`
border-radius: 12;
box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.15);
padding:40px;
background-color:white;
width:618px;
z-index:1000;
margin-top:-100px;
align-self:center;
margin-bottom:100px;
`;

} else {
    HeaderImage = styled(ImageBackground)`
    width: 100%;
    height:${props=>props?.type=="rent"?'417px':props.type=='insurance'?'500px':props.type=='login'?'320px':'377px'};
    justify-content:flex-end;
    padding-horizontal:70px;
    padding-top:80px;
    `;
    HeaderContent = styled.View`
    width: 40%;
    align-self:flex-end;
    margin-bottom:48px;
    `;
    Body = styled.View`
    width: 100%;
    padding-top:48px;
    padding-horizontal:70px;
    padding-bottom:12px;
    `;
    Picture1 = styled.Image`
width: 301px;
height:${props=>props.type=="rent"?'400px':props?.type=="insurance"?'260px':'347px'};
border-radius:12px;
`;
Content = styled.View`
width: 48%;
`;
VideoWrapper = styled.View`
width: 365px;
height:206px;
margin-bottom:-100px;
z-index:1000;
margin-top:64px;
align-self:center;
`;
DoYouKnow=styled.View`
border-radius: 12;
box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.15);
padding:24px;
background-color:white;
width:302px;
z-index:1000;
`;
LoginCard=styled.View`
border-radius: 12;
box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.15);
padding:40px;
background-color:white;
width:400px;
z-index:1000;
margin-top:-100px;
align-self:center;
margin-bottom:100px;
`;
RegisterCard=styled.View`
border-radius: 12;
box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.15);
padding:40px;
background-color:white;
width:618px;
z-index:1000;
margin-top:-100px;
align-self:center;
margin-bottom:100px;
`;

}

export {
 HeaderImage,
 HeaderContent,
 Body,
 Picture1,
 Content,
 VideoWrapper,
 DoYouKnow,
 LoginCard,
 RegisterCard
};
