import { types } from "../../actionTypes";

const propertyReducer = (
  state = {
    searchLoading: false,
    searchData: null,
    searchStatus: "",
    propertyData: null,
    propertyLoading: false,
    searchObject: {},
    saveLoading: false,
    reSearchLoading: false,
    savedLoading: false,
    savedData: null,
    pageData: [],
    propertyCouncilLoader: false,
    propertySchoolLoader: false,
    totalProperties: 0,
  },
  action
) => {
  switch (action.type) {
    case types.PAGE_DATA: {
      return {
        ...state,
        pageData: action.payload,
      };
    }
    case types.SEARCH_REQUEST: {
      return {
        ...state,
        searchLoading: true,
      };
    }
    case types.SEARCH_SUCCESS: {
      return {
        ...state,
        searchLoading: false,
        searchData: action.payload,
        pageData: action?.payload?.data,
        searchStatus: action.payload.status,
        totalProperties: action?.payload?.total
          ? action.payload.total
          : state.totalProperties,
      };
    }
    case types.SEARCH_FAILED: {
      return {
        ...state,
        searchLoading: false,
        searchData: action.payload,
        searchStatus: false,
      };
    }
    case types.SEARCHMAP_REQUEST: {
      return {
        ...state,
        searchMapLoading: true,
      };
    }
    case types.SEARCHMAP_SUCCESS: {
      return {
        ...state,
        searchMapLoading: false,
        searchMapData: action.payload,
        searchMapStatus: action.payload.status,
      };
    }
    case types.SEARCHMAP_FAILED: {
      return {
        ...state,
        searchMapLoading: false,
        searchMapData: action.payload,
        searchMapStatus: false,
      };
    }
    case types.GET_PROPERTY_REQUEST: {
      return {
        ...state,
        propertyLoading: true,
      };
    }
    case types.GET_PROPERTY_SUCCESS: {
      return {
        ...state,
        propertyLoading: false,
        propertyData: action.payload,
      };
    }
    case types.GET_PROPERTY_FAILED: {
      return {
        ...state,
        propertyLoading: false,
        propertyData: action.payload,
      };
    }
    case types.GET_PROPERTY_CARD_REQUEST: {
      return {
        ...state,
        selectedPlaceLoading: true,
      };
    }
    case types.GET_PROPERTY_CARD_SUCCESS: {
      return {
        ...state,
        selectedPlaceLoading: false,
        selectedPlace: action.payload,
      };
    }
    case types.GET_PROPERTY_CARD_FAILED: {
      return {
        ...state,
        selectedPlaceLoading: false,
        selectedPlace: action.payload,
      };
    }
    case types.REMOVE_PROPERTY_CARD: {
      return {
        ...state,
        selectedPlace: null,
      };
    }
    case types.SAVE_SEARCH_OBJECT: {
      return {
        ...state,
        searchObject: action.payload,
      };
    }
    case types.SAVE_PROPERTY_SUCCESS: {
      return {
        ...state,
        saveLoading: false,
      };
    }
    case types.SAVE_PROPERTY_FAILED: {
      return {
        ...state,
        saveLoading: false,
      };
    }
    case types.SAVE_PROPERTY_REQUEST: {
      return {
        ...state,
        saveLoading: true,
      };
    }
    case types.RE_SEARCH_SUCCESS: {
      return {
        ...state,
        reSearchLoading: false,
        searchData: action.payload,
      };
    }
    case types.RE_SEARCH_FAILED: {
      return {
        ...state,
        reSearchLoading: false,
        searchData: action.payload,
      };
    }
    case types.RE_SEARCH_REQUEST: {
      return {
        ...state,
        reSearchLoading: true,
      };
    }
    case types.GET_SAVED_SUCCESS: {
      return {
        ...state,
        saveLoading: false,
        savedData: action.payload,
      };
    }
    case types.GET_SAVED_FAILED: {
      return {
        ...state,
        saveLoading: false,
        savedData: action.payload,
      };
    }
    case types.GET_SAVED_REQUEST: {
      return {
        ...state,
        savedLoading: true,
      };
    }
    case types.PROPERTYNEARME_REQUEST: {
      return {
        ...state,
        nearMePropertyLoading: true,
      };
    }
    case types.PROPERTYNEARME_SUCCESS: {
      return {
        ...state,
        nearMeProperies: action.payload,
        nearMePropertyLoading: false,
      };
    }
    case types.PROPERTYNEARME_FAIL: {
      return {
        ...state,
        nearMeProperies: [],
        nearMePropertyLoading: false,
      };
    }
    case types.GET_PROPERTY_COUNCIL_REQUEST: {
      return {
        ...state,
        councilData: null,
        propertyCouncilLoader: true,
      };
    }
    case types.GET_PROPERTY_COUNCIL_SUCCESS: {
      return {
        ...state,
        councilData: action.payload,
        propertyCouncilLoader: false,
      };
    }
    case types.GET_PROPERTY_COUNCIL_FAILED: {
      return {
        ...state,
        councilData: null,
        propertyCouncilLoader: false,
      };
    }

    case types.GET_TRANSPORT_REQUEST: {
      return {
        ...state,
        transportData: null,
        transportLoader: true,
      };
    }
    case types.GET_TRANSPORT_SUCCESS: {
      return {
        ...state,
        transportData: action.payload,
        transportLoader: false,
      };
    }
    case types.GET_TRANSPORT_FAILED: {
      return {
        ...state,
        transportData: null,
        transportLoader: false,
      };
    }
    case types.GET_PROPERTY_SCHOOL_REQUEST: {
      return {
        ...state,
        schoolData: null,
        propertySchoolLoader: true,
      };
    }
    case types.GET_PROPERTY_SCHOOL_SUCCESS: {
      return {
        ...state,
        schoolData: action.payload,
        propertySchoolLoader: false,
      };
    }
    case types.GET_PROPERT_SCHOOL_FAILED: {
      return {
        ...state,
        schoolData: null,
        propertySchoolLoader: false,
      };
    }
    
    case types.GET_MAINTANANCE : {
      return {
        ...state,
        maintenanceData: null,
        maintenanceDataLoading: true,
      };
    }
    case types.GET_MAINTANANCE_SUCCESS : {
      return {
        ...state,
        maintenanceData: action.payload,
        maintenanceDataLoading: false,
      };
    }
    case types.GET_MAINTANANCE_FAIL : {
      return {
        ...state,
        maintenanceData: null,
        maintenanceDataLoading: false,
      };
    }
    
    default:
      return state;
  }
};

export default propertyReducer;
