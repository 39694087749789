import React, { useState } from "react";
import {
  Image,
  TouchableOpacity,
  View,
  FlatList,
  Text,
  SafeAreaView,
} from "react-native";
import { Video } from "expo-av";
import { MaterialIcons } from "@expo/vector-icons";
import getPlatformType from "../../helpers/getPlatform";
import { ImageBackground } from "react-native";
const layoutType = getPlatformType();
const ImageSlider = (props) => {
  const [index, SetIndex] = useState(0);
  const { data, borderTopRightRadius, videoHeight } = props;
  
  return (
    <View
      style={{
        width: "100%",
        height: "100%",
        justifyContent: "center",
      }}
    >
      {data && data?.length ? (
        <View
          style={{
            width: "100%",
            height: "100%",
            borderTopRightRadius: borderTopRightRadius
              ? borderTopRightRadius
              : 20,
          }}
        >
          <Image
            source={{
              uri: data[index],
            }}
            // resizeMode={"contain"}
            style={{
              width: "100%",
              height: "100%",
              borderTopRightRadius: borderTopRightRadius
                ? borderTopRightRadius
                : 20,
            }}
          />
        </View>
      ) : (
        <View
          style={{
            width: "100%",
            height: "100%",
            borderTopRightRadius: borderTopRightRadius
              ? borderTopRightRadius
              : 20,
          }}
        >
          <Image
            source={{
              uri: "https://assets.openbrix.co.uk/nophoto.jpg",
            }}
            // resizeMode={"contain"}
            style={{
              width: "100%",
              height: "100%",
              borderTopRightRadius: borderTopRightRadius
                ? borderTopRightRadius
                : 20,
            }}
          />
        </View>
      )}

      {index == 0 ? null : (
        <TouchableOpacity
          onPress={() => SetIndex(index - 1)}
          style={{ position: "absolute", left: 10 }}
        >
          <MaterialIcons name="navigate-before" size={28} color="white" />
        </TouchableOpacity>
      )}
      {index == data?.length - 1 ? null : (
        <TouchableOpacity
          onPress={() => SetIndex(index + 1)}
          style={{ position: "absolute", right: 10 }}
        >
          <MaterialIcons name="navigate-next" size={28} color="white" />
        </TouchableOpacity>
      )}
    </View>
  );
};

export default ImageSlider;
