import React from 'react';
import { View, Image, TouchableOpacity, Text } from "react-native"
import getPlatformType from '../../../helpers/getPlatform';
import { Card, HestiaLogo, NormalText } from '../styles';
import { Ionicons } from '@expo/vector-icons';
const TopSection = (props) => {
    const layoutType = getPlatformType();
    return (
        <>
            {!props?.hideBack && <TouchableOpacity onPress={() => props?.goBack()} style={{ padding: 10, borderWidth: 1, alignSelf: 'flex-start', marginTop: 30, justifyContent: 'center', alignItems: 'center', borderRadius: 100 }}>
                <Text style={{ fontSize: 15, fontFamily: 'Lato' }}>
                    <Ionicons name="ios-arrow-back" size={20} color="black" />  Back to Welcome Screen
                </Text>
            </TouchableOpacity>}
            <View style={{ flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center', marginVertical: 30 }}>
                <Card width={550}>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20 }}>
                        <NormalText style={{ fontWeight: '700' }}>{props?.title}</NormalText>
                        <Image source={require('../../../assets/images/zopa_green_logo.png')} style={{ width: 55, height: 30 }} />
                    </View>
                    <NormalText>{props?.body}
                    </NormalText>
                </Card>
                {layoutType != "phone" && <HestiaLogo source={require("../../../assets/SVG/hestia.svg")} resizeMode="contain" />}
            </View>
        </>
    )
}
export default TopSection;