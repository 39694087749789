import { Text, View, Button } from "react-native";
import React, { useEffect, useState } from "react";
import { isAndroid, isIOS } from "react-device-detect";
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'

import { COLORS } from "../../constants/colorPallete";
import getPlatformType from "../../helpers/getPlatform";

export default function AppBanner() {

  const layout = getPlatformType();
  const [open, setOpen] = useState(false)

  const openApp = () => {
    let href = "https://play.google.com/store/apps/details?id=com.tlyfe";
    if (isAndroid) {
       href = "https://play.google.com/store/apps/details?id=com.tlyfe";
    } else if(isIOS) {
       href = "https://apps.apple.com/app/tlyfe/id6449941281";
    }
    window.open(href, '_blank', 'noreferrer');
  }

  useEffect(() => {
    if (layout == 'phone') {
        const visited = localStorage.getItem('appBarVisited');
        if (!visited) {
            setOpen(true);
            localStorage.setItem('appBarVisited', true);
        }
    }
  }, []);

  return (
    <BottomSheet open={open}>
        <View style={{ marginTop: 10, marginBottom: 24, flex: 1 }}>
            <Text style={{fontWeight: "bold", alignSelf: 'center' }}>
                Tlyfe is better on the app
            </Text>
            <View
                style={{
                    flex: 1,
                    margin: 10,
                    paddingLeft: 40,
                    paddingRight: 40,
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }}
            >
                <Button onPress={openApp} title="Open App" color={COLORS.LGREEN} />
                <Button onPress={()=> { setOpen(false) }} title="Continue" color={COLORS.GREY1}/>
            </View>
        </View>
    </BottomSheet>
  );
}