import React, { useState } from "react";
import { Image } from "react-native";
import {
  Container,
  SectionWrapper,
  DualView,
  TextBox,
  SectionTitle,
  SectionText,
  ImageBox,
  TeamsSection,
  MemberBoxWrapper,
  MemberBox,
  MemberImage,
  MemberName,
  MemberNameText,
  MemberDetails,
  MemberDetailsText,
  MemberHeader,
  TeamHeader,
  ListCol,
  ColTitle,
  TitleLogo,
  ListText,
  ColorText,
  DFlex,
} from "./styles";

import getPlatformType from "../../helpers/getPlatform";
const layoutType = getPlatformType();

import Header from "../../commonComponents/headerComponent/headerComponent";
import Footer from "../../commonComponents/footerComponent/customFooter";
import TripleTabs from "../../commonComponents/tripleTabs/tripleTabs";

const TabAgent = (props) => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  

  return (
    <SectionWrapper>
   
      <DualView
        style={{
          flexDirection: layoutType !== "phone" ? "row-reverse" : "column",
          alignItems: "flex-start",
        }}
      >
        <ImageBox style={{ height: layoutType == "web" ? 278 : 200 }}>
          <Image
            source={require("../../assets/images/IG_square_TEMPLATE4.png")}
            style={{
              width: "100%",
              height: layoutType == "web" ? 278 : 200,
              resizeMode: "contain",
            }}
          ></Image>
        </ImageBox>
        <TextBox>
          <SectionTitle>Marketplace</SectionTitle>
          <SectionText>
          Much like an App Store, OpenBrix has integrated with a large number of approved industry suppliers. Agents can visit the MarketPlace and buy products for their clients, themselves or any transactions. Whether an Agent needs a “Virtual Tour”, a home media subscription for a consumer, a digital conveyancing process, or purchase “white” goods for a property, we have made the integration easy and negate the need for form filling, emails and phone calls. Simply choose the property, click on the product and purchase. Earn substantial and transparent referral fees as part of the process.
          </SectionText>
        </TextBox>
      </DualView>
      <DualView>
        <ImageBox>
          <Image
            source={require("../../assets/images/renovation.png")}
            style={{ width: "100%", height: "100%", resizeMode: "cover" }}
          ></Image>
        </ImageBox>
        <TextBox style={{ paddingTop: 0 }}>
          <SectionTitle>
          OpenBrix offers Agents control over the 
community
          </SectionTitle>
          <SectionText>
          Being a member of the OpenBrix community means that each agent on the platform, has a voice and a vote towards how OpenBrix works.

          </SectionText>
          <SectionText>
          Blockchain technology ensures that you have the power to decide on how we price the products and how we utilise the data. True democracy.

          </SectionText>
          <SectionTitle>
          Data & Transparency

          </SectionTitle>
          <SectionText>
          Bringing transparency of customer data to the property market is at the heart of everything we do at OpenBrix. Transparency coupled with decentralisation of control means that all parties on the network can interact and transact without intermediaries, whilst being fully informed at every step of the process.

          </SectionText>
        </TextBox>
      </DualView>
    </SectionWrapper>
  );
};

export default TabAgent;
