import React, { Component } from "react";
import {
  View,
  Text,
  Dimensions,
  SafeAreaView,
  Image,
  TouchableOpacity,
  Platform,
  ScrollView,
} from "react-native";
import List from "../commonComponents/ListComponent";
import Header from "../commonComponents/headerComponent/headerComponent";
import { Container, Main } from "./searchRenterScreen.style";
import getPlatformType from "../helpers/getPlatform";
import { ButtonView } from "./searchFilter/searchFilterStyles";
import ButtonWithTitle from "../commonComponents/buttonWithTitle/buttonWithTitle";
import WideButton from "../commonComponents/wideButton";
const layoutType = getPlatformType();
import { MaterialIcons, FontAwesome } from "@expo/vector-icons";
import ListHeader from "../commonComponents/ListHeader";
import GoogleMaps from "../commonComponents/MapViewComponent/mapView";
import SearchFilter from "./searchFilter/searchFilter";
import Footer from "../commonComponents/footerComponent/customFooter";
import idx from "idx";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
const WIN_HEIGHT=Dimensions.get('window').height;
import { ContainerScrollView } from "../helpers/appStyles";
import {
  search,
  mapSearch,
  mapDetails,
  removeMapDetails,
  saveProperty,
  savePageData,
} from "../redux/actions/property";
import { saveSearchObject, saveSortValue } from "../redux/actions/app";
import Loader from "../commonComponents/activityIndicator/activityIndicator";
import NoDataFound from "./NoDataFound";
const { height, width: deviceWidth } = Dimensions.get("window");
let sublayoutType = "";
class SearchRenterScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expand: true,
      showFilter: false,
      currentView: "list",
      open: false,
      showModal: false,
      data: [],
      page: 1,
      pageData: [],
      refresh: false,
      flag: false,
    };
  }
  componentDidMount() {
    // this._fetchData();
    if (deviceWidth >= 768 && deviceWidth < 1024) {
      sublayoutType = "phone";
    }
    this._onFetchProperties();
    this._onFetchMapProperties();
  }
  componentDidUpdate(prevProps) {
    //Typical usage, don't forget to compare the props
    if (this.props?.searchObject?.search !== prevProps?.searchObject?.search) {
      this._onFetchProperties();
      this._onFetchMapProperties();
    }
  }
  onSortBy = (e) => {
    const { search, searchObject, navigation, sortValue } = this.props;
    const { page, pageData } = this.state;
    search(searchObject, e, page, (response) => {
      // this.setState({pageData:page==1?response:[...pageData,...response],refresh:false})
    });
    this.props.saveSortValue(e);
  };
  _onFetchProperties = () => {
    const { search, searchObject, navigation, sortValue } = this.props;
    const { page, pageData } = this.state;

    console.log("Sort values is",sortValue)
    search(searchObject, sortValue, page, (response) => {});
    this.setState({ flag: true });
  };

  _onFetchMapProperties = () => {
    const { mapSearch, searchObject, navigation, sortValue } = this.props;
    const { page, pageData } = this.state;
    mapSearch(searchObject, sortValue, page, (response) => {});
  };
  onExpand = async (value) => {
    let arr = this.state.data;
    arr.splice(2, 1);
    this.setState({ expand: value, data: arr });
  };
  mapScreen = () => {
    return <View style={{ flex: 1, backgroundColor: "pink" }}></View>;
  };
  closeExpand = async () => {
    let arr = this.state.data;
    await arr.splice(2, 0, { id: "map" });
    this.setState({ expand: false, data: arr });
  };
  _onValue = (e) => {
    this.setState({ currentView: e });
  };
  _onFilterSearch = (ob1, ob2) => {
    // const { searchObject } = this.props;
    const { search, searchObject, navigation, sortValue } = this.props;
    const { page, pageData } = this.state;
    let newSearchObj = { ...ob1, ...ob2 };

    // 
    // 
    // 
    this._onFetchMapProperties();
    search(newSearchObj, sortValue, 1, (response) => {
      //  this.setState({pageData:response})
    });

    this.props.saveSearchObject(newSearchObj);
    this.setState({ showFilter: false, flag: true });
  };
  onLoadMore = () => {
    this.setState(
      {
        page: this.state.page + 1,
        refresh: true,
      },
      () => this._onFetchProperties()
    );
  };
  render() {
    const { navigate, goBack } = this.props.navigation;
    const { searchData, loading, searchObject, mapDetailsLoading,authDataFromMobile } = this.props;
    const {
      expand,
      data,
      currentView,
      showFilter,
      showModal,
      refresh,
    } = this.state;
    // 
    if (loading && !refresh) {
      return <Loader loading={loading} />;
    }
    return (
      <SafeAreaView style={{ backgroundColor: "#FAFAFA",height:WIN_HEIGHT }}>
     <Header
     authDataFromMobile={authDataFromMobile}
            showLogoWeb
            showLogoTab
            drawerWithFilterMob
            headerButtonsTab={sublayoutType == "phone" ? false : true}
            headerButtonsWeb
            rightChildLabelTab
            rightChildLabelWeb
            showDrawerTab
            headerButtonAction={() => this.setState({ showFilter: true })}
            {...this.props}
            sortBy={(e) => this.onSortBy(e)}
            sortValue={this.props.sortValue}
            onBackAction={() =>
              navigate("tlyfe - powered by OpenBrix")
            }
          />
          {/* <Loader loading={mapDetailsLoading} /> */}
            <ContainerScrollView>
          <Container>
            {currentView == "list" ? (
              <View
                style={{
                  alignItems: "center",
                  width:
                    layoutType == "phone" || sublayoutType == "phone"
                      ? "100%"
                      : null,
                  height: "100%",
                }}
              >
                {layoutType == "phone" || sublayoutType == "phone" ? (
                  <ListHeader
                    _onValue={(e) => this._onValue(e)}
                    {...this.props}
                    style={{ position: "absolute", top: 0 }}
                    sortBy={(e) => this.onSortBy(e)}
                    sortValue={this.props.sortValue}
                    showFilter={sublayoutType == "phone" ? true : false}
                    filterButtonAction={() =>
                      this.setState({ showFilter: true })
                    }
                  />
                ) : null}
                <List
                  navigate={navigate}
                  isExpand={(e) => this.onExpand(e)}
                  open={expand}
                  searchObject={searchObject}
                  searchAPI={this.props.search}
                  sortValue={this.props.sortValue}
                  onSave={this.props.saveProperty}
                  data={this.props.pageData}
                  flag={this.state.flag}
                  loadMore={() => this.onLoadMore()}
                  toggleRefresh={(value) => this.setState({ refresh: value })}
                  savePageData={this.props.savePageData}
                  totalPage={this.props.totalProperties}
                />
              </View>
            ) : (
              <View style={{ width: "100%", height: "100%" }}>
                {layoutType == "phone" || sublayoutType == "phone" ? (
                  <ListHeader
                    _onValue={(e) => this._onValue(e)}
                    style={{ position: "absolute" }}
                    sortBy={(e) => this.onSortBy(e)}
                    sortValue={this.props.sortValue}
                    showFilter={sublayoutType == "phone" ? true : false}
                    filterButtonAction={() =>
                      this.setState({ showFilter: true })
                    }
                  />
                ) : null}
                <GoogleMaps
                  navigate={navigate}
                  data={this.props.searchMapData}
                  polygon={this.props.polygon}
                  // schools={this.props?.searchMapData ? [this.props.searchMapData[4],this.props.searchMapData[1]]:null}
                  // transports={this.props?.searchMapData ? [this.props.searchMapData[0],this.props.searchMapData[2]]:null}
                  searchObject={searchObject}
                  onSave={this.props.saveProperty}
                  onMarkerSelect={(id) => {
                    this.props.mapDetails(id);
                  }}
                  onMarkerRemove={(id) => {
                    this.props.removeMapDetails();
                  }}
                  selectedPlace={this.props.selectedPlace}


                />
              </View>
            )}
            {layoutType != "phone" && sublayoutType != "phone" ? (
              <View
                style={{
                  width: layoutType !== "tab" ? 364 : 300,
                  marginLeft: 15,
                  marginTop: 10,
                  alignSelf: "flex-start",
                }}
              >
                <GoogleMaps
                  navigate={navigate}
                  data={this.props.searchMapData}
                  polygon={this.props.polygon}
                  searchObject={searchObject}
                  onSave={this.props.saveProperty}
                  onMarkerSelect={(id) => {
                    this.props.mapDetails(id);
                  }}
                  onMarkerRemove={(id) => {
                    this.props.removeMapDetails();
                  }}
                  selectedPlace={this.props.selectedPlace}
                />
              </View>
            ) : null}
          </Container>
          
          {layoutType != "phone" ? <Footer /> : null}
          </ContainerScrollView>
          {showFilter ? (
            <View
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                top: 100,
              }}
            >
              <SearchFilter
                onBack={() => this.setState({ showFilter: false })}
                searchObject={this.props.searchObject}
                onApply={(e1, e2) => this._onFilterSearch(e1, e2)}
                propertyFor={
                  searchObject.filters ? searchObject.filters.property_for : 1
                }
              />
            </View>
          ) : null}
     </SafeAreaView> 
    );
  }
}
const mapStateToProps = (state) => ({
  loading: state.propertyReducer.searchLoading,
  searchData: idx(state, (_) => _.propertyReducer.searchData.data),
  searchMapData: idx(state, (_) => _.propertyReducer.searchMapData.locations),
  polygon: idx(state, (_) => _.propertyReducer.searchMapData.polygon),
  searchObject: idx(state, (_) => _.appReducer.searchObject),
  sortValue: state?.appReducer?.sortValue,
  pageData: state?.propertyReducer?.pageData,
  mapDetailsLoading: state.propertyReducer.selectedPlaceLoading,
  selectedPlace: idx(state, (_) => _.propertyReducer.selectedPlace.data),
  totalProperties: state?.propertyReducer?.totalProperties,
  authDataFromMobile: state?.appReducer?.mobileData?.data?.isWebView,

});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      search,
      mapSearch,
      mapDetails,
      saveProperty,
      saveSearchObject,
      saveSortValue,
      savePageData,
      removeMapDetails,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(SearchRenterScreen);