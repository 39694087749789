import React, { useState } from "react";
import {
  View,
  Text,
  SafeAreaView,
  Image,
  TouchableOpacity,
  Platform,
  ScrollView,
  Button,
} from "react-native";
import {
  Container,
  SectionWrapper,
  DualView,
  TextBox,
  SectionTitle,
  SectionText,
  ImageBox,
  TeamsSection,
  MemberBoxWrapper,
  MemberBox,
  MemberImage,
  MemberName,
  MemberNameText,
  MemberDetails,
  MemberDetailsText,
  MemberHeader,
  TeamHeader,
} from "./styles";

import getPlatformType from "../../helpers/getPlatform";
const layoutType = getPlatformType();

import Header from "../../commonComponents/headerComponent/headerComponent";
import Footer from "../../commonComponents/footerComponent/customFooter";
import TripleTabs from "../../commonComponents/tripleTabs/tripleTabs";
import TabHowItWorks from "./TabHowItWorks";
import TabTenantBuyer from "./TabTenantBuyer";
import TabAgent from "./TabAgent";
import { Dimensions } from "react-native";
import OpenFlo from "./Openflo";
import Tlyfe from "./Tlyfe";
import Mls from "./Mls";
import BackGroundGenerator from "../LandingPages/common/BackGroundGenerator";
import { backgroundSearchArr } from "../LandingPages/images/openFlo/searchArrayOpenflo";

const AboutUs = (props) => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const tabList = [
    {
      label: "Openbrix",
      target: <TabHowItWorks  />,
      targetfooter: null,
    },
    {
      label: "Openflo",
      targetfooter: null,
      target: <OpenFlo />,
    },
    {
      label: "tlyfe",
      target: <Tlyfe />,
      targetfooter: null,
    },
    {
      label: "mls",
      targetfooter: null,
      target: <Mls />,
    },
    
  ];
  const setCurrentTab = (index) => {
    
    setCurrentTabIndex(index);
  };
  let arr = JSON.parse(JSON.stringify(backgroundSearchArr));
  return (
    <BackGroundGenerator isLanding  props={props} hideMiddleLogo>

        <Container style={{marginTop:100}}>
          <TripleTabs
            isFullWidth={false}
            tabList={tabList}
            title="About us"
            setCurrentTabIndex={setCurrentTab}
          />
        </Container>
        {currentTabIndex == 0 ? (

            <TeamsSection>
          </TeamsSection>


        ) : null}  
    </BackGroundGenerator>
  );
};

export default AboutUs;
