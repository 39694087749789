import React from "react";
import { Text, View } from "react-native";
import { ODSIcon } from "../atoms/ODSIcon";
const iconData = require('../atoms/ODSIcon/selection.json');

export const Icons = () => {
  return (
    <View>
      <Text style={{ textAlign: 'center', marginVertical: 10, fontSize: 28, fontWeight: 'bold' }}>Icons</Text>
      <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
        {iconData.icons.map((e) =>
          <View style={{ margin: 10, justifyContent: 'center', alignItems: 'center' }}>
            <Text style={{ marginBottom: 10 }}>{e.properties.name}</Text>
            <ODSIcon icon={e.properties.name} size={20} />
          </View>
        )}
      </View>
      <Text style={{ backgroundColor: '#3a3b3c', color: 'white', padding: 10, textAlign: 'center' }}>{`
  import { ODSIcon } from 'openbrix-design-system';

  <ODSIcon icon='TB-Arrow-Left' size={20} color={'black'} />`}</Text>
    </View>

  )
}