import { View } from 'react-native'; 
import React, { useState, useEffect } from 'react'; 
import Modal  from "react-native-modal";
import { useDispatch, useSelector } from 'react-redux'; 

import RepaymentText from './repaymentText'; 
import { tdsActions } from './tdsRedux/tds.actions'; 
import { backgroundTdsArr } from './backgroundTdsArr'; 
import getPlatformType from '../../helpers/getPlatform'; 
import { navigateAction } from '../../helpers/navigationHelpers';
import BackGroundGenerator from '../LandingPages/common/BackGroundGenerator'; 
import { Container, BoxShadow, Typography, LineSaperator, ButtonWrapper } from './style'; 
 
const custodialRespond = (props) => { 
  const dispatch = useDispatch(); 
  const layoutType = getPlatformType(); 
  const [type, setType] = useState('web'); 
  const [tenancy, setTenancy] = useState({}); 
  const [tenants, setTenants] = useState([]);
  const [repayment, setRepayment] = useState({}); 
  const [agreedStatus, setAgreedStatus] = useState('dont_agree'); 
  const [modal, setModal] = useState({ visible: false, text: '' }); 
  const respondSuccess =  useSelector((state) => state.tds?.respondSuccess);
  const repaymentResponse = useSelector((state) => state.tds?.repaymentProposal); 

  useEffect(() => {
    dispatch(tdsActions.getDepositRepaymentProposal(props.route.params.case_id, props?.route?.params?.tenancy_id, props?.route?.params?.dan));
  }, []);
 
  useEffect(() => { 
    if (repaymentResponse) { 
      setTenants(repaymentResponse?.tenancy?.tenants)
      setTenancy(repaymentResponse?.tenancy); 
      setRepayment(repaymentResponse?.repayment?.repaymentProposal)
    }
  }, [repaymentResponse]); 

  useEffect(() => { 
    if (respondSuccess) {
      navigateAction(props.navigation, 'Custodial Dashboard', { 'tenancy_id': props?.route?.params?.tenancy_id, 'dan': props?.route?.params?.dan })
    }
  }, [respondSuccess]); 
  
  const renderDepositSection = () => { 
    return ( 
      <BoxShadow> 
        <Typography 
           id={"TOTAL_DEPOSIT_HELD"}
        color={'#194592'} size={'23px'} bold={700}> 
          Total Deposit Held 
        </Typography> 
        <Typography bottom={'31px'} top={'27px'} color={'#194592'} size={'38px'} bold={800}> 
          £ {tenancy?.tenancy?.deposit_amount} 
        </Typography> 
        <LineSaperator /> 
        <Typography top={'34px'} color={'#194592'} size={'23px'} bold={700}> 
          Tenancy end date 
        </Typography> 
 
        <Typography top={'19px'} bottom={'27px'} color={'#194592'} size={'20px'} bold={500}> 
          Supplied by Landlord/Agent : {repayment?.tenancy_end_date_landlord }
        </Typography> 
       </BoxShadow> 
    ); 
  }; 
 
  const renderPreviewSection = () => { 
    return ( 
      <BoxShadow bottom={'40px'} top={'40px'}> 
        <Typography bottom={'3px'} align={'left'} color={'#194592'} size={'23px'} bold={700}> 
          Repayment to Tenants 
        </Typography> 
         
         { tenants?.map(tenant=>{
            return (
              <RepaymentText colHeading={'Landlord/Agent Request'} colValue={tenant.amount_to_tenant_rr_ll} label={tenant.fullname} />  
            )
         })}
 
        <RepaymentText colValue={repayment?.amount_to_tenants_landlord_rrll}  label={'Total'}  lColor={'#194592'} />

        <Typography 
          top={'60px'} 
          bottom={'3px'} 
          align={'left'} 
          color={'#194592'} 
          size={'23px'} 
          bold={700} 
        > 
          Repayment to Landlord 
        </Typography> 
        <Typography align="left" top={'40px'} color={'#000000'} size={'20px'} bold={500}> 
          {tenancy?.property?.membername}
        </Typography> 
        <RepaymentText colHeading={'Landlord/Agent Request'} colValue={repayment?.amount_to_agent_cleaning} label={'Cleaning'} /> 
        <RepaymentText colValue={repayment?.amount_to_agent_damage} label={'Damage to Property/Contents'} /> 
        <RepaymentText colValue={repayment?.amount_to_agent_redecoration} label={'Redecoration'} /> 
        <RepaymentText colValue={repayment?.amount_to_agent_gardening} label={'Gardening'} /> 
        <RepaymentText colValue={repayment?.amount_to_agent_rent_arrears} label={'Rent Arrears'} /> 
        <RepaymentText colValue={repayment?.amount_to_agent_other} label={'Other or Unknonw'} /> 
        <RepaymentText colValue={repayment?.amount_to_agent_rr} label={'Total'} lColor={'#194592'} /> 
        <> 
          <Typography top={'100px'} align={'left'} color={'#659B63'} size={'23px'} bold={800}> 
            Response to Repayment Request 
          </Typography> 
          <Typography top={'51px'} color={'#454545'} size={'20px'} bold={400}> 
            Please select one of the following options: 
          </Typography> 
 
          <ButtonWrapper 
            top={'51px'} 
            color={'#FFFFFF'} 
            bcolor={agreedStatus == 'agree' ? '#A4D4A2' : '#194592'} 
            radius={'11px'} 
            onPress={() => { 
              setAgreedStatus('agree'); 
            }} 
          > 
            <Typography 
              color={agreedStatus == 'agree' ? '#A4D4A2' : '#194592'} 
              size={'19px'} 
              bold={800} 
            > 
              I agree with the repayment request 
            </Typography> 
          </ButtonWrapper> 
          <ButtonWrapper 
            top={'51px'} 
            color={'#FFFFFF'} 
            bcolor={agreedStatus == 'agree1' ? '#A4D4A2' : '#194592'} 
            radius={'11px'} 
            onPress={() => { 
              setAgreedStatus('agree1'); 
            }} 
          > 
            <Typography 
              color={agreedStatus == 'agree1' ? '#A4D4A2' : '#194592'} 
              size={'19px'} 
              bold={800} 
            > 
              I agree with the repayment request and wish to change the amount each tenant receives 
            </Typography> 
          </ButtonWrapper> 
          <ButtonWrapper 
            top={'51px'} 
            color={'#FFFFFF'} 
            bcolor={agreedStatus == 'agree2' ? '#A4D4A2' : '#194592'} 
            radius={'11px'} 
            onPress={() => { 
              setAgreedStatus('agree2'); 
            }} 
          > 
            <Typography 
              color={agreedStatus == 'agree2' ? '#A4D4A2' : '#194592'} 
              size={'19px'} 
              bold={800} 
            > 
              I do not agree with the repayment request and wish to resolve the dispute using the
              scheme’s dispute resolution mechanism
            </Typography> 
          </ButtonWrapper> 
          <ButtonWrapper 
            top={'51px'} 
            color={'#FFFFFF'} 
            bcolor={agreedStatus == 'agree3' ? '#A4D4A2' : '#194592'} 
            radius={'11px'} 
            onPress={() => { 
              setAgreedStatus('agree3'); 
            }} 
          > 
            <Typography 
              color={agreedStatus == 'agree3' ? '#A4D4A2' : '#194592'} 
              size={'19px'} 
              bold={800} 
            > 
              I do not agree with the repayment request and wish to resolve the dispute 
              using the scheme’s dispute resolution mechanism
            </Typography> 
          </ButtonWrapper> 
        </> 
 
        <ButtonWrapper 
          top={'75px'} 
          onPress={() => { 
            setModal({ 
              visible: true, 
              text: '', 
            }); 
          }} 
        > 
          <Typography color={'#FFFFFF'} size={'19px'} bold={800}> 
            {'SUBMIT'} 
          </Typography> 
        </ButtonWrapper> 
      </BoxShadow> 
    ); 
  }; 
 
  return ( 
    <> 
      <BackGroundGenerator 
        isTds 
        props={props} 
        layoutType={(data) => { 
          setType(data); 
        }} 
        isTLYF 
        infoIcon 
        backgroundArr={backgroundTdsArr} 
        isBack={true} 
        backNavigation={() => props?.navigation?.goBack()} 
      > 
        <Container> 
          {renderDepositSection()} 
 
          {renderPreviewSection()} 
        </Container> 
        <Modal isVisible={modal.visible} transparent={true} coverScreen={false} hasBackdrop={false} backdropOpacity={0} onBackdropPress={() => setModal({ visible: false, text: '', })} style={{ margin: 0, width: '100%', flex: 1, backgroundColor: '#00000080', paddingVertical: layoutType == 'phone' ? '5%' : '1%', paddingHorizontal: layoutType == 'phone' ? '8%' : '3%' }} > 
          <View style={{ width: layoutType == 'phone' ? '365px' : '500px', backgroundColor: '#FAFAFA', borderRadius: 20, overflow: 'hidden', alignSelf: 'center', padding: layoutType == 'phone' ? '5%' : '2%' }} > 
            <Typography color={'#659B63'} size={'23px'} bold={800}> 
              Response to Repayment Request 
            </Typography> 
            {agreedStatus == 'agree' ? ( 
              <> 
                <Typography align="left" top={'41px'} color={'#454545'} size={'20px'} bold={400}> 
                  You are agreeing to the agent/landlord’s repayment request. 
                </Typography> 
                <Typography align="left" top={'17px'} color={'#454545'} size={'20px'} bold={400}> 
                  This means that we will pay the deposit in accodance with thier request. 
                </Typography> 
                <Typography align="left" top={'17'} color={'#454545'} size={'20px'} bold={400}> 
                  Please confirm this is what you want to happen to the deposit. 
                </Typography> 
              </> 
            ) : ( 
              <Typography align="left" top={'41px'} color={'#454545'} size={'20px'} bold={400}> 
                This selected response action will have to be continued on the website 
              </Typography> 
            )} 
            <ButtonWrapper 
              color={'#638ACC'} 
              width={'100%'} 
              style={{ marginTop: 30 }} 
              onPress={() => {
                if(agreedStatus == 'agree') {
                  dispatch(tdsActions.updateDepositRepaymentProposal(props.route.params.case_id));
                } else {
                  window.open('https://www.tenancydepositscheme.com/log-in/', "_blank")
                }
                setModal({ visible: false, text: '' });
              }}
            > 
              <Typography color={'#FFFFFF'} size={'19px'} bold={700}> 
                {agreedStatus == 'agree' ? 'Continue' : 'Open website'} 
              </Typography> 
            </ButtonWrapper> 
            <ButtonWrapper 
              bcolor={'#638ACC'} 
              color={'#FFFFFF'} 
              width={'100%'} 
              style={{ marginTop: 30 }} 
              onPress={() => { 
                setModal({ 
                  visible: false, 
                  text: '', 
                }); 
              }} 
            > 
              <Typography color={'black'} size={'19px'} bold={700}> 
                Cancel 
              </Typography> 
            </ButtonWrapper> 
          </View> 
        </Modal> 
      </BackGroundGenerator> 
    </> 
  ); 
}; 
 
export default custodialRespond; 
