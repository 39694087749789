import React, { useCallback, useEffect, useRef, useState } from "react";
import { Dimensions, FlatList, Image, ImageBackground, ScrollView, Text, TouchableOpacity, View } from "react-native";
import AutoHeightImage from "../common/AutoImage";
import stylesMobile from "./stylesMobile";
import stylesTab from "./stylesTab";
import stylesWeb from "./stylesWeb";
import { backgroundSearchArr } from "../images/tlyfe/searchArrayTlyfe";
import BackGroundGenerator from "../common/BackGroundGenerator";
import { navigateAction, trackAnalytics } from "../../../helpers/navigationHelpers";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "../../openFlow/style";
import WideButton from "../../../commonComponents/wideButton";
import { COLORS } from "../../../constants/colorPallete";
import Form from "../../rentalLandingScreen/form";
import getPlatformType from "../../../helpers/getPlatform";
import CommonAuth from "./CommonAuth";
import ImageView from "./imageView";
import { saveSearchObject } from "../../../redux/actions/app";
import { LogoutApi } from "../../../redux/actions/auth";

const FEATURED = [
  {
    icon: require("./images/times.png"),
  },
  {
    icon: require("./images/ft.png"),
  },
  {
    icon: require("./images/industry.png"),
  },
  {
    icon: require("./images/kerfuffle.png"),
    isLastElemet:true
  },
];
const LandingOpenBrix = (props) => {
  const authData =useSelector((state) => state?.appReducer?.mobileData?.data?.isWebView);
  const [type, setType] = useState("web");
  const layoutType = getPlatformType();
  const scrollViewRef = useRef();

  const dispatch = useDispatch();
  const isAnylyticsEnable = useSelector(
    (state) => state?.cookiesReducer?.customiseCookies.Analytics,
  );
  const { navigate } = props.navigation;
  const navigateTo = (params, from) => {
    if (from) {
      navigateAction(props?.navigation, params, from);
    } else {
      navigateAction(props?.navigation, params);
    }
  };
  const [property_for, setPropertyFor] = useState(1);
  const styles = type == "web" ? stylesWeb : type == "mobile" ? stylesMobile : stylesTab;
  const [state, setState] = useState({
    from: "",
  });

  const _onSearch = (value) => {
    isAnylyticsEnable ? trackAnalytics("Button", "OnClick", "Property-Search") : null;
    dispatch(saveSearchObject(value));
    props.navigation.navigate("Search");
  };

  useEffect(() => {
    if (props?.route?.params?.from) {
      setState({
        ...state,
        from: props?.route?.params?.from ? props?.route?.params?.from : "",
      });
    }
  }, [props?.route?.params?.from]);
  const handleScrollButtonClick = useCallback(() => {

    const height=Dimensions.get('window').height;
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTo({ y:layoutType=='web'?height:height/2, animated: true });
    }
  }, []);
  const token = useSelector((state) => state?.appReducer?.loginData?.data?.token);
  let BottomWrapper = type == "web" ? ImageBackground : View;
  return (
    <>
      <BackGroundGenerator
        props={props}
        layoutType={(data) => setType(data)}
        backgroundArr={backgroundSearchArr}
        isLanding
        cloudHeader={true}
        scrollViewRef={scrollViewRef}
      >
      
        {token ? null : <>
          <CommonAuth
          onScrollButtonClick={handleScrollButtonClick}
            heading={"RENT SMARTER \nWITH TLYFE,"}
            navigation={navigate}
            type={type}
            subheading={"LOGIN AND/OR REGISTER TODAY"}
            showRegistrationForm={true}
            bigbuttonfunction={() => {
            }
            }
            desc={"TO FIND OUT MORE ABOUT THE BENEFITS OF USING TLYFE, THE UKs NUMBER ONE TENANT APP"}
            {...props}
          />
          <ImageView
            type={type}
            heading={"AGENTS CLICK HERE TO FIND OUT HOW WE CAN HELP YOU."}
            subheading={" AUTOMATE AND TRANSFORM TENANT ONBOARDING."}
            onButtonClick={() => {
              navigateTo("LandingAgent");
            }}
            id={"AGENTCLICKHERE"}
            buttonText={"AGENTS CLICK HERE"}
            imageUrl={require("./second.png")}
          />
          <View style={styles.textContainer}>
            <View
              style={[
                styles.shawow,
                {
                  width: "100%",
                  flexDirection: "column",
                  paddingVertical: "7%",
                  paddingHorizontal: "6%",
                  borderRadius: 23,
                  borderWidth: 0.01,
                  borderColor: COLORS.GREY1,
                  alignItems: "center",
                },
              ]}
            >
              <TouchableOpacity onPress={() => navigateTo("landingSearch")}>
                <AutoHeightImage
                  style={{
                    width: 169,
                    alignSelf: "center",
                  }}
                  uri={require("./../images/openFlo/web/OpenBrixLogo.svg")}
                />
              </TouchableOpacity>
              <Typography
                line={"35px"}
                raj
                bold={"600"}
                top={"40px"}
                color={"#1C1C1C"}
                size={"26px"}
                align={"center"}
                bottom={"40px"}
              >
                tlyfe is part of the OpenBrix suite of applications helping to bring harmony to the
                property sector, seamlessly connecting tenants, agents and suppliers on a single
                platform.
              </Typography>
              <WideButton
                width={"471px"}
                onPress={() => {
                  navigateTo("landingTlyfe");
                }}
                gradiant
                id={"TENANTSCLICKHERE"}
                childText="TENANTS CLICK HERE"
                height={"65px"}
                gradBorderRadius={50}
                isRaj={true}
                textSize={24}
              />
            </View>
          </View>
          <View
            style={{
              paddingHorizontal: "8%",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "##FAFAFA",
              paddingVertical: 60,
            }}
          >
            <Typography
              bottom={"30px"}
              line={type !== "web" ? "26px" : "52px"}
              raj
              bold={"700"}
              color={"#1C1C1C"}
              size={type !== "web" ? "26px" : "48px"}
            >
              AS FEATURED IN:
            </Typography>

            <FlatList
              data={FEATURED}
              horizontal={true}
              contentContainerStyle={{
                flexGrow: 1,
                justifyContent: "center",
              }}
              style={{
                overscrollBehaviorY: "auto",
                width: "100%",
                touchAction: "auto",
              }}
              renderItem={({
                item,
                index,
              }) => (
                <View
                  style={[
                    {
                      width: 270,
                      borderRadius: 20,
                      justifyContent: "center",
                      alignItems: "center",
                      padding: 39,
                      marginRight:item?.isLastElemet ?0: 71,
                      backgroundColor: "white",
                      marginTop: index % 2 === 0 ? 20 : 0,
                    },
                  ]}
                  id={index}
                >
                  <Image
                    resizeMode={"contain"}
                    source={item.icon}
                    style={{
                      width: 200,
                      height: 97,
                    }}
                  />
                </View>
              )}
            />
          </View>
        </>}
        <BottomWrapper source={require("./bottom.png")} style={styles.searchSection}>
         {authData?null: <View
            style={{
              flex: 0.5,
              marginLeft: type !== "web" ? 0 : "10%",
            }}
          >
            <Typography
              raj
              bold={"700"}
              color={"white"}
              size={type !== "web" ? "40px" : "90px"}
              line={type !== "web" ? "48px" : "87px"}
              align={type == "web" ? "flex-start" : "center"}
            >
              PROPERTY SEARCH:
            </Typography>
            <Typography
              raj
              color={"white"}
              bold={"700"}
              size={type !== "web" ? "28px" : "45px"}
              line={type !== "web" ? "29px" : "50px"}
              top={"20px"}
              align={type == "web" ? "flex-start" : "center"}
            >
              LOOKING FOR YOUR NEW FOREVER HOME?
            </Typography>
            <Typography
              bottom={"20px"}
              line={type == "web" ? "37px" : "26px"}
              raj
              top={"20px"}
              color={"white"}
              size={type == "web" ? "30px" : "18px"}
              align={type == "web" ? "flex-start" : "center"}
            >
              LET US KICK-START YOUR JOURNEY WITH OUR COMPREHENSIVE PROPERTY SEARCH
            </Typography>
          </View>}
          <View
            style={{
              flex: 0.5,
            }}
          >
            <View
              style={{
                width: type == "web" ? "90%" : "100%",
                alignSelf: "center",
              }}
            >
              <View
                style={{
                  width: "60%",
                  backgroundColor: "#FFFFFF",
                  height: 62,
                  borderTopRightRadius: 20,
                  borderTopLeftRadius: 20,
                  flexDirection: "row",
                  alignSelf: "center",
                }}
              >
                <TouchableOpacity
                  onPress={() => setPropertyFor(1)}
                  style={{
                    flex: 0.5,
                    borderTopLeftRadius: 20,
                    backgroundColor: property_for == 1 ?   "white":COLORS.BLACK,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  id={"PROPERTY_RENT_TAB"}
                >
                  <Text
                    style={{
                      fontFamily: "lato-bold",
                      fontSize: 16,
                      color: property_for == 1 ?  COLORS.BLACK:"white",
                    }}
                  >
                    RENT
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => setPropertyFor(0)}
                  style={{
                    flex: 0.5,
                    borderTopRightRadius: 20,
                    justifyContent: "center",
                    alignItems: "center",
                    borderLeftWidth: 1,
                    backgroundColor: property_for == 0 ?  "white": COLORS.BLACK,
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "lato-bold",
                      fontSize: 16,
                      color: property_for == 0 ? COLORS.BLACK: "white",
                    }}
                    id={"PROPERTY_BUY_TAB"}
                  >
                    BUY
                  </Text>
                </TouchableOpacity>
              </View>
              <View
                style={{
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#FFFFFF",
                  borderRadius: 20,
                }}
              >
                <View style={{ width: "100%" }}>
                  <View style={{
                    paddingHorizontal: 10,
                    paddingVertical: 20,
                  }}>
                    <Form
                      onSearch={(e) => _onSearch(e)}
                      navigate={navigate}
                      property_for={property_for}
                      {...props}
                    />
                  </View>
                </View>
              </View>
            </View>
          </View>
        </BottomWrapper>
      </BackGroundGenerator>

    </>
  );
};
export default LandingOpenBrix;
