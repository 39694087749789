import React from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { TouchableOpacity } from "react-native";

import { NormalText } from "../styles";
import getPlatformType from "../../../helpers/getPlatform";
import DropDownPicker from "../../../commonComponents/DropDownPicker";
import { DropDownSectionWrapper, DropDownWrapper } from "../../searchFilter/form.style";
import DatePickerComponent from '../../../commonComponents/datepicker/DatePickerComponent';
import Regex from "../../../helpers/regex";

const layoutType = getPlatformType();

const TitleArray = [
  { value: "Mr ", label: "Mr" },
  { value: "Miss", label: "Miss" },
  { value: "MS", label: "Ms" },
  { value: "MRS", label: "Mrs" },
];

const Text = styled.Text`
  color: #000;
  font-family: 'Lato';
`;

const Title = styled(Text)`
  font-size: 17px;
  color: rgb(255, 181, 128);
  font-weight: 600;
  font-family: "Lato";
`;

const LabelText = styled(Title)`
  font-size: 15px;
  color: #000;
  font-weight: 500;
  font-family: 'Lato';
`;

const RowContainer = styled.View`
  display: flex;
  flex-direction: ${props => (props.platform == 'web' ? "row" : (props.platform == 'tab') ? 'row' : 'column')};
`;

const NormalView = styled.View`
  display: flex;
`;

const ColumnContainer = styled.View`
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: flex-end;
`;

const TitleContainer = styled.View`
  margin: 10px 0 10px 20px;
`;

const InputContainerSmall = styled.TextInput`
  width: ${props => (props.platform == 'web' ? "130px" : (props.platform == 'tab') ? '130px' : 'auto')};
  height: 40px;
  border-radius: 6px;
  border: 1px solid #d0d0d0;
  margin-top: 10px;
  padding-left: 20px;
  position: relative;
`;

const InputContainerBig = styled(InputContainerSmall)`
  width: ${props => (props.platform == 'web' ? "274px" : (props.platform == 'tab') ? 'fit-content' : '100%')};
`;

const ErrorText = styled.Text`
  color: red;
  font-family: 'Lato';
  font-size: 12px;
  position: absolute;
  bottom: 2px;
`;

const PersonalInfoTab = (data) => {

  const onChangeTextHandler = (fieldName, value, isEmpty) => {
    data.dataSetter(fieldName, value);
    if (value.length > 0) {
      data.checkEmptyStatus(isEmpty, false)
    } else {
      data.checkEmptyStatus(isEmpty, true)
    }
  };

  return (
    <>
      <RowContainer platform={layoutType}>
        <TitleContainer>
          <Title>Personal</Title>
        </TitleContainer>
      </RowContainer>
      <RowContainer style={{ justifyContent: 'space-between' }} platform={layoutType}>
        <ColumnContainer>
          <LabelText>Title</LabelText>
          <DropDownWrapper>
            <DropDownSectionWrapper style={{ flexGrow: 2, width: layoutType == "phone" ? '100%' : '180px' }}>
              <DropDownPicker
              id={"ZOPA_TITLE"}
                items={TitleArray}
                defaultValue={data.data.title}
                onChangeItem={(e) => data.dataSetter('title', e.value)}
              />
            </DropDownSectionWrapper>
          </DropDownWrapper>
          {data.personalInfoEmptyStatus.titleIsEmmpty && <ErrorText>This field is required</ErrorText>}
        </ColumnContainer>
        <ColumnContainer>
          <LabelText>First Name</LabelText>
          <InputContainerBig 
            id={"ZOPA_FNAME"}
          onChangeText={(text) =>Regex.validateWithSpecialName(text) &&  onChangeTextHandler('firstName', text, 'firstNameIsEmmpty')}
            value={data.data.firstName} platform={layoutType}
          />
          {data.personalInfoEmptyStatus.firstNameIsEmmpty && <ErrorText>This field is required</ErrorText>}
        </ColumnContainer>
        <ColumnContainer>
          <LabelText>Last Name</LabelText>
          <InputContainerBig
           id={"ZOPA_LNAME"}
          onChangeText={(text) =>Regex.validateWithSpecialName(text) && onChangeTextHandler('lastName', text, 'lastNameIsEmmpty')}
            value={data.data.lastName} platform={layoutType}
          />
          {data.personalInfoEmptyStatus.lastNameIsEmmpty && <ErrorText>This field is required</ErrorText>}
        </ColumnContainer>
      </RowContainer>
      <RowContainer style={{ justifyContent: 'space-between' }} platform={layoutType}>
        <ColumnContainer>
          <View style={{ width: layoutType == "phone" ? '100%' : '180px' }}>
            <DatePickerComponent 
             placeholder={"Date of Birth"}
             is18yrs
            value={data.data.birthDate} onUpdateInput={(date) => { data.dataSetter('birthDate', date);data.checkEmptyStatus("birthDateIsEmmpty", false) }} />
          </View>
          {data.personalInfoEmptyStatus.birthDateIsEmmpty && <ErrorText>This field is required</ErrorText>}
        </ColumnContainer>

        <ColumnContainer>
          <LabelText>Telephone</LabelText>
          <InputContainerBig
          id={"ZOPA_NUMBER"}
          onChangeText={(text) => {
            const numericText = text.replace(/[^0-9]/g, '');

    const truncatedText = numericText.slice(0, 13);
            onChangeTextHandler('telephone', truncatedText, 'telephoneIsEmmpty')}}
            value={data.data.telephone} platform={layoutType}
          />
          {data.personalInfoEmptyStatus.telephoneIsEmmpty && <ErrorText>This field is required</ErrorText>}
        </ColumnContainer>
        <ColumnContainer>
          <LabelText>Email</LabelText>
          <InputContainerBig 
           id={"ZOPA_EMAIL"}
          onChangeText={(text) => onChangeTextHandler('email', text, 'emailIsEmmpty')}
            value={data.data.email} platform={layoutType}
          />
          {data.personalInfoEmptyStatus.emailIsEmmpty && <ErrorText>This field is required</ErrorText>}
        </ColumnContainer>
      </RowContainer>


      <RowContainer platform={layoutType}>
        <TitleContainer>
          <Title>Current Address</Title>
        </TitleContainer>
      </RowContainer>
      <RowContainer platform={layoutType}>
      </RowContainer>
      <RowContainer platform={layoutType}>
        <ColumnContainer>
          <LabelText>House No. <Text style={{color:'red'}}>*</Text></LabelText>
          <InputContainerBig 
           id={"ZOPA_HOUSEN"}
          onChangeText={(text) => onChangeTextHandler('houseNumber', text, 'houseNumberIsEmmpty')}
            value={data.data.houseNumber} platform={layoutType}
          />
          {data.personalInfoEmptyStatus.houseNumberIsEmmpty && <ErrorText>This field is required</ErrorText>}
        </ColumnContainer>
      </RowContainer>
      <RowContainer platform={layoutType}>
        <ColumnContainer>
          <LabelText>Address Line 1 <Text style={{color:'red'}}>*</Text></LabelText>
          <InputContainerBig 
            id={"ZOPA_ADDRESS1"}
          onChangeText={(text) => onChangeTextHandler('address1', text, 'address1IsEmmpty')}
            value={data.data.address1} platform={layoutType}
          />
          {data.personalInfoEmptyStatus?.address1IsEmmpty && <ErrorText>This field is required</ErrorText>}
        </ColumnContainer>
        <ColumnContainer>
          <LabelText>Address Line 2 <Text style={{color:'red'}}>*</Text></LabelText>
          <InputContainerBig 
           id={"ZOPA_ADDRESS2"}
          onChangeText={(text) => onChangeTextHandler('address2', text, 'address2IsEmmpty')}
            value={data.data.address2} platform={layoutType}
          />
          {data.personalInfoEmptyStatus.address2IsEmmpty && <ErrorText>This field is required</ErrorText>}
        </ColumnContainer>
      </RowContainer>
      <RowContainer platform={layoutType}>
        <ColumnContainer>
          <LabelText>Town / City <Text style={{color:'red'}}>*</Text></LabelText>
          <InputContainerBig 
           id={"ZOPA_TOWN"}
          onChangeText={(text) =>Regex.validateBankName(text) && onChangeTextHandler('city', text, 'cityIsEmmpty')}
            value={data.data.city} platform={layoutType}
          />
          {data.personalInfoEmptyStatus.cityIsEmmpty && <ErrorText>This field is required</ErrorText>}
        </ColumnContainer>

        <ColumnContainer>
          <LabelText>Post Code <Text style={{color:'red'}}>*</Text></LabelText>
          <InputContainerBig
           id={"ZOPA_POSTCOE"}
            onChangeText={(text) => onChangeTextHandler('postalCode', text, 'postalCodeIsEmmpty')}
            value={data.data.postalCode} platform={layoutType}
          />
          {data.personalInfoEmptyStatus.postalCodeIsEmmpty && <ErrorText>This field is required</ErrorText>}
        </ColumnContainer>
      </RowContainer>

      <RowContainer platform={layoutType}>
        <ColumnContainer>
          <View style={{ width: layoutType == "phone" ? '100%' : '274px',marginTop: 10 }}>
            <DatePickerComponent 
            placeholder={"Date moved into this property?"}
            id={"ZOPA_MOVEIN"}
            value={data.data.moveInDate} onUpdateInput={(date) => { data.dataSetter('moveInDate', date);data.checkEmptyStatus("moveInDateIsEmmpty", false) }} />
          </View>
          {data.personalInfoEmptyStatus.moveInDateIsEmmpty && <ErrorText>This field is required</ErrorText>}
        </ColumnContainer>
      </RowContainer>
      {data?.ButtonView()}
    </>
  );
}

export default PersonalInfoTab;