import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { tdsActions } from './tdsRedux/tds.actions';
import { backgroundTdsArr } from './backgroundTdsArr';
import { navigateAction } from '../../helpers/navigationHelpers';
import BackGroundGenerator from '../LandingPages/common/BackGroundGenerator';
import { Container, LineSaperator, ButtonWrapper, BlueBox, BoxShadow, Typography, primaryColor } from './style';

const custodialChangePassword = (props) => {
  const dispatch = useDispatch();
  const [type, setType] = useState('web');

  const triggerChangePassword = () => {
    dispatch(tdsActions.triggerResetPassword());
  };

  return (
    <>
      <BackGroundGenerator
        isTds
        props={props}
        layoutType={(data) => {
          setType(data);
        }}
        isTLYF
        backgroundArr={backgroundTdsArr}
        isBack={true}
        infoIcon
        backNavigation={()=> navigateAction(props.navigation, 'Custodial Account') }
      >
        <Container>
          <BoxShadow>
              <Typography color={primaryColor} size={'23px'} bold={600}>
                Change Password
              </Typography>
            <Typography top={'40px'} color={'#454545'} size={'20px'} bold={400}>
              Pressing the button below will initiate the password change process.
            </Typography>
            <Typography color={'#454545'} size={'20px'} bold={400}>
              You will be sent an email containing a link to follow.
            </Typography>
            <Typography bottom={'30px'} top={'20px'} color={'#454545'} size={'20px'} bold={400}>
              Upon following this link you will be asked to enter a new password.
            </Typography>
            <LineSaperator />
            <ButtonWrapper 
            id={"TDS_CHANGE_MY_PASS"}
            top={'40px'} onPress={() => triggerChangePassword()}>
              <Typography 
               id={"TDS_CHANGE_MY_PASS_TEXT"}
              color={'#FFFFFF'} size={'19px'} bold={700}>
                CHANGE MY PASSWORD
              </Typography>
            </ButtonWrapper>
          </BoxShadow>
        </Container>
      </BackGroundGenerator>
    </>
  );
};

export default custodialChangePassword;
