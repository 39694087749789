import React, { useState, useEffect } from "react";
import { Dimensions, Image, TouchableOpacity, View } from "react-native";
import Header from "./Header";
import { useTheme } from "@emotion/react";
import { ODSText } from "../../../openbrix-design-system/ui/atoms/ODSText";
import getScreenType from "../../../openbrix-design-system/utils/getScreenType";
import { Body, Content, HeaderContent, HeaderImage, LoginCard, Picture1, RegisterCard, VideoWrapper } from "./styles";
import Footer from "./Footer";
import Wrapper from "./Wrapper";
import VimeoPlayer from "../../commonComponents/youtubePlayer/vimeoPlayer";
import Constant from "../tds/tdsRedux/Constant";
import { ODSInput } from "../../../openbrix-design-system/ui/atoms/ODSInput";
import ODSButton from "../../../openbrix-design-system/ui/atoms/ODSButton";
import { _get, BASE_URL, GOOGELELOGIKEY, SERVER_URL, URL } from "../../helpers/connectionHelpers";
// import GoogleLogin from "react-google-login";
import ODSCheckbox from "../../../openbrix-design-system/ui/atoms/ODSCheckbox";
import { ODSIcon } from "../../../openbrix-design-system/ui/atoms/ODSIcon";
import ODSCombobox from "../../../openbrix-design-system/ui/atoms/ODSComboBox";
import label from "../openFlow/constants";
import { ODSModal } from "../../../openbrix-design-system/ui/atoms/ODSModal/ODSModal.web";
import { useDispatch } from "react-redux";
import { EmailValidation, MobileValidation, NameValidation, PasswordValidation } from "../../helpers/validation";
import { googleLogin, login, resetPassword, signup } from "../../redux/actions/auth";
import Regex from "../../helpers/regex";
import { types } from "../../redux/actionTypes";
import { useGoogleLogin } from "@react-oauth/google";


const RegisterLanding = (props) => {
    const { colors } = useTheme();
    const layout = getScreenType();
    const dispatch = useDispatch();
    const [register_refrence, setRegister_refrence] = useState('');

    const [secureText, setSecureText] = useState(true);
    const [sourceList, setSourceList] = useState([]);
    const [tenancy_reg_status, setTenancy_reg_status] = useState('');
    const [tenencyList, setTenancyList] = useState([
{name: "Currently Renting", key: "currently_renting"},
{name: "Not Renting", key: "not_renting"},
{name: "Moving in next 30 days", key: "next_30"},
{name: "N/A", key: "N/A"},
    ]);

    const [state, setState] = useState({
        is_tenant_concierge_service:false,
        tncStatus: false,
        contactStatus: false,
        buyerEmail: "",
        buyerEmailErrorMsg: "",
        buyerFirstName: "",
        buyerFirstNameErrorMsg: "",
        buyerLastName: "",
        buyerLastNameErrorMsg: "",
        buyerMobile: "",
        buyerMobileErrorMsg: "",
        buyerPassword: "",
        buyerPasswordErrorMsg: "",
        validateCheckbox: false,
        propertyAgent: "",
        isRenter: true,
        isCurrentRentProperty: true,
        selectedTab: "Tab1",
        recaptchaValue: false,
    });
    const resetState = () => {
        setState({
            tncStatus: false,
            contactStatus: false,
            buyerEmail: "",
            buyerEmailErrorMsg: "",
            buyerFirstName: "",
            buyerFirstNameErrorMsg: "",
            buyerLastName: "",
            buyerLastNameErrorMsg: "",
            buyerMobile: "",
            buyerMobileErrorMsg: "",
            buyerPassword: "",
            buyerPasswordErrorMsg: "",
            validateCheckbox: false,
            propertyAgent: "",
            isRenter: true,
            isCurrentRentProperty: true,
            selectedTab: "Tab1",
            recaptchaValue: false,
            is_tenant_concierge_service:false
        });
    };
    const {
        navigation,
        isTLYF,
        params
    } = props;
    useEffect(() => {
        getSourceDetails()
      }, []);
      const getSourceDetails = ()=>{
        _get(
          `${SERVER_URL}${URL.getsource}`,
          {}, // all headers here (if)
          {}, // body here (if)
          (res) => {
            // function to perform on success
          console.log("getsource",res)
          if(res&&res?.data&&res?.data?.length){
            setSourceList(res?.data)
          }
          },
          (err) => {
            // function to perform on failure
          
          }
        );
      }
    useEffect(() => {
        if (params?.from) {
            setState({
                ...state,
                buyerEmail: params?.from ? params?.from : "",
            });
        }
    }, [params]);
    useEffect(() => {
        if (props?.route?.params?.from) {
            setState({
                ...state,
                from: props?.route?.params?.from ? props?.route?.params?.from : "",
            });
        }
    }, [props?.route?.params?.from]);
    const _onSignup = () => {
        const {
            tncStatus,
            contactStatus,
            buyerEmail,
            buyerFirstName,
            buyerMobile,
            buyerPassword,
            propertyAgent,
            isCurrentRentProperty,
            isRenter,
            buyerLastName,
            is_tenant_concierge_service,
            /* recaptchaValue,*/
            from,
        } = state;
        let emailCheck = EmailValidation(buyerEmail);
        let firstNameCheck = NameValidation(buyerFirstName);
        let tenancy_reg_statusCheck = NameValidation(tenancy_reg_status);
        let lastNameCheck = NameValidation(buyerLastName);
        let mobileCheck = MobileValidation(buyerMobile);
        let passwordCheck = PasswordValidation(buyerPassword);
        if (
            emailCheck.status &&
            mobileCheck.status &&
            passwordCheck.status &&
            firstNameCheck.status &&
            lastNameCheck.status &&
            tenancy_reg_statusCheck.status&&
            tncStatus
        ) {
            setState({
                ...state,
                validateCheckbox: false,
            });
            dispatch(
                signup(
                    {
                        first_name: buyerFirstName,
                        last_name: buyerLastName,
                        contact_number: buyerMobile,
                        email: buyerEmail.trim(),
                        password: buyerPassword,
                        isRenter: isRenter,
                        isCurrentRentProperty: isCurrentRentProperty,
                        propertyAgentName: propertyAgent,
                        user_type: 0,
                        registered_from: from,
                        is_tenant_concierge_service:is_tenant_concierge_service,
                        register_refrence:register_refrence,
                        tenancy_reg_status:tenancy_reg_status
                    },
                    props.navigation.navigate,
                    () => {
                        resetState();
                        dispatch({
                            type: types.SHOW_ALERT,
                            alertType: "success",
                            text: "Registration Successful!",
                        });
                       props.changeView();
                    },
                ),
            );
        } else {
            setState({
                ...state,
                buyerFirstNameErrorMsg: buyerFirstName ? "" : "This is a required field",
                buyerLastNameErrorMsg: buyerLastName ? "" : "This is a required field",
                buyerEmailErrorMsg: emailCheck.status ? "" : emailCheck.msg,
                buyerPasswordErrorMsg: passwordCheck.status ? "" : passwordCheck.msg,
                buyerMobileErrorMsg: mobileCheck.status ? "" : mobileCheck.msg,
                validateCheckbox: true,
                tenancy_reg_statusMsg:tenancy_reg_status?"":"This is a required field"
            });
        }
    };

    React.useEffect(() => {
        if (props?.route?.params?.from) {
            setState({
                ...state,
                from: props?.route?.params?.from ? props?.route?.params?.from : "",
            });
        }
    }, [props?.route?.params?.from]);

    useEffect(() => {
        if (props?.route?.params?.email) {
            setState({
                ...state,
                buyerEmail: props?.route?.params?.email ? props?.route?.params?.email : "",
            });
        }
    }, [props?.route?.params?.email]);



    const _onGoogleLogin = (googleResponse) => {
        if (googleResponse?.access_token) {
          dispatch(
            googleLogin(
              {
                accessToken: googleResponse?.access_token,
                platform: 'web',
                registered_from: 'web',
              },
              props.navigation
            )
          );
        } else {
          dispatch({
            type: types.SHOW_ALERT,
            text: "Google login failed with an unknown error, please contact support, in the meantime, you can login using email and password. If you've signed up using google, you can reset your password to continue",
          });
        }
      };
      const loginWithGoogle = useGoogleLogin({
        onSuccess: (tokenResponse) => _onGoogleLogin(tokenResponse),
      });
    const {
        tncStatus,
        contactStatus,
        buyerEmail,
        buyerEmailErrorMsg,
        buyerFirstName,
        buyerFirstNameErrorMsg,
        buyerMobileErrorMsg,
        buyerMobile,
        buyerLastNameErrorMsg,
        buyerPassword,
        buyerPasswordErrorMsg,
        buyerLastName,
        validateCheckbox,
        propertyAgent,
        isRenter,
        isCurrentRentProperty,
        is_tenant_concierge_service,
        tenancy_reg_statusMsg
      } = state;
      console.log("dfdfdfdf",state)
    return (
        <RegisterCard>
            <ODSText textAlign='center' type="h3" color={colors.text.secondary}>Register</ODSText>
            <ODSText type="md" color={colors.text.secondary} marginTop={12} textAlign={'center'}>Already have an account? <TouchableOpacity onPress={() => props.changeView()}><ODSText textDecorationLine='underline' type='md-bold' color={colors.main.purple[700]}>Login</ODSText></TouchableOpacity> </ODSText>

            <View style={{ marginTop: 32, gap: 32 }}>
                <View style={{ flexDirection: layout == "phone" ? 'column' : 'row', justifyContent: 'space-between', flexWrap: 'wrap', gap: 12 }}>
                    <ODSInput
                        label="First Name"
                        placeholder="first name"
                        style={{ width: layout == 'phone' ? '100%' : 259 }}
                        value={state.buyerFirstName}
                        onChange={(firstName) => {
                            Regex.validateWithSpecialName(firstName) &&
                             setState({
                               ...state,
                               buyerFirstName: firstName,
                               buyerFirstNameErrorMsg: "",
                             });
                           }}
                           hints={buyerFirstNameErrorMsg == "" ?[]:[{hint:buyerFirstNameErrorMsg,state:'error'}]}
                    />
                    <ODSInput
                        label="Last Name"
                        placeholder="last name"
                        value={state.buyerLastName}
                        style={{ width: layout == 'phone' ? '100%' : 259 }}
                        onChange={(lastName) =>
                            Regex.validateWithSpecialName(lastName) &&
                            setState({
                              ...state,
                              buyerLastName: lastName,
                              buyerLastNameErrorMsg: "",
                            })
                          }
                          hints={buyerLastNameErrorMsg == "" ?[]:[{hint:buyerLastNameErrorMsg,state:'error'}]}

                   />
                    <ODSInput
                        label="Email"
                        placeholder="email"
                        style={{ width: layout == 'phone' ? '100%' : 259 }}
                        value={buyerEmail}
                        onChange={(email) =>
                            setState({
                              ...state,
                              buyerEmail: email,
                              buyerEmailErrorMsg: "",
                            })
                          }
                          hints={buyerEmailErrorMsg == "" ?[]:[{hint:buyerEmailErrorMsg,state:'error'}]}
   
                   />
                    <ODSInput
                        label="Contact number"
                        placeholder="contact"
                        value={buyerMobile}
                        style={{ width: layout == 'phone' ? '100%' : 259 }}
                        onChange={(mobile) =>{
                          const numericValue = mobile.replace(/[^0-9]/g, '');
                          setState({
                            ...state,
                            buyerMobile: numericValue,
                            buyerMobileErrorMsg: "",
                          });

                        }
                           
                          }
                          keyboardType="number-pad"
                          hints={buyerMobileErrorMsg == "" ?[]:[{hint:buyerMobileErrorMsg,state:'error'}]}

                  />
                    <ODSInput
                        label="Password"
                        placeholder="password"
                        style={{ width: layout == 'phone' ? '100%' : 259 }}
                        secureTextEntry={secureText}
                        onChange={(password) =>
                            setState({
                              ...state,
                              buyerPassword: password,
                              buyerPasswordErrorMsg: "",
                            })
                          }
                          hints={buyerPasswordErrorMsg == "" ?[]:[{hint:buyerPasswordErrorMsg,state:'error'}]}
                          renderRightIcon={<TouchableOpacity onPress={() => {
                            setSecureText(!secureText);
                        }}>
                            <ODSIcon icon={secureText ? 'eye-blocked' : 'eye'} size={20} />
                        </TouchableOpacity>}
                   />
                    <View style={{ width: layout == 'phone' ? '100%' : 259,zIndex:1000 }}>
                        <ODSCombobox 
                       
                         width={layout == "phone" ? Dimensions.get('screen').width - 48 : 259} 
                         onChange={(value) => {
                            setRegister_refrence(value.value);
                          }}
                          placeholder={register_refrence ? register_refrence : ''}
                          label="Where did you hear "
                          data={
                            sourceList && sourceList.length
                              ? sourceList.map((salutation) => {
                                  return { value: salutation.key, label: salutation.name };
                                })
                              : []
                          }/>

                    </View>
                    <View style={{ width: layout == 'phone' ? '100%' : "100%",zIndex:1 }}>
                        <ODSCombobox 
                         width={layout == "phone" ? Dimensions.get('screen').width - 48 : "100%"} 
                         onChange={(value) => {
                          setTenancy_reg_status(value.value);
                          setState({
                            ...state,
                            buyerEmailErrorMsg: "",
                            tenancy_reg_statusMsg:""
                          })
                          }}
                          hints={tenancy_reg_statusMsg == "" ?[]:[{hint:tenancy_reg_statusMsg,state:'error'}]}
                          placeholder={tenancy_reg_status ? tenancy_reg_status : ''}
                          label="What is your tenancy status? *"
                          data={
                            tenencyList && tenencyList.length
                              ? tenencyList.map((salutation) => {
                                  return { value: salutation.key, label: salutation.name };
                                })
                              : []
                          }/>

                    </View>
                </View>
                <View style={{ borderRadius: 4, gap: 12, width: '100%', padding: 16, backgroundColor: colors.main.purple[100], flexDirection: 'row', borderWidth: 1, borderColor: colors.main.purple[400], zIndex: -100 }}>
                    <ODSIcon icon="TB-Info-Outline" size={24} color={colors.main.purple[400]} />
                    <ODSText type="md" color={colors.text.secondary} >You’re about to register as a tenant. <TouchableOpacity onPress={()=>{ window.open("https://agent.openbrix.co.uk/", '_blank');}}><ODSText textDecorationLine='underline' type='md-bold' color={colors.main.purple[700]}>Click here</ODSText></TouchableOpacity> if you want to register as an agent. </ODSText>

                </View>
                <View style={{ flexDirection: 'row', zIndex: -100, alignItems: 'center' }}>
                    <ODSCheckbox checked={tncStatus}  onPress={() =>
                  setState({
                    ...state,
                    tncStatus: !state.tncStatus,
                  })
                }/>
                    <ODSText type="md" color={colors.text.secondary} >I accept tlyfe <TouchableOpacity onPress={()=>window.open('https://tlyfe.co.uk/legal-bits?to=t%2Fc')}><ODSText textDecorationLine='underline' type='md-bold' color={colors.main.purple[700]}>T&Cs</ODSText></TouchableOpacity> and <TouchableOpacity onPress={()=>window.open('https://tlyfe.co.uk/legal-bits?to=policy')}><ODSText textDecorationLine='underline' type='md-bold' color={colors.main.purple[700]}>privacy policy</ODSText></TouchableOpacity> </ODSText>
                </View>
                <View style={{ flexDirection: 'row', zIndex: -100, alignItems: 'center' }}>
                    <ODSCheckbox checked={is_tenant_concierge_service}  onPress={() =>
                  setState({
                    ...state,
                    is_tenant_concierge_service: !state.is_tenant_concierge_service,
                  })
                }/>
              <View style={{ flexDirection: 'row', flexWrap: "wrap",flex:1}}>
              <ODSText marginLeft={8} type="sm" color={colors.text.secondary}>
                I wish to have access to the free Home concierge service, to help with my tenancy{" "}
                <TouchableOpacity onPress={() => window.open("https://my.home.cc/terms", '_blank')}>
                <ODSText textDecorationLine="underline" type="md-bold" color={colors.main.purple[700]}>
                  (Terms apply)
                </ODSText>
              </TouchableOpacity>
             </ODSText>
             
            </View>
                </View>
                {validateCheckbox && !tncStatus ? (
                <ODSText type="sm" color={colors.feedback.error[500]}>Please accept above condition.</ODSText>
              ) : null}

                <ODSButton
                    themeColor={colors.main.purple}
                    type="primary"
                    disabled={false}
                    restStyle={{ width: 250, alignSelf: 'center' }}
                   onPress={_onSignup}
                >
                    Signup
                </ODSButton>
                <View style={{alignSelf:"center"
       }}>
       
</View>
<TouchableOpacity
            onPress={()=>loginWithGoogle()}
            style={{
              height: 50,
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168)',
              borderRadius: '10px',
              flexDirection: 'row',
            }}
          >
            <Image
              source={require('../../../openbrix-design-system/images/icons/Google.png')}
              style={{
                marginRight: 14,
                height: 24,
                width: 24,
              }}
            />
            <ODSText type="md-bold" color={'rgba(0, 0, 0, 0.54)'}>
              {' '}
              Continue with Google
            </ODSText>
          </TouchableOpacity>
                <View style={{ width: '100%', height: 0.25, backgroundColor: '#2B3541' }} />
                <View style={{ flexDirection: 'row', gap: 24, justifyContent: 'center' }}>
                    <Image source={require('../../../openbrix-design-system/images/appstore.png')} style={{ width: 138, height: 40 }} />
                    <Image source={require('../../../openbrix-design-system/images/playstore.png')} style={{ width: 138, height: 40 }} />
                </View>
            </View>
        </RegisterCard>
    )
}
export default RegisterLanding;