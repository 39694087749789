import React from 'react';
import styled from 'styled-components/native';
import getPlatformType from '../../helpers/getPlatform';
import { COLORS } from "../../constants/colorPallete";
const layoutType = getPlatformType();
let LineSaperator = styled.View`
  height: 3px;
  background-color: ${COLORS.GREEN};
  margin-top: 6px;
`;
let Container = styled.View`
  align-items: center;
  width: 100%;
  margin-bottom: 12px;
`;
let Section = styled.View`
  width: 512px;
  margin: 8px;
  margin-top: 51px;
`;
let RowContainer = styled.View`
  flex-direction: row;
  margin-bottom: 17px;
`;
let Heading = styled.Text`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 36px;
  line-height: 24px;
  text-align: center;
  color: ${COLORS.LGREEN};
`;
let SubHeading = styled.Text`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 24px;
  text-align: center;
  color: black;
  margin-top: 16;
  width: 622px;
`;
let ProgressCard = styled.View`
width: 81%;
align-self: flex-end;
background: #FFFFFF;
justify-content: 'center'
box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.15);
border-radius: 8px;
padding: 15px;
padding-left: 100px;
`;

let CircularWrapper = styled.View`
  background: #ffffff;
  /* cardfinal */
  box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.15);
  width: 170px;
  height: 170px;
  border-radius: 80px;
  position: absolute;
  left: 0;
  top: -1%;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;

let ProgressCardLabel = styled.Text`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 16px;
  color: #000000;
  margin-top: 10px;
`;

let ProgressCardValue = styled.Text`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 19px;
  line-height: 24px;
  /* or 126% */

  /* Tlyfe orange */

  color: ${COLORS.LGREEN};
`;
let PropertyWrapper = styled.View`
  width: 100%;
  /* margin-top: 24px; */
  padding: 32px;
  background: #ffffff;
  box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  flex-direction: column;
  padding-right: 0px;
`;

let RentalWrapper = styled.View`
  margin-top: 24px;
`;
let ButtonWrapper = styled.View`
  margin-top:${layoutType=='phone'?'20px':'30px'};
  flex-direction: row;
  margin-bottom:${layoutType=='phone'?'10px':'0px'};

`;
let RHeading = styled.Text`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 19px;
  line-height: 24px;
  color: ${COLORS.LGREEN};
`;
let RValue = styled.Text`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  flex: 0.4;
  color: #000000;
`;
let RText = styled.Text`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 16px;
  color: #000000;
  flex: 0.6;
`;
let PropertyAdderss = styled.Text`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 17px;
  line-height: 24px;
  color: ${COLORS.LGREEN};
`;
let TabHeadingText = styled.Text`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 19px;
  line-height: 24px;
  text-align: center;
  color: ${(isActive) => (isActive ? `#ffb580` : `black`)};
`;

let TableLabes = styled.Text`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  line-height: 16px;
  margin-top: 10;
  margin-bottom: 10;
  color: #000000;
`;
let TableWrapper = styled.View`
background: #FFFFFF;
box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.15);
border-radius: 8px;
padding-top: 30px
padding-bottom: 30px;
padding-left: 16px;
padding-right: 16px;
height: 455px;
`;
if (layoutType == 'phone') {
  SubHeading = styled.Text`
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 24px;
    color: black;
    margin-left: 41px;
    margin-right: 74px;
    margin-top: 18px;
    margin-bottom: 40px;
  `;

  CircularWrapper = styled.View`
    background: #ffffff;
    /* cardfinal */
    box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.15);
    width: 170px;
    height: 170px;
    border-radius: 80px;
    position: absolute;
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    padding: 10px;
  `;

  RowContainer = styled.View`
    flex-direction: column;
    width: 100%;
    padding: 12px;
  `;

  Section = styled.View`
    width: 100%;
    margin-top: 10px;
  `;

  ProgressCard = styled.View`
  width: 75%;
  align-self: flex-end;
  background: #ffffff;
  justify-content: 'center'
  box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 10px;
  padding-left: 90px;
`;
} else if (layoutType == 'web') {
} else {
  RowContainer = styled.View`
    flex-direction: row;
    width: 100%;
    padding: 16px;
  `;

  Section = styled.View`
    width: 48%;
    margin: 8px;
    margin-top: 31px;
  `;

  ProgressCard = styled.View`
      width: 81%;
      align-self: flex-end;
      background: #ffffff;
      justify-content: 'center'
      box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.15);
      border-radius: 8px;
      padding: 10px;
      padding-left: 90px;
    `;
}

export {
  TableLabes,
  LineSaperator,
  TabHeadingText,
  TableWrapper,
  ButtonWrapper,
  RHeading,
  RValue,
  RText,
  RentalWrapper,
  PropertyAdderss,
  PropertyWrapper,
  CircularWrapper,
  ProgressCardValue,
  ProgressCardLabel,
  Container,
  Heading,
  SubHeading,
  RowContainer,
  Section,
  ProgressCard,
};
