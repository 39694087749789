import styled from "styled-components/native";
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Text, View,Image,TouchableOpacity } from 'react-native';

import { tdsActions } from './tdsRedux/tds.actions';
import { backgroundTdsArr } from './backgroundTdsArr';
import getPlatformType from '../../helpers/getPlatform';
import CheckBox from '../../commonComponents/checkbox/checkbox';
import { navigateAction } from '../../helpers/navigationHelpers';
import { generalColorsTheme } from './../../constants/colorPallete';
import DropDownPicker from "../../commonComponents/DropDownPicker";
import BackGroundGenerator from '../LandingPages/common/BackGroundGenerator';
import CustomTextInput from '../../commonComponents/customTextInput/customTextInput';
import { Title } from "../../commonComponents/customTextInput/customTextInput.style";
import { DropDownSectionWrapper, DropDownWrapper } from "../../components/searchFilter/form.style";
import { RowContainer, TabHeader, Container, ButtonWrapper, TabsWrapper, Typography, TabBody, Avtar, primaryColor } from './style';
import { types } from "../../redux/actionTypes";
import Regex from "../../helpers/regex";
import { useNavigationState } from '@react-navigation/native'; 

const custodialAccount = (props) => {
  const routesLength = useNavigationState(state => state.routes.length);
  const layoutType = getPlatformType();
  const dispatch = useDispatch();
  const [type, setType] = useState('web');
  const [account, setAccount] = useState({});
  const [activeTab, setActiveTab] = useState('Account');
  const [personalDetails, setPersonalDetails] = useState({});
  const tdsAccount = useSelector((state) => state.tds?.tdsAccount);
  const [preferences, setPreferences] = useState({ lead: false, marketing: false });
  const [accountEdit,setAccountEdit]=useState(false);
  const [bankEdit,setBankEdit]=useState(false);


  const countries = [
    { value: 'United Kingdom', label: 'United Kingdom' },
    { value: 'Afghanistan', label: 'Afghanistan' },
    { value: 'Aland Islands', label: 'Aland Islands' },
    { value: 'Albania', label: 'Albania' },
    { value: 'Algeria', label: 'Algeria' },
    { value: 'American Samoa', label: 'American Samoa' },
    { value: 'Andorra', label: 'Andorra' },
    { value: 'Angola', label: 'Angola' },
    { value: 'Anguilla', label: 'Anguilla' },
    { value: 'Antarctica', label: 'Antarctica' },
    { value: 'Antigua and Barbuda', label: 'Antigua and Barbuda' },
    { value: 'Argentina', label: 'Argentina' },
    { value: 'Armenia', label: 'Armenia' },
    { value: 'Aruba', label: 'Aruba' },
    { value: 'Australia', label: 'Australia' },
    { value: 'Austria', label: 'Austria' },
    { value: 'Azerbaijan', label: 'Azerbaijan' },
    { value: 'Bahamas', label: 'Bahamas' },
    { value: 'Bahrain', label: 'Bahrain' },
    { value: 'Bangladesh', label: 'Bangladesh' },
    { value: 'Barbados', label: 'Barbados' },
    { value: 'Belarus', label: 'Belarus' },
    { value: 'Belgium', label: 'Belgium' },
    { value: 'Belize', label: 'Belize' },
    { value: 'Benin', label: 'Benin' },
    { value: 'Bermuda', label: 'Bermuda' },
    { value: 'Bhutan', label: 'Bhutan' },
    { value: 'Bolivia', label: 'Bolivia' },
    { value: 'Bonaire', label: 'Bonaire' },
    { value: 'Bosnia and Herzegovina', label: 'Bosnia and Herzegovina' },
    { value: 'Botswana', label: 'Botswana' },
    { value: 'Bouvet Island', label: 'Bouvet Island' },
    { value: 'Brazil', label: 'Brazil' },
    { value: 'British Indian Ocean Territory', label: 'British Indian Ocean Territory' },
    { value: 'British Virgin Islands', label: 'British Virgin Islands' },
    { value: 'Brunei Darussalam', label: 'Brunei Darussalam' },
    { value: 'Bulgaria', label: 'Bulgaria' },
    { value: 'Burkina Faso', label: 'Burkina Faso' },
    { value: 'Burundi', label: 'Burundi' },
    { value: 'Cambodia', label: 'Cambodia' },
    { value: 'Cameroon', label: 'Cameroon' },
    { value: 'Canada', label: 'Canada' },
    { value: 'Cape Verde', label: 'Cape Verde' },
    { value: 'Cayman Islands', label: 'Cayman Islands' },
    { value: 'Central African Republic', label: 'Central African Republic' },
    { value: 'Chad', label: 'Chad' },
    { value: 'Chile', label: 'Chile' },
    { value: 'China', label: 'China' },
    { value: 'Christmas Island', label: 'Christmas Island' },
    { value: 'Cocos (Keeling) Islands', label: 'Cocos (Keeling) Islands' },
    { value: 'Colombia', label: 'Colombia' },
    { value: 'Comoros', label: 'Comoros' },
    { value: 'Congo', label: 'Congo' },
    { value: 'Cook Islands', label: 'Cook Islands' },
    { value: 'Costa Rica', label: 'Costa Rica' },
    { value: 'Cote D\'Ivoire', label: 'Cote D\'Ivoire' },
    { value: 'Croatia', label: 'Croatia' },
    { value: 'Cuba', label: 'Cuba' },
    { value: 'Curacao', label: 'Curacao' },
    { value: 'Cyprus', label: 'Cyprus' },
    { value:"Czech Republic", label: "Czech Republic" },
    { value:"Denmark", label: "Denmark " },
    { value:"Djibouti", label: "Djibouti" },
    { value:"Dominica", label: "Dominica" },
    { value:"Dominican Republic", label: "Dominican Republic" },
    { value:"Ecuador", label: "Ecuador" },
    { value:"Egypt", label: "Egypt" },
    { value:"El Salvador", label: " El Salvador" },
    { value:"Equatorial Guinea", label: "Equatorial Guinea" },
    { value:"Eritrea", label: "Eritrea" },
    { value:"Estonia", label: "Estonia" },
    { value:"Ethiopia", label: "Ethiopia" },
    { value:"Falkland Islands (Malvinas)", label: "Falkland Islands (Malvinas)" },
    { value:"Faroe Islands", label: "Faroe Islands" },
    { value:"Fiji", label: "Fiji" },
    { value:"Finland", label: "Finland" },
    { value:"France", label: "France" },
    { value:"French Guiana", label: "French Guiana" },
    { value:"French Polynesia", label: "French Polynesia" },
    { value:"French Southern Territories", label: "French Southern Territories" },
    { value:"Gabon", label: "Gabon" },
    { value:"Gambia", label: "Gambia" },
    { value:"Georgia", label: "Georgia" },
    { value:"Germany", label: "Germany" },
    { value:"Ghana", label: "Ghana" },
    { value:"Gibraltar", label: "Gibraltar" },
    { value:"Greece", label: "Greece" },
    { value:"Greenland", label: "Greenland" },
    { value:"Grenada", label: "Grenada" },
    { value:"Guadaloupe", label: "Guadaloupe" },
    { value:"Guam", label: "Guam" },
    { value:"Guatemala", label: "Guatemala" },
    { value:"Guernsey", label: "Guernsey" },
    { value:"Guinea", label: "Guinea" },
    { value:"Guinea-Bissau", label: "Guinea-Bissau" },
    { value:"Guyana", label: "Guyana" },
    { value:"Haiti", label: "Haiti" },
    { value:"Heard Island and McDonald Islands", label: "Heard Island and McDonald Islands" },
    { value:"Holy See (Vatican City State)", label: "Holy See (Vatican City State)" },
    { value:"Honduras", label: "Honduras" },
    { value:"Hong Kong", label: "Hong Kong" },
    { value:"Hrvatska", label: "Hrvatska" },
    { value:"Hungary", label: "Hungary" },
    { value:"Iceland", label: "Iceland" },
    { value:"India", label: "India" },
    { value:"Indonesia", label: "Indonesia" },
    { value:"Iran, Islamic Republic Of", label: "Iran, Islamic Republic Of" },
    { value:"Iraq", label: "Iraq" },
    { value:"Ireland", label: "Ireland" },
    { value:"Isle of Man", label: "Isle of Man" },
    { value:"Israel", label: "Israel" },
    { value:"Italy", label: "Italy" },
    { value:"Jamaica", label: "Jamaica" },
    { value:"Japan", label: "Japan" },
    { value:"Jersey", label: "Jersey" },
    { value:"Jordan", label: "Jordan" },
    { value:"Kazakhstan", label: "Kazakhstan" },
    { value:"Kenya", label: "Kenya" },
    { value:"Kiribati", label: "Kiribati" },
    { value:"Korea, Democratic People's Republic Of",  label: "Korea, Democratic People's Republic Of" },
    { value:"Korea, Republic of", label: "Korea, Republic of" },
    { value:"Kuwait", label: "Kuwait" },
    { value:"Kyrgyzstan", label: "Kyrgyzstan" },
    { value:"Lao People's Democratic Republic", label: "Lao People's Democratic Republic" },
    { value:"Latvia", label: "Latvia" },
    { value:"Lebanon", label: "Lebanon" },
    { value:"Lesotho", label: "Lesotho" },
    { value:"Liberia", label: "Liberia" },
    { value:"Libya", label: "Libya" },
    { value:"Liechtenstein", label: "Liechtenstein" },
    { value:"Lithuania", label: "Lithuania" },
    { value:"Luxembourg", label: "Luxembourg" },
    { value:"Macao", label: "Macao" },
    { value:"Macedonia, the Former Yugoslav Republic Of", label: "Macedonia, the Former Yugoslav Republic Of" },
    { value:"Madagascar", label: "Madagascar" },
    { value:"Malawi", label: "Malawi" },
    { value:"Malaysia", label: "Malaysia" },
    { value:"Maldives", label: "Maldives" },
    { value:"Mali", label: "Mali" },
    { value:"Malta", label: "Malta" },
    { value:"Marshall Islands", label: "Marshall Islands" },
    { value:"Martinique", label: "Martinique" },
    { value:"Mauritania", label: "Mauritania" },
    { value:"Mauritius", label: "Mauritius" },
    { value:"Mayotte", label: "Mayotte" },
    { value:"Mexico", label: "Mexico" },
    { value:"Micronesia, Federated States Of", label: "Micronesia, Federated States Of" },
    { value:"Moldova, Republic of", label: "Moldova, Republic of" },
    { value:"Monaco", label: "Monaco" },
    { value:"Mongolia", label: "Mongolia" },
    { value:"Montenegro", label: "Montenegro" },
    { value:"Montserrat", label: "Montserrat" },
    { value:"Morocco", label: "Morocco" },
    { value:"Mozambique", label: "Mozambique" },
    { value:"Myanmar", label: "Myanmar" },
    { value:"Namibia", label: "Namibia" },
    { value:"Nauru", label: "Nauru" },
    { value:"Nepal", label: "Nepal" },
    { value:"Netherlands", label: " Netherlands" },
    { value:"Netherlands Antilles", label: "Netherlands Antilles" },
    { value:"New Caledonia", label: "New Caledonia" },
    { value:"New Zealand", label: "New Zealand" },
    { value:"Nicaragua", label: "Nicaragua" },
    { value:"Niger", label: "Niger" },
    { value:"Nigeria", label: "Nigeria" },
    { value:"Niue", label: "Niue" },
    { value:"Norfolk Island", label: "Norfolk Island" },
    { value:"Northern Mariana Islands", label: "Northern Mariana Islands" },
    { value:"Norway", label: "Norway" },
    { value:"Oman", label: "Oman" },
    { value:"Pakistan", label: "Pakistan" },
    { value:"Palau", label: "Palau" },
    { value:"Palestinian Territory, Occupied", label: "Palestinian Territory, Occupied" },
    { value:"Panama", label: "Panama" },
    { value:"Papua New Guinea", label: "Papua New Guinea" },
    { value:"Paraguay", label: "Paraguay" },
    { value:"Peru", label: "Peru" },
    { value:"Philippines", label: "Philippines" },
    { value:"Pitcairn", label: "Pitcairn" },
    { value:"Poland", label: "Poland" },
    { value:"Portugal", label: "Portugal" },
    { value:"Puerto Rico", label: "Puerto Rico" },
    { value:"Qatar", label: "Qatar" },
    { value:"Reunion", label: "Reunion" },
    { value:"Romania", label: "Romania" },
    { value:"Russian Federation", label: "Russian Federation" },
    { value:"Rwanda", label: "Rwanda" },
    { value:"Saint Barthelemy", label: "Saint Barthelemy" },
    { value:"Saint Helena", label: "Saint Helena" },
    { value:"Saint Kitts and Nevis", label: "Saint Kitts and Nevis" },
    { value:"Saint Lucia", label: "Saint Lucia" },
    { value:"Saint Martin (French Part)", label: "Saint Martin (French Part)" },
    { value:"Saint Pierre And Miquelon", label: "Saint Pierre And Miquelon" },
    { value:"Saint Vincent and the Grenadines", label: "Saint Vincent and the Grenadines" },
    { value:"Samoa", label: "Samoa" },
    { value:"San Marino", label: "San Marino" },
    { value:"Sao Tome and Principe", label: "Sao Tome and Principe" },
    { value:"Saudi Arabia", label: "Saudi Arabia" },
    { value:"Senegal", label: "Senegal" },
    { value:"Serbia", label: "Serbia" },
    { value:"Seychelles", label: "Seychelles" },
    { value:"Sierra Leone", label: "Sierra Leone" },
    { value:"Singapore", label: "Singapore" },
    { value:"Sint Maarten (Dutch part)", label: "Sint Maarten (Dutch part)" },
    { value:"Slovakia", label: "Slovakia" },
    { value:"Slovenia", label: "Slovenia" },
    { value:"Solomon Islands", label: "Solomon Islands" },
    { value:"Somalia", label: "Somalia" },
    { value:"South Africa", label: "South Africa" },
    { value:"South Georgia and the South Sandwich Islands", label: "South Georgia and the South Sandwich Islands" },
    { value:"South Sudan", label: "South Sudan" },
    { value:"Spain", label: "Spain" },
    { value:"Sri Lanka", label: "Sri Lanka" },
    { value:"Sudan", label: "Sudan" },
    { value:"Suriname", label: "Suriname" },
    { value:"Svalbard And Jan Mayen", label: "Svalbard And Jan Mayen" },
    { value:"Swaziland", label: "Swaziland" },
    { value:"Sweden", label: "Sweden" },
    { value:"Switzerland", label: "Switzerland" },
    { value:"Syrian Arab Republic", label: "Syrian Arab Republic" },
    { value:"Taiwan, Province of China", label: "Taiwan, Province of China" },
    { value:"Tajikistan", label: "Tajikistan" },
    { value:"Tanzania, United Republic of", label: "Tanzania, United Republic of" },
    { value:"Thailand", label: "Thailand" },
    { value:"The Democratic Republic of the Congo", label: "The Democratic Republic of the Congo" },
    { value:"Timor-Leste", label: "Timor-Leste" },
    { value:"Togo", label: "Togo" },
    { value:"Tokelau", label: "Tokelau" },
    { value:"Tonga", label: "Tonga" },
    { value:"Trinidad and Tobago", label: "Trinidad and Tobago" },
    { value:"Tunisia", label: "Tunisia" },
    { value:"Turkey", label: "Turkey" },
    { value:"Turkmenistan", label: "Turkmenistan" },
    { value:"Turks and Caicos Islands", label: "Turks and Caicos Islands" },
    { value:"Tuvalu", label: "Tuvalu" },
    { value:"Uganda", label: "Uganda" },
    { value:"Ukraine", label: "Ukraine" },
    { value:"United Arab Emirates", label: "United Arab Emirates" },
    { value:"United States", label: "United States" },
    { value:"United States Minor Outlying Islands", label: "United States Minor Outlying Islands" },
    { value:"Uruguay", label: "Uruguay" },
    { value:"Uzbekistan", label: "Uzbekistan" },
    { value:"Vanuatu", label: "Vanuatu" },
    { value:"Venezuela, Bolivarian Republic of", label: "Venezuela, Bolivarian Republic of" },
    { value:"Viet Nam", label: "Viet Nam" },
    { value:"Virgin Islands, British", label: "Virgin Islands, British" },
    { value:"Virgin Islands, U.S.", label: "Virgin Islands, U.S." },
    { value:"Wallis and Futuna", label: "Wallis and Futuna" },
    { value:"Western Sahara", label: "Western Sahara" },
    { value:"Yemen", label: "Yemen" },
    { value:"Zambia", label: "Zambia" },
    { value:"Zimbabwe", label: "Zimbabwe" },

  ]

  useEffect(() => {
    dispatch(tdsActions.getTDSAccount());
  }, []);

  useEffect(() => {
    if (tdsAccount) {
      const bankDetails = {
        bank_name: tdsAccount.person.bank_name,
        bank_sort_code: tdsAccount.person.bank_sort_code,
        bank_account_name: tdsAccount.person.bank_account_name,
        bank_account_number: tdsAccount.person.bank_account_number,
      };

      const userDetails = {
        email: tdsAccount.person.person_email,
        tenant: tdsAccount.person.tenant,
        last_name: tdsAccount.person.person_lastname,
        first_name: tdsAccount.person.person_firstname,
        certificate_number: tdsAccount.person.certificate_number,
        contact_number: tdsAccount.person.person_mobile,
        person_addresscity : tdsAccount.person.person_addresscity,
        person_addresscountry : tdsAccount.person.person_addresscountry,
        person_addresscounty : tdsAccount.person.person_addresscounty,
        person_addresslines : tdsAccount.person.person_addresslines,
        person_addresspostcode: tdsAccount.person.person_addresspostcode
      };

      const preferenceDetails = {
        lead: tdsAccount.person.share_data == 'yes',
        marketing: tdsAccount.person.marketing_agreement == 'yes',
      };

      setAccount(bankDetails);
      setPersonalDetails(userDetails);
      setPreferences(preferenceDetails);
    }
  }, [tdsAccount]);

  const handlePersonalDetailsChange = (value, field) => {
    setPersonalDetails({ ...personalDetails, [field]: value });
  };

  const handleChange = (value, field) => {
    setAccount({ ...account, [field]: value });
  };

  const handleMarketingPreferencesChange = () => {
    dispatch(tdsActions.updateMarketingPreferences({ marketing: !preferences.marketing }));
    setPreferences({ ...preferences, marketing: !preferences.marketing });
  };

  const handleLeadPreferencesChange = () => {
    dispatch(tdsActions.updateLeadPreferences({ lead: !preferences.lead }));
    setPreferences({ ...preferences, lead: !preferences.lead });
  };

  const updatePersonalDetails = () => {
    const payload = {
      last_name: personalDetails.last_name,
      first_name: personalDetails.first_name,
      mobile: personalDetails.contact_number,
      person_addresscity: personalDetails.person_addresscity,
      person_addresslines: personalDetails.person_addresslines,
      person_addresscounty: personalDetails.person_addresscounty,
      person_addresscountry: personalDetails.person_addresscountry,
      person_addresspostcode: personalDetails.person_addresspostcode,
    };
    dispatch(tdsActions.updateAccount(payload))
    setAccountEdit(false);
  };

  const updateBankAccount = () => {
    if(account.password=="" || account?.password==null){
      dispatch({
        type: types.SHOW_ALERT,
        alertType: "error",
        text: "Please Enter your TDS Password",
      });
    }
    else{
      account.name_on_account = account.bank_account_name;
      dispatch(tdsActions.updateBankAccount(account));
      setBankEdit(false);
    }
   
  };

  const bankAccountTab = () => {
    return (
      <View style={{ width: '100%', padding: '6%' }}>
        <RowContainer style={{ flexDirection: layoutType == 'phone' ? 'column' : 'row',justifyContent:'space-between' }}>
          <CustomTextInput
            width={layoutType == 'phone' ? '100%' : '45%'}
            title="Name on Account"
            type="text"
            name="account_name"
            prefilledValue={account.bank_account_name}
            bColor={'#638ACC'}
            disabled={!bankEdit}
            onChangeText={(value) =>Regex.validateBankName(value) && handleChange(value, 'bank_account_name')}
            id={"TDS-ACC-NAME"}
          />

          <CustomTextInput
            width={layoutType == 'phone' ? '100%' : '45%'}
            title="Account Number"
            type="number"
            name="account_number"
            prefilledValue={account.bank_account_number}
            bColor={'#638ACC'}
            disabled={!bankEdit}
            id={"TDS-ACC-NUMBER"}
            onChangeText={(value) => handleChange(value.replace(/[^0-9]/g, ''), 'bank_account_number')}
          />
        </RowContainer>

        <RowContainer style={{ flexDirection: layoutType == 'phone' ? 'column' : 'row',justifyContent:'space-between' }}>
          <CustomTextInput
            width={layoutType == 'phone' ? '100%' : '45%'}
            title="Sort Code"
            type="number"
            name="sort_code"
            disabled={!bankEdit}
            maxLength={6}
            prefilledValue={account.bank_sort_code}
            bColor={'#638ACC'}
            id={"TDS-ACC-SORTCODE"}
            onChangeText={(value) => handleChange(value.replace(/[^0-9]/g, ''), 'bank_sort_code')}
          />

          <CustomTextInput
            width={layoutType == 'phone' ? '100%' : '45%'}
            title="Bank Name"
            type="text"
            name="bank_name"
            disabled={!bankEdit}
            prefilledValue={account.bank_name}
            bColor={'#638ACC'}
            id={"TDS-ACC-BANKNAME"}
            onChangeText={(value) =>Regex.validateBankName(value) && handleChange(value, 'bank_name')}
          />
        </RowContainer>
        <RowContainer style={{ flexDirection: layoutType == 'phone' ? 'column' : 'row',justifyContent:'space-between' }}>
        <CustomTextInput
          width={layoutType == 'phone' ? '100%' : '45%'}
          title="Password"
          type="password"
          name="password"
          password
          prefilledValue={account.password}
          disabled={!bankEdit}
          bColor={'#638ACC'}
          id={"TDS-ACC-PASSWORD"}
          onChangeText={(value) => handleChange(value, 'password')}
        />
        <ButtonWrapper
          style={{ width: layoutType == 'phone' ? '100%' : '45%' }}
          color={bankEdit?'#a4d4a2':primaryColor} 
          bcolor={bankEdit?'#a4d4a2':primaryColor}
          id={"TDS-ACC-UPDATE"}
          onPress={() =>bankEdit?updateBankAccount():setBankEdit(true)}
        >
            <Typography 
                 id={"TDS-ACC-UPDATE_TEXT"}
            color={'white'} size={'19px'} bold={800}>
            {bankEdit?'SAVE':'EDIT'}
            </Typography>
        </ButtonWrapper>
      </RowContainer>
       

        <RowContainer
          style={{
            marginTop: 20,
            flexDirection: layoutType == 'phone' ? 'column' : 'row',
            justifyContent: 'space-between',
          }}
        >
          <ButtonWrapper
            width={layoutType == 'phone' ? '100%' : '45%'}
            height={'50px'}
            id={"TDS-ACC-ADDINTERNATIONALBANK"}
            onPress={() => navigateAction(props.navigation, 'Custodial Add International Bank Account')}
          >
          <Typography color={'white'} size={'19px'} bold={800}>
            ADD INTERNATIONAL BANK DETAILS
          </Typography>
          </ButtonWrapper>

          <ButtonWrapper
            color={'#FFFFFF'}
            border
            width={layoutType == 'phone' ? '100%' : '45%'}
            height={'50px'}
            id={"TDS-ACCOUNT-CPASSWORD"}
         
            onPress={() => {
              navigateAction(props.navigation, 'Custodial Change Password');
            }}
          >
            <Typography 
            id={"TDS-ACCOUNT-CPASSWORD_TEXT"}
            color={'#194592'} size={'19px'} bold={800}>
              CHANGE PASSWORD
            </Typography>
          </ButtonWrapper>
        </RowContainer>
      </View>
    );
  };

  const accountTab = () => {
    return (
      <View
        style={{
          width: '100%',
          padding: '6%',
          flexDirection: layoutType == 'web' ?  'row':'column',
        }}
      >
        <View
          style={{
            alignItems: 'center',
          }}
        >
         <Image source={require('../../assets/images/tds-profile.png')} style={{width:120,height:120}}/>

          <ButtonWrapper
            width={'100%'}
            height={'50px'}
            style={{ marginBottom: 20 }}
            onPress={() => {
              navigateAction(props.navigation, 'Custodial Change Password');
            }}
          >
            <Text style={{ color: 'white' }}>CHANGE PASSWORD</Text>
          </ButtonWrapper>
          <TouchableOpacity
          id={"TDS-ACCOUNT-LOGOUT"} 
          onPress={()=> 
            dispatch(tdsActions.logoutToTDS(props.navigation))
          }
          >
            <Image
            style={{width: 100,
              height: 100}}
            resizeMode={'contain'}
            source={require("./web/logoutTds.png")}
            />
            </TouchableOpacity>
        </View>
        <View style={{ marginLeft: layoutType == 'phone' ? 0 : 30 }}>
          <RowContainer style={{ flexDirection: layoutType == 'phone' ? 'column' : 'row' ,justifyContent:'space-between'}}>
            <CustomTextInput
              width={layoutType == 'phone' ? '100%' : '45%'}
              title="First Name"
              type="text"
              name="first_name"
              id={"TDS-USER-FNAME"}
              disabled={true}
              prefilledValue={personalDetails.first_name}
              bColor={'#638ACC'}
              onChangeText={(value) => handlePersonalDetailsChange(value, 'first_name')}
            />

            <CustomTextInput
              width={layoutType == 'phone' ? '100%' : '45%'}
              title="Last Name"
              type="text"
              name="last_name"
              id={"TDS-USER-LNAME"}
              disabled={true}
              prefilledValue={personalDetails.last_name}
              bColor={'#638ACC'}
              onChangeText={(value) => handlePersonalDetailsChange(value, 'last_name')}
            />
          </RowContainer>
          <CustomTextInput
            width={'100%'}
            title="Contact Number"
            type="number"
            maxLength={12}
            name="contact_number"
            id={"TDS-USER-CNUMBER"}
            disabled={!accountEdit}
            prefilledValue={personalDetails.contact_number}
            bColor={'#638ACC'}
            onChangeText={(value) => handlePersonalDetailsChange(value.replace(/[^0-9]/g, ''), 'contact_number')}
          />

          <CustomTextInput
            width={'100%'}
            title="Email"
            type="text"
            name="email"
            id={"TDS-USER-EMAIL"}
            disabled={true}
            prefilledValue={personalDetails.email}
            bColor={'#638ACC'}
            onChangeText={(value) => handlePersonalDetailsChange(value, 'email')}
          />

          <CustomTextInput
            width={'100%'}
            title="Address"
            type="text"
            name="person_addresslines"
            id={"TDS-USER-ADDRESS"} 
            prefilledValue={personalDetails.person_addresslines}
            bColor={'#638ACC'}
            disabled={!accountEdit}
            onChangeText={(value) => handlePersonalDetailsChange(value, 'person_addresslines')}
          />

          <RowContainer style={{ flexDirection: layoutType == 'phone' ? 'column' : 'row',justifyContent:'space-between' }}>
            <CustomTextInput
              width={layoutType == 'phone' ? '100%' : '45%'}
              title="Post code"
              type="text"
              id={"TDS-USER-PCODE"} 
              name="person_addresspostcode"
              prefilledValue={personalDetails.person_addresspostcode}
              bColor={'#638ACC'}
              disabled={!accountEdit}
              onChangeText={(value) => handlePersonalDetailsChange(value, 'person_addresspostcode')}
            />

            <CustomTextInput
              width={layoutType == 'phone' ? '100%' : '45%'}
              title="City"
              type="text"
              id={"TDS-USER-CITY"} 
              name="person_addresscity"
              prefilledValue={personalDetails.person_addresscity}
              bColor={'#638ACC'}
              disabled={!accountEdit}
              onChangeText={(value) =>Regex.validateBankName(value) &&  handlePersonalDetailsChange(value, 'person_addresscity')}
            />
          </RowContainer>

          <View style={{ flexDirection: "row", justifyContent: "space-between", paddingBottom: 8 }}>
            <Title>Country</Title>
          </View>
          <DropDownWrapper style={{ flexGrow:2,width: '100%' }}>
            <DropDownSectionWrapper style={{ width: '100%' ,flexGrow:2}}>
              <DropDownPicker
              id={"TDS-USER-COUNTRY"} 
                items={countries}
                width={'100%'}
                disabled={!accountEdit}
                defaultValue={personalDetails.person_addresscountry}
                onChangeItem={(e) => handlePersonalDetailsChange(e.value, 'person_addresscountry')}
              />
            </DropDownSectionWrapper>
          </DropDownWrapper>
          <ButtonWrapper color={accountEdit?'#a4d4a2':primaryColor} bcolor={accountEdit?'#a4d4a2':primaryColor} height={'50px'} width={'100%'} onPress={() =>accountEdit?updatePersonalDetails():setAccountEdit(true)}>
           <Typography color={'white'} bold>{accountEdit?'SAVE':'EDIT'}</Typography>
          </ButtonWrapper>

        </View>
      </View>
    );
  };

  const preferencesTab = () => {
    return (
      <View style={{ width: '100%', padding: '6%' }}>
        <RowContainer>
          <View style={{ flex: 0.9, paddingRight: 10 }}>
            <Typography align={'left'} color={'#194592'} size={'27px'} bold={700}>
              Lead
            </Typography>
            <Typography top={'27px'} align={'left'} color={'#454545'} size={'20px'} bold={400}>
              Do you agree to your contact details being shared with the agent, landlord(s), and
              tenant(s) associated with your deposit?
            </Typography>
          </View>
          <View style={{ flex: 0.1, alignItems: 'flex-end', justifyContent: 'center' }}>
            <CheckBox 
            id={"TDS-CHECK-LEAD"}
            checked={preferences.lead} onPress={() => handleLeadPreferencesChange()} />
          </View>
        </RowContainer>
        <RowContainer style={{ marginTop: 50 }}>
          <View style={{ flex: 0.9 }}>
            <Typography align={'left'} color={'#194592'} size={'27px'} bold={700}>
              Marketing
            </Typography>
            <Typography top={'27px'} align={'left'} color={'#454545'} size={'20px'} bold={400}>
              As part of our service, TDS send customer newsletters. Opt-in to receive
              communications about third-party products and services from TDS and selected partners.
            </Typography>
          </View>
          <View style={{ flex: 0.1, alignItems: 'flex-end', justifyContent: 'center' }}>
            <CheckBox
              id={"TDS-CHECK-MARKETING"}
              checked={preferences.marketing}
              onPress={() => handleMarketingPreferencesChange()}
            />
          </View>
        </RowContainer>
      </View>
    );
  };

  const renderTabs = () => {
    return (
      <TabsWrapper>
        <View
          style={{
            flexDirection: 'row',
            width: '100%',
            paddingVertical: 20,
            borderBottomWidth: 1,
            borderBottomColor: generalColorsTheme.active,
          }}
        >
          <TabHeader 
           id={"TDS-TAB-ACCOUNT"}
          hideBoderLeft onPress={() => setActiveTab('Account')}>
            <Typography
              color={
                activeTab === 'Account' ? generalColorsTheme.active : generalColorsTheme.inactive
              }
              size={'23px'}
              bold={700}
              id={"TDS-TAB-ACC_TEXT"}
            >
              TDS Account
            </Typography>
          </TabHeader>
          <TabHeader 
           id={"TDS-TAB-PREFERENCES"}
          hideBoderLeft onPress={() => setActiveTab('Preferences')}>
            <Typography
              color={
                activeTab === 'Preferences'
                  ? generalColorsTheme.active
                  : generalColorsTheme.inactive
              }
              size={'23px'}
              bold={700}
              id={"TDS-TAB-PREFERENCES_TEXT"}
            >
              Preferences
            </Typography>
          </TabHeader>
          <TabHeader 
          id={"TDS-TAB-BANK-DETAILS"}
          onPress={() => setActiveTab('Bank')} hideBoderLeft>
            <Typography
              color={activeTab === 'Bank' ? generalColorsTheme.active : generalColorsTheme.inactive}
              size={'23px'}
              bold={700}
              id={"TDS-TAB-BANK_TEXT"}
            >
              Bank Details
            </Typography>
          </TabHeader>
        </View>
        <TabBody>
          {activeTab === 'Account' ? accountTab() : null}
          {activeTab === 'Preferences' ? preferencesTab() : null}
          {activeTab === 'Bank' ? bankAccountTab() : null}
        </TabBody>
      </TabsWrapper>
    );
  };

  return (
    <>
      <BackGroundGenerator
        isTds
        props={props}
        layoutType={(data) => {
          setType(data);
        }}
        isTLYF
        backgroundArr={backgroundTdsArr}
        isBack={true}
        infoIcon
        backNavigation={()=> {
          routesLength==1? 
          navigateAction(props.navigation, 'Custodial Tenancies'):props.navigation.pop()} }
      >
        <Container>{renderTabs()}</Container>
      </BackGroundGenerator>
    </>
  );
};

export default custodialAccount;
