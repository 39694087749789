import React from 'react';
import Modal  from "react-native-modal";

import WideButton from './../wideButton';
import { COLORS } from '../../constants/colorPallete';
import { Container, Box, Title, Discription, WideButtonWrapper } from './customPopUp.styles';
import { Text } from 'react-native';
import { Typography } from '../../components/tds/style';
import ODSButton from '../../../openbrix-design-system/ui/atoms/ODSButton';
import { getTokens } from '../../../openbrix-design-system/design-tokens/color-tokens';
import { ODSText } from '../../../openbrix-design-system/ui/atoms/ODSText';

const CustomPopUp = (props) => {
  const {
    loading,
    primaryButtonText,
    secondaryButtonText,
    primaryText,
    secondaryText,
    onPrimaryButtonPress,
    onSecondaryButtonPress,
    note,
    ...attributes
  } = props;
  const themeObj = getTokens('light').colors;
  return (
    <Modal
      isVisible={loading}
      backdropTransitionInTiming={2000}
      backdropTransitionOutTiming={2000}
      fullScreen={true}
    >
      <Container>
        <Box>
          <ODSText
            textAlign={'center'}
            color={themeObj.neutral[700]}
            style={{ alignSelf: 'center' }}
            type={'button'}
          >
            {primaryText}
          </ODSText>
          <ODSText
            textAlign={'center'}
            color={themeObj.neutral[700]}
            marginTop={10}
            style={{ alignSelf: 'center' }}
            type={'sm'}
          >
            {secondaryText}
          </ODSText>
          <WideButtonWrapper>
            <ODSButton
              lIcon={'TB-Arrow-Right'}
              restStyle={{ width: 300, marginTop: 20,alignSelf: 'center' }}
              onPress={() => onPrimaryButtonPress()}
            >
              {primaryButtonText}
            </ODSButton>
          </WideButtonWrapper>
          {secondaryButtonText && (
            <WideButtonWrapper>
              <ODSButton
              
                restStyle={{ width: 300,  alignSelf: 'center' }}
                type="secondary"
                themeColor={themeObj.main.salmon}
                onPress={() => onSecondaryButtonPress()}
              >
                {secondaryButtonText}
              </ODSButton>
            </WideButtonWrapper>
          )}
          {note && (
           
              <ODSText
            textAlign={"flex-start"}
                color={themeObj.neutral[700]}
                
                type={'sm'}
              >
              Please note:  {note}
              </ODSText>
          
          )}
        </Box>
      </Container>
    </Modal>
  );
};

export default CustomPopUp;
