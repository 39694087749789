import React, { useState } from "react";
import {
    View,
    Text,
    SafeAreaView,
    Image,
    TouchableOpacity,
    Platform,
    ScrollView,
    Button,
} from "react-native";
import {
    Container,
    SectionWrapper,
    DualView,
    TextBox,
    SectionTitle,
    SectionText,
    ImageBox,
    TeamsSection,
    MemberBoxWrapper,
    MemberBox,
    MemberImage,
    MemberName,
    MemberNameText,
    MemberDetails,
    MemberDetailsText,
    MemberHeader,
    TeamHeader,
} from "./styles";

import getPlatformType from "../../helpers/getPlatform";
const layoutType = getPlatformType();

import Header from "../../commonComponents/headerComponent/headerComponent";
import Footer from "../../commonComponents/footerComponent/customFooter";
import TripleTabs from "../../commonComponents/tripleTabs/tripleTabs";

const TabTenantBuyer = (props) => {
    return (
        <SectionWrapper>
          
        <DualView
                style={{
                    flexDirection: layoutType !== "phone" ? "row-reverse" : "column",
                    alignItems:'flex-start'
                }}
            >
                <ImageBox style={{height: layoutType == "web" ? 278 : 200}}>
                    <Image
                        source={require("../../assets/images/townhouse.png")}
                        style={{ width: "100%", height: layoutType == "web" ? 278 : 200, resizeMode: "contain" }}
                    ></Image>
                </ImageBox>
                <TextBox>
                    <SectionTitle>THE UK MLS – Multi-Listing Service.</SectionTitle>
                    <SectionText>
                    OpenBrix is set to revolutionise the property portal market through the introduction of the first UK wide transparent Multi-Listing Service (MLS). Agents can Increase their earning potential through the monetisation of property portfolios by sharing commissions. Agents can now act for buyers. The MLS hosts UK properties and is open to National and International Agents who wish to introduce buyers from all over the globe.
                    </SectionText>
                </TextBox>
            </DualView>
            <DualView>
                <ImageBox>
                    <Image
                        source={require("../../assets/images/courtyard.png")}
                        style={{ width: "100%", height: "100%", resizeMode: "cover" }}
                    ></Image>
                </ImageBox>
                <TextBox style={{ paddingTop: 0 }}>
                    <SectionTitle>The world’s first MLS using blockchain technology</SectionTitle>
                    <SectionText>
                    Thanks to the blockchain technology agents are now able to connect online and share property to the whole MLS. Alternatively, Agents can setup their own unlimited bespoke networks, agree commission structures, marketing rights and of course each agreement is immutable and transparent, ultimately enabling agents to proactively work for both buyers and tenants collaboratively. The UK MLS gives control to the Primary instructed Agent.

                    </SectionText>
                    <SectionText>
                    In today’s variable market conditions, we need to collaborate and work together, whether you are an agent short on supply or short on demand, now is the time to join the MLS and increase the probability of selling your instruction.

                    </SectionText>
                </TextBox>
            </DualView>
         
        </SectionWrapper>
    );
};

export default TabTenantBuyer;
