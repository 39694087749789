import React, { useState } from "react";
import styled from "styled-components/native";
import getPlatformType from "../../helpers/getPlatform";
import { Dimensions, ScrollView, Switch, TouchableOpacity, View } from "react-native";
import { useDispatch } from "react-redux";
import {
  customiseCookies,
  hasUserClickedOrAcceptedCookies,
  showCookiesBanner,
  showCustomiseBanner,
} from "../../actions/cookies";
import { Typography } from "../tds/style";

const layoutType = getPlatformType();

const View1 = styled.View`
`;
const View2 = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 50px;
`;
const Container = styled.View`
  justify-content: center;
  flex-direction: row;
  align-items: center;
  align-self: center;
  z-index: 1;
  display: flex;
  right: 0;
  left: 0;
  flex-direction: column;
  background: #fff;
  width: ${layoutType == "phone" ? '90%' : '70%'};
  padding: 20px;
`;
const TitleContainer = styled.Text`
  background: #C4F5D8;
  padding: 47px 65px;
  border-radius: 30px;
  color: #4C664B;
  font-size: 23px;
  font-weight: 600px;
`;
const TextContainer = styled.View`
  margin-top: ${props => (props.platform == 'web' ? "50px" : (props.platform == 'tab') ? '50px' : '10px')};
`;
const TextT = styled.Text`
  text-align: center;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 20px;
`;
const TextD = styled.Text`
  font-size: 20px;
  margin-bottom: 20px;
`;
const BtnsContainer = styled.View`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: ${props => (props.platform == 'web' ? "50px" : (props.platform == 'tab') ? '0px' : '0px')};
`;
const Btn1 = styled.TouchableOpacity`
  padding: 19px 48px;
  border-radius: 30px;
  background: #F5F5F5;
  border: 1px solid #638ACC;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
  margin-right: 30px;
`;
const Btn2 = styled.TouchableOpacity`
  padding: 19px 82px;
  border-radius: 30px;
  background: #638ACC;
  border: 1px solid #F5F5F5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
`;
const TextBtn1 = styled.Text`
  font-size: 19px;
  color: #454545;
  font-weight: 700;
`;
const TextBtn2 = styled.Text`
  font-size: 19px;
  color: #FFF;
  font-weight: 700;
`;
const LINE1 = `
We use cookies, which are small text files, to improve your experience on our website and to show

you personalised content. You can allow all or manage them individually

You can find out more on our cookie page at any time.


`;
export default function Customise() {
  const dispatch = useDispatch();
  const [Essential, setEssential] = useState(true);
  const [Analytics, setAnalytics] = useState(false);
  const [Functional, setFunctional] = useState(false);
  const [Advertising, setAdvertising] = useState(false);
  const [Personalisation, setPersonalisation] = useState(false);
  const [sentry, setSentry] = useState(false);


  const toggleList = (title, detail, value, onChange) => {
    return (
      <View style={{
        marginTop: 15,
        flexDirection: layoutType == "phone" ? 'row-reverse' : 'row',
        width: '95%'
      }}>
        <Switch
          trackColor={{
            false: "#cdcdcd",
            true: "#cdcdcd"
          }}
          thumbColor={value ? "#0000000" : "#fff"}
          ios_backgroundColor="#3e3e3e"
          style={{
            backgroundColor: value
              ? "#cdcdcd"
              : "#cdcdcd",
            borderRadius: 30,
          }}
          onValueChange={async (e) => {
            // setState({...state,property_alert:e})
            onChange(!value)

          }
          }
          value={value}
        />
        <View style={{
          marginLeft: 40,
          alignItems: 'flex-start',
          width: '90%'
        }}>
          <Typography color={'#000'}>{title}</Typography>
          <Typography color={'#000'} size={16} bold={400} align={'left'} top={20}>{detail}
          </Typography>
        </View>
      </View>
    )
  }

  return (
    <ScrollView style={{ height: Dimensions.get('window').height / 1.3 }}>
      <Container platform={layoutType}>
        {/* <TouchableOpacity onPress={()=> dispatch(showCustomiseBanner(false))} style={{position:'absolute',top:5,right:20}}>
        <Text  style={{color:'#000',fontSize:25}}>x</Text>
      </TouchableOpacity> */}
        <Typography size={25} color={'#000'}>Our Cookies</Typography>
        <View style={{
          width: '100%',
          position: 'absolute',
          height: 1,
          top: 70,
          backgroundColor: '#000'
        }}/>
        <TouchableOpacity onPress={() => {

          dispatch(customiseCookies({
            Essential: true,
            Analytics: true,
            Functional: true,
            Advertising: true,
            Personalisation: true,
          }));
          dispatch(showCustomiseBanner(false))
          dispatch(showCookiesBanner(false))
          dispatch(hasUserClickedOrAcceptedCookies(true))
          localStorage.setItem('cookies_status', "true");
        }} style={{
          paddingHorizontal: 50,
          paddingVertical: 10,
          borderRadius: 5,
          backgroundColor: '#000',
          marginTop: layoutType == "phone" ? '15%' : '5%'
        }}>
          <Typography color={'#fff'} size={20}>Allow All</Typography>
        </TouchableOpacity>
        <Typography size={18} color={'#000'} align={'left'} bold={'500'}>{LINE1.toUpperCase()}
        </Typography>
        {toggleList('Essential', 'These cookies are needed for essential functions such as making payments. Standard cookies can’t be switched off and they don’t store any of your information.', Essential, () => {
        })}
        {toggleList('Analytics', 'These cookies gather information such as how many people are using our site or which pages are popular to help us improve customer experience. Switching off these cookies will mean we can’t gather information to improve the experience.', Analytics, setAnalytics)}
        {toggleList('Functional', 'These cookies make your shopping experience easier. They enable basic functions such as seeing recently viewed products or searches. Switching off these cookies will mean that areas of our website can’t work properly.', Functional, setFunctional)}
        {toggleList('Advertising', 'These cookies help us to learn what you’re interested in so we can show you relevant adverts on other websites. Switching off these cookies will mean we can’t show you any personalised adverts.', Advertising, setAdvertising)}
        {toggleList('Personalisation', 'These cookies help us to learn what you’re interested in so we can show you relevant content while you shop on our site.', Personalisation, setPersonalisation)}
        {toggleList('Error tracking and performance', 'These cookies help us to track our site errors and performance', sentry, setSentry)}

       
        <TouchableOpacity onPress={() => {
          dispatch(customiseCookies({
            Essential,
            Analytics,
            Functional,
            Advertising,
            Personalisation,
            sentry
          }))
          dispatch(showCustomiseBanner(false))
          dispatch(showCookiesBanner(false))
          dispatch(hasUserClickedOrAcceptedCookies(true))
          localStorage.setItem('cookies_status', "true");

        }} style={{
          paddingHorizontal: 50,
          paddingVertical: 10,
          borderRadius: 5,
          borderColor: '#000',
          marginTop: '5%',
          borderWidth: 1,
          marginBottom: 20
        }}>
          <Typography color={'#000'} size={20}>Save Preferences</Typography>
        </TouchableOpacity>
        {/* <TextContainer platform={layoutType}>
        <TextT>Customize Consent Preferences</TextT>
        <TextD>We use cookies to help you navigate efficiently and perform certain functions. You will find detailed information about all cookies under each consent category below. The cookies that are categorized as "Necessary" are stored on your browser as they are essential for enabling the basic functionalities of the site.</TextD>
        <TextD>We also use third-party cookies that help us analyze how you use this website, store your preferences, and provide the content and advertisements that are relevant to you.</TextD>
        <TextD>These cookies will only be stored in your browser with your prior consent. You can choose to enable or disable some or all of these cookies but disabling some of them may affect your browsing experience.</TextD>
        <View2>
          <View1>
            <TextT>Necessary</TextT></View1>
          <View1><CheckBox  disabled={true} value={isSelectedNecessaryCookies} onValueChange={setSelectionNecessaryCookies} style={{alignSelf: "center", width: 40, height: 40}} /></View1>
        </View2>
        <TextD>Necessary cookies are required to enable the basic features of this site, such as providing secure log-in or adjusting your consent preferences. These cookies do not store any personally identifiable data.</TextD>
        <View2>
          <View1>
            <TextT>Functional</TextT></View1>
          <View1><CheckBox value={isSelectedFunctionalCookies} onValueChange={setSelectionFunctionalCookies} style={{alignSelf: "center", width: 40, height: 40}} /></View1>
        </View2>
        <TextD>Functional cookies help perform certain functionalities like sharing the content of the website on social media platforms, collecting feedback, and other third-party features.</TextD>
        <View2>
          <View1>
            <TextT>Analytics</TextT></View1>
          <View1><CheckBox value={isSelectedAnalyticsCookies} onValueChange={setSelectionAnalyticsCookies} style={{alignSelf: "center", width: 40, height: 40}} /></View1>
        </View2>
        <TextD>Analytical cookies are used to understand how visitors interact with the website. These cookies help provide information on metrics such as the number of visitors, bounce rate, traffic source, etc.</TextD>
        <View2>
          <View1>
            <TextT>Tracker</TextT></View1>
          <View1><CheckBox value={isSelectedTrackerCookies} onValueChange={setSelectionTrackerCookies} style={{alignSelf: "center", width: 40, height: 40}} /></View1>
        </View2>
        <TextD>Tracker cookies are used to provide visitors with customized information based on the pages you visited previously and to analyze the effectiveness of the ad campaigns.</TextD>
        
        <BtnsContainer platform={layoutType}>
          <Btn1 onPress={()=> {
            if(!isSelectedTrackerCookies){
              dispatch(changeCookiesSettings(false))
            }else{
              dispatch(changeCookiesSettings(true))
            }
            dispatch(showCustomiseBanner(false))
            dispatch(showCookiesBanner(false))
            dispatch(hasUserClickedOrAcceptedCookies(true))
          }
          
          }platform={layoutType}>
            <TextBtn1>SAVE PREFERENCES</TextBtn1>
          </Btn1>
          <Btn2 onPress={()=> {
            dispatch(changeCookiesSettings(true));
            dispatch(showCustomiseBanner(false))
            dispatch(showCookiesBanner(false))
            dispatch(hasUserClickedOrAcceptedCookies(true))
          }}>
            <TextBtn2>ACCEPT ALL</TextBtn2>
          </Btn2>
        </BtnsContainer>
      </TextContainer> */}
      </Container>
    </ScrollView>
  )
}
