import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

const Portal = ({ children }) => {
  const [el, setEl] = useState(null);
  const [target, setTarget] = useState(null);

  useEffect(() => {
    const newEl = document.createElement('div');
    const newTarget = document.body;

    setEl(newEl);
    setTarget(newTarget);

    newTarget.appendChild(newEl);

    return () => {
      newTarget && newTarget.removeChild(newEl);
    };
  }, []);

  if (el) {
    return ReactDOM.createPortal(children, el);
  }

  return null;
};

Portal.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Portal;
