import React from "react";
import styled from "styled-components/native";
import getPlatformType from "../../helpers/getPlatform";

const layoutType = getPlatformType();
let Container,Main,HeaderRow,Content,ContentWrap,Card;

if (layoutType == "phone") {
  Container = styled.ScrollView`
    flex: 1;
  `;
  Main = styled.View`
  width: 100%;
  justify-content:center;
  padding-horizontal:20px;
`;
Content = styled.View`
  width: 100%;
`;
ContentWrap = styled.View`
  width: 100%;
`;
HeaderRow = styled.View`
  width: 100%;
  justify-content:center;
  flex-direction:column-reverse;
  align-items:center;
`;
Card = styled.View`
width: 100%;
background-color: #fff;
border-bottom-left-radius: 20px;
border-top-right-radius: 20px;
box-shadow: rgba(0, 0, 0, 0.07) 10px 10px 6.68px;
padding: 20px;
`;

} else if (layoutType == "web") {
  Container = styled.ScrollView`
  flex: 1;
  `;
  Main = styled.View`
  align-items:flex-end;
  padding-horizontal:150px;
  width:100%
`;
HeaderRow = styled.View`
  width: 700px;
  justify-content:space-between;
  flex-direction:row;
  align-items:center;
`;
Content = styled.View`
  width: 900px;
`;
ContentWrap = styled.View`
  width: 100%;
  flex-direction:row;
  flex-wrap:wrap;
  justify-content:space-between;

`;
Card = styled.View`
width: ${props=>props?.width?props.width:'343px'};
background-color: #fff;
border-bottom-left-radius: 20px;
border-top-right-radius: 20px;
box-shadow: rgba(0, 0, 0, 0.07) 10px 10px 6.68px;
padding: 20px;
`;
} else {
  Container = styled.ScrollView`
  flex: 1;
  `;
  Main = styled.View`
  width: 100%;
  align-items:flex-end;
  padding-horizontal:100px;

`;
HeaderRow = styled.View`
width: 70%;
justify-content:space-between;
flex-direction:row;
align-items:center;
`;
Content = styled.View`
  width: 100%;
`;
ContentWrap = styled.View`
  width: 100%;
  flex-direction:row;
  justify-content:space-between;
`;
Card = styled.View`
width: ${props=>props?.width?props.width:'343px'};
background-color: #fff;
border-bottom-left-radius: 20px;
border-top-right-radius: 20px;
box-shadow: rgba(0, 0, 0, 0.07) 10px 10px 6.68px;
padding: 20px;
`;
}
const TextContainer = styled.Text`
  text-align: center;
  font-size: ${props => (layoutType == 'web' ? "24px" : (layoutType == 'tab') ? '22px' : '18px')};
  font-weight: 700;
  color: #000;
  margin: 0;
  font-family: 'Lato';
`;
const HestiaLogo = styled.Image`
  width: ${props => (layoutType == 'web' ? "150px" : (layoutType == 'tab') ? '103px' : '120px')};
  height: ${props => (layoutType == 'web' ? "150px" : (layoutType == 'tab') ? '103px' : '120px')};
`;
const NormalText = styled.Text`
  font-size: 15px;
  color: #000;
  margin: 0;
  font-family: 'Lato';
  text-align:justify;
`;
const NextBtn = styled.TouchableOpacity`
  width: 143px;
  height: 40px;
  border-radius: 25px;
  background: #b0c7e9;
  box-shadow: rgba(77, 78, 224, 0.5) 0px 2px 4px;
  color: #102047;
  justify-content: center;
  alignSelf:${layoutType!='phone'?'flex-end':'center'};
  margin-top:30px;
  margin-bottom:30px;
`;

export {
  Container,
  Main,
  HeaderRow,
  TextContainer,
  HestiaLogo,
  Content,
  ContentWrap,
  NormalText,
  Card,
  NextBtn
};
