import styled from "styled-components/native";
import getPlatformType from "../../helpers/getPlatform";
let Container;
const layoutType = getPlatformType();
if (layoutType == "phone") {
  Container = styled.View`
  width: 100%;
  height: 78vh;
  background-color: white;
`;
}
else if (layoutType == "web") {
  Container = styled.View`
  width: 100%;
  height: 700px;
  background-color: white;
`;
}
else {
  Container = styled.View`
  width: 100%;
  height: 44vh;
  background-color: white;
`;
}
const BottomContainer = styled.View`
  width: 320px;
  height: 226px;
  margin: 5px 0px 15px 10px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 20px;
`;

export { Container, BottomContainer };
