export const tdsConstants = {

  SEARCH_DEPOSIT_FAILURE: 'SEARCH_DEPOSIT_FAILURE',
  SEARCH_DEPOSIT_SUCCESS: 'SEARCH_DEPOSIT_SUCCESS',
  SEARCH_DEPOSIT_REQUEST: 'SEARCH_DEPOSIT_REQUEST',
  CLEAR_DEPOSIT_REQUEST: 'CLEAR_DEPOSIT_REQUEST',
  GET_SEARCHED_DEPOSIT_FAILURE: 'GET_SEARCHED_DEPOSIT_FAILURE',
  GET_SEARCHED_DEPOSIT_SUCCESS: 'GET_SEARCHED_DEPOSIT_SUCCESS',
  GET_SEARCHED_DEPOSIT_REQUEST: 'GET_SEARCHED_DEPOSIT_REQUEST',

  GET_TDS_TOKEN_FAILURE: 'GET_TDS_TOKEN_FAILURE',
  GET_TDS_TOKEN_SUCCESS: 'GET_TDS_TOKEN_SUCCESS',
  GET_TDS_TOKEN_REQUEST: 'GET_TDS_TOKEN_REQUEST',

  LOGIN_TO_TDS_FAILURE: 'LOGIN_TO_TDS_FAILURE',
  LOGIN_TO_TDS_SUCCESS: 'LOGIN_TO_TDS_SUCCESS',
  LOGIN_TO_TDS_REQUEST: 'LOGIN_TO_TDS_REQUEST',

  LOGOUT_TO_TDS_FAILURE: 'LOGOUT_TO_TDS_FAILURE',
  LOGOUT_TO_TDS_SUCCESS: 'LOGOUT_TO_TDS_SUCCESS',
  LOGOUT_TO_TDS_REQUEST: 'LOGOUT_TO_TDS_REQUEST',

  GET_TENANCY_DETAILS_REQUEST: 'GET_TENANCY_DETAILS_REQUEST',
  GET_TENANCY_DETAILS_FAILURE: 'GET_TENANCY_DETAILS_FAILURE',
  GET_TENANCY_DETAILS_SUCCESS: 'GET_TENANCY_DETAILS_SUCCESS',

  DOWNLOAD_DEPOSIT_CERTIFICATE_REQUEST: 'DOWNLOAD_DEPOSIT_CERTIFICATE_REQUEST',
  DOWNLOAD_DEPOSIT_CERTIFICATE_FAILURE: 'DOWNLOAD_DEPOSIT_CERTIFICATE_FAILURE',
  DOWNLOAD_DEPOSIT_CERTIFICATE_SUCCESS: 'DOWNLOAD_DEPOSIT_CERTIFICATE_SUCCESS',

  GET_TDS_ACCOUNT_REQUEST: 'GET_TDS_ACCOUNT_REQUEST',
  GET_TDS_ACCOUNT_FAILURE: 'GET_TDS_ACCOUNT_FAILURE',
  GET_TDS_ACCOUNT_SUCCESS: 'GET_TDS_ACCOUNT_SUCCESS',

  UPDATE_TDS_ACCOUNT_REQUEST: 'UPDATE_TDS_ACCOUNT_REQUEST',
  UPDATE_TDS_ACCOUNT_FAILURE: 'UPDATE_TDS_ACCOUNT_FAILURE',
  UPDATE_TDS_ACCOUNT_SUCCESS: 'UPDATE_TDS_ACCOUNT_SUCCESS',

  UPDATE_BANK_ACCOUNT_REQUEST: 'UPDATE_BANK_ACCOUNT_REQUEST',
  UPDATE_BANK_ACCOUNT_FAILURE: 'UPDATE_BANK_ACCOUNT_FAILURE',
  UPDATE_BANK_ACCOUNT_SUCCESS: 'UPDATE_BANK_ACCOUNT_SUCCESS',

  UPDATE_INTERNATIONAL_BANK_ACCOUNT_REQUEST: 'UPDATE_INTERNATIONAL_BANK_ACCOUNT_REQUEST',
  UPDATE_INTERNATIONAL_BANK_ACCOUNT_FAILURE: 'UPDATE_INTERNATIONAL_BANK_ACCOUNT_FAILURE',
  UPDATE_INTERNATIONAL_BANK_ACCOUNT_SUCCESS: 'UPDATE_INTERNATIONAL_BANK_ACCOUNT_SUCCESS',

  TRIGGER_PASSWORD_RESET_REQUEST: 'TRIGGER_PASSWORD_RESET_REQUEST',
  TRIGGER_PASSWORD_RESET_FAILURE: 'TRIGGER_PASSWORD_RESET_FAILURE',
  TRIGGER_PASSWORD_RESET_SUCCESS: 'TRIGGER_PASSWORD_RESET_SUCCESS',

  FORGOT_PASSWORD_RESET_REQUEST: 'FORGOT_PASSWORD_RESET_REQUEST',
  FORGOT_PASSWORD_RESET_FAILURE: 'FORGOT_PASSWORD_RESET_FAILURE',
  FORGOT_PASSWORD_RESET_SUCCESS: 'FORGOT_PASSWORD_RESET_SUCCESS',

  CREATE_DEPOSIT_REPAYMENT_PROPOSAL_REQUEST: 'CREATE_DEPOSIT_REPAYMENT_PROPOSAL_REQUEST',
  CREATE_DEPOSIT_REPAYMENT_PROPOSAL_SUCCESS: 'CREATE_DEPOSIT_REPAYMENT_PROPOSAL_SUCCESS',
  CREATE_DEPOSIT_REPAYMENT_PROPOSAL_FAILURE: 'CREATE_DEPOSIT_REPAYMENT_PROPOSAL_FAILURE',

  GET_DEPOSIT_REPAYMENT_PROPOSAL_REQUEST: 'GET_DEPOSIT_REPAYMENT_PROPOSAL_REQUEST',
  GET_DEPOSIT_REPAYMENT_PROPOSAL_SUCCESS: 'GET_DEPOSIT_REPAYMENT_PROPOSAL_SUCCESS',
  GET_DEPOSIT_REPAYMENT_PROPOSAL_FAILURE: 'GET_DEPOSIT_REPAYMENT_PROPOSAL_FAILURE',

  UPDATE_DEPOSIT_REPAYMENT_PROPOSAL_REQUEST: 'UPDATE_DEPOSIT_REPAYMENT_PROPOSAL_REQUEST',
  UPDATE_DEPOSIT_REPAYMENT_PROPOSAL_SUCCESS: 'UPDATE_DEPOSIT_REPAYMENT_PROPOSAL_SUCCESS',
  UPDATE_DEPOSIT_REPAYMENT_PROPOSAL_FAILURE: 'UPDATE_DEPOSIT_REPAYMENT_PROPOSAL_FAILURE',

  UPDATE_MARKETING_PREFERENCES_FAILURE: 'UPDATE_MARKETING_PREFERENCES_FAILURE',
  UPDATE_MARKETING_PREFERENCES_SUCCESS: 'UPDATE_MARKETING_PREFERENCES_SUCCESS',
  UPDATE_MARKETING_PREFERENCES_REQUEST: 'UPDATE_MARKETING_PREFERENCES_REQUEST',

  UPDATE_LEAD_PREFERENCES_FAILURE: 'UPDATE_LEAD_PREFERENCES_FAILURE',
  UPDATE_LEAD_PREFERENCES_SUCCESS: 'UPDATE_LEAD_PREFERENCES_SUCCESS',
  UPDATE_LEAD_PREFERENCES_REQUEST: 'UPDATE_LEAD_PREFERENCES_REQUEST',

  GET_TENANCY_FAILURE: 'GET_TENANCY_FAILURE',
  GET_TENANCY_SUCCESS: 'GET_TENANCY_SUCCESS',
  GET_TENANCY_REQUEST: 'GET_TENANCY_REQUEST',

  CONFIRM_TENANCY_FAILURE: 'CONFIRM_TENANCY_FAILURE',
  CONFIRM_TENANCY_SUCCESS: 'CONFIRM_TENANCY_SUCCESS',
  CONFIRM_TENANCY_REQUEST: 'CONFIRM_TENANCY_REQUEST',

  GET_TENANCIES_FAILURE: 'GET_TENANCIES_FAILURE',
  GET_TENANCIES_SUCCESS: 'GET_TENANCIES_SUCCESS',
  GET_TENANCIES_REQUEST: 'GET_TENANCIES_REQUEST',

  ACTIVATE_EMAIL_REQUEST:'ACTIVATE_EMAIL_REQUEST',
  ACTIVATE_EMAIL_SUCCESS:'ACTIVATE_EMAIL_SUCCESS',
  ACTIVATE_EMAIL_FAILURE:'ACTIVATE_EMAIL_FAILURE',

  TDS_RESET_PASSWORD_REQUEST:'TDS_RESET_PASSWORD_REQUEST',
  TDS_RESET_PASSWORD_SUCCESS:'TDS_RESET_PASSWORD_SUCCESS',
  TDS_RESET_PASSWORD_FAILURE:'TDS_RESET_PASSWORD_FAILURE',

  SEND_ACTIVATION_REQUEST:'SEND_ACTIVATION_REQUEST',
  SEND_ACTIVATION_SUCCESS:'SEND_ACTIVATION_SUCCESS',
  SEND_ACTIVATION_FAILURE:'SEND_ACTIVATION_FAILURE'
 


};
