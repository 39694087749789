import React from "react";
import styled from "styled-components/native";
import getPlatformType from "../../helpers/getPlatform";
import { FlatList } from "react-native-gesture-handler";

const layoutType = getPlatformType();

let GroupedText,
  DropdownText,
  Dropdown,
  DropdownWrapper,
  DropDownClickText,
  FlatListDropdown,
  FlatListOption,
  DropdownTextRight;

if (layoutType == "phone") {
} else if (layoutType == "web") {
} else {
}
GroupedText = styled.Text`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
DropdownText = styled.Text`
  height: 18px;
  color: #000000;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 18px;
  text-align: left;
  margin: 5px 0px;
  padding-left: 2px;
`;
DropdownTextRight = styled.Text`
  font-size: 11px;
  font-weight: 400;
  line-height: 13px;
`;
DropdownWrapper = styled.View`
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  position: relative;
`;
DropDownClickText = styled.Text`
  height: 45px;
  border-radius: 5px;
  border: solid 1px #d0d0d0;
  text-align: center;
  color: black;
  z-index: 2;
  font-size: 13px;
  font-weight: 400;
  line-height: 45px;
`;

FlatListDropdown = styled.FlatList`
  position: absolute;
  border: 1px solid #dbdbdb;

  width: 100%;
  background-color: #fff;
  max-height: 200px;
`;

FlatListOption = styled.Text`
  padding: 10px;
  text-align: center;
`;

export {
  GroupedText,
  Dropdown,
  DropdownWrapper,
  DropdownText,
  DropDownClickText,
  FlatListDropdown,
  FlatListOption,
  DropdownTextRight,
};
