export const getCcjText =(label)=>{

    if (label =="Person not Found"){

      return "From our record we cannot find your details, as such we cannot provide you a CCJ status. If you wish to rectify this please contact openbrix."
      
      }
      
      if (label =="Pending"){
      
      return "Your CCJ Status verification is pending."
      
      }
      
      if (label =="NO CCJ"){
      
      return "From our record we can see that you have no CCJ in your name. Your prospective estate agents will be able to see this and help you secure your dream rental home."
      
      }
      
      if (label =="CCJ"){
      
      return "From our record we can see that you have the following CCJ in your name."
      
      }
      
      if (label =="N/A"){
      
      return "Please Note: You have chosen not to add your CCJ status to your profile."
      
      }
    }
  