import React from "react";
import styled from "styled-components/native";
import getPlatformType from "../../helpers/getPlatform";
import { FlatList } from "react-native-gesture-handler";

const layoutType = getPlatformType();

let FilterView,
  FilterHeading,
  DualOptionalView,
  FilterInnerView,
  ButtonView,
  ButtonWrapper,
  FilterHeadingClearText,
  FilterHeadingBox,
  FilterHeaderText,
  FilterHeadingClearBox,
  BoxSelectorWrapper,
  SearchText,
  DropDownWrapper,
  DropDownSectionWrapper,
  TitleWrapper,
  FilterContent,
  FormContainer;
TitleWrapper = styled.Text`
  color: #000000;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px; 
`;
if (layoutType == "phone") {
  FilterView = styled.View`
    margin: 0 auto;
    padding: 0px;
    background: #f3f3f3;
    width: 100%;
  `;
  FilterInnerView = styled.View`
    width: 327px;
    display: flex;
  `;
  DualOptionalView = styled.View`
    display: flex;
    background-color: white;
    align-items: center;
  `;
  ButtonWrapper = styled.View`
    align-items: center;
    width: 100%;
    flex-direction:row;
  `;
  ButtonView = styled.View`
    margin: 20px 0px;
    width: 150px;
  `;
  BoxSelectorWrapper = styled.View`
    padding-bottom: 0px;
  `;
  FilterHeading = styled.View`
    background: #fff;
    padding-top: 20px;
    height: auto;
    padding-bottom: 15px;
  `;
  FilterHeadingBox = styled.View`
    width: 327px;
    margin: 0 auto;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `;
  SearchText = styled.Text`
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    padding-left: 106px;
  `;
  FilterContent = styled.View`
    width: 100%;
  `;
  FilterHeading = styled.View`
    display: none;
  `;
  FormContainer = styled.View`
  padding: 8px 16px;
  `;
} else if (layoutType == "web") {
  FilterView = styled.View`
    width: 100%;
    padding: 24px 80px;
    background-color: #ffffff;
    align-items: center;
    margin: 0 auto 20px;
  `;
  FilterInnerView = styled.View`
    width: 459px;
    margin: 0 8px;
  `;
  DualOptionalView = styled.View`
    display: flex;
    flex-direction: row;
    padding: 0 96px;
  `;
  ButtonView = styled.View`
    margin-top: 20px;
    width: 269px;
  `;
  ButtonWrapper = styled.View`
    align-items: flex-end;
    width: 100%;
  `;
  BoxSelectorWrapper = styled.View`
    padding-bottom: 0px;
  `;
  FilterHeading = styled.View`
    background: #fff;
    height: auto;
  `;
  FilterHeadingBox = styled.View`
    width: auto;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `;
  SearchText = styled.Text`
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    padding-left: 120px;
  `;
  FilterContent = styled.View`
    width: 1130px;
  `;
  FormContainer = styled.View`
  
  `;
} else {
  FilterView = styled.View`
    width: 100%;
    padding: 24px 40px;

    background-color: #ffffff;
    margin: 0 auto;
  `;
  FilterInnerView = styled.View`
    width: 435px;
    margin: 0 auto;
  `;
  DualOptionalView = styled.View`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `;
  ButtonView = styled.View`
    margin-top: 20px;
    width: 227px;
  `;
  ButtonWrapper = styled.View`
    align-items: flex-end;
    width: 100%;
  `;
  BoxSelectorWrapper = styled.View`
    padding-bottom: 0px;
  `;
  FilterHeading = styled.View`
  height:35px;
  `;
  FilterHeadingBox = styled.View`
    width: auto;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `;
  FilterContent = styled.View`
    width: 100%;
  `;
  SearchText = styled.Text`
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    padding-left: 35px;
  `;
  FormContainer = styled.View`
   
  `;
}

FilterHeaderText = styled.Text`
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
`;
FilterHeadingClearText = styled.View`
  padding: 10px;
  height: 50px;

  background: white;
  margin-bottom: 20px;
  justify-content: center;
`;
FilterHeadingClearBox = styled.View`
  width: 327px;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  margin: 0 auto;
`;
DropDownWrapper = styled.View`
  flex-direction: row;
`;
DropDownSectionWrapper = styled.View`
  flex: 1;
`;

export {
  FilterView,
  FilterHeading,
  DualOptionalView,
  FilterInnerView,
  ButtonView,
  ButtonWrapper,
  BoxSelectorWrapper,
  FilterHeadingClearText,
  FilterHeadingBox,
  FilterHeaderText,
  FilterHeadingClearBox,
  SearchText,
  FilterContent,
  DropDownWrapper,
  TitleWrapper,
  DropDownSectionWrapper,
  FormContainer
};
