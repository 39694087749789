import React, { useState } from "react";
import { View } from "react-native";
import Header from "../../commonComponents/headerComponent/headerComponent";
import Footer from "../../commonComponents/footerComponent/customFooter";
import { Card, Content, ContentWrap, HeaderRow, HestiaLogo, Main, NextBtn, NormalText, TextContainer, Container } from "./styles";
import { Image } from "react-native";
import styled from "styled-components/native";
import { Checkbox } from "react-native-paper";
import { Button } from "../openFlow/atoms/button";
import { useNavigation } from "@react-navigation/native";
import getPlatformType from "../../helpers/getPlatform";
import TopSection from "./common/TopSection";
import ZopaForm from "./common/ZopaForm";
import { useDispatch } from "react-redux";
import { tenantActions } from "../openFlow/openFlowRedux/tenant.actions";
const Quote = (props) => {
    const navigation = useNavigation();
    const layoutType = getPlatformType();
    const dispatch=useDispatch();
    const message=props?.data?.data;
    console.log("--->",message)
    const FormSection = () => {
        return (
            <View>
                {layoutType != "phone" && <View style={{ height: 120 }} />}
                <Card width={390} platform={layoutType} style={{ marginBottom: layoutType == "phone" ? 40 : 0 }}>
                    <CardRow1>
                        <DescriptionText>Amount</DescriptionText>
                        <ValueText>£{message?.amount}</ValueText>
                    </CardRow1>
                    <CardRow2>
                        <DescriptionText>Term (Months)</DescriptionText>
                        <ValueText>{message?.term}</ValueText>
                    </CardRow2>
                    <CardRow2>
                        <DescriptionText>APR % (Guaranteed)</DescriptionText>
                        <ValueText>{(message?.apr*100)?.toFixed(2)}%</ValueText>
                    </CardRow2>
                    <CardRow2>
                        <DescriptionText>Interest Rate %</DescriptionText>
                        <ValueText>{(message?.interest_rate*100)?.toFixed(2)}%</ValueText>
                    </CardRow2>
                    <CardRow2>
                        <DescriptionText>Monthly Payments</DescriptionText>
                        <ValueText>£{(message?.monthly_payments)?.toFixed(2)}</ValueText>
                    </CardRow2>
                    <CardRow2>
                        <DescriptionText>Fee</DescriptionText>
                        <ValueText>£{message?.fee}</ValueText>
                    </CardRow2>
                    <CardRow2>
                        <DescriptionText>Total Cost of Credit</DescriptionText>
                        <ValueText>£{message?.total_cost_of_credit?.toFixed(2)}</ValueText>
                    </CardRow2>
                </Card>
            </View>
        )
    }
    const preApprovedSection = () => {
        return (
            <View>
                <Card width={350}>
                    <NextBtn style={{ alignSelf: 'center', width: 280, height: 60, margin: 0, backgroundColor: '#00B401' }}>
                        <NormalText style={{ textAlign: 'center', fontWeight: '700', color: 'white' }} platform={layoutType}>Pre-Approved-100%</NormalText>
                    </NextBtn>
                </Card>
                <Card width={350} style={{ marginVertical: 40 }}>
                    <NormalText>
                        As you have been pre-approved, this means that so long as the information you provided remains valid and true and you pass additional lender checks. You’ll be offered the loan.
                        <br /><br />
                        Subject to further fraud and identity checks.
                        <br /><br />
                        The rate shown is the rate you’ll get if approved for this loan.

                    </NormalText>
                </Card>
                <Card width={350} style={{ marginBottom: 40 }}>
                    <NormalText>
                        <b> What happens Next?</b><br /><br />

                        Should you wish to proceed with the loan application click the button below<br />

                        <p style={{ color: 'red' }}>Please Note: The final lender decision is subject to further lender checks</p>
                        <br /><br /><br />

                    </NormalText>
                </Card>
            </View>
        )
    }

    const QuoteUnavailable = () => {
        return (
            <View>
                <Card width={600}>
                    <NextBtn style={{ alignSelf: 'center', width: 280, height: 60, margin: 0 }}>
                        <NormalText style={{ textAlign: 'center', fontWeight: '700' }} platform={layoutType}>Quote unavailable</NormalText>
                    </NextBtn>
                </Card>
                <Card width={600} style={{ marginVertical: 40 }}>
                    <NormalText>
                        <b>Sorry we can’t offer you a loan right now</b><br /><br />

                        <b>There are six common reasons why we say no:</b><br /><br />

                        •	If you applied for a Zopa loan in the past 6 months.<br />
                        •	A limited amount of information in your credit file.<br />
                        •	Records that you’ve missed payments in the past 6 years.<br />
                        •	Your existing level of unsecured debt (including credit cards).<br />
                        •	The affordability of the loan in your financial circumstances.<br />
                        •	Your credit score , as reported by TransUnion and Equifax<br /><br />

                        <b>What happened exactly?</b><br />
                        We use the information you gave us to make our decision <br /><br />

                        This isn’t just about your credit score – there are lots of things we consider. <br />
                        Please have a look at the list of reasons above and you may see at least one that related to you<br />


                    </NormalText>
                </Card>
            </View>
        )
    }
    const Eligble = () => {
        return (
            <View>
                <Card width={350}>
                    <NextBtn style={{ alignSelf: 'center', width: 280, height: 60, margin: 0, backgroundColor: '#F8C822' }}>
                        <NormalText style={{ textAlign: 'center', fontWeight: '700', color: 'white' }} platform={layoutType}>You’re eligible to apply for a loan
                            Acceptance likelihood 75%
                        </NormalText>
                    </NextBtn>
                </Card>
                <Card width={350} style={{ marginVertical: 40 }}>
                    <NormalText>
                        The Guaranteed APR means the rate shown is the rate you will  get if approved for this loan<br /><br />

                        In order to proceed, please click the button below. You will then be directed to Zopa’s site to complete your application. <br /><br />

                        <p style={{ color: 'red' }}>Please note the offer is subject to further lender identify and fraud Checks” </p>
                    </NormalText>
                </Card>
            </View>
        )
    };
    const followup=(id)=>{
        dispatch(tenantActions.zopaFollowup(id,(res)=>{
            if(res?.status==true){
                window.open(message?.redirectUrl,'_blank')
            }
            else{
              dispatch({
                type: types.SHOW_ALERT,
                alertType:"error",
                text: JSON.stringify(res?.message),
              });
            }
          }));
        };


    return (
        <>
            <Header
                MenuAccountWeb
                showDrawerMob
                showDrawerTab
                showLogoMob
                showLogoWeb
                showLogoTab
                rightChildLabelTab
                isTLYF
                {...props}
            />
            <Container>
                <Main>
                    <TopSection hideBack={true} goBack={() => props?.goBack()} title={'Zopa loan'} body={`Below is the status of your quote.`} />
                    <Content>
                        {message?.preapproval_status == "DECLINED" && <ContentWrap>
                            {layoutType != 'phone' && <View style={{ width: '30%' }} />}
                            {QuoteUnavailable()}
                        </ContentWrap>}
                        {message?.preapproval_status == "PREAPPROVED" &&
                            <ContentWrap>
                                {FormSection()}
                                {preApprovedSection()}
                            </ContentWrap>
                        }
                        {(message?.preapproval_status != "PREAPPROVED" && message?.preapproval_status != "DECLINED" ) &&
                            <ContentWrap>
                                {FormSection()}
                                {Eligble()}
                            </ContentWrap>
                        }
                    </Content>
                   {!(message?.preapproval_status == "DECLINED") && <View style={{ width: '100%', flexDirection: layoutType == "phone" ? 'column' : 'row', justifyContent: 'space-between', alignItems: 'center', marginVertical: 60 }}>
                        <NormalText style={{ width: layoutType == "phone" ? "100%" : '70%', marginBottom: layoutType == "phone" ? 30 : 0 }}>
                            <b>Representative example</b><br />
                            A loan of £{message?.amount} over {message?.term} months will cost you £{(message?.monthly_payments)?.toFixed(2)} per month at a representative {(message?.apr*100)?.toFixed(2)}% APR.<br /><br />

                            The total cost after {message?.term} months is £{(message?.total_cost_of_credit)?.toFixed(2)} which includes £{(message?.total_cost_of_credit-message?.amount)?.toFixed(2)} interest at {(message?.interest_rate*100)?.toFixed(2)}% fixed and a £{message?.fee} fee. The total amount of credit is £{message?.total_cost_of_credit?.toFixed(2)}.<br />

                            The rate you are offered will depend on your individual circumstances.<br />
                        </NormalText>
                        <CompleteBtnContainer onPress={()=>followup(message?.savedResponseId)}>
                            <BtnTextContainer>Complete Application</BtnTextContainer>
                        </CompleteBtnContainer>
                    </View>}

                </Main>
                <Footer isTLYF />
            </Container>

        </>
    )
}


const Row1 = styled.View`
  width: ${props => (props.platform == 'web' ? "1000px" : (props.platform == 'tab') ? '90%' : '100%')};
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0;
  justify-content: ${props => (props.platform == 'web' ? "space-between" : (props.platform == 'tab') ? 'space-between' : 'center')};
`;


const CardRow1 = styled(Row1)`
  width: 343px;
  height: 44px;
  justify-content: space-between;
  padding: 0 20px;
  align-items: center;
`;

const CardRow2 = styled(CardRow1)`
  border: 1px solid #D8D8D8;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-width: 0;
`;

const Text = styled.Text`
  font-size: 13px;
  color: #000;
  font-weight: 400;
  font-family: 'Lato';
`;

const DescriptionText = styled(Text)`
  font-weight: 700;
`;

const ValueText = styled(Text)`
  font-weight: 700;
  font-size: 19px;
  color: rgb(255, 181, 128);
`;

const PreAprovedBtn = styled.TouchableOpacity`
  width: 167px;
  height: 55px;
  border-radius: 25px;
  justify-content: center;
  background-color: #00B401;
`;


const CompleteBtnContainer = styled(PreAprovedBtn)`
  width: 200px;
  height: 61px;
  background-color: #D89F6E;
`;

const BtnTextContainer = styled.Text`
  text-align: center;
  font-size: 16px;
  color: #fff;
  font-weight: 700;
  font-family: 'Lato';
`;
export default Quote;
