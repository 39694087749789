import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";
import { getAnalytics } from "firebase/analytics";
export const firebaseConfig = {
    apiKey: "AIzaSyBRmzLl3Fb5WKFmtINsLvddTIxiYH_c2DA",
    authDomain: "obxdev-14197.firebaseapp.com",
    projectId: "obxdev-14197",
    storageBucket: "obxdev-14197.appspot.com",
    messagingSenderId: "282150369524",
    appId: "1:282150369524:web:294cbcfc4dcc95a77cc6a1",
    measurementId: "G-F6ZS2X0S8Q"
  };
export const app = initializeApp(firebaseConfig);
export const messaging=getMessaging(app);
export const analytics = getAnalytics(app);