import React, { useState } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { ODSIcon } from '../ODSIcon';
import { useTheme } from '@emotion/react';
import { ODSText } from '../ODSText';

const ODSRadioButton = ({ checked, onPress, label, color, disabled, style }) => {
  const theme = useTheme();
  const [selected, setSelected] = useState(checked);

  const onChecked = () => {
    if (!disabled) {
      // setSelected(!selected);
      onPress && onPress(!selected);
    }
  };

  return (
    <TouchableOpacity
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        opacity: disabled ? 0.5 : 1,
        ...style,
      }}
      onPress={onChecked}
      disabled={disabled}>
      <View
        style={{
          width: 24,
          height: 24,
          borderColor: disabled
            ? theme.colors.disabled.text
            : checked
            ? theme.colors.button.contrast
            : theme.colors.other['border-neutral'],
          borderWidth: checked ? 1 : 1,
          borderRadius: 30,
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        {checked && (
          <View
            style={{
              width: 10,
              height: 10,
              backgroundColor: disabled ? theme.colors.disabled.text : theme.colors.button.contrast,
              borderRadius: 10,
            }}
          />
        )}
      </View>
      <ODSText type="sm" marginLeft={8} color={theme.colors.text.primary}>
        {label}
      </ODSText>
    </TouchableOpacity>
  );
};

export default ODSRadioButton;
