import { View } from 'react-native';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';
import Constant from './tdsRedux/Constant';
import { backgroundTdsArr } from './backgroundTdsArr';
import getPlatformType from '../../helpers/getPlatform';
import { navigateAction, popAction } from '../../helpers/navigationHelpers';
import { Container, ButtonWrapper, Typography, BoxShadow } from './style';
import BackGroundGenerator from '../LandingPages/common/BackGroundGenerator';
import CustomTextInput from '../../commonComponents/customTextInput/customTextInput';
import { EmailValidation } from '../../helpers/validation';
import { types } from '../../redux/actionTypes';
import { tdsActions } from './tdsRedux/tds.actions';
import Modal  from "react-native-modal";

const custodialActivation = (props) => {
  const dispatch = useDispatch();
  const layoutType = getPlatformType();
  const [type, setType] = useState('web');
  const [isSFUser, setIsSFUser] = useState(null);

  const [state, setState] = useState({
    email: '',
    activation_code: '',
    resendEmail: '',
    captchaToken:"",
    last_name:"",
    last_name:"",
  });
  const [sentModal, setModal] = useState(false);

  useEffect(() => {
    if (props?.route?.params?.isSFUser=="Yes") {
      setIsSFUser(props?.route?.params?.isSFUser);
    } else {
      setIsSFUser(null);
    }
  }, [props?.route?.params?.isSFUser]);

  const resendActivationLink = () => {
    navigateAction(props?.navigation, 'TDS Create Password');
  };
  const activateNow = () => {
    const emailValidate = EmailValidation(state?.email);
    if (emailValidate?.status) {
      if (state?.activation_code != '') {
        let payload = {
          email: state?.email.trim(),
          activation_code: state?.activation_code.trim(),
        };
        dispatch(
          tdsActions.activateEmail(
            payload,
            (e) => {
              dispatch({
                type: types.SHOW_ALERT,
                alertType: 'success',
                text: 'Activate successfully',
              });
              navigateAction(props?.navigation, 'TDS Create Password', {
                email: state?.email.trim(),
                activation_code: state?.activation_code.trim(),
              });
            },
            isSFUser
          )
        );
      } else {
        dispatch({
          type: types.SHOW_ALERT,
          alertType: 'error',
          text: 'Invalid activation code',
        });
      }
    } else {
      dispatch({
        type: types.SHOW_ALERT,
        alertType: 'error',
        text: 'Invalid email',
      });
    }
  };
  const activateNowSf = () => {
    const emailValidate = EmailValidation(state?.email);

    if (state?.captchaToken) {
    if (emailValidate?.status) {
      if (state?.last_name) {
      if (state?.first_name) {
      if (state?.activation_code != '') {
        let payload = {
          email: state?.email.trim(),
          password: state?.activation_code.trim(),
          first_name:state.first_name,
          last_name:state.last_name,
          recaptcha:state.captchaToken
        };
        dispatch(
          tdsActions.activateEmail(
            payload,
            (e) => {
              dispatch({
                type: types.SHOW_ALERT,
                alertType: 'success',
                text: 'Activate successfully',
              });
              navigateAction(props?.navigation, 'TDS Create Password', {
                email: state?.email.trim(),
                activation_code: state?.activation_code.trim(),
              });
            },
            isSFUser
          )
        );
      } else {
        dispatch({
          type: types.SHOW_ALERT,
          alertType: 'error',
          text: 'Invalid activation code',
        });
      }
    }else {
      dispatch({
        type: types.SHOW_ALERT,
        alertType: 'error',
        text: 'Please enter first name',
      });
    }}else {
      dispatch({
        type: types.SHOW_ALERT,
        alertType: 'error',
        text: 'Please enter last name',
      });
    }
    } else {
      dispatch({
        type: types.SHOW_ALERT,
        alertType: 'error',
        text: 'Invalid email',
      });
    }}else{
      dispatch({
        type: types.SHOW_ALERT,
        alertType: 'error',
        text: 'Please validate recaptcha first!',
      });
    }
  };
  const resendCode = () => {
    const emailValidate = EmailValidation(state?.resendEmail);
   if(state?.captchaToken){
    if (emailValidate?.status) {
      let payload = {
        email: state?.resendEmail.trim(),
        recaptcha:state?.captchaToken
      };
      dispatch(
        tdsActions.sendActivationCode(
          payload,
          (e) => {
            setModal(true);
          },
          isSFUser
        )
      );
    } else {
      dispatch({
        type: types.SHOW_ALERT,
        alertType: 'error',
        text: 'Invalid email',
      });
    }
   }else{
      dispatch({
        type: types.SHOW_ALERT,
        alertType: 'error',
        text: 'Please validate recaptcha first!',
      });
   }
  
  };
  const resendCodeWithoutCaptch = () => {
    const emailValidate = EmailValidation(state?.resendEmail);
 
    if (emailValidate?.status) {
      let payload = {
        email: state?.resendEmail.trim(),
      };
      dispatch(
        tdsActions.sendActivationCode(
          payload,
          (e) => {
            setModal(true);
          },
          isSFUser
        )
      );
    } else {
      dispatch({
        type: types.SHOW_ALERT,
        alertType: 'error',
        text: 'Invalid email',
      });
    }
   }

  const handleChange = (value, field) => {
    setState({ ...state, [field]: value });
  };
  const handleOnChange = (value) => {
    setState({
      ...state,
      recaptchaValue: true,
      captchaToken: value,
    });
  };
  const renderBoxSection = () => {
    return (
      <View
        style={{
          width: '100%',
          flexDirection: layoutType == 'phone' ? 'column' : 'row',
          marginTop: 50,
          marginBottom: 50,
        }}
      >
        <BoxShadow
          bottom={'30px'}
          style={layoutType == 'phone' ? {} : { marginRight: 8, flex: 0.5 }}
        >
          <Typography top={'20px'} color={'#0000002'} size={'23px'} bold={700}>
            Activate Now
          </Typography>
          <Typography top={'30px'} color={'#454545'} size={'20px'} bold={400}>
            To activate your account, refer to the email sent by TDS at the start of your tenancy
            which contains your activation code.{' '}
          </Typography>
          <Typography top={'30px'} color={'#454545'} size={'20px'} bold={400} bottom={40}>
            Simply enter this code and your email address below to activate your TDS account. You
            will then be asked to create a password.{' '}
          </Typography>

          {isSFUser =='Yes'? (
            <>
              <CustomTextInput
                id="first_name"
                width={'100%'}
                placeHolder="First Name"
                type="text"
                name="first_name"
                // prefilledValue={state?.activation_code}
                bColor={'#638ACC'}
                onChangeText={(value) => handleChange(value, 'first_name')}
              />
              <CustomTextInput
                id="last_name"
                width={'100%'}
                placeHolder="Last Name"
                type="text"
                name="last_name"
                // prefilledValue={state?.activation_code}
                bColor={'#638ACC'}
                onChangeText={(value) => handleChange(value, 'last_name')}
              />
            </>
          ) : null}
          <CustomTextInput
            id="Email"
            width={'100%'}
            placeHolder="Email"
            type="text"
            name="email"
            // prefilledValue={state?.email}
            bColor={'#638ACC'}
            onChangeText={(value) => handleChange(value, 'email')}
          />
          <CustomTextInput
            id="activationCode"
            width={'100%'}
            placeHolder="Activation Code"
            type="text"
            name="activationCode"
            // prefilledValue={state?.activation_code}
            bColor={'#638ACC'}
            onChangeText={(value) => handleChange(value, 'activation_code')}
          />

          {/* <Typography bottom={'30px'} top={'30px'} color={'#454545'} size={'20px'} bold={400}>
            If you have any queries please{' '}
            <Typography
              onPress={() => window.open(Constant.TDS_CONTACTUS, '_blank')}
              color={'#2052A6'}
              size={'20px'}
              bold={800}
            >
              {' '}
              click here{' '}
            </Typography>
            to contact us.
          </Typography> */}
          {isSFUser=="Yes" ? (
            <View
              style={{
                paddingTop: 20,
                alignItems: 'center',
              }}
            >
              <ReCAPTCHA
                id={'register-recaptcha'}
                sitekey={Constant.RECAPTCHA_KEY}
                onChange={handleOnChange}
              />
            </View>
          ) : null}
          <ButtonWrapper
            color={'#659B63'}
            bcolor={'#659B63'}
            width={'100%'}
            style={{ marginTop: 30 }}
            onPress={() => isSFUser =='Yes'?activateNowSf(): activateNow()}
          >
            <Typography color={'#FFFFFF'} size={'19px'} bold={700}>
              ACTIVATE
            </Typography>
          </ButtonWrapper>
        </BoxShadow>
        <BoxShadow
          bottom={'30px'}
          style={layoutType == 'phone' ? {} : { marginLeft: 8, flex: 0.5 }}
        >
          <Typography top={'20px'} color={'#0000002'} size={'23px'} bold={700}>
            Resend Activation Code
          </Typography>
          <Typography top={'30px'} color={'#454545'} size={'20px'} bold={400}>
            If you have not received your activation code, or the link has expired, please enter the
            email used with your TDS account and click resend.{' '}
          </Typography>
          <Typography bottom={'100px'} top={'30px'} color={'#454545'} size={'20px'} bold={400}>
            If you have any queries please{' '}
            <Typography
              onPress={() => window.open(Constant.TDS_CONTACTUS, '_blank')}
              color={'#2052A6'}
              size={'20px'}
              bold={800}
            >
              click here{' '}
            </Typography>
            to contact us.
          </Typography>

          <CustomTextInput
            id="resendEmail"
            width={'100%'}
            placeHolder="Email"
            type="text"
            name="resendEmail"
            // prefilledValue={state?.resendEmail}
            bColor={'#638ACC'}
            onChangeText={(value) => handleChange(value, 'resendEmail')}
          />
           {isSFUser=="Yes" ? (
            <View
              style={{
                paddingTop: 20,
                alignItems: 'center',
              }}
            >
              <ReCAPTCHA
                id={'register-recaptcha'}
                sitekey={Constant.RECAPTCHA_KEY}
                onChange={handleOnChange}
              />
            </View>
          ) : null}
          <ButtonWrapper
            color={'#659B63'}
            bcolor={'#659B63'}
            width={'100%'}
            style={{ marginTop: 30 }}
            onPress={() => isSFUser=="Yes" ?resendCode(): resendCodeWithoutCaptch() }
          >
            <Typography color={'#FFFFFF'} size={'19px'} bold={700}>
              RESEND ACTIVATION EMAIL
            </Typography>
          </ButtonWrapper>
        </BoxShadow>
      </View>
    );
  };

  return (
    <>
      <BackGroundGenerator
        infoIcon
        isTds
        isBack={true}
        props={props}
        layoutType={(data) => {
          setType(data);
        }}
        isTLYF
        backgroundArr={backgroundTdsArr}
        backNavigation={() => popAction(props.navigation, 'TDS Search Deposit')}
      >
        <Container width={'1080px'}>
          <View
            style={{
              width: '60%',
              justifyContent: 'center',
              alignItems: 'center',
              marginVertical: 10,
              borderRadius: 10,
              alignSelf: 'center',
            }}
          >
            <Typography color={'#ffffff'} size={'29px'} bold={'bold'}>
              Activate your account
            </Typography>
            <Typography color={'#ffffff'} size={'23px'} top={'20px'} bold={'500'}>
              This screen will allow you to activate your TDS Account
            </Typography>
          </View>
          {renderBoxSection()}
          <Modal
            isVisible={sentModal}
            transparent={true}
            coverScreen={false}
            hasBackdrop={false}
            backdropOpacity={0}
            onBackdropPress={() => setModal(false)}
            style={{ margin: 0, width: '100%', flex: 1, backgroundColor: '#00000080' }}
          >
            <View
              style={{
                width: layoutType == 'phone' ? '95%' : '70%',
                backgroundColor: '#FAFAFA',
                borderRadius: 20,
                overflow: 'hidden',
                alignSelf: 'center',
                padding: layoutType == 'phone' ? 30 : 80,
              }}
            >
              <BoxShadow
                style={{ backgroundColor: '#C4F5D8', paddingHorizontal: 30, paddingVertical: 30 }}
              >
                <Typography
                  id={'TDS-FORGOT-PASSWORD-TEXT'}
                  color={'#4C664B'}
                  size={'23px'}
                  bold={800}
                >
                  Activation Email Sent
                </Typography>
              </BoxShadow>
              <Typography top={'80px'} color={'#454545'} size={'20px'} bold={400} text>
                Please check email for the activation code.{'\n'}
                Once you have received this you can return here to activate your account.{'\n'}
                If you have not received an email please{' '}
                <Typography
                  onPress={() => window.open(Constant.TDS_CONTACTUS, '_blank')}
                  color={'#2052A6'}
                  size={'20px'}
                  bold={800}
                >
                  contact us.
                </Typography>
              </Typography>
              <ButtonWrapper
                width={layoutType == 'phone' ? '60%' : '30%'}
                style={{ marginTop: 80 }}
                onPress={() => setModal(false)}
              >
                <Typography color={'#FFFFFF'} size={'19px'} bold={700}>
                  BACK
                </Typography>
              </ButtonWrapper>
            </View>
          </Modal>
        </Container>
      </BackGroundGenerator>
    </>
  );
};

export default custodialActivation;
