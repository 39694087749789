import React from "react";
import { View } from "react-native";
import { Svg, Circle, Text as SVGText } from "react-native-svg";
import { COLORS } from "../constants/colorPallete";

const CircularProgress = (props) => {
  const { size, strokeWidth, text,customText } = props;
  const pointerRadius = props.pointerRadius ? props.pointerRadius : 5
  const radius = (size - strokeWidth) / 2 - pointerRadius;
  const circum = radius * 2 * Math.PI;
  const svgProgress = 100 - props.progressPercent;
  const angle = props.progressPercent * 360 / 100;
  const pointerX = (size / 2) + (radius * Math.sin(angle * Math.PI / 180));
  const pointerY = (size / 2) - (radius * Math.cos(angle * Math.PI / 180));

  return (
    <View style={{ margin: 10, borderWidth: 0 }}>
      <Svg width={size} height={size}>
        {/* Background Circle */}
        <Circle
          stroke={props.bgColor ? props.bgColor : "#f2f2f2"}
          fill="none"
          cx={size / 2}
          cy={size / 2}
          r={radius}
          {...{ strokeWidth }}
        />
        <Circle
          stroke={props.pgColor ? props.pgColor :COLORS.orange}
          fill="none"
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeDasharray={`${circum} ${circum}`}
          strokeDashoffset={radius * Math.PI * 2 * (svgProgress / 100)}
          strokeLinecap="round"
          transform={`rotate(-90, ${size / 2}, ${size / 2})`}
          {...{ strokeWidth }}
        />
        <Circle
          fill={props.pointerColor ? props.pointerColor : "#E82C70"}
          cx={pointerX}
          cy={pointerY}
          r={pointerRadius}
        />
        {customText?
            <SVGText
            id="noofDaysLeft  "
            fontSize={props.textSize ? props.textSize : "35"}
            x={size / 2}
            y={36}
            textAnchor="middle"
            fill={props.textColor ? props.textColor : "#ffffff"}
            fontWeight={"bold"}
            fontFamily={"Lato"}
          >
            {customText?customText:""}
          </SVGText>:null
      
      }
     {text&&   <>
        <SVGText
          id="noofDaysLeft  "
          fontSize={props.textSize ? props.textSize : "35"}
          x={size / 2}
          y={size / 2.4 + (props.textSize ? props.textSize / 2 - 1 : 5)}
          textAnchor="middle"
          fill={props.textColor ? props.textColor : "#ffffff"}
          fontWeight={"bold"}
          fontFamily={"Lato"}
        >
          {text}
        </SVGText>
        <SVGText
          id="daysLeft"
          fontSize={"19.5"}
          x={size / 2}
          y={size / 1.7 + (19.5 / 2 - 1 )}
          textAnchor="middle"
          fill={"#000000"}
          fontWeight={"bold"}
          fontFamily={"Lato"}
       >
          days left
        </SVGText>
        </>}
      </Svg>
    </View>
  );
};

export default CircularProgress;
