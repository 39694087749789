import React from 'react';
import styled from 'styled-components/native';
import getPlatformType from '../../helpers/getPlatform';
const layoutType = getPlatformType();

let FooterWrapper;
let Container;
let FooterLogo;
let FooterLinks1;
let FooterLinks2;
let SocialLinks;
let TextContent;
let SocialImg;
let ObxWhiteImg;
let SocialImgBox;
let Vline;

if (layoutType == 'phone') {
  FooterWrapper = styled.View`
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    height: 100%;
    align-items: center;
    padding: 0;
  `;

  Container = styled.View`
    height: 150px;
    width: 100%;
    padding-right: 16px;
    padding-left: 16px;
    margin-right: auto;
    margin-left: auto;
  `;

  FooterLogo = styled.View`
    flex-grow: 6;
  `;

  FooterLinks1 = styled.View`
    flex-grow: 2;
  `;

  FooterLinks2 = styled.View`
    flex-grow: 2;
  `;

  ObxWhiteImg = styled.Image`
    height: 40px;
    width: 100px;
  `;

  SocialLinks = styled.View`
    display: flex;
    flex-direction: row;
  `;

  SocialImgBox = styled.TouchableOpacity`
    margin-right: 11px;
  `;
  SocialImg = styled.Image`
    height: 28px;
    width: 28px;
  `;

  TextContent = styled.Text`
    color: #ffffff;
    font-size: 13px;
    margin-right: 6px;
  `;
  Vline = styled.View`
    height: 16px;
    width: 1px;
    background-color: #ffffff;
    margin-right: 6px;
  `;
} else if (layoutType == 'web') {
  FooterWrapper = styled.View`
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    height: 100%;
    align-items: center;
  `;

  Container = styled.View`
    height: 150px;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
  `;

  FooterLogo = styled.View`
    flex-direction: 'column';
  `;

  FooterLinks1 = styled.View`
    flex-grow: 1;
  `;

  FooterLinks2 = styled.View`
    flex-grow: 1;
  `;
  ObxWhiteImg = styled.Image``;
  SocialLinks = styled.View`
    display: flex;
    flex-direction: row;
    margin-left: 10px;
  `;

  SocialImg = styled.Image`
    height: 22px;
    width: 22px;
  `;
  SocialImgBox = styled.TouchableOpacity`
    margin-right: 16px;
  `;
  TextContent = styled.Text`
    color: #ffffff;
    font-size: 14px;
    margin-right: 20px;
  `;
  Vline = styled.View`
    height: 16px;
    width: 1px;
    background-color: #ffffff;
    margin-right: 20px;
  `;
} else {
  FooterWrapper = styled.View`
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    height: 100%;
    align-items: center;
  `;

  Container = styled.View`
    height: 150px;
    width: 100%;
    padding-right: 120px;
    padding-left: 120px;
    margin-right: auto;
    margin-left: auto;
  `;

  FooterLogo = styled.View`
    flex-grow: 8;
  `;

  FooterLinks1 = styled.View`
    flex-grow: 1;
  `;

  FooterLinks2 = styled.View`
    flex-grow: 1;
  `;

  ObxWhiteImg = styled.Image`
    height: 40px;
    width: 130px;
  `;

  SocialLinks = styled.View`
    display: flex;
    flex-direction: row;
  `;

  SocialImg = styled.Image`
    height: 25px;
    width: 24px;
  `;
  SocialImgBox = styled.TouchableOpacity`
    margin-right: 16px;
  `;
  TextContent = styled.Text`
    color: #ffffff;
    font-size: 14px;
    margin-right: 20px;
  `;
  Vline = styled.View`
    height: 16px;
    width: 1px;
    background-color: #ffffff;
    margin-right: 20px;
  `;
}

export {
  Vline,
  FooterWrapper,
  Container,
  FooterLinks1,
  FooterLinks2,
  FooterLogo,
  SocialLinks,
  TextContent,
  SocialImg,
  ObxWhiteImg,
  SocialImgBox,
};
