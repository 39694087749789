import React, { useState } from "react";
import { View, Text, SafeAreaView, ScrollView } from "react-native";

import getPlatformType from "../../helpers/getPlatform";
const layoutType = getPlatformType();

import Header from "../../commonComponents/headerComponent/headerComponent";

const ResetMailConfirmation = (props) => {
  return (
    <SafeAreaView style={{ backgroundColor: "#FAFAFA", height: "100%" }}>
      <Header
        showLogoMob
        showLogoWeb
        showLogoTab
        rightChildLabelTab
        rightChildLabelWeb
        showBackButtonMob
        showBackButtonTab
        isTLYF
        {...props}
      />
      <ScrollView style={{ height: "100%" }}>
        <View
          style={{
            height: "100%",
            flex: 1,
            alignItems: "center",
            marginBottom: 40,
            justifyContent: "center",
          }}
        >
          <View
            style={{
              width: layoutType == "phone" ? "100%" : "80%",
              height: 200,
              backgroundColor: "#ffffff",
              justifyContent: "center",
              alignItems: "center",
              marginTop: layoutType == "phone" ? 0 : 40,
            }}
          >
            <Text style={{ fontWeight: "bold" ,textAlign:'center'}}>
              Thanks, please check your email for reset instructions.
            </Text>
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default ResetMailConfirmation;
