import React, { useState } from "react";
import { Image, Linking, Text, View } from "react-native";
import AutoHeightImage from "../common/AutoImage";
import Rings from "../common/Rings";
import ThreeSection from "../common/ThreeSection";
import TwistedButton from "../common/TwistedButton";
import stylesMobile from "./stylesMobile";
import stylesTab from "./stylesTab";
import stylesWeb from "./stylesWeb";
import Easy from "../../../assets/SVG/landingIcons/easy.svg";
import Credit from "../../../assets/SVG/landingIcons/credit.svg";
import Verify from "../../../assets/SVG/landingIcons/verify.svg";
import Digital from "../../../assets/SVG/landingIcons/digital.svg";
import Crm from "../../../assets/SVG/landingIcons/crm.svg";
import Audit from "../../../assets/SVG/landingIcons/audit.svg";
import { backgroundSearchArr } from "../images/openFlo/searchArrayOpenflo";
import BackGroundGenerator from "../common/BackGroundGenerator";
import ThreeCircle from "../common/ThreeCircle";
import YoutubePlayer from "../../../commonComponents/youtubePlayer/youtubePlayer";
import Constant from "../../openFlow/openFlowRedux/Constant";
import { trackAnalytics } from "../../../helpers/navigationHelpers";
import { useSelector } from "react-redux";

const listSection = [
  {
    svg: Digital,
    heading: "DIGITAL REFERERNCE",
    sub: "Comprehensive digital referencing in real-time!",
  },
  {
    svg: Verify,
    heading: "ID VERIFICATION",
    sub: "Proving a tenants right-to-rent in a quick and efficient digital manner",
  },
  {
    svg: Crm,
    heading: "CRM INTEGRATION",
    sub: "Reduced administration and double-keying through our industry wide CRM integration",
  },
  {
    svg: Audit,
    heading: "AUDIT TRAIL",
    sub: "Fully secure and auditable document storage and process management, ensuring full compliance",
  },
  {
    svg: Credit,
    heading: "IMPROVED CREDIT SCORE",
    sub: "Enabling tenants to improve their credit score with rent payment tracking and reporting",
  },
  {
    svg: Easy,
    heading: "EASY TO USE",
    sub: "Simple, intuitive and easy to use dashboard, helping to make life easier for lettings agents whilst also providing security and compliance",
  },
];
const LandingOpenflo = (props) => {
  const [type, setType] = useState("web");
  const styles = type == "web" ? stylesWeb : type == "mobile" ? stylesMobile : stylesTab;
  const isAnylyticsEnable = useSelector(
    (state) => state?.cookiesReducer?.customiseCookies.Analytics,
  );
  console.log("props", props.route);
  const [state, setState] = useState({
    from: "",
  });

  React.useEffect(() => {
    if (props?.route?.params?.from) {
      setState({
        ...state,
        from: props?.route?.params?.from ? props?.route?.params?.from : "",
      });
    }
  }, [props?.route?.params?.from]);
  return (
    <>
      <BackGroundGenerator
        props={props}
        isLanding
        layoutType={(data) => setType(data)} backgroundArr={backgroundSearchArr}>
        <View style={[styles.container]}>
          <View style={[styles.headingWrapper, styles.horizontal]}>
            <View>
              <Text style={[styles.headingText, styles.headingTextWrapper]}>SMART TENANT</Text>
              <Text style={[styles.headingText, styles.headingTextWrapper]}>ONBOARDING</Text>
              <View style={{ flexDirection: "row" }}>
                <Text style={[styles.headingText, styles.headingTextWrapper]}>WITH</Text>
                <AutoHeightImage
                  style={{
                    width: 235,
                    marginLeft: 6,
                  }}
                  uri={require("./../images/openFlo/web/openflo.svg")}
                />
              </View>
            </View>

            <Rings size={type == "web" ? 150 : 130}/>
            <View style={styles.videoWrapper}>
              <YoutubePlayer
                url={Constant.OPENFLO_URL}
                height={"100%"}
                width={"100%"}
              />
            </View>
          </View>
          <View style={[styles.visionWrapper, { flexDirection: "row" }]}>
            <Text style={[styles.heading1]}>Introducing</Text>
            <AutoHeightImage
              style={{
                width: 197,
                marginLeft: 6,
              }}
              uri={require("./../images/openFlo/web/openflo.svg")}
            />
            <Rings size={50}/>
          </View>
          <View style={[styles.visionSubTextWrapper, styles.horizontal]}>
            <Text style={[styles.heading3, styles.TextCenter]}>
              The next generation tenant onboarding solution.
            </Text>
            <Text style={[styles.heading3, styles.TextCenter, { marginTop: 30 }]}>
              The innovative and interactive onboarding management solution designed specifically
              for letting agents, ensuring the tenant onboarding process is completed in a smooth,
              timely and compliant manner.
            </Text>
            <TwistedButton
              title={"CLICK TO REGISTER/LOGIN"}
              id={"click-to-register-openflo"}
              customStyle={{ marginTop: 45 }}
              onPress={() => {
                isAnylyticsEnable ? trackAnalytics("Button", "OnClick", "Landing-Openflo-Sign-in") : null;
                Linking.openURL("https://agent.openbrix.co.uk/login");
              }}
            />
          </View>
          <View style={styles.saperaterWrapper}>
            <View style={styles.saperator}/>
          </View>
          <View style={[styles.cardWrapper, styles.horizontal]}>
            {listSection.map((item, index3) => (
              <ThreeSection
                showRings
                layoutType={type}
                key={index3}
                icon={<Image source={item.svg} style={{
                  width: 40,
                  height: 40,
                }}/>}
                heading={item?.heading}
                description={item?.sub}
                customStyle={styles.cardSection}
              />
            ))}
          </View>
          <View style={styles.saperaterWrapper}>
            <View style={styles.saperator}/>
          </View>
          <View style={[styles.piramidWrapper, styles.horizontal]}>
            <ThreeCircle
              layoutType={type}
              size={250}/>
          </View>
          <View style={[styles.horizontal]}>
            <TwistedButton
              title={"CLICK TO REGISTER/LOGIN"}
              id={"click-to-register-openflo"}
              customStyle={{ marginBottom: 60 }}
              onPress={() => {
                isAnylyticsEnable ? trackAnalytics("Button", "OnClick", "Landing-Openflo-Sign-in") : null;
                Linking.openURL("https://agent.openbrix.co.uk/login");
              }}
            />
          </View>
          <View style={[styles.horizontal, { marginBottom: 30 }]}>
            <Text style={[styles.heading2, styles.TextCenter, { textTransform: "uppercase" }]}>
              Automated, innovative, compliant and complete! {"\n"}Bringing harmony to agents and
              their tenants!
            </Text>
          </View>
        </View>
      </BackGroundGenerator>
    </>
  );
};
export default LandingOpenflo;
