import React, { Component } from "react";
import {
  View,
  Text,
  SafeAreaView,
  Image,
  TouchableOpacity,
  ScrollView
} from "react-native";
import getPlatformType from "../../helpers/getPlatform";
import {
  Container, Box, Title, Discription, WideButtonWrapper
} from "./styles";
import Header from "../../commonComponents/headerComponent/headerComponent";
import ImageResizeMode from "react-native/Libraries/Image/ImageResizeMode";
import WideButton from "../../commonComponents/wideButton";
const layoutType = getPlatformType();

export default class NoData extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  onButtonPress=()=>{
    this.props.onPress();
  }

  render() {
    const { navigation } = this.props;

    return (
   <View style={{flex:1}}>
        <Container>
          <Box>
            <View style={{
              width: 108,
              height: 108,
              borderRadius: 108,
              display: 'flex',
              backgroundColor: '#f1f1f1',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: 10
            }}>
              <View style={{
                width: 90,
                height: 90,
                borderRadius: 90,
                display: 'flex',
                backgroundColor: '#fff',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                <Image
                  source={require("../../assets/images/creditScore.png")}
                  resizeMode={ImageResizeMode.contain}
                  style={{
                    width: 45, height: 45, alignItems: 'center',
                    justifyContent: 'center'
                  }}
                ></Image>
              </View>
            </View>
            <Title>{this.props.text}</Title>
            <Discription>Why not try widening your search or broadening your requirements? </Discription>

            <WideButtonWrapper>
              <WideButton
                borderRadius={15}
                childText="Back to search"
                whiteButton={true}
                gradiant
                onPress={()=>this.onButtonPress()}
              />
            </WideButtonWrapper>
          </Box>
        </Container>
        </View>    
    );
  }
}
