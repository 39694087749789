import ReactGA from "react-ga4";

export const navigateAction = (navigationProp, keyName, dataToAppend) => {
  // takes navigation as the first arguement, keyname to which the navigation is to be made as the second arguement, data that is meant to be passed as props as the third arguement
  navigationProp.navigate(keyName, dataToAppend);
};

export const pushAction = (navigationProp, keyName, dataToAppend) => {
  // takes navigation as the first arguement, keyname to which the navigation is to be made as the second arguement, data that is meant to be passed as props as the third arguement
  navigationProp.push(keyName, dataToAppend);
};

export const popAction = (navigationProp, numberofKeys) => {
  // takes number of keys to be popped as the arguement
  navigationProp.goBack();
};

export const replaceAction = (navigationProp, keyName, dataToAppend) => {
  // takes navigation as the first arguement, keyname to which the navigation is to be made as the second arguement, data that is meant to be passed as props as the third arguement
  navigationProp.replace(keyName, dataToAppend);
};
export const popToTopAction = (navigationProp, dataToAppend) => {
  // just takes the data to be appended as the arguement for passing the functions if needed
  navigationProp.navigate(dataToAppend);
};
export const trackAnalytics = (Category, Action, Label, Value) => {
  // ReactGA.initialize("G-084ENPRZN7");
  ReactGA.event({
    category: Category,
    action: Action,
    label: Label, // optional
    value: Value, // optional, must be a number
    nonInteraction: true, // optional, true/false
    transport: "xhr", // optional, beacon/xhr/image
  });
};
//
// ─── EXAMPLE OF HOW THE ACTION SHOULD LOOK LIKE ─────────────────────────────────
//

// import { navigateAction } from "../../helpers/navigationHelpers";

//  navigateAction(props.navigation, "SigninScreen", {
//      priyankItems: "PRIYANK",
//    });
