import React, { useState } from "react";

import MediaHubDetails from "./mediaHubDetails";
import BackGroundGenerator from "../LandingPages/common/BackGroundGenerator";
import { backgroundSearchArr } from "../LandingPages/images/tlyfe/searchArrayTlyfe";

const MediaHubDetailsPage = (props) => {
  const [type, setType] = useState("web");

  return (
    <BackGroundGenerator
    props={props}
    layoutType={(data) => setType(data)}
    backgroundArr={backgroundSearchArr}
    isTLYF={true}
    isLanding
  >
      <MediaHubDetails backLink="MediaHub" navigation={props.navigation} id={props?.route?.params?.id} />
    </BackGroundGenerator>
  );
};

export default MediaHubDetailsPage;
