import React from "react";
import styled from "styled-components/native";
import getPlatformType from "../../helpers/getPlatform";
import { FlatList } from "react-native-gesture-handler";

const layoutType = getPlatformType();

let BoxButton,
  BoxView,
  BoxText,
  BoxWrapper,
  BoxTextLabel,
  ImageWrapper,
  TitleView,
  IntroImg,
  TitleText;
if (layoutType == "phone") {
  BoxWrapper = styled.ScrollView`
    padding: 10px 0px 49px 0px;
  `;
  TitleView = styled.Text`
    color: #000000;
    margin-left: 20px;
    margin-top: 10px;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
  `;
  TitleText = styled.Text`
  margin: 30px 0px 30px 0px;
  color: #000000;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 21px;
  text-align:center;
  padding:10px;
  `;
  BoxView = styled.View`
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  flex-direction: row;
  `;
  BoxButton = styled.View`
  align-items: center;
  width: 132px;
  margin: 10px 10px 10px 10px;
  `;
  ImageWrapper = styled.View`
    height: 60px;
    width: 60px;
    border-radius: 30;
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
    shadow-color: #000;
  `;
  BoxText = styled.Text`
    color: #000000;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
    text-align: center;
  `;
  IntroImg = styled.Image`
    width: 30px;
    height: 30px;
  `;
} else if (layoutType == "web") {
  BoxWrapper = styled.View`
    align-items: center;
  `;
  TitleView = styled.Text`
    color: #000000;
    margin-left: 20px;
    margin-top: 10px;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
  `;
  TitleText = styled.Text`
    margin: 48px 0px 40px 0px;
    color: #000000;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
    text-align:center;
    width:50%;
  `;
  BoxView = styled.View`
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    
  `;
  BoxButton = styled.View`
    align-items: center;
    width: 208px;
    margin: 0 15px 48px;
  `;
  ImageWrapper = styled.View`
    height: 79.2px;
    width: 79.2px;
    border-radius: 4000px;
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
    shadow-color: #000;
  `;
  BoxText = styled.Text`
    color: #000000;
    font-size: 12.5px;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
  `;
  IntroImg = styled.Image`
    width: 40px;
    height: 40px;
  `;
} else {
  BoxWrapper = styled.View`
    padding-top: 10px;
    padding-bottom: 48px;
    align-items: center;
  `;
  TitleView = styled.Text`
    color: #000000;
    margin-left: 20px;
    margin-top: 10px;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
  `;
  TitleText = styled.Text`
  margin: 48px 0px 40px 0px;
  color: #000000;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 21px;
  text-align:center;
  width:50%;
  `;
  BoxView = styled.View`
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width:100%

  `;
  BoxButton = styled.View`
    align-items: center;
    width: 253px;
    margin: 20px 20px 20px 20px;
  `;
  ImageWrapper = styled.View`
    height: 79.2px;
    width: 79.2px;
    border-radius: 40px;
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
    shadow-color: #000;
  `;
  BoxText = styled.Text`
    color: #000000;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 18px;
    text-align: center;
  `;
  IntroImg = styled.Image`
    width: 40px;
    height: 40px;
  `;
}
BoxTextLabel = styled.Text`
  height: 18px;
  color: #000000;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 18px;
  text-align: left;
  margin: 5px 0px;
  padding-left: 2px;
`;

export {
  BoxButton,
  BoxView,
  BoxText,
  BoxWrapper,
  BoxTextLabel,
  TitleView,
  ImageWrapper,
  IntroImg,
  TitleText
};
