import React, {Component} from "react";
import {Platform} from "react-native";
import {View, SafeAreaView, ScrollView, Text} from "react-native";


import Header from "../../../commonComponents/headerComponent/headerComponent";


import {connect} from "react-redux";
import {bindActionCreators} from "redux";


class MarketPlaceDetails extends Component {
    

    render() {
        return (
            <SafeAreaView style={{backgroundColor: "#FAFAFA", height: "100%"}}>
                <Header
                    MenuAccountWeb
                    showDrawerMob
                    showDrawerTab
                    showLogoWeb
                    showLogoMob
                    showLogoTab
                    {...this.props}
                />
              
             
            </SafeAreaView>
        );
    }
}

const mapStateToProps = (state) => ({
    profileLoading: state.authReducer.profileLoading,
    profile: state.authReducer.profile,
    updateProfileStatus: state.authReducer.updateProfileStatus,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
           
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(MarketPlaceDetails);
