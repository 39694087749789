import React, { Component } from "react";
import {
  View,
  Text,
  Dimensions,
  SafeAreaView,
  Image,
  TouchableOpacity,
  Platform,
  ScrollView,
  FlatList,
} from "react-native";

import { MaterialIcons } from "@expo/vector-icons";
import getPlatformType from "../../helpers/getPlatform";
import Header from "../../commonComponents/headerComponent/headerComponent";
import IntroScreen from "../../commonComponents/introScreen";
import { Container, ThanksText } from "./styles";
const layoutType = getPlatformType();

export default class AgentConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { navigation } = this.props;
    const { navigate } = this.props.navigation;
    return (
      <ScrollView>
        <View style={{ flex: 1, alignItems: "center", marginBottom: 40 }}>
          <Header
            showLogoMob
            showLogoWeb
            showLogoTab
            showDrawerMob
            showDrawerTab
            isTLYF
            {...this.props}
          />
          {layoutType == "phone" ? (
            <View
              style={{
                marginTop: 15,
                marginBottom: 15,
                width: "100%",
                height: "60%",
              }}
            >
              <IntroScreen show={"Tab1"}/>
            </View>
          ) : null}
          <Container
            style={{
              shadowOffset: {
                width: 0,
                height: 2,
              },
              shadowOpacity: 0.09,
              shadowRadius: 6.68,
              elevation: 11,
            }}
          >
            <ThanksText>Thanks for registering with Tlyfe</ThanksText>
            <TouchableOpacity
              style={{
                width: "80%",
                borderRadius: 30,
                height: 40,
                borderColor: "#FFB581",
                borderWidth: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
              onPress={() => navigate("Sign in",{email:this.props?.route?.params?.email})}
            >
              <Text  id ="register-gotoLogin" style={{fontWeight:'500'}}>Go to login</Text>
            </TouchableOpacity>
          </Container>
        </View>
      </ScrollView>
    );
  }
}
