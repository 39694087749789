import React, { useEffect, useState } from "react";
import { Text, View, Image } from "react-native";
import { AntDesign } from "@expo/vector-icons";
import { LinearGradient } from "expo-linear-gradient";
import { TouchableOpacity } from "react-native-gesture-handler";
import complete from "../../../assets/images/complete.png";
import inprogress from "../../../assets/images/inprogress.png";
import notstarted from "../../../assets/images/notstarted.png";
import { COLORS } from "../../../constants/colorPallete";

const CardComponent = (props) => {
  
  const { status, title, onCardClick, statusTitle } = props;
  const [ThemeColor, setThemeColor] = useState([]);
  const [statusText, setStatus] = useState("");

  useEffect(() => {
    

    switch (status) {
      case "VERIFIED":
        setThemeColor(["#9DDEA9", "#9DDEA9"]);
        setStatus("Verified");
        break;
      case "NOT_STARTED":
        setThemeColor(["#F28A7D", "#EDB6AB"]);
        setStatus("Not Started");
        break;
      case "IN_PROGRESS":
        setThemeColor(["#EEAB6D", "#EFA968"]);
        setStatus("In Progress");
        break;
      case "Completed":
        setThemeColor(["#9DDEA9", "#9DDEA9"]);
        setStatus("Completed");
        break;
      default:
        setThemeColor(["#F28A7D", "#EDB6AB"]);
        setStatus("Not Started");
        break;
    }
  }, [status]);
  useEffect(() => {
    if (statusTitle) {
      setStatus(statusTitle);
    }
  }, [statusTitle]);
  const getICON = () => {
    switch (status) {
      case "VERIFIED":
        return complete;
        break;
      case "Completed":
        return complete;
        break;
      case "NOT_STARTED":
        return notstarted;
        break;
      case "IN_PROGRESS":
        return inprogress;
        break;
      default:
        return notstarted;
        break;
    }
  };
  const getColor = () => {
    switch (status) {
      case "VERIFIED":
        return "#4CAF50";
        break;
      case "Completed":
        return "#4CAF50";
        break;
      case "NOT_STARTED":
        return "grey";
        break;
      case "IN_PROGRESS":
        return "#EDABC5";
        break;
      default:
        return "grey";
        break;
    }
  };

  return (
    <View
      style={{
        width: 323,
        height: 100,
        alignItems: "flex-end",
        justifyContent: "center",
        margin: "2%",
      }}
    >
      <View
        style={{
          height: 77,
          width: "76%",
          justifyContent: "center",
          alignItems: "center",
          paddingHorizontal: "9%",
          flexWrap: "wrap",
          shadowColor: "#000",
          shadowOffset: {
            width: 0,
            height: 2,
          },
          shadowOpacity: 0.25,
          shadowRadius: 3.84,
          backgroundColor: "white",
          elevation: 5,
          borderTopRightRadius: 10,
          borderColor: "#dadada",
          borderWidth: 1,
        }}
      >
        {title ? <Text style={{ fontWeight:"bold",flexWrap: "wrap" }}>{title}</Text> : null}
        {status||statusTitle ? (
          <TouchableOpacity
            onPress={(data) => {
              props.onCardClick(statusText);
            }}
            style={{
              width: 133,
              alignItems: "center",
              justifyContent: "space-between",
              borderRadius: 16,
              borderWidth: 1,
              marginTop: 10,
              borderColor: "#D0D0D0",

              paddingHorizontal: "8%",
              paddingVertical: "3%",

              flexDirection: "row",
            }}
          >
            <Text style={{  textAlign:"center"}}>{statusText}</Text>
            <AntDesign
              onPress={() => {}}
              name="right"
              size={16}
              id={props.id ? props.id : undefined}
              color={COLORS.orange}
            />
          </TouchableOpacity>
        ) : null}
      </View>
      <LinearGradient
        colors={ThemeColor}
        style={{
          height: 100,
          width: 100,
          borderRadius: 50,
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          left: 0,
          backgroundColor: "#9DDEA9",
        }}
      >
        {/* <View
          style={{
            height: 40,
            width: 40,
            borderRadius: 20,

            justifyContent: "center",
            alignItems: "center",
          }}
        > */}
        {/* <AntDesign
            onPress={() => 
            name={getICON()}
            size={29}
            color="white"
          /> */}
        <Image source={getICON()} style={{ width: 44, height: 44 }} />
        {/* </View> */}
      </LinearGradient>
    </View>
  );
};

export default CardComponent;
