import React from "react";
import styled from "styled-components/native";
import getScreenType from "../../../../openbrix-design-system/utils/getScreenType";

const layoutType = getScreenType();
let DrawerWrapper, DrawerIcon, DrawerMenu, SubMenuItem, MenuItem;
if (layoutType == "phone") {
  DrawerWrapper = styled.View`
    flex: 1;
    border-bottom-right-radius: 40px;
    border-top-right-radius: 40px;
  `;
  DrawerIcon = styled.View`
   align-items:center;
  justify-content:space-between;
  width:100%;
  padding-horizontal:32px;
   width:100%;
   flex-direction:row;
   padding-top:20px;
  `;
  DrawerMenu = styled.View`
    padding-left: 25px;
    padding-right: 25px;
    margin-top:25px;
  `;
  MenuItem = styled.Text`
    color: #ffffff;
    font-size: 18px;
    font-weight: 900;
    margin-bottom: 20px;
  `;
  SubMenuItem = styled.Text`
    color: #ffffff;
    font-size: 18px;
    font-weight: 900;
    margin-bottom: 20px;
    margin-left: 30px;
  `;
} else if (layoutType == "web") {
  DrawerWrapper = styled.View`
    flex: 1;
    border-bottom-right-radius: 40px;
    border-top-right-radius: 40px;
  `;
  DrawerIcon = styled.View`
    height: 107px;
    padding: 50px 60px;
    margin-bottom: 40px;
  `;
  DrawerMenu = styled.View`
    padding-left: 20px;
    padding-right: 20px;
  `;
  MenuItem = styled.Text`
    color: #ffffff;
    font-size: 18px;
    font-weight: 900;
    margin-bottom: 44px;
  `;
  SubMenuItem = styled.Text`
    color: #ffffff;
    font-size: 18px;
    font-weight: 900;
    margin-left: 30px;
  `;
} else {
  DrawerWrapper = styled.View`
    flex: 1;
    border-bottom-right-radius: 40px;
    border-top-right-radius: 40px;
  `;
  DrawerIcon = styled.View`
  align-items:center;
 justify-content:space-between;
 width:100%;
 padding-horizontal:32px;
  width:100%;
  flex-direction:row;
  padding-top:20px;
  padding-bottom:30px;

 `;
  DrawerMenu = styled.View`
    padding-left: 10px;
    padding-right: 10px;
    align-self:flex-end;
  `;
  MenuItem = styled.Text`
    color: #ffffff;
    font-size: 18px;
    font-weight: 900;
    margin-bottom: 44px;
  `;
  SubMenuItem = styled.Text`
    color: #ffffff;
    font-size: 18px;
    font-weight: 900;
    margin-bottom: 44px;
    margin-left: 30px;
  `;
}
export { DrawerWrapper, DrawerIcon, DrawerMenu, MenuItem, SubMenuItem };
