
import React, { useEffect, useState } from "react";
import { FlatList, ImageBackground, Text, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { getCcqStatus } from "../../redux/actions/preReferencing";
import AppBanner from "../appBanner/index";
import getPlatformType, { getImage } from "../../helpers/getPlatform";
import { navigateAction } from "../../helpers/navigationHelpers";
import { tenantActions } from "../openFlow/openFlowRedux/tenant.actions";
import BackGroundGenerator from "../LandingPages/common/BackGroundGenerator";
import { getProfile } from "../../redux/actions/auth";
import { useIsFocused } from "@react-navigation/native";
import ODSWelcomeTile from "../../../openbrix-design-system/ui/atoms/ODSTiles/WelcomeTile";
import ODSTenancyTile from "../../../openbrix-design-system/ui/atoms/ODSTiles/TenancyTile";
import ODSDashboardTiles from "../../../openbrix-design-system/ui/molecules/DashboardTiles";

const Dashboard = (props) => {

  const { navigation } = props;
  const dispatch = useDispatch();
  const [type, setType] = useState("web");
  const layout = getPlatformType();
  const [tiles, setTiles] = useState([]);
  const [flowId, setFlowId] = React.useState(null);
  const [preQualData, setPreQualData] = React.useState([]);
  const [flowIdInfo, setFlowIdInfo] = React.useState(null);
  const globalState = useSelector((state) => state?.tenant);
  const [filterButton, setFilterButton] = React.useState([]);
  const [isLoginTypeTds, setIsLoginTypeTds] = React.useState(false);

  const [hoverStartTime, setHoverStartTime] = useState(null);
  const [tdsRoute, setTdsRoute] = useState("TDS Search Deposit");
  const profile = useSelector((state) => state.authReducer?.profile);
  const userData = useSelector((state) => state.authReducer?.profile?.data?.appUser);
  const {
    affordability,
    fcc_pre_reference,
    id_verification,
    right_to_rent,
    tenant_info
  } = preQualData;
  const tdsLocationSelected = useSelector((state) => state.appReducer?.tdsLocationSelected);

  const focus = useIsFocused();
  useEffect(() => {
    fetchTiles();
  }, [focus]);
  const fetchTiles = () => {
    dispatch(tenantActions.getTilesData((data) => {
      if (data) {
        if (data[0].card == "OpenFlow" || data[0].card == "HomeBox") {
          setTiles([...data]);
        } else {
          setTiles([{}, ...data]);
        }
      }
    }));
  };
  useEffect(() => {
    dispatch(getProfile());
    dispatch(getCcqStatus(true));
    dispatch(tenantActions.getPreQualificationStatus());
    return () => {
      setHoverStartTime(0);
    };
  }, []);
  useEffect(() => {
    if (profile?.data?.isActiveOpenflow) {
  
      dispatch(tenantActions.getFlowInfo());
    }

  }, [profile?.data?.isActiveOpenflow]);
  useEffect(() => {
    console.log("profile?.data",profile?.data)
    if (profile?.data?.appUser?.registered_from == "tds"||profile?.data?.appUser?.registration_refrence =="tds") {
      setIsLoginTypeTds("tds");
    }else{
      setIsLoginTypeTds('')
    }
  }, [profile?.data?.appUser?.registered_from, profile?.data?.appUser?.registration_refrence]);

  useEffect(() => {
    setFlowId(globalState?.flowIdInfo?.openflow_id);
    console.log("globalState?.flowIdInfo", globalState?.flowIdInfo)
    setFlowIdInfo(globalState?.flowIdInfo);

    const ListArray = [
      {
        buttonStatus: "tenancy_status",
        routeKey: "TenantDetails",
        id: "tenantDetails",
        actionStatus: globalState?.flowIdInfo?.tenancy_status,
        button_text: globalState?.flowIdInfo?.tenancy_button_text,
        navigate: () => navigation.navigate("TenantDetails")
      },
      {
        buttonStatus: "doc_status",
        routeKey: "PropertyDocs",
        id: "landlord-PropertyDocument",
        actionStatus: globalState?.flowIdInfo?.doc_status,
        button_text: globalState?.flowIdInfo?.doc_button_text,
        navigate: () => navigation.navigate("PropertyDocs")
      },
      {
        buttonStatus: "rental_offer_status",
        routeKey: "RentalOffers",
        id: "rentalOffer",
        actionStatus: globalState?.flowIdInfo?.rental_offer_status,
        button_text: globalState?.flowIdInfo?.rental_offer_button_text,
        navigate: () => navigation.navigate("RentalOffers")
      },
      {
        buttonStatus: "holding_deposit_status",
        routeKey: "HoldingDeposit",
        id: "holdingDeposit",
        actionStatus: globalState?.flowIdInfo?.holding_deposit_status,
        button_text: globalState?.flowIdInfo?.holding_deposit_button_text,
        navigate: () => navigation.navigate("HoldingDeposit")
      },
      {
        buttonStatus: "verification_status",
        routeKey: "IdAndReference",
        id: "id-Referncing",
        actionStatus: globalState?.flowIdInfo?.verification_status,
        button_text: globalState?.flowIdInfo?.verification_button_text,
        navigate: () => navigation.navigate("IdAndReference")
      },
      {
        buttonStatus: "landlord_status",
        routeKey: "ReviewAST",
        id: "tenancyAgreement",
        actionStatus: globalState?.flowIdInfo?.landlord_status,
        button_text: globalState?.flowIdInfo?.landlord_button_text,
        navigate: () => navigation.navigate("ReviewAST")
      },
      {
        buttonStatus: "move_in_status",
        routeKey: "DepositInfo",
        id: "moveIn",
        actionStatus: globalState?.flowIdInfo?.move_in_status,
        button_text: globalState?.flowIdInfo?.move_in_button_text,
        navigate: () => navigation.navigate("DepositInfo")
      },
      {
        buttonStatus: "property_status",
        routeKey: "DepositProtection",
        id: "depositProtect-HomeSetUp",
        actionStatus: globalState?.flowIdInfo?.property_status,
        button_text: globalState?.flowIdInfo?.verification_button_text,
        navigate: () => navigation.navigate("DepositProtection")
      },
    ];

    let filterList = ListArray.filter((data, indes) => data?.actionStatus === 5);
    setFilterButton(filterList.reverse());

    if (globalState?.preQualData?.preQualStatus) {
      setPreQualData(globalState?.preQualData?.preQualStatus);
    }
  }, [globalState]);

  useEffect(() => {
    if (profile && profile?.data) {
      if (profile?.data?.tdkToken?.user_id) {
        setTdsRoute("Custodial Tenancies");
      } else {
        setTdsRoute(tdsLocationSelected ? tdsLocationSelected : "tdsLocation");
      }
    } else {
      setTdsRoute(tdsLocationSelected ? tdsLocationSelected : "tdsLocation");
    }
  }, [profile]);

  const getIdVerificationStatus = () => {
    switch (id_verification?.idverification) {
      case 2:
        return 'Completed';
        break;
      case 9:
        return 'Failed';
        break;
      case 1:
        return 'Pending';
        break;
      default:
        return null;
        break;
    }
  };

  const renderIDVerStatus = () => {
    if (id_verification?.idverification == 2) {
      return 2
    } else if (id_verification?.idverification == 9) {
      return 0
    } else if (id_verification?.idverification == 1 || id_verification?.payment_required == false) {
      return 1
    } else {
      return 0
    }
  };

  return (
    <BackGroundGenerator isTLYF props={props}>
      <ImageBackground
        source={
          getImage("dashboard.png")
        }
        style={{
          width: '100%',
          height: '270px',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
        resizeMode="cover"
      ></ImageBackground>
      <View
        style={{ position: 'absolute', top: layout == 'phone' ? 150 : 120, alignSelf: 'center', zIndex: 1000 }}
      >
        <ODSWelcomeTile
          title={profile && `Welcome ${profile?.data.first_name}!`}
          subtitle={'Check out your latest updates below!'}
          onPress={() => navigateAction(navigation, isLoginTypeTds == "tds" ? tdsRoute : "My Account")}
          registerForm={isLoginTypeTds}
        />
      </View>
      <View style={{ height: layout == 'phone' ? 130 : 130 }} />

      {tiles.length > 0 && (
        <ODSDashboardTiles
          navigationRoutes={[
            {
              id: 'MyDocuments',
              route: 'Document Share Center',
            },
            {
              id: 'OpenFlow',
              route: 'My Tenancy',
            },
            {
              id: 'CreditBuilder',
              route: 'Credit Builder',
              status: userData?.creditladder_code == "" ? null : userData?.creditladder_code != "expired" ? "Completed" : "Expired"
            },
            {
              id: 'TenancyDeposit',
              route: tdsRoute,
            },
            {
              id: 'PreQualification',
              route: 'PreQualification',
            },
            {
              id: 'IDVerification',
              route: 'PreQualificationIdAndVerification',
              status: id_verification?.idverification && getIdVerificationStatus()
            },
            {
              id: 'Rewards',
              route: 'Rewards',
            },
            {
              id: 'Insurance',
              route: 'TenantInsurance',
            },
          ]}
          rentReadyData={[{
            title: "Affordability",
            state: affordability?.affordability_amount ? 2 : 0,
            onPress: () => navigateAction(navigation, "PreQualificationAffordability")

          },
          {
            title: "Tenant Info",
            state: (tenant_info?.occupation || tenant_info?.marital_status) ? 2 : 0,
            onPress: () => navigateAction(navigation, "PreQualificationTenantInfo")
          },
          {
            title: "Right to Rent",
            state: right_to_rent?.right_to_rent ? 2 : 0,
            onPress: () => navigateAction(navigation, "PreQualificationRightToRent")
          },
          {
            title: "ID Verification",
            state: renderIDVerStatus(),
            onPress: () => navigateAction(navigation, "PreQualificationIdAndVerification")
          }, {
            title: "Rent Ready Reference",
            state: fcc_pre_reference?.status == 'completed' ? 2 : (fcc_pre_reference?.status == 'in_progress' || fcc_pre_reference?.payment_required == false) ? 1 : 0,
            onPress: () => navigateAction(navigation, "PreQualificationPreReference")
          }]}
          navigation={navigation}
          tiles={tiles}
          isLoginTypeTds={isLoginTypeTds}
          renderTenancyTile={<ODSTenancyTile
            movedIn={flowIdInfo?.percentage_completed == 100 ? true : false}
            actionButtons={filterButton}
            percentage={flowIdInfo?.percentage_completed || 0}
            actionRequired={flowIdInfo?.percentage_completed > 0 && flowIdInfo?.percentage_completed <= 99 && filterButton && true}
            onPress={() => navigateAction(navigation, "My Tenancy")}
          />}
        />
      )}
      <AppBanner />
    </BackGroundGenerator>
  );
};

export default Dashboard;
