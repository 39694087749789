
const webPath= "./web/"
const tabPath= "./tab/"
const mobilePath= "./mobile/"
export const backgroundSearchArr = [
    {
      webUri: require(webPath+'Ellipse2.png'),
      tabUri: require(tabPath+'Ellipse2.png'),
      mobileUri: require(mobilePath+'Ellipse2.png'),
      webStyle: {
        width: 116,
        position: 'absolute',
        left: 0,
      },
      tabStyle: {
        width: 170,
        position: 'absolute',
        left: 0,
      },
      mobileStyle: {
        width: 100,
        position: 'absolute',
        left: 0,
      },
      blurRadius:true,
    },
    {
      webUri: require(webPath+'Ellipse.png'),
      tabUri: require(tabPath+'Ellipse.png'),
      mobileUri: require(mobilePath+'Ellipse.png'),
      webStyle: {
        width: 462.95,
        alignSelf: 'flex-end',
        position: 'absolute',
        right: 0,
      },
      tabStyle: {
        width: 302,
        alignSelf: 'flex-end',
        position: 'absolute',
        right: 0,
      },
      mobileStyle: {
        width: 200,
        alignSelf: 'flex-end',
        position: 'absolute',
        right: 0,
      },
      blurRadius:true,
    },
    {
      webUri: require(webPath+'Home.svg'),
      tabUri: require(tabPath+'home.svg'),
      mobileUri: require(mobilePath+'home.svg'),
      webStyle: {
        marginTop: 150,
      },
      tabStyle: {
        marginTop: 150,
      },
      mobileStyle: {
        marginTop: 227,
      },
    },
    {
      webUri: require(webPath+'Ellipse4.svg'),
      tabUri: require(tabPath+'Ellipse3.svg'),
      mobileUri: require(mobilePath+'Ellipse3.png'),
      webStyle: {
        top: 1500,
        width: 292,
        position: 'absolute',
        right: 0,
      },
      tabStyle: {
        top: 1202,
        width: 170,
        position: 'absolute',
        left: 0,
      },
      mobileStyle: {
        top: 746,
        width: 100,
        position: 'absolute',
        left: 0,
      },
      blurRadius:true,
    },
    {
      webUri: require(webPath+'Ellipse5.svg'),
      tabUri: require(tabPath+'Ellipse4.svg'),
      mobileUri: require(mobilePath+'Ellipse4.png'),
      webStyle: {
        top: 1200,
        width: 292,
        position: 'absolute',
        left: 0,
      },
      tabStyle: {
        top: 2000,
        width: 170,
        position: 'absolute',
        right: 0,
      },
      mobileStyle: {
        top: 1301,
        width: 108,
        position: 'absolute',
        left: 0,
      },
      blurRadius: 2,
      blurRadius:true,
    },
    {
      webUri: require(webPath+'Ellipse6.svg'),
      tabUri: require(tabPath+'Ellipse5.svg'),
      mobileUri: require(mobilePath+'Ellipse6.png'),
      webStyle: {
        top: 1800,
        width: 691,
        position: 'absolute',
        right: 0,
      },
      tabStyle: {
        top: 2393,
        width: 170,
        position: 'absolute',
        left: 0,
      },
      mobileStyle: {
        top: 1982,
        width: 108,
        position: 'absolute',
        right: 0,
      },
      blurRadius:true,
    },
    // {
    //   webUri: require(webPath+'Ellipse7.svg'),
    //   tabUri: null,
    //   mobileUri:require(mobilePath+'Ellipse5.png'),
    //   webStyle: {
    //     top: 2500,
    //     width: 243,
    //     position: 'absolute',
    //     left: 10,
    //   },
    //   tabStyle: null,
    //   mobileStyle: {
    //     top: 3150,
    //     position: 'absolute',
      
    //   },
    //   blurRadius:true,
    // },
    // {
    //   webUri: require(webPath+'HomeLeft.svg'),
    //   tabUri: require(tabPath+'HomeLeft.svg'),
    //   mobileUri: require(mobilePath+'HomeLeft.png'),
    //   webStyle: {
    //     top: 2500,
    //     position: 'absolute',
    //   },
    //   tabStyle: {
    //     top: 3525,
    //     position: 'absolute',
    //   },
    //   mobileStyle: {
    //     top: 3203,
    //     position: 'absolute',
    //   },
    // },
  ];