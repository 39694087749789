import React from "react";
import Header from "../../commonComponents/headerComponent/headerComponent";
import Footer from "../../commonComponents/footerComponent/customFooter";
import {
  View,
  Text,
  Dimensions,
  SafeAreaView,
  Image,
  TouchableOpacity,
  Platform,
  ScrollView,
  FlatList,
  TextInput,
  StyleSheet,
} from "react-native";
import { LinearGradient } from "expo-linear-gradient";

import { MainSection, Section1,Section2 } from "./CreditHistoryStyle";
import CardComponent from "./Components/cardComponent";
import { ContainerScrollView } from "../../helpers/appStyles";
import SkeltonComponents from "./Components/skeltonComponents";

const CreditHistory = (props) => {
  return (
    <View style={{ backgroundColor: "#FAFAFA", height: "100%" }}>
      <Header
        showLogoMob
        showLogoWeb
        showLogoTab
        rightChildLabelTab
        rightChildLabelWeb
        rightChildLabelMob
        showDrawerMob
        showDrawerTab
        showNotification
        isTLYF
        {...props}
      />
      <ContainerScrollView>
        <MainSection>
          <Section1>
          <SkeltonComponents
            type={"button"}
            title={"Credit History Status"}
            data={[
              {
                title: "Registration",
                button: "Completed",
                buttonColor: "#00B401",
                onClick: () => {
                  alert("start");
                },
               
              },
              {
                title: "Bank Connection",
                button: "Start",
                buttonColor: "#EB5936",
                onClick: () => {
                  alert("start");
                },
              },
            ]}
          />
          </Section1>
          <Section2>
            <SkeltonComponents
              type={"summary"}
              title={"Credit History Information"}
              data={[
                {
                  value:`In all foreseeable circumstances, the exceptional results of the hardware maintenance has the potential to improve or transform The Type of Potential Style" (Palmer Broughton in The Book of the Continuing Support) 
                  It is obvious, that with help of the comprehensive methods provides rich insights into any prominent landmarks.
                  `,
                  onClick: () => {
                    
                  },
                }
              ]}
            />
          </Section2>
        </MainSection>

        <Footer
        isTLYF
        />
      </ContainerScrollView>
    </View>
  );
};
const styles = StyleSheet.create({});
export default CreditHistory;
