import React, { useState } from 'react';
import { Dimensions, StyleSheet, View, Text, TouchableOpacity } from 'react-native';
import getPlatformType, { getImage } from '../../helpers/getPlatform';
import { ChildrenView } from './styles';
const layoutType = getPlatformType();
import { ImageBackground } from 'react-native';
const WIN_HEIGHT = Dimensions.get('window').height;
import PreQualification from './preQualificationNew';
import BackGroundGenerator from '../LandingPages/common/BackGroundGenerator';
import ODSButton from '../../../openbrix-design-system/ui/atoms/ODSButton';
import { replaceAction } from '../../helpers/navigationHelpers';
import { getTokens } from '../../../openbrix-design-system/design-tokens/color-tokens';

const PreQualificationAffordability = (props) => {
  const themeObj = getTokens('light').colors;
  return (
    <BackGroundGenerator props={props} isTLYF>
      <View style={{ minHeight: WIN_HEIGHT - 300 }}>
        <View style={{ width: '100%' }}>
          <ImageBackground
            source={getImage("aff.png")}
            style={{ width: '100%', height: 300 }}
            resizeMode={'cover'}
          >
            <ODSButton
              rIcon={'TB-Arrow-Left'}
              restStyle={{ marginTop: 20, marginLeft: 20,width: 200 }}
              type="secondary"
              themeColor={themeObj.main.pink}
              disabled={false}
              onPress={() => replaceAction(props?.navigation, 'PreQualification')}
            >
              Rent Ready
            </ODSButton>
          </ImageBackground>
          <ChildrenView>
            <PreQualification />
          </ChildrenView>
        </View>
      </View>
    </BackGroundGenerator>
  );
};
const styles = StyleSheet.create({});
export default PreQualificationAffordability;
