import React from "react";
import styled from "styled-components/native";
import getPlatformType from "../../helpers/getPlatform";

const layoutType = getPlatformType();

let MainSection, Title, Section1, Section2,DocSection;

if (layoutType == "phone") {
  MainSection = styled.View`
  margin: 28px 4%;
  `;
  Section1 = styled.View`
  margin: 0px 0px 20px 0px
  `;
  DocSection = styled.View`
  margin: 28px 4%;
  `;
  Section2 = styled.View``;
} else if (layoutType == "web") {
  MainSection = styled.View`
    margin: 54px 9% 0px 9%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    min-height: 20vh;
  `;
  Section1 = styled.View`
    margin: 0px 130px 0px 0px;
    width:343px
  `;
  DocSection = styled.View`
  margin: 30px 28% 30px 27%;
align-self: center;
width:782px
  `;
  Section2 = styled.View`
  width:343px
  `;
} else {
  MainSection = styled.View`
  margin: 76px 20px;
  flex-direction: row;
  justify-content: center;
  `;
  Section1 = styled.View`
  margin: 0px 10% 0px 0px;
  width:310px
  `;
  Section2 = styled.View`
  width:310px
  `;
  DocSection = styled.View`
  margin: 30px 20px;
  `;
}

export { MainSection, Title, Section1, Section2,DocSection };
