import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  headingText: {
    fontFamily: 'Lato',
    fontWeight: "700",
    fontSize: 39,
  },
  heading1: {
    fontFamily: 'Lato',
    fontWeight: "700",
    fontSize: 32,
  },
  heading2: {
    fontFamily: 'Lato',
    fontWeight: "700",
    fontSize: 24,
  },
  heading3: {
    fontFamily: 'Lato',
    fontWeight: "400",
    fontSize: 19,
  },
  TextCenter:{
      textAlign:"center"
  },
  container: {
    marginTop: 241,
  },

  headingWrapper: {
    alignSelf: 'center',
    alignItems:'center'
  },
  headingTextWrapper: {
    textAlign: 'center',
  },
  visionWrapper:{
      marginTop:542,
      alignItems:'center'
  },
  visionSubTextWrapper:{
    marginTop: 58,
    alignItems: 'center',
    justifyContent: 'center',
  },
  saperaterWrapper: {
    marginVertical: 65,
  },
  saperator: {
    height: 1,
    backgroundColor: '#6d6d6d',
  },
  valuesWrapper:{
    marginBottom: 106,
    marginLeft: 69
  },
  sectionWrapper: {
    marginBottom: 52,
  },
  section1: {
    marginBottom: 28,
  },
  section2: {
    marginBottom: 36,
  },
  manifastoWrapper:{
    marginTop: 87,
    marginBottom: 75,
    alignItems:"center"
},
manifestoText:{
    textAlign:"center"
},
cardWrapper: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems:'center',
    marginBottom: 126,
  },
  cardSection:{
    marginTop: 77
 },
 formWrapper: {
  paddingHorizontal: 30,
  paddingVertical: 30,
  },
  horizontal: {
    marginHorizontal: 25,
    backgroundColor:"white"
  },
});
