import React from "react";
import { TouchableOpacity, Text, ActivityIndicator, View } from "react-native";
import { TextBox, RightText, Title, OuterView } from "./checkbox.style";
import { Ionicons } from "@expo/vector-icons";
import { COLORS } from "../../constants/colorPallete";
export default class CheckBox extends React.Component {
  render() {
    const {
      checked,
      label,
      onPress,
      fontSize,
      height,
      fontWeight,
      id
    } = this.props;
   
    
    return (
      <OuterView height={height}>
        <View
         
          style={{
            height: "100%",
            width: "100%",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Ionicons
            id ={id}
            onPress={onPress}
            name={!checked ? "ios-square-outline" : "ios-checkbox-outline"}
            size={!checked ? 28 : 25}
            color={checked ? COLORS.orange : "black"}
          />
          <Text
            style={{
              paddingLeft: "2%",
              alignSelf: "center",
              fontSize: fontSize ? fontSize : 11,
              fontWeight: fontWeight ? fontWeight : "600",
            }}
          >
            {label}
          </Text>
        </View>
      </OuterView>
    );
  }
}
