import React, { useState } from "react";
import {
  View,
  Text,
  Dimensions,
  SafeAreaView,
  Image,
  TouchableOpacity,
  Platform,
  ScrollView,
  FlatList,
} from "react-native";
import Header from "../../commonComponents/headerComponent/headerComponent";
import getPlatformType from "../../helpers/getPlatform";
import WideButton from "../../commonComponents/wideButton";
import { connect } from "react-redux";
import { MaterialIcons } from "@expo/vector-icons";
import { bindActionCreators } from "redux";
import { showToaster } from "../../redux/actions/toast";
import { removeTenantFromProperty } from "../../redux/actions/property";
import idx from "idx";
import { sendEmail, callIfAvailable } from "../../helpers/callEmailHelper";
import CustomPopUp from "./../../commonComponents/customPopUp/customPopUp";

const layoutType = getPlatformType();
import { Icon } from "react-native-elements";
import NoDataNotification from "../NoDataFound/noData";
import { COLORS } from "../../constants/colorPallete";

const MyProperty = (props) => {
  const profileData = idx(props, (_) => _.profileData)
    ? idx(props, (_) => _.profileData)
    : idx(props, (_) => _.route.params.profileData);
    
  const [popUpIsVisible, setPopupVisible] = React.useState(false);

  const callAgent = async (x) => {
    let status = false;
    if (x) {
      status = await callIfAvailable(x);
      if (!status) {
        props.showToaster("error", "Sorry, agent's contact unavailable");
      }
    } else if (!status) {
      props.showToaster("error", "Sorry, agent's contact unavailable");
    }
  };

  const emailAgent = async () => {
    
    let objectdetails = {
      agentDetails: profileData?.data,
      addressLine:profileData.data?.tenantDetails?.property?.address_line1 +" "+ profileData?.data?.tenantDetails?.property?.postcode,
      branchName: profileData?.data?.agentDetails?.branch?.name,
      username:profileData?.data?.first_name + " "+profileData?.data?.last_name
    };

    if(!profileData?.data?.agentDetails?.branch?.name) {
      let noAgentAssociatedObjData = {
        branch: {
          email: 'Info@openbrix.co.uk',
          name: 'OBX',
        },
        property_name: '...'
      };

      let status = await sendEmail(noAgentAssociatedObjData, false, objectdetails);
      if (!status) {
        props.showToaster("error", "Sorry, agent email data not available");
      }
    }else{
      let status = await sendEmail(profileData?.data?.agentDetails, true,objectdetails);
      if (!status) {
        props.showToaster("error", "Sorry, agent email data not available");
      }
    }
  };

  const removeTenant = () => {
    const data = idx(profileData, (_) => _.data.tenantDetails.property);
    props.removeTenantFromProperty(data.oprid_id, props.navigation.navigate);
  };


  return (
   
    <View style={{ flex: 1 }}>
      {layoutType === "phone" ? (
        <Header showBackButtonMob showLogoMob {...props} />
      ) : null}
      {profileData.data && (
        <View
          style={
            layoutType === "phone"
              ? {
                  paddingLeft: 10,
                  paddingRight: 10,
                }
              : null
          }
        >
          <View
            style={{
              height: 70,
              width: "100%",
              backgroundColor: "#FFFFFF",
              shadowColor: "grey",
              shadowOffset: {
                width: 2,
                height: 2,
              },
              shadowOpacity: 0.09,
              shadowRadius: 6.68,
              elevation: 11,
              borderTopRightRadius: 10,
              borderBottomLeftRadius: 10,
              justifyContent: "space-between",
              marginTop: 15,
              alignItems: "center",
              marginBottom: 25,
              flexDirection: "row",
            }}
          >
            <View style={{ height: "100%", width: "30%" }}>
              <Image
                source={
                  idx(
                    profileData,
                    (_) =>
                      _.data.tenantDetails.property.property_images.length > 0
                  ) &&
                  profileData.data.tenantDetails.property.property_images
                    .length > 0
                    ? {
                        uri: profileData.data.tenantDetails.property.property_images[0].startsWith(
                          "http"
                        )
                          ? profileData.data.tenantDetails.property.property_images[0].startsWith(
                              "https://tmp"
                            )
                            ? profileData.data.tenantDetails.property.property_images[0].replace(
                                "https://tmp",
                                "https://dev"
                              )
                            : profileData.data.tenantDetails.property
                                .property_images[0]
                          : profileData.data.tenantDetails.property
                              .property_images[0],
                      }
                    : {
                        uri:
                          "https://www.jagranimages.com/images/newimg/09122019/09_12_2019-house_pexels_19829618.jpg",
                      }
                }
                style={{
                  height: "100%",
                  width: "100%",
                  borderBottomLeftRadius: 10,
                }}
              />
            </View>
            <View
              style={{
                height: "100%",
                width: "60%",
                paddingHorizontal: 10,
                justifyContent: "center",
              }}
            >
              {profileData.data?.tenantDetails?.property_id ? (
                <>
                  <Text style={{ fontSize: 16, color: COLORS.orange, fontWeight: "bold" }}>
                    {idx(
                      profileData,
                      (_) => _.data.tenantDetails.property.address_line1
                    )
                      ? profileData.data.tenantDetails.property.address_line1 + ", "
                      : ""}
                    {idx(profileData, (_) => _.data.tenantDetails.property.postcode)
                      ? profileData.data.tenantDetails.property.postcode
                      : ""}
                  </Text><Text style={{ color: "#b0b0b0" }}>
                  {idx(
                    profileData,
                    (_) => _.data.tenantDetails.property.property_name
                  )
                    ? profileData.data.tenantDetails.property.property_name
                    : ""}
                </Text></>
               ) : (
                <Text
                  style={{ fontSize: 16, color: COLORS.orange, fontWeight: "bold" }}>N/A</Text>
              )}
            </View>
            {profileData.data?.tenantDetails?.property_id && (
              <View style={{ height: "100%", width: "10%", marginTop: 43 }}>
                <MaterialIcons
                  name="delete"
                  size={24}
                  color={COLORS.orange}
                  style={{ alignItems: "flex-end" }}
                  onPress={() => setPopupVisible(true)}
                />
              </View>
            )}
          </View>
          <Text style={{ fontSize: 20, color: "black", fontWeight: "600" }}>
            Rental Information
          </Text>
          <View
            style={{
              height: 200,
              width: "100%",
              backgroundColor: "#FFFFFF",
              shadowColor: "grey",
              shadowOffset: {
                width: 2,
                height: 2,
              },
              shadowOpacity: 0.09,
              shadowRadius: 6.68,
              elevation: 11,
              borderTopRightRadius: 10,
              borderBottomLeftRadius: 10,
              marginTop: 15,
              marginBottom: 15,
            }}
          >
            <View
              style={{
                flex: 0.25,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                paddingHorizontal: 15,
                borderBottomColor: "#f1f1f1",
                borderBottomWidth: 1,
              }}
            >
              <Text>Number of residents</Text>
              <Text style={{ color: COLORS.orange, fontWeight: "600" }}>
                {profileData?.data?.tenantDetails?.opbrixIds.length ? (
                  profileData?.data?.tenantDetails?.opbrixIds.length + ' residents'
                ) : 'N/A'}
              </Text>
            </View>
            <View
              style={{
                flex: 0.25,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                paddingHorizontal: 15,
                borderBottomColor: "#f1f1f1",
                borderBottomWidth: 1,
              }}
            >
              <Text>Rental Type</Text>
              <Text style={{ color: COLORS.orange, fontWeight: "600" }}>
              {profileData?.data?.tenantDetails?.opbrixIds.length ? (
                  profileData?.data?.tenantDetails?.property.duration + ' months'
                ) : 'N/A'}
              </Text>
            </View>
            <View
              style={{
                flex: 0.25,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                paddingHorizontal: 15,
                borderBottomColor: "#f1f1f1",
                borderBottomWidth: 1,
              }}
            >
              <Text>Rental amount</Text>
              <Text style={{ color: COLORS.orange, fontWeight: "600" }}>
                {profileData?.data?.tenantDetails?.property.AgreedRentalAmount && '£'}
                {" "}
                {profileData?.data?.tenantDetails?.property.AgreedRentalAmount?parseFloat(profileData?.data?.tenantDetails?.property.AgreedRentalAmount).toFixed(2):"N/A"}
              </Text>
            </View>
            <View
              style={{
                flex: 0.25,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                paddingHorizontal: 15,
                borderBottomColor: "#f1f1f1",
                borderBottomWidth: 1,
              }}
            >
              <Text>Agent</Text>
              <Text style={{ color: COLORS.orange, fontWeight: "600" }}>
                {idx(
                  profileData,
                  (_) => _.data.agentDetails.branch.company.name
                )
                  ? profileData.data.agentDetails.branch.company.name
                  : "OBX"}
              </Text>
            </View>
          </View>

          <View style={{ flexDirection: "row", width: "100%", marginTop: 15 }}>
            <View style={{ flex: 0.5 }}>
              <WideButton
                childText={"Call Agent"}
                gradiant
                onPress={() => {
                  callAgent(
                    idx(
                      profileData,
                      (_) => _.data.agentDetails.branch.contact_number
                    )
                      ? profileData.data.agentDetails.branch.contact_number
                      : "0203 740 4721"
                  );
                }}
              />
            </View>
            <View style={{ flex: 0.5 }}>
              <WideButton
                 gradiant
                childText={"Email Agent"}
                width="100%"
                onPress={() => emailAgent()}
              />
            </View>
          </View>
        </View>
       )}
      <View
        style={{
          width: "100%",
          marginTop: 25,
          marginBottom: 15,
        }}
      >
      </View>
      <CustomPopUp
        loading={popUpIsVisible}
        primaryButtonText={"Yes"}
        secondaryButtonText={"Cancel"}
        primaryText={"Remove?"}
        secondaryText={
          "Are you sure you wish to remove yourself from the property? You will no longer be able to create payments history connected to this property"
        }
        onPrimaryButtonPress={() => {
          setPopupVisible(false);
          removeTenant();
        }}
        onSecondaryButtonPress={() => {
          //this.setState({ popUpIsVisible: false });
          setPopupVisible(false);
        }}
      />
    </View>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showToaster,
      removeTenantFromProperty,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(MyProperty);
