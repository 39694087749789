import React from "react";
import styled from "styled-components/native";
import getPlatformType from "../../helpers/getPlatform";
import { ImageBackground } from "react-native";

const layoutType = getPlatformType();
let Title;
let Container;
let Background;
let TabWrapper;
let FormContainer;
let ListSection;
let TitleWrapper;
let PropertyCardWrapper;
let CardWrapper;
let TlyfeCard;
let DropDownWrapper,
BannerImage1,
  DropDownSectionWrapper,
  IntroWrapper,
  TitleTextWrapper,
  ReviewWrapper,
  ButtomSection,
  MainContent,
  CookieText,
  TlyfeLogo,
  ReviewItem,
  BannerImage2,
  BannerImage3,
  BannerText2,
  BannerText1
  ;

CookieText = styled.Text`
  color: #fff;
  font-size: 12px;
  max-width: 50%;
  text-align: center;
`;
if (layoutType == "phone") {
  BannerImage1 = styled.Image`
  height: 57px;
  width: 127px;
`;

BannerImage2 = styled.Image`
height: 57px;
width: 104px;
margin-vertical: 20px
`;
BannerImage3 = styled.Image`
height: 57px;
width: 176px;
`;
BannerText1 = styled.Text`
color: white;
text-align: center;
font-size: 16px;
margin-top:10px
`;
BannerText2 = styled.Text`
color: white;
text-align: center;
font-size: 18px;
margin-top:10px
`;

  CookieText = styled.Text`
    max-width: 90%;
    color: #fff;
    text-align: center;
    font-size: 16px;
    padding: 0px 10px;
  `;
  Container = styled.View`
    background-color: #fafafa;
  `;
  
  FormContainer = styled.View`
    padding: 8px 16px;
    width: 100%;
  `;
  TlyfeLogo = styled.Image`
  width:120;
  height:100;
  resize-mode:contain;
`;
  TitleWrapper = styled.Text`
    color: #000000;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
    margin: 21px 0px 0px 0px;
  `;
  ListSection = styled.View`
    position: absolute;
    top: 10%;
    width: 100%;
    height: 100;
  `;
  Title = styled.Text`
    color: #000;
    text-align: center;
    font-size: 16px;
    margin-top: 15px;
  `;
  Background = styled(ImageBackground)`
    width: 100%;
    height:522px
  `;
  TabWrapper = styled.View`
    width: 100%;
  `;
  CardWrapper = styled.View`
    width: 100%;
  `;
  PropertyCardWrapper = styled.View`
    height: 226px;
    margin: 0px 0px 0px 16px;
  `;
  TitleTextWrapper = styled.Text`
    margin: 30px 0px 16px 16px;
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
  `;
  IntroWrapper = styled.View`
    background-color: #ffffff;
    shadow-color: #000;
  `;
  ReviewWrapper = styled.View`
    padding: 0 16px 16px 16px;
  `;
  ReviewItem = styled.View`
    width: 300px;
    padding: 16px;
    margin: 8px 8px 8px 0;
    margin-left: 16px;
  `;
  MainContent = styled.View`
    width: 100%;
    padding: 0;
  `;
  TlyfeCard = styled.View`
  background-color: #fff;
  width:85%;
  height:210px;
  margin-bottom:200px;
  margin-right:50px;
  border-top-right-radius:12;
  border-bottom-left-radius:12;
  box-shadow: 0 1px 1px  rgba(0, 0, 0, 0.2);
`;
} else if (layoutType == "web") {
  Container = styled.View`
    background-color: #fafafa;
  `;
  BannerImage1 = styled.Image`
  height: 137px;
  width: 297px;
`;
BannerImage2 = styled.Image`
height: 137px;
width: 104px;
padding-horizontal: 200px
`;
BannerImage3 = styled.Image`
height: 137px;
width: 493px;
`;
BannerText1 = styled.Text`
color: white;
text-align: center;
font-size: 54px;
margin-top:40px
`;
BannerText2 = styled.Text`
color: white;
text-align: center;
font-size: 58px;
margin-top:10px
`;
  TlyfeLogo = styled.Image`
  width:120;
  height:100;
  resize-mode:contain;
`;
  TlyfeCard = styled.View`
  background-color: #fff;
  width:85%;
  height:210px;
  margin-bottom:200px;
  margin-right:50px;
  border-top-right-radius:12;
  border-bottom-left-radius:12;
  box-shadow: 0 1px 1px  rgba(0, 0, 0, 0.2);
`;

  Title = styled.Text`
    color: #000;
    text-align: center;
    font-size: 16px;
    margin-top: 15px;
  `;
  Background = styled(ImageBackground)`
    height: 522px;
    width: "100%";
  `;
  TabWrapper = styled.View`
    height: 100%;
    width: 459px;
    margin-left: 95px;
    padding-bottom:20;
  `;
  CardWrapper = styled.View`
    height: 100%;
    width: 459px;
    margin-left: 95px;
    padding-bottom:20;
  `;
  FormContainer = styled.View`
    margin: 0px 16px;
  `;
  TitleWrapper = styled.Text`
    color: #000000;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
    margin: 10px 0px 0px 0px;
  `;
  PropertyCardWrapper = styled.View`
    height: auto;
  `;
  TitleTextWrapper = styled.Text`
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
    margin: 48px 0 24px;
  `;
  IntroWrapper = styled.View`
    background-color: #ffffff;
    shadow-color: #000;
    padding-bottom:25px;
  `;
  ReviewWrapper = styled.View`
    margin-bottom: 66px;
    flex-direction: row;
    justify-content: space-between;
  `;
  ReviewItem = styled.View`
    width: 280px;
    padding: 24px;
    margin: 10px;
  `;
  MainContent = styled.View`
    width: 100%;
    padding: 0 150px;
    justify-content:space-around;
  `;
} else {
  BannerText1 = styled.Text`
color: white;
text-align: center;
font-size: 26px;
margin-top:10px
`;
BannerText2 = styled.Text`
color: white;
text-align: center;
font-size: 28px;
margin-top:10px
`;
  BannerImage1 = styled.Image`
  height: 137px;
  width: 297px;
`;
BannerImage2 = styled.Image`
height: 137px;
width: 104px;
`;
BannerImage3 = styled.Image`
height: 137px;
width: 493px;
`;
  Container = styled.View`
    background-color: #fafafa;
  `;
  TlyfeLogo = styled.Image`
  width:90;
  height:80;
  resize-mode:contain;
`;
  TlyfeCard = styled.View`
  background-color: #fff;
  width:100%;
  height:150px;
  margin-bottom:200px;
  // margin-right:50px;
  border-top-right-radius:12;
  border-bottom-left-radius:12;
  box-shadow: 0 1px 1px  rgba(0, 0, 0, 0.2);
`;
  Title = styled.Text`
    color: #000;
    text-align: center;
    font-size: 16px;
  `;
  Background = styled(ImageBackground)`
    width: "100%";
    height:522px
  `;
  TabWrapper = styled.View`
    height: 100%;
    width: 384px;
    padding-top: 20px;
  `;
  CardWrapper = styled.View`
    height: 100%;
    width: 300px;
    padding-top: 20px;
  `;
  FormContainer = styled.View`
    margin: 0px 16px;
  `;
  TitleWrapper = styled.Text`
    color: #000000;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
    margin: 21px 0px 0px 0px;
  `;
  PropertyCardWrapper = styled.View`
    height: 226px;
    margin: 0px 0px 0px 40px;
  `;
  TitleTextWrapper = styled.Text`
    margin: 48px 0px 16px 40px;
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
  `;
  IntroWrapper = styled.View`
    background-color: #ffffff;
    shadow-color: #000;
  `;

  ReviewWrapper = styled.View`
    margin-bottom: 66px;
    flex-direction: row;
    justify-content: space-between;
    height: 153px;
    margin: 0px 40px 40px 40px;
  `;
  ReviewItem = styled.View`
    width: 230px;
    padding: 24px;
    margin: 10px;
    margin-left: 16px;
  `;
  MainContent = styled.View`
    width: 100%;
    justify-content:space-around;
  `;
}
DropDownWrapper = styled.View`
  flex-direction: row;
`;
DropDownSectionWrapper = styled.View`
  flex: 0.5;
`;

ButtomSection = styled.TouchableOpacity`
  margin: 10px 0px 10px;
  justify-content: flex-end;
  align-items: flex-end;
`;

export {
  Container,
  Title,
  Background,
  TabWrapper,
  FormContainer,
  ListSection,
  TitleWrapper,
  DropDownWrapper,
  DropDownSectionWrapper,
  ButtomSection,
  IntroWrapper,
  PropertyCardWrapper,
  ReviewWrapper,
  TitleTextWrapper,
  MainContent,
  ReviewItem,
  CookieText,
  TlyfeCard,
  CardWrapper,
  TlyfeLogo,
  BannerImage1,
  BannerImage2,
  BannerImage3,
  BannerText2,
  BannerText1
};
