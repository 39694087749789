import React from 'react';
import { Text, View } from 'react-native';
import { ODSIcon } from '../atoms/ODSIcon';
import { StyleSheet } from 'react-native';
import ODSButton from '../atoms/ODSButton';
import { getTokens } from '../../design-tokens/color-tokens';
const iconData = require('../atoms/ODSIcon/selection.json');

export const Button = (props) => {
  console.log('props', props);
  const themeObj = getTokens('light').colors;
  return (
    <>
      <Text style={{ textAlign: 'center', marginVertical: 10, fontSize: 28, fontWeight: 'bold' }}>
        Button
      </Text>
      <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
        <View style={styles.container}>
          <ODSButton
            rIcon={'TB-Arrow-Left'}
            lIcon={'TB-Arrow-Right'}
            restStyle={{ marginBottom: 10 }}
            type="primary"
            disabled={false}
            onPress={() => console.log('Primary Button Pressed')}
          >
            Primary Button
          </ODSButton>

          <ODSButton
            rIcon={'TB-Arrow-Left'}
            lIcon={'TB-Arrow-Right'}
            restStyle={{ marginBottom: 10 }}
            type="secondary"
            disabled={false}
            onPress={() => console.log('Secondary Button Pressed')}
          >
            Secondary Button
          </ODSButton>

          <ODSButton
            // rIcon={'TB-Arrow-Left'}
            lIcon={'TB-Arrow-Right'}
            restStyle={{ marginBottom: 10 }}
            type="disabled"
            disabled
            onPress={() => console.log('Disabled Button Pressed')}
          >
            Disabled Button
          </ODSButton>
        </View>
        <View style={styles.container}>
          <ODSButton
            rIcon={'TB-Arrow-Left'}
            lIcon={'TB-Arrow-Right'}
            themeColor={themeObj.neutral}
            restStyle={{ marginBottom: 10 }}
            type="primary"
            disabled={false}
            onPress={() => console.log('Primary Button Pressed')}
          >
            Primary Button
          </ODSButton>

          <ODSButton
            rIcon={'TB-Arrow-Left'}
            lIcon={'TB-Arrow-Right'}
            restStyle={{ marginBottom: 10 }}
            themeColor={themeObj.neutral}
            type="secondary"
            disabled={false}
            onPress={() => console.log('Secondary Button Pressed')}
          >
            Secondary Button
          </ODSButton>

          <ODSButton
            rIcon={'TB-Arrow-Left'}
            lIcon={'TB-Arrow-Right'}
            restStyle={{ marginBottom: 10 }}
            themeColor={themeObj.purple}
            type="disabled"
            disabled
            onPress={() => console.log('Disabled Button Pressed')}
          >
            Nutral
          </ODSButton>
        </View>
        <View style={styles.container}>
          <ODSButton
            rIcon={'TB-Arrow-Left'}
            lIcon={'TB-Arrow-Right'}
            restStyle={{ marginBottom: 10 }}
            themeColor={themeObj.main.purple}
            type="primary"
            disabled={false}
            onPress={() => console.log('Primary Button Pressed')}
          >
            Primary Button
          </ODSButton>

          <ODSButton
            rIcon={'TB-Arrow-Left'}
            lIcon={'TB-Arrow-Right'}
            restStyle={{ marginBottom: 10 }}
            type="secondary"
            themeColor={themeObj.main.purple}
            disabled={false}
            onPress={() => console.log('Secondary Button Pressed')}
          >
            Secondary Button
          </ODSButton>

          <ODSButton
            rIcon={'TB-Arrow-Left'}
            lIcon={'TB-Arrow-Right'}
            restStyle={{ marginBottom: 10 }}
            type="disabled"
            disabled
            themeColor={themeObj.main.neutral}
            onPress={() => console.log('Disabled Button Pressed')}
          >
            Disabled Button
          </ODSButton>
        </View>
        <View style={styles.container}>
          <ODSButton
            rIcon={'TB-Arrow-Left'}
            lIcon={'TB-Arrow-Right'}
            restStyle={{ marginBottom: 10 }}
            themeColor={themeObj.main.salmon}
            type="primary"
            disabled={false}
            onPress={() => console.log('Primary Button Pressed')}
          >
            Primary Button
          </ODSButton>

          <ODSButton
            rIcon={'TB-Arrow-Left'}
            lIcon={'TB-Arrow-Right'}
            restStyle={{ marginBottom: 10 }}
            type="secondary"
            themeColor={themeObj.main.salmon}
            disabled={false}
            onPress={() => console.log('Secondary Button Pressed')}
          >
            Secondary Button
          </ODSButton>

          <ODSButton
            rIcon={'TB-Arrow-Left'}
            lIcon={'TB-Arrow-Right'}
            restStyle={{ marginBottom: 10 }}
            type="disabled"
            disabled
            themeColor={themeObj.main.salmon}
            onPress={() => console.log('Disabled Button Pressed')}
          >
            Disabled Button
          </ODSButton>
        </View>
        <View style={styles.container}>
          <ODSButton
            rIcon={'TB-Arrow-Left'}
            lIcon={'TB-Arrow-Right'}
            restStyle={{ marginBottom: 10 }}
            themeColor={themeObj.main.pink}
            type="primary"
            disabled={false}
            onPress={() => console.log('Primary Button Pressed')}
          >
            Primary Button
          </ODSButton>

          <ODSButton
            rIcon={'TB-Arrow-Left'}
            lIcon={'TB-Arrow-Right'}
            restStyle={{ marginBottom: 10 }}
            type="secondary"
            themeColor={themeObj.main.pink}
            disabled={false}
            onPress={() => console.log('Secondary Button Pressed')}
          >
            Secondary Button
          </ODSButton>

          <ODSButton
            rIcon={'TB-Arrow-Left'}
            lIcon={'TB-Arrow-Right'}
            restStyle={{ marginBottom: 10 }}
            type="disabled"
            disabled
            themeColor={themeObj.main.pink}
            onPress={() => console.log('Disabled Button Pressed')}
          >
            Disabled Button
          </ODSButton>
        </View>
      </View>
      <Text
        style={{ backgroundColor: '#3a3b3c', color: 'white', padding: 10, textAlign: 'center' }}
      >{` <ODSButton type="disabled" disabled onPress={() => console.log("Disabled Button Pressed")}>
        Disabled Button
      </ODSButton>`}</Text>
    </>
  );
};
const styles = StyleSheet.create({
  container: {
    padding: 10,
    width: 300,
  },
  categoryContainer: {
    padding: 10,
  },
  categoryTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  colorContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  colorBox: {
    width: 100,
    height: 100,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    margin: 5,
  },
  colorText: {
    color: 'black',
    fontSize: 8,
    textAlign: 'center',
  },
});
