import { Linking, Platform } from "react-native";

export const openUrlHelpers = (url) => {
  if (url) {
    Linking.canOpenURL(url).then((supported) => {
      if (supported) {
        if (Platform.OS === "web") {
          if (url) {
            let ext = url.split(".").reverse()[0];
            if (ext === "pdf") {
              window.open(url, "_blank");
            } else {
              window.open(url, "_blank");
            }
          }
        } else {
          Linking.openURL(url);
        }
      } else {
      }
    });
  }
};