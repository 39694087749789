import React, { useEffect } from "react";
import styled from "styled-components/native";
import getPlatformType from "../../helpers/getPlatform";
import { useDispatch, useSelector } from "react-redux";
import {
  customiseCookies,
  hasUserClickedOrAcceptedCookies,
  showCookiesBanner,
  showCustomiseBanner,
} from "../../actions/cookies";
import { ScrollView, Text, TouchableOpacity, View } from "react-native";
import { Typography } from "../tds/style";

const layoutType = getPlatformType();

const Container = styled.View`
  justify-content: center;
  flex-direction: row;
  align-items: center;
  align-self: center;
  z-index: 1;
  right: 0;
  left: 0;
  flex-direction: column;
  background: #000;
  width: ${layoutType == "phone" ? "90%" : "50%"}
  padding-horizontal: 40px;
  padding-vertical: 20px;
`;
const TitleContainer = styled.Text`
  background: #C4F5D8;
  padding: 47px 65px;
  border-radius: 30px;
  color: #4C664B;
  font-size: 23px;
  font-weight: 600px;
`;
const TextContainer = styled.View`
  margin-top: 50px;
`;
const TextT = styled.Text`
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
`;
const TextD = styled.Text`
  font-size: 20px;
  margin-bottom: 20px;
`;
const Span = styled.Text`
  font-size: 20px;
  color: #638ACC;
  cursor: pointer;
`;
const BtnsContainer = styled.View`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: ${props => (props.platform == "web" ? "50px" : (props.platform == "tab") ? "0px" : "0px")};
`;
const Btn1 = styled.TouchableOpacity`
  padding: 19px 82px;
  border-radius: 30px;
  background: #F5F5F5;
  border: 1px solid #638ACC;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
  margin-right: 30px;
`;
const Btn2 = styled.TouchableOpacity`
  padding: 19px 82px;
  border-radius: 30px;
  background: #638ACC;
  border: 1px solid #F5F5F5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
`;
const TextBtn1 = styled.Text`
  font-size: 19px;
  color: #454545;
  font-weight: 700;
`;
const TextBtn2 = styled.Text`
  font-size: 19px;
  color: #FFF;
  font-weight: 700;
`;

export default function Cookies({ setCookiesPopupVisible }) {
  const dispatch = useDispatch();
  const hasUserSavedCookies = useSelector((state) => state?.cookiesReducer?.userHasSavedCookiesPreferences);

  useEffect(() => {
    //alert(hasUserSavedCookies)
  }, [hasUserSavedCookies]);

  return (
    <Container platform={layoutType}>
      {/* <TouchableOpacity onPress={()=>setCookiesPopupVisible(false)} style={{position:'absolute',top:5,right:20}}>
        <Text  style={{color:'white',fontSize:25}}>x</Text>
      </TouchableOpacity> */}
      <Typography size={25} color={"white"}>Our Cookies</Typography>
      <ScrollView style={{ height: 300 }}>
        <Typography bold={"400"} size={layoutType == "phone" ? 18 : 16} top={10} color={"white"} align={"left"}>
          {`Cookies Policy   

What are Cookies?  

Cookies are small files which are stored on a user's (“you” or “your”) computer and hold data relating to a specific website. Cookies are placed on your computer by that website and accessed each time you browse the site.  

The cookies used by Openbrix.co.uk/ - including tlyfe - (the Site) collect data to help us improve our Site by seeing which parts of it are being used, how users are navigating to each page and what items on each page they prefer to click on.  

We have listed below each of the cookies that our Site uses, with detail as to why we use them. We have also detailed the time each cookie is kept on your system. This can be persistent (the cookie remains after you leave the site, to be used when you return) or session (the cookie is deleted once your visit is complete).  

You can opt-out of certain types of cookies via the Site. Opting out will apply only to the browser or device that you are using at the time, so you may need to change these settings again if you use other browsers or devices to access the Site.  

If you use your browser settings to block all cookies (including essential cookies) you may not be able to access all or parts of our Site. If you wish to restrict or block web browser cookies that are set on your device, you can do this through your browser settings; the help function within your browser should tell you how to achieve this. Alternatively, you may wish to visit www.aboutcookies.org, which contains comprehensive information on how to control browser cookies on a wide variety of desktop browsers.  

We use the following types of cookies:  

Error Tracking Cookies  

These cookies help us to measure our Site traffic and analyse user behaviour with the goal of improving our service.   

Cookies: amp ba62b8 and stripe mid  

Description: these cookies are used to measure performance and log errors. 

Application place: Sentry.  

Persistent or Session: 1 year. 

 

Cookie: stripe sid  

Description: this cookie is used to measure performance and log errors.  

Application place: Sentry. 

Persistent or Session: 1 day.  

  

Cookies: gsID and anonid  

Description: these cookies are used to measure performance and log errors.  

Application place: Sentry. 

Persistent or Session: Session  

 

Cookie: sentry-sc  

Description: this cookie is used to track errors on the Site. A user ID is stored to track errors for a particular user.  

Application place: Sentry  

Persistent or Session: 1 day.  

 

Cookie: session  

Description: this cookie is used by sentry for error tracking or performance monitoring 

Application place: Sentry  

Persistent or Session: 1 month   

 

Cookie:  AMP_*  

Description: this cookie is used by sentry for error tracking or performance monitoring 

Application place : Sentry  

Persistent or Session: 1 year   

 

Strictly Necessary Cookies  

These are cookies that are required for the operation of our Site. They include, for example, cookies that enable you to log into secure areas of our Site, use a shopping cart or make use of payment services. Without these cookies, we will not be able to connect you to our Site properly.  

Cookie: stripe mid  

Description: this cookie stores user attributes to support the processing of payments and provide fraud prevention capabilities.  

Application place: Stripe  

Persistent or Session: 1 year  

 

Analytical or Performance Cookies  

These cookies collect information about how visitors use our Site, for instance which page is viewed most often, and if error messages are seen. These cookies don't collect information that identifies a visitor. All information that these cookies collect is aggregated and therefore anonymous. It is only used to improve how our Site works.  

Like most websites, we use analytics software, such as Google Analytics. This software gathers anonymous data relating to how our Site is being used and then provides us with aggregated visitor statistics, number of page views etc. We supplement this data with our own analytics, which allows us to do more in-depth analysis of how people use our Site.  

We regularly add new features and change existing features to make the Site work as well as possible, and to make the experience as helpful as possible to all parties. To ensure that the changes we make are useful, we test variants of these changes across our Site and review data from our analytics system to see what works best. The cookies below allow us to do that.  

Cookie: ga  

Description: this cookie is associated with Google Universal Analytics. It is used to distinguish unique users by assigning randomly generated numbers as client identifiers. It is included in each page request and used to calculate visitors and data from site analytics reports.  

Application place: Google Analytics. 

Persistent or Session: 2 years. 

 

Cookie: gat  

Description: this cookie is used to throttle the request rate, limiting the collection of data on high traffic sites.  

Application place: Google Analytics.  

Persistent or Session: 10 minutes.   

 

Cookie: gid  

Description: this cookie distinguishes users.  

Application place: Google Analytics  

Persistent or Session: 1 day.  

 

Targeting Cookies and Online Behavioural Advertising  

These cookies record your visit to our Site, the pages you have visited and the links you have followed. We will use this information to make our Site and the advertising displayed on it more relevant to your interests. We may also share this information with third parties for this purpose.   

Cookies: Google 

You can learn how Google handles information from sites or apps that use their services by visiting their website. If you don't want Google to use information based on your OpenBrix activity for the purpose of showing you more relevant advertisements, you can opt out by following the instructions specified on their website. For reference, Google operates the Google Display Network (comprising partner websites and some Google properties like YouTube), DoubleClick online ad serving products, the DoubleClick Ad Exchange, and Invite Media. Because Google uses DoubleClick and Invite technology, cookies placed by Google for behavioural advertising, including the opt-out cookie, will appear as DoubleClick and Invite cookies. 

Local Storage  

In addition to cookies, local storage allows us to use a small amount of space on your device to store information, in much the same way as cookies. We use this to store information which, we feel, enhances your journey through the Site by remembering previous actions and making them default for the future.  

Strictly Necessary Local Storage Items  

Cookie: persist:root  

Description: this stores user authentication parameters as well as cookie preference information and search optimization data.  

Application place: Openbrix.  

Persistent or Session: Session  



Cookie: cookies_status  

Description: this stores cookie preference information.  

Application place: Openbrix.  

Persistent or Session: ?? 

If you have any queries or require more information regarding this policy, please contact us by email at: customerservices@OpenBrix.co.uk.`}

        </Typography>
      </ScrollView>
      <View style={{
        width: "100%",
        flexDirection: layoutType == "phone" ? "column" : "row",
        marginTop: layoutType == "phone" ? 20 : 30,
        marginBottom: 20,
        justifyContent: "space-around",
        alignItems: "center",
      }}>
        <TouchableOpacity onPress={() => {
          dispatch(showCustomiseBanner(true));
          setCookiesPopupVisible(false);
          dispatch(showCookiesBanner(false));
        }}>
          <Text style={{
            color: "white",
            fontSize: layoutType == "phone" ? 18 : 22,
            fontFamily: "Lato",
            textDecorationLine: "underline",
          }}>Manage Cookies</Text>
        </TouchableOpacity>
        <TouchableOpacity 
        testID="allowallcookies"
        onPress={() => {
          setCookiesPopupVisible(false);
          dispatch(hasUserClickedOrAcceptedCookies(true));
          dispatch(customiseCookies({
            Essential: true,
            Analytics: true,
            Functional: true,
            Advertising: true,
            Personalisation: true,
          }));
          localStorage.setItem("cookies_status", "true");
        }} style={{
          paddingHorizontal: 50,
          marginTop: layoutType == "phone" ? 20 : 0,
          paddingVertical: 10,
          borderRadius: 5,
          backgroundColor: "white",
        }}>
          <Typography color={"#000"} size={20}>Allow All</Typography>
        </TouchableOpacity>
      </View>
    </Container>
    // <Container platform={layoutType}>
    //   <TitleContainer>Cookies</TitleContainer>
    //   <TextContainer>
    //     <TextT>We value your privacy</TextT>
    //     <TextD>We use cookies to enhance your browsing experience, serve personalized ads or content, and analyze our traffic. <Span>Read more.</Span></TextD>
    //     <TextD>By clicking "Accept All", you consent to our use of cookies</TextD>
    //     <BtnsContainer platform={layoutType}>
    //       <Btn1 platform={layoutType} onPress={()=>{
    //         dispatch(showCustomiseBanner(true))
    //         dispatch(showCookiesBanner(false))
    //       }}>
    //         <TextBtn1>CUSTOMISE</TextBtn1>
    //       </Btn1>
    //       <Btn2 onPress={()=> {
    //         setCookiesPopupVisible(false)
    //         dispatch(hasUserClickedOrAcceptedCookies(true))
    //       }}>
    //         <TextBtn2>ACCEPT ALL</TextBtn2>
    //       </Btn2>
    //     </BtnsContainer>
    //   </TextContainer>
    // </Container>
  );
}
