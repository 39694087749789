import React from "react";
import { ScrollView, StyleSheet, Text, View } from "react-native";
import { ColorPallete } from "./ColorPallete";
import { Typography } from "./Typography";
import { Icons } from "./Icons";
import { ODSIcon } from "../atoms/ODSIcon";
import { getTokens } from "../../design-tokens/color-tokens";
import { typographyStyles } from "../../design-tokens/base-tokens";
import { ODSText } from "../atoms/ODSText";
import { Button } from "./Button";
import { Input } from "./Input";
import { Tiles } from "./tiles";
import { Badges } from "./Badges";
import ODSCheckbox from "../atoms/ODSCheckbox";
import { Checkbox } from "./Checkbox";
import { RadioButton } from "./RadioButton";
import ODSTabs from "../atoms/ODSTabs";
export const Playground = () => {
    const theme = getTokens('light');

    return (
        <ScrollView>
            <View style={{ flex: 1 }}>
                <Input />
                <ODSText color="white" backgroundColor={theme.colors.main.others.home} padding={20} textAlign={'center'} size={typographyStyles({ fontSize: 60 }).bold}>Openbrix Design System</ODSText>
                <ODSTabs />
                <Button />
                <Icons />
                <ColorPallete />
                {/* <Badges />
                <Tiles />
                <ColorPallete />
                <Typography />
                <Icons /> */}
                {/* <Button  /> */}
                {/* <Input /> */}
                {/* <Badges/> */}
                {/* <Tiles/> */}
                {/* <ColorPallete /> */}
                {/* <Typography />
                <Icons />
                <Button />
                <Input />
                <Checkbox />
                <RadioButton />
                <Badges /> */}
                {/* <Button /> */}
                {/* <Typography />
                <RadioButton />
                <Checkbox />
                <Input />
                <Tiles /> */}
                {/* <Badges /> */}
                {/* <Icons /> */}


            </View>
        </ScrollView>
    )
}
