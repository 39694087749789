import { useDispatch, useSelector } from 'react-redux';
import { ScrollView } from 'react-native-gesture-handler';
import React, { Fragment, useEffect, useState } from 'react';
import { Image, ImageBackground, Text, TouchableOpacity, View } from 'react-native';
import { loadStripe } from '@stripe/stripe-js/pure';
import { Typography } from '../tds/style';
import { COLORS } from '../../constants/colorPallete';
import NoDataNotification from '../NoDataFound/noData';
import getPlatformType, { getImage } from '../../helpers/getPlatform';
import { CardBig, CardContainer, RowContainer } from './styles';
import { STRIPEKEY } from '../../helpers/connectionHelpers';
import BackGroundGenerator from '../LandingPages/common/BackGroundGenerator';
import { ButtonWrapper, TabHeadingText, TableLabes } from '../rentalPortal/styles';
import {
  getCreditLadderHistory,
  getLadger,
  getlink,
  startPayment,
  cancelSubscription,
  getPaymentStatus,
} from '../../redux/actions/Ladger';
import { Elements } from '@stripe/react-stripe-js';
import { CheckoutForm } from '../../components/idVerification/checkoutForm';
import CustomPopUp from '../../commonComponents/customPopUp/customPopUp';
import ODSButton from '../../../openbrix-design-system/ui/atoms/ODSButton';
import { replaceAction } from '../../helpers/navigationHelpers';
import { getTokens } from '../../../openbrix-design-system/design-tokens/color-tokens';
import { ODSText } from '../../../openbrix-design-system/ui/atoms/ODSText';
import { Wrapper } from '../identifyAndFinance/style';

const MoreInformation = (props) => {
  const themeObj = getTokens('light').colors;
  const dispatch = useDispatch();
  const [url, setUrl] = useState('');
  const layoutType = getPlatformType();
  const isVerified = clhData ? true : false;
  const Paymentcol = ['Date', 'Amount', 'Post Code'];
  const [clientSecret, setClientSecret] = useState('');
  const [showCheckOutForm, setShowCheckOutForm] = useState(false);
  const profile = useSelector((state) => state.authReducer.profile);
  const clhData = useSelector((state) => state.ladgerReducer.clhData);
  const subscription = useSelector((state) => state.ladgerReducer?.clhData?.subscription);
  const authDataFromMobile = useSelector((state) => state?.appReducer?.mobileData?.data?.isWebView);
  const [popUpIsVisible, setpopUpIsVisible] = useState(false);
  let stripePromise = loadStripe(STRIPEKEY);
  console.log("--->DDD",clhData)
  const isMobilePaymentRequire = useSelector(
    (state) => state?.mobileReducer?.isCreditBuilderPayment
  );
  const isInappWebView = useSelector((state) => state?.mobileReducer?.isInappWebView);

  const [paymentRec, setPaymentRec] = useState(null);
  const paymentData = useSelector((state) => state.ladgerReducer?.clhData?.payment_data?.tenancies);
  const loadData = () => {
    dispatch(getLadger({}, props.navigation.navigate));
    dispatch(
      getlink((data) => {
        setUrl(data);
      })
    );
    dispatch(getCreditLadderHistory({}, props.navigation.navigate));
  };
  useEffect(() => {
    loadData();
  }, []);
  useEffect(() => {
    if (paymentData) {
      setPaymentRec(paymentData);
    }
  }, [paymentData]);
  const openLinkCreditHoistory = () => {
    window.open(url, '_blank');
  };
  console.log('paymentrec', paymentRec);
  const RenderPaymentTable = () => {
    return (
      <Fragment>
        {paymentRec[0].payments && paymentRec[0].payments.length ? (
          <Fragment>
            <ScrollView style={{ maxHeight: 300 }}>
              {paymentRec[0].payments?.map((item, index) => (
                <Fragment key={index}>
                  <View
                    style={{
                      width: '100%',
                      paddingVertical: 10,
                      flexDirection: 'row',
                    }}
                  >
                    <View
                      style={{
                        flex: Paymentcol.length / 1,
                        alignItems: 'center',
                      }}
                    >
                      <TableLabes>{item?.date || '-'}</TableLabes>
                    </View>
                    <View
                      style={{
                        flex: Paymentcol.length / 1,
                        alignItems: 'center',
                      }}
                    >
                      <TableLabes>
                        £ {(item?.amount ? (item?.amount / 100).toFixed(2) : '-') || '-'}
                      </TableLabes>
                    </View>
                    <View
                      style={{
                        flex: Paymentcol.length / 1,
                        alignItems: 'center',
                      }}
                    >
                      <TableLabes>{paymentRec[0]?.tenancy?.postcode || '-'}</TableLabes>
                    </View>
                  </View>
                  <View
                    style={{
                      height: 1,
                      backgroundColor: '#D9D9D9',
                      width: '100%',
                    }}
                  />
                </Fragment>
              ))}
            </ScrollView>
          </Fragment>
        ) : (
          <NoDataNotification
            noimage
            title="To start reporting your rental payment to help improve your credit score, click the button below"
            ctaText="Go to Credit Builder"
            onPress={() => props.navigation.navigate('Credit Builder')}
            imageIcon={require('../../assets/SVG/nt.png')}
            btnId={'CREDITHISTORY-NAVIGATE'}
          />
        )}
      </Fragment>
    );
  };

  const cancelSubscription1 = () => {
    setpopUpIsVisible(false);
    dispatch(
      cancelSubscription(() => {
        loadData();
      })
    );
  };

  const _signUp = () => {
    if (clhData?.payment_required || clhData?.subscription?.status=="canceled") {
      dispatch(
        startPayment((response) => {
          if (window?.ReactNativeWebView && isMobilePaymentRequire) {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({
                val: {
                  type: 'payment',
                  clientSecret: response?.clientSecret,
                },
              })
            );
          } else {
            setClientSecret(response.clientSecret);
            setTimeout(
              function () {
                setShowCheckOutForm(true);
              }.bind(this),
              1000
            );
          }
        })
      );
    } else {
      if (window?.ReactNativeWebView && isInappWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            val: {
              type: 'doctoopen_inapp',
              Url: url,
            },
          })
        );
      } else {
        window.open(url, '_blank');
      }
    }
  };
  const renderPayments = () => {
    return (
      <Wrapper>
        {showCheckOutForm == 'success' ? (
          renderPaymentSuccess()
        ) : showCheckOutForm == 'failed' ? (
          renderPaymentFailed()
        ) : (
          <View style={{ flexDirection: 'column', width: layoutType == 'phone' ? '100%' : 400 }}>
            <ODSText
              textAlign={'flex-start'}
              color={themeObj.neutral[700]}
              type={'button'}
              marginTop={20}
            >
              Checkout
            </ODSText>

            <View
              style={{
                padding: 24,
                borderColor: '#1B8A8C',
                borderWidth: 1.3,
                borderRadius: 8,
                marginTop: 20,
                marginBottom: 20,
              }}
            >
              <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <ODSText color={themeObj.neutral[700]} type={'sm'}>
                  Credit Builder
                </ODSText>
                <ODSText color={themeObj.neutral[700]} type={'sm'}>
                  £8 (inc. VAT)
                </ODSText>
              </View>
              <ODSText
                textAlign={'flex-start'}
                color={themeObj.neutral[700]}
                style={{ marginTop: 10 }}
                marginTop={20}
                type={'button'}
              >
                Total cost: £8
              </ODSText>
            </View>
            <Elements stripe={stripePromise} options={{ clientSecret: clientSecret }}>
              <CheckoutForm
                theme={themeObj.main.blue}
                onSuccessToken={(success) => {
                  if (success) {
                    setShowCheckOutForm('success');
                  } else {
                    setShowCheckOutForm('failed');
                  }
                }}
              />
            </Elements>
          </View>
        )}
      </Wrapper>
    );
  };
  const renderPaymentSuccess = () => {
    return (
      <View
        style={{
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <Image
          source={require('./../../assets/images/Progress.svg')}
          resizeMode="contain"
          style={{
            height: 50,
            width: 50,
            alignSelf: 'center',
            marginTop: 25,
          }}
        />
        <ODSText
          textAlign={'center'}
          color={themeObj.neutral[700]}
          style={{ alignSelf: 'center' }}
          type={'button'}
        >
          Payment successful!
        </ODSText>
        <Typography
          align={'center'}
          top={'10px'}
          size={'20px'}
          bold={'900'}
          color={COLORS.BLACK}
        ></Typography>
        <ODSText
          textAlign={'center'}
          color={themeObj.neutral[700]}
          style={{ alignSelf: 'center' }}
          type={'sm'}
        >
          Your payment is being applied to your account, please check again in a few moments.
        </ODSText>
        <ODSButton
            lIcon={'TB-Rotating'}
            restStyle={{ marginTop: 20, alignSelf:"center"}}
           
            disabled={false}
            onPress={() => {
              dispatch(
                getPaymentStatus((cb) => {
                  if (cb) {
                    setShowCheckOutForm(false);
                    loadData();
                  }
                })
              );
            }}
          >
           Refresh
          </ODSButton>
       
      </View>
    );
  };
  const renderPaymentFailed = () => {
    return (
      <View
        style={{
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <Image
          source={require('./../../assets/images/failed.png')}
          resizeMode="contain"
          style={{
            height: 50,
            width: 50,
            alignSelf: 'center',
            marginTop: 25,
          }}
        />
         <ODSText
          textAlign={'center'}
          color={themeObj.neutral[700]}
          style={{ alignSelf: 'center' }}
          type={'button'}
        >
          Payment failed!
        </ODSText>
        <Typography
          align={'center'}
          top={'10px'}
          size={'20px'}
          bold={'900'}
          color={COLORS.BLACK}
        ></Typography>
        <ODSText
          textAlign={'center'}
          color={themeObj.neutral[700]}
          style={{ alignSelf: 'center' }}
          type={'sm'}
        >
          Ooops! There appears to be a problem, your payment has failed.
        </ODSText>
        <ODSButton
            lIcon={'TB-Rotating'}
            restStyle={{ marginTop: 20, alignSelf:"center"}}
           type={"secondary"}
            disabled={false}
            onPress={() =>   setShowCheckOutForm(false)}
          >
           Try again
          </ODSButton>
      </View>
    );
  };
  const renderHowitwORKS = () => {
    return (
      <CardBig>
        <ODSText textAlign={'flex-end'} color={themeObj.neutral[700]} type={'button'}>
          How it works
        </ODSText>
        <View
          style={{
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <View
            style={{
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <View
              style={{
                width: 60,
                height: 60,
                borderRadius: 30,
                backgroundColor: '#CCE8CB',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Image
                source={require('../../assets/images/Vector1.png')}
                style={{
                  width: 30,
                  height: 30,
                  resizeMode: 'contain',
                }}
              />
            </View>
            <View
              style={{
                width: 3,
                height: layoutType == 'web' ? 50 : layoutType == 'phone' ? 50 : 80,
                marginVertical: 5,
                backgroundColor: COLORS.LGREEN,
              }}
            />
            <View
              style={{
                width: 60,
                height: 60,
                borderRadius: 30,
                backgroundColor: '#CCE8CB',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Image
                source={require('../../assets/images/Vector2.png')}
                style={{
                  width: 30,
                  height: 30,
                  resizeMode: 'contain',
                }}
              />
            </View>
            <View
              style={{
                width: 3,
                height: layoutType == 'web' ? 50 : layoutType == 'phone' ? 50 : 80,
                marginVertical: 5,
                backgroundColor: COLORS.LGREEN,
              }}
            />
            <View
              style={{
                width: 60,
                height: 60,
                borderRadius: 30,
                backgroundColor: '#CCE8CB',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Image
                source={require('../../assets/images/Vector3.png')}
                style={{
                  width: 30,
                  height: 30,
                  resizeMode: 'contain',
                }}
              />
            </View>
          </View>
          <View
            style={{
              width: layoutType == 'web' ? '85%' : layoutType == 'phone' ? '75%' : '80%',
            }}
          >
            <ODSText
              textAlign={'flex-end'}
              color={themeObj.neutral[700]}
              marginTop={layoutType == 'phone' ? 10 : 30}
              type={'sm'}
            >
              Provide your tenancy details
            </ODSText>

            <ODSText
              textAlign={'flex-end'}
              color={themeObj.neutral[700]}
              marginTop={10}
              type={'sm'}
            >
              {`Confirm your identity and tenancy details by answering a few quick questions. This will allow future rent payments to be reported to all three major credit agencies.`}
            </ODSText>

            <ODSText
              textAlign={'flex-end'}
              color={themeObj.neutral[700]}
              marginTop={layoutType == 'phone' ? 10 : 30}
              type={'sm'}
            >
              Connect your bank for read-only access
            </ODSText>

            <ODSText
              textAlign={'flex-end'}
              color={themeObj.neutral[700]}
              marginTop={10}
              type={'sm'}
            >
              The rent reporting service is FCA regulated and the technology we use is supported by
              all major banks.
            </ODSText>

            <ODSText
              textAlign={'flex-end'}
              color={themeObj.neutral[700]}
              marginTop={layoutType == 'phone' ? 10 : 30}
              type={'sm'}
            >
              Pay your rent as normal
            </ODSText>

            <ODSText
              textAlign={'flex-end'}
              color={themeObj.neutral[700]}
              marginTop={10}
              type={'sm'}
            >
              The service will read and identify rent payments that will appear on statutory credit
              reports.
            </ODSText>
          </View>
        </View>
      </CardBig>
    );
  };
  return (
    <BackGroundGenerator isTLYF props={props}>
      <ImageBackground
        source={getImage('cb.png')}
        style={{
          width: '100%',
          height: '270px',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
        resizeMode="cover"
      >
        {authDataFromMobile ? null : (
          <ODSButton
            rIcon={'TB-Arrow-Left'}
            restStyle={{ marginTop: 20, marginLeft: 20, width: 200 }}
            type="secondary"
            disabled={false}
            onPress={() => replaceAction(props?.navigation, 'Dashboard')}
          >
            My Dashboard
          </ODSButton>
        )}
      </ImageBackground>
      {showCheckOutForm ? (
        renderPayments()
      ) : (
        <RowContainer>
          <View>
            <CardBig>
              <ODSText textAlign={'flex-end'} color={themeObj.neutral[700]} type={'button'}>
                Report your rent payments to help improve your credit score
              </ODSText>
              <ODSText
                textAlign={'flex-end'}
                color={themeObj.neutral[700]}
                marginTop={10}
                type={'sm'}
              >
                The smarter way to report your rent payments & improve your credit position
                with the UK’s top three credit reference agencies: Experian, Equifax and TransUnion.
              </ODSText>
              <ODSText
              textAlign={'flex-end'}
              color={themeObj.neutral[700]}
              marginTop={10}
              type={'xs'}
            >
              {`*Our Rent Reporting function allows you to keep and prove that your rent payment are up to date and with tlyfe sharing these payments with the Credit Reference Agencies, your prompt rental payments can influence your own Credit Score.`}
            </ODSText>

              <ODSText
                textAlign={'flex-end'}
                color={themeObj.neutral[700]}
                marginTop={20}
                type={'button'}
              >
                Benefit from Credit Building
              </ODSText>
              <ODSText
                textAlign={'flex-end'}
                color={themeObj.neutral[700]}
                marginTop={10}
                type={'sm'}
              >
                A credit score forms the basis on which companies make lending decisions. A strong
                credit rating can help you access finance at better rates.
              </ODSText>

              <ODSText
                textAlign={'flex-end'}
                color={themeObj.neutral[700]}
                marginTop={20}
                type={'button'}
              >
                Benefit from Rent Reporting
              </ODSText>
              <ODSText
                textAlign={'flex-end'}
                color={themeObj.neutral[700]}
                marginTop={10}
                type={'sm'}
              >
                A verified record that shows your rental payments are made in full and on time,
                which you can share with agents, landlords or mortgage lenders.
              </ODSText>
              <ODSText
                textAlign={'flex-end'}
                color={themeObj.neutral[700]}
                marginTop={10}
                type={'sm'}
              >
                Sign up and start building your credit profile for only{' '}
                <ODSText textAlign={'flex-end'} color={themeObj.neutral[700]} type={'button'}>
                  £8.00 per month.
                </ODSText>
                Click the button below.
              </ODSText>
            </CardBig>
            {renderHowitwORKS()}
          </View>
          <View>
            <CardBig>
              <>
                {showCheckOutForm ? (
                  renderPayments()
                ) : (
                  <View>
                    {/* {clhData?.user_data?.status && (
                      <Typography align={'center'} color={'black'} size={'18px'}>
                        {`Status: ` + clhData?.user_data?.status}
                      </Typography>
                    )} */}

                    {(subscription && clhData?.subscription?.status!="canceled" ) ? null : (
                      <ODSText textAlign={'flex-end'} color={themeObj.neutral[700]} type={'button'}>
                        Sign up in under 5 minutes for only £8.00 per month!
                      </ODSText>
                    )}

                    {clhData?.user_data?.status == 'pending' ||
                    clhData?.user_data?.status == 'accepted' ||
                    clhData?.user_data?.status == 'live' ? (
                      <ODSText
                        textAlign={'center'}
                        color={themeObj.neutral[700]}
                        style={{ alignSelf: 'center' }}
                        type={'button'}
                      >
                        You’re currently subscribed!
                      </ODSText>
                    ) : (subscription && clhData?.subscription?.status!="canceled") ? (
                      <>
                        <ODSText
                          textAlign={'center'}
                          color={themeObj.neutral[700]}
                          style={{ alignSelf: 'center' }}
                          type={'button'}
                        >
                          Connect to OpenBanking
                        </ODSText>
                        <ODSText
                          textAlign={'center'}
                          color={themeObj.neutral[700]}
                          marginTop={10}
                          style={{ alignSelf: 'center' }}
                          type={'sm'}
                        >
                          Please click continue, to complete your application and begin rent
                          reporting.
                        </ODSText>
                      </>
                    ) : null}
                    <ODSButton
                      lIcon={'TB-Arrow-Right'}
                      restStyle={{ width: 300, marginTop: 20, alignSelf: 'center' }}
                      type="primary"
                      // themeColor={themeObj.main.purple}
                      disabled={!clhData?.payment_required && !url}
                      onPress={_signUp}
                    >
                      {clhData?.user_data?.status == 'pending' ||
                      clhData?.user_data?.status == 'accepted' ||
                      clhData?.user_data?.status == 'live'
                        ? 'My Credit Builder'
                        : (subscription && clhData?.subscription?.status!="canceled" )
                        ? 'Continue'
                        : `Start Reporting`}
                    </ODSButton>

                    {(subscription && clhData?.subscription?.status!="canceled") ? (
                      <ODSButton
                        restStyle={{ width: 300, marginTop: 20, alignSelf: 'center' }}
                        type="secondary"
                        themeColor={themeObj.main.salmon}
                        onPress={() => setpopUpIsVisible(true)}
                      >
                        Cancel Subscription
                      </ODSButton>
                    ) : null}
                    {(subscription && clhData?.subscription?.status!="canceled" ) && (
                      <ODSText
                        textAlign={'center'}
                        color={themeObj.neutral[700]}
                        marginTop={10}
                        style={{ alignSelf: 'center' }}
                        type={'sm'}
                      >
                        Subscription renews :{' '}
                        {
                          new Date(subscription?.current_period_end * 1000)
                            .toISOString()
                            .split('T')[0]
                        }
                      </ODSText>
                    )}
                  </View>
                )}
              </>

              {paymentRec && paymentRec?.length ? (
                <View>
                  <View
                    style={{ flexDirection: 'row', justifyContent: 'space-around', marginTop: 20 }}
                  >
                    <TabHeadingText style={{ color: COLORS.GREEN }}>Payment History</TabHeadingText>
                  </View>
                  <View
                    style={{
                      marginTop: 20,
                      width: '100%',
                      paddingVertical: 10,
                      flexDirection: 'row',
                    }}
                  >
                    {Paymentcol.map((item, index) => (
                      <View
                        key={index}
                        style={{
                          flex: Paymentcol.length / 1,
                          alignItems: 'center',
                        }}
                      >
                        <Text>{item}</Text>
                      </View>
                    ))}
                  </View>
                  <View
                    style={{
                      height: 1,
                      backgroundColor: '#D9D9D9',
                      width: '100%',
                    }}
                  />
                  {RenderPaymentTable()}
                </View>
              ) : null}
              {/* <Image
                source={require('../../assets/images/clbanner.png')}
                style={{
                  width: 213,
                  height: 100,
                  resizeMode: 'contain',
                  alignSelf: 'center',
                  marginTop: 20,
                }}
              /> */}
              <ODSText
                textAlign={'flex-start'}
                color={themeObj.neutral[700]}
                marginTop={10}
                style={{ alignSelf: 'center' }}
                type={'sm'}
              >
                Supported banks:
              </ODSText>

              <ODSText
                textAlign={'flex-start'}
                color={themeObj.neutral[700]}
                marginTop={10}
                style={{ alignSelf: 'center' }}
                type={'sm'}
              >
                {`Bank of Scotland, Barclays, Chelsea Building Society, Chase, Danske Bank, First Direct, Halifax, HSBC, Lloyds Bank, MBNA, Monzo, Nationwide, Natwest, Revolut, Royal Bank of Scotland, Santander, Starling Bank, Tesco Bank, Tide, Transferwise, TSB, Ulster Bank, Yorkshire Building Society`}
              </ODSText>
            </CardBig>
          </View>
        </RowContainer>
      )}
      <CustomPopUp
        loading={popUpIsVisible}
        primaryButtonText={'Sure'}
        secondaryButtonText={'Back'}
        primaryText={'Cancel Subscriptions ?'}
        secondaryText={' Are you sure you want to Cancel Subscriptions?'}
        onPrimaryButtonPress={() => {
          cancelSubscription1();
        }}
        note={
          'that by cancelling this product your monthly rent will not be recorded on your credit file with UK largest credit reference agencies. Many banks and financial institutions use these agencies when giving you credit and loans. This can materially impact your interest on credit cards and loans. Furthermore due to open banking regulation, once cancelled we would require 45 days to reconnect to your bank.'
        }
        onSecondaryButtonPress={() => {
          setpopUpIsVisible(false);
        }}
      />
    </BackGroundGenerator>
  );
};
export default MoreInformation;
