import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  headingText: {
    fontFamily: 'Lato',
    fontWeight: '700',
    fontSize: 49,
  },
  heading1: {
    fontFamily: 'Lato',
    fontWeight: '700',
    fontSize: 40,
  },
  heading2: {
    fontFamily: 'Lato',
    fontWeight: '700',
    fontSize: 27,
  },
  heading3: {
    fontFamily: 'Lato',
    fontWeight: '400',
    fontSize: 19,
  },
  TextCenter: {
    textAlign: 'center',
  },
  manifestoText: {},
  container: {
    marginTop: 260,
  },
  headingWrapper: {},

  headingTextWrapper: {},
  visionWrapper: {
    marginTop: 378,
    alignItems: 'center',
    justifyContent: 'center',
  },
  visionSubTextWrapper: {
    marginTop: 71,
    alignItems: 'center',
    justifyContent: 'center',
  },
  saperaterWrapper: {
    marginVertical: 65,
  },
  saperator: {
    height: 0.5,
    backgroundColor: '#6d6d6d',
  },
  valuesWrapper: {
    marginBottom: 133,
  },
  sectionWrapper: {
    marginBottom: 48,
  },
  section1: {
    marginBottom: 22,
  },
  section2: {
    marginBottom: 37,
  },
  manifastoWrapper: {
    marginTop: 96,
    marginBottom: 121,
  },
  cardWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 180,
  },
  formWrapper: {
    paddingHorizontal: 30,
    paddingVertical: 30,
  },
  horizontal: {
    marginHorizontal: 122,
    backgroundColor:"white"
  },
});
