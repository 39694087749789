import React, { useState, useEffect, useReducer } from "react";
import { Entypo } from "@expo/vector-icons";

import { View, Text, ScrollView, Dimensions } from "react-native";
import {
  Container,
  MainContent,
  ListRow,
  ListCol,
  AlertTitle,
  AlertTitleText,
  AlertMoreInfo,
  AlertMoreInfoText,
  ListHeader,
  ListHeaderClearCTA,
  NoDataTag,
} from "./createAlertScreen.style";
import {
  BubbleText,
  BubbleTextView,
  BubbleIcon,
  BubbleSuperText,
} from "../../commonComponents/googlePlaces/sugg.style";
import Header from "../../commonComponents/headerComponent/headerComponent";
import WideButton from "../../commonComponents/wideButton";
import Loader from "./../../commonComponents/activityIndicator/activityIndicator";
import NoDataNotification from "../NoDataFound/noData";

import Footer from "../../commonComponents/footerComponent/customFooter";
import Form from "./form";
import getPlatformType from "../../helpers/getPlatform";
import { FilterConfigObject } from "./../../helpers/objectHelpers";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { myAlerts, delAlert } from "../../redux/actions/createAlert";
import { ContainerScrollView } from "../../helpers/appStyles";
const WIN_HEIGHT = Dimensions.get("window").height;

const layoutType = getPlatformType();
const MyAlerts = (props) => {
  const [myAlertsData, setMyAlertsData] = useState();

  const updateData = (type, field, value) => {};

  useEffect(() => {
    const unsubscribe = navigation.addListener("focus", () => {
      props.myAlerts();
    });
    return unsubscribe;
  }, [navigation, props]);

  useEffect(() => {
    setMyAlertsData(props.myAlertsData?.data);
  }, [props]);

  const delAlert = async (e) => {
    await props.delAlert(e);
    //await props.myAlerts();
  };

  const { navigation } = props;
  const { navigate } = navigation;
  const { loading } = props;
  return (
    <Container>
      <Header
        showLogoWeb
        showLogoTab
        rightChildLabelTab
        rightChildLabelWeb
        showBackButtonTab
        showClearAllButtonMob
        showClearAllButtonTab
        pageTitleMob="My alerts"
        showDrawerMob
        clearAction={() => delAlert("")}
        {...props}
      />
      <ContainerScrollView>
        <ListRow style={{ minHeight: WIN_HEIGHT - 337 }}>
          {myAlertsData&&myAlertsData?.length &&Array.isArray(myAlertsData)? (
            <>
              <ListHeader>
                <View style={{ flexDirection: "row" }}>
                  <ListHeaderClearCTA
                    onPress={() =>
                      navigate("Create Alert", {
                        alert_id: "",
                      })
                    }
                    style={{ marginRight: layoutType == "web" ? 20 : 0 }}
                  >
                    <Text style={{ color: "#E71E82", fontWeight: "500" }}>
                      Create New Alert
                    </Text>
                  </ListHeaderClearCTA>
                  {layoutType == "web" && (
                    <ListHeaderClearCTA onPress={() => delAlert("")}>
                      <Text>Clear all</Text>
                    </ListHeaderClearCTA>
                  )}
                </View>
              </ListHeader>
              {myAlertsData.data.map((data, index) => {
                return (
                  <ListCol
                    key={index}
                    style={{
                      shadowColor: "#000",
                      shadowOffset: {
                        width: 0,
                        height: 11,
                      },
                      shadowOpacity: 0.55,
                      shadowRadius: 14.78,

                      elevation: 22,
                    }}
                  >
                    <MainContent style={{ paddingTop: 20, paddingBottom: 20 }}>
                      <AlertTitle>
                        <AlertTitleText>Alert</AlertTitleText>
                        <AlertMoreInfo
                          onPress={() =>
                            navigate("Create Alert", {
                              alert_id: data.alert_id,
                            })
                          }
                        >
                          <AlertMoreInfoText>More Info</AlertMoreInfoText>
                          <Entypo
                            name="chevron-right"
                            size={17}
                            color="#E71E82"
                          />
                        </AlertMoreInfo>
                      </AlertTitle>
                      <View
                        style={{ marginTop: 10, marginLeft: 5, marginRight: 5 }}
                      >
                        <BubbleTextView
                          style={{ marginLeft: 16, marginRight: 16 }}
                        >
                          <>
                            <BubbleText>
                              <BubbleIcon>
                                <Entypo
                                  name="cross"
                                  size={17}
                                  color="#E71E82"
                                />
                              </BubbleIcon>
                              <BubbleSuperText>
                                {data.search_string}
                              </BubbleSuperText>
                            </BubbleText>
                          </>
                        </BubbleTextView>
                        <Form
                          key="1"
                          navigate={navigate}
                          updateData={updateData}
                          formValues={data}
                          tab={data.property_for == 1 ? "Tab1" : "Tab2"}
                          readonly={true}
                          clear={false}
                        />
                      </View>
                      <View
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <WideButton
                          onPress={() => delAlert(data.alert_id)}
                          borderRadius={15}
                          childText="Cancel Alert"
                          whiteButton={true}
                          styles={{ marginTop: "2%" }}
                          width="80%"
                        />
                      </View>
                    </MainContent>
                  </ListCol>
                );
              })}
            </>
          ) : 
            <NoDataTag>
              <NoDataNotification
                title="Sorry, you don’t have any new alerts"
                description="Why not try creating a new alert?"
                ctaText="Create New Alert"
                imageIcon={ require("../../assets/SVG/not-alert.svg")}
                onPress={() =>
                  navigate("Create Alert", {
                    alert_id: "",
                  })
                }
              />
            </NoDataTag>
          }

          {/* </Background> */}
        </ListRow>

        <Footer isTLYF {...props} />
      </ContainerScrollView>
      <Loader loading={loading} />
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.alertReducer.myAlertsLoading,
    myAlertsData: state.alertReducer.myAlertsData,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      myAlerts,
      delAlert,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(MyAlerts);
