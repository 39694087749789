import React from "react";
import styled from "styled-components/native";
import getPlatformType from "../../helpers/getPlatform";

const layoutType = getPlatformType();
let Container,
  ContentWrapper,
  Content,
  Wrapper,
  SpacingView,
  TopContainer,
  NotificationTitle,
  NotificationData,
  NotificationProperty,
  BottomContainer,
  BottomContainerInner,
  NotificationText,
  NotificationPara,
  NotificationDataList,
  NotificationDataItem,
  NotificationDataItemValue;

TopContainer = styled.View`
  width: 60%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0px 0 40px;
`;
NotificationTitle = styled.Text`
  width: 100%;
  color: #000;
  font-size: 18px;
  line-height: 22px;
  padding: 0px 0 20px;
`;
NotificationData = styled.View`
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: rgba(128, 128, 128, 0.4) 0px 0px 7px;
  width: 45%;
`;
NotificationDataList = styled.View`
  padding: 15px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
NotificationDataItem = styled.Text`
  color: #000;
  font-size: 13px;
  line-height: 16px;
`;
NotificationDataItemValue = styled.Text`
  color: #000;
  font-size: 14px;
  line-height: 17px;
`;
NotificationProperty = styled.View`
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 45%;
  display: flex;
  flex-direction: row;
  box-shadow: rgba(128, 128, 128, 0.4) 0px 0px 7px;
  height: 70px;
  overflow: hidden;
`;
BottomContainer = styled.View`
  width: 100%;
  background: #fff;
  box-shadow: rgba(128, 128, 128, 0.4) 0px 0px 7px;
  margin-bottom: 100px;
`;
BottomContainerInner = styled.View`
  width: 65%;
  margin: 0 auto;
  padding: 20px 0px;
`;
NotificationText = styled.Text`
  width: 100%;
  color: #000;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 20px;
`;
NotificationPara = styled.Text`
  width: 100%;
  padding: 20px;
  color: #000;
  font-size: 13px;
  line-height: 20px;
  border-radius: 5px;
  border: 1px solid #d0d0d0;
  min-height: 100px;
`;

if (layoutType == "phone") {
  Container = styled.View``;
  SpacingView = styled.View``;
  ContentWrapper = styled.View`
    margin: 26px 16px;
  `;
  Content = styled.View`
    box-shadow: rgba(128, 128, 128, 0.4) 0px 0px 7px;
    background-color: white;
    border-bottom-left-radius: 20px;
    border-top-right-radius: 20px;
  `;
  BottomContainerInner = styled.View`
    width: 90%;
    margin: 0 auto;
    padding: 20px 0px;
  `;
  TopContainer = styled.View`
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 10px 0 100px;
  `;
  NotificationData = styled.View`
    border-bottom-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: rgba(128, 128, 128, 0.4) 0px 0px 7px;
    width: 100%;
    order: 2;
  `;
  NotificationProperty = styled.View`
    border-bottom-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    flex-direction: row;
    box-shadow: rgba(128, 128, 128, 0.4) 0px 0px 7px;
    height: 70px;
    overflow: hidden;
    margin-bottom: 20px;
    order: 1;
    width: 90%;
    margin: 0px auto 30px;
  `;
  BottomContainer = styled.View`
    width: 100%;
    background: #fff;
    box-shadow: rgba(128, 128, 128, 0.4) 0px 0px 7px;
    margin-bottom: 30px;
  `;
  Wrapper = styled.View``;
} else if (layoutType == "web") {
  Container = styled.View`
    justify-content: center;
    flex-direction: row;
    align-self: center;
    align-items: center;
  `;
  ContentWrapper = styled.View`
    width: 444px;
    margin-top: 64px;
  `;
  Content = styled.View`
    box-shadow: rgba(128, 128, 128, 0.4) 0px 0px 7px;
    background-color: white;
    border-bottom-left-radius: 20px;
    border-top-right-radius: 20px;
  `;
  Wrapper = styled.View`
    flex-direction: row;
  `;
  SpacingView = styled.View`
    margin-right: 274.8px;
  `;
} else {
  Container = styled.View`
    justify-content: center;
    flex-direction: row;
    align-self: center;
    align-items: center;
  `;
  ContentWrapper = styled.View`
    width: 343px;
    margin-top: 68px;
  `;
  Content = styled.View`
    box-shadow: rgba(128, 128, 128, 0.4) 0px 0px 7px;
    background-color: white;
    border-bottom-left-radius: 20px;
    border-top-right-radius: 20px;
  `;
  Wrapper = styled.View`
    flex-direction: row;
  `;
  SpacingView = styled.View`
    margin-right: 70px;
  `;
  BottomContainerInner = styled.View`
    width: 90%;
    margin: 0 auto;
    padding: 20px 0px;
  `;
  TopContainer = styled.View`
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 20px 0 40px;
  `;
}

const LeftPanal = styled.View``;
const BackTextWrapper = styled.Text`
  height: 17px;
  color: #000000;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
`;
export {
  Container,
  LeftPanal,
  BackTextWrapper,
  ContentWrapper,
  Content,
  Wrapper,
  SpacingView,
  TopContainer,
  NotificationTitle,
  NotificationData,
  NotificationProperty,
  BottomContainer,
  BottomContainerInner,
  NotificationText,
  NotificationPara,
  NotificationDataList,
  NotificationDataItem,
  NotificationDataItemValue,
};
