import { combineReducers } from "redux";
import appReducer from "./app";
import authReducer from "./auth";
import cookiesReducer from "./cookies";
import propertyReducer from "./property";
import statusPopupReducer from "./statusPopup";
import alertReducer from "./createAlert";
import ladgerReducer from "./Ladger";
import mediaReducer from "./mediaHub";
import notificationReducer from "./notification";
import mobileReducer from "./mobileReducer";
import preReferencing from "./preReferencing";
import { tds } from './../../components/tds/tdsRedux/tds.reducer';
import { tenant } from './../../components/openFlow/openFlowRedux/tenant.reducer';

import { types } from "../actionTypes";

const mainReducer = combineReducers({
  appReducer,
  authReducer,
  propertyReducer,
  statusPopupReducer,
  alertReducer,
  ladgerReducer,
  mediaReducer,
  notificationReducer,
  preReferencing,
  tenant,
  tds,
  cookiesReducer,
  mobileReducer
});

const rootReducer = (state, action) => {
  // Clear all data in redux store to initial.
  if (action.type === types.DESTROY_STATE) state = undefined;

  return mainReducer(state, action);
};

export default rootReducer;
