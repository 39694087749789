import React, { Component } from "react";
import {
  View,
  TouchableWithoutFeedback,
  TouchableOpacity,
  findNodeHandle,
  Button,
  SafeAreaView,
  ScrollView,
  Text,
} from "react-native";
import { MaterialIcons } from "@expo/vector-icons";

import {
  ReportWrapper,
  ReportBox,
  ReportDateName,
  ReportDate,
  ReportNameText,
  ReportDetails,
  ReportDetailsText,
  ReportLabelBox,
  ReportLabel,
  ReportDetailsBox,
  Container,
} from "./pastStyles";

import Header from "../../commonComponents/headerComponent/headerComponent";
import getPlatformType from "../../helpers/getPlatform";
import Footer from "../../commonComponents/footerComponent/customFooter";
const layoutType = getPlatformType();
import idx from "idx";
import { Entypo } from "@expo/vector-icons";
const PastIssues = (props) => {
  return (
    <SafeAreaView>
      <Header
        showDrawer
        showLogo
        showBackButtonTab
        showBackButtonMob
        showLogoTab
        onBackAction={() => props.navigation.navigate("Rental Passport")}
        {...props}
      />
      <ScrollView>
        <View>
          {layoutType === "web" && (
            <Container>
              <TouchableOpacity
                onPress={() => {
                  props.navigation.navigate("Rental Passport");
                }}
              >
                <Text>
                  <Entypo
                    name="chevron-left"
                    size={18}
                    color="#000"
                    style={{}}
                  />
                  Back
                </Text>
              </TouchableOpacity>
            </Container>
          )}
          {layoutType == "phone" ? (
            <View>
              <View style={{ paddingHorizontal: 10 }}>
                <TouchableOpacity
                  style={{
                    height: 45,
                    width: "100%",
                    backgroundColor: "#FFFFFF",
                    shadowColor: "grey",
                    shadowOffset: {
                      width: 2,
                      height: 2,
                    },
                    shadowOpacity: 0.09,
                    shadowRadius: 6.68,
                    elevation: 11,
                    borderTopRightRadius: 10,
                    borderBottomLeftRadius: 10,
                    justifyContent: "space-between",
                    marginTop: 15,
                    alignItems: "center",
                    marginBottom: 15,
                    flexDirection: "row",
                    paddingHorizontal: 15,
                  }}
                >
                  <Text style={{ color: "#E71F81", fontWeight: "bold" }}>
                    {idx(props, (_) => _.route.params.item.summary)
                      ? props.route.params.item.summary
                      : ""}
                  </Text>
                  <Text style={{}}>
                    {idx(props, (_) => _.route.params.item.date_closed)
                      ? props.route.params.item.date_closed.substring(0, 10)
                      : ""}
                  </Text>
                </TouchableOpacity>
              </View>
              <View style={{ backgroundColor: "#ffff", padding: 10 }}>
                <View style={{ paddingBottom: 20, paddingTop: 10 }}>
                  <Text> Report Description1 </Text>
                </View>
                <View
                  style={{
                    width: "100%",
                    borderWidth: 1,
                    borderColor: "#d0d0d0",
                    borderRadius: 5,
                    padding: 10,
                  }}
                >
                  <Text>
                    {" "}
                    {idx(props, (_) => _.route.params.item.description)
                      ? props.route.params.item.description
                      : ""}
                  </Text>
                </View>
              </View>
              {/* <ReportDetailsBox> */}
              {/* <ReportLabelBox>
                  <ReportLabel>Report Description1</ReportLabel>
                </ReportLabelBox> */}
              {/* <ReportDetails>
                  <ReportDetailsText>
                    {props.route.params.item ? props.route.params.item.description : ""}
                  </ReportDetailsText>
                </ReportDetails> */}
              {/* </ReportDetailsBox> */}
            </View>
          ) : (
            <ReportWrapper>
              <ReportBox>
                <ReportDateName>
                  <ReportNameText>
                    {props.route.params.item
                      ? props.route.params.item.summary
                      : ""}
                  </ReportNameText>
                  <ReportDate>
                    {props.route.params.item
                      ? props.route.params.item.date_closed.substring(0, 10)
                      : "22/06/20"}
                  </ReportDate>
                </ReportDateName>
                <ReportDetailsBox>
                  <ReportLabelBox>
                    <ReportLabel>Report Description</ReportLabel>
                  </ReportLabelBox>
                  <ReportDetails>
                    <ReportDetailsText>
                      {props.route.params.item
                        ? props.route.params.item.description
                        : ""}
                    </ReportDetailsText>
                  </ReportDetails>
                </ReportDetailsBox>
              </ReportBox>
            </ReportWrapper>
          )}
          {layoutType !== "phone" ? <Footer {...props} /> : null}
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};
export default PastIssues;
