import React, { useState, useEffect } from "react";
import Header from "../../commonComponents/headerComponent/headerComponent";
import Footer from "../../commonComponents/footerComponent/customFooter";
import {
  View,
  Text,
  Dimensions,
  SafeAreaView,
  Image,
  TouchableOpacity,
  Platform,
  ScrollView,
  FlatList,
  TextInput,
  StyleSheet,
} from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { Entypo } from "@expo/vector-icons";
import {
  getAffordStatus,
  resetStatus,
} from "./../../redux/actions/preReferencing";
import { MainSection, Section1, Section2 } from "./preReferencingStyle";
import { MainTitle } from "./style";
import CardComponent from "./Components/cardComponent";
import { ContainerScrollView } from "../../helpers/appStyles";
import SkeltonComponents from "./Components/skeltonComponents";
import { useSelector, useDispatch } from "react-redux";
import { COLORS } from "../../constants/colorPallete";
import { Button } from "../openFlow/atoms/button";

const Afford = (props) => {
  const { onCloseClick, showClose } = props;
  const [Affordability, setAffordablity] = useState(null);
  const [expectedMonthlyRental, setExpectedMonthlyRental] = useState(null);
  const dispatch = useDispatch();
  const preFilledData = useSelector(
    (state) => state?.preReferencing?.ccqData?.affordibilityCheck
  );
  const [monthlyIncome, setMonthlyIncome] = useState(null);
  const [affordData, setAffordData] = useState(null);

  useEffect(() => {
    if (preFilledData) {
      if (preFilledData?.monthly_rent > 1) {
        setAffordablity("YES");
        setExpectedMonthlyRental(preFilledData?.monthly_rent);
        setMonthlyIncome(preFilledData?.salary);
        setAffordData(preFilledData);
      } else if (preFilledData?.monthly_rent == 1) {
        setAffordablity("NO");
        setMonthlyIncome(preFilledData?.salary);
        setAffordData(preFilledData);
      }
    }
  }, [preFilledData]);


  const isError = () => {
    console.log("monthlyIncomemonthlyIncome",monthlyIncome)
    console.log("expectedMonthlyRental",expectedMonthlyRental)
    console.log("Affordability",Affordability)

    if (Affordability == "YES") {
      if (monthlyIncome && expectedMonthlyRental) {
        return false;
      } else {
        return true;
      }
    } else if (Affordability === "NO") {
      if (monthlyIncome) {
        return false;
      } else {
        return true;
      }
    }
  };

  const checkFieldDataAmount = (val) => {
    setExpectedMonthlyRental(val.replace(/[^\d.-]/g, ''));
  }
  const checkFieldDataYearly = (val) => {
    setMonthlyIncome(val.replace(/[^\d.-]/g, ''));
  }


  return (
    <MainSection style={{ marginBottom: 10 }}>
      <Section1>
        <MainTitle style={{ marginTop: 10, textAlign: "left",color:COLORS.LGREEN ,alignSelf :"center"}}>
          Affordability
        </MainTitle>
        <SkeltonComponents
          type={"empty"}
          renderBody={() => {
            return (
              <>
                <Text>Have you found a property you would like to rent?</Text>
                <View
                  style={{ flexDirection: "row", flex: 1, marginTop: "4%" }}
                >
                  <View
                    style={{
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      flex: 0.5,
                    }}
                  >
                    <TouchableOpacity
                      onPress={() => {
                        setAffordablity("NO");
                        dispatch(resetStatus());
                      }}
                      style={{
                        height: 24,
                        justifyContent: "center",
                        alignItems: "center",
                        width: 98,
                        borderRadius: 25,
                        backgroundColor: "#FC4850",
                      }}
                    >
                      <Text style={{ color: "white" }}>No</Text>
                    </TouchableOpacity>
                  </View>
                  <View
                    style={{
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      flex: 0.5,
                    }}
                  >
                    <TouchableOpacity
                      onPress={() => {
                        dispatch(resetStatus());
                        setAffordablity("YES");
                      }}
                      style={{
                        height: 24,
                        justifyContent: "center",
                        alignItems: "center",
                        width: 98,
                        borderRadius: 25,
                        backgroundColor: COLORS.LGREEN,
                      }}
                    >
                      <Text style={{ color: "white" }}>Yes</Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </>
            );
          }}
        />

        {Affordability ? (
          <React.Fragment>
            {Affordability === "YES" ? (
              <SkeltonComponents
                type={"empty"}
                renderBody={() => {
                  return (
                    <>
                      <Text>
                        What is your share of the expected monthly rental
                        amount?
                      </Text>
                      <View
                        style={{
                          flexDirection: "row",
                          marginTop: "10%",
                          alignItems: "center",
                        }}
                      >
                        <Text style={{ color: "black" }}>Amount £</Text>
                        <TextInput
                          style={{
                            width: "60%",
                            margin: 12,
                            borderBottomWidth: 1,
                            borderColor: "black",
                            color: COLORS.LGREEN
                          }}
                          type={"numeric"}
                          onChangeText={(val) => {
                            checkFieldDataAmount(val)
                          }}
                          value={expectedMonthlyRental}
                        />
                      </View>
                    </>
                  );
                }}
              />
            ) : null}
            <SkeltonComponents
              type={"empty"}
              renderBody={() => {
                return (
                  <>
                    <Text>
                      Please "self certify" your Yearly gross income *
                    </Text>
                    <View
                      style={{
                        flexDirection: "row",
                        marginTop: "10%",
                        alignItems: "center",
                      }}
                    >
                      <Text style={{ color: "black" }}>Amount £</Text>
                      <TextInput
                        style={{
                          width: "60%",
                          margin: 12,
                          borderBottomWidth: 1,
                          borderColor: "black",
                          color: COLORS.LGREEN
                        }}
                        type={"numeric"}
                        onChangeText={(val) => {
                          checkFieldDataYearly(val)
                        }}
                        value={monthlyIncome}
                      />
                    </View>
                  </>
                );
              }}
            />
          </React.Fragment>
        ) : null}
        {Affordability ? (
            <Button 
            isLGREEN
            style={{marginTop: 20,alignSelf: "center"}}
            disabled={isError()}
            onClick={() => {
              let data = {
                salary: monthlyIncome,
                monthly_rent: expectedMonthlyRental,
              };
              if (Affordability == "NO") {
                data.monthly_rent = 1;
              }
              dispatch(getAffordStatus(data, false));
              if (showClose) {
                onCloseClick();
              }
            }}type={'solid'} title={'Submit'} />

       
        ) : null}
      </Section1>
      <Section2>
        {affordData ? (
          <>
            {Affordability == "NO" ? (
              <SkeltonComponents
                type={"empty"}
                renderBody={() => {
                  return (
                    <>
                      <View
                        style={{ flexDirection: "row", marginBottom: "6%" }}
                      >
                        <View
                          style={{
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "flex-start",
                            flex: 0.3,
                          }}
                        >
                          <Text style={{ color: "black", fontWeight: "bold" }}>
                            Affordability
                          </Text>
                        </View>
                        <View
                          style={{
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "flex-end",
                            flex: 0.7,
                          }}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              setAffordablity(true);
                            }}
                            style={{
                              height: 24,
                              justifyContent: "center",
                              alignItems: "center",
                              width: "70%",
                              borderRadius: 25,
                              backgroundColor: "#A2A0A0",
                            }}
                          >
                            <Text style={{ color: "white" }}>N/A</Text>
                          </TouchableOpacity>
                        </View>
                      </View>

                      <Text>
                        Affordability criteria are determined by each individual
                        agent. From the information provided, we would suggest
                        you speak to an estate agent to discuss your
                        affordability from your declared monthly income amount,
                        the normal affordable rental amount would be:
                      </Text>
                      <View
                        style={{
                          flexDirection: "row",
                          marginTop: "5%",
                          alignItems: "center",
                        }}
                      >
                        <Text style={{ color: "#FFB580" }}>Amount £</Text>
                        <Text
                          style={{
                            width: "60%",
                            margin: 12,
                            borderBottomWidth: 1,
                            borderColor: "#FFB580",
                          }}
                          value={"sss"}
                        >
                          {affordData?.affordbility_amount.toFixed(2)}
                        </Text>
                      </View>
                    </>
                  );
                }}
              />
            ) : null}
            {Affordability === "YES" && affordData?.is_affordable ? (
              <SkeltonComponents
                type={"empty"}
                renderBody={() => {
                  return (
                    <>
                      <View
                        style={{ flexDirection: "row", marginBottom: "6%" }}
                      >
                        <View
                          style={{
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "flex-start",
                            flex: 0.3,
                          }}
                        >
                          <Text style={{ color: "black", fontWeight: "bold" }}>
                            Affordability
                          </Text>
                        </View>
                        <View
                          style={{
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "flex-end",
                            flex: 0.7,
                          }}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              setAffordablity(true);
                            }}
                            style={{
                              height: 24,
                              justifyContent: "center",
                              alignItems: "center",
                              width: "70%",
                              borderRadius: 25,
                              backgroundColor: "#00B401",
                            }}
                          >
                            <Text style={{ color: "white" }}>Pass</Text>
                          </TouchableOpacity>
                        </View>
                      </View>

                      <Text>
                        Affordability criteria is determined by each individual
                        agent. From the information provided we would suggest
                        you speak to an estate agent to discuss yoru
                        affordability From your declared monthly income amount,
                        the normal affordable rental amount would be:
                      </Text>
                    </>
                  );
                }}
              />
            ) : Affordability === "YES" && !affordData?.is_affordable ? (
              <SkeltonComponents
                type={"empty"}
                renderBody={() => {
                  return (
                    <>
                      <View
                        style={{ flexDirection: "row", marginBottom: "6%" }}
                      >
                        <View
                          style={{
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "flex-start",
                            flex: 0.3,
                          }}
                        >
                          <Text style={{ color: "black", fontWeight: "bold" }}>
                            Affordability
                          </Text>
                        </View>
                        <View
                          style={{
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "flex-end",
                            flex: 0.7,
                          }}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              setAffordablity(true);
                            }}
                            style={{
                              height: 24,
                              justifyContent: "center",
                              alignItems: "center",
                              width: "70%",
                              borderRadius: 25,
                              backgroundColor: "#FFB580",
                            }}
                          >
                            <Text style={{ color: "white" }}>
                              Speak to Agent
                            </Text>
                          </TouchableOpacity>
                        </View>
                      </View>

                      <Text>
                        Affordability criteria is determined by each individual
                        agent. From the information provided we would suggest
                        you speak to an estate agent to discuss yoru
                        affordability From your declared monthly income amount,
                        the normal affordable rental amount would be:
                      </Text>
                      {/* <View
                        style={{
                          flexDirection: "row",
                          marginTop: "5%",
                          alignItems: "center",
                        }}
                      >
                        <Text style={{ color: "#FFB580" }}>Amount £</Text>
                        <TextInput
                          style={{
                            width: "60%",
                            margin: 12,
                            borderBottomWidth: 1,
                            borderColor: "#FFB580",
                          }}
                          type={"numeric"}
                          onChangeText={(val) => {
                            
                          }}
                          value={amount}
                        />
                      </View> */}
                    </>
                  );
                }}
              />
            ) : null}
          </>
        ) : null}
        {showClose ? (
          <TouchableOpacity
            style={[
              {
                marginTop: 20,
                backgroundColor: "transparent",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
              },
            ]}
            onPress={() => {
              onCloseClick();
            }}
          >
            <Text style={{ color: COLORS.LGREEN, fontWeight: "bold" }}>Close</Text>
          </TouchableOpacity>
        ) : null}
      </Section2>
    </MainSection>
  );
};
export default Afford;
