import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  shawow: {
    boxShadow: '0px 0px 25px #DADADA',
  },

  container: {
    paddingHorizontal: '6%',
    height: 1350,
    paddingTop: 150,
  },
  formWrapper: {
    flex: 0.5,
    marginTop: 40,
  },
  imageContainer: {
    paddingHorizontal: '8%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: '9%',
  },
  textContainer: {
    paddingHorizontal: '8%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    paddingVertical: '12%',
  },
  searchSection: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: 80,
    backgroundColor: '#F9B383',
    paddingBottom: 353,
    paddingHorizontal: '8%',
  },
  wrapper: {
    paddingBottom: 10000,
  },
  videoWrapper: {
    width: 349,
    height: 241,
    borderWidth: 0.5,
    borderColor: '#000000',
    borderRadius: 9,
    padding: 9,
    backgroundColor: 'transparent',
    marginTop: 73,
  },
});
