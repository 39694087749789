import React, { useState } from 'react';
import { Dimensions, StyleSheet, View } from 'react-native';
import BackButton from '../../commonComponents/BackButton';
import { ChildrenView } from './styles';
import { ImageBackground } from 'react-native';
const WIN_HEIGHT = Dimensions.get('window').height;
import IdBack from '../../assets/images/prequal.png';
import BackGroundGenerator from '../LandingPages/common/BackGroundGenerator';
import RewardsComponent from './rewardsComponent';
import { Typography } from '../openFlow/style';
import { COLORS } from '../../constants/colorPallete';
import { getImage } from '../../helpers/getPlatform';
import { replaceAction } from '../../helpers/navigationHelpers';
import ODSButton from '../../../openbrix-design-system/ui/atoms/ODSButton';

const Rewards = (props) => {
  return (
    <BackGroundGenerator props={props} isTLYF>
      <View style={{ minHeight: WIN_HEIGHT - 300 }}>
        <View style={{ width: '100%' }}>
          <ImageBackground
            source={getImage('rewards.png')}
            style={{ width: '100%', height: 200 }}
            resizeMode={'cover'}
          >
            <ODSButton
              rIcon={'TB-Arrow-Left'}
              restStyle={{ marginTop: 20, marginLeft: 20, width: 200 }}
              type="secondary"
              disabled={false}
              onPress={() => replaceAction(props?.navigation, 'Dashboard')}
            >
              My Dashboard
            </ODSButton>
          </ImageBackground>

          <ChildrenView style={{ top: 0 }}>
            <RewardsComponent />
          </ChildrenView>
        </View>
      </View>
    </BackGroundGenerator>
  );
};
const styles = StyleSheet.create({});
export default Rewards;
