import idx from "idx";
import { Entypo } from "@expo/vector-icons";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FlatList, Image, Text, TextInput, TouchableOpacity, View } from "react-native";
import WideButton from "../../commonComponents/wideButton";
import FilePicker from "../../commonComponents/filePicker/filepicker.web";
import { BackTextWrapper, LeftPanal } from "../../components/moreInformation/styles";
import DatePickerComponent from "../../commonComponents/datepicker/DatePickerComponent";
import { logError } from "../../redux/actions/Ladger";
import getPlatformType from "../../helpers/getPlatform";
import { ImageBox, ImageWrapper, MainTitle } from "./style";
import SkeltonComponents from "./Components/skeltonComponents";
import { getMaintanenceDetails } from "../../redux/actions/property";
import { MainSection, Section1, Section2 } from "./preReferencingStyle";
import "./style.css";
import BackGroundGenerator from "../LandingPages/common/BackGroundGenerator";

const layoutType = getPlatformType();

const DetailMaintenance = (props) => {
  const [loading, setLoading] = useState(true);
  const [doc, setDocs] = useState([
    {
      name: null,
      openbrixName: null,
      mimetype: "image/png",
      id: 1,
    },
    {
      name: null,
      openbrixName: null,
      mimetype: "image/png",
      id: 2,
    },
    {
      name: null,
      openbrixName: null,
      mimetype: "image/png",
      id: 3,
    },
    {
      name: null,
      openbrixName: null,
      mimetype: "image/png",
      id: 4,
    },
  ]);

  const { maintenance_id } = idx(props, (_) => _.route.params);

  const isLoading = useSelector((state) => state?.preReferencing?.isLoading);
  const globalState = useSelector((state) => state?.propertyReducer?.maintenanceData);
  const property = useSelector((state) => state?.tenant?.flowIdInfo?.property);

  const propertyAssociated = useSelector(
    (state) => state?.authReducer?.profile?.data?.tenantDetails?.property,
  );

  const profile = useSelector((state) => state?.authReducer?.profile);

  const dispatch = useDispatch();

  const [issueSummary, setIssueSummary] = useState("");
  const [dateOpened, setDateOpened] = useState(new Date());
  const [title, setTitle] = useState("");
  const [tenantProperty, setTenantProperty] = useState(null);

  React.useEffect(() => {
    if (props?.route?.params?.maintenance_id) {
      if (props.route.params.maintenance_id != "add") {
        dispatch(getMaintanenceDetails(maintenance_id));
      }
    }
  }, [props?.route?.params?.maintenance_id]);
  React.useEffect(() => {
    if (globalState) {
      if (globalState?.description) {
        setTitle(globalState.description);
      }
      if (globalState?.summary) {
        setIssueSummary(globalState?.summary);
      }
      if (globalState?.images) {
        setDocs(globalState?.images);
      }
      setLoading(false);
    } else {
      setLoading(false);
      setTitle("");
      setIssueSummary("");
    }
  }, [globalState, isLoading]);

  React.useEffect(() => {
    if (propertyAssociated) {
      setTenantProperty(propertyAssociated);
    }
  }, [propertyAssociated]);

  const dateUpdateInput = (date) => {
    setDateOpened(date);
  };

  const _logError = () => {
    if (profile?.data?.tenantDetails && profile?.data?.tenantDetails?.property_id) {
      const uploadedImages = doc.filter((doc) => (doc.name ? true : false));
      const arr = uploadedImages.map((ele) => ele.openbrixName);
      dispatch(
        logError(
          {
            app_user_id: profile?.data?.appUser?.app_user_id,
            property_id: profile?.data?.tenantDetails?.property_id,
            issuetitle: title,
            issueSummary: issueSummary,
            date_closed: dateOpened,
            issueImage: JSON.stringify(arr),
          },
          props.navigation.navigate,
        ),
      );
    }
  };
  console.log("logssss", doc);
  return (
    <BackGroundGenerator props={props}>
      {layoutType === "web" && (
        <View
          style={{
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
            marginBottom: 12,
          }}
        >
          <View style={{
            width: 1130,
            paddingVertical: 10,
          }}>
            <LeftPanal>
              <TouchableOpacity
                onPress={() => {
                  props.navigation.goBack();
                }}
              >
                <BackTextWrapper>
                  <Entypo name="chevron-left" size={18} color="#000" style={{}}/> Back
                </BackTextWrapper>
              </TouchableOpacity>
            </LeftPanal>
          </View>
        </View>
      )}
      <MainSection style={{ marginBottom: 0 }}>
        <Section1>
          <MainTitle style={{
            marginTop: 10,
            textAlign: "left",
          }}>Maintenance</MainTitle>
          <SkeltonComponents
            type={"empty"}
            renderBody={() => {
              return (
                <>
                  <View
                    style={{
                      flexDirection: "row",
                      flex: 1,
                      borderBottomWidth: 1,
                      borderBottomColor: "#979797",
                      paddingBottom: 17,
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "column",
                        justifyContent: "center",

                        flex: 0.5,
                      }}
                    >
                      <Text style={{ color: "black" }}>Date Opened</Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-end",
                        flex: 0.5,
                      }}
                    >
                      <DatePickerComponent
                        id={"MAINTENANCE-DATE-OPENED"}
                        value={dateOpened}
                        onUpdateInput={(date) => {
                          dateUpdateInput(date);
                        }}
                      />
                    </View>
                  </View>

                  <View
                    style={{
                      flexDirection: "row",
                      flex: 1,
                      borderBottomWidth: 1,
                      borderBottomColor: "#979797",
                      paddingVertical: 17,
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "column",
                        justifyContent: "center",
                        flex: 0.5,
                      }}
                    >
                      <Text style={{ color: "black" }}>Status</Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-end",
                        flex: 0.5,
                      }}
                    >
                      <Text
                        style={{
                          color: globalState?.status === "unapproved" ? "red" : "green",
                        }}
                      >
                        {globalState?.status ? globalState?.status : "N/A"}
                      </Text>
                    </View>
                  </View>
                  <View style={{
                    flexDirection: "row",
                    flex: 1,
                    paddingTop: 17,
                  }}>
                    <View
                      style={{
                        flexDirection: "column",
                        justifyContent: "center",

                        flex: 0.5,
                      }}
                    >
                      <Text style={{ color: "black" }}>Report Title </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-end",
                        flex: 0.5,
                      }}
                    >
                      <TextInput
                        id={"MAINTENANCE-REPORT-TITLE"}
                        style={{
                          width: "100%",
                          height: 60,
                          paddingHorizontal: 10,
                          border: "1px solid black",
                        }}
                        maxLength={40}
                        value={title}
                        multiline
                        editable={globalState ? false : true}
                        placeholder="Enter report title"
                        onChangeText={(value) => {
                          setTitle(value);
                        }}
                      />
                    </View>
                  </View>
                </>
              );
            }}
          />
        </Section1>
        <Section2 style={{ marginLeft: layoutType === "phone" ? 0 : 20 }}>
          <>
            <MainTitle style={{
              marginTop: 10,
              textAlign: "left",
            }}>Transaction ID</MainTitle>
            <SkeltonComponents
              type={"empty"}
              renderBody={() => {
                return (
                  <>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Text style={{
                        color: "#E71E82",
                        fontSize: 20,
                      }}>
                        {globalState?.maintenance_action_id
                          ? globalState?.maintenance_action_id
                          : "N/A"}
                      </Text>
                    </View>
                  </>
                );
              }}
            />

            <View
              style={{
                borderTopRightRadius: 15,
                borderBottomLeftRadius: 15,
                width: "100%",
                height: 70,
                marginTop: 15,
                flexDirection: "row",
                shadowColor: "#000",
                shadowOffset: {
                  width: 0,
                  height: 2,
                },
                shadowOpacity: 0.25,
                shadowRadius: 3.84,
                elevation: 5,
              }}
            >
              <View style={{
                height: "100%",
                width: "25%",
              }}>
                <Image
                  source={{
                    uri: tenantProperty?.property_images?.length
                      ? tenantProperty?.property_images[0]
                      : "https://assets.openbrix.co.uk/nophoto.jpg",
                  }}
                  resizeMode={"stretch"}
                  style={{
                    height: "100%",
                    width: "100%",
                  }}
                />
              </View>
              <View style={{
                flex: 1,
                paddingLeft: 12,
                justifyContent: "center",
              }}>
                <Text
                  style={{
                    fontSize: layoutType != "web" ? 14 : 16,
                    color: "#E71F81",
                    fontWeight: "bold",
                    width: "99%",
                  }}
                >
                  {tenantProperty?.address_line1}
                </Text>
                <Text
                  style={{
                    color: "#b0b0b0",
                    marginTop: 5,
                    fontSize: layoutType != "web" ? 12 : 14,
                    width: "95%",
                  }}
                >
                  {tenantProperty?.property_name}
                </Text>
              </View>
            </View>
          </>
        </Section2>
      </MainSection>

      <View
        style={{
          backgroundColor: "#FFF",
          marginTop: "4%",
          shadowRadius: 2,
          shadowOffset: {
            width: 0,
            height: -3,
          },
          shadowColor: "#fff",
          elevation: 4,
          zIndex: 1,
        }}
      >
        <MainSection style={{ flexDirection: "column" }}>
          <SkeltonComponents
            type={"empty"}
            title="Issue Summary"
            renderBody={() => {
              return (
                <>
                  <View style={{ flexDirection: "row" }}>
                    <TextInput
                      style={{
                        height: 100,
                        width: "100%",
                        padding: 10,
                      }}
                      multiline={true}
                      placeholder={"Enter summary details"}
                      editable={globalState ? false : true}
                      onChangeText={(VAL) => setIssueSummary(VAL)}
                      id={"MAINTENANCE-ISSUE-SUMMARY"}
                      value={
                        issueSummary
                        // globalState?.summary ? globalState?.summary : "N/A"
                      }
                    />
                  </View>
                </>
              );
            }}
          />
          <Text style={{
            fontSize: 15,
            marginVertical: "4%",
            fontWeight: "bold",
          }}>
            Show Images
          </Text>

          {props?.route?.params?.maintenance_id == "add" ? (
            <ImageWrapper>
              <ImageBox>
                <FilePicker
                  fileName={doc[0]?.openbrixName}
                  renderImage={true}
                  Idindex={1}
                  file={doc[0]?.name}
                  onFileSelect={(val, ii) => {
                    console.log("valvalval", val);
                    if (ii === 1) {
                      let tempArray = [...doc];
                      tempArray[0] = val;
                      setDocs(tempArray);
                    }
                  }}
                />
              </ImageBox>
              <ImageBox>
                <FilePicker
                  fileName={doc[1]?.openbrixName}
                  renderImage={true}
                  file={doc[1]?.name}
                  Idindex={2}
                  onFileSelect={(val, ii) => {
                    if (ii === 2) {
                      let tempArray = [...doc];
                      tempArray[1] = val;

                      setDocs(tempArray);
                    }
                  }}
                />
              </ImageBox>
              <ImageBox>
                <FilePicker
                  fileName={doc[2]?.openbrixName}
                  Idindex={3}
                  renderImage={true}
                  file={doc[2]?.name}
                  onFileSelect={(val, ii) => {
                    if (ii === 3) {
                      let tempArray = [...doc];
                      tempArray[2] = val;
                      setDocs(tempArray);
                    }
                  }}
                />
              </ImageBox>
              <ImageBox>
                <FilePicker
                  fileName={doc[3]?.openbrixName}
                  renderImage={true}
                  Idindex={4}
                  file={doc[3]?.name}
                  onFileSelect={(val, ii) => {
                    if (ii === 4) {
                      let tempArray = [...doc];
                      tempArray[3] = val;
                      setDocs(tempArray);
                    }
                  }}
                />
              </ImageBox>
            </ImageWrapper>
          ) : (
            <View
              style={{
                marginTop: 10,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FlatList
                data={globalState?.images}
                columnWrapperStyle={{ justifyContent: "space-between" }}
                numColumns={layoutType == "phone" ? 2 : 4}
                renderItem={({ item }) => (
                  <Image
                    style={{
                      width: layoutType == "phone" ? "44%" : "22%",
                      marginTop: 10,
                      height: layoutType == "phone" ? 100 : 200,
                      marginRight: 10,
                    }}
                    source={{ uri: item }}
                  />
                )}
              />
            </View>
          )}
        </MainSection>
        {props?.route?.params?.maintenance_id == "add" ? (
          <View
            style={{
              width: 300,
              alignSelf: "center",
              marginBottom: 30,
            }}
          >
            <WideButton
              id={"MAINTENANCE-SUBMIT-REPORT"}
              onPress={() => _logError()}
              width="100%"
              childText={"Submit report"}
            />
          </View>
        ) : null}
      </View>
    </BackGroundGenerator>
  );
};
export default DetailMaintenance;
